import React, { ComponentType, SVGProps, memo } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import classnames from "classnames";
import { HTMLDivProps, VoidCallback } from "src/types/common";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import styles from "./BroadcastButton.scss";

interface BroadcastButtonProps extends HTMLDivProps {
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
  className?: string;
  disabled?: boolean;
  message: MessageDescriptor;
  onClick?: VoidCallback;
  variant: ButtonVariant.PRIMARY | ButtonVariant.SECONDARY;
}

const BroadcastButton: React.FC<BroadcastButtonProps> = ({
  disabled,
  onClick,
  message,
  Icon,
  className,
  variant,
  ...rest
}) => (
  <div className={classnames(styles.root, className)} {...rest}>
    <Button
      size={ButtonSize.CIRCLE_EXTRABIG_56}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon />
    </Button>
    <Typography className={styles.message} type={TYPOGRAPHY_TYPE.PARAGRAPH3}>
      <FormattedMessage {...message} />
    </Typography>
  </div>
);

export default memo(BroadcastButton);
