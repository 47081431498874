import { useCallback } from "react";
import { emitEvent } from "@analytics/emit";
import {
  Action,
  GiftPlaceholder,
  MosTargetValue,
} from "src/core/analytics/enums";
import { EventNames, MosEventFields } from "src/features/mos/imports/types";

type MosEventFieldParam = Partial<Record<MosEventFields, unknown>>;

export const useMosAnalytics = () => {
  const errorAction = useCallback((params: MosEventFieldParam) => {
    emitEvent(EventNames.MOS_RECEIVED_ERROR, params);
  }, []);

  const giftClickAction = useCallback((params: MosEventFieldParam) => {
    emitEvent(EventNames.UI_ACTION, {
      [MosEventFields.ACTION]: Action.CLICK,
      [MosEventFields.TARGET]: MosTargetValue.MOS,
      [MosEventFields.GIFT_PLACEHOLDER]: GiftPlaceholder.MOS_V2,
      ...params,
    });
  }, []);

  const startScrollAction = useCallback((params: MosEventFieldParam) => {
    emitEvent(EventNames.UI_ACTION, {
      [MosEventFields.ACTION]: Action.SCROLL,
      [MosEventFields.GIFT_PLACEHOLDER]: GiftPlaceholder.MOS_V2,
      ...params,
    });
  }, []);

  const endScrollAction = useCallback((params: MosEventFieldParam) => {
    emitEvent(EventNames.MOS_DISPLAYED, params);
  }, []);

  return { giftClickAction, startScrollAction, endScrollAction, errorAction };
};
