import React, { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { REACTION } from "chat/enums";
import { chatSelectors } from "chat/exports/state/selectors";
import { Typography } from "chat/imports/components";
import {
  CustomTypographyType,
  HeadlineTypographyType,
} from "chat/imports/constants";
import { RootState } from "chat/imports/state";
import { sendMessageReaction, updateMessageReaction } from "chat/state";
import { MessageIdentifier } from "chat/types";
import styles from "./MessageReactions.scss";

interface MessageReactionsProps {
  isMyMessage: boolean;
  message: MessageIdentifier;
  messageSenderId: string | undefined;
}

const selector =
  (messageId: number, currentConversationId: null | string) =>
  (state: RootState) => ({
    allMessageReactions: chatSelectors.getMessageReactions(
      state,
      currentConversationId,
      messageId
    ),
    isMessageHasSingleReaction: chatSelectors.getIsMessageHasSingleReaction(
      state,
      currentConversationId,
      messageId
    ),
  });

export const MessageReactions = ({
  isMyMessage,
  messageSenderId,
  message,
}: MessageReactionsProps) => {
  const dispatch = useDispatch();
  const conversationId = useSelector(chatSelectors.getCurrentConversationId);
  const { allMessageReactions, isMessageHasSingleReaction } = useSelector(
    selector(message.id, conversationId),
    shallowEqual
  );
  const myReactionIndex = useMemo(
    () =>
      allMessageReactions?.messageReactions.findIndex(
        (reaction) => allMessageReactions?.myReactionId === reaction.reactionId
      ),
    [allMessageReactions?.messageReactions, allMessageReactions?.myReactionId]
  );

  const onReactionClickHandler =
    (reaction: REACTION) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (allMessageReactions?.myReactionId === reaction) {
        dispatch(
          sendMessageReaction({
            reactionId: reaction,
            deleteReaction: true,
            messageSenderId,
            message,
          })
        );
      } else if (allMessageReactions.myReactionId) {
        dispatch(
          updateMessageReaction({
            oldReaction: allMessageReactions.myReactionId,
            newReaction: reaction,
            message,
            messageSenderId,
          })
        );
      } else {
        dispatch(
          sendMessageReaction({
            reactionId: reaction,
            deleteReaction: false,
            messageSenderId,
            message,
          })
        );
      }
    };

  return (
    <div
      className={classnames(styles.root, {
        [styles.myMessage]: isMyMessage,
        [styles.singleReaction]: isMessageHasSingleReaction,
        [styles.myReactionFirst]: myReactionIndex === 0,
        [styles.myReactionLast]:
          myReactionIndex === allMessageReactions?.messageReactions.length - 1,
      })}
    >
      {(allMessageReactions?.messageReactions ?? []).map(
        ({ reactionId, reactionCount }) => (
          <div
            className={classnames(styles.reaction, {
              [styles.myReaction]:
                allMessageReactions.myReactionId === reactionId,
            })}
            key={reactionId}
            onClick={onReactionClickHandler(reactionId)}
          >
            <Typography type={HeadlineTypographyType.HEADLINE4}>
              {reactionId}
            </Typography>

            {!isMessageHasSingleReaction && reactionCount > 1 && (
              <Typography
                className={styles.reactionCount}
                type={CustomTypographyType.MINI}
              >
                <FormattedNumber
                  value={reactionCount}
                  notation={reactionCount >= 1000 ? "compact" : undefined}
                />
              </Typography>
            )}
          </div>
        )
      )}
    </div>
  );
};
