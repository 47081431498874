import { shallowEqual, useSelector } from "react-redux";
import {
  Breakpoints,
  DeviceType,
  RootState,
} from "src/features/broadcast/imports/enums";
import { useBreakpointPrecise } from "src/features/broadcast/imports/hooks";
import {
  deviceInfoSelectors,
  getSendLivePartyInvitesEnabled,
} from "src/features/broadcast/imports/state";

const selector = (state: RootState) => ({
  deviceType: deviceInfoSelectors.getDeviceType(state),
  isSendInviteToLiveParty: getSendLivePartyInvitesEnabled(state),
});

export const useInvitesToLivePartyEnabled = () => {
  const breakpoint = useBreakpointPrecise();

  const { deviceType, isSendInviteToLiveParty } = useSelector(
    selector,
    shallowEqual
  );

  return (
    isSendInviteToLiveParty &&
    deviceType === DeviceType.DESKTOP &&
    breakpoint === Breakpoints.DESKTOP
  );
};
