import { checkSessionStartEventPayload } from "src/core/analytics/dataMesh/utils/refreshMethods/checkSessionStartEventPayload";
import { updateSessionStartDate } from "src/core/analytics/dataMesh/utils/refreshMethods/updateSessionStartDate";
import { getAnalyticsSessionStartRefreshIntervalInMs } from "src/core/analytics/imports/environment";

export const initSessionStartEventRefresh = () => {
  updateSessionStartDate();
  setInterval(() => {
    updateSessionStartDate();
    checkSessionStartEventPayload();
  }, getAnalyticsSessionStartRefreshIntervalInMs());
};
