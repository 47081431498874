import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import Datadog from "@analytics/DatadogUtils";
import { emitEvent } from "@analytics/emit";
import { AcmeBIType, EventFields, EventNames } from "@analytics/enums";
import {
  getEnvironmentName,
  getTransactionOutdatedTimeoutMs,
} from "environment";
import {
  STATUS_TO_EVENT_MAP,
  TRANSACTION_STATUS_KEY,
  TRANSACTIONS_DATA_KEY,
} from "src/core/analytics/constants";
import {
  LocalStorageTransactions,
  Transaction,
} from "src/core/analytics/types";
import { analyticsPurchaseEvent } from "src/core/analytics/utils/analyticsPersonalOffers";
import { AcmeServiceIdentifier, AcmeServiceName } from "src/enums";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { currentTimeMillis } from "src/utils/dateUtils";
import { getInMemoryStore } from "src/utils/getStoreHandler";
import { getAcmeTransactionLoggerEnabled } from "state/abTests";
import { actions } from "state/actionCreators/translationStatusAnalytics";
import { emitImpactPurchase } from "utils/impactSetupUtils";
import { jsonParse } from "utils/jsonConverter";

export const persistConfig = {
  whitelist: [TRANSACTIONS_DATA_KEY],
};

const initialState = {
  transactionsData: {},
};

export interface TransactionStatusDataAcmeType {
  isFirst: boolean;
  status: number;
  timestamp: number;
  transactionId: string;
}

export interface TransactionType
  extends Partial<TransactionStatusDataAcmeType> {
  [key: string]: boolean | number | string | undefined;
}

export interface TransactionStatusAnalytics {
  transactionsData: Record<string, TransactionType | undefined>;
}

interface AcmeIdentifier {
  serviceIdentifier: string;
  serviceName: string;
}

export interface TransactionStatusAcme extends AcmeIdentifier {
  data: TransactionStatusDataAcmeType;
}

const isTransactionStatusMessage = ({
  serviceName,
  serviceIdentifier,
}: AcmeIdentifier) =>
  serviceName === AcmeServiceName.FINANCE &&
  serviceIdentifier === AcmeServiceIdentifier.TRANSACTION_STATUS_MESSAGE_ACME;

const cleanOutdatedTransactions = (state: TransactionStatusAnalytics) => {
  Object.entries(state.transactionsData).forEach(
    ([transactionId, transactionData]) => {
      if (!transactionData) {
        delete state.transactionsData[transactionId];

        return;
      }

      const { timestamp } = transactionData;
      const isOutdated =
        !timestamp ||
        currentTimeMillis() - timestamp > getTransactionOutdatedTimeoutMs();

      if (isOutdated) {
        delete state.transactionsData[transactionId];
      }
    }
  );
};

const reducer = createReducer<TransactionStatusAnalytics>(
  initialState,
  (builder) => {
    builder
      .addCase(actions.acmeTransactionResponse, (state, action) => {
        const { data, ...acmeMessageIdentifier } = action.payload;

        if (!isTransactionStatusMessage(acmeMessageIdentifier)) {
          return;
        }

        const localTransactions = localStorage.getItem(
          `persist:${getEnvironmentName()}:${TRANSACTION_STATUS_KEY}`
        );

        const isAcmeTransactionLoggerEnabled =
          getAcmeTransactionLoggerEnabled(getInMemoryStore());

        Datadog.availableToLogger(isAcmeTransactionLoggerEnabled, () =>
          Datadog.info(AcmeBIType.PURCHASE_TRANSACTION, {
            acmeResponse: action.payload,
            localTransactions,
          })
        );

        const { transactionId, status, isFirst, timestamp } = data;

        const eventName = STATUS_TO_EVENT_MAP[status];

        if (!transactionId || !eventName) {
          return;
        }

        const parseLocalTransactions =
          localTransactions &&
          jsonParse<LocalStorageTransactions>(localTransactions);

        const parseTransactionsData =
          !!parseLocalTransactions &&
          jsonParse<Record<string, Transaction>>(
            parseLocalTransactions[TRANSACTIONS_DATA_KEY]
          );

        if (
          !parseTransactionsData ||
          typeof parseTransactionsData !== "object"
        ) {
          return;
        }

        const selectedTransaction = parseTransactionsData[transactionId];

        if (!selectedTransaction || selectedTransaction.isSend) {
          return;
        }

        parseTransactionsData[transactionId].isSend = true;

        parseLocalTransactions[TRANSACTIONS_DATA_KEY] = JSON.stringify(
          parseTransactionsData
        );

        localStorage.setItem(
          `persist:${getEnvironmentName()}:${TRANSACTION_STATUS_KEY}`,
          JSON.stringify(parseLocalTransactions)
        );

        const userHashedInfo =
          marketingSelectors.getUserHashedInfo(getInMemoryStore());

        const analyticsData = {
          ...selectedTransaction,
          [EventFields.TRANSACTION_ID]: transactionId,
          [EventFields.TIMESTAMP]: timestamp,
          [EventFields.HASHED_EMAIL]: userHashedInfo?.hashedEmail,
          [EventFields.HASHED_PHONE_NUMBER]: userHashedInfo?.hashedPhoneNumber,
        };

        emitEvent(eventName, analyticsData);

        if (isFirst) {
          emitEvent(EventNames.PURCHASE_FTP, analyticsData);
        }

        if (eventName === EventNames.PURCHASE_SUCCESS) {
          emitImpactPurchase(analyticsData);
          analyticsPurchaseEvent(transactionId, analyticsData);
        }
      })
      .addCase(actions.addTransaction, (state, action) => {
        state.transactionsData = {
          ...state.transactionsData,
          ...action.payload,
        };
      })
      .addMatcher(
        isAnyOf(actions.addTransaction, actions.acmeTransactionResponse),
        cleanOutdatedTransactions
      );
  }
);

export default reducer;
