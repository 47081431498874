import { ErrorSource } from "@datadog/browser-core";
import { LogsEvent } from "@datadog/browser-logs";
import { SIFT_SDK_URL } from "@analytics/sift/constants";
import { API_ERROR_NETWORK_ERROR_TEXT } from "src/constants";

enum LogMessage {
  EXTENSION_SCRIPT_ERROR = "chrome-extension",
  LOADING_CHUNK = "Loading chunk",
  LOADING_CSS_CHUNK = "Loading CSS chunk",
  SCRIPT_ERROR = "Script error.",
  XHR_ERROR_GET = "XHR error GET",
}

enum BanubaSdkLogMessage {
  NO_SURFACE_ERROR = "No surface to draw on",
}

enum LogStatus {
  DEBUG = "debug",
  ERROR = "error",
  INFO = "info",
  WARN = "warn",
}

const ABORT_ERROR_KIND = "AbortError";
const CINEMA_URL_PREFIX = "https://cinema-";
const BANUBA_SDK_PREFIX = "bnb_sdk";

const loadingAssetErrorSourceList = [ErrorSource.CONSOLE, ErrorSource.SOURCE];

const loadingAssetMessageList = [
  LogMessage.LOADING_CHUNK,
  LogMessage.LOADING_CSS_CHUNK,
];

const valueOrEmpty = <T>(value?: T) => value ?? "";

const FILTERS_CRITERIA = [
  function filterNetworkErrors(log: LogsEvent) {
    const [errorOrigin, statusCode] = [
      valueOrEmpty(log?.origin),
      valueOrEmpty(log?.http?.status_code),
    ];

    return statusCode === 0 && errorOrigin === ErrorSource.NETWORK;
  },
  function filterAbortErrors(log: LogsEvent) {
    const errorKind = valueOrEmpty(log?.error?.kind);

    return errorKind.includes(ABORT_ERROR_KIND);
  },
  function filterApiErrorsNetworkErrors(log: LogsEvent) {
    const message = valueOrEmpty(log?.message);

    return message.includes(API_ERROR_NETWORK_ERROR_TEXT);
  },
  function filterXhrGetServerErrors(log: LogsEvent) {
    const isXhrErrorGetMessage = log.message.includes(LogMessage.XHR_ERROR_GET);
    const isNetworkErrorSource = log.origin === ErrorSource.NETWORK;
    const isGetRequest = log.http?.method === "GET";
    const isServerError = (log.http?.status_code ?? 0) >= 500;
    const isCinemaUrl = !!log.http?.url?.startsWith(CINEMA_URL_PREFIX);

    return (
      isXhrErrorGetMessage &&
      isNetworkErrorSource &&
      isGetRequest &&
      isServerError &&
      isCinemaUrl
    );
  },
  function filterExtensionScriptError(log: LogsEvent) {
    return log.error?.stack?.includes(LogMessage.EXTENSION_SCRIPT_ERROR);
  },
  function filterLoadingAssetErrors(log: LogsEvent) {
    const isLoadingAssetMessage = loadingAssetMessageList.some((message) =>
      log.message.includes(message)
    );

    const isLoadingAssetErrorSource = loadingAssetErrorSourceList.some(
      (origin) => log.origin === origin
    );

    return isLoadingAssetMessage && isLoadingAssetErrorSource;
  },

  function switchToInfoThirdPartyScriptErrorLog(log: LogsEvent) {
    if (
      log.status === LogStatus.ERROR &&
      log.message.includes(LogMessage.SCRIPT_ERROR)
    ) {
      log.status = LogStatus.INFO;
    }
  },
  function filterLoadingSiftSdkErrors(log: LogsEvent) {
    return log.status === LogStatus.ERROR && log.message.includes(SIFT_SDK_URL);
  },
  function filterBanubaSdkErrors(log: LogsEvent) {
    return (
      log.message.includes(BANUBA_SDK_PREFIX) &&
      log.message.includes(BanubaSdkLogMessage.NO_SURFACE_ERROR)
    );
  },
];

export const filterNetworkErrors = (log: LogsEvent) =>
  !FILTERS_CRITERIA.some((cb) => cb(log));
