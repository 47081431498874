import { ApiMessageType } from "chat/enums";
import { getProxycadorHost } from "chat/imports/environment";
import { enhancedFetch as fetch } from "chat/imports/utils";
import { StoredMessage } from "chat/state";
import { DeleteMessageRequestsResponse } from "chat/types";

export const editMessage = (
  message: StoredMessage
): Promise<DeleteMessageRequestsResponse> =>
  fetch(`${getProxycadorHost()}/tc2/v2/messages/edit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      message: {
        message_type: ApiMessageType.TEXT_MESSAGE,
        body: message.body,
      },
      message_identifier: message.id,
    }),
  }).then((resp) => resp.json());
