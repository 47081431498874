import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { RegistrationSource } from "@analytics/enums";
import { emitOpenProfileEvent } from "@analytics/profile/emitOpenProfileEvent";
import { useProfileEvent } from "@analytics/profile/useProfileEvent";
import {
  emitMoreButtonClicked,
  emitOneToOneStreamStartClicked,
} from "chat/exports/analytics";
import { ConversationState } from "chat/exports/types";
import {
  messageRequestSelectors,
  removeMessagesRequest,
} from "chat/messageRequest/exports/state";
import { SCENE } from "enums/modalScope";
import {
  Breakpoints,
  LoginPromotionType,
  MiniProfileEntranceSource,
  ModalType,
  ProfileType,
  ToastType,
} from "src/enums";
import { broadcastOneToOneMessages } from "src/features/broadcastOneToOne/common/messages";
import { isBroadcastOneToOneEnabled } from "src/features/broadcastOneToOne/soc/broadcastOneToOneSoc";
import { openLoginView } from "src/features/signin/exports/state/flows";
import {
  blockUserAction,
  unblockUserAction,
} from "src/state/actionCreators/blockedUsers";
import { RootState } from "src/state/delegate";
import { AccountInfo } from "src/types/common";
import { BasicProfile } from "src/types/profile/profile";
import useDeleteChat from "src/ui/hooks/useDeleteChat";
import useIntersectionObserver from "src/ui/hooks/useIntersectionObserver";
import {
  linkToBroadcastOneToOne,
  linkToMessageRequest,
  linkToMessageRequestMatch,
} from "src/ui/navigation/links";
import { getIsKickOutEnabled } from "state/abTests";
import { openConfirmationBottomScreen } from "state/actionCreators/bottomScreen";
import {
  openBlockListModal,
  openConfirmationModal,
  openEditProfileModal,
  openReportModal,
} from "state/actionCreators/modal";
import { unfollow } from "state/flows/followingList";
import { kickUserFromStream } from "state/flows/kick";
import { blockedUsersSelectors } from "state/selectors";
import { broadcastActionCreators } from "state/tree/broadcast";
import DisplayName from "ui/common/DisplayName";
import Button from "ui/common/button/Button";
import { ButtonSize, ButtonVariant } from "ui/common/button/types";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useClickOutside from "ui/hooks/useClickOutside";
import useMoreMenuSelector from "ui/hooks/useMoreMenuSelector";
import useProfileShareButtonHandlers from "ui/hooks/useProfileShareButtonHandlers";
import useShare from "ui/hooks/useShare";
import { useToast } from "ui/hooks/useToast";
import useIsOnBroadcast from "ui/navigation/useIsOnBroadcast";
import { ReactComponent as DotsIcon } from "img/ic_dots_32.svg";
import styles from "./MoreMenu.scss";
import transition from "ui/transitions/FadeTransition.scss";

interface MoreMenuProps {
  accountId: string;
  accountInfo?: AccountInfo;
  basicProfile: BasicProfile;
  className?: string;
  conversationId?: string;
  isMiniProfile?: boolean;
  isOfflineChat?: boolean;
}

const selector = (conversationId: string) => (state: RootState) => ({
  isBroadcastOneToOneEnabled: isBroadcastOneToOneEnabled(state),
  blockList: blockedUsersSelectors.getAllBlockedUsers(state),
  messagesRequest: messageRequestSelectors.getConversation(
    state,
    conversationId
  ),
  isKickOutEnabled: getIsKickOutEnabled(state),
});

const MoreMenu: FC<MoreMenuProps> = ({
  className,
  accountId,
  accountInfo,
  conversationId,
  basicProfile,
  isMiniProfile,
  isOfflineChat,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const { notify } = useToast();
  const rootRef = useRef<HTMLDivElement>(null);
  const isInView = useIntersectionObserver(rootRef, {
    rootMargin: "0px 0px 0px 0px",
  });
  const [isMenuVisible, setMenuVisible] = useState(false);
  const toggleMenu = useCallback(() => setMenuVisible((prev) => !prev), []);
  const closeMenu = useCallback(() => setMenuVisible(false), []);
  const { isLoggedIn, isBlocked, isFollowed, isSubscribed, isMe } =
    useMoreMenuSelector(accountId);
  const {
    blockList,
    messagesRequest,
    isBroadcastOneToOneEnabled,
    isKickOutEnabled,
  } = useSelector(selector(accountId), shallowEqual);
  const isBroadcastScreen = useIsOnBroadcast();

  const isChatRequestMatch = useRouteMatch(linkToMessageRequestMatch);
  const isChatRequest =
    messagesRequest?.state === ConversationState.CHAT_REQUEST &&
    !!messagesRequest?.hidden;

  const handleDeleteChat = useDeleteChat({
    accountInfo,
    conversationId,
  });

  const getProfileBIEvent = useProfileEvent(
    MiniProfileEntranceSource.REPORT,
    ProfileType.MINI_PROFILE
  );

  useClickOutside(rootRef, closeMenu);

  useEffect(() => {
    if (!isInView && isMenuVisible) {
      closeMenu();
    }
  }, [closeMenu, isInView, isMenuVisible]);

  const handleEditProfileClick = useCallback(() => {
    closeMenu();
    dispatch(openEditProfileModal());
  }, [dispatch, closeMenu]);

  const handleBlockListClick = useCallback(() => {
    close();
    dispatch(openBlockListModal());
  }, [dispatch]);

  const handleReportClick = useCallback(() => {
    closeMenu();
    emitOpenProfileEvent(getProfileBIEvent(accountId));
    dispatch(
      // @ts-ignore TODO: types
      openReportModal({
        accountId,
      })
    );
  }, [dispatch, closeMenu, accountId, getProfileBIEvent]);

  const handleBlockOrUnblockClick = useCallback(() => {
    closeMenu();

    const openConfirmation =
      breakpoint === Breakpoints.DESKTOP
        ? openConfirmationModal
        : openConfirmationBottomScreen;

    if (!isLoggedIn) {
      dispatch(
        openLoginView({
          registrationSource: RegistrationSource.PROFILE_REPORT,
          promotionType: LoginPromotionType.DEFAULT,
        })
      );

      return;
    }

    if (isBlocked) {
      dispatch(
        // @ts-ignore TODO: https://tango-me.atlassian.net/browse/WEB-4861
        openConfirmation({
          modalScope: SCENE,
          modalType: ModalType.UNBLOCK_USER_MODAL,
          title: sharedMessages.unblockUserTitle,
          confirmText: sharedMessages.unblockUserTitle,
          dismissText: sharedMessages.cancel,
          body: (
            <FormattedMessage
              {...sharedMessages.unblockUserQuestion}
              values={{
                name: (
                  <b>
                    <DisplayName basicProfile={basicProfile} />
                  </b>
                ),
              }}
            />
          ),
          basicProfile,
          confirm: () => dispatch(unblockUserAction({ accountId })),
          isShaderTheme: isMiniProfile,
        })
      );
    } else {
      dispatch(
        // @ts-ignore TODO:types
        openConfirmation({
          modalScope: SCENE,
          modalType: ModalType.BLOCK_USER_MODAL,
          title: sharedMessages.blockUserTitle,
          confirmText: sharedMessages.blockUserTitle,
          dismissText: sharedMessages.cancel,
          body: (
            <FormattedMessage
              {...sharedMessages.blockUserQuestion}
              values={{
                name: (
                  <b>
                    <DisplayName basicProfile={basicProfile} />
                  </b>
                ),
              }}
            />
          ),
          basicProfile,
          isShaderTheme: isMiniProfile,
          isControlButtonsWithSpacing: isOfflineChat,
          confirm: async () => {
            dispatch(blockUserAction({ accountId }));

            if (isChatRequest) {
              dispatch(removeMessagesRequest({ conversationId: accountId }));

              if (isChatRequestMatch) {
                history.push(linkToMessageRequest);
              }
            }
          },
        })
      );
    }
  }, [
    closeMenu,
    breakpoint,
    isLoggedIn,
    isBlocked,
    dispatch,
    basicProfile,
    isMiniProfile,
    accountId,
    isOfflineChat,
    isChatRequest,
    isChatRequestMatch,
    history,
  ]);

  const share = useShare(
    useProfileShareButtonHandlers({
      accountId,
      basicProfile,
    })
  );

  const handleMoreClick = () => {
    if (isOfflineChat && conversationId) {
      emitMoreButtonClicked({ chatId: conversationId });
    }

    toggleMenu();
  };

  const handleShareClick = useCallback(() => {
    closeMenu();
    share();
  }, [share, closeMenu]);

  const handleKickClick = useCallback(() => {
    dispatch(
      kickUserFromStream({
        accountId,
        onSuccess: () => {
          closeMenu();
        },
        onError: () => {
          notify({
            type: ToastType.REGULAR,
            title: sharedMessages.somethingWentWrong,
          });
          closeMenu();
        },
      })
    );
  }, [accountId, closeMenu, dispatch, notify]);

  const handleStartOneToOneClick = () => {
    if (isOfflineChat && conversationId) {
      emitOneToOneStreamStartClicked({ chatId: conversationId });
    }

    dispatch(broadcastActionCreators.broadcastTransformToOneToOne(accountId));

    history.push(linkToBroadcastOneToOne);
  };

  const handleUnfollowClick = useCallback(() => {
    closeMenu();
    dispatch(
      // @ts-ignore TODO:types
      openConfirmationModal({
        title: sharedMessages.unfollowUserTitle,
        modalType: ModalType.UNFOLLOW_MODAL,
        modalScope: SCENE,
        body: (
          <FormattedMessage
            {...sharedMessages.unfollowUserQuestion}
            values={{
              name: (
                <b>
                  <DisplayName basicProfile={basicProfile} />
                </b>
              ),
            }}
          />
        ),
        confirm: () => {
          dispatch(unfollow(accountId, RegistrationSource.UNKNOWN));
        },
      })
    );
  }, [closeMenu, dispatch, basicProfile, accountId]);

  return (
    <div
      className={classnames(styles.root, className, {
        [styles.miniProfile]: isMiniProfile,
      })}
      ref={rootRef}
    >
      <Button
        className={classnames(styles.btn, {
          [styles.active]: isMenuVisible,
        })}
        size={ButtonSize.MEDIUM_32}
        variant={ButtonVariant.ICON_ONLY}
        onClick={handleMoreClick}
        data-testid="more-menu-profile"
      >
        <DotsIcon />
      </Button>
      <CSSTransition
        in={isMenuVisible}
        classNames={transition}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH3}
          className={classnames(styles.menu, {
            [styles.white]: isMiniProfile,
          })}
          as="menu"
        >
          {isOfflineChat && !isBlocked && isBroadcastOneToOneEnabled && (
            <li
              className={styles.item}
              onTouchStart={emptyFunction}
              onClick={handleStartOneToOneClick}
            >
              <FormattedMessage {...broadcastOneToOneMessages.oneToOneStream} />
            </li>
          )}
          {isMe && !isMiniProfile ? (
            <>
              <li
                className={styles.item}
                onTouchStart={emptyFunction}
                onClick={handleEditProfileClick}
              >
                <FormattedMessage {...sharedMessages.editProfile} />
              </li>
              {blockList.length > 0 && (
                <li
                  className={styles.item}
                  onTouchStart={emptyFunction}
                  onClick={handleBlockListClick}
                >
                  <FormattedMessage {...sharedMessages.blockedUsers} />
                </li>
              )}
            </>
          ) : (
            <>
              {isFollowed &&
                !isSubscribed &&
                !isBlocked &&
                !isMe &&
                !isOfflineChat && (
                  <li
                    className={styles.item}
                    onTouchStart={emptyFunction}
                    onClick={handleUnfollowClick}
                    data-testid="profile-unfollow-menu-item"
                  >
                    <FormattedMessage
                      id="modal.unfollow.title"
                      defaultMessage="Unfollow"
                    />
                  </li>
                )}
              {isMiniProfile && (
                <li
                  onClick={handleShareClick}
                  onTouchStart={emptyFunction}
                  className={styles.item}
                >
                  <FormattedMessage {...sharedMessages.shareProfile} />
                </li>
              )}
              {isMiniProfile && isBroadcastScreen && isKickOutEnabled && (
                <li
                  onClick={handleKickClick}
                  onTouchStart={emptyFunction}
                  className={styles.item}
                >
                  <FormattedMessage {...sharedMessages.kickOut} />
                </li>
              )}
              {!isMe && !isOfflineChat && (
                <li
                  className={styles.item}
                  onTouchStart={emptyFunction}
                  onClick={handleReportClick}
                  data-testid="profile-report-menu-item"
                >
                  <FormattedMessage {...sharedMessages.report} />
                </li>
              )}
              {!isMe && (
                <li
                  className={styles.item}
                  onTouchStart={emptyFunction}
                  onClick={handleBlockOrUnblockClick}
                  data-testid="profile-block-menu-item"
                >
                  <FormattedMessage
                    {...sharedMessages[
                      isBlocked ? "unblockUserTitle" : "blockUserTitle"
                    ]}
                  />
                </li>
              )}
              {!!isChatRequestMatch && isChatRequest && (
                <li
                  onClick={handleDeleteChat}
                  onTouchStart={emptyFunction}
                  className={styles.item}
                  data-testid="delete-chat-menu-item"
                >
                  <FormattedMessage {...sharedMessages.deleteConversation} />
                </li>
              )}
            </>
          )}
        </Typography>
      </CSSTransition>
    </div>
  );
};

export default memo(MoreMenu);
