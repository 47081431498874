import { createAction } from "@reduxjs/toolkit";
import { GoalStickerItemType } from "@analytics/enums";
import { Gift } from "src/features/broadcastLobby/common/types";
import { StreamKind } from "src/types/richFragment/Stream";
import { MultiBroadcastInvite } from "state/tree/broadcast";

interface BroadcastPicturePayload {
  broadcastPictureThumbnailUrl: string;
  broadcastPictureUrl: string;
}

export const broadcastReset = createAction("lwc/broadcast/reset");
export const broadcastResetOneToOne = createAction(
  "lwc/broadcast/resetOneToOne"
);
export const broadcastInitStarted = createAction<{
  broadcastKind: StreamKind;
  broadcastSource: string;
}>("lwc/broadcast/init/started");
export const broadcastInitSucceeded = createAction<{
  broadcastId: string;
  broadcastKey: string;
}>("lwc/broadcast/init/succeeded");
export const broadcastInitFailed = createAction(
  "lwc/broadcast/init/failed",
  (error: Error) => ({ payload: error, error: true })
);
export const broadcastInitUserBan = createAction<number>(
  "lwc/broadcast/init/userBan"
);
export const broadcastBadPicture = createAction(
  "lwc/broadcast/init/badPicture"
);
export const broadcastTerminateStarted = createAction(
  "lwc/broadcast/terminate/started"
);
export const broadcastTerminateSucceeded = createAction(
  "lwc/broadcast/terminate/succeeded"
);
export const broadcastGetInvite = createAction<MultiBroadcastInvite>(
  "lwc/broadcast/getInvite"
);
export const broadcastClearInvite = createAction("lwc/broadcast/clearInvite");
export const broadcastTerminateFailed = createAction(
  "lwc/broadcast/terminate/failed",
  (error: Error) => ({
    payload: error,
    error: true,
  })
);
export const broadcastPictureUploadStarted =
  createAction<BroadcastPicturePayload>("lwc/broadcast/pictureUpload/started");
export const broadcastPictureUploadSucceeded =
  createAction<BroadcastPicturePayload>(
    "lwc/broadcast/pictureUpload/succeeded"
  );
export const broadcastPictureUploadFailed = createAction(
  "lwc/broadcast/pictureUpload/failed",
  (
    error: Error,
    meta: {
      prevBroadcastPictureThumbnailUrl?: string;
      prevBroadcastPictureUrl?: string;
    }
  ) => ({
    payload: error,
    error: true,
    meta,
  })
);
export const broadcastTitleChanged = createAction<string>(
  "lwc/broadcast/title/changed"
);

export const broadcastToggleVideo = createAction("lwc/broadcast/toggleVideo");
export const broadcastToggleAudio = createAction("lwc/broadcast/toggleAudio");
export const broadcastToggleScreenShare = createAction(
  "lwc/broadcast/toggleScreenShare"
);

export const broadcastToggleGiftSounds = createAction(
  "lwc/broadcast/toggleGiftSounds"
);

export const clearStartedPlayingPips = createAction(
  "lwc/broadcast/clearStartedPlayingPips"
);

export const markPipAsStartedPlaying = createAction<string>(
  "lwc/broadcast/markPipAsStartedPlaying"
);

export const mobileMediaPermissionsGranted = createAction<boolean>(
  "lwc/broadcast/mobileMediaPermissionsGranted"
);

export const broadcastTransformToOneToOne = createAction<string>(
  "lwc/broadcast/transformToOneToOne"
);
export const setGoalStickerItemType = createAction<GoalStickerItemType>(
  "lwc/broadcast/setGoalStickerItemType"
);

export const broadcastUpdateGift = createAction<Gift | undefined>(
  "lwc/broadcast/broadcastUpdateGift"
);

export const resetPipMutedState = createAction(
  "lwc/broadcast/resetPipMutedState"
);

export const setPipMutedState = createAction<{
  muted: boolean;
  streamId: string;
}>("lwc/broadcast/setPipMutedState");
