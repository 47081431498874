import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import FormattedAudioTime from "chat/components/currentConversation/components/VoiceMessage/FormattedAudioTime";
import { setupCanvas } from "chat/components/currentConversation/components/VoiceMessage/canvasUtils";
import { drawBars } from "chat/components/currentConversation/components/VoiceMessage/drawBars";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import { VoidCallback } from "chat/imports/types";
import styles from "./AudioWaveForm.scss";

enum WAVE_COLORS {
  BLACK = "#181A21",
  TETRIARY_DARK = "#5f5f5f",
  TETRIARY_LIGHT = "#BFC1C5",
  WHITE = "#fff",
  WHITE_SHADER = "#A6AFFF",
}

interface AudioWaveformProps {
  audioUrl: string | undefined;
  barValues?: number[];
  height?: number;
  isDark?: boolean;
  isMyMessage?: boolean;
  isPlaying?: boolean;
  onAudioEnded?: VoidCallback;
  width?: number;
}

const AudioWaveform: FC<AudioWaveformProps> = ({
  audioUrl,
  barValues = [],
  width = 192,
  height = 20,
  isDark = false,
  isMyMessage = false,
  isPlaying = false,
  onAudioEnded,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [duration, setDuration] = useState<null | number>(null);
  const [currentTime, setCurrentTime] = useState(0);

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration || 0);
    }
  };

  const drawWaveform = useCallback(
    (time: number) => {
      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const ctx = setupCanvas(canvas, width, height);
      if (!ctx) {
        return;
      }

      ctx.clearRect(0, 0, width, height);

      if (barValues.length === 0) {
        return;
      }

      const safeDuration = duration ?? 0;
      const progressRatio = safeDuration > 0 ? time / safeDuration : 0;
      const progressX = progressRatio * width;

      const tetriary = isDark
        ? WAVE_COLORS.TETRIARY_DARK
        : WAVE_COLORS.TETRIARY_LIGHT;
      const playedColor =
        isMyMessage || isDark ? WAVE_COLORS.WHITE : WAVE_COLORS.BLACK;
      const unplayedColor = isMyMessage ? WAVE_COLORS.WHITE_SHADER : tetriary;

      ctx.fillStyle = unplayedColor;
      drawBars(ctx, barValues, width, height);

      ctx.save();
      ctx.beginPath();
      ctx.rect(0, 0, progressX, height);
      ctx.closePath();
      ctx.clip();

      ctx.fillStyle = playedColor;
      drawBars(ctx, barValues, width, height);
      ctx.restore();
    },
    [barValues, duration, height, isDark, isMyMessage, width]
  );

  const handleAudioEnded = useCallback(() => {
    if (!audioRef.current) {
      return;
    }

    audioRef.current.currentTime = 0;
    audioRef.current.pause();

    setCurrentTime(0);
    drawWaveform(0);

    if (onAudioEnded) {
      onAudioEnded();
    }
  }, [drawWaveform, onAudioEnded]);

  useEffect(() => {
    let frameId = 0;

    const tick = () => {
      if (!audioRef.current) {
        return;
      }

      const time = audioRef.current.currentTime;

      setCurrentTime(time);

      if (duration === null) {
        frameId = requestAnimationFrame(tick);

        return;
      }

      drawWaveform(time);

      if (duration > 0 && time >= duration) {
        handleAudioEnded();
      } else {
        frameId = requestAnimationFrame(tick);
      }
    };

    if (isPlaying && audioRef.current) {
      frameId = requestAnimationFrame(tick);
    } else {
      drawWaveform(currentTime);
    }

    return () => {
      cancelAnimationFrame(frameId);
    };
  }, [isPlaying, duration, drawWaveform, currentTime, handleAudioEnded]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    if (isPlaying) {
      audioRef.current.play().catch(emptyFunction);
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  const handleCanvasClick = (event: MouseEvent<HTMLCanvasElement>) => {
    if (!canvasRef.current || !audioRef.current || !duration || duration <= 0) {
      return;
    }

    const rect = canvasRef.current.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const ratio = clickX / width;

    audioRef.current.currentTime = ratio * duration;
  };

  const timeLeft = duration !== null ? Math.max(0, duration - currentTime) : 0;

  return (
    <div
      className={classnames(styles.waveform, {
        [styles.isMyMessage]: isMyMessage,
      })}
    >
      {duration !== null && (
        <Typography type={TYPOGRAPHY_TYPE.MINI} className={styles.time}>
          <FormattedAudioTime seconds={timeLeft} />
        </Typography>
      )}

      {audioUrl && (
        <audio
          ref={audioRef}
          src={audioUrl}
          onLoadedMetadata={handleLoadedMetadata}
          controls={false}
          className={styles.audio}
          autoPlay={false}
        />
      )}

      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        onClick={handleCanvasClick}
        className={styles.canvas}
      />
    </div>
  );
};

export default AudioWaveform;
