import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import {
  MessageAction,
  emitMessageActionEvent,
} from "chat/analytics/emitMessageActionEvent";
import { ReactComponent as AttachmentIcon } from "chat/assets/ic_attachment_32.svg";
import { ReactComponent as SendIcon } from "chat/assets/ic_send_24.svg";
import { messages } from "chat/components/common/messages";
import { ConversationBarMessageInfo } from "chat/components/currentConversation/components/ConversationBarMessageInfo/ConversationBarMessageInfo";
import { CHAT_MAX_TEXT_LENGTH } from "chat/constants";
import { useEditAndSendTextMessage } from "chat/hooks/useEditAndSendTextMessage";
import { GiftIcon } from "chat/imports/assets";
import {
  Button,
  EmojiPickerButton,
  GiftActionButton,
  GrowingTextArea,
  Typography,
} from "chat/imports/components";
import {
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  linkToChat,
  linkToMessageRequest,
} from "chat/imports/constants";
import { getIsChatMediaEnabled } from "chat/imports/environment";
import {
  useAutoFocusSwitch,
  useBreakpointMobileLayout,
  useBreakpointPrecise,
} from "chat/imports/hooks";
import {
  RootState,
  getMoodsV2ForChatEnabled,
  isSystemChat,
  userSelectors,
} from "chat/imports/state";
import { AccountInfo } from "chat/imports/types";
import { sharedMessages } from "chat/imports/utils";
import { DisclaimersArea } from "chat/messageRequest/exports/components";
import { removeRequestsConversation } from "chat/messageRequest/state/asyncAction";
import { sendTextMessage } from "chat/state/actionCreators";
import { StoredConversation } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import {
  ChatMessageAnalyticsParams,
  ChatMessageSentFlags,
  ConversationState,
  MessageType,
} from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import { MosInChat } from "src/features/chat/components/Mos/MosInChat";
import {
  DELIMITERS_REGEX,
  DESKTOP_INPUT_BAR_HEIGHT_DEFAULT,
  DESKTOP_ROWS_COUNT_DEFAULT,
  MOBILE_INPUT_BAR_HEIGHT_DEFAULT,
  MOBILE_ROWS_COUNT_DEFAULT,
} from "./constants";
import styles from "./ConversationInputBar.scss";

interface ConversationInputBarProps {
  accountInfo?: AccountInfo;
  analyticsParams: ChatMessageAnalyticsParams;
  autoFocus?: boolean;
  companionId?: string;
  conversationId: string;
  isBlocked?: boolean;
  isChatRequestDisclaimerHidden: boolean;
  isConversationLoading?: boolean;
  isConversationLoadingFailed?: boolean;
  isHidden: boolean;
  isStateMessageRequest: boolean;
  openFileManager: VoidFunction;
  setIsChatRequestDisclaimerHidden: (state: boolean) => void;
  setIsRequestsVisible: (state: boolean) => void;
  state: StoredConversation["state"];
}

const selector = (state: RootState) => ({
  myAccountId: userSelectors.getMyAccountId(state),
  editingMessageInProgress: chatSelectors.getEditingMessageInProgress(state),
});

const ConversationInputBar: FC<ConversationInputBarProps> = ({
  conversationId,
  autoFocus,
  companionId,
  setIsRequestsVisible,
  openFileManager,
  isStateMessageRequest,
  isConversationLoadingFailed = false,
  isBlocked = false,
  isHidden,
  accountInfo,
  state,
  isChatRequestDisclaimerHidden,
  setIsChatRequestDisclaimerHidden,
  isConversationLoading = false,
  analyticsParams,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const { formatMessage } = useIntl();
  const { myAccountId, editingMessageInProgress } = useSelector(
    selector,
    shallowEqual
  );
  const [value, setValue] = useState("");
  const isChatMediaEnabled = getIsChatMediaEnabled();
  const isMobile = useBreakpointMobileLayout();
  const isDesktop = Breakpoints.DESKTOP === breakpoint;
  const history = useHistory();
  const { rootRef, textAreaRef, onPickerVisibilityChange } =
    useAutoFocusSwitch(autoFocus);
  const editTextMessage = useEditAndSendTextMessage({
    value,
    setValue,
    conversationId,
    textAreaRef,
  });
  const messagesRequestChatMatch = useRouteMatch(
    `${linkToMessageRequest}/${conversationId}`
  );

  const clearValue = () => setValue("");

  const isGroupChat = isGroupChatId(conversationId);
  const isChatRequest = state === ConversationState.CHAT_REQUEST;

  const conversationLatestMessage = useSelector(
    useCallback(
      (state: RootState) =>
        chatSelectors.getConversationLatestMessage(state, conversationId),
      [conversationId]
    ),
    shallowEqual
  );

  const isConversationByLatestMessageBlocked = useMemo(
    () =>
      conversationLatestMessage?.from === myAccountId &&
      conversationLatestMessage?.type === MessageType.GROUP_MEMBER_LEAVE,
    [myAccountId, conversationLatestMessage]
  );

  useEffect(() => {
    setIsChatRequestDisclaimerHidden(!isStateMessageRequest);
  }, [conversationId, isStateMessageRequest]);

  useEffect(() => {
    if (
      isChatRequestDisclaimerHidden &&
      messagesRequestChatMatch &&
      !isStateMessageRequest &&
      !isConversationLoading &&
      !isChatRequest
    ) {
      dispatch(removeRequestsConversation(conversationId));
      history.push(`${linkToChat}/${conversationId}`);
      setIsRequestsVisible(false);
    }
  }, [
    isStateMessageRequest,
    isChatRequestDisclaimerHidden,
    conversationId,
    state,
    isConversationLoading,
    isChatRequest,
    messagesRequestChatMatch,
  ]);

  const disabled =
    isConversationLoadingFailed ||
    isBlocked ||
    isConversationByLatestMessageBlocked;

  const sendText = useCallback(() => {
    if (editingMessageInProgress) {
      editTextMessage();

      emitMessageActionEvent({
        action: MessageAction.EDIT_MESSAGE_BUTTON,
        chatId: analyticsParams.chatId,
        flag: ChatMessageSentFlags.TEXT,
      });

      return;
    }

    setValue("");
    dispatch(
      sendTextMessage({
        conversationId,
        body: value,
        from: myAccountId,
        formatMessage,
        analyticsParams,
      })
    );
    if (isStateMessageRequest) {
      setIsChatRequestDisclaimerHidden(true);
    }
  }, [
    setIsRequestsVisible,
    editingMessageInProgress,
    dispatch,
    conversationId,
    value,
    myAccountId,
    formatMessage,
    isStateMessageRequest,
    analyticsParams,
    history,
  ]);

  const onKeyDown = useCallback(
    ({ key, shiftKey }: KeyboardEvent<HTMLTextAreaElement>) => {
      if (key === "Enter" && !shiftKey && !!value) {
        sendText();
      }
    },
    [sendText, value]
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) =>
      setValue(
        e.target.value
          .replace(DELIMITERS_REGEX, "")
          .substring(0, CHAT_MAX_TEXT_LENGTH)
      ),
    []
  );

  useEffect(() => {
    if (disabled) {
      setValue("");
    }
  }, [disabled, setValue]);

  const onInputContainerClicked = useCallback(() => {
    textAreaRef.current?.focus();
  }, []);

  const handlePickEmoji = useCallback(
    (emojiData) => {
      setValue((prevValue) => {
        if (
          emojiData.native.length + prevValue.length <=
          CHAT_MAX_TEXT_LENGTH
        ) {
          return prevValue + emojiData.native;
        }

        return prevValue;
      });
      textAreaRef.current?.focus();
    },
    [textAreaRef]
  );

  const style = useMemo(
    () => ({
      height: isDesktop
        ? DESKTOP_INPUT_BAR_HEIGHT_DEFAULT
        : MOBILE_INPUT_BAR_HEIGHT_DEFAULT,
    }),
    [isDesktop]
  );

  const buttonSize = isDesktop
    ? ButtonSize.CIRCLE_MEDIUM_40
    : ButtonSize.CIRCLE_SMALL_32;

  const blockedUserMessage = isGroupChatId(conversationId)
    ? sharedMessages.blockedUserInChat
    : sharedMessages.blockedUser;

  const isChatRequestDisclaimerShown =
    isChatRequest && isHidden && !isChatRequestDisclaimerHidden;
  const isCoinChatRequestDisclaimerShown =
    isChatRequest && !isGroupChat && !isHidden;

  const isMosChatSOCEnabled = useSelector(getMoodsV2ForChatEnabled);
  const isSystemConversation = useSelector((state: RootState) =>
    isSystemChat(state, conversationId)
  );

  const isMosChatEnabled =
    isMosChatSOCEnabled &&
    !isSystemConversation &&
    !isGroupChat &&
    !isBlocked &&
    companionId;

  return (
    <div
      className={classnames(styles.root, styles[breakpoint], {
        [styles.isDisclaimerVisible]:
          isCoinChatRequestDisclaimerShown || isChatRequestDisclaimerShown,
      })}
      ref={rootRef}
      data-testid="conversation-input-bar"
    >
      {!isConversationLoading && (
        <DisclaimersArea
          conversationId={conversationId}
          accountInfo={accountInfo}
          isChatRequestDisclaimerShown={isChatRequestDisclaimerShown}
          isCoinChatRequestDisclaimerShown={isCoinChatRequestDisclaimerShown}
        />
      )}
      {(isBlocked || isConversationByLatestMessageBlocked) && isMobile ? (
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH2}
          className={styles.title}
          as="div"
        >
          <FormattedMessage {...blockedUserMessage} />
        </Typography>
      ) : (
        <div className={styles.container}>
          {isMosChatEnabled && (
            <MosInChat
              conversationId={conversationId}
              companionId={companionId}
            />
          )}
          <ConversationBarMessageInfo clearValue={clearValue} />
          <div className={styles.wrapper}>
            {!isDesktop && isChatMediaEnabled && (
              <div className={styles.attachmentContainer}>
                <Button
                  disabled={disabled}
                  size={ButtonSize.CIRCLE_SMALL_32}
                  variant={ButtonVariant.ICON_ONLY}
                  onClick={disabled ? undefined : openFileManager}
                  className={classnames(styles.btn, styles.attachment)}
                  data-testid="attach-content-button"
                >
                  <AttachmentIcon />
                </Button>
              </div>
            )}
            <label
              className={classnames(styles.inputContainer, {
                [styles.nonEmptyValue]: value.length,
                [styles.disabled]: disabled,
              })}
              onClick={onInputContainerClicked}
            >
              <GrowingTextArea
                className={classnames(styles.input, {
                  [styles.disabled]: disabled,
                })}
                disabled={disabled}
                placeholder={formatMessage(messages.placeholder)}
                onChange={disabled ? undefined : onChange}
                onKeyDown={disabled ? undefined : onKeyDown}
                value={value}
                maxRows={
                  isDesktop
                    ? DESKTOP_ROWS_COUNT_DEFAULT
                    : MOBILE_ROWS_COUNT_DEFAULT
                }
                style={style}
                data-testid="textarea"
                ref={textAreaRef}
              />
              <EmojiPickerButton
                disabled={disabled}
                onPickEmoji={handlePickEmoji}
                onPickerVisibilityChange={onPickerVisibilityChange}
                className={classnames(styles.emoji, styles.btn)}
                pickerWrapperClassName={styles.pickerWrapper}
                data-testid="show-emoji-picker"
              />
            </label>
            <div className={styles.buttons}>
              {value && (
                <Button
                  disabled={disabled}
                  className={styles.btn}
                  size={ButtonSize.CIRCLE_MEDIUM_40}
                  variant={ButtonVariant.PRIMARY}
                  onClick={disabled ? undefined : sendText}
                  data-testid="chat-send-message-button"
                >
                  <SendIcon />
                </Button>
              )}
              {isDesktop && !value && isChatMediaEnabled && (
                <Button
                  disabled={disabled}
                  size={buttonSize}
                  variant={ButtonVariant.ICON_ONLY}
                  onClick={disabled ? undefined : openFileManager}
                  className={classnames(styles.btn, styles.attachment)}
                  data-testid="attach-content-button"
                >
                  <AttachmentIcon />
                </Button>
              )}
              {!value && companionId && (
                <GiftActionButton
                  disabled={disabled}
                  className={classnames(styles.btn, styles.giftButton)}
                  size={buttonSize}
                  variant={ButtonVariant.ICON_ONLY}
                  data-testid="gift-button"
                >
                  <GiftIcon />
                </GiftActionButton>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ConversationInputBar);
