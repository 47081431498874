import React, { FC, createContext, useContext } from "react";
import { MessageDescriptor } from "react-intl";

interface PauseDisclaimerContextValue {
  pauseMessage?: MessageDescriptor;
}

interface PauseDisclaimerProviderProps {
  value: PauseDisclaimerContextValue;
}

const PauseDisclaimerContext = createContext<PauseDisclaimerContextValue>({});

export const PauseDisclaimerProvider: FC<PauseDisclaimerProviderProps> = ({
  children,
  value,
}) => (
  <PauseDisclaimerContext.Provider value={value}>
    {children}
  </PauseDisclaimerContext.Provider>
);

export const usePauseDisclaimerContext = () =>
  useContext(PauseDisclaimerContext);
