import { AnyAction, Dispatch } from "redux";
import { GetConversationsResponse } from "chat/api/chat";
import { CHAT_CONVERSATIONS_LIST_REFRESH_MESSAGES_LIMIT } from "chat/constants";
import { Direction } from "chat/enums";
import { NotificationType } from "chat/imports/constants";
import {
  ContentSharedPayload,
  parseMessageFromBase64,
} from "chat/imports/proto";
import {
  RootState,
  batchLoadProfiles,
  notificationActionCreators,
  userSelectors,
} from "chat/imports/state";
import { AppDispatch, WithRequired } from "chat/imports/types";
import {
  sendPremiumMediaMessage,
  sendPremiumMessage,
} from "chat/premiumMessage/state/asyncAction";
import {
  ContentSharedPayloadMessage,
  ContentType,
  PlatformType,
} from "chat/premiumMessage/types";
import { getIsPinChatEnabled } from "chat/soc/chatSoc";
import {
  BaseMessagePayload,
  fetchConversationsRefresh,
  sendImageMessage,
  sendTextMessage,
  sendVideoMessage,
} from "chat/state/actionCreators";
import { StoredMessage } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import {
  ChatMessageAnalyticsParams,
  ChatMessageSentFlags,
  Message,
  MessageType,
} from "chat/types";
import { getVideoInfo } from "chat/utils/getVideoInfo";
import { getAmountOfChatMessagesToFetchAfterReconnect } from "environment";

interface RetryMessageParams {
  analyticsParams: ChatMessageAnalyticsParams;
  formatMessage: BaseMessagePayload["formatMessage"];
  message: StoredMessage;
}

export const retryMessage =
  ({ message, formatMessage, analyticsParams }: RetryMessageParams) =>
  (dispatch: AppDispatch) => {
    const {
      type,
      media,
      id: { chat_id, requestId },
      from,
      body,
    } = message;

    if (!chat_id || !from || !requestId) {
      return;
    }

    if (
      type === MessageType.IMAGE_MESSAGE &&
      media?.[0]?.download_url != null
    ) {
      return dispatch(
        sendImageMessage({
          formatMessage,
          imageUrl: media[0].download_url,
          conversationId: chat_id,
          from,
          retryId: requestId,
          analyticsParams,
        })
      );
    }

    if (type === MessageType.TEXT_MESSAGE && body != null) {
      return dispatch(
        sendTextMessage({
          formatMessage,
          body,
          conversationId: chat_id,
          from,
          retryId: requestId,
          analyticsParams,
        })
      );
    }

    if (type === MessageType.PREMIUM_MESSAGE_SHARED) {
      const { giftId, type, mediaInfo } = parseMessageFromBase64(
        message.payload,
        ContentSharedPayload
      ) as ContentSharedPayloadMessage;

      // parseMessageFromBase64 returns `type` as number 0 | 1, thus converting it to "PHOTO" | "VIDEO"
      const contentType = [ContentType.PHOTO, ContentType.VIDEO][
        type as unknown as number
      ];

      return dispatch(
        sendPremiumMessage({
          messageId: requestId,
          conversationId: chat_id,
          recipientId: chat_id,
          platformType: PlatformType.WEB,
          from,
          giftId,
          items: [
            {
              type: contentType,
              mediaInfo,
            },
          ],
          retryId: requestId,
        })
      );
    }
  };

export const refreshConversations =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState();
      const currentConversations = chatSelectors.getConversations(state);
      const shouldUpdateBatchOfMessages =
        chatSelectors.getIsNeededToFetchBatchOfMessages(state);
      const isPinChatEnabled = getIsPinChatEnabled(state);
      const pinnedChatsCount = chatSelectors.getPinnedConversationsCount(state);
      const myAccountId = userSelectors.getMyAccountId(state);
      let latestMessageTimestamp =
        currentConversations[0]?.last_message_ts || 0;

      if (isPinChatEnabled && pinnedChatsCount) {
        const pinnedConversations = currentConversations.slice(
          0,
          pinnedChatsCount
        );

        const firstUnpinnedConversation =
          currentConversations[pinnedChatsCount];

        const latestPinnedMessageTimestamp = pinnedConversations.reduce(
          (ts, chat) => Math.max(ts, chat.last_message_ts || 0),
          0
        );

        const latestUnPinnedMessageTimestamp =
          firstUnpinnedConversation?.last_message_ts || 0;

        latestMessageTimestamp = Math.max(
          latestPinnedMessageTimestamp,
          latestUnPinnedMessageTimestamp
        );
      }

      const { conversations } = (await dispatch(
        fetchConversationsRefresh({
          direction: Direction.FORWARD,
          last_update_request_timestamp: latestMessageTimestamp,
          include_group_members: true,
          include_messages: true,
          include_group_info: true,
          include_account_info: true,
          limit_messages_per_conversation: shouldUpdateBatchOfMessages
            ? getAmountOfChatMessagesToFetchAfterReconnect()
            : CHAT_CONVERSATIONS_LIST_REFRESH_MESSAGES_LIMIT,
          isRefreshing: true,
        }) as unknown as AnyAction
      ).unwrap()) as GetConversationsResponse;

      if (!conversations) {
        return;
      }

      const notifications = conversations.reduce(
        (
          acc: {
            accountId: string;
            id: string;
            message: Message;
            peerId: string;
            type: NotificationType.TC_MESSAGE;
          }[],
          conversation
        ) => {
          const {
            messages,
            conversation: { hidden, conversation_id, muted_message_ts },
          } = conversation;

          if (hidden || !messages || muted_message_ts) {
            return acc;
          }

          const msg = messages.find(
            (message) =>
              !!message.from &&
              message.from !== myAccountId &&
              message.id.ts > latestMessageTimestamp &&
              message.type !== MessageType.NORMAL_CALL_MESSAGE
          ) as WithRequired<Message, "from">;

          if (!msg) {
            return acc;
          }

          acc.push({
            id: `message-${conversation_id}`,
            type: NotificationType.TC_MESSAGE,
            accountId: msg.from,
            message: msg,
            peerId: conversation_id,
          });

          return acc;
        },
        []
      );

      if (!notifications.length) {
        return;
      }

      await dispatch(
        batchLoadProfiles({
          ids: notifications.map((notification) => notification.accountId),
          loadOnlyIfMissing: true,
        }) as unknown as AnyAction
      );

      notifications.forEach((notification) => {
        dispatch(notificationActionCreators.addNotification(notification));
      });
    } catch (e) {
      // noop
    }
  };

interface MediaMessage
  extends Pick<BaseMessagePayload, "formatMessage" | "from"> {
  analyticsParams: ChatMessageAnalyticsParams;
  caption: string;
  conversationId: string;
  giftId?: string;
  mediaFile: File;
  mediaType: ContentType;
  mediaUrl: string;
}

export const sendMediaMessage =
  ({
    conversationId,
    mediaFile,
    mediaType,
    mediaUrl,
    caption,
    from,
    formatMessage,
    giftId,
    analyticsParams,
  }: MediaMessage) =>
  async (dispatch: AppDispatch) => {
    if (giftId) {
      await dispatch(
        sendPremiumMediaMessage({
          conversationId,
          mediaFile,
          mediaType,
          mediaUrl,
          from,
          formatMessage,
          giftId,
        })
      );
    } else {
      if (mediaType === ContentType.PHOTO) {
        await dispatch(
          sendImageMessage({
            conversationId,
            imageUrl: mediaUrl,
            from,
            formatMessage,
            analyticsParams,
          })
        );
      }

      if (mediaType === ContentType.VIDEO) {
        const mediaData = await getVideoInfo(mediaFile, true);
        await dispatch(
          sendVideoMessage({
            conversationId,
            mediaFile,
            from,
            formatMessage,
            analyticsParams,
            mediaData,
          })
        );
      }
    }

    if (caption) {
      await dispatch(
        sendTextMessage({
          conversationId,
          body: caption,
          from,
          formatMessage,
          analyticsParams: {
            ...analyticsParams,
            flags: [ChatMessageSentFlags.TEXT],
          },
        })
      );
    }
  };
