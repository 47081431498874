import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { sort } from "src/utils/immutableArrayUtils";
import batchLoadProfiles from "state/flows/batchLoadProfiles";
import { viewerSessionSelectors } from "state/selectors";

const GAME_STATE = {
  GAME_STATE_CANCELLED: "GAME_STATE_CANCELLED",
  GAME_STATE_COMPLETED: "GAME_STATE_COMPLETED",
  GAME_STATE_STARTED: "GAME_STATE_STARTED",
};

export const sortPlayers = (players = [], firstPlayerId) => {
  if (!firstPlayerId || !players.length) {
    return players;
  }

  return sort(players, (a, b) => b.amount - a.amount).reduce((acc, player) => {
    if (player.leftGame) {
      return acc;
    }
    if (player.accountId !== firstPlayerId) {
      acc.push(player);
    } else {
      acc.unshift(player);
    }

    return acc;
  }, []);
};

const selector = (state) => {
  const broadcasterId = viewerSessionSelectors.getBroadcasterId(state);
  const competition =
    viewerSessionSelectors.getCompetitionByPlayerId(state, broadcasterId) || {};

  const {
    gameInfo: {
      gameType,
      stateEnum,
      countdownSeconds,
      gameId,
      finishMillis,
    } = {},
    gameEvents = {},
  } = competition;

  const { players, winner } = gameEvents;

  const isCompetitionFinished = [
    GAME_STATE.GAME_STATE_CANCELLED,
    GAME_STATE.GAME_STATE_COMPLETED,
  ].includes(stateEnum);

  return {
    isCompetitionLive:
      gameType === "GAME_COMPETITION" &&
      stateEnum === GAME_STATE.GAME_STATE_STARTED &&
      !!players?.length,
    isCompetitionFinished,
    players: sortPlayers(players, broadcasterId),
    competitionCountdown: countdownSeconds,
    winner,
    gameId,
    finishMillis,
  };
};

const getIsCompetitionLiveSelector = (state) => {
  const broadcasterId = viewerSessionSelectors.getBroadcasterId(state);
  const competition =
    viewerSessionSelectors.getCompetitionByPlayerId(state, broadcasterId) || {};

  const { gameInfo: { gameType, stateEnum } = {}, gameEvents = {} } =
    competition;

  const { players } = gameEvents;

  return (
    gameType === "GAME_COMPETITION" &&
    stateEnum === "GAME_STATE_STARTED" &&
    !!players?.length
  );
};

export const useIsCompetitionLive = () =>
  useSelector(getIsCompetitionLiveSelector);

export default () => {
  const dispatch = useDispatch();
  const {
    isCompetitionLive,
    isCompetitionFinished,
    players,
    competitionCountdown,
    winner,
    gameId,
    finishMillis,
  } = useSelector(selector, shallowEqual);

  const accountIds = players.map((player) => player.accountId);
  const [p1Id, p2Id, p3Id, p4Id] = accountIds;

  useEffect(() => {
    dispatch(
      batchLoadProfiles({
        ids: accountIds,
        loadOnlyIfMissing: true,
      })
    );
  }, [p1Id, p2Id, p3Id, p4Id]);

  return {
    isCompetitionLive,
    isCompetitionFinished,
    players,
    competitionCountdown,
    winner,
    gameId,
    finishMillis,
    isCompetitionStarted: isCompetitionLive && players.length > 1,
  };
};
