import { typedDelegateSelectors } from "src/features/broadcastExternal/imports/state";
import {
  BroadcastMasksState,
  initialState,
} from "src/features/broadcastMasks/state/slice";

export const localBroadcastMasksSelectors = {
  getConfig: (state: BroadcastMasksState) => state.config,
  getMask: (state: BroadcastMasksState) => state.selectedMask,
  getPlayer: (state: BroadcastMasksState) => state.player,
  getBeauty: (state: BroadcastMasksState) => state.beauty,
  getMakeup: (state: BroadcastMasksState) => state.makeup,
  getMobileViewType: (state: BroadcastMasksState) => state.mobileView,
  getBeautyChangesCount: (state: BroadcastMasksState) => {
    let differenceCount = 0;

    Object.keys(initialState.beauty).forEach((key) => {
      if (state.beauty[key] !== initialState.beauty[key]) {
        differenceCount++;
      }
    });

    Object.keys(state.beauty).forEach((key) => {
      if (!(key in initialState.beauty)) {
        differenceCount++;
      }
    });

    return differenceCount;
  },
  getLoadingCompleted: (state: BroadcastMasksState) => state.loadingCompleted,
};

export const broadcastMasksSelectors = typedDelegateSelectors(
  localBroadcastMasksSelectors,
  "broadcastMasks"
);
