import {
  connectionManagerSelectors,
  deviceInfoSelectors,
  loginSelectors,
  marketingSelectors,
  userSelectors,
} from "src/core/analytics/imports/selectors";
import { getAnalyticsPluginGatewayEnabled } from "src/core/analytics/imports/state";
import { getInMemoryStore } from "src/core/analytics/imports/utils";

export const getAccountId = () =>
  userSelectors.getMyAccountId(getInMemoryStore());

export const getIsLoggedIn = () =>
  loginSelectors.isLoggedIn(getInMemoryStore());

export const getDeviceInfo = () => ({
  type: deviceInfoSelectors.getDeviceType(getInMemoryStore()),
  browserName: deviceInfoSelectors.getBrowserName(getInMemoryStore()),
  browserVersion: deviceInfoSelectors.getBrowserVersion(getInMemoryStore()),
  locale: deviceInfoSelectors.getDeviceLocale(getInMemoryStore()),
  os: deviceInfoSelectors.getDeviceOS(getInMemoryStore()),
  osVersion: deviceInfoSelectors.getDeviceVersion(getInMemoryStore()),
});

export const getAnalyticsKey = () =>
  marketingSelectors.getAnalyticsKey(getInMemoryStore());

export const getUsername = () =>
  connectionManagerSelectors.getUsername(getInMemoryStore());

export const getAnalyticsPluginEnabled = () =>
  getAnalyticsPluginGatewayEnabled(getInMemoryStore());
