import { getProxycadorHost } from "src/features/giftsDrawer/imports/environment";
import { enhancedFetch } from "src/features/giftsDrawer/imports/utils";
import {
  GetMyGiftDrawerResponse,
  GiftDrawerRequest,
  GiftDrawerRequestDTO,
} from "./giftDrawerTypes";

export const fetchGiftDrawer = (
  request: GiftDrawerRequestDTO
): Promise<GetMyGiftDrawerResponse> => {
  const data: GiftDrawerRequest = {
    currentDrawerEncryptedId: request.drawerId,
    currentDrawerEncryptedVersion: request.drawerVersion,
    target: request.target,
  };

  return enhancedFetch(
    `${getProxycadorHost()}/gift-box/public/giftdrawer/v1/giftdrawer`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(data),
    }
  ).then((resp) => resp.json());
};
