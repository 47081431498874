import { GLSL, Shaders } from "gl-react";

export const SHADERS = Shaders.create({
  SplitColor: {
    frag: GLSL`
      precision mediump float;
      varying vec2 uv;
      uniform sampler2D video;
      uniform float scale;
      const float Y_OFFSET = 0.33;
        
      void main() {
        float scaledY = (uv.y - Y_OFFSET) * scale + Y_OFFSET;
        float halfX = uv.x * 0.5;
        
        vec4 colorMask = texture2D(video, vec2(halfX, scaledY));
        vec4 colorContent = texture2D(video, vec2(halfX + 0.5, scaledY));
        
        gl_FragColor = colorContent * colorMask.r;
      }`,
  },
  ImageOverlayed: {
    frag: GLSL`
      precision mediump float;
      varying vec2 uv;
      uniform sampler2D video;
      uniform sampler2D overlayTexture;
      uniform vec2 textureSize;

      void main() {
        vec2 maskUV = vec2(uv.x / 3.0, uv.y);
        vec2 contentUV = vec2((uv.x / 3.0) + 1.0/3.0, uv.y);
        vec2 overlayUV = vec2((uv.x / 3.0) + 2.0/3.0, uv.y);

        vec4 contentColor = texture2D(video, contentUV);

        vec2 contentPixel = contentUV * textureSize;
        vec2 overlayPixel = overlayUV * textureSize;

        const int iterations = 5;
        const float overlayStep = 12.0;
        const float overlayIterationStep = (2.0 * overlayStep) / float(iterations - 1);
        float overlayX = 0.0;
        float overlayY = 0.0;
        float startX = overlayPixel.x - overlayStep;
        float startY = overlayPixel.y - overlayStep;

        for (int ix = 0; ix < iterations; ix++) {
          float x = startX + float(ix) * overlayIterationStep;
          float clampedX = clamp(x, 0.0, textureSize.x - 1.0);
          for (int iy = 0; iy < iterations; iy++) {
            float y = startY + float(iy) * overlayIterationStep;
            float clampedY = clamp(y, 0.0, textureSize.y - 1.0);
            float sampleX = (clampedX / textureSize.x);
            float sampleY = (clampedY / textureSize.y);
            vec4 sampleColor = texture2D(video, vec2(sampleX, sampleY));
            overlayX += sampleColor.r;
            overlayY += sampleColor.g;
          }
        }
        float totalSamples = float(iterations * iterations);
        overlayX /= totalSamples;
        overlayY /= totalSamples;

        vec4 overlayColor = texture2D(overlayTexture, vec2(overlayX, (1.0 - overlayY)));

        vec4 maskColor = texture2D(video, maskUV);
        vec4 maskNW = texture2D(video, maskUV + vec2(-1.0/300.0, -1.0/300.0));
        vec4 maskNE = texture2D(video, maskUV + vec2( 1.0/300.0, -1.0/300.0));
        vec4 maskSW = texture2D(video, maskUV + vec2(-1.0/300.0,  1.0/300.0));
        vec4 maskSE = texture2D(video, maskUV + vec2( 1.0/300.0,  1.0/300.0));
        maskColor = max(max(maskColor, maskNW), max(maskNE, max(maskSW, maskSE)));
        maskColor.a = 1.0;

        vec4 finalColor = mix(contentColor, overlayColor, maskColor.g);
        finalColor.a = mix(contentColor.r, overlayColor.a, maskColor.g);
        gl_FragColor = finalColor;
      }
  `,
  },
});
