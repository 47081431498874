import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addUserToProfileBlockList,
  fetchProfileBlockList,
  removeUserFromProfileBlockList,
} from "src/api/profile";
import { isApiError } from "src/api/utils/enhancedFetch";
import { RootState } from "src/state/delegate";
import { BlockedUsersState } from "src/state/tree/blockedUsers";

export const loadBlockedUsers = createAsyncThunk<
  BlockedUsersState["data"]["accountIds"],
  void,
  { rejectValue: string; state: RootState }
>("lwc/blockedUsers/fetch", async (_, api) => {
  try {
    return await fetchProfileBlockList();
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const blockUserAction = createAsyncThunk<
  string | void,
  { accountId: string | undefined },
  { rejectValue: string; state: RootState }
>("lwc/blockedUsers/blockUser", async ({ accountId }, api) => {
  if (!accountId) {
    return;
  }

  try {
    await addUserToProfileBlockList({ accountId });

    return accountId;
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const unblockUserAction = createAsyncThunk<
  string,
  { accountId: string },
  { rejectValue: string; state: RootState }
>("lwc/blockedUsers/unblockUser", async ({ accountId }, api) => {
  try {
    await removeUserFromProfileBlockList({ accountId });

    return accountId;
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
