import { createSlice } from "@reduxjs/toolkit";
import { RedeemConfigResponse } from "src/features/finance/redeems/root/type";
import { loadRedeemConfigInfo } from "src/features/finance/redeems/state/asyncActions";

export interface RedeemConfigState {
  data: RedeemConfigResponse | null;
  error: null | string;
  loading: boolean;
}

const initialState: RedeemConfigState = {
  data: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "redeemConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRedeemConfigInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadRedeemConfigInfo.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(loadRedeemConfigInfo.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      });
  },
});

export const redeemConfigSlice = slice.reducer;
