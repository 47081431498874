import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Breakpoints } from "src/enums";
import { openNsfwContentBottomScreen } from "state/actionCreators/bottomScreen";
import { openNsfwContentModal } from "state/actionCreators/modal";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { linkToBasePath } from "ui/navigation/links";
import { useMount } from "utils/miniReactUse";
import styles from "./NsfwContentHidden.scss";

interface NsfwContentHiddenProps {
  redirectLink: string;
}

const NsfwContentHidden = ({ redirectLink }: NsfwContentHiddenProps) => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const dispatch = useDispatch();
  const history = useHistory();

  useMount(() => {
    if (isDesktop) {
      dispatch(
        openNsfwContentModal({
          redirectLink,
          shouldRedirectOnClose: true,
          onOutsideClickDismiss: () => {
            history.push(linkToBasePath);
          },
        })
      );
    } else {
      dispatch(
        openNsfwContentBottomScreen({
          redirectLink,
          shouldRedirectOnClose: true,
          onOutsideClickDismiss: () => {
            history.push(linkToBasePath);
          },
        })
      );
    }
  });

  return <div className={styles.root} />;
};

export default NsfwContentHidden;
