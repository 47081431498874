import { createReducer } from "@reduxjs/toolkit";
import { Story } from "src/types/happyMoments";
import {
  clearStories,
  deleteStory,
  forceStoriesSet,
  loadStories,
  resetGiftAnimation,
} from "state/actionCreators/stories";
import { GIFT_SENT, GIFT_SENT_FAILED } from "state/actionTypes";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";

export type StoriesState = AsyncState<{
  list: string[];
  map: Record<string, Story>;
  nextCursor: string;
  previousCursor: string;
  shouldRestartGiftAnimation?: boolean;
  wasFetched: boolean;
}>;

const initialState = {
  data: {
    list: [],
    map: {},
    nextCursor: "",
    previousCursor: "",
    shouldRestartGiftAnimation: false,
    wasFetched: false,
  },
  meta: initialFetcherStateMeta,
};

export const FREE_LIKE_ID = "free-gift";

const reducer = createReducer<StoriesState>(initialState, (builder) => {
  addAsyncCasesToBuilderV2({
    builder,
    action: loadStories,
    prepareData: (oldData, newData) => {
      oldData.list.push(...newData.list);
      oldData.map = { ...oldData.map, ...newData.map };
      oldData.nextCursor = newData.nextCursor;
      oldData.previousCursor = newData.previousCursor;
      oldData.wasFetched = true;

      return oldData;
    },
    initialData: initialState.data,
  });
  builder
    .addCase(resetGiftAnimation, (state, action) => {
      state.data.shouldRestartGiftAnimation = action.payload;
    })
    .addCase(deleteStory.fulfilled, (state, action) => {
      state.data.list = state.data.list.filter((id) => id !== action.meta.arg);
      delete state.data.map[action.meta.arg];
    })
    .addCase(forceStoriesSet, (state, { payload: stories }) => {
      state.data = {
        ...initialState.data,
        list: stories.map(({ storyId }) => storyId),
        map: stories.reduce((map: StoriesState["data"]["map"], story) => {
          map[story.storyId] = story;

          return map;
        }, {}),
      };
      state.meta = initialState.meta;
    })
    .addCase(clearStories, () => initialState)
    .addMatcher(
      (action) => action.type === GIFT_SENT,
      (state, action) => {
        const {
          payload: { momentId, giftId },
        } = action;

        const moment = state.data.map[momentId];

        if (giftId === FREE_LIKE_ID && moment) {
          moment.likesCount = (moment.likesCount || 0) + 1;
          moment.likedByMe = true;
        }
      }
    )
    .addMatcher(
      (action) => action.type === GIFT_SENT_FAILED,
      (state, action) => {
        const {
          payload: { momentId, giftId },
        } = action;

        const moment = state.data.map[momentId];

        if (giftId === FREE_LIKE_ID && moment) {
          moment.likesCount = moment.likesCount ? moment.likesCount - 1 : 0;
          moment.likedByMe = false;
        }
      }
    );
});

export default reducer;
