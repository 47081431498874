import { ClientSessionFields } from "src/core/analytics/dataMesh/common/enums";
import {
  getAnalyticsKey,
  getDeviceInfo,
  getUsername,
} from "src/core/analytics/dataMesh/utils/store/getStoreEntity";
import { Platforms } from "src/core/analytics/enums";
import { getInMemoryStore } from "src/core/analytics/imports/utils";

export const getSessionStartPayloadFromStore = () => {
  const inMemoryStore = getInMemoryStore();

  if (inMemoryStore) {
    const { type, browserName, browserVersion, locale, osVersion, os } =
      getDeviceInfo();
    const analyticsKey = getAnalyticsKey();
    const username = getUsername();

    return {
      [ClientSessionFields.DEVICE_CATEGORY]: type,
      [ClientSessionFields.PLATFORM]: Platforms.WEB,
      [ClientSessionFields.BROWSER]: browserName,
      [ClientSessionFields.BROWSER_VERSION]: browserVersion,
      [ClientSessionFields.DEVICE_LANGUAGE]: locale,
      [ClientSessionFields.USERNAME]: username,
      [ClientSessionFields.VISITOR_ID]: analyticsKey,
      [ClientSessionFields.TANGO_VERSION]: GENERATED_APP_INFO.fullVersion,
      [ClientSessionFields.OS]: os,
      [ClientSessionFields.OS_VERSION]: osVersion,
    };
  }

  return {};
};
