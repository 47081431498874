import React, { memo, useRef } from "react";
import { Nullable } from "src/types/common";
import useLazyImage from "ui/hooks/useLazyImage";
import styles from "./BackgroundImageFullScreenProps.scss";

interface BackgroundImageFullScreenProps {
  imageSrc: string;
}

const BackgroundImageFullScreen = ({
  imageSrc,
}: BackgroundImageFullScreenProps) => {
  const bgRef = useRef<Nullable<HTMLImageElement>>(null);
  useLazyImage(bgRef, imageSrc);

  return (
    <div className={styles.bgImageWrapper}>
      <img
        className={styles.picture}
        data-src={imageSrc}
        alt="stream-avatar"
        ref={bgRef}
      />
    </div>
  );
};

export default memo(BackgroundImageFullScreen);
