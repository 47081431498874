import { createReducer } from "@reduxjs/toolkit";
import { Nullable } from "src/types/common";
import {
  checkInVisitor,
  clearVisitorData,
  markVisitorDataStale,
} from "state/actionCreators/visitor";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";

interface VisitorData {
  analyticsKey: Nullable<string>;
  countryCode: string;
  isVisitor: boolean;
  visitorAnalyticsKey: Nullable<string>;
}

export const persistConfig = {
  whiteList: ["data"],
};

export type VisitorState = AsyncState<VisitorData, string>;

export const initialVisitorState = {
  data: {
    analyticsKey: null,
    visitorAnalyticsKey: null,
    countryCode: "",
    isVisitor: false,
  },
  meta: initialFetcherStateMeta,
};

export default createReducer<VisitorState>(initialVisitorState, (builder) => {
  builder
    .addCase(clearVisitorData, (state) => {
      state.data = {
        ...initialVisitorState.data,
        visitorAnalyticsKey: state.data.visitorAnalyticsKey,
      };

      state.meta = initialFetcherStateMeta;
    })
    .addCase(markVisitorDataStale, (state) => {
      state.meta.stale = true;
    });

  addAsyncCasesToBuilderV2({
    initialData: initialVisitorState.data,
    builder,
    action: checkInVisitor,
    prepareData: (oldData, newData) => newData,
  });
});
