import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GiftDrawerTarget } from "src/features/giftsDrawer/api/giftDrawerTypes";
import { getIsGiftsDrawerV2Enabled } from "src/features/giftsDrawer/giftsDrawerSoc";
import { loadGiftsDrawer } from "src/features/giftsDrawer/imports/asyncAction";

export const useLoadGiftsDrawer = (
  target?: GiftDrawerTarget,
  isFetchAlways?: boolean
) => {
  const dispatch = useDispatch();
  const isGiftsDrawerV2Enabled = useSelector(getIsGiftsDrawerV2Enabled);

  useEffect(() => {
    if ((isFetchAlways || isGiftsDrawerV2Enabled) && target) {
      dispatch(loadGiftsDrawer(target));
    }
  }, [dispatch, isFetchAlways, isGiftsDrawerV2Enabled, target]);
};
