/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.MultiBroadcastMute || ($protobuf.roots.MultiBroadcastMute = {});

export const MultiBroadcastMuteRequest = $root.MultiBroadcastMuteRequest = (() => {

    function MultiBroadcastMuteRequest(properties) {
        this.encryptedStreamIds = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MultiBroadcastMuteRequest.prototype.mbId = "";
    MultiBroadcastMuteRequest.prototype.encryptedStreamIds = $util.emptyArray;

    MultiBroadcastMuteRequest.create = function create(properties) {
        return new MultiBroadcastMuteRequest(properties);
    };

    MultiBroadcastMuteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.mbId != null && Object.hasOwnProperty.call(message, "mbId"))
            writer.uint32(10).string(message.mbId);
        if (message.encryptedStreamIds != null && message.encryptedStreamIds.length)
            for (let i = 0; i < message.encryptedStreamIds.length; ++i)
                writer.uint32(18).string(message.encryptedStreamIds[i]);
        return writer;
    };

    MultiBroadcastMuteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MultiBroadcastMuteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiBroadcastMuteRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.mbId = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.encryptedStreamIds && message.encryptedStreamIds.length))
                        message.encryptedStreamIds = [];
                    message.encryptedStreamIds.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MultiBroadcastMuteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MultiBroadcastMuteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiBroadcastMuteRequest)
            return object;
        let message = new $root.MultiBroadcastMuteRequest();
        if (object.mbId != null)
            message.mbId = String(object.mbId);
        if (object.encryptedStreamIds) {
            if (!Array.isArray(object.encryptedStreamIds))
                throw TypeError(".MultiBroadcastMuteRequest.encryptedStreamIds: array expected");
            message.encryptedStreamIds = [];
            for (let i = 0; i < object.encryptedStreamIds.length; ++i)
                message.encryptedStreamIds[i] = String(object.encryptedStreamIds[i]);
        }
        return message;
    };

    MultiBroadcastMuteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.encryptedStreamIds = [];
        if (options.defaults)
            object.mbId = "";
        if (message.mbId != null && message.hasOwnProperty("mbId"))
            object.mbId = message.mbId;
        if (message.encryptedStreamIds && message.encryptedStreamIds.length) {
            object.encryptedStreamIds = [];
            for (let j = 0; j < message.encryptedStreamIds.length; ++j)
                object.encryptedStreamIds[j] = message.encryptedStreamIds[j];
        }
        return object;
    };

    MultiBroadcastMuteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MultiBroadcastMuteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiBroadcastMuteRequest";
    };

    return MultiBroadcastMuteRequest;
})();

export const MultiBroadcastMuteResponse = $root.MultiBroadcastMuteResponse = (() => {

    function MultiBroadcastMuteResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MultiBroadcastMuteResponse.prototype.code = 1;

    MultiBroadcastMuteResponse.create = function create(properties) {
        return new MultiBroadcastMuteResponse(properties);
    };

    MultiBroadcastMuteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        return writer;
    };

    MultiBroadcastMuteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MultiBroadcastMuteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiBroadcastMuteResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MultiBroadcastMuteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MultiBroadcastMuteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiBroadcastMuteResponse)
            return object;
        let message = new $root.MultiBroadcastMuteResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "SUCCESS":
        case 1:
            message.code = 1;
            break;
        case "FEATURE_DISABLED":
        case 2:
            message.code = 2;
            break;
        case "BAD_REQUEST":
        case 3:
            message.code = 3;
            break;
        case "SERVER_ERROR":
        case 4:
            message.code = 4;
            break;
        }
        return message;
    };

    MultiBroadcastMuteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = options.enums === String ? "SUCCESS" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        return object;
    };

    MultiBroadcastMuteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MultiBroadcastMuteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiBroadcastMuteResponse";
    };

    return MultiBroadcastMuteResponse;
})();

export const MultiBroadcastUnmuteRequest = $root.MultiBroadcastUnmuteRequest = (() => {

    function MultiBroadcastUnmuteRequest(properties) {
        this.encryptedStreamIds = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MultiBroadcastUnmuteRequest.prototype.mbId = "";
    MultiBroadcastUnmuteRequest.prototype.encryptedStreamIds = $util.emptyArray;

    MultiBroadcastUnmuteRequest.create = function create(properties) {
        return new MultiBroadcastUnmuteRequest(properties);
    };

    MultiBroadcastUnmuteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.mbId != null && Object.hasOwnProperty.call(message, "mbId"))
            writer.uint32(10).string(message.mbId);
        if (message.encryptedStreamIds != null && message.encryptedStreamIds.length)
            for (let i = 0; i < message.encryptedStreamIds.length; ++i)
                writer.uint32(18).string(message.encryptedStreamIds[i]);
        return writer;
    };

    MultiBroadcastUnmuteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MultiBroadcastUnmuteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiBroadcastUnmuteRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.mbId = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.encryptedStreamIds && message.encryptedStreamIds.length))
                        message.encryptedStreamIds = [];
                    message.encryptedStreamIds.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MultiBroadcastUnmuteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MultiBroadcastUnmuteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiBroadcastUnmuteRequest)
            return object;
        let message = new $root.MultiBroadcastUnmuteRequest();
        if (object.mbId != null)
            message.mbId = String(object.mbId);
        if (object.encryptedStreamIds) {
            if (!Array.isArray(object.encryptedStreamIds))
                throw TypeError(".MultiBroadcastUnmuteRequest.encryptedStreamIds: array expected");
            message.encryptedStreamIds = [];
            for (let i = 0; i < object.encryptedStreamIds.length; ++i)
                message.encryptedStreamIds[i] = String(object.encryptedStreamIds[i]);
        }
        return message;
    };

    MultiBroadcastUnmuteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.encryptedStreamIds = [];
        if (options.defaults)
            object.mbId = "";
        if (message.mbId != null && message.hasOwnProperty("mbId"))
            object.mbId = message.mbId;
        if (message.encryptedStreamIds && message.encryptedStreamIds.length) {
            object.encryptedStreamIds = [];
            for (let j = 0; j < message.encryptedStreamIds.length; ++j)
                object.encryptedStreamIds[j] = message.encryptedStreamIds[j];
        }
        return object;
    };

    MultiBroadcastUnmuteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MultiBroadcastUnmuteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiBroadcastUnmuteRequest";
    };

    return MultiBroadcastUnmuteRequest;
})();

export const MultiBroadcastUnmuteResponse = $root.MultiBroadcastUnmuteResponse = (() => {

    function MultiBroadcastUnmuteResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MultiBroadcastUnmuteResponse.prototype.code = 1;

    MultiBroadcastUnmuteResponse.create = function create(properties) {
        return new MultiBroadcastUnmuteResponse(properties);
    };

    MultiBroadcastUnmuteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        return writer;
    };

    MultiBroadcastUnmuteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MultiBroadcastUnmuteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiBroadcastUnmuteResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MultiBroadcastUnmuteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MultiBroadcastUnmuteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiBroadcastUnmuteResponse)
            return object;
        let message = new $root.MultiBroadcastUnmuteResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "SUCCESS":
        case 1:
            message.code = 1;
            break;
        case "FEATURE_DISABLED":
        case 2:
            message.code = 2;
            break;
        case "BAD_REQUEST":
        case 3:
            message.code = 3;
            break;
        case "SERVER_ERROR":
        case 4:
            message.code = 4;
            break;
        }
        return message;
    };

    MultiBroadcastUnmuteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = options.enums === String ? "SUCCESS" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        return object;
    };

    MultiBroadcastUnmuteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MultiBroadcastUnmuteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiBroadcastUnmuteResponse";
    };

    return MultiBroadcastUnmuteResponse;
})();

export const MultiBroadcastVolumeState = $root.MultiBroadcastVolumeState = (() => {

    function MultiBroadcastVolumeState(properties) {
        this.entries = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MultiBroadcastVolumeState.prototype.entries = $util.emptyArray;

    MultiBroadcastVolumeState.create = function create(properties) {
        return new MultiBroadcastVolumeState(properties);
    };

    MultiBroadcastVolumeState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.entries != null && message.entries.length)
            for (let i = 0; i < message.entries.length; ++i)
                $root.MultiBroadcastVolumeEntry.encode(message.entries[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    MultiBroadcastVolumeState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MultiBroadcastVolumeState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiBroadcastVolumeState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.entries && message.entries.length))
                        message.entries = [];
                    message.entries.push($root.MultiBroadcastVolumeEntry.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MultiBroadcastVolumeState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MultiBroadcastVolumeState.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiBroadcastVolumeState)
            return object;
        let message = new $root.MultiBroadcastVolumeState();
        if (object.entries) {
            if (!Array.isArray(object.entries))
                throw TypeError(".MultiBroadcastVolumeState.entries: array expected");
            message.entries = [];
            for (let i = 0; i < object.entries.length; ++i) {
                if (typeof object.entries[i] !== "object")
                    throw TypeError(".MultiBroadcastVolumeState.entries: object expected");
                message.entries[i] = $root.MultiBroadcastVolumeEntry.fromObject(object.entries[i]);
            }
        }
        return message;
    };

    MultiBroadcastVolumeState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.entries = [];
        if (message.entries && message.entries.length) {
            object.entries = [];
            for (let j = 0; j < message.entries.length; ++j)
                object.entries[j] = $root.MultiBroadcastVolumeEntry.toObject(message.entries[j], options);
        }
        return object;
    };

    MultiBroadcastVolumeState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MultiBroadcastVolumeState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiBroadcastVolumeState";
    };

    return MultiBroadcastVolumeState;
})();

export const MultiBroadcastVolumeEntry = $root.MultiBroadcastVolumeEntry = (() => {

    function MultiBroadcastVolumeEntry(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MultiBroadcastVolumeEntry.prototype.encryptedStreamId = "";
    MultiBroadcastVolumeEntry.prototype.volume = 0;

    MultiBroadcastVolumeEntry.create = function create(properties) {
        return new MultiBroadcastVolumeEntry(properties);
    };

    MultiBroadcastVolumeEntry.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.encryptedStreamId != null && Object.hasOwnProperty.call(message, "encryptedStreamId"))
            writer.uint32(10).string(message.encryptedStreamId);
        if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
            writer.uint32(21).float(message.volume);
        return writer;
    };

    MultiBroadcastVolumeEntry.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MultiBroadcastVolumeEntry.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiBroadcastVolumeEntry();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.encryptedStreamId = reader.string();
                    break;
                }
            case 2: {
                    message.volume = reader.float();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MultiBroadcastVolumeEntry.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MultiBroadcastVolumeEntry.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiBroadcastVolumeEntry)
            return object;
        let message = new $root.MultiBroadcastVolumeEntry();
        if (object.encryptedStreamId != null)
            message.encryptedStreamId = String(object.encryptedStreamId);
        if (object.volume != null)
            message.volume = Number(object.volume);
        return message;
    };

    MultiBroadcastVolumeEntry.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.encryptedStreamId = "";
            object.volume = 0;
        }
        if (message.encryptedStreamId != null && message.hasOwnProperty("encryptedStreamId"))
            object.encryptedStreamId = message.encryptedStreamId;
        if (message.volume != null && message.hasOwnProperty("volume"))
            object.volume = options.json && !isFinite(message.volume) ? String(message.volume) : message.volume;
        return object;
    };

    MultiBroadcastVolumeEntry.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MultiBroadcastVolumeEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiBroadcastVolumeEntry";
    };

    return MultiBroadcastVolumeEntry;
})();

export const ResponseCode = $root.ResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "SUCCESS"] = 1;
    values[valuesById[2] = "FEATURE_DISABLED"] = 2;
    values[valuesById[3] = "BAD_REQUEST"] = 3;
    values[valuesById[4] = "SERVER_ERROR"] = 4;
    return values;
})();

export { $root as default };
