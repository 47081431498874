import {
  ApplicationThunk,
  EventFields,
  GeneralEventFields,
  StorageKeys,
} from "src/features/marketing/imports";
import {
  emitEvent,
  tangoAnalytics,
} from "src/features/marketing/imports/utils";
import { loadUserHashedInfo } from "src/features/marketing/state/asyncAction";
import { marketingSelectors } from "src/features/marketing/state/selectors";

interface RegistrationParams {
  [GeneralEventFields.COMMENT]: string;
  [GeneralEventFields.LOGIN_PROVIDER]: string;
  [GeneralEventFields.USER_ID]: string;
}

export const sendMarketingEventFlow =
  (actionName?: string, params?: RegistrationParams): ApplicationThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const userHashedInfo = marketingSelectors.getUserHashedInfo(state);

      if (!userHashedInfo?.hashedPhoneNumber && !userHashedInfo?.hashedEmail) {
        const userHashedInfoResponse =
          await dispatch(loadUserHashedInfo()).unwrap();

        tangoAnalytics.then((analytics) =>
          analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
            [EventFields.HASHE]: userHashedInfoResponse?.hashedEmail,
            [EventFields.HASHP]: userHashedInfoResponse?.hashedPhoneNumber,
          })
        );

        if (params) {
          emitEvent(actionName, {
            ...params,
            hashedEmail: userHashedInfoResponse.hashedEmail,
            hashedPhoneNumber: userHashedInfoResponse.hashedPhoneNumber,
          });
        }
      } else {
        tangoAnalytics.then((analytics) =>
          analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
            [EventFields.HASHE]: userHashedInfo?.hashedEmail,
            [EventFields.HASHP]: userHashedInfo?.hashedPhoneNumber,
          })
        );
      }
    } catch (err) {
      if (params) {
        emitEvent(actionName, params);
      }
    }
  };
