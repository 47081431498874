import { datadogLogs } from "@datadog/browser-logs";
import {
  InMemoryStorage,
  IndexedDBStorage,
  LocalStorage,
} from "src/core/analytics/imports/storage";

export const dataMeshParamsStorage = new IndexedDBStorage(
  "DataMeshDb",
  "dataMeshParamsStorage",
  {
    fallbackStorage: [new LocalStorage(), new InMemoryStorage()],
    onError: (error) => {
      datadogLogs.logger.warn(error.message, { kind: "indexedDB" }, error);
    },
  }
);
