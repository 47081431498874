import { drawRoundedRect } from "chat/components/currentConversation/components/VoiceMessage/canvasUtils";

export function drawBars(
  ctx: CanvasRenderingContext2D,
  barValues: number[],
  width: number,
  height: number
) {
  const middleY = height / 2;
  const barCount = barValues.length;
  const barW = width / barCount;
  const gap = 2;
  const cornerRadius = 3;

  for (let i = 0; i < barCount; i++) {
    const fraction = (barValues[i] + 1) / 101;
    const barHeight = fraction * (height / 2);

    const barX = i * barW + gap / 2;
    const effectiveBarWidth = barW - gap;
    const fullHeight = barHeight * 2;

    drawRoundedRect(
      ctx,
      barX,
      middleY - barHeight,
      effectiveBarWidth,
      fullHeight,
      cornerRadius
    );
  }
}
