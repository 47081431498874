import React from "react";
import { useDispatch } from "react-redux";
import MessageMenuOptionBase from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuOptionBase";
import { CHAT_POPOVER_OPTIONS } from "chat/constants/chatMenuOptionsConfig";
import { Breakpoints } from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { openDeleteChatModalHandler } from "chat/imports/state";
import { StoredConversation } from "chat/state";

interface DeleteOptionProps {
  conversation: StoredConversation;
}

export const DeleteOption = ({ conversation }: DeleteOptionProps) => {
  const dispatch = useDispatch();
  const isMobile = useBreakpointPrecise() !== Breakpoints.DESKTOP;

  if (conversation.group_info?.familyId) {
    return null;
  }

  const onDeleteHandler = () => {
    dispatch(
      openDeleteChatModalHandler({
        isMobile,
        conversationId: conversation.conversation_id,
      })
    );
  };

  return (
    <MessageMenuOptionBase
      option={CHAT_POPOVER_OPTIONS.delete}
      onClick={onDeleteHandler}
    />
  );
};
