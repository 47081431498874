import { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { getIsGiftsDrawerV2Enabled } from "src/features/giftsDrawer/exports/abTests";
import refreshCustomDrawer from "state/flows/refreshCustomDrawer";
import refreshGiftsDrawer from "state/flows/refreshGiftsDrawer";

const useRefreshGiftsDrawer = (enabled) => {
  const dispatch = useDispatch();

  const isGiftsDrawerV2Enabled = useSelector(getIsGiftsDrawerV2Enabled);

  useEffect(() => {
    if (isGiftsDrawerV2Enabled) {
      if (enabled) {
        batch(() => {
          dispatch(refreshCustomDrawer());
          dispatch(refreshGiftsDrawer());
        });
      }

      return;
    }

    if (enabled === undefined || enabled) {
      batch(() => {
        dispatch(refreshCustomDrawer());
        dispatch(refreshGiftsDrawer());
      });
    }
  }, [dispatch, isGiftsDrawerV2Enabled, enabled]);
};

export default useRefreshGiftsDrawer;
