import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import once from "lodash.once";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { RootState } from "state/delegate";
import {
  connectionManagerSelectors,
  deviceInfoSelectors,
  userSelectors,
} from "state/selectors";
import { isStandalone } from "utils/locationUtils";

const selectors = (state: RootState) => ({
  accountId: userSelectors.getMyAccountId(state),
  gpuInfo: deviceInfoSelectors.getDeviceGPUModel(state),
  analyticsKey: marketingSelectors.getAnalyticsKey(state),
  userName: connectionManagerSelectors.getUsername(state),
});

const setGlobalContextOnce = once(() => {
  window.addEventListener("offline", () => {
    datadogLogs.setGlobalContextProperty("isOnline", false);
  });

  window.addEventListener("online", () => {
    datadogLogs.setGlobalContextProperty("isOnline", true);
  });

  window.navigator?.permissions
    ?.query({
      name: "notifications",
    })
    .then((permissionStatus) => {
      datadogLogs.setGlobalContextProperty(
        "notificationPermission",
        permissionStatus.state
      );
    });
});

export const DatadogController: FC = () => {
  const { accountId, gpuInfo, analyticsKey, userName } = useSelector(selectors);

  useEffect(() => {
    setGlobalContextOnce();
  }, []);

  useEffect(() => {
    datadogLogs.setGlobalContext({
      isOnline: navigator.onLine,
      isPWA: isStandalone,
      accountId,
      webdriver: navigator.webdriver,
      analyticsKey,
    });
  }, [accountId, analyticsKey]);

  useEffect(() => {
    datadogLogs.logger.info("Device info", {
      isOnline: navigator.onLine,
      isPWA: isStandalone,
      accountId,
      webdriver: navigator.webdriver,
      analyticsKey,
      gpuInfo,
    });
  }, [gpuInfo, accountId, analyticsKey, userName]);

  return null;
};
