import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import { loadGifterUsersListRequest } from "src/features/gifterStatistics/api/gifterUsersApi";
import { GifterUsersListResponse } from "src/features/gifterStatistics/common/types";

interface FetchGifterUsersParams {
  accountId: number;
  timeframe: string;
}

export const loadGifterUsersList = createAsyncThunk<
  GifterUsersListResponse,
  FetchGifterUsersParams
>(
  "lwc/gifterUsersList/get",
  async ({ accountId, timeframe }, { rejectWithValue }) => {
    try {
      return await loadGifterUsersListRequest({
        accountId,
        timeframe,
      });
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return rejectWithValue(error);
    }
  }
);
