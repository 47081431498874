import React, { FC, ImgHTMLAttributes, memo, useRef } from "react";
import classnames from "classnames";
import { Nullable } from "src/types/common";
import { getPostsAlternativeDomainContentSupportEnabled } from "state/abTests";
import useLazyImage from "ui/hooks/useLazyImage";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import styles from "./Picture.scss";

const Picture: FC<ImgHTMLAttributes<HTMLImageElement>> = ({
  src,
  onClick,
  className,
  ...rest
}) => {
  const ref = useRef<Nullable<HTMLImageElement>>(null);
  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getPostsAlternativeDomainContentSupportEnabled
  );
  const adjustedImageSrc = makeAlternativeDomainUrl(src);
  useLazyImage(ref, adjustedImageSrc);

  return (
    <img
      {...rest}
      ref={ref}
      data-src={adjustedImageSrc}
      className={classnames(styles.root, className)}
      onClick={onClick}
      alt=""
    />
  );
};

export default memo(Picture);
