import merge from "lodash.merge";
import { combineReducers, compose } from "redux";
import { FeedLoadDirection, PostsFeedType } from "src/enums";
import { uniq } from "src/utils/miniLodash";
import {
  CHANGE_POSTS_TYPE,
  CLEAR_PHOTOS_SUBS_ONLY_FEED,
  DELETE_FEED_POST,
  FOLLOW_USER,
  GIFT_SENT,
  POSTS_FEED_BEGIN_FETCH,
  POSTS_FEED_END_FETCH,
  SET_OTHER_FEED_OWNER,
  SET_OTHER_SUB_ONLY_FEED_OWNER,
  UNFOLLOW_USER,
  UNLOCK_POST_SUCCESS,
  UPDATE_LIKED_BY_ME,
} from "state/actionTypes";
import withActionsFilter from "state/hor/withActionsFilter";
import withFetcher, { fetcherSelectors } from "state/hor/withFetcher";
import withUserSessionScope from "state/hor/withUserSessionScope";
import ensureParameter from "./utils/ensureParameter";

export const initialData = {
  olderCursor: "",
  newerCursor: "",
  cursor: {
    [FeedLoadDirection.OLDER]: "",
    [FeedLoadDirection.NEWER]: "",
  },
  posts: {},
  postIds: [],
};

const mergeCursor = (oldData, newData, direction) => {
  if (direction === FeedLoadDirection.NEWER) {
    return newData.direction === direction
      ? newData.cursor[direction] || oldData.cursor[direction]
      : oldData.cursor[direction] || newData.cursor[direction];
  }
  return newData.direction === direction
    ? newData.cursor[direction] // old cursor can be empty if there are no more posts
    : oldData.cursor[direction] || newData.cursor[direction];
};

const reducer = (state, action) => {
  if (action.type === DELETE_FEED_POST) {
    return {
      ...state,
      postIds: state.postIds.filter(
        (postId) => postId !== action.payload.postId
      ),
      posts: Object.fromEntries(
        Object.entries(state.posts).filter(
          ([postId]) => postId !== action.payload.postId
        )
      ),
    };
  }

  if (action.type === GIFT_SENT) {
    const { postId, additionInPoint } = action.payload;
    if (!postId || !additionInPoint) {
      return state;
    }
    const postObject = state.posts[postId];
    if (!postObject) {
      return state;
    }
    const updatedPostObject = {
      ...postObject,
      giftedPointsCount: postObject.giftedPointsCount + additionInPoint,
    };
    return { ...state, posts: { ...state.posts, [postId]: updatedPostObject } };
  }
  if (action.type === UPDATE_LIKED_BY_ME) {
    const { postId, likedByMe } = action.payload;
    const postObject = state.posts[postId];
    if (!postObject) {
      return state;
    }
    const updatedPostObject = {
      ...postObject,
      likedByMe,
      likesCount:
        likedByMe !== postObject.likedByMe
          ? postObject.likesCount + (likedByMe ? 1 : -1)
          : postObject.likesCount,
    };
    return { ...state, posts: { ...state.posts, [postId]: updatedPostObject } };
  }
  if (action.type === UNLOCK_POST_SUCCESS) {
    const { post } = action.payload;

    const newPosts = Object.entries(state.posts).reduce(
      (acc, [postId, item]) => ({
        ...acc,
        [postId]:
          postId === post.postId
            ? post
            : {
                ...item,
                subscriptionDetails: {
                  ...item.subscriptionDetails,
                  streamer: {
                    ...item.subscriptionDetails.streamer,
                    maxAvailableUnlockExclusivePostCount:
                      item.authorId === post.authorId
                        ? post.subscriptionDetails.streamer
                            .maxAvailableUnlockExclusivePostCount
                        : item.subscriptionDetails.streamer
                            .maxAvailableUnlockExclusivePostCount,
                  },
                },
              },
      }),
      {}
    );
    return {
      ...state,
      posts: newPosts,
    };
  }
  return state;
};

const withPostsFeedFetcher = withFetcher({
  beginFetchActionType: POSTS_FEED_BEGIN_FETCH,
  endFetchActionType: POSTS_FEED_END_FETCH,
  initialData,
  extractData: ({
    posts = {},
    postIds = [],
    nextCursor = "",
    previousCursor = "",
    direction,
  }) => ({
    posts,
    postIds,
    cursor: {
      [FeedLoadDirection.OLDER]: previousCursor,
      [FeedLoadDirection.NEWER]: nextCursor,
    },
    direction,
  }),
  mergeData: (oldData, newData) => ({
    posts: merge({}, oldData.posts, newData.posts),
    postIds: uniq(
      newData.direction === FeedLoadDirection.OLDER
        ? [...oldData.postIds, ...newData.postIds]
        : [...newData.postIds, ...oldData.postIds]
    ),
    cursor: {
      [FeedLoadDirection.OLDER]: mergeCursor(
        oldData,
        newData,
        FeedLoadDirection.OLDER
      ),
      [FeedLoadDirection.NEWER]: mergeCursor(
        oldData,
        newData,
        FeedLoadDirection.NEWER
      ),
    },
  }),
});

const othersFeedReducer = (state, action) => {
  if (action.type === SET_OTHER_FEED_OWNER) {
    if (state.ownerId !== action.payload) {
      return {
        ...state,
        ...initialData,
        isLoadFailed: false,
        isLoadInProgress: false,
        ownerId: action.payload,
      };
    }
  }
  return reducer(state, action);
};

const othersSubsOnlyFeedReducer = (state, action) => {
  if (action.type === SET_OTHER_SUB_ONLY_FEED_OWNER) {
    if (state.ownerId !== action.payload) {
      return {
        ...state,
        ...initialData,
        isLoadFailed: false,
        isLoadInProgress: false,
        ownerId: action.payload,
      };
    }
  }
  return reducer(state, action);
};

const photosOnlyFeedReducer = (state, action) => {
  if (action.type === CLEAR_PHOTOS_SUBS_ONLY_FEED) {
    return initialData;
  }
  return reducer(state, action);
};

const timelineFeedReducer = (state, action) => {
  if (action.type === FOLLOW_USER) {
    return initialData;
  }
  if (action.type === CHANGE_POSTS_TYPE) {
    return {
      ...state,
      olderCursor: "",
      newerCursor: "",
      cursor: {
        [FeedLoadDirection.OLDER]: "",
        [FeedLoadDirection.NEWER]: "",
      },
    };
  }
  if (action.type === UNFOLLOW_USER) {
    return initialData;
  }
  return reducer(state, action);
};

const localSelectors = {
  ...fetcherSelectors,
  getPostsList: (state) => state.postIds,
  getPostsMap: (state) => state.posts,
  getPostById: (state, postId) =>
    ensureParameter(postId, "postId") && state.posts[postId],
  getCursorForDirection: (state, direction) =>
    ensureParameter(direction, "direction") && state.cursor[direction],
  getOwner: (state) => state.ownerId,
};

export const selectors = {
  isLoadingPostsForCategory: (state, category) =>
    localSelectors.getIsLoadInProgress(state[category]),
  isLoadingPostsFailedForCategory: (state, category) =>
    localSelectors.getIsLoadFailed(state[category]),
  getPostsList: (state, category) =>
    localSelectors.getPostsList(state[category]),
  getPostsMap: (state, category) => localSelectors.getPostsMap(state[category]),
  getPostById: (state, category, postId) =>
    localSelectors.getPostById(state[category], postId),
  getCursorForDirection: (state, category, direction) =>
    localSelectors.getCursorForDirection(state[category], direction),
  getOthersFeedOwner: (state) =>
    localSelectors.getOwner(state[PostsFeedType.OTHERS]),
  getOthersSubOnlyFeedOwner: (state) =>
    localSelectors.getOwner(state[PostsFeedType.OTHERS_SUB_ONLY]),
};

const withCategoryFilter = (category) =>
  withActionsFilter(
    (action) =>
      action.type === DELETE_FEED_POST ||
      action.type === GIFT_SENT ||
      action.type === FOLLOW_USER ||
      action.type === UNFOLLOW_USER ||
      action.type === CHANGE_POSTS_TYPE ||
      (action.meta && action.meta.category === category)
  );

export default combineReducers({
  [PostsFeedType.TIMELINE]: compose(
    withUserSessionScope,
    withCategoryFilter(PostsFeedType.TIMELINE),
    withPostsFeedFetcher
  )(timelineFeedReducer),
  [PostsFeedType.TIMELINE_SUB_ONLY]: compose(
    withUserSessionScope,
    withCategoryFilter(PostsFeedType.TIMELINE_SUB_ONLY),
    withPostsFeedFetcher
  )(timelineFeedReducer),
  [PostsFeedType.MINE]: compose(
    withUserSessionScope,
    withCategoryFilter(PostsFeedType.MINE),
    withPostsFeedFetcher
  )(reducer),
  [PostsFeedType.OTHERS]: compose(
    withCategoryFilter(PostsFeedType.OTHERS),
    withPostsFeedFetcher
  )(othersFeedReducer),
  [PostsFeedType.OTHERS_SUB_ONLY]: compose(
    withCategoryFilter(PostsFeedType.OTHERS_SUB_ONLY),
    withPostsFeedFetcher
  )(othersSubsOnlyFeedReducer),
  [PostsFeedType.PHOTOS_SUB_ONLY]: compose(
    withCategoryFilter(PostsFeedType.PHOTOS_SUB_ONLY),
    withPostsFeedFetcher
  )(photosOnlyFeedReducer),
});
