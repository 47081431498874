import React, { FC, HTMLAttributes, memo } from "react";
import { useLocation } from "react-router-dom";
import { LegalNavigationConfig } from "legal/types";
import LegalNavigationItem from "./LegalNavigationItem";
import styles from "./LegalNavigation.scss";

interface LegalNavigationProps extends HTMLAttributes<HTMLDivElement> {
  navigationConfig: LegalNavigationConfig[];
}

const LegalNavigation: FC<LegalNavigationProps> = ({ navigationConfig }) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.root}>
      {navigationConfig.map(({ message, to, dataTestId }) => (
        <LegalNavigationItem
          key={to}
          data-testid={dataTestId}
          message={message}
          selected={pathname.includes(to)}
          to={to}
        />
      ))}
    </div>
  );
};

export default memo(LegalNavigation);
