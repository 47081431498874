import { createSlice } from "@reduxjs/toolkit";
import { PointsResponse } from "src/features/finance/redeems/root/type";
import { loadPointsInfo } from "src/features/finance/redeems/state/asyncActions";

export interface PointsState {
  data: PointsResponse | null;
  error: null | string;
  loading: boolean;
}

const initialState: PointsState = {
  data: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "pointsUserInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadPointsInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadPointsInfo.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(loadPointsInfo.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      });
  },
});

export const pointsSlice = slice.reducer;
