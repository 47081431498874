import { fetch } from "src/core/analytics/imports/api";
import { getProxycadorHost } from "src/core/analytics/imports/environment";

interface SendEventToGatewayType {
  body: BodyInit;
  isUserLoggedIn: boolean;
}

export const sendEventToGateway = ({
  isUserLoggedIn,
  body,
}: SendEventToGatewayType) => {
  const eventGatewayUrl = isUserLoggedIn
    ? `${getProxycadorHost()}/event-gateway/api/v1/events`
    : `${getProxycadorHost()}/event-gateway/api/v1/anonymous-events`;

  return fetch(eventGatewayUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-protobuf;charset=UTF-8",
    },
    body,
  });
};
