/* eslint-disable react/jsx-no-bind */
import React from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { IcArrowIcon as ArrowIcon } from "chat/imports/assets";
import {
  Button,
  GiftsList,
  Scrollbar,
  Typography,
} from "chat/imports/components";
import {
  ButtonSize,
  ButtonVariant,
  ScrollbarVariant,
  SpecialKind,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useLoadGiftsDrawer } from "chat/imports/hooks";
import {
  RootState,
  customDrawerSelectors,
  giftDrawerSelectors,
  giftsCacheSelectors,
} from "chat/imports/state";
import {
  Gift,
  GiftDrawerTarget,
  Gift as RawGift,
  VoidCallback,
} from "chat/imports/types";
import styles from "./PremiumMessageGifts.scss";

const getGroupGiftIds = (state: RootState, groupIds: string[]) =>
  groupIds.flatMap(
    (groupId) => giftsCacheSelectors.getGroupById(state, groupId)?.giftIds
  );

const giftsSelector = (state: RootState) => {
  const customCategoryIds = customDrawerSelectors.getCategories(state);
  const defaultCategoryIds = giftDrawerSelectors.getCategoryIdsList(
    state,
    GiftDrawerTarget.PREMIUM_MESSAGE
  );

  let categoryIds = customCategoryIds?.map(
    (category: { id: string }) => category.id
  );

  let categorySelector = customDrawerSelectors.getCategoryById;

  if (!categoryIds?.length) {
    categoryIds = defaultCategoryIds;
    categorySelector = giftDrawerSelectors.getCategoryById;
  }

  const giftIds = categoryIds.flatMap((categoryId: string) => {
    const { giftIds: categoryGiftIds = [], groupIds = [] } =
      categorySelector(state, categoryId, GiftDrawerTarget.PREMIUM_MESSAGE) ||
      {};

    const groupGiftIds = getGroupGiftIds(state, groupIds);

    return [...categoryGiftIds, ...groupGiftIds];
  });

  return giftsCacheSelectors
    .getGiftsByIds(state, giftIds)
    .filter((gift: Gift) => gift.special !== SpecialKind.AR_GIFT);
};

type PremiumMessageGiftsProps = {
  onClickBack: VoidCallback;
  onSelectGift: (giftId: string) => void;
};

const PremiumMessageGifts = ({
  onSelectGift,
  onClickBack,
}: PremiumMessageGiftsProps) => {
  const gifts = useSelector(giftsSelector, shallowEqual) as RawGift[];

  useLoadGiftsDrawer(GiftDrawerTarget.PREMIUM_MESSAGE);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.ICON_ONLY}
          className={styles.backButton}
          onClick={onClickBack}
        >
          <ArrowIcon />
        </Button>
        <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3} className={styles.title}>
          <FormattedMessage
            id="modal.send-media.all-gifts.title"
            defaultMessage="Select Premium Gift"
          />
        </Typography>
      </div>
      <Scrollbar variant={ScrollbarVariant.LIGHT}>
        <GiftsList
          gifts={gifts}
          onClickGift={onSelectGift}
          className={styles.giftsList}
        />
      </Scrollbar>
    </div>
  );
};

export default PremiumMessageGifts;
