import { getProxycadorHost } from "src/features/chat/imports/environment";
import {
  ReferralProgramMessages,
  enhancedFetch,
  sharedMessages,
} from "src/features/chat/imports/utils";
import { MessageType } from "src/features/chat/types";

export const downloadMedia = async (
  mediaUrl: string,
  messageType: MessageType,
  callbackHandler: (message: { defaultMessage: string; id: string }) => void,
  isIOS: boolean,
  isIPAD: boolean
) => {
  try {
    const securePath = mediaUrl.split("/securecontent/")[1];
    const response = await enhancedFetch(
      `${getProxycadorHost()}/securecontent/${securePath}`,
      {
        method: "GET",
        mode: "cors",
      }
    );

    const blob = await response.blob();

    const fileName = `media.${messageType === MessageType.IMAGE_MESSAGE ? "jpg" : "mp4"}`;

    const isIOSMobile = isIOS || isIPAD;

    if (
      isIOSMobile &&
      navigator.canShare &&
      navigator.canShare({
        files: [new File([blob], fileName, { type: blob.type })],
      })
    ) {
      await navigator.share({
        files: [new File([blob], fileName, { type: blob.type })],
        title: "Save media",
      });

      callbackHandler(
        messageType === MessageType.IMAGE_MESSAGE
          ? sharedMessages.photoSaved
          : sharedMessages.videoSaved
      );

      return true;
    }

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    callbackHandler(
      messageType === MessageType.IMAGE_MESSAGE
        ? sharedMessages.photoSaved
        : sharedMessages.videoSaved
    );

    return true;
  } catch (error) {
    callbackHandler(ReferralProgramMessages.downloadFailed);

    return false;
  }
};
