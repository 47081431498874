import { batch } from "react-redux";
import invariant from "fbjs/lib/invariant";
import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { enterStream, fetchStreamInfo } from "api/stream";
import {
  HTTP_CODE_CONFLICT,
  HTTP_CODE_FORBIDDEN,
  HTTP_CODE_GONE,
  HTTP_CODE_PAYMENT_REQUIRED,
} from "enums/httpCodes";
import { StreamEvents, StreamSessionInitializationResult } from "src/enums";
import { StreamKind, StreamStatus } from "src/types/richFragment/Stream";
import { genericNetworkError } from "state/actionCreators/networkError";
import { updatedLiveStreamInfo } from "state/actionCreators/streamInfo";
import {
  endSessionInitialization,
  resetViewerSession,
  updatedViewerSession,
} from "state/actionCreators/viewerSession";
import {
  loginSelectors,
  userSelectors,
  viewerSessionSelectors,
} from "state/selectors";

const keepEventTypesForLoggedInUser = [
  StreamEvents.PROMOTION,
  StreamEvents.TICKET,
  StreamEvents.GIFT,
  StreamEvents.MESSAGE,
  StreamEvents.CENSORED_MESSAGE,
];
const keepEventTypesForAnon = [
  StreamEvents.GIFT,
  StreamEvents.MESSAGE,
  StreamEvents.CENSORED_MESSAGE,
];

const getEventTypesToKeepOnSessionReset = (state, streamId) => {
  if (viewerSessionSelectors.getStreamId(state) !== streamId) {
    return [];
  }
  if (loginSelectors.isLoggedIn(state)) {
    return keepEventTypesForLoggedInUser;
  }

  return viewerSessionSelectors.getStreamKind(state) === StreamKind.PUBLIC
    ? keepEventTypesForAnon
    : [];
};

// visible for testing
export const dropEmptyUrlsFromProfile = ({
  profilePictureUrl,
  profileThumbnailUrl,
  ...rest
}) => ({
  ...rest,
  ...(profilePictureUrl && { profilePictureUrl }),
  ...(profileThumbnailUrl && { profileThumbnailUrl }),
});

const convertWatchJsonV2ToV1 = ({
  details: {
    stream: {
      encryptedAccountId,
      id,
      status,
      title,
      thumbnail,
      liveListUrl,
      masterListUrl,
      previewListUrl,
      landscape,
    },
    settings,
    isPublic,
    uniqueViewerCount,
    totalPointsInStream,
    likeCount,
    anchorPoints,
    source,
    anchor,
    ticketPrice,
    viewerCount,
    restrictions,
    moderationLevel,
  },
  multiBroadcast,
  stickers,
  bonusLevel,
  bonusPercentage,
}) => ({
  broadcasterId: encryptedAccountId,
  isPublic,
  uniqueViewerCount,
  totalPointsInStream,
  likesInStream: likeCount,
  totalPointsOfBroadcaster: anchorPoints,
  source,
  basicInfo: {
    id,
    status,
    title,
    thumbnail,
    broadcasterId: encryptedAccountId,
    ticketPrice,
    viewerCount,
    masterListUrl,
    liveListUrl,
    previewListUrl,
    restrictions,
    landscape,
    moderationLevel,
  },
  settings,
  entities: {
    basicProfile: {
      [encryptedAccountId]: dropEmptyUrlsFromProfile(anchor),
    },
    events: {},
    giftedAmounts: {},
  },
  moderationLevel,
  multiBroadcast,
  stickers,
  bonusLevel,
  bonusPercentage,
});

export default (streamId, requestId = "") =>
  async (dispatch, getState) => {
    const state = getState();

    invariant(
      streamId && streamId.length,
      `initializeViewerSession: sessionId is required!`
    );

    const isMultiBroadcastSwitch = !!Object.values(
      viewerSessionSelectors.getMultiBroadcastStreams(state)
    ).find((x) => x.stream.mbDescriptor.streamId === streamId);

    const defaultBehaviour = (status) => {
      dispatch(genericNetworkError({ status }));
      dispatch(
        endSessionInitialization(
          streamId,
          StreamSessionInitializationResult.EXPIRED
        )
      );
    };

    dispatch(
      resetViewerSession(
        streamId,
        isMultiBroadcastSwitch
          ? Object.values(StreamEvents)
          : getEventTypesToKeepOnSessionReset(state, streamId),
        isMultiBroadcastSwitch
      )
    );
    AnalyticsUtils.updateInteractionId();

    try {
      const streamInfo = await enterStream(streamId, requestId);
      batch(() => {
        dispatch(
          updatedViewerSession({
            streamId,
            currentUserId: userSelectors.getMyAccountId(state),
            ...convertWatchJsonV2ToV1(streamInfo),
          })
        );
        dispatch(
          endSessionInitialization(
            streamId,
            StreamSessionInitializationResult.SUCCESS,
            streamInfo.details.source
          )
        );
      });
    } catch ({ status, body }) {
      switch (status) {
        case HTTP_CODE_PAYMENT_REQUIRED: {
          batch(() => {
            dispatch(
              updatedViewerSession({
                streamId,
                currentUserId: userSelectors.getMyAccountId(state),
                ...convertWatchJsonV2ToV1(body),
              })
            );
            dispatch(
              endSessionInitialization(
                streamId,
                loginSelectors.isLoggedIn(state)
                  ? StreamSessionInitializationResult.PAYMENT_REQUIRED
                  : StreamSessionInitializationResult.LOGIN_REQUIRED,
                body.details.source
              )
            );
          });
          break;
        }
        case HTTP_CODE_FORBIDDEN: {
          batch(() => {
            dispatch(
              endSessionInitialization(
                streamId,
                StreamSessionInitializationResult.LOGIN_REQUIRED
              )
            );
          });
          break;
        }
        case HTTP_CODE_CONFLICT: {
          dispatch(
            endSessionInitialization(
              streamId,
              StreamSessionInitializationResult.KICKED_OUT
            )
          );
          break;
        }
        case HTTP_CODE_GONE: {
          batch(() => {
            dispatch(
              updatedLiveStreamInfo({
                streamId,
                info: {
                  basicInfo: { id: streamId, status: StreamStatus.TERMINATED },
                },
              })
            );
            dispatch(
              endSessionInitialization(
                streamId,
                StreamSessionInitializationResult.TERMINATED
              )
            );
          });
          try {
            const info = await fetchStreamInfo({ streamId });
            dispatch(updatedLiveStreamInfo({ streamId, info }));
          } catch (e) {
            // eslint-disable-line no-empty
          }
          break;
        }
        default: {
          defaultBehaviour(status);
          break;
        }
      }
    }
  };
