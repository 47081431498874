import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  getIsHomePageEnabled,
  getIsHomePageV2Enabled,
  getIsHomePageV3Enabled,
} from "state/abTests";
import { RootState } from "state/delegate";
import { serverOwnedConfigSelectors } from "state/selectors";
import { FORBIDDEN_UNAUTHORIZED_PATH_NAMES } from "ui/navigation/enums/forbiddenUnauthorizedPathNames";
import {
  linkToAgencyProgramAgreement,
  linkToBasePath,
  linkToBroadcasterAgreement,
  linkToBugBountyProgram,
  linkToCommunityGuidelines,
  linkToCopyright,
  linkToDSAInformation,
  linkToFAQ,
  linkToGetTheApp,
  linkToHomePageV3,
  linkToHomeV2Page,
  linkToOnboardingLandingPage,
  linkToPrivacyPolicy,
  linkToPublisherTermsAndConditions,
  linkToRegistration,
  linkToRegpage1,
  linkToTermsOfUse,
  linkToThirdPartyLicense,
  linkToVisitorCashier,
  linkToWelcome,
} from "ui/navigation/links";
import checkIsMatchPath from "ui/navigation/utils/checkIsMatchPath";

interface UseIsPathnameForVisitorProps {
  pathname: string;
}

const VISITOR_PATH_NAMES: string[] = [
  linkToAgencyProgramAgreement,
  linkToBroadcasterAgreement,
  linkToBugBountyProgram,
  linkToCommunityGuidelines,
  linkToCopyright,
  linkToDSAInformation,
  linkToFAQ,
  linkToGetTheApp,
  linkToHomePageV3,
  linkToHomeV2Page,
  linkToOnboardingLandingPage,
  linkToPrivacyPolicy,
  linkToPublisherTermsAndConditions,
  linkToRegistration,
  linkToRegpage1,
  linkToTermsOfUse,
  linkToThirdPartyLicense,
  linkToWelcome,
  linkToVisitorCashier,
  ...FORBIDDEN_UNAUTHORIZED_PATH_NAMES,
];

const selector = (state: RootState) => ({
  isServerConfigReady: serverOwnedConfigSelectors.getIsServerConfigReady(state),
  isHomePageEnabled: getIsHomePageEnabled(state),
  isHomePageV2Enabled: getIsHomePageV2Enabled(state),
  isHomePageV3Enabled: getIsHomePageV3Enabled(state),
});

const useIsPathnameForVisitor = ({
  pathname,
}: UseIsPathnameForVisitorProps) => {
  const {
    isServerConfigReady,
    isHomePageEnabled,
    isHomePageV2Enabled,
    isHomePageV3Enabled,
  } = useSelector(selector, shallowEqual);

  const isAnyHomePageEnabled =
    isHomePageEnabled || isHomePageV2Enabled || isHomePageV3Enabled;

  return useMemo(() => {
    // TODO update once https://tango-me.atlassian.net/browse/WEB-6993 is ready
    if (!isServerConfigReady) {
      return false;
    }

    if (isAnyHomePageEnabled && pathname === linkToBasePath) {
      return true;
    }

    return checkIsMatchPath({ pathNames: VISITOR_PATH_NAMES, pathname });
  }, [isAnyHomePageEnabled, isServerConfigReady, pathname]);
};

export default useIsPathnameForVisitor;
