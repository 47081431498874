import type { LoyaltyProgramState } from "src/features/loyaltyProgram/state/loyaltyProgramSlice";

export const loyaltyProgramSelectors = {
  isLoading: (state: LoyaltyProgramState) => state.loading,
  getLoyaltyProgramList: (state: LoyaltyProgramState) => state.loyaltyProgram,
  getTiers: (state: LoyaltyProgramState) => state.tiers,
  getActiveTier: (state: LoyaltyProgramState) => state.tier,
  getSelectedTearData: (state: LoyaltyProgramState) =>
    state.tiers[state.selectedTierIndex],
  getSelectedTierIndex: (state: LoyaltyProgramState) => state.selectedTierIndex,
  getCurrentVipLevelDetails: (state: LoyaltyProgramState) =>
    state.currentVipLevelDetails,
  getCurrentTierIndex: (state: LoyaltyProgramState) => state.currentTierIndex,
  getLevelsDetails: (state: LoyaltyProgramState) => state.levelsDetails,
  getVipLevelsDataFromZip: (state: LoyaltyProgramState, id: string) =>
    state.vipLevelsDataFromZip[id],
};
