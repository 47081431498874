import { matchPath, useLocation } from "react-router-dom";
import { DomainType } from "src/enums";
import { linkToChatMatch, linkToStreamMatch } from "ui/navigation/links";

const getIsMatchPathname = (pathname: string, path: string) =>
  matchPath(pathname, {
    path,
    exact: true,
    strict: true,
  })?.isExact;

export const getDomainFromPathname = (pathname: string) => {
  if (getIsMatchPathname(pathname, linkToStreamMatch)) {
    return DomainType.STREAM;
  }

  if (getIsMatchPathname(pathname, linkToChatMatch)) {
    return DomainType.CHAT;
  }

  return DomainType.UNKNOWN;
};

export const useDomain = () => {
  const { pathname } = useLocation();

  return getDomainFromPathname(pathname);
};
