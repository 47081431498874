import Hls from "hls.js";
import { getLhlsEnabled } from "environment";
import { DeviceType } from "src/enums";
import Logger from "utils/logger";

const TECH_PARAM = "tech";

export type HlsTech = "alhls" | "hls" | "lhls";

export type HlsPlayerImplementation = "hls-js" | "native";

const browserCanPlayHls = (() => {
  let result: boolean | null = null;

  return () => {
    if (result === null) {
      const video = document.createElement("video");
      result = video.canPlayType("application/vnd.apple.mpegurl") !== "";
    }

    return result;
  };
})();

export const getHlsPlayerImplementation = (
  deviceType: DeviceType,
  enableHlsJsOnIOS: boolean
): HlsPlayerImplementation => {
  if (!Hls.isSupported()) {
    return "native";
  }

  if (
    browserCanPlayHls() &&
    deviceType !== DeviceType.ANDROID &&
    !enableHlsJsOnIOS
  ) {
    return "native";
  }

  return "hls-js";
};

export const getHlsTech = (player: HlsPlayerImplementation) => {
  if (!getLhlsEnabled()) {
    return "hls";
  }
  if (player === "native") {
    return "alhls";
  }

  return "lhls";
};

export const getPlaylistUrl = (src: URL | string, hlsTech: HlsTech) => {
  try {
    const url = new URL(src);
    const params = url.searchParams;

    params.delete(TECH_PARAM);
    params.set(TECH_PARAM, hlsTech);

    return url.toString();
  } catch (e) {
    return src.toString();
  }
};

export const printLevels = (hls: Hls) => {
  Logger.log(`hls available levels (${hls.levels.length}):`);
  hls.levels
    .map(({ width, height, url }) => ({
      width,
      height,
      url,
    }))
    .forEach((x, index) => {
      Logger.log(`hls ${index === hls.currentLevel ? "*" : " "} ${index}: `, x);
    });
};
