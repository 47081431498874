import {
  linkToAppealPage,
  linkToArtistsClub,
  linkToBroadcast,
  linkToChatMatch,
  linkToCommonLiveStreams,
  linkToCreators,
  linkToFeed,
  linkToFollowingTab,
  linkToGiftCards,
  linkToHappyMomentsMatch,
  linkToLandingPagePhoenix,
  linkToLeaderBoard,
  linkToLeaders,
  linkToMessageRequestMatch,
  linkToNFTAuction,
  linkToProfileMatch,
  linkToProfileSettingsPage,
  linkToReferralProgram,
  linkToSearchMatch,
  linkToSettingsMatch,
  linkToSocialGamesBase,
  linkToStreamMatch,
  linkToSubscribers,
  linkToSubscriptions,
  linkToTournamentMatch,
  linkToUserProfileOrLiveStream,
} from "ui/navigation/links";

export const FORBIDDEN_UNAUTHORIZED_PATH_NAMES: string[] = [
  linkToAppealPage,
  linkToArtistsClub,
  linkToBroadcast,
  linkToChatMatch,
  linkToCommonLiveStreams,
  linkToCreators,
  linkToFeed,
  linkToFollowingTab,
  linkToGiftCards,
  linkToHappyMomentsMatch,
  linkToLandingPagePhoenix,
  linkToLeaderBoard,
  linkToLeaders,
  linkToMessageRequestMatch,
  linkToNFTAuction,
  linkToProfileMatch,
  linkToProfileSettingsPage,
  linkToReferralProgram,
  linkToSearchMatch,
  linkToSettingsMatch,
  linkToSocialGamesBase,
  linkToStreamMatch,
  linkToSubscribers,
  linkToSubscriptions,
  linkToTournamentMatch,
  linkToUserProfileOrLiveStream,
];
