import { useCallback, useRef } from "react";

const useLongPressTouch = ({
  onLongPress,
  options: { delay = 300 } = {},
}: {
  onLongPress: () => void;
  options?: {
    delay?: number;
  };
}) => {
  const timerIdRef = useRef<ReturnType<typeof setTimeout>>();
  const onLongPressRef = useRef(onLongPress);
  onLongPressRef.current = onLongPress;

  const start = useCallback(() => {
    timerIdRef.current = setTimeout(() => {
      onLongPressRef.current();
    }, delay);

    window.addEventListener(
      "scroll",
      () => {
        clearTimeout(timerIdRef.current);
      },
      { once: true }
    );
  }, [delay]);

  const clear = useCallback(() => {
    clearTimeout(timerIdRef.current);
  }, []);

  return {
    onTouchStart: start,
    onTouchEnd: clear,
  };
};

export default useLongPressTouch;
