import React, { FC, PropsWithChildren, useEffect, useRef } from "react";
import { VoidCallback } from "src/features/giftAnimation/imports/types";

interface VideoProps {
  isSoundEnabled: boolean;
  onComplete?: VoidCallback;
  onDimensionsChange?: (width: number, height: number) => void;
  onError: VoidCallback;
  onFrame: (currentTime: number) => void;
  videoUrl?: string;
}

const MAX_FPS = 24;
const MS_PER_SECOND = 1000;
const MEDIA_READY_TO_PLAY = 4;

export const Video: FC<PropsWithChildren<VideoProps>> = ({
  onFrame,
  onComplete,
  isSoundEnabled,
  videoUrl,
  onError,
  onDimensionsChange,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const lastTimeRef = useRef<number>(0);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    let rafId: number;
    let isPlaying = false;

    const updateFrame = () => {
      if (!isPlaying) {
        return;
      }
      if (onDimensionsChange) {
        onDimensionsChange(video.videoWidth, video.videoHeight);
      }

      const currentTime = performance.now();
      const elapsed = currentTime - lastTimeRef.current;
      const fps = MS_PER_SECOND / elapsed;
      const limitedFPS = Math.min(MAX_FPS, fps);
      const frameInterval = MS_PER_SECOND / limitedFPS;

      if (elapsed >= frameInterval) {
        if (
          video.readyState >= MEDIA_READY_TO_PLAY &&
          video.videoWidth > 0 &&
          video.currentTime > 0
        ) {
          onFrame(video.currentTime);
        }
        lastTimeRef.current = currentTime;
      }

      rafId = requestAnimationFrame(updateFrame);
    };

    const startPlayback = () => {
      if (!video || isPlaying) {
        return;
      }

      if (video.readyState >= MEDIA_READY_TO_PLAY) {
        const playingVideo = video.play();

        if (playingVideo !== undefined) {
          playingVideo
            .then(() => {
              isPlaying = true;
              video.muted = !isSoundEnabled;
              lastTimeRef.current = performance.now();
              rafId = requestAnimationFrame(updateFrame);
            })
            .catch(onError);
        }
      }
    };

    video.addEventListener("canplaythrough", startPlayback);
    video.addEventListener("error", onError);

    return () => {
      isPlaying = false;
      cancelAnimationFrame(rafId);
      video.removeEventListener("canplaythrough", startPlayback);
      video.removeEventListener("error", onError);
      video.pause();
    };
  }, [onFrame]);

  if (videoRef.current) {
    videoRef.current.muted = !isSoundEnabled;
  }

  return (
    <video
      ref={videoRef}
      crossOrigin="anonymous"
      src={videoUrl}
      autoPlay
      muted
      playsInline
      onEnded={onComplete}
      preload="auto"
      disablePictureInPicture
    />
  );
};
