import { useSelector } from "react-redux";
import { StoredMessage } from "chat/state";
import { MessageType } from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import { userSelectors } from "state/selectors";

export const useDeleteChatMessageModalMode = (message: StoredMessage) => {
  const accountId = useSelector(userSelectors.getMyAccountId);
  const {
    type,
    from,
    id: { chat_id = null },
  } = message;

  const isGroupChat = isGroupChatId(chat_id);
  const isMyMessage = accountId === from;

  const onlyForMeMessageTypes = [
    MessageType.PREMIUM_MESSAGE_SHARED,
    MessageType.GIFT_IN_CHAT,
    MessageType.NORMAL_CALL_MESSAGE,
    MessageType.MISSED_CALL_MESSAGE,
  ];

  return onlyForMeMessageTypes.includes(type) || !isMyMessage || isGroupChat;
};
