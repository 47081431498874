import { useSelector } from "react-redux";
import { RootState } from "state/delegate";
import {
  isAlternativeDomain,
  replaceDomainWithHostname,
} from "utils/alternativeDomainUtils";

export const useMakeAlternativeDomainUrl = (
  featureFlagSelector: (stat: RootState) => boolean
) => {
  const isAlternativeDomainSupported = useSelector(featureFlagSelector);

  return isAlternativeDomain() && isAlternativeDomainSupported
    ? <T extends string | undefined>(url: T) =>
        url ? replaceDomainWithHostname(url) : url
    : <T extends string | undefined>(url: T) => url;
};
