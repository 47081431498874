import {
  parseBooleanEnabledSOC,
  parseNumberSOC,
} from "src/features/returnAppleLoginUsers/imports/abTest";

export const getIsReturnAppleLoginUsersEnabled = parseBooleanEnabledSOC(
  "web.return.apple.login.users.enabled",
  "0"
);

export const getIsAppleCloseDisabled = parseBooleanEnabledSOC(
  "web.apple.close.disabled",
  "0"
);

export const getTimerSeconds = parseNumberSOC(
  "web.return.apple.login.users.timer.seconds",
  "0"
);
