import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames, RegistrationSource } from "@analytics/enums";
import {
  getEnabledPaymentProviders,
  getReferralRegistrationEnabled,
} from "environment";
import { IS_NEW_USER_STORAGE_KEY, TANGO_ACCOUNT_ID } from "src/constants";
import {
  Breakpoints,
  CombinedFeedType,
  LiveFeedType,
  PersonalFeedType,
} from "src/enums";
import { RegistrationType } from "src/features/referralProgram/common/enums";
import { setReferralInvitation } from "src/features/referralProgram/exports/state";
import { setReferrerAccountId } from "src/features/signin/exports/state/actionCreators";
import { openLoginView } from "src/features/signin/exports/state/flows";
import {
  getIsLandingPageOpenAsModalEnabled,
  getIsLandingPageV3Enabled,
  getIsRefillV2MobileEnabled,
  getNftAuctionTags,
} from "src/state/abTests";
import {
  openCashierV2DrawerBottomScreen,
  openRefillDrawerBottomScreen,
  openRefillV2DrawerBottomScreen,
} from "src/state/actionCreators/bottomScreen";
import landingPagePhoenixConfigActions from "src/state/actionCreators/landingPagePhoenix";
import { RootState } from "src/state/delegate";
import { Nullable } from "src/types/common";
import usePrevious from "src/ui/hooks/usePrevious";
import isValueInStringEnum from "src/utils/isValueInStringEnum";
import {
  LINK_FROM_WEB_CHAT_PARAM,
  isLinkOpenedFromWeb,
} from "src/utils/locationUtils";
import { openLandingPageModalHandler } from "state/actionCreators/modal";
import {
  bottomScreenSelectors,
  loginSelectors,
  userSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/taskManager";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import {
  AdditionalAction,
  DeeplinkQueryField,
  Landing,
  LinkType,
  Target,
  TaskType,
} from "ui/navigation/deepLink/types";
import {
  deepLink,
  linkToChat,
  linkToCreatorsTab,
  linkToFeed,
  linkToLandingPagePhoenix,
  linkToLiveStream,
  makeLinkToFreeSpinesSocialGames,
  makeLinkToLiveStreamsFeed,
  makeLinkToProfile,
  makeLinkToSocialGames,
} from "ui/navigation/links";

export const emitLinkOpenedEvent = ({
  accountId,
  linkTypeParam,
  linkType,
  landing,
  peerId,
  sessionId,
  shortLink = 0,
  url,
}: {
  accountId: Nullable<string>;
  landing: Nullable<string>;
  linkType: Nullable<string>;
  linkTypeParam: Nullable<string>;
  peerId: Nullable<string>;
  sessionId?: Nullable<string>;
  shortLink?: number;
  url?: Nullable<string>;
}): void =>
  emitEvent(EventNames.LINK_OPENED, {
    account_id: accountId,
    link_type_params: linkTypeParam,
    is_new: localStorage.getItem(IS_NEW_USER_STORAGE_KEY),
    landing,
    link_type: linkType,
    peer_id: peerId,
    session_id: sessionId,
    short_link: shortLink,
    url,
  });

const checkLpAdditionalAction = (
  additionalAction: Nullable<AdditionalAction | string>
) =>
  additionalAction &&
  (additionalAction.startsWith("lp") || additionalAction.startsWith("landing"));

const selector = (state: RootState) => ({
  accountId: userSelectors.getMyAccountId(state) as string,
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isRefillV2MobileEnabled: getIsRefillV2MobileEnabled(state),
  screenData: bottomScreenSelectors.screenData(state),
  isLandingPageOpenAsModalEnabled: getIsLandingPageOpenAsModalEnabled(state),
  isLandingPageV3Enabled: getIsLandingPageV3Enabled(state),
  auctionTags: getNftAuctionTags(state),
});

const useDeepLinkActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    accountId,
    isLoggedIn,
    screenData,
    isLandingPageOpenAsModalEnabled,
    isRefillV2MobileEnabled,
    isLandingPageV3Enabled,
    auctionTags,
  } = useSelector(selector, shallowEqual);

  const isDesktop = useBreakpointPrecise() === Breakpoints.DESKTOP;
  const prevScreenData = usePrevious(screenData);

  const isStream = useRouteMatch({
    path: "/stream/",
    strict: false,
  });

  const setTaskByAdditionalAction = useCallback(
    ({
      additionalAction,
      messageUuid,
      paymentSummary,
      bannerType,
    }: {
      additionalAction: Nullable<AdditionalAction | string>;
      bannerType: Nullable<string>;
      messageUuid: Nullable<string>;
      paymentSummary: Nullable<string>;
      promotionType: Nullable<string>;
      promotionValue: Nullable<string>;
      trackingId: Nullable<string>;
    }) => {
      switch (additionalAction) {
        case AdditionalAction.OPEN_OFFERS: {
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_INTENT_OPEN_BUY_COINS,
              payload: {
                messageUuid,
                paymentSummary,
              },
            })
          );
          break;
        }
        case AdditionalAction.OPEN_BRING_FRIENDS: {
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_INTENT_OPEN_BRING_FRIENDS,
            })
          );
          break;
        }
        case AdditionalAction.ONBOARDING_LANDING: {
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_INTENT_OPEN_ONBOARDING_LANDING,
            })
          );
          break;
        }
        case AdditionalAction.OPEN_REGISTRATION: {
          if (!isLoggedIn) {
            if (bannerType) {
              dispatch(
                openLoginView({
                  eventText: bannerType,
                  registrationSource: RegistrationSource.PROMOTION,
                })
              );
              break;
            }

            dispatch(openLoginView());
          }
          break;
        }
      }
    },
    [dispatch, isLoggedIn]
  );

  const setTaskByTarget = useCallback(
    ({
      additionalAction,
      audienceId,
      provider,
      targetUid,
      trackingId,
      messageUuid,
      targetSocialGameId,
      targetStreamId,
      target,
      tab,
      referrerAccountId,
      campaignId,
      targetGameId,
      isLinkFromWebChat,
    }: DeeplinkAction) => {
      switch (target) {
        case Target.PROFILE:
          emitLinkOpenedEvent({
            accountId,
            peerId: accountId,
            landing: accountId ? Landing.PROFILE : Landing.MAIN_SCREEN,
            linkType: LinkType.PROFILE,
            linkTypeParam: accountId,
          });
          break;
        case Target.VIEW_PROFILE:
          emitLinkOpenedEvent({
            accountId,
            peerId: targetUid,
            landing: targetUid ? Landing.PROFILE : Landing.MAIN_SCREEN,
            linkType: LinkType.PROFILE,
            linkTypeParam: targetUid,
          });
          break;
        case Target.STREAM:
          emitLinkOpenedEvent({
            accountId,
            peerId: targetUid,
            landing: targetStreamId ? Landing.STREAM : Landing.MAIN_SCREEN,
            linkType: LinkType.LIVE,
            linkTypeParam: targetStreamId,
            sessionId: targetStreamId,
          });
          break;
        case Target.AGENT: {
          emitLinkOpenedEvent({
            accountId,
            peerId: referrerAccountId,
            landing: Landing.MAIN_SCREEN,
            linkType: LinkType.AGENTS,
            linkTypeParam: referrerAccountId,
          });
        }
      }

      switch (target) {
        case Target.MOBILE_SETTINGS:
        case Target.APP_SETTINGS:
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_INTENT_OPEN_MOBILE_SETTINGS,
            })
          );
          break;
        case Target.PROFILE:
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_INTENT_OPEN_PROFILE,
            })
          );
          break;
        case Target.PROFILE_SETTINGS:
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_INTENT_EDIT_PROFILE_SETTINGS,
            })
          );
          break;
        case Target.REFILL:
        case Target.CASHIER: {
          const payload = provider
            ? { provider, lockToProvider: true }
            : undefined;
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_INTENT_OPEN_BUY_COINS,
              payload: {
                ...payload,
                messageUuid,
              },
            })
          );
          break;
        }
        case Target.AGENT:
        case Target.SUPPORTER: {
          if (referrerAccountId && getReferralRegistrationEnabled()) {
            dispatch(setReferrerAccountId(referrerAccountId));
          }

          if (referrerAccountId) {
            const registrationReferralType =
              target === Target.AGENT
                ? RegistrationType.CREATOR
                : RegistrationType.SUPPORTER;

            dispatch(
              setReferralInvitation({
                inviterId: referrerAccountId,
                registrationReferralType,
              })
            );

            emitEvent(EventNames.OPEN_DEEPLINK, {
              [EventFields.RECIPIENT_ACCOUNT_ID]: accountId,
              [EventFields.TARGET]: registrationReferralType.toLowerCase(),
              [EventFields.LOGGED_IN_ACCOUNT_ID]: referrerAccountId,
              [EventFields.ACTION]: "referral_program",
            });
          }
          break;
        }
        case Target.CONNECTED_ACCOUNTS: {
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_INTENT_OPEN_CONNECTED_ACCOUNTS,
              payload: {
                instagramFlow:
                  additionalAction === AdditionalAction.INSTAGRAM_FLOW,
              },
            })
          );
          break;
        }
        case Target.NEARBY_WITH_REGISTRATION:
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_OPEN_NEARBY_WITH_REGISTRATION,
            })
          );
          break;
        case Target.CREATORS_WITH_REGISTRATION:
          dispatch(
            actionCreators.addQueueItem({
              type: TaskType.DEEP_LINK_OPEN_CREATORS_WITH_REGISTRATION,
            })
          );
          history.replace(linkToCreatorsTab);
          break;
        case Target.LIVE:
          history.replace(
            tab ? makeLinkToLiveStreamsFeed(tab) : linkToLiveStream
          );
          break;
        case Target.CONVERSATION:
          history.replace(linkToChat);
          break;
        case Target.TIMELINE:
          history.replace(linkToFeed);
          break;
        case Target.SUPPORT:
          history.replace(makeLinkToProfile(TANGO_ACCOUNT_ID));
          break;
        case Target.LANDING_PAGE: {
          if (campaignId !== null) {
            dispatch(landingPagePhoenixConfigActions.setCampaignId(campaignId));
          }

          if (!getEnabledPaymentProviders().length) {
            return;
          }

          if (isLinkFromWebChat && !isLandingPageV3Enabled) {
            history.push(linkToLandingPagePhoenix, {
              trackingId,
              messageUuid,
            });
            break;
          }

          if (isLandingPageOpenAsModalEnabled) {
            dispatch(
              // TODO: remove @ts-ignore when move modal.js to typescript https://tango-me.atlassian.net/browse/WEB-4861
              // @ts-ignore
              openLandingPageModalHandler({
                trackingId,
                withBottomScreen: !isDesktop,
                isLandingPageV3Enabled,
                onDismiss: (): void => {
                  if (isDesktop) {
                    return;
                  }

                  if (isStream) {
                    if (isRefillV2MobileEnabled) {
                      dispatch(
                        openRefillV2DrawerBottomScreen({
                          screenData: prevScreenData,
                        })
                      );
                    } else {
                      dispatch(
                        openRefillDrawerBottomScreen({
                          screenData: prevScreenData,
                        })
                      );
                    }
                  } else {
                    dispatch(
                      openCashierV2DrawerBottomScreen({
                        screenData: prevScreenData,
                      })
                    );
                  }
                },
              })
            );
          } else {
            history.push(linkToLandingPagePhoenix, {
              trackingId,
              messageUuid,
            });
          }

          break;
        }
        case Target.SOCIAL_GAMES:
          history.push(makeLinkToSocialGames(targetSocialGameId), {
            trackingId,
          });
          break;
        case Target.SOCIAL_GAMES_FREESPINS:
          history.push(makeLinkToFreeSpinesSocialGames(targetGameId), {
            trackingId,
            audienceId,
            isFreeSpines: true,
          });
          break;
        default:
          break;
      }
    },
    [
      accountId,
      dispatch,
      history,
      isLandingPageV3Enabled,
      isLandingPageOpenAsModalEnabled,
      isDesktop,
      isStream,
      isRefillV2MobileEnabled,
      prevScreenData,
    ]
  );

  interface DeeplinkAction {
    additionalAction: Nullable<AdditionalAction | string>;
    auctionTab: Nullable<string>;
    audienceId: Nullable<string>;
    bannerType: Nullable<string>;
    campaignId: Nullable<string>;
    isLinkFromWebChat: Nullable<string>;
    messageUuid: Nullable<string>;
    paymentSummary: Nullable<string>;
    promotionType: Nullable<string>;
    promotionValue: Nullable<string>;
    provider: Nullable<string>;
    referrerAccountId: Nullable<string>;
    showOffers: Nullable<string>;
    tab: Nullable<CombinedFeedType>;
    target: Nullable<Target>;
    targetConversationId: Nullable<string>;
    targetGameId: Nullable<string>;
    targetSocialGameId: Nullable<string>;
    targetStoryId: Nullable<string>;
    targetStreamId: Nullable<string>;
    targetUid: Nullable<string>;
    trackingId: Nullable<string>;
    username: Nullable<string>;
    webview: Nullable<string>;
  }

  const extractDeepLinkAction = useCallback(
    (url: string | undefined): DeeplinkAction => {
      const emptyDeepLinkAction = {
        audienceId: null,
        target: null,
        additionalAction: null,
        promotionType: null,
        promotionValue: null,
        targetStreamId: null,
        referrerAccountId: null,
        showOffers: null,
        provider: null,
        username: null,
        targetUid: null,
        targetGameId: null,
        targetSocialGameId: null,
        targetStoryId: null,
        targetConversationId: null,
        tab: null,
        auctionTab: null,
        trackingId: null,
        messageUuid: null,
        paymentSummary: null,
        campaignId: null,
        webview: null,
        bannerType: null,
        isLinkFromWebChat: null,
      };

      if (url?.includes(deepLink)) {
        try {
          const query = new URL(url).searchParams;

          AnalyticsUtils.updateIsLinkOpenedFromWeb(
            isDesktop || isLinkOpenedFromWeb(url) ? "1" : "0"
          );

          const additionalAction = query.get(
            DeeplinkQueryField.ADDITIONAL_ACTION
          );

          const isLpAdditionalAction =
            checkLpAdditionalAction(additionalAction);

          const target =
            query.get(DeeplinkQueryField.TARGET) ||
            (isLpAdditionalAction ? Target.LANDING_PAGE : null);

          const tab = query.get(DeeplinkQueryField.TAB);

          return {
            target: isValueInStringEnum(Target, target) ? target : null,
            tab:
              isValueInStringEnum(LiveFeedType, tab) ||
              isValueInStringEnum(PersonalFeedType, tab)
                ? tab
                : null,
            auctionTab:
              tab && target === Target.AUCTION && auctionTags.includes(tab)
                ? tab
                : null,
            additionalAction: isValueInStringEnum(
              AdditionalAction,
              additionalAction
            )
              ? additionalAction
              : null,
            promotionType: query.get(DeeplinkQueryField.PROMOTION_TYPE),
            promotionValue: query.get(DeeplinkQueryField.PROMOTION_VALUE),
            targetStreamId: query.get(DeeplinkQueryField.STREAM_ID),
            referrerAccountId: query.get(DeeplinkQueryField.REFERRER_ID),
            showOffers: query.get(DeeplinkQueryField.SHOW_OFFERS),
            provider: query.get(DeeplinkQueryField.PROVIDER),
            username: query.get(DeeplinkQueryField.USERNAME),
            targetUid: query.get(DeeplinkQueryField.UID),
            targetSocialGameId: query.get(DeeplinkQueryField.SOCIAL_GAME_ID),
            audienceId: query.get(DeeplinkQueryField.AUDIENCE_ID),
            targetGameId: query.get(DeeplinkQueryField.GAME_ID),
            targetStoryId: query.get(DeeplinkQueryField.STORY_ID),
            targetConversationId: query.get(DeeplinkQueryField.CID),
            trackingId: query.get(DeeplinkQueryField.TRACKING_ID),
            messageUuid: query.get(DeeplinkQueryField.MESSAGE_UUID),
            paymentSummary: query.get(DeeplinkQueryField.PAYMENT_SUMMARY),
            campaignId:
              query.get(DeeplinkQueryField.CAMPAIGN_ID) ||
              (isLpAdditionalAction ? additionalAction : null),
            webview: query.get(DeeplinkQueryField.WEB_VIEW),
            bannerType: query.get(DeeplinkQueryField.BANNER_TYPE),
            isLinkFromWebChat: query.get(LINK_FROM_WEB_CHAT_PARAM),
          };
        } catch (err) {
          return emptyDeepLinkAction;
        }
      } else {
        return emptyDeepLinkAction;
      }
    },
    [isDesktop]
  );

  return {
    extractDeepLinkAction,
    setTaskByTarget,
    setTaskByAdditionalAction,
  };
};

export default useDeepLinkActions;
