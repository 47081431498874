import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  CHAT_CONVERSATIONS_LIST_REACTIONS_LIMIT,
  CHAT_CONVERSATIONS_LIST_REACTIONS_START_TS,
} from "chat/constants";
import { RootState } from "chat/imports/state";
import { useMount } from "chat/imports/utils";
import {
  getIsMessageReactionsEnabled,
  getIsMessageReadIndicatorEnabled,
} from "chat/soc/chatSoc";
import {
  fetchSelfMessagesReactions,
  fetchUnreadMessagesRequest,
  getReactionsByChat,
} from "chat/state";
import chatSelectors from "chat/state/selectors";

const selector = (state: RootState) => ({
  unreadStatus: chatSelectors.getUnreadMessagesStatus(state),
  unreadReactionStatus: chatSelectors.getUnreadReactionStatus(state),
  unreadSelfReactionStatus: chatSelectors.getSelfReactionStatus(state),
  isFetchUnreadMessagesInProgress:
    chatSelectors.getIsUnreadRequestInProgress(state),
  lastReadTs: chatSelectors.getLastReadTs(state),
  isMessageReadIndicatorEnabled: getIsMessageReadIndicatorEnabled(state),
  lastReactionTs: chatSelectors.getLastReactionTimeStamp(state),
  conversationId: chatSelectors.getCurrentConversationId(state),
  isMessageReactionsEnabled: getIsMessageReactionsEnabled(state),
});

export const useMessageAndReactionReadIndication = () => {
  const {
    unreadStatus,
    isFetchUnreadMessagesInProgress,
    lastReadTs,
    isMessageReadIndicatorEnabled,
    conversationId,
    isMessageReactionsEnabled,
    unreadReactionStatus,
    lastReactionTs,
    unreadSelfReactionStatus,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(fetchUnreadMessagesRequest({ start_timestamp: lastReadTs }));
  });

  useMount(() => {
    if (conversationId) {
      dispatch(
        getReactionsByChat({
          conversationId,
          limit: CHAT_CONVERSATIONS_LIST_REACTIONS_LIMIT,
          minMessageTs: CHAT_CONVERSATIONS_LIST_REACTIONS_START_TS,
          lastReactionTs,
        })
      );
    }

    dispatch(
      fetchSelfMessagesReactions({
        startTimestamp: CHAT_CONVERSATIONS_LIST_REACTIONS_START_TS,
        limitPerChat: CHAT_CONVERSATIONS_LIST_REACTIONS_LIMIT,
      })
    );
  });

  useEffect(() => {
    if (
      isMessageReadIndicatorEnabled &&
      !isFetchUnreadMessagesInProgress &&
      unreadStatus
    ) {
      dispatch(fetchUnreadMessagesRequest({ start_timestamp: lastReadTs }));
    }
  }, [
    dispatch,
    unreadStatus,
    isFetchUnreadMessagesInProgress,
    lastReadTs,
    isMessageReadIndicatorEnabled,
  ]);

  useEffect(() => {
    if (isMessageReactionsEnabled && unreadSelfReactionStatus) {
      dispatch(
        fetchSelfMessagesReactions({
          startTimestamp: lastReadTs,
          limitPerChat: CHAT_CONVERSATIONS_LIST_REACTIONS_LIMIT,
          currentConversationId: conversationId || undefined,
        })
      );
    }
  }, [dispatch, unreadSelfReactionStatus]);

  useEffect(() => {
    if (isMessageReactionsEnabled && unreadReactionStatus && conversationId) {
      dispatch(
        getReactionsByChat({
          conversationId,
          limit: CHAT_CONVERSATIONS_LIST_REACTIONS_LIMIT,
          minMessageTs: CHAT_CONVERSATIONS_LIST_REACTIONS_START_TS,
          lastReactionTs,
        })
      );
    }
  }, [dispatch, unreadReactionStatus, unreadSelfReactionStatus]);
};
