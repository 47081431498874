import React, { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { DeleteOption } from "chat/components/ChatMenu/ChatMenuOptions/DeleteOption";
import MuteOption from "chat/components/ChatMenu/ChatMenuOptions/MuteOption";
import PinOption from "chat/components/ChatMenu/ChatMenuOptions/PinOption";
import useLongPressTouch from "chat/hooks/useLongPressTouch";
import { StoredConversation } from "chat/state";
import styles from "src/features/chat/components/conversationsList/ConversationMenu.scss";

const ConversationMenu = ({
  children,
  conversation,
}: {
  children: React.ReactNode;
  conversation: StoredConversation;
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenMenu = () => {
    setOpen(true);

    document.documentElement.style.overflow = "hidden";
  };

  const handleCloseMenu = () => {
    setOpen(false);

    document.documentElement.style.overflow = "";
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    handleOpenMenu();
  };

  const handleOpenChange = (open: boolean) =>
    open ? handleOpenMenu() : handleCloseMenu();

  const longPressProps = useLongPressTouch({
    onLongPress: handleOpenMenu,
  });

  return (
    <div onContextMenu={handleContextMenu}>
      <Popover.Root open={open} onOpenChange={handleOpenChange}>
        <Popover.Anchor {...longPressProps} data-menuopen={open}>
          {children}
        </Popover.Anchor>

        <Popover.Portal>
          <>
            <Popover.Content
              className={styles.content}
              align="start"
              sideOffset={6}
              alignOffset={8}
              onClick={handleCloseMenu}
              data-testid="chat-menu-options"
            >
              <PinOption conversation={conversation} />
              <MuteOption conversation={conversation} />
              <DeleteOption conversation={conversation} />
            </Popover.Content>

            <div className={styles.backdrop} />
          </>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default ConversationMenu;
