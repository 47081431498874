import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loadPoints,
  loadRedeemConfig,
} from "src/features/finance/redeems/api/redeemsApi";
import { isApiError } from "src/features/finance/redeems/imports/utils";
import {
  PointsResponse,
  RedeemConfigResponse,
} from "src/features/finance/redeems/root/type";

interface LoadPointsInfoParams {
  accountId: number;
  userName: string;
}

export const loadPointsInfo = createAsyncThunk<
  PointsResponse,
  LoadPointsInfoParams
>(
  "lwc/pointsUserInfo/get",
  async ({ accountId, userName }, { rejectWithValue }) => {
    try {
      return await loadPoints({
        accountId,
        userName,
      });
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return rejectWithValue(error);
    }
  }
);

interface LoadRedeemConfigParams {
  accountId: number;
}

export const loadRedeemConfigInfo = createAsyncThunk<
  RedeemConfigResponse,
  LoadRedeemConfigParams
>("lwc/redeemConfig/get", async ({ accountId }, { rejectWithValue }) => {
  try {
    return await loadRedeemConfig({
      accountId,
    });
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return rejectWithValue(error);
  }
});
