import {
  type PayloadAction,
  SerializedError,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { DEFAULT_PAGE_SIZE } from "src/features/referralProgram/common/constants";
import {
  AgencyLevel,
  Currency,
  ReferralType,
  ReferredUsersOrder,
  ReferredUsersSortBy,
  TimeRange,
  UserActivity,
} from "src/features/referralProgram/common/enums";
import {
  ActiveTear,
  QueryParams,
  ReferralInvitation,
  ReferredUser,
  ReferredUsersMetaData,
  Tier,
} from "src/features/referralProgram/common/types";
import {
  loadReferralUsersList,
  loadTopReferralUsersList,
  registerReferralUser,
} from "src/features/referralProgram/state/asyncActions";
import { Nullable } from "src/types/common";
import { ApplicationThunk } from "state/types";

export const referralInvitationKey = "referralInvitation";

export const INITIAL_QUERY_PARAMS: QueryParams = {
  referralType: ReferralType.CREATORS,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  order: ReferredUsersOrder.DESC,
  sortBy: ReferredUsersSortBy.EARNED_POINTS,
  activity: UserActivity.ANY,
  timeRange: TimeRange.CURRENT_MONTH,
  nameFilter: "",
};

export interface ReferralProgramState {
  currency: Currency;
  error: Nullable<SerializedError>;
  loading: boolean;
  meta: ReferredUsersMetaData;
  queryParams: QueryParams;
  [referralInvitationKey]: Nullable<ReferralInvitation>;
  referredUsers: Array<ReferredUser>;
  selectedTierIndex: number;
  tier: ActiveTear;
  tiers: Tier[];
  topReferredUsers: Array<ReferredUser>;
}

export const initialState: ReferralProgramState = {
  referredUsers: [],
  topReferredUsers: [],
  meta: {
    pageCount: 1,
    totalEarnedPoints: 0,
    totalNewUsers: 0,
    totalPoints: 0,
    totalRedeemPoints: 0,
    totalUsers: 0,
    totalStreamedDays: 0,
    totalStreamedSeconds: 0,
    totalUsd: 0,
    totalEarnedUsd: 0,
    totalRedeemUsd: 0,
  },
  tiers: [],
  tier: {
    currentMonthRevenuePoints: 0,
    lifetimeRevenuePoints: 0,
    currentTier: AgencyLevel.ROOKIE,
    projectedNextTier: AgencyLevel.BRONZE,
  },
  loading: false,
  error: null,
  queryParams: INITIAL_QUERY_PARAMS,
  currency: Currency.DIAMONDS,
  selectedTierIndex: 0,
  referralInvitation: null,
};

export const persistConfig = {
  whitelist: [referralInvitationKey],
};

const slice = createSlice({
  name: "referralProgram",
  initialState,
  reducers: {
    setQueryParams(state, action: PayloadAction<Partial<QueryParams>>) {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setSelectedTierIndex(state, action: PayloadAction<number>) {
      state.selectedTierIndex = action.payload;
    },
    setCurrency(state, action: PayloadAction<Currency>) {
      state.currency = action.payload;
    },
    setReferralInvitation(state, action: PayloadAction<ReferralInvitation>) {
      state[referralInvitationKey] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        loadReferralUsersList.fulfilled,
        loadTopReferralUsersList.fulfilled
      ),
      (state, action) => {
        state.loading = false;
        state.referredUsers = action.payload.data;
        state.meta = action.payload.meta;

        if (action.payload.meta.tiers?.length && action.payload.meta.tier) {
          state.tiers = action.payload.meta.tiers;
          state.tier = action.payload.meta.tier;
          const selectedTierIndex = state.tiers.findIndex(
            ({ id }) => id === state.tier.currentTier
          );
          state.selectedTierIndex =
            selectedTierIndex < 1 ? 1 : selectedTierIndex; // Rookie tier is not counted, thus index cannot be less than 1
        }
      }
    );
    builder.addMatcher(
      isAnyOf(loadReferralUsersList.pending, loadTopReferralUsersList.pending),
      (state) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        loadReferralUsersList.rejected,
        loadTopReferralUsersList.rejected
      ),
      (state, action) => {
        state.loading = false;
        state.error = action.error;
      }
    );
    builder.addMatcher(
      isAnyOf(registerReferralUser.fulfilled, registerReferralUser.rejected),
      (state) => {
        state[referralInvitationKey] = null;
      }
    );
  },
});

export const {
  setQueryParams,
  setSelectedTierIndex,
  setCurrency,
  setReferralInvitation,
} = slice.actions;

export const referralProgramReducer = slice.reducer;

export const changeQueryParams =
  (queryParams: Partial<QueryParams>): ApplicationThunk =>
  (dispatch) => {
    dispatch(setQueryParams(queryParams));
    dispatch(loadReferralUsersList());
  };

export const resetQueryParams = (): ApplicationThunk => (dispatch) => {
  dispatch(changeQueryParams(INITIAL_QUERY_PARAMS));
};

export const attemptRegisterReferralUser =
  (): ApplicationThunk => async (dispatch, getState) => {
    const referralInvitation = getState().referralProgram.referralInvitation;

    if (referralInvitation) {
      await dispatch(registerReferralUser(referralInvitation));
    }
  };
