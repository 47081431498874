import {
  InvitationStatus,
  InvitationType,
} from "src/features/broadcast/enums/invites";
import { typedDelegateSelectors } from "src/features/broadcast/imports/state";
import {
  Invitation,
  InvitationState,
} from "src/features/broadcast/state/invitationsSlice";
import { isSentInvitation } from "src/features/broadcast/state/utils";

const MAX_ACTIVE_STREAMS = 3;

interface FilterSortParams {
  invitationType: InvitationType;
  limit: number;
  status: InvitationStatus;
}

const filterAndSortInvitationsIds = (
  senderInvitations: Invitation[],
  { invitationType, status, limit }: FilterSortParams
) => {
  const filtered = senderInvitations.filter(
    (inv) =>
      inv.invitationType === invitationType && inv.invitationStatus === status
  );

  return filtered
    .sort((a, b) =>
      isSentInvitation(status)
        ? b.timestamp - a.timestamp
        : a.timestamp - b.timestamp
    )
    .slice(0, limit)
    .map((inv) => inv.recipientAccountId);
};

const localInvitationsSelectors = {
  getSentInvitationsRecipients: (
    state: InvitationState,
    broadcastId: string,
    invitationType: InvitationType,
    acceptedCount: number
  ): string[] => {
    const senderInvitations = state.invitationsBySender[broadcastId] ?? [];

    const maxSentToShow = Math.max(0, MAX_ACTIVE_STREAMS - acceptedCount);

    return filterAndSortInvitationsIds(senderInvitations, {
      invitationType,
      status: InvitationStatus.SENT,
      limit: maxSentToShow,
    });
  },

  getAcceptedInvitationsRecipients: (
    state: InvitationState,
    broadcastId: string,
    invitationType: InvitationType
  ): string[] => {
    const senderInvitations = state.invitationsBySender[broadcastId] ?? [];

    return filterAndSortInvitationsIds(senderInvitations, {
      invitationType,
      status: InvitationStatus.ACCEPTED_BY_USER,
      limit: MAX_ACTIVE_STREAMS,
    });
  },

  getInvitationFlow: (state: InvitationState) => state.invitationFlow,
};

export const invitationsSelectors = typedDelegateSelectors(
  localInvitationsSelectors,
  "invitations"
);
