import { useEffect } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { EventFields } from "@analytics/enums";
import { analyticsDataType } from "src/core/analytics/types";
import { RootState } from "state/delegate";
import { userSelectors } from "state/selectors";

const getImpactOrderId = (accountId: string) =>
  `${format(new Date(), "ddmmyyyyhhmmss")}${accountId}`;

const onlinePurchaseId = 41558;
const signUpCompletedId = 41557;
const purchaseCategory = "coins";
const TIMEOUT_INTERVAL = 5000;

enum ImpactEventStatuses {
  IDENTIFY = "identify",
  TRACK_CONVERSION = "trackConversion",
}

export const useImpactConversionEmit = () => {
  const selector = (state: RootState) => userSelectors.getMyAccountId(state);
  const accountId = useSelector(selector);

  useEffect(() => {
    try {
      window.setTimeout(() => {
        if (!window.ire || !accountId) {
          return;
        }
        /* eslint-disable no-console */
        console.log("Impact IDENTIFY function triggered");

        window.ire(ImpactEventStatuses.IDENTIFY, {
          customerId: accountId,
          customerEmail: "",
        });
      }, TIMEOUT_INTERVAL);
    } catch (e) {
      // noop
    }
  }, [accountId]);
};

export const emitImpactSignUpCompleted = (accountId: string) => {
  if (!window.ire || !accountId) {
    return;
  }
  try {
    /* eslint-disable no-console */
    console.log(
      `Impact TRACK_CONVERSION function triggered with id:${signUpCompletedId}`
    );
    window.ire(
      ImpactEventStatuses.TRACK_CONVERSION,
      signUpCompletedId,
      {
        orderId: getImpactOrderId(accountId),
        customerId: accountId,
        customerEmail: "",
      },
      {
        verifySiteDefinitionMatch: true,
      }
    );
  } catch (e) {
    // noop
  }
};

export const emitImpactPurchase = (analyticsData: analyticsDataType) => {
  if (!window.ire) {
    return;
  }

  try {
    /* eslint-disable no-console */
    console.log(
      `Impact TRACK_CONVERSION function triggered with id:${onlinePurchaseId}`
    );
    window.ire(
      ImpactEventStatuses.TRACK_CONVERSION,
      onlinePurchaseId,
      {
        orderId: analyticsData[EventFields.TRANSACTION_ID],
        customerId: analyticsData[EventFields.LOGGED_IN_ACCOUNT_ID],
        customerEmail: "",
        customerStatus: analyticsData[EventFields.IS_FIRST]
          ? "New"
          : "Returning",
        currencyCode: analyticsData[EventFields.CURRENCY],
        orderPromoCode: "",
        orderDiscount: 0, // TODO: decide how to calculate
        items: [
          {
            subTotal: analyticsData[EventFields.LOCAL_CURRENCY_AMOUNT],
            category: purchaseCategory,
            sku: analyticsData[EventFields.OFFER_ID],
            quantity: 1,
            name: purchaseCategory,
          },
        ],
      },
      {
        verifySiteDefinitionMatch: true,
      }
    );
  } catch (e) {
    // noop
  }
};
