import { StreamEvents } from "src/enums";
// eslint-disable-next-line no-restricted-imports
import {
  VIEWER_SESSION_PREMIUM_MB_START_WATCH,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_UPDATE,
} from "state/actionTypes";
import { getStreamId } from "./commonSelectors";

const initialState = {};

const checkIfStreamDataShouldParsed = ({ streamId, streams }) => {
  if (!streams || streams.length === 0) {
    return true;
  }

  return streams.find(
    (stream) => stream.stream.mbDescriptor.streamId === streamId
  );
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      if (
        action.payload === getStreamId(context) ||
        action.meta.multibroadcastSwitch
      ) {
        return state;
      }

      return initialState;
    }
    case VIEWER_SESSION_UPDATE: {
      if (
        action.payload.multiBroadcast &&
        checkIfStreamDataShouldParsed({
          streamId: getStreamId(context),
          streams: action.payload.multiBroadcast.streams,
        })
      ) {
        const {
          payload: {
            basicInfo: { id, masterListUrl },
            multiBroadcast: { streams = [] },
          },
        } = action;

        if (streams.length === 0) {
          return {};
        }

        const checkOnMainStreamURL = (steamId) =>
          steamId === id ? masterListUrl : "";

        return streams.reduce((acc, currentStream) => {
          const {
            stream: {
              mbDescriptor: { streamId },
              streamURL,
            },
          } = currentStream;

          const prevStreamURL = state[streamId]?.stream?.streamURL;

          acc[streamId] = {
            ...currentStream,
            stream: {
              ...currentStream.stream,
              streamURL:
                streamURL ||
                prevStreamURL ||
                checkOnMainStreamURL(streamId) ||
                "",
            },
          };

          return acc;
        }, {});
      }

      return state;
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.payload?.entities) {
        const { events } = action.payload.entities;

        for (const eventId in events) {
          const event = events[eventId];

          if (event.type === StreamEvents.MULTI_BROADCAST) {
            if (!event.data.streams || event.data.streams.length === 0) {
              return {};
            }

            const updatedStreams = {};

            const stateStreamURLs = {};
            Object.keys(state).forEach((key) => {
              const stream = state[key] && state[key].stream;
              if (stream && stream.streamURL) {
                stateStreamURLs[key] = stream.streamURL;
              }
            });

            event.data.streams.forEach((currentStream) => {
              const {
                stream: {
                  mbDescriptor: { streamId },
                  streamURL,
                },
              } = currentStream;

              const prevStreamURL = stateStreamURLs[streamId];
              const finalStreamURL = streamURL || prevStreamURL || "";

              updatedStreams[streamId] = {
                ...currentStream,
                stream: {
                  ...currentStream.stream,
                  streamURL: finalStreamURL,
                },
              };
            });

            return updatedStreams;
          }
        }
      }

      return state;
    }
    case VIEWER_SESSION_PREMIUM_MB_START_WATCH: {
      const { streamId, streamInfo } = action.payload;

      return {
        ...state,
        [streamId]: {
          ...state[streamId],
          stream: {
            ...state[streamId].stream,
            streamURL: streamInfo.details.stream.masterListUrl,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export const localMultiBroadcastSelectors = {
  getMultiBroadcastStreams: (state) => state,
  getMultiBroadcastStreamById: (state, id) => state[id],
};
