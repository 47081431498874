import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { createLocationStateForChat } from "chat/analytics/createLocationStateForChat";
import { emitLiveStreamWatchClicked } from "chat/analytics/emitLiveStreamWatchClicked";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { StreamSourceChatType } from "chat/enums";
import { fetchBatchStreamStatus } from "chat/imports/api";
import { Typography } from "chat/imports/components";
import {
  HOUR,
  StreamStatus,
  TYPOGRAPHY_TYPE,
  ToastType,
  linkToFunctionIsNotAvailable,
} from "chat/imports/constants";
import { isMoveOneToOneFromChatEnabled } from "chat/imports/environment";
import { useIsOnStream, useToast } from "chat/imports/hooks";
import { RootState } from "chat/imports/state";
import { BasicProfile, Nullable } from "chat/imports/types";
import {
  ensureHttps,
  makeLinkToLiveStream,
  sharedMessages,
} from "chat/imports/utils";
import chatSelectors from "chat/state/selectors";
import { MessageMedia } from "chat/types";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { isBroadcastOneToOneEnabled } from "src/features/broadcastOneToOne/exports/soc";
import styles from "./LiveStreamMessage.scss";
interface LiveStreamMessageProps {
  basicProfile?: BasicProfile;
  conversationId: string;
  media: MessageMedia;
  messageConfig: MessageConfig;
  messageTimestamp?: number;
}
const LiveStreamMessage: React.FC<LiveStreamMessageProps> = ({
  media,
  conversationId,
  messageConfig,
  basicProfile,
  messageTimestamp,
}) => {
  const history = useHistory();
  const isOneToOneEnabled = useSelector(isBroadcastOneToOneEnabled);
  const { media_id, thumbnail_url } = media;
  const [isStreamLive, setIsStreamLive] = useState(false);
  const { notify } = useToast();
  useEffect(() => {
    const fetchStreamStatus = async () => {
      if (
        media_id &&
        messageTimestamp &&
        Date.now() - messageTimestamp < HOUR
      ) {
        const streamStatus = await fetchBatchStreamStatus([media_id]);
        if (streamStatus?.[media_id] === StreamStatus.LIVING) {
          setIsStreamLive(true);
        }
      }
    };
    fetchStreamStatus();
  }, []);
  const ref = useRef<Nullable<HTMLAnchorElement>>(null);
  const chatType = useSelector(
    useCallback(
      (state: RootState) => {
        const conversation = chatSelectors.getConversation(
          state,
          conversationId
        );
        if (conversation?.group_info) {
          return conversation.group_info.familyId
            ? StreamSourceChatType.LIVE_FAMILY
            : StreamSourceChatType.GROUP;
        }

        return StreamSourceChatType.SINGLE;
      },
      [conversationId]
    )
  );
  const handleStreamClick = () => {
    if (isStreamLive) {
      emitLiveStreamWatchClicked({
        chatId: conversationId,
      });
      history.push(makeLinkToLiveStream(media_id));
    } else {
      notify({
        type: ToastType.REGULAR,
        title: sharedMessages.broadcastEnded,
      });
    }
  };
  const isOnStream = useIsOnStream();
  const linkTarget = useMemo(() => {
    if (media_id && isMoveOneToOneFromChatEnabled()) {
      return makeLinkToLiveStream(
        media_id,
        createLocationStateForChat(chatType)
      );
    }

    return linkToFunctionIsNotAvailable;
  }, [media_id, chatType]);
  const testId = `live-stream-message-${media_id}`;
  if (isOneToOneEnabled && isStreamLive) {
    return (
      <div
        className={styles.liveStreamMessageWrapper}
        data-testid={testId}
        onClick={handleStreamClick}
      >
        <img
          className={styles.profileBackground}
          src={ensureHttps(basicProfile?.profilePictureUrl)}
          alt="Profile picture"
        />
        <div className={styles.nameWrapper}>
          <div className={styles.name}>{basicProfile?.firstName}</div>

          <div className={styles.liveBadge}>
            <Typography
              className={styles.liveText}
              type={TYPOGRAPHY_TYPE.HEADLINE4}
            >
              <FormattedMessage id="broadcast.live" defaultMessage="Live" />
            </Typography>
          </div>
        </div>
      </div>
    );
  }
  if (isOneToOneEnabled && !isStreamLive) {
    return (
      <div
        className={styles.completedStreamMessageWrapper}
        data-testid={testId}
        onClick={handleStreamClick}
      >
        <img
          className={styles.profileBackground}
          src={ensureHttps(basicProfile?.profilePictureUrl)}
          alt="Profile picture"
        />
        <div className={styles.nameWrapper}>
          <div className={styles.name}>{basicProfile?.firstName}</div>

          <div className={styles.liveBadge}>
            <Typography
              className={styles.liveText}
              type={TYPOGRAPHY_TYPE.HEADLINE4}
            >
              <FormattedMessage id="broadcast.live" defaultMessage="Live" />{" "}
            </Typography>
            <Typography
              className={styles.completedText}
              type={TYPOGRAPHY_TYPE.HEADLINE4}
            >
              <FormattedMessage
                id="gifts.collection.completed"
                defaultMessage="Completed"
              />
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Link
      to={linkTarget}
      className={classnames(
        styles.root,
        getGroupMessageClassnames(styles, messageConfig)
      )}
      style={
        thumbnail_url
          ? ({
              "--thumbnail": `url(${ensureHttps(thumbnail_url)})`,
            } as CSSProperties)
          : undefined
      }
      data-testid={testId}
      replace={isOnStream}
      ref={ref}
    >
      <div className={styles.title}>
        <FormattedMessage
          id="home-page.header-hav.btn.watch-now"
          defaultMessage="Watch Now"
        />
      </div>
    </Link>
  );
};
export default LiveStreamMessage;
