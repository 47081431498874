/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.MarketingClientVisitEventModel || ($protobuf.roots.MarketingClientVisitEventModel = {});

export const MarketingClientVisitEvent = $root.MarketingClientVisitEvent = (() => {

    function MarketingClientVisitEvent(properties) {
        this.reportedUrlParams = {};
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MarketingClientVisitEvent.prototype.analyticsKey = null;
    MarketingClientVisitEvent.prototype.landingUrl = null;
    MarketingClientVisitEvent.prototype.referrerUrl = null;
    MarketingClientVisitEvent.prototype.utmSource = null;
    MarketingClientVisitEvent.prototype.utmMedium = null;
    MarketingClientVisitEvent.prototype.utmCampaign = null;
    MarketingClientVisitEvent.prototype.reportedUrlParams = $util.emptyObject;

    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientVisitEvent.prototype, "_analyticsKey", {
        get: $util.oneOfGetter($oneOfFields = ["analyticsKey"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientVisitEvent.prototype, "_landingUrl", {
        get: $util.oneOfGetter($oneOfFields = ["landingUrl"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientVisitEvent.prototype, "_referrerUrl", {
        get: $util.oneOfGetter($oneOfFields = ["referrerUrl"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientVisitEvent.prototype, "_utmSource", {
        get: $util.oneOfGetter($oneOfFields = ["utmSource"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientVisitEvent.prototype, "_utmMedium", {
        get: $util.oneOfGetter($oneOfFields = ["utmMedium"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientVisitEvent.prototype, "_utmCampaign", {
        get: $util.oneOfGetter($oneOfFields = ["utmCampaign"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    MarketingClientVisitEvent.create = function create(properties) {
        return new MarketingClientVisitEvent(properties);
    };

    MarketingClientVisitEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.analyticsKey != null && Object.hasOwnProperty.call(message, "analyticsKey"))
            writer.uint32(10).string(message.analyticsKey);
        if (message.landingUrl != null && Object.hasOwnProperty.call(message, "landingUrl"))
            writer.uint32(18).string(message.landingUrl);
        if (message.referrerUrl != null && Object.hasOwnProperty.call(message, "referrerUrl"))
            writer.uint32(26).string(message.referrerUrl);
        if (message.utmSource != null && Object.hasOwnProperty.call(message, "utmSource"))
            writer.uint32(34).string(message.utmSource);
        if (message.utmMedium != null && Object.hasOwnProperty.call(message, "utmMedium"))
            writer.uint32(42).string(message.utmMedium);
        if (message.utmCampaign != null && Object.hasOwnProperty.call(message, "utmCampaign"))
            writer.uint32(50).string(message.utmCampaign);
        if (message.reportedUrlParams != null && Object.hasOwnProperty.call(message, "reportedUrlParams"))
            for (let keys = Object.keys(message.reportedUrlParams), i = 0; i < keys.length; ++i)
                writer.uint32(58).fork().uint32(10).string(keys[i]).uint32(18).string(message.reportedUrlParams[keys[i]]).ldelim();
        return writer;
    };

    MarketingClientVisitEvent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MarketingClientVisitEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MarketingClientVisitEvent(), key, value;
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.analyticsKey = reader.string();
                    break;
                }
            case 2: {
                    message.landingUrl = reader.string();
                    break;
                }
            case 3: {
                    message.referrerUrl = reader.string();
                    break;
                }
            case 4: {
                    message.utmSource = reader.string();
                    break;
                }
            case 5: {
                    message.utmMedium = reader.string();
                    break;
                }
            case 6: {
                    message.utmCampaign = reader.string();
                    break;
                }
            case 7: {
                    if (message.reportedUrlParams === $util.emptyObject)
                        message.reportedUrlParams = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.reportedUrlParams[key] = value;
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MarketingClientVisitEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MarketingClientVisitEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.MarketingClientVisitEvent)
            return object;
        let message = new $root.MarketingClientVisitEvent();
        if (object.analyticsKey != null)
            message.analyticsKey = String(object.analyticsKey);
        if (object.landingUrl != null)
            message.landingUrl = String(object.landingUrl);
        if (object.referrerUrl != null)
            message.referrerUrl = String(object.referrerUrl);
        if (object.utmSource != null)
            message.utmSource = String(object.utmSource);
        if (object.utmMedium != null)
            message.utmMedium = String(object.utmMedium);
        if (object.utmCampaign != null)
            message.utmCampaign = String(object.utmCampaign);
        if (object.reportedUrlParams) {
            if (typeof object.reportedUrlParams !== "object")
                throw TypeError(".MarketingClientVisitEvent.reportedUrlParams: object expected");
            message.reportedUrlParams = {};
            for (let keys = Object.keys(object.reportedUrlParams), i = 0; i < keys.length; ++i)
                message.reportedUrlParams[keys[i]] = String(object.reportedUrlParams[keys[i]]);
        }
        return message;
    };

    MarketingClientVisitEvent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.objects || options.defaults)
            object.reportedUrlParams = {};
        if (message.analyticsKey != null && message.hasOwnProperty("analyticsKey")) {
            object.analyticsKey = message.analyticsKey;
            if (options.oneofs)
                object._analyticsKey = "analyticsKey";
        }
        if (message.landingUrl != null && message.hasOwnProperty("landingUrl")) {
            object.landingUrl = message.landingUrl;
            if (options.oneofs)
                object._landingUrl = "landingUrl";
        }
        if (message.referrerUrl != null && message.hasOwnProperty("referrerUrl")) {
            object.referrerUrl = message.referrerUrl;
            if (options.oneofs)
                object._referrerUrl = "referrerUrl";
        }
        if (message.utmSource != null && message.hasOwnProperty("utmSource")) {
            object.utmSource = message.utmSource;
            if (options.oneofs)
                object._utmSource = "utmSource";
        }
        if (message.utmMedium != null && message.hasOwnProperty("utmMedium")) {
            object.utmMedium = message.utmMedium;
            if (options.oneofs)
                object._utmMedium = "utmMedium";
        }
        if (message.utmCampaign != null && message.hasOwnProperty("utmCampaign")) {
            object.utmCampaign = message.utmCampaign;
            if (options.oneofs)
                object._utmCampaign = "utmCampaign";
        }
        let keys2;
        if (message.reportedUrlParams && (keys2 = Object.keys(message.reportedUrlParams)).length) {
            object.reportedUrlParams = {};
            for (let j = 0; j < keys2.length; ++j)
                object.reportedUrlParams[keys2[j]] = message.reportedUrlParams[keys2[j]];
        }
        return object;
    };

    MarketingClientVisitEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MarketingClientVisitEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MarketingClientVisitEvent";
    };

    return MarketingClientVisitEvent;
})();

export { $root as default };
