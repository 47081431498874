import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { networkAvailabilitySelectors } from "state/selectors";

export const useNetworkReconnectWatchdog = () => {
  const [keyBuster, setKeyBuster] = useState(0);
  const isOnline = useSelector(networkAvailabilitySelectors.isNetworkAvailable);
  useEffect(() => {
    setKeyBuster((x) => x + 1);
  }, [isOnline]);

  return keyBuster;
};

export default useNetworkReconnectWatchdog;
