import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PullToRefresh from "pulltorefreshjs";
import { DeviceType } from "src/enums";
import { getIsPullToRefreshEnabled } from "state/abTests";
import {
  bottomScreenSelectors,
  deviceInfoSelectors,
  dropdownMenuSelectors,
} from "state/selectors";
import {
  baseLinkToLeaders,
  linkToBasePath,
  linkToChat,
  linkToFollowingTab,
  linkToLiveStream,
} from "ui/navigation/links";
import { isStandalone } from "utils/locationUtils";
import styles from "./PullToRefresh.scss";

const linksForPullToRefresh = [
  linkToChat,
  linkToLiveStream,
  baseLinkToLeaders,
  linkToFollowingTab,
];

function getScrollableParent(el: Element | null) {
  let innerEL: Element | null = el;

  while (innerEL && innerEL !== document.body) {
    const style = window.getComputedStyle(innerEL);
    const overflowY = style.overflowY;
    if (
      (overflowY === "auto" || overflowY === "scroll") &&
      innerEL.scrollHeight > innerEL.clientHeight
    ) {
      return innerEL;
    }
    innerEL = innerEL.parentElement;
  }

  return document.scrollingElement || document.documentElement;
}

function isElementScrolledToTop(el: Element) {
  if (el.scrollHeight <= el.clientHeight) {
    return true;
  }
  const firstChild = el.firstElementChild;
  if (!firstChild) {
    return true;
  }
  const elRect = el.getBoundingClientRect();
  const childRect = firstChild.getBoundingClientRect();

  return Math.abs(childRect.top - elRect.top) < 2;
}

let lastTouchTarget: Element | null = null;
window.addEventListener(
  "touchstart",
  (e) => {
    lastTouchTarget = e.target instanceof Element ? e.target : null;
  },
  { passive: true }
);

const isPathNameMatchList = (pathName: string, routeList: string[]) =>
  routeList.some((route) => pathName.includes(route));

const getCustomShouldPullToRefresh =
  (isPullToRefreshActive: boolean, pathname: string) => () => {
    if (
      (!isPathNameMatchList(pathname, linksForPullToRefresh) &&
        pathname !== linkToBasePath) ||
      !isPullToRefreshActive
    ) {
      return false;
    }

    const scrollableParent = getScrollableParent(lastTouchTarget);

    if (
      scrollableParent === document.body ||
      scrollableParent === document.documentElement
    ) {
      return window.scrollY === 0;
    }

    return isElementScrolledToTop(scrollableParent);
  };

const spinnerMarkup = `
  <div class="${styles.spinner} ${styles.center}">
    ${Array.from({ length: 12 })
      .map(() => `<div class="${styles["spinner-blade"]}"></div>`)
      .join("")}
  </div>
`;

export const PullToRefreshComponent = () => {
  const isBottomScreenOpened = useSelector(bottomScreenSelectors.screenType);
  const isPullToRefreshEnabled = useSelector(getIsPullToRefreshEnabled);
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const { isAvatarMenuVisible = false } = useSelector(
    dropdownMenuSelectors.getDropdownMenuState
  );
  const isIOS = deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD;
  const { pathname } = useLocation();

  const hasToEnablePullToRefresh =
    isIOS &&
    isStandalone &&
    isPullToRefreshEnabled &&
    (isPathNameMatchList(pathname, linksForPullToRefresh) ||
      pathname === linkToBasePath);

  useEffect(() => {
    if (!hasToEnablePullToRefresh) {
      return;
    }

    const ptr = PullToRefresh.init({
      mainElement: "body",
      onRefresh() {
        window.location.reload();
      },
      shouldPullToRefresh: getCustomShouldPullToRefresh(
        !(isBottomScreenOpened || isAvatarMenuVisible),
        pathname
      ),
      instructionsPullToRefresh: " ",
      instructionsReleaseToRefresh: " ",
      instructionsRefreshing: " ",
      getMarkup: () => spinnerMarkup,
    });

    return () => {
      ptr.destroy();
    };
  }, [
    hasToEnablePullToRefresh,
    isBottomScreenOpened,
    pathname,
    isAvatarMenuVisible,
  ]);

  return null;
};
