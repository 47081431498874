import React from "react";
import classnames from "classnames";
import { Skeleton } from "src/features/chat/imports/components";
import { Breakpoints } from "src/features/chat/imports/constants";
import { useBreakpointPrecise } from "src/features/chat/imports/hooks";
import styles from "src/features/chat/components/Mos/MosContainerSkeleton.scss";

const mosCountByBreakpoint = {
  [Breakpoints.MOBILE]: 7,
  [Breakpoints.SMALL_MOBILE]: 7,
  [Breakpoints.TABLET]: 12,
  [Breakpoints.DESKTOP]: 11,
};

export const MosContainerSkeleton = () => {
  const breakpoint = useBreakpointPrecise();

  const isShown = breakpoint === Breakpoints.DESKTOP;

  const mosCount = mosCountByBreakpoint[breakpoint];

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      {isShown && (
        <div className={styles.navCircle}>
          <Skeleton />
        </div>
      )}
      <div className={styles.mosList}>
        {Array.from({ length: mosCount }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={styles.mosBlock}>
            <Skeleton className={styles.image} />
            <Skeleton className={styles.price} />
          </div>
        ))}
      </div>
      {isShown && (
        <div className={styles.navCircle}>
          <Skeleton />
        </div>
      )}
    </div>
  );
};
