import { createSlice } from "@reduxjs/toolkit";
import { MosError } from "chat/imports/constants";
import { MosV2State } from "chat/imports/state";
import { getMosV2ActionForChat } from "src/features/chat/state/actionCreators";

const initialState: MosV2State = {
  error: null,
  loading: false,
  items: [],
  lineupId: null,
};

const slice = createSlice({
  name: "mosChatV2",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMosV2ActionForChat.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getMosV2ActionForChat.fulfilled, (state, { payload }) => {
        state.items = payload.items;
        state.lineupId = payload.lineupId;
        state.loading = false;
      })
      .addCase(getMosV2ActionForChat.rejected, (state, { payload }) => {
        state.error = payload?.message ?? MosError.SOMETHING_WENT_WRONG;
        state.loading = false;
      });
  },
});

export const mosChatV2Reducer = slice.reducer;
