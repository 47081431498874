import React, { useCallback } from "react";
import AppleLogin from "react-apple-login";
import { useDispatch } from "react-redux";
import { Breakpoints } from "src/enums";
import {
  getAppleClientId,
  getAppleProviderEnabled,
} from "src/features/signin/imports/environment";
import {
  ButtonVariant,
  useBreakpointPrecise,
} from "src/features/signin/imports/ui";
import { getAppleRedirectURI } from "src/features/signin/imports/utils";
import { LoginAppleButtonProps } from "src/features/signin/modal/login/components/LoginAppleButton/types";
import ProviderButton from "src/features/signin/modal/login/components/ProviderButton";
import { openAppleAccountRestoration } from "src/state/actionCreators/modal";
import { ComponentWithClassName } from "src/types/common";

const LoginAppleButton: ComponentWithClassName<LoginAppleButtonProps> = ({
  buttonVariant = ButtonVariant.SECONDARY,
  provider,
  type,
  loginTypeVariant,
  className,
  buttonText,
  onLoginSuccess,
}) => {
  const dispatch = useDispatch();
  const isMobile = useBreakpointPrecise() !== Breakpoints.DESKTOP;

  const getOnProviderClick = useCallback(
    () => dispatch(openAppleAccountRestoration({ isMobile })),
    [dispatch, isMobile]
  );

  const renderButton = useCallback(
    () => (
      <ProviderButton
        buttonVariant={buttonVariant}
        key={provider}
        provider={provider}
        loginTypeButtonVariant={loginTypeVariant}
        onClick={getOnProviderClick}
        type={type}
        className={className}
        buttonText={buttonText}
        onLoginSuccess={onLoginSuccess}
      />
    ),
    [
      buttonVariant,
      provider,
      loginTypeVariant,
      getOnProviderClick,
      type,
      className,
      buttonText,
      onLoginSuccess,
    ]
  );

  if (!getAppleProviderEnabled()) {
    return null;
  }

  return (
    <AppleLogin
      clientId={getAppleClientId()}
      redirectURI={getAppleRedirectURI()}
      scope="email name"
      usePopup
      responseMode="form_post"
      render={renderButton}
    />
  );
};

export default LoginAppleButton;
