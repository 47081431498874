import {
  ChatSource,
  ConversationsListItemClickedText,
  UIActionMenuTarget,
} from "chat/enums";
import { EventFields, SCREEN_NAME, emitUiAction } from "chat/imports/analytics";
import isGroupChatId from "chat/utils/isGroupChatId";

interface EmitConversationsListItemClickedParams {
  chatId: string;
  isFamily?: boolean;
  target: UIActionMenuTarget;
}

export const emitChatMenuOptionClicked = ({
  target,
  chatId,
  isFamily,
}: EmitConversationsListItemClickedParams) => {
  let text = ConversationsListItemClickedText.SINGLE;
  const isGroupChat = isGroupChatId(chatId);

  if (isGroupChat) {
    text = ConversationsListItemClickedText.GROUP;
  }

  if (isFamily) {
    text = ConversationsListItemClickedText.FAMILY;
  }

  emitUiAction({
    target,
    tango_screen: SCREEN_NAME.MAIN_CONVERSATIONS,
    additionalParams: {
      [EventFields.TEXT]: text,
      [EventFields.CHAT_ID]: chatId,
      [EventFields.SHARE_SOURCE_ID]: ChatSource.CHAT,
    },
  });
};
