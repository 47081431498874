export class LocalStorage implements Storage {
  private readonly prefix: string;

  constructor() {
    this.prefix = "dataMesh_";
  }

  clear() {
    for (const key of Object.keys(localStorage)) {
      if (key.includes(this.prefix)) {
        localStorage.removeItem(key);
      }
      this.removeItem(key);
    }
  }

  getItem(key: string) {
    return localStorage.getItem(`${this.prefix}${key}`);
  }

  key(index: number) {
    return localStorage.key(index);
  }

  removeItem(key: string) {
    localStorage.removeItem(`${this.prefix}${key}`);
  }

  setItem(key: string, value: string) {
    localStorage.setItem(`${this.prefix}${key}`, value);
  }

  get length() {
    return Object.keys(localStorage).filter((key) => key.includes(this.prefix))
      .length;
  }
}
