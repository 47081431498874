import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { BannerConfig } from "src/types/banner";

interface BannersConfigResponse {
  banners?: BannerConfig[];
  version: string;
}
export const fetchBannerConfigs = (): Promise<BannersConfigResponse> =>
  fetch(`${getProxycadorHost()}/bannerator/banners/search`, {
    method: "GET",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Accept: "application/json; charset=UTF-8",
    },
  }).then((resp) => resp.json());
