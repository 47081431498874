import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
import { Select, SelectOption } from "legal/imports/components";
import { languageByIsoCode } from "legal/imports/constants";
import { useBreakpointPrecise } from "legal/imports/hooks";
import styles from "./LegalLanguagePicker.scss";

const languageOptions: SelectOption[] = [
  { label: languageByIsoCode.en, value: "" },
  { label: languageByIsoCode.tr, value: "tr" },
];

const LegalLanguagePicker = () => {
  const history = useHistory();
  const location = useLocation();
  const { locale } = useParams<{ locale: string }>();
  const breakpoint = useBreakpointPrecise();

  const currentLanguage =
    languageOptions.find(({ value }) => value === locale) || languageOptions[0];

  const onLanguageChange = (selectedOption?: SelectOption) => {
    if (!selectedOption) {
      return;
    }

    const { value } = selectedOption;

    const currentPath = location.pathname.replace(`/${locale}`, "");
    const newPath = value === "" ? currentPath : `/${value}${currentPath}`;

    history.push(newPath);
  };

  return (
    <Select
      onChange={onLanguageChange}
      value={currentLanguage}
      options={languageOptions}
      className={classnames(styles.root, styles[breakpoint])}
      containerClassName={classnames(styles.container, styles[breakpoint])}
    />
  );
};

export default LegalLanguagePicker;
