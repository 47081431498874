import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { MAX_NOTIFICATIONS } from "src/constants";
import { getShowNativeNotificationForTangoPush } from "state/abTests";
import { deviceInfoSelectors, notificationsSelectors } from "state/selectors";
import { actionCreators } from "state/tree/notifications";
import usePortalElement from "ui/hooks/usePortalElement";
import FallbackNotification from "ui/notification/FallbackNotification";
import NativeNotification from "ui/notification/NativeNotification";
import { useMount } from "utils/miniReactUse";
import styles from "./NotificationsController.scss";

const useUpdateNotificationsStatus = () => {
  const dispatch = useDispatch();

  useMount(() => {
    const notificationsSupported = "Notification" in window;

    batch(() => {
      dispatch(
        actionCreators.setNotificationsSupported(notificationsSupported)
      );
      notificationsSupported &&
        dispatch(
          actionCreators.setNotificationPermission(
            // eslint-disable-next-line compat/compat
            window.Notification.permission
          )
        );
    });
  });
};

const notificationsSelector = (state) => ({
  canUseNativeNotifications:
    notificationsSelectors.areNotificationsAllowed(state) &&
    getShowNativeNotificationForTangoPush(state),
  isWebView: deviceInfoSelectors.getWebview(state),
  notificationIds: notificationsSelectors.getNotificationIds(state),
});

const NotificationsController = () => {
  useUpdateNotificationsStatus();
  const { canUseNativeNotifications, isWebView, notificationIds } = useSelector(
    notificationsSelector,
    shallowEqual
  );
  const dispatch = useDispatch();
  const el = usePortalElement(
    notificationIds.length && !canUseNativeNotifications
  );
  useEffect(() => {
    if (notificationIds.length > MAX_NOTIFICATIONS) {
      dispatch(
        actionCreators.clearNotifications(
          notificationIds.slice(0, notificationIds.length - MAX_NOTIFICATIONS)
        )
      );
    }
  }, [dispatch, notificationIds]);

  if (isWebView || !notificationIds.length) {
    return null;
  }

  const notificationsSlice = notificationIds.slice(-MAX_NOTIFICATIONS);

  if (canUseNativeNotifications) {
    return (
      <>
        {notificationsSlice.map((x) => (
          <NativeNotification key={x} notificationId={x} />
        ))}
      </>
    );
  }

  return ReactDOM.createPortal(
    <div className={styles.root}>
      {notificationsSlice.map((x) => (
        <FallbackNotification key={x} notificationId={x} />
      ))}
    </div>,
    el
  );
};

export default NotificationsController;
