import { useRef } from "react";

const useIsFirstRender = () => {
  const ref = useRef(true);
  const isFirstRender = ref.current;

  ref.current = false;

  return isFirstRender;
};

export default useIsFirstRender;
