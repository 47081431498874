import React, { memo } from "react";
import { FormattedMessage, defineMessage } from "react-intl";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import DisplayName from "ui/common/DisplayName";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { AllTypographyTypes } from "ui/common/typography/types";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useProfileById from "ui/hooks/useProfileById";
import { usePauseDisclaimerContext } from "ui/scenes/stream/common/PauseDisclaimerContext";
import { ReactComponent as PauseSquircleIcon } from "img/ic_pause_squircle.svg";
import styles from "./PauseDisclaimer.scss";
import fadeTransition from "./competition/components/competitionOverlay/CompetitionOverlayFadeTransition.scss";

interface PauseDisclaimerProps {
  accountId: string;
  descriptionClassName?: string;
  pipMode?: boolean;
  showIcon?: boolean;
  typographyType?: AllTypographyTypes;
  visible?: boolean;
}

const defaultPauseMessage = defineMessage({
  id: "will.be.back.soon",
  defaultMessage: "will be back soon...",
});

const PauseDisclaimer: ComponentWithClassName<PauseDisclaimerProps> = ({
  accountId,
  visible,
  showIcon = true,
  className,
  descriptionClassName,
  typographyType = TYPOGRAPHY_TYPE.PARAGRAPH3,
  pipMode = false,
}) => {
  const { basicProfile } = useProfileById(accountId);
  const breakpoint = useBreakpoint();
  const { pauseMessage: contextPauseMessage } = usePauseDisclaimerContext();

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      mountOnEnter
      unmountOnExit
      classNames={fadeTransition}
    >
      <Typography
        type={typographyType}
        className={classnames(
          styles.root,
          {
            [styles.mobile]: breakpoint !== Breakpoints.DESKTOP,
            [styles.pip]: pipMode,
          },
          className
        )}
        data-testid="paused-overlay"
        as="div"
      >
        {showIcon && <PauseSquircleIcon className={styles.icon} />}
        <div className={descriptionClassName}>
          <Typography
            type={TYPOGRAPHY_TYPE.HEADLINE4}
            as="p"
            className={classnames(styles.broadcasterName)}
          >
            <DisplayName basicProfile={basicProfile} />
          </Typography>
          <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3}>
            <FormattedMessage
              {...(contextPauseMessage || defaultPauseMessage)}
            />
          </Typography>
        </div>
      </Typography>
    </CSSTransition>
  );
};

export default memo(PauseDisclaimer);
