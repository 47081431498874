import React, { FC, memo } from "react";
import ArticleMeta from "legal/components/ArticleMeta/ArticleMeta";
import { Headline, Paragraph } from "legal/components/typography";
import { SCREEN_NAME, ScreenViewReporter } from "legal/imports/analytics";
import { FormattedMessageTitle } from "legal/imports/components";
import { legalMessages } from "legal/imports/constants";
import {
  LinkToCopyright,
  LinkToFAQ,
  LinkToTermsOfUse,
  MailToSupport,
} from "legal/links";
import styles from "legal/common/LegalArticle.scss";

/* eslint-disable react/jsx-no-literals, react/no-unescaped-entities */
const CommunityGuidelines: FC = () => (
  <>
    <ScreenViewReporter name={SCREEN_NAME.LEGAL_COMMUNITY_GUIDELINES} />
    <FormattedMessageTitle {...legalMessages.communityGuidelines} />

    <article className={styles.article}>
      <ArticleMeta postDate="Last Modified: 03.10.2023" withLanguagePicker />

      <Headline level={1}>Community Guidelines</Headline>

      <Paragraph>
        At Tango, we strive to empower everyone to seize the magic of the moment
        through live-streaming. Our community is built on a foundation of
        openness and respect, and we welcome users and broadcasters ("
        <b>Members</b>") from all backgrounds to foster a supportive and
        inclusive environment with us.
      </Paragraph>
      <Paragraph>
        These Community Guidelines ("<b>Community Guidelines</b>") are a set of
        norms and expectations that are intended to design a safe and welcoming
        space for everyone while allowing Members the freedom to create and
        share a range of experiences and perspectives. These Community
        Guidelines apply to all accounts and content, including live-streaming
        videos, text, comments, links, and chats. By using Tango, you hereby
        accept these Community Guidelines, which are integrated into and form a
        part of Tango's <LinkToTermsOfUse>Terms of Use</LinkToTermsOfUse>.
      </Paragraph>
      <Paragraph>
        We take violations of these Community Guidelines very seriously and use
        a combination of technology and human moderation measures to proactively
        enforce our policies. We encourage all Members to report any content or
        accounts which they believe violate these Community Guidelines, and our
        team will investigate the report and take the necessary action to ensure
        the safety of our Members. This can be done via <MailToSupport />.
      </Paragraph>

      <Headline level={2}>Personal Safety</Headline>
      <Paragraph>
        We prioritize the safety of our Members and we have a zero-tolerance
        policy towards any activities that may jeopardize their well-being. We
        expect all Members to refrain from streaming, distributing, sharing, or
        posting any activities that could be harmful to themselves or others.
        These prohibited activities include but are not limited to the use of
        drugs, alcohol, weapons, or any other dangerous products; engaging or
        supporting terrorism, money laundering, organized crimes, or any
        activities threatening public's safety; extremism; endangering minors'
        safety, including grooming, exploitation, abuse, or assault; self-harm,
        suicide, or any act that may cause harm to oneself or others; violence,
        harm, reckless behavior, vandalism or other dangerous activities.
      </Paragraph>
      <Paragraph>
        We take such activities very seriously and any accounts associated with
        them may be immediately suspended. If you come across any content that
        you believe may be harmful or dangerous, please report it to us
        immediately.
      </Paragraph>

      <Headline level={2}>Sexual activities</Headline>
      <Paragraph>
        One of our key principles is the prohibition of sexually explicit or
        suggestive content that may make Members feel uncomfortable,
        objectified, or threatened. Any form of inappropriate live-streaming
        videos, images, or audio content is strictly prohibited. This also
        extends to any material that is sexual or pornographic, including but
        not limited to sexually explicit language, gestures, and emojis.
        Furthermore, offering sexual services or sexual interactions of any kind
        within Tango is not acceptable. Any such behavior will result in
        immediate account removal and potential reporting to relevant
        authorities.
      </Paragraph>

      <Headline level={2}>Respect community Members</Headline>
      <Paragraph>
        We expect all Members to interact and treat each other with respect and
        dignity. We prohibit any form of hate speech, violence, harassment, and
        bullying. We do not tolerate any material or comments that promote
        prejudice or discrimination against our Members, based on their
        ethnicity, race, gender, sexual orientation, religion, disability, or
        any other personal attribute. We understand that disagreements and
        conflicts may arise, but we ask that all Members approach these
        situations civilly and respectfully.
      </Paragraph>

      <Headline level={2}>Authenticity</Headline>
      <Paragraph>
        We value authenticity and originality in the content that our Members
        create and share. We believe that every member has a unique perspective
        and voice that can contribute to our community's diversity. As such, we
        encourage our Members to post their own original content and respect
        their ownership of that material. We also have a responsibility to
        maintain the integrity of Tango and ensure that all content is truthful,
        accurate, and not intentionally misleading. We do not tolerate any
        content that misrepresents the identity of other Members or is designed
        to deceive our Members. This includes deep-fakes, manipulated, or edited
        content that alters the original context of the material.
      </Paragraph>

      <Headline level={2}>Protect personal information</Headline>
      <Paragraph>
        It is strictly prohibited to share or post personal information about
        others without their consent. This includes but is not limited to
        live-streaming, photos, videos, profiles, full names, addresses, phone
        numbers, or email addresses. We also do not allow the sharing of
        personal information that you do not have the right to share, such as
        confidential or sensitive information that is protected by law. In
        addition, we urge our Members to exercise caution when sharing their own
        personal information on Tango or elsewhere online, including sensitive
        information such as financial information, social security numbers, or
        passwords, as this information can be used for identity theft and fraud.
      </Paragraph>

      <Headline level={2}>Protect intellectual property rights</Headline>
      <Paragraph>
        We believe that respecting the creative works of others is crucial to
        fostering a positive and respectful environment. We encourage our
        Members to always respect the intellectual property rights of others and
        to obtain proper permissions and licenses before using copyrighted
        works. We strictly prohibit the uploading, posting, broadcasting, or
        sharing of any content that infringes on someone else's intellectual
        property or copyright. This includes the unauthorized use of copyrighted
        works such as images, videos, sounds, art, or music.
      </Paragraph>
      <Paragraph>
        If you believe that your intellectual property rights have been
        infringed upon by Members, we urge you to report the infringement to us
        so that we can take appropriate action. We have created a{" "}
        <LinkToCopyright>Copyright Policy Page</LinkToCopyright> where Members
        can learn more about our policies and procedures.
      </Paragraph>

      <Headline level={2}>Malicious and deceptive practices</Headline>
      <Paragraph>
        We prohibit any attempts to compromise or abuse the security,
        reliability, or integrity of Tango. This includes but is not limited to
        accessing Tango's platform through unauthorized means, such as hacking
        or using stolen login credentials. We also prohibit spamming, fake
        engagement, artificially collecting followers or gifts, or any other
        deceitful practices that could deceive our Members.
      </Paragraph>
      <Paragraph>
        In addition, we do not tolerate any content or activity that interferes
        with the integrity of Tango's services or the experience of another user
        or their devices. This includes spreading viruses, malware, or other
        harmful software or engaging in any activity that could harm the
        functionality or performance of Tango.
      </Paragraph>

      <Headline level={2}>Respect the law</Headline>
      <Paragraph>
        We expect all of our Members to adhere to applicable local, state,
        national, and international laws and regulations. We rely on our
        Members' support to identify and eliminate any inappropriate or illegal
        content on Tango. If you come across any content that you believe
        violates our community guidelines or is deemed inappropriate or
        offensive, we urge you to report it to us immediately. We will
        investigate any reports of inappropriate or illegal content and take the
        necessary action, which may include removing the content and notifying
        the appropriate authorities if necessary. We also remind our Members
        that they are responsible for their own actions on Tango and that they
        will be held accountable for any violations of the law.
      </Paragraph>

      <Paragraph>
        For more practical information on our Community Guidelines, or if you
        have any questions, please visit our <LinkToFAQ />.
      </Paragraph>
    </article>
  </>
);

export default memo(CommunityGuidelines);
