import { RefObject } from "react";

export const getVisibleElementsIndexes = (
  rootRef: RefObject<HTMLDivElement>,
  selector: string
) => {
  if (!rootRef?.current) {
    return [];
  }

  const container = rootRef.current;
  const containerRect = container.getBoundingClientRect();

  const elements = Array.from(container.querySelectorAll(selector));

  return elements
    .map((element, index) => ({ element, initialIndex: index }))
    .filter(({ element }) => {
      const rect = element.getBoundingClientRect();

      const isVisibleVertically =
        rect.top < containerRect.bottom && rect.bottom > containerRect.top;
      const isVisibleHorizontally =
        rect.left < containerRect.right && rect.right > containerRect.left;

      return isVisibleVertically && isVisibleHorizontally;
    })
    .map(({ initialIndex }) => initialIndex);
};
