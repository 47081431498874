import { defineMessages } from "react-intl";

export default defineMessages({
  nftInfoTitle: {
    id: "what_is_tango_cards_auction_header",
    defaultMessage: "What is the Tango Cards Auction?",
  },
  pageTitle: {
    id: "tango_cards_auction",
    defaultMessage: "Tango Cards Auction",
  },
  allCards: {
    id: "auction_all_bids_header",
    defaultMessage: "All Tango Cards",
  },
  myBids: {
    id: "auction_broadcaster_bids_cards",
    defaultMessage: "My Bids",
  },
  myCard: {
    id: "auction_broadcaster_my_card",
    defaultMessage: "My Card",
  },
  refreshButton: {
    id: "auction_broadcaster_refresh_button",
    defaultMessage: "Refresh",
  },
  infoButton: {
    id: "auction_broadcaster_info_button",
    defaultMessage: "info",
  },
  finished: {
    id: "auction_finished",
    defaultMessage: "Finished",
  },
  processingLots: {
    id: "auction_processing",
    defaultMessage: "Last call!",
  },
  about: {
    id: "modal.edit-profile.status",
    defaultMessage: "About",
  },
  noBids: {
    id: "no_bids_text",
    defaultMessage: "No bids yet",
  },
  raise: {
    id: "action_raise",
    defaultMessage: "Raise",
  },
  bet: {
    id: "action_bet",
    defaultMessage: "Bid",
  },
  startBid: {
    id: "start_bid",
    defaultMessage: "Start bid",
  },
  currentBid: {
    id: "current_bid",
    defaultMessage: "Current bid",
  },
  noCards: {
    id: "no_cards",
    defaultMessage: "No Cards in this category",
  },
  marketplace: {
    id: "marketplace",
    defaultMessage: "Marketplace",
  },
  cardOwned: {
    id: "card_owned",
    defaultMessage: "Owned for",
  },
  buyNow: {
    id: "buy_now",
    defaultMessage: "Buy Now",
  },
});
