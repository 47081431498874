import { mobileTypes } from "src/constants";
import { DeviceInfoState } from "state/types";

export const selectors = {
  isAnyMobileDevice: (state: DeviceInfoState) =>
    mobileTypes.includes(state.type),
  isDeviceFingerprintingInProgress: (state: DeviceInfoState) =>
    state.fingerprintingInProgress,
  getDeviceFingerprint: (state: DeviceInfoState) => state.fingerprint,
  getDeviceLocale: (state: DeviceInfoState) => state.locale,
  getDeviceType: (state: DeviceInfoState) => state.type,
  getDeviceOS: (state: DeviceInfoState) => state.os,
  getDeviceMajorVersion: (state: DeviceInfoState) =>
    state.version ? parseInt(state.version) : null,
  getBrowserName: (state: DeviceInfoState) => state.browserName,
  getBrowserVersion: (state: DeviceInfoState) => state.browserVersion,
  getWebview: (state: DeviceInfoState) => state.webview,
  getDevice: (state: DeviceInfoState) =>
    `${state.os}_${state.browserName}_${state.version}`,
  getDeviceVersion: (state: DeviceInfoState) => state.version,
  getDeviceGPUModel: (state: DeviceInfoState) => state.gpuModel,
};
