import { emitEvent } from "@analytics/emit";
import {
  BI_LANDING_FIELD,
  EventFields,
  EventNames,
  PERSONAL_OFFERS_SOURCE,
  SCREEN_NAME,
} from "@analytics/enums";
import { Nullable } from "src/types/common";
import { PersonalOffersConfig } from "src/types/personalOffer";
import { omit } from "src/utils/miniLodash";

interface EmitPersonalOffersEventProps {
  parseNewConfig: PersonalOffersConfig;
  parseOldConfig: Nullable<PersonalOffersConfig> | undefined;
  screenName?: SCREEN_NAME;
  source: PERSONAL_OFFERS_SOURCE;
  startTimestamp: number;
  trackingId?: Nullable<string>;
}

export const emitPersonalOffersEvent = ({
  parseOldConfig,
  parseNewConfig,
  startTimestamp,
  source,
  trackingId,
  screenName,
}: EmitPersonalOffersEventProps) => {
  const oldConfig = omit(parseOldConfig, ["triggerId"]);
  const newConfig = omit(parseNewConfig, ["triggerId"]);
  const isEqualConfig = JSON.stringify(oldConfig) === JSON.stringify(newConfig);

  if (isEqualConfig) {
    emitEvent(EventNames.PERSONAL_OFFERS_SKIPPED, {
      [BI_LANDING_FIELD.TRIGGER_ID]: parseNewConfig.triggerId,
    });

    return;
  }

  emitEvent(EventNames.PERSONAL_OFFERS_RECEIVED, {
    [BI_LANDING_FIELD.TRIGGER_ID]: parseNewConfig.triggerId,
    [BI_LANDING_FIELD.TRACKING_ID]: trackingId,
    [BI_LANDING_FIELD.SOURCE]: source,
    [BI_LANDING_FIELD.TIME_LOADING]: Date.now() - startTimestamp,
    [EventFields.TANGO_SCREEN]: screenName,
  });
};
