import React, { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import {
  MessageAction,
  emitMessageActionEvent,
  getMessageFlag,
} from "chat/analytics/emitMessageActionEvent";
import MessageMenuOptionBase from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuOptionBase";
import { MESSAGE_POPOVER_OPTIONS } from "chat/constants/messageMenuOptionsConfig";
import { ToastType } from "chat/imports/constants";
import { deviceInfoSelectors } from "chat/imports/state";
import { StoredMessage } from "chat/state";
import { MessageType } from "chat/types";
import { useToast } from "src/features/referralProgram/imports/hooks";
import { sharedMessages } from "src/features/referralProgram/imports/messages";

export interface MessageMenuOptionProps {
  closeMenu: VoidFunction;
  message: StoredMessage;
}

const MessageMenuCopyOption: FC<MessageMenuOptionProps> = ({
  message,
  closeMenu,
}) => {
  const { notify } = useToast();
  const locale = useSelector(deviceInfoSelectors.getDeviceLocale);
  const isTextMessage = message.type === MessageType.TEXT_MESSAGE;
  const onCopyHandler = useCallback(() => {
    let text = message?.body || "";

    if (message?.isTranslated) {
      text = message.translation?.[locale] || text;
    }

    copy(text);

    closeMenu();

    notify({
      type: ToastType.REGULAR,
      title: sharedMessages.messageCopied,
    });

    emitMessageActionEvent({
      action: MessageAction.MESSAGE_COPY,
      chatId: message.id.chat_id,
      flag: getMessageFlag(message),
    });
  }, [message, notify, closeMenu]);

  if (!isTextMessage) {
    return null;
  }

  return (
    <MessageMenuOptionBase
      option={MESSAGE_POPOVER_OPTIONS.copy}
      onClick={onCopyHandler}
    />
  );
};

export default MessageMenuCopyOption;
