import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { LegalNavigationConfig } from "legal/types";
import LegalAccordionItem from "./LegalAccordionItem";
import styles from "./LegalAccordion.scss";

type AccordionProps = {
  navigationConfig: LegalNavigationConfig[];
};

function extractActiveItem(
  arr: LegalNavigationConfig[],
  pathname: string
): [LegalNavigationConfig, LegalNavigationConfig[]] {
  const index = arr.findIndex((item) => pathname.includes(item.to));
  const [activeItem] = arr.splice(index, 1);

  return [activeItem, arr];
}

const LegalAccordion: FC<AccordionProps> = ({ navigationConfig }) => {
  const { pathname } = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeItem, restItems] = useMemo(
    () => extractActiveItem([...navigationConfig], pathname),
    [pathname, navigationConfig]
  );
  const { to, message, dataTestId } = activeItem;

  useEffect(() => {
    setIsExpanded(false);
  }, [pathname]);

  const navigationRef = useRef<HTMLDivElement>(null);
  const navigationStyles = useMemo(
    () =>
      isExpanded ? { height: `${navigationRef.current?.scrollHeight}px` } : {},
    [isExpanded]
  );

  const handleClick = useCallback(
    (to) => {
      if (pathname.includes(to)) {
        setIsExpanded((prev) => !prev);
      }
    },
    [pathname]
  );

  return (
    <ul className={classnames(styles.root, !isExpanded && styles.closed)}>
      <LegalAccordionItem
        key={to}
        dataTestId={dataTestId}
        expanded={isExpanded}
        message={message}
        onClick={handleClick}
        to={to}
        active
      />
      <div
        ref={navigationRef}
        className={styles.navigation}
        style={navigationStyles}
      >
        {restItems.map(({ to, message, dataTestId }) => (
          <LegalAccordionItem
            key={to}
            dataTestId={dataTestId}
            expanded={isExpanded}
            message={message}
            onClick={handleClick}
            to={to}
          />
        ))}
      </div>
    </ul>
  );
};

export default LegalAccordion;
