import { datadogLogs } from "@datadog/browser-logs";
import once from "lodash.once";
import { Nullable } from "src/types/common";
import { loadScript } from "utils/scriptUtils";

const SCRIPT_ID = "recaptcha-script";
type ReCaptchaVersion = "v2" | "v3";

let currentVersion: Nullable<ReCaptchaVersion> = null;

export const V2_KEY_ID = "explicit";

export const reCaptchaLoader = once(
  (lang, keyId = V2_KEY_ID) =>
    new Promise<typeof window.grecaptcha>((resolve, reject) => {
      const version = keyId === V2_KEY_ID ? "v3" : "v2";

      if (currentVersion === version && document.getElementById(SCRIPT_ID)) {
        return resolve(window.grecaptcha);
      }

      const existingScript = document.getElementById(SCRIPT_ID);
      if (existingScript) {
        existingScript.remove();
        delete window.grecaptcha;
      }

      const src = `https://www.google.com/recaptcha/enterprise.js?render=${keyId}&hl=${lang}`;

      loadScript({
        src,
        nodeId: SCRIPT_ID,
        async: true,
        defer: true,
        onLoad: () => {
          currentVersion = version;
          if (!window.grecaptcha) {
            datadogLogs.logger.error("Failed to load reCAPTCHA script");

            return reject(new Error("reCAPTCHA script is not initialized"));
          }

          window.grecaptcha?.enterprise.ready(async () =>
            resolve(window.grecaptcha)
          );
        },
        onError: () => {
          datadogLogs.logger.error(`Failed to load reCAPTCHA script ${src}`);
          reject(new Error("Failed to load reCAPTCHA script"));
        },
        isRemoveOnError: true,
      });
    })
);
