import { Dispatch } from "redux";
import Datadog from "@analytics/DatadogUtils";
import { fetchAcmeMessage } from "api/acme";
import { getReceivedAcmeMessageDelay } from "environment";
import { isApiError } from "src/api/utils/enhancedFetch";
import { ReceivedAcmeType } from "src/types/acme";
import { receivedAcme } from "state/actionCreators/connectionManager";
import { actions } from "state/actionCreators/receivedAcmeIds";
import { RootState } from "state/delegate";
import { parseAcme } from "ui/utils/acmeUtils";

// TODO test coverage https://tango-me.atlassian.net/browse/WEB-9058
export const fetchMissedAcme = async (
  dispatch: Dispatch,
  acmeIds: string[],
  username: string
) => {
  try {
    const {
      messages: { message: messageList = [] },
    } = await fetchAcmeMessage(acmeIds, username);

    dispatch(actions.clearAcmeIds());

    messageList.forEach((item) => {
      parseAcme(item, (msg: ReceivedAcmeType) => dispatch(receivedAcme(msg)));
    });
  } catch (error) {
    const errorMessage = "fetchAcmeMessage";

    if (isApiError(error)) {
      return Datadog.error(
        `${errorMessage}: ${error.getMessage()}`,
        error.getDatadogContext(),
        error
      );
    }

    if (error instanceof Error) {
      Datadog.error(errorMessage, {}, error);
    }
  }
};

export default (dispatch: Dispatch, getState: () => RootState) => {
  window.setInterval(async () => {
    const {
      receivedAcmeIds: { acmeIds },
      login: { loggedIn },
      // @ts-ignore should be removed after moving connectionManager to ts
      connectionManager: { username },
    } = getState();

    if (!loggedIn) {
      return;
    }

    dispatch(actions.clearAcmeIds());
    await fetchMissedAcme(dispatch, acmeIds, username);
  }, getReceivedAcmeMessageDelay());
};
