import React, { Suspense, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { getArtistsPageEnabled } from "environment";
import { CUSTOMER_SUPPORT_LINK } from "src/constants";
import { useOpenLoginByMarketingQuery } from "src/features/marketing/exports/hooks";
import { TournamentRoute } from "src/features/visitorTournament/exports/components";
import lazyWithPageReload from "src/utils/lazyWithPageReload";
import { suspensionStatusSelectors } from "state/selectors";
import LoadingComponent from "ui/common/LoadingComponent";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import useEnableBroadcast from "ui/hooks/useEnableBroadcast";
import useLoadBannerConfigs from "ui/hooks/useLoadBannerConfigs";
import useLoadTaxes from "ui/hooks/useLoadTaxes";
import useLoadVipConfigs from "ui/hooks/useLoadVipConfig";
import usePaymentSessionSummary from "ui/hooks/usePaymentSessionSummary";
import LoggedInRoute from "ui/navigation/LoggedInRoute";
import LiveModeRedirector from "ui/scenes/live/LiveModeRedirector";
import UserSuspendedView from "ui/suspension/UserSuspendedView";
import {
  linkToAgencyLandingPage,
  linkToAgencyProgramAgreement,
  linkToAppealPage,
  linkToArtistsClub,
  linkToBasePath,
  linkToBroadcast,
  linkToBroadcastOneToOne,
  linkToBroadcasterAgreement,
  linkToBugBountyProgram,
  linkToCommunityGuidelines,
  linkToCopyright,
  linkToCreators,
  linkToDSAInformation,
  linkToFAQ,
  linkToFirstMarketingPage,
  linkToFunctionIsNotAvailable,
  linkToGiftCards,
  linkToHomeV2Page,
  linkToLandingPagePhoenix,
  linkToLeaderBoard,
  linkToLiveStream,
  linkToOnboardingLandingPage,
  linkToPrivacyPolicy,
  linkToPublisherTermsAndConditions,
  linkToSocialGamesBase,
  linkToTangoProfile,
  linkToTermsOfUse,
  linkToThirdPartyLicense,
  linkToTournamentMatch,
  linkToWebviewApmOffers,
  linkToWebviewCheckout,
  linkToWebviewPaymentButton,
  linkToWebviewSafechargeOffers,
} from "./links";

const OnboardingLanding = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "onboardingLanding" */
      "ui/scenes/landingPage/OnboardingLanding/OnboardingLanding"
    )
);

const LegalRouter = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "legalRouter" */
      "ui/scenes/legal/LegalRouter"
    )
);

const DesktopRouter = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "desktopRouter" */
      "./DesktopRouter"
    )
);

const MobileRouter = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "mobileRouter" */
      "./MobileRouter"
    )
);

const BroadcastPage = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "broadcastPage" */ "stream/components/BroadcastPage/BroadcastPage"
    )
);

const BroadcastOneToOnePage = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "broadcastOneToOnePage" */ "src/features/broadcastOneToOne/pages/BroadcastOneToOnePage"
    )
);

const GiftCard = lazyWithPageReload(
  () => import(/* webpackChunkName: "giftCard" */ "ui/scenes/giftCard/GiftCard")
);

const LandingPagePhoenix = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "landingPagePhoenix" */ "ui/scenes/landingPagePhoenix/LandingPage"
    )
);

const ArtistsClubPage = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "artistsClub" */ "ui/scenes/artistsClub/ArtistsClubPage"
    )
);

const LeaderBoardLanding = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "LeaderBoardLanding" */
      "ui/scenes/landingPage/LeaderBoardLanding/LeaderBoardLanding"
    )
);

const TournamentsLanding = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "TournamentsLanding" */
      "ui/scenes/landingPage/Tournaments/TournamentsLanding"
    )
);

const AppealPage = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "AppealPage" */
      "src/features/appeal/AppealPage/exports"
    )
);

const FunctionIsNotAvailablePage = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "functionIsNotAvailable" */
      "ui/scenes/error/functionIsNotAvailable/FunctionIsNotAvailable"
    )
);

const WebviewSafechargeOffers = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "WebviewSafechargeOffers" */
      "ui/common/webviewOffersView/WebviewSafechargeOffers"
    )
);
const WebviewApmOffers = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "WebviewApmOffers" */
      "ui/common/webviewOffersView/WebviewApmOffers"
    )
);
const CheckoutWebview = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "PaymentWebview" */
      "src/features/finance/checkout/components/webview/CheckoutWebview"
    )
);
const PaymentButtonWebview = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "PaymentWebview" */
      "src/features/finance/checkout/components/webview/PaymentButtonWebview"
    )
);

const SocialGamesRouter = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "SocialGamesRouter" */
      "src/features/socialGames/SocialGamesRouter"
    )
);

const HomePageV2 = lazyWithPageReload(
  () =>
    import(
      /* webpackChunkName: "HomePageV2" */
      "src/features/homePage/v2/exports/HomePageV2"
    )
);

const AgencyLanding = lazyWithPageReload(() =>
  import(
    /* webpackChunkName: "AgencyLanding" */ "src/features/agencyLanding/exports/components"
  ).then((module) => ({ default: module.AgencyLanding }))
);

const FirstMarketingPage = lazyWithPageReload(() =>
  import(
    /* webpackChunkName: "HottestPage" */ "src/features/marketingPages/exports/components"
  ).then((module) => ({ default: module.HottestMarketingPage }))
);

const RedirectToCS = () => {
  useLayoutEffect(() => {
    document.location.replace(CUSTOMER_SUPPORT_LINK);
  }, []);

  return null;
};

export const legalLocalizedPaths = [
  `/:locale${linkToFAQ}`,
  `/:locale${linkToTermsOfUse}`,
  `/:locale${linkToPrivacyPolicy}`,
  `/:locale${linkToCopyright}`,
  `/:locale${linkToThirdPartyLicense}`,
  `/:locale${linkToBroadcasterAgreement}`,
  `/:locale${linkToAgencyProgramAgreement}`,
  `/:locale${linkToCommunityGuidelines}`,
  `/:locale${linkToPublisherTermsAndConditions}`,
  `/:locale${linkToBugBountyProgram}`,
  `/:locale${linkToDSAInformation}`,
];

export const legalPathNames = [
  linkToTermsOfUse,
  linkToPrivacyPolicy,
  linkToCopyright,
  linkToThirdPartyLicense,
  linkToAgencyProgramAgreement,
  linkToBroadcasterAgreement,
  linkToCommunityGuidelines,
  linkToPublisherTermsAndConditions,
  linkToBugBountyProgram,
  linkToDSAInformation,
  linkToFAQ,
];

export const combinedLegalPaths = [...legalPathNames, ...legalLocalizedPaths];

const BasePathRedirect = () => <Redirect to={linkToBasePath} />;

const linksToArtistsPage = [linkToArtistsClub, linkToCreators];

const RootRouter = () => {
  const isMobileLayout = useBreakpointMobileLayout();
  const isSuspended = useSelector(suspensionStatusSelectors.data);

  useLoadTaxes();
  useLoadVipConfigs();
  useLoadBannerConfigs();
  usePaymentSessionSummary();
  useOpenLoginByMarketingQuery();

  const broadcastEnabled = useEnableBroadcast();

  if (isSuspended) {
    return <UserSuspendedView />;
  }

  const restRouter = isMobileLayout ? (
    <Route component={MobileRouter} />
  ) : (
    <Route component={DesktopRouter} />
  );

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Switch>
        {!getArtistsPageEnabled() && (
          <LoggedInRoute
            path={linksToArtistsPage}
            component={BasePathRedirect}
          />
        )}
        {getArtistsPageEnabled() && (
          <LoggedInRoute
            exact
            path={linksToArtistsPage}
            component={ArtistsClubPage}
          />
        )}
        {broadcastEnabled && (
          <LoggedInRoute path={linkToBroadcast} component={BroadcastPage} />
        )}
        {broadcastEnabled && (
          <LoggedInRoute
            path={linkToBroadcastOneToOne}
            component={BroadcastOneToOnePage}
          />
        )}
        <Route path={linkToTangoProfile} exact component={RedirectToCS} />
        <Route
          path={linkToWebviewSafechargeOffers}
          component={WebviewSafechargeOffers}
        />
        <Route path={linkToWebviewApmOffers} component={WebviewApmOffers} />
        <Route path={linkToWebviewCheckout} component={CheckoutWebview} />
        <Route
          path={linkToWebviewPaymentButton}
          component={PaymentButtonWebview}
        />
        <Route
          path={linkToOnboardingLandingPage}
          component={OnboardingLanding}
        />

        <Route path={combinedLegalPaths} component={LegalRouter} />

        <LoggedInRoute path={linkToGiftCards} component={GiftCard} />
        <LoggedInRoute
          path={linkToLandingPagePhoenix}
          component={LandingPagePhoenix}
        />
        <Route exact path={linkToLiveStream} component={LiveModeRedirector} />

        <LoggedInRoute
          exact
          path={linkToLeaderBoard}
          component={LeaderBoardLanding}
        />
        <Route
          exact
          path={linkToFunctionIsNotAvailable}
          component={FunctionIsNotAvailablePage}
        />
        <TournamentRoute
          exact
          path={linkToTournamentMatch}
          component={TournamentsLanding}
        />
        <LoggedInRoute exact path={linkToAppealPage} component={AppealPage} />
        <LoggedInRoute
          path={linkToSocialGamesBase}
          component={SocialGamesRouter}
        />
        <Route path={linkToHomeV2Page} component={HomePageV2} />
        <Route exact path={linkToAgencyLandingPage} component={AgencyLanding} />
        <Route
          exact
          path={linkToFirstMarketingPage}
          component={FirstMarketingPage}
        />
        {restRouter}
      </Switch>
    </Suspense>
  );
};

export default RootRouter;
