import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { REFILL } from "enums/cashier";
import { GiftPlaceholder, GiftSource, MosType } from "src/core/analytics/enums";
import { MosLineup } from "src/features/mos/components/MosLineup/MosLineup";
import {
  DEBOUNCE_DELAY,
  GIFT_SELECTOR,
  THROTTLE_DELAY,
} from "src/features/mos/constants";
import { useMosAnalytics } from "src/features/mos/hooks/useMosAnalytics";
import { useMosItems } from "src/features/mos/hooks/useMosItems";
import {
  useBreakpointMobileLayout,
  useBreakpointPrecise,
  useCountRenderingGift,
} from "src/features/mos/imports/hooks";
import {
  RootState,
  mosV2Selectors,
  viewerSessionSelectors,
} from "src/features/mos/imports/state";
import {
  ComponentWithClassName,
  MosEventFields,
} from "src/features/mos/imports/types";
import { GiftRendererContext } from "src/features/mos/imports/ui";
import { debounce, throttle } from "src/features/mos/imports/utils";
import { GiftClickParams, GiftVariant } from "src/features/mos/types";
import { getVisibleElementsIndexes } from "src/features/mos/utils/getVisibleElementsIndexes";
import { openCheckoutBottomScreen } from "state/actionCreators/bottomScreen";
import { openCheckoutModal } from "state/actionCreators/modal";
import styles from "./MosInStream.scss";

const selector = (state: RootState) => ({
  lineupId: mosV2Selectors.getMosV2LineupId(state),
  mosErrorLoading: mosV2Selectors.getMosV2Error(state),
  broadcasterId: viewerSessionSelectors.getBroadcasterId(state),
  streamId: viewerSessionSelectors.getStreamId(state),
});

export const MosInStream: ComponentWithClassName = ({ className }) => {
  const dispatch = useDispatch();
  const mosItems = useMosItems();
  const breakpoint = useBreakpointPrecise();
  const isMobile = useBreakpointMobileLayout();

  const rootRef = useRef<HTMLDivElement>(null);
  const isScrollingRef = useRef(false);

  const { lineupId, mosErrorLoading, broadcasterId, streamId } = useSelector(
    selector,
    shallowEqual
  );

  const { giftSetRenderPosition, giftGetRenderPosition } =
    useCountRenderingGift();

  const { giftClickAction, startScrollAction, endScrollAction, errorAction } =
    useMosAnalytics();

  useEffect(() => {
    if (mosErrorLoading) {
      errorAction({
        [MosEventFields.GIFT_SOURCE_ID]: streamId,
        [MosEventFields.PEER_ID]: broadcasterId,
        [MosEventFields.GIFT_SOURCE]: GiftSource.STREAM,
        [MosEventFields.REASON]: mosErrorLoading,
        [MosEventFields.MOS_TYPE]: isMobile
          ? MosType.HORIZONTAL
          : MosType.VERTICAL,
      });
    }
  }, [mosErrorLoading]);

  const handleGiftClick = ({
    id,
    typeAbbreviation,
    position,
  }: GiftClickParams) => {
    const sendGiftAnalyticsParams = {
      [MosEventFields.PEER_ID]: broadcasterId,
      [MosEventFields.BUCKET_ID]: typeAbbreviation,
      [MosEventFields.GIFT_SOURCE]: GiftSource.STREAM,
      [MosEventFields.GIFT_SOURCE_ID]: streamId,
      [MosEventFields.GIFT_PLACEHOLDER]: GiftPlaceholder.MOS_V2,
      [MosEventFields.GIFT_PLACEHOLDER_ID]: lineupId,
    };

    giftClickAction({
      [MosEventFields.RULE_ID]: lineupId,
      [MosEventFields.POSITION]: position,
      [MosEventFields.BUCKET_ID]: typeAbbreviation,
      [MosEventFields.OBJECT_ID]: id,
      [MosEventFields.GIFT_SOURCE]: GiftSource.STREAM,
      [MosEventFields.GIFT_SOURCE_ID]: streamId,
      [MosEventFields.MOS_TYPE]: isMobile
        ? MosType.HORIZONTAL
        : MosType.VERTICAL,
    });

    return sendGiftAnalyticsParams;
  };

  const onOfferClick = useCallback(
    (data) => {
      if (isMobile) {
        const screenData = {
          offer: data,
          pricePointId: data.pricePointId,
          viewType: REFILL,
        };

        dispatch(
          openCheckoutBottomScreen({
            screenData,
          })
        );
      } else {
        dispatch(
          openCheckoutModal({
            viewType: REFILL,
            offer: data,
          })
        );
      }
    },
    [dispatch, isMobile]
  );

  const renderGiftActions = useMemo(
    () => ({
      giftSetRenderPosition,
      giftGetRenderPosition,
    }),
    []
  );

  const handleStartScroll = (lineupId: string) => {
    if (isScrollingRef.current) {
      return;
    }

    startScrollAction({
      [MosEventFields.RULE_ID]: lineupId,
      [MosEventFields.GIFT_SOURCE]: GiftSource.STREAM,
      [MosEventFields.GIFT_SOURCE_ID]: streamId,
      [MosEventFields.MOS_TYPE]: isMobile
        ? MosType.HORIZONTAL
        : MosType.VERTICAL,
    });

    isScrollingRef.current = true;
  };

  const handleEndScroll = useMemo(
    () =>
      debounce((lineupId: string) => {
        const visibleGifts = getVisibleElementsIndexes(rootRef, GIFT_SELECTOR);

        endScrollAction({
          [MosEventFields.RULE_ID]: lineupId,
          [MosEventFields.GIFT_SOURCE_ID]: streamId,
          [MosEventFields.PEER_ID]: broadcasterId,
          [MosEventFields.GIFT_SOURCE]: GiftSource.STREAM,
          [MosEventFields.MIN_COLUMN_POSITION]: visibleGifts[0],
          [MosEventFields.MAX_COLUMN_POSITION]:
            visibleGifts[visibleGifts.length - 1],
          [MosEventFields.MOS_TYPE]: isMobile
            ? MosType.HORIZONTAL
            : MosType.VERTICAL,
        });

        isScrollingRef.current = false;
      }, DEBOUNCE_DELAY),
    []
  );

  const handleScrollEvent = useMemo(
    () =>
      throttle(() => {
        if (!rootRef.current) {
          return;
        }

        if (lineupId) {
          handleEndScroll(lineupId);

          handleStartScroll(lineupId);
        }
      }, THROTTLE_DELAY),
    [lineupId]
  );

  if (!mosItems.length) {
    return null;
  }

  return (
    <GiftRendererContext.Provider value={renderGiftActions}>
      <MosLineup
        containerClassName={classnames(
          styles.root,
          styles[breakpoint],
          styles.maskFull,
          className
        )}
        mosItems={mosItems}
        ref={rootRef}
        onScroll={handleScrollEvent}
        handleGiftClick={handleGiftClick}
        onOfferClick={onOfferClick}
        variant={GiftVariant.STREAM}
      />
    </GiftRendererContext.Provider>
  );
};
