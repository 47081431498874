import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import setupDatadogRUM from "src/analytics/setupDatadogRUM";
import { getDatadogRUMEnabled } from "src/environment";
import { RootState } from "src/state/delegate";
import { userSelectors } from "src/state/selectors";

const selector = (state: RootState) => userSelectors.getMyAccountId(state);

const useDatadogRUMSetup = () => {
  const accountId = useSelector(selector);
  const isInitializedRef = useRef(false);

  useEffect(() => {
    const activeUser = datadogRum.getUser()?.accountId;
    const isInitialized = isInitializedRef.current;

    if (!accountId) {
      if (activeUser) {
        datadogRum.clearUser();
        datadogRum.stopSession();
      }

      return;
    }

    if (!activeUser && isInitialized) {
      datadogRum.setUser({ accountId });

      return;
    }

    if (!isInitialized && getDatadogRUMEnabled()) {
      setupDatadogRUM(accountId);
      isInitializedRef.current = true;
    }
  }, [accountId]);
};

export default useDatadogRUMSetup;
