import type { Logger, LoggerFn } from "./Logger";

export class ConsoleLogger implements Logger {
  public readonly error: LoggerFn;
  public readonly log: LoggerFn;
  public readonly warn: LoggerFn;

  constructor() {
    // eslint-disable-next-line no-console
    this.error = console.error.bind(console);
    // eslint-disable-next-line no-console
    this.log = console.log.bind(console);
    // eslint-disable-next-line no-console
    this.warn = console.warn.bind(console);
  }
}
