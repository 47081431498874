import { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { isSimdSupported as getIsSimdSupported, utils } from "@banuba/webar";
import { Breakpoints, DeviceType } from "stream/imports/enums";
import {
  getBanubaSDKRestrictedIOSVersions,
  getBanubaSDKWhitelistedAndroidGPUList,
  getIsBanubaSDKEnabled,
  getIsBanubaSDKMobileEnabled,
  useBreakpointPrecise,
} from "stream/imports/hooks";
import {
  broadcastMasksSelectors,
  deviceInfoSelectors,
} from "stream/imports/state";
import { RootState } from "stream/imports/types";

const selectors = (state: RootState) => ({
  isBanubaSDKEnabled: getIsBanubaSDKEnabled(state),
  isBanubaSDKMobileEnabled: getIsBanubaSDKMobileEnabled(state),
  restrictedIOSVersions: getBanubaSDKRestrictedIOSVersions(state),
  whitelistedGPUModelsList: getBanubaSDKWhitelistedAndroidGPUList(state),
  deviceType: deviceInfoSelectors.getDeviceType(state),
  deviceVersion: deviceInfoSelectors.getDeviceVersion(state),
  deviceGPUModel: deviceInfoSelectors.getDeviceGPUModel(state),
  banubaConfig: broadcastMasksSelectors.getConfig(state),
  loadingCompleted: broadcastMasksSelectors.getLoadingCompleted(state),
});

export const useBanubaSDKEnabled = () => {
  const breakpoint = useBreakpointPrecise();
  const [isSimdSupported, setIsSimdSupported] = useState(false);
  const [isSimdSupportChecked, setIsSimdSupportChecked] = useState(false);

  const isGpuPresent = useRef(true);

  const {
    isBanubaSDKEnabled,
    isBanubaSDKMobileEnabled,
    restrictedIOSVersions,
    whitelistedGPUModelsList,
    deviceType,
    deviceVersion,
    deviceGPUModel,
    banubaConfig,
    loadingCompleted,
  } = useSelector(selectors, shallowEqual);

  useEffect(() => {
    getIsSimdSupported()
      .then(setIsSimdSupported)
      .finally(() => {
        setIsSimdSupportChecked(true);
      });

    isGpuPresent.current = utils.createCanvas().getContext("webgl2") !== null;
  }, []);

  const isEnabled = useMemo(() => {
    if (
      !isBanubaSDKEnabled ||
      !loadingCompleted ||
      !isSimdSupported ||
      !isGpuPresent.current ||
      (loadingCompleted && !banubaConfig)
    ) {
      return false;
    }

    if (breakpoint === Breakpoints.DESKTOP) {
      return true;
    }

    if (deviceType === DeviceType.IOS && deviceVersion) {
      const isIOSVersionRestricted =
        restrictedIOSVersions.includes(deviceVersion);

      return isBanubaSDKMobileEnabled && !isIOSVersionRestricted;
    }

    if (deviceType === DeviceType.ANDROID) {
      const isGPUModelWhitelisted = whitelistedGPUModelsList.some((model) =>
        deviceGPUModel.includes(model)
      );

      return isBanubaSDKMobileEnabled && isGPUModelWhitelisted;
    }

    return isBanubaSDKMobileEnabled;
  }, [
    isBanubaSDKEnabled,
    isSimdSupported,
    breakpoint,
    deviceType,
    deviceVersion,
    isBanubaSDKMobileEnabled,
    restrictedIOSVersions,
    whitelistedGPUModelsList,
    deviceGPUModel,
    banubaConfig,
    loadingCompleted,
  ]);

  return {
    isBanubaSDKEnabled: isEnabled,
    isCheckCompleted: isSimdSupportChecked && loadingCompleted,
  };
};
