import { MessageDescriptor } from "react-intl";
import {
  LoginModalTitleType,
  LoginProvider,
} from "src/features/signin/imports/enums";
import LoginAppleButton from "src/features/signin/modal/login/components/LoginAppleButton";
import LoginGoogleButton from "src/features/signin/modal/login/components/LoginGoogleButton";
import ProviderButton from "src/features/signin/modal/login/components/ProviderButton";

export const providerToComponentMap = {
  [LoginProvider.APPLE]: LoginAppleButton,
  [LoginProvider.GOOGLE]: LoginGoogleButton,
  [LoginProvider.TANGO_PHONE_NUMBER]: ProviderButton,
  [LoginProvider.TANGO_DISPOSABLE_TOKEN]: ProviderButton,
};

export type LoginModalMessages = {
  [key in LoginModalTitleType]?: MessageDescriptor;
};
