import { messages } from "chat/components/common/messages";
import {
  DeleteIcon,
  MuteIcon,
  PinIcon32,
  SoundOnIcon,
  UnpinIcon,
} from "chat/imports/assets";
import { sharedMessages } from "chat/imports/utils";
import {
  getIsDeleteChatEnabled,
  getIsMuteChatEnabled,
  getIsPinChatEnabled,
} from "chat/soc/chatSoc";
import { MessageMenuOptionType } from "chat/types";

export const CHAT_POPOVER_OPTIONS: { [key: string]: MessageMenuOptionType } = {
  pin: {
    id: "pin",
    message: messages.pin,
    icon: PinIcon32,
    visibilityFn: getIsPinChatEnabled,
  },
  unpin: {
    id: "unpin",
    message: messages.unpin,
    icon: UnpinIcon,
    visibilityFn: getIsPinChatEnabled,
  },
  mute: {
    id: "mute",
    message: sharedMessages.happyMomentMute,
    icon: MuteIcon,
    visibilityFn: getIsMuteChatEnabled,
  },
  unmute: {
    id: "unmute",
    message: sharedMessages.happyMomentUnMute,
    icon: SoundOnIcon,
    visibilityFn: getIsMuteChatEnabled,
  },
  delete: {
    id: "delete",
    message: messages.deleteChat,
    icon: DeleteIcon,
    visibilityFn: getIsDeleteChatEnabled,
  },
};
