import { getIsGiftsDrawerV2Enabled } from "src/features/giftsDrawer/exports/abTests";
import { GiftDrawerTarget } from "src/features/giftsDrawer/exports/enums";
import { giftDrawerSelectors } from "src/features/giftsDrawer/exports/state";
import { getCurrentStream, giftsCacheSelectors } from "state/selectors";

export default (state) => {
  const stream = getCurrentStream(state);
  const gifts = giftsCacheSelectors.getAllGifts(state);
  const categories = giftDrawerSelectors.getCategoryIdsList(
    state,
    GiftDrawerTarget.PREMIUM_ENTRANCE
  );
  const isGiftsDrawerV2Enabled = getIsGiftsDrawerV2Enabled(state);

  if (isGiftsDrawerV2Enabled) {
    const isGiftDrawerLoading = giftDrawerSelectors.isLoading(state);
    const giftIds = categories
      .map((id) =>
        giftDrawerSelectors.getCategoryById(
          state,
          id,
          GiftDrawerTarget.PREMIUM_ENTRANCE
        )
      )
      .flatMap((category) => category.giftIds);

    if (giftIds.length === 0 || !stream || isGiftDrawerLoading) {
      return;
    }

    const allGifts = giftIds.map((id) => gifts[id]).filter(Boolean);

    return (
      allGifts
        .filter((x) => !x.special)
        .sort((a, b) => a.priceInCredit - b.priceInCredit)
        .find((x) => x.priceInCredit >= stream.ticketPrice) ||
      allGifts[allGifts.length - 1]
    );
  }

  const classicCategory =
    categories && giftsCacheSelectors.getCategoryById(state, categories[0]);

  if (!classicCategory || classicCategory.giftIds.length === 0 || !stream) {
    return;
  }
  const classicGifts = classicCategory.giftIds.map((x) => gifts[x]);

  return (
    classicGifts
      .filter((x) => !x.special)
      .sort((a, b) => a.priceInCredit - b.priceInCredit)
      .find((x) => x.priceInCredit >= stream.ticketPrice) ||
    classicGifts[classicGifts.length - 1]
  );
};
