import { getProxycadorHost } from "environment";
import { GifterUsersListResponse } from "src/features/gifterStatistics/common/types";
import { enhancedFetch } from "src/features/referralProgram/imports/utils";

export const loadGifterUsersListRequest = (params: {
  accountId: number;
  timeframe: string;
}): Promise<GifterUsersListResponse> =>
  enhancedFetch(
    `${getProxycadorHost()}/stream/top/v1/gifters/${params.timeframe}?pageSize=100`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "X-TangoAuth-AccountId": `${params.accountId}`,
      },
    }
  ).then((resp) => resp.json());
