import { createSlice } from "@reduxjs/toolkit";
import { GifterUsersListResponse } from "src/features/gifterStatistics/common/types";
import { loadGifterUsersList } from "src/features/gifterStatistics/state/asyncActions";

export interface GifterUsersListState {
  data: GifterUsersListResponse | null;
  error: null | string;
  loading: boolean;
}

const initialState: GifterUsersListState = {
  data: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "gifterUsersList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadGifterUsersList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadGifterUsersList.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(loadGifterUsersList.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      });
  },
});

export const gifterUsersListSlice = slice.reducer;
