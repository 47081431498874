import React, { forwardRef } from "react";
import { MediaMessageSaveOption } from "chat/components/MessageMenu/MessageMenuOptions/MediaMessageSaveOption";
import MessageMenuCopyOption from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuCopyOption";
import MessageMenuDeleteOption from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuDeleteOption";
import MessageMenuEditOption from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuEditOption";
import MessageMenuTranslateOption from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuTranslateOption";
import { StoredMessage } from "chat/state";

interface MessageMenuOptionProps {
  className?: string;
  closeMenu: VoidFunction;
  isMyMessage: boolean;
  message: StoredMessage;
}

const MessageMenuOptionsList = forwardRef<
  HTMLDivElement,
  MessageMenuOptionProps
>(({ message, isMyMessage, closeMenu, className }, ref) => (
  <div ref={ref} className={className} data-testid="chat-message-menu">
    <MessageMenuCopyOption closeMenu={closeMenu} message={message} />
    <MessageMenuTranslateOption
      closeMenu={closeMenu}
      isMyMessage={isMyMessage}
      message={message}
    />
    <MessageMenuEditOption
      closeMenu={closeMenu}
      isMyMessage={isMyMessage}
      message={message}
    />
    <MediaMessageSaveOption closeMenu={closeMenu} message={message} />
    <MessageMenuDeleteOption closeMenu={closeMenu} message={message} />
  </div>
));

export default MessageMenuOptionsList;
