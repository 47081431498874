import React, { memo } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";
import { DEFAULT_DISCOUNT_PERCENT } from "src/features/mos/imports/constants";
import { useBreakpointPrecise } from "src/features/mos/imports/hooks";
import {
  Breakpoints,
  SpecialOfferType,
  TYPOGRAPHY_TYPE,
} from "src/features/mos/imports/types";
import {
  Coin,
  FormattedNumber,
  PersonalPriceWithDiscount,
  Typography,
  sharedMessages,
} from "src/features/mos/imports/ui";
import styles from "./MosSpecialOffer.scss";

interface MosSpecialOffer {
  offer: SpecialOfferType;
  onClick?: (offer: SpecialOfferType) => void;
}

export const MosSpecialOffer = memo(({ offer, onClick }: MosSpecialOffer) => {
  const breakpoint = useBreakpointPrecise();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;

  const { formatMessage } = useIntl();

  const {
    offers: [{ coins, bonus, currencyPrice }],
    price,
    imageUrl,
  } = offer;

  const onOfferClick = () => {
    onClick?.(offer);
  };

  return (
    <div
      className={classnames(
        styles[breakpoint],
        styles.specialOffer,
        "specialOffer"
      )}
      onClick={onOfferClick}
      role="button"
      tabIndex={0}
    >
      <div className={styles.image}>
        <img src={imageUrl} />
      </div>

      <div className={styles.offerDetails}>
        <div className={styles.credits}>
          <Coin className={styles.coin} />

          <Typography
            type={isMobile ? TYPOGRAPHY_TYPE.HEADLINE5 : TYPOGRAPHY_TYPE.MINI}
          >
            <FormattedNumber value={coins || 0} />{" "}
            {formatMessage(sharedMessages.coins)}
          </Typography>
        </div>

        <div className={styles.priceContainer}>
          <PersonalPriceWithDiscount
            offer={{
              currencyPrice,
              discountPercent: bonus || DEFAULT_DISCOUNT_PERCENT,
              usdPrice: price,
            }}
            priceStyle={styles.price}
            originalPriceStyle={styles.originalPrice}
            typographyPriceType={TYPOGRAPHY_TYPE.MINI}
            typographyOriginalPriceType={TYPOGRAPHY_TYPE.MINI}
          />
        </div>
      </div>
    </div>
  );
});

MosSpecialOffer.displayName = "MosSpecialOffer";
