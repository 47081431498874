import { batch, useDispatch, useSelector } from "react-redux";
import { GiftsDrawerLayout } from "src/enums";
import { getIsGiftsDrawerV2Enabled } from "src/features/giftsDrawer/exports/abTests";
import { useGiftDrawerTarget } from "src/features/giftsDrawer/exports/hooks";
import {
  openGiftsDrawerInStreamBottomScreen,
  openGiftsDrawerV2InStreamBottomScreen,
} from "state/actionCreators/bottomScreen";
import { openGiftsDrawerModal } from "state/actionCreators/modal";
import { actionCreators as giftsCacheActionCreators } from "state/tree/giftsCache";
import { actionCreators as settingsActionCreators } from "state/tree/settings";
import useGiftsDrawerLayout from "ui/hooks/useGiftsDrawerLayout";

export const getShowGiftsDrawer =
  ({ dispatch, giftsDrawerLayout, giftDrawerTarget, isGiftDrawerV2Enabled }) =>
  ({ tabId } = {}) => {
    switch (giftsDrawerLayout) {
      case GiftsDrawerLayout.MODAL:
        dispatch(openGiftsDrawerModal({ tabId, target: giftDrawerTarget }));
        break;

      case GiftsDrawerLayout.BOTTOM_SCREEN:
        batch(() => {
          const giftDrawerBottomScreenAction = isGiftDrawerV2Enabled
            ? openGiftsDrawerV2InStreamBottomScreen
            : openGiftsDrawerInStreamBottomScreen;

          dispatch(giftsCacheActionCreators.setGiftsDrawerTabId(tabId));
          dispatch(settingsActionCreators.setGiftsDrawerVisibility(true));
          dispatch(
            giftDrawerBottomScreenAction({
              screenData: {
                target: giftDrawerTarget,
              },
              onDismiss: () => {
                dispatch(
                  settingsActionCreators.setGiftsDrawerVisibility(false)
                );
              },
            })
          );
        });
        break;

      case GiftsDrawerLayout.RIGHT_SIDE:
        batch(() => {
          dispatch(giftsCacheActionCreators.setGiftsDrawerTabId(tabId));
          dispatch(settingsActionCreators.setGiftsDrawerVisibility(true));
        });
        break;

      default:
        throw Error(`Unallowed giftsDrawerLayout: '${giftsDrawerLayout}'`);
    }
  };

const useShowGiftsDrawer = (target) => {
  const dispatch = useDispatch();
  const isGiftDrawerV2Enabled = useSelector(getIsGiftsDrawerV2Enabled);
  const giftsDrawerLayout = useGiftsDrawerLayout();
  const giftDrawerTarget = useGiftDrawerTarget();

  return getShowGiftsDrawer({
    dispatch,
    giftsDrawerLayout,
    giftDrawerTarget: target || giftDrawerTarget,
    isGiftDrawerV2Enabled,
  });
};

export default useShowGiftsDrawer;
