import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { DateSeparatorOneDayNow } from "chat/components/DateSeparatorOneDayNow/DateSeparatorOneDayNow";
import ChatSpinner from "chat/components/common/Spinner";
import CurrentConversationSkeleton from "chat/components/currentConversation/components/CurrentConversationSkeleton/CurrentConversationSkeleton";
import {
  CHAT_OLDER_MESSAGES_PAGE_SIZE,
  systemMessageTypes,
} from "chat/constants";
import { Direction } from "chat/enums";
import { useShowMessageActionsMenu } from "chat/hooks/useShowMessageActionsMenu";
import { BottomMarker, Typography } from "chat/imports/components";
import { Breakpoints, TYPOGRAPHY_TYPE } from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import {
  RootState,
  batchLoadProfiles,
  blockedUsersSelectors,
  userSelectors,
} from "chat/imports/state";
import { AccountInfo } from "chat/imports/types";
import { sharedMessages } from "chat/imports/utils";
import { fetchConversation } from "chat/state/actionCreators";
import { StoredConversation, StoredMessage } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import { ChatMessageAnalyticsParams, ConversationState } from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import ConversationMessagesList from "./ConversationMessagesList";
import Message from "./Message";
import styles from "./ConversationMessages.scss";

interface LoadControllerProps {
  conversationId: string;
  lastMessageTs: number;
}

const LoadController = memo<LoadControllerProps>(
  ({ conversationId, lastMessageTs }) => {
    const dispatch = useDispatch();
    const { isLoading, hasMoreMessages } = useSelector(
      (state: RootState) =>
        chatSelectors.getConversation(state, conversationId),
      shallowEqual
    );
    const loadMore = useCallback(() => {
      dispatch(
        fetchConversation({
          conversationId,
          direction: Direction.REVERSE,
          end_timestamp: lastMessageTs,
          limit: CHAT_OLDER_MESSAGES_PAGE_SIZE,
          include_group_info: true,
          include_group_members: true,
        })
      );
    }, [conversationId, lastMessageTs]);

    if (!hasMoreMessages) {
      return null;
    }

    if (isLoading) {
      return null;
    }

    return <BottomMarker onReached={loadMore} />;
  }
);

LoadController.displayName = "LoadController";

interface DayProps {
  accountInfo?: AccountInfo;
  analyticsParams: ChatMessageAnalyticsParams;
  conversationId: string;
  date: number;
  isMessageMenuEnabled: boolean;
  messages: StoredMessage[];
}

const Day = memo<DayProps>(
  ({
    date,
    messages,
    conversationId,
    accountInfo,
    analyticsParams,
    isMessageMenuEnabled,
  }) => {
    const accountId = useSelector(userSelectors.getMyAccountId);

    const messageElements = messages.reduce((acc, message, index, array) => {
      const prevMessage = array[index - 1];
      const nextMessage = array[index + 1];

      const isMyMessage = accountId === message.from;
      const isSystemMessage = systemMessageTypes.includes(message.type);
      const isPrevSystemMessage =
        prevMessage && systemMessageTypes.includes(prevMessage.type);
      const isNextSystemMessage =
        nextMessage && systemMessageTypes.includes(nextMessage.type);

      const isLastInGroup =
        !prevMessage ||
        message.from !== prevMessage.from ||
        isSystemMessage ||
        isPrevSystemMessage;

      const isNewMessageGroup =
        !nextMessage ||
        message.from !== nextMessage.from ||
        isSystemMessage ||
        isNextSystemMessage;

      const messageConfig = {
        isMyMessage,
        isLastInGroup,
        isFirstInGroup: isNewMessageGroup,
        analyticsParams,
        nextMessageId: nextMessage?.id?.id,
      };

      if (isLastInGroup && index !== 0) {
        acc.push(
          <div
            key={`messages-group-separator-${message.id.id}`}
            data-testid="messages-group-separator"
            className={styles.messageGroupSeparator}
          />
        );
      }

      acc.push(
        <Message
          key={message.id.id}
          message={message}
          isMessageMenuEnabled={isMessageMenuEnabled}
          conversationId={conversationId}
          accountInfo={accountInfo}
          messageConfig={messageConfig}
        />
      );

      return acc;
    }, [] as ReactNode[]);

    return (
      <>
        {messageElements}
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH3}
          className={styles.dateSeparator}
          data-testid="date-separator"
        >
          <DateSeparatorOneDayNow value={date} />
        </Typography>
      </>
    );
  }
);

Day.displayName = "Day";

interface ConversationMessageProps {
  accountInfo?: AccountInfo;
  analyticsParams: ChatMessageAnalyticsParams;
  conversationId: string;
  isChatRequestDisclaimerHidden: boolean;
  isHidden: boolean;
  state: StoredConversation["state"];
}

const conversationSelector =
  (conversationId: string) => (state: RootState) => ({
    conversation: chatSelectors.getConversation(state, conversationId),
    conversationMessagesByDates: chatSelectors.getConversationMessagesByDates(
      state,
      conversationId
    ),
  });

const ConversationMessages: React.FC<ConversationMessageProps> = ({
  conversationId,
  state,
  accountInfo,
  isHidden,
  isChatRequestDisclaimerHidden,
  analyticsParams,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const isMessageMenuEnabled = useShowMessageActionsMenu();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const isBlocked = useSelector(
    useCallback(
      (state: RootState) =>
        blockedUsersSelectors.isBlockedUser(state, accountInfo?.account_id),
      [accountInfo?.account_id]
    )
  );
  const [showUserBlockedMessage, setShowUserBlockedMessage] = useState(false);

  const isGroupChat = isGroupChatId(conversationId);

  const isShowDisclaimer =
    state === ConversationState.CHAT_REQUEST && !isGroupChat;

  const {
    conversationMessagesByDates: {
      dates,
      messagesIds,
      lastMessageTs,
      accountIds,
      latestMessageFrom,
    },
    conversation: { isLoading = true },
  } = useSelector(
    useCallback(conversationSelector(conversationId), [conversationId]),
    shallowEqual
  );

  useEffect(() => {
    if (isGroupChat) {
      dispatch(batchLoadProfiles({ ids: accountIds, loadOnlyIfMissing: true }));
    }
  }, [accountIds, isGroupChat, dispatch]);

  useEffect(() => {
    if (!isBlocked && showUserBlockedMessage) {
      dispatch(
        fetchConversation({
          conversationId,
          direction: Direction.REVERSE,
          limit: CHAT_OLDER_MESSAGES_PAGE_SIZE,
          include_group_info: true,
          include_group_members: true,
        })
      );
      setShowUserBlockedMessage(false);
    }
  }, [isBlocked, showUserBlockedMessage]);

  const isPreloadedMessageState =
    !dates.length || (dates.length === 1 && dates[0].messages.length === 1);

  const blockedUserMessage = isGroupChat
    ? sharedMessages.blockedUserInChat
    : sharedMessages.blockedUser;

  return (
    <>
      {!isPreloadedMessageState && isLoading && (
        <ChatSpinner
          className={classnames(styles.spinner, styles[breakpoint])}
        />
      )}
      <ConversationMessagesList
        isBlocked={isBlocked}
        messageIds={messagesIds}
        latestMessageFrom={latestMessageFrom}
        isChatRequestDisclaimerHidden={isChatRequestDisclaimerHidden}
        isShowDisclaimer={isShowDisclaimer}
        isHidden={isHidden}
        className={classnames(styles.root, styles[breakpoint])}
      >
        {isPreloadedMessageState && isLoading && (
          <CurrentConversationSkeleton />
        )}
        {((isPreloadedMessageState && !isLoading) ||
          !isPreloadedMessageState) && (
          <>
            {isBlocked && isDesktop && (
              <Typography
                type={TYPOGRAPHY_TYPE.PARAGRAPH5}
                className={styles.blockedUserMessage}
                data-testid="blocked-user-message"
              >
                <FormattedMessage {...blockedUserMessage} />
              </Typography>
            )}
            {dates.map(({ date, messages }, index) => (
              <Day
                // The first element always has a dynamic date, so we make it static
                key={index === 0 ? 0 : date}
                date={Number(date)}
                messages={messages}
                conversationId={conversationId}
                accountInfo={accountInfo}
                analyticsParams={analyticsParams}
                isMessageMenuEnabled={isMessageMenuEnabled}
              />
            ))}
            <LoadController
              conversationId={conversationId}
              lastMessageTs={lastMessageTs}
            />
          </>
        )}
      </ConversationMessagesList>
    </>
  );
};

export default ConversationMessages;
