import { batch } from "react-redux";
import { analyticsUpgradeGuest } from "src/core/analytics/utils/analyticsUpgradeGuest";
import { updateAnalyticsKey } from "src/features/marketing/state/slice";
import { attemptRegisterReferralUser } from "src/features/referralProgram/exports/asyncActions";
import {
  fetchAllowedCredentialTypes,
  registerVisitor,
  registerVisitorWithRecaptcha,
} from "src/features/signin/api/login";
import { referralRegistration } from "src/features/signin/api/referrals";
import {
  DeviceTokenType,
  validation,
} from "src/features/signin/api/validation";
import {
  EventCategory,
  EventFields,
  EventNames,
  RegistrationSource,
  SCREEN_NAME,
  UtmParams,
  emitAF,
  emitEvent,
  emitReasonAction,
} from "src/features/signin/imports/analytics";
import { CLIENT_CAPABILITIES } from "src/features/signin/imports/constants";
import {
  DeviceType,
  LoginProvider,
  LoginResult,
  ModalType,
  SignUpEntryPoint,
  ToastType,
} from "src/features/signin/imports/enums";
import {
  getAppleProviderEnabled,
  getGoogleProviderEnabled,
  getRecaptchaKeyId,
  getReferralRegistrationEnabled,
} from "src/features/signin/imports/environment";
import {
  FROM_FLOW,
  HTTP_CODE_UNAUTHORIZED,
  addToast,
  checkInVisitor,
  clearVisitorData,
  deviceInfoSelectors,
  dismissModalWithType,
  fetchSessionDetails,
  getIsRecaptchaEnabled,
  loadMyProfile,
  lockTopModal,
  loginSelectors,
  openRegistrationDisabledModal,
  refreshSessionToken,
  retryAfterCaptcha,
  setCaptchaConfig,
  setDropdownAvatarState,
  setDropdownMenuState,
  unlockTopModal,
  userSelectors,
  validationEnd,
} from "src/features/signin/imports/state";
import {
  CaptchaType,
  linkToBasePath,
  sharedMessages,
} from "src/features/signin/imports/ui";
import {
  cleanLocalStorageByKeys,
  emitImpactSignUpCompleted,
  getOsVersion,
  isLockedByCaptcha,
} from "src/features/signin/imports/utils";
import { openLoginView } from "src/features/signin/state/flows/openLoginView";
import {
  askForSms,
  beganPreparingProviders,
  endedPreparingProviders,
  failedToLogoutFromProvider,
  loadedLoginProvider,
  loginFailed,
  loginSetEntryPoint,
  loginStart,
  loginSuccess,
  logoutStart,
  logoutSuccess,
  phoneLoginAttemptLimitReached,
  phoneLoginMethodUnavailableForRegisteredUser,
  phoneLoginPhoneVerificationUnavailable,
  phoneLoginSetSmsResendDelay,
  phoneLoginVerificationFailed,
  phoneLoginVerificationRequired,
  phoneLoginVerificationRequiredSMSNotPossible,
  specifyPhoneNumberForPhoneLogin,
} from "src/features/signin/state/login/actionCreators";
import { generateRecaptchaToken } from "src/features/signin/utils/generateRecaptchaToken";
import mapProviderToApi from "src/features/signin/utils/mapProviderToApi";
import { reCaptchaLoader } from "src/features/signin/utils/reCaptchaLoader";
import { refreshPWATutorial } from "src/features/tutorials/shared/state/actions";

const LOCAL_STORAGE_KEYS = [UtmParams.UTM_CAMPAIGN];

export const sendReferralRegistrationRequest = async (getState) => {
  if (!getReferralRegistrationEnabled()) {
    return;
  }
  const state = getState();
  const referrerAccountId = loginSelectors.getReferrerAccountId(state);
  const isGuest = !loginSelectors.isLoggedIn(state);
  if (!referrerAccountId) {
    return;
  }
  try {
    await referralRegistration(referrerAccountId);
  } catch (ignored) {
    //
  }
  if (isGuest) {
    emitEvent(EventNames.AGENT_GUEST_REGISTRATION, {
      guest_id: userSelectors.getMyAccountId(state),
      referral_id: referrerAccountId,
    });
  }
};

const emitRegistrationError = ({ errorMessage, loginResult }) => {
  emitEvent(EventNames.REGISTRATION_ERROR, {
    [EventFields.COMMENT]: errorMessage,
    [EventFields.ITEM_TYPE]: loginResult,
  });
};

export const prepareProviders = () => (dispatch, getState) => {
  const state = getState();
  const promises = [];

  if (
    loginSelectors.isPreparingProviders(state) ||
    loginSelectors.getAvailableProviders(state).length > 0
  ) {
    return Promise.resolve();
  }

  const onPreparationsEnd = () => dispatch(endedPreparingProviders());
  dispatch(beganPreparingProviders());

  return fetchAllowedCredentialTypes()
    .catch(() => Object.values(LoginProvider))
    .then((providers) => {
      const availableProviders = loginSelectors.getAvailableProviders(state);

      const loadProviderIfNeeded = (provider, loader) => {
        if (
          !providers.includes(provider) ||
          availableProviders.includes(provider)
        ) {
          return;
        }

        promises.push(
          loader().then(() => dispatch(loadedLoginProvider(provider)))
        );
      };

      loadProviderIfNeeded(LoginProvider.TANGO_PHONE_NUMBER, () =>
        Promise.resolve()
      );

      loadProviderIfNeeded(LoginProvider.TANGO_DISPOSABLE_TOKEN, () =>
        Promise.resolve()
      );

      getGoogleProviderEnabled() &&
        loadProviderIfNeeded(LoginProvider.GOOGLE, () => Promise.resolve());

      getAppleProviderEnabled() &&
        loadProviderIfNeeded(LoginProvider.APPLE, () => Promise.resolve());

      return Promise.all(promises);
    })
    .then(onPreparationsEnd, onPreparationsEnd);
};

export const shouldRetryLogin = ({ loginResult }) => {
  switch (loginResult) {
    case LoginResult.VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE:
    case LoginResult.METHOD_UNAVAILABLE_FOR_REGISTERED_USER:
    case LoginResult.VERIFICATION_REQUIRED:
    case LoginResult.WRONG_CODE:
    case LoginResult.LOGGED_IN:
    case LoginResult.LIMIT_REACHED:
    case LoginResult.METHOD_UNAVAILABLE:
      return false;
    case LoginResult.UNKNOWN_ERROR:
    case LoginResult.GUEST_UPGRADE_FAILED:
    default:
      return true;
  }
};

export const loginWithProviderSelectors = (state) => ({
  availableProviders: loginSelectors.getAvailableProviders(state),
  isAuthorized: loginSelectors.isAuthorized(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isLoginInProgress: loginSelectors.isLoginInProgress(state),
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  locale: deviceInfoSelectors.getDeviceLocale(state),
  isMobile: deviceInfoSelectors.isAnyMobileDevice(state),
  deviceType: deviceInfoSelectors.getDeviceType(state),
  entryPoint: loginSelectors.getLoginEntryPoint(state),
  isRecaptchaEnabled: getIsRecaptchaEnabled(state),
});

export const loginWithProvider =
  ({ provider, onLoginSuccess = () => {}, screenType }) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      availableProviders,
      isLoggedIn,
      isLoginInProgress,
      fingerprint,
      locale,
      isMobile,
      deviceType,
      entryPoint,
      isRecaptchaEnabled,
    } = loginWithProviderSelectors(state);

    if (!availableProviders.includes(provider)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(`provider ${provider} is not ready`);
    }

    if (isLoggedIn || isLoginInProgress) {
      return Promise.resolve();
    }

    const obtainCredential = (provider, state) => {
      const { phoneNumber, verificationCode, allowOnlySmsValidation } =
        loginSelectors.getPhoneNumberAuthenticationState(state);
      switch (provider) {
        case LoginProvider.TANGO_PHONE_NUMBER:
          return Promise.resolve({
            type: LoginProvider.TANGO_PHONE_NUMBER,
            phoneNumber,
            verificationCode,
            allowTc2Validation: !allowOnlySmsValidation,
          });
      }
    };

    const attemptUpgradeGuest = async (credential) => {
      const body = {
        credential,
        fingerprint,
        locale,
        clientCapabilities: CLIENT_CAPABILITIES,
        osVersion: getOsVersion(),
        clientVersion: GENERATED_APP_INFO.fullVersion,
      };

      if (
        isRecaptchaEnabled &&
        provider === LoginProvider.TANGO_PHONE_NUMBER &&
        !credential.verificationCode
      ) {
        await reCaptchaLoader(locale, getRecaptchaKeyId());
        const recaptchaToken = await generateRecaptchaToken();

        return registerVisitorWithRecaptcha({
          body,
          provider: mapProviderToApi[provider],
          recaptchaToken,
        });
      }

      return registerVisitor({
        body,
        provider: mapProviderToApi[provider],
      });
    };

    const upgradeGuestWithRetry = (state) => async (credential) => {
      const { type, phoneNumber, verificationCode } = credential;
      if (type === LoginProvider.TANGO_PHONE_NUMBER) {
        const resendAllowedTimestamp = loginSelectors.getResendAllowedTimestamp(
          state,
          phoneNumber
        );
        if (
          typeof resendAllowedTimestamp === "number" &&
          typeof verificationCode !== "string" &&
          new Date() < resendAllowedTimestamp
        ) {
          const { codeProvider } =
            loginSelectors.getPhoneNumberAuthenticationState(state);

          return {
            loginResult: LoginResult.VERIFICATION_REQUIRED,
            // it's possible to resend SMS but not TANGO_CHAT message
            deliveryMethod: "SMS",
            provider: codeProvider,
            credential: { type: LoginProvider.TANGO_PHONE_NUMBER, phoneNumber },
          };
        }
      }

      const firstAttempt = await attemptUpgradeGuest(credential);
      if (!shouldRetryLogin(firstAttempt)) {
        analyticsUpgradeGuest(firstAttempt, credential?.type);

        return { ...firstAttempt, credential };
      }

      const secondAttempt = await attemptUpgradeGuest(credential);

      if (!shouldRetryLogin(secondAttempt)) {
        analyticsUpgradeGuest(secondAttempt, credential?.type);

        return { ...secondAttempt, credential };
      }
      // eslint-disable-next-line no-throw-literal
      throw "Login failed";
    };

    dispatch(lockTopModal());
    dispatch(loginStart());

    return obtainCredential(provider, state, dispatch)
      .then(upgradeGuestWithRetry(state))
      .catch((error) => {
        if (isLockedByCaptcha(error)) {
          const { phoneNumber, allowOnlySmsValidation } =
            loginSelectors.getPhoneNumberAuthenticationState(state);

          dispatch(
            setCaptchaConfig({
              type: CaptchaType.PHONE,
              phoneNumber,
              fingerprint,
            })
          );

          retryAfterCaptcha(
            error,
            specifyPhoneNumberForPhoneLogin(phoneNumber)
          );

          if (entryPoint) {
            retryAfterCaptcha(error, loginSetEntryPoint(entryPoint));
          }

          if (allowOnlySmsValidation) {
            retryAfterCaptcha(error, askForSms());
          }

          if (
            ![
              SignUpEntryPoint.REGISTRATION,
              SignUpEntryPoint.REGPAGE_1,
            ].includes(entryPoint) &&
            (isMobile ||
              (deviceType === DeviceType.IPAD &&
                window.innerHeight > window.innerWidth))
          ) {
            retryAfterCaptcha(
              error,
              openLoginView({
                registrationSource: RegistrationSource.SELF_PROFILE_ICON,
                isShowPhoneNumberLoginFlow: true,
                screenType,
                onLoginSuccess,
              })
            );
          }

          retryAfterCaptcha(
            error,
            loginWithProvider({ provider, screenType, onLoginSuccess })
          );
        }

        dispatch(loginFailed(error));
        throw error;
      })
      .then(
        ({
          analyticsKey,
          loginResult,
          resendDelay,
          errorMessage,
          accountId,
          newRegistration,
          deliveryMethod,
          provider: codeProvider,
          ...connectionManagerData
        }) => {
          switch (loginResult) {
            case LoginResult.VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE:
              dispatch(phoneLoginVerificationRequiredSMSNotPossible());
            /* eslint-disable no-fallthrough */
            case LoginResult.VERIFICATION_REQUIRED:
              batch(() => {
                dispatch(
                  phoneLoginVerificationRequired({
                    codeProvider,
                    deliveryMethod,
                  })
                );
                if (typeof resendDelay === "number") {
                  dispatch(phoneLoginSetSmsResendDelay(resendDelay));
                }
              });
              emitRegistrationError({
                errorMessage,
                loginResult:
                  loginResult ===
                  LoginResult.VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE
                    ? LoginResult.VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE
                    : LoginResult.VERIFICATION_REQUIRED,
              });
              break;
            case LoginResult.METHOD_UNAVAILABLE_FOR_REGISTERED_USER:
              dispatch(phoneLoginMethodUnavailableForRegisteredUser());
              emitRegistrationError({
                errorMessage,
                loginResult: LoginResult.METHOD_UNAVAILABLE_FOR_REGISTERED_USER,
              });
              break;
            case LoginResult.METHOD_UNAVAILABLE:
              dispatch(phoneLoginPhoneVerificationUnavailable());
              emitRegistrationError({
                errorMessage,
                loginResult: LoginResult.METHOD_UNAVAILABLE,
              });
              break;
            case LoginResult.LIMIT_REACHED:
              dispatch(phoneLoginAttemptLimitReached(errorMessage));
              emitRegistrationError({
                errorMessage,
                loginResult: LoginResult.LIMIT_REACHED,
              });
              break;
            case LoginResult.WRONG_CODE:
              dispatch(phoneLoginVerificationFailed(errorMessage));
              emitRegistrationError({
                errorMessage,
                loginResult: LoginResult.WRONG_CODE,
              });
              break;
            case LoginResult.GUEST_UPGRADE_FAILED:
              emitRegistrationError({
                errorMessage,
                loginResult: LoginResult.GUEST_UPGRADE_FAILED,
              });
              break;
            case LoginResult.LOGGED_IN:
              if (provider === LoginProvider.TANGO_PHONE_NUMBER) {
                emitEvent(EventNames.CODE_ENTERED, {
                  [EventFields.RESULT_CODE]: 1,
                  [EventFields.TANGO_SCREEN]: SCREEN_NAME.REGISTRATION_SIGN_IN,
                });
              }
              if (!newRegistration) {
                emitEvent(EventNames.TANGO_LOGIN);
                emitAF({
                  eventName: EventNames.TANGO_LOGIN,
                  eventCategory: EventCategory,
                });
              }

              if (!newRegistration && analyticsKey) {
                dispatch(updateAnalyticsKey({ analyticsKey }));
              }

              newRegistration && emitImpactSignUpCompleted(accountId);

              batch(() => {
                dispatch(
                  loginSuccess({
                    accountId,
                    newRegistration,
                    connectionManagerData,
                    provider,
                    clientCapabilities: CLIENT_CAPABILITIES,
                    entryPoint,
                  })
                );
                dispatch(fetchSessionDetails());
                dispatch(clearVisitorData());
                dispatch(
                  setDropdownAvatarState({
                    isAvatarMenuVisible: false,
                    shouldHideInstantly: true,
                  })
                );
              });

              if (typeof onLoginSuccess === "function") {
                onLoginSuccess();
              }

              dispatch(attemptRegisterReferralUser());

              return {
                accountId,
                ...sendReferralRegistrationRequest(getState),
              };
          }
        }
      )
      .then(() => dispatch(loadMyProfile()))
      .then(() => {
        batch(() => {
          dispatch(unlockTopModal());
          dispatch(
            dismissModalWithType({
              modalType: ModalType.LOGIN,
              modalDismissReason: FROM_FLOW,
            })
          );
          dispatch(
            addToast({
              type: ToastType.REGULAR,
              title: sharedMessages.loginSuccessMessage,
            })
          );
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(unlockTopModal());
          if (err.status === 412) {
            dispatch(
              dismissModalWithType({
                modalType: ModalType.LOGIN,
                modalDismissReason: FROM_FLOW,
              })
            );
            dispatch(openRegistrationDisabledModal());
          }
        });
      });
  };

export const logoutFromProvider = (provider) => {
  switch (provider) {
    case LoginProvider.TANGO_PHONE_NUMBER:
    case LoginProvider.TANGO_DISPOSABLE_TOKEN:
      return Promise.resolve();
    case LoginProvider.GOOGLE:
      return Promise.resolve();
    case LoginProvider.APPLE:
      return Promise.resolve();
  }
};

export const loadProvider = (provider) => {
  switch (provider) {
    case LoginProvider.TANGO_PHONE_NUMBER:
    case LoginProvider.TANGO_DISPOSABLE_TOKEN:
    case LoginProvider.GOOGLE:
    case LoginProvider.APPLE:
      return Promise.resolve();
  }
};

export const onLogoutSuccess = (provider, reason) => (dispatch, getState) => {
  dispatch(logoutSuccess());
  dispatch(
    addToast({
      type: ToastType.REGULAR,
      title: sharedMessages.logoutSuccessMessage,
    })
  );
  emitReasonAction(reason);
  const handleLogoutError = (error) =>
    dispatch(failedToLogoutFromProvider({ error, provider }));
  if (provider === null) {
    return Promise.resolve();
  }
  if (loginSelectors.getAvailableProviders(getState()).includes(provider)) {
    return logoutFromProvider(provider).catch(handleLogoutError);
  }

  return loadProvider(provider)
    .then(() => logoutFromProvider(provider))
    .catch(handleLogoutError);
};

export const logout =
  ({ reason, history }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (
      !loginSelectors.isLoggedIn(state) ||
      loginSelectors.isLogoutInProgress(state)
    ) {
      dispatch(refreshPWATutorial());

      return;
    }

    history.push(linkToBasePath);

    const provider = loginSelectors.getLoggedInProvider(state);
    dispatch(logoutStart());
    cleanLocalStorageByKeys(LOCAL_STORAGE_KEYS);

    batch(() => {
      dispatch(onLogoutSuccess(provider, reason));
      dispatch(
        setDropdownMenuState({
          isAvatarMenuVisible: false,
          isBurgerMenuVisible: false,
          shouldHideInstantly: true,
        })
      );
      dispatch(refreshPWATutorial());
    });

    await dispatch(checkInVisitor());
  };

export const tryValidation =
  ({ locale, deviceToken, webPushToken }) =>
  (dispatch) =>
    validation({
      locale,
      osVersion: getOsVersion(),
      deviceToken,
      deviceTokens: [
        ...(webPushToken
          ? [
              {
                deviceTokenType: DeviceTokenType.WEB_PUSH_API,
                deviceToken: webPushToken,
              },
            ]
          : []),
        {
          deviceTokenType: DeviceTokenType.TANGO_TOKEN,
          deviceToken,
        },
      ],
    })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log("Validation failure: ", err);

        if (err.status === HTTP_CODE_UNAUTHORIZED) {
          dispatch(refreshSessionToken());
        }
      })
      .finally(() => {
        dispatch(validationEnd());
      });
