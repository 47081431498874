import {
  type PayloadAction,
  SerializedError,
  createSlice,
} from "@reduxjs/toolkit";
import {
  CurrentVipLevelDetails,
  LevelsDetails,
  VipLevelJsonDataFromZip,
  VipModel,
} from "src/features/loyaltyProgram/common/types";
import { Nullable } from "src/features/loyaltyProgram/imports/types";
import { loadVipManagerLightModelsList } from "src/features/loyaltyProgram/state/asyncActions";

export interface LoyaltyProgramState {
  currentTierIndex: number;
  currentVipLevelDetails: CurrentVipLevelDetails;
  error: Nullable<SerializedError>;
  levelsDetails: Array<LevelsDetails>;
  loading: boolean;
  loyaltyProgram: Array<VipModel>;
  selectedTierIndex: number;
  tier: VipModel;
  tiers: VipModel[];
  vipInfo: {
    capsVersion: number;
    coinsLeft: string;
    expiration: string;
    level: number;
    totalSum: string;
    vipConfigId: string;
  };
  vipLevelsDataFromZip: {
    [key: string]: VipLevelJsonDataFromZip;
  };
}

export const initialState: LoyaltyProgramState = {
  loyaltyProgram: [],
  tiers: [],
  tier: {
    bundleZipUrl: "",
    id: "",
    label: null,
    level: 0,
    name: "",
    state: "",
    weight: 0,
  },
  loading: false,
  error: null,
  selectedTierIndex: 0,
  vipInfo: {
    capsVersion: 0,
    coinsLeft: "0",
    expiration: "1712227691",
    level: 0,
    totalSum: "0",
    vipConfigId: "0",
  },
  currentVipLevelDetails: {
    periodInfo: {
      expirationTime: "1712227691",
      startTime: "1702227691",
    },
    vipConfigId: "0",
    crownsEarned: 0,
  },
  currentTierIndex: 0,
  levelsDetails: [],
  vipLevelsDataFromZip: {},
};

const slice = createSlice({
  name: "loyaltyProgram",
  initialState,
  reducers: {
    setSelectedTierIndex(state, action: PayloadAction<number>) {
      state.selectedTierIndex = action.payload >= 0 ? action.payload : 0;
    },
    setCurrentTierIndex(state, action: PayloadAction<number>) {
      state.currentTierIndex = action.payload;
    },
    setVipInfo(state, action: PayloadAction<LoyaltyProgramState["vipInfo"]>) {
      state.vipInfo = action.payload;
    },
    setVipLevelsDataFromZip(
      state,
      action: PayloadAction<{ id: string; value: VipLevelJsonDataFromZip }>
    ) {
      state.vipLevelsDataFromZip = {
        ...state.vipLevelsDataFromZip,
        [action.payload.id]: action.payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadVipManagerLightModelsList.fulfilled,
      (state, action) => {
        state.loyaltyProgram = action.payload.vipModels;
        state.tiers = action.payload.vipModels;
        state.currentVipLevelDetails = action.payload.currentVipLevelDetails;
        state.levelsDetails = action.payload.levelsDetails;
        state.selectedTierIndex = 0;
        state.loading = false;
      }
    );
    builder.addCase(loadVipManagerLightModelsList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loadVipManagerLightModelsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  setSelectedTierIndex,
  setVipInfo,
  setCurrentTierIndex,
  setVipLevelsDataFromZip,
} = slice.actions;

export const loyaltyProgramReducer = slice.reducer;
