import { enhancedFetch as fetch } from "chat/imports/utils";
import {
  GetConversationsByIdsRequest,
  GetConversationsByIdsResponse,
  GetPinedChatsRequestResponse,
  SetPinedChatResponse,
} from "chat/types";
import { getProxycadorHost } from "environment";

export const getPinnedChatsIds = (): Promise<GetPinedChatsRequestResponse> =>
  fetch(`${getProxycadorHost()}/tc2/v2/conversations/pinned`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((resp) => resp.json());

export const getConversationsByIds = ({
  conversation_ids,
  direction,
}: GetConversationsByIdsRequest): Promise<GetConversationsByIdsResponse> =>
  fetch(`${getProxycadorHost()}/tc2/v2/messages/by_conversations/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      conversation_ids,
      direction,
      include_group_info: true,
      include_account_info: true,
    }),
  }).then((resp) => resp.json());

export const setPinned = ({
  conversationId,
  pin = false,
}: {
  conversationId: string;
  pin?: boolean;
}): Promise<SetPinedChatResponse> =>
  fetch(
    `${getProxycadorHost()}/tc2/v2/conversations/${conversationId}/set-pinned?pin=${pin}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then((resp) => resp.json());
