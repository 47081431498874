import { DeviceType } from "src/enums";
import { HlsTech } from "src/features/stream/utils/hlsPlayback";
import { RootState } from "src/state/delegate";
import { serverOwnedConfigSelectors } from "src/state/selectors";
import { parseBooleanEnabledSOC } from "state/abTests";

export const playerDecodeErrorRecoveryEnabled = parseBooleanEnabledSOC(
  "web.player.decode.error.recovery.enabled",
  "0"
);

type HlsJsConfiguration = {
  /*
  The maximum duration of buffered media to keep once it has been played, in seconds. 
  Any video buffered past this duration will be evicted. Infinity means no restriction 
  on back buffer length; 0 keeps the minimum amount. 
  The minimum amount is equal to the target duration of a segment to ensure that current 
  playback is not interrupted. Keep in mind, the browser can and does evict media from 
  the buffer on its own, so with the Infinity setting, HLS.js will let the browser do what 
  it needs to do. (Ref: the MSE spec under coded frame eviction).
  */
  backBufferLength: number;

  /*
  Setting config.debug = true; will turn on debug logs on JS console.
  */
  debug: boolean;

  /*
  Enable WebWorker (if available on browser) for TS demuxing/MP4 remuxing, to improve performance and avoid lag/frame drops.
  */
  enableWorker: boolean;

  /*
  Whether or not to force having a key frame in the first AVC sample after a discontinuity. 
  If set to true, after a discontinuity, the AVC samples without any key frame will be 
  dropped until finding one that contains a key frame. If set to false, all AVC samples 
  will be kept, which can help avoid holes in the stream. Setting this parameter 
  to false can also generate decoding weirdness when switching level or seeking.
  */
  forceKeyFrameOnDiscontinuity: boolean;

  /*
  Controls how corrupted video data is handled based on MPEG-TS integrity checks.
  - `'process'` (default): Continues processing corrupted data, which may lead to decoding errors.
  - `'skip'`: Discards corrupted video data to prevent potential playback issues.

  This parameter accepts a string with possible values: `'process'` | `'skip'`.
  */
  handleMpegTsVideoIntegrityErrors: "process" | "skip";

  /*
  Override current Media Source duration to Infinity for a live broadcast. Useful, if you 
  are building a player which relies on native UI capabilities in modern browsers. 
  If you want to have a native Live UI in environments like iOS Safari, Safari, 
  Android Google Chrome, etc. set this value to true.
  */
  liveDurationInfinity: boolean;

  /*
  maximum distance from the edge before the player seeks forward to hls.liveSyncPosition 
  configured using liveMaxLatencyDurationCount (multiple of target duration) or 
  liveMaxLatencyDuration returns 0 before first playlist is loaded
  */
  liveMaxLatencyDurationCount: number;

  /*
  edge of live delay, expressed in multiple of EXT-X-TARGETDURATION. if set to 3, playback 
  will start from fragment N-3, N being the last fragment of the live playlist.
  decreasing this value is likely to cause playback stalls.
  */
  liveSyncDurationCount: number;

  /*
  increment to the calculated `hls.targetLatency` on each playback stall, expressed in seconds.
  When `liveSyncDuration` is specified in config,
  `hls.targetLatency` is calculated as `liveSyncDuration` plus `liveSyncOnStallIncrease` multiplied by number of stalls.
  Otherwise `hls.targetLatency` is calculated as `liveSyncDurationCount` multiplied by `EXT-X-TARGETDURATION`
  plus `liveSyncOnStallIncrease` multiplied by number of stalls.

  */
  liveSyncOnStallIncrease: number;

  /*
  Maximum buffer length in seconds. If buffer length is/become less than this value, 
  a new fragment will be loaded. This is the guaranteed buffer length HLS.js will try
  to reach, regardless of maxBufferSize.
  */
  maxBufferLength: number;

  /*
  When set to a value greater than 1, the latency-controller will adjust video.playbackRate up to maxLiveSyncPlaybackRate 
  to catch up to target latency in a live stream. hls.targetLatency is based on liveSyncDuration|Count or manifest PART-|HOLD-BACK.
  */
  maxLiveSyncPlaybackRate: number;

  /*
  Whether or not playback rate adjustment adjustment only allowed in 1 segment lag;
  */
  restrictLiveSyncPlaybackRateCheckToLiveRange: boolean;

  /*
  Start prefetching start fragment although media not attached yet.
  */
  startFragPrefetch: boolean;

  /*
  Provide a path to hls.worker.js as an alternative to injecting the worker based on the iife library wrapper function.
  When workerPath is defined as a string, the transmuxer interface will initialize a WebWorker using the resolved workerPath URL.
  When using the ESM version of the library (hls.mjs), this option is required in order for web workers to be used.
  */
  workerPath: string;
};

const defaultWorkerPath = "/hls-js/hls.worker.js";

const defaultHlsJsConfig: Record<HlsTech, HlsJsConfiguration> = {
  alhls: {
    backBufferLength: 6,
    debug: false,
    enableWorker: false,
    forceKeyFrameOnDiscontinuity: true,
    handleMpegTsVideoIntegrityErrors: "skip",
    liveMaxLatencyDurationCount: 8,
    liveSyncDurationCount: 4,
    liveDurationInfinity: false,
    liveSyncOnStallIncrease: 0,
    maxLiveSyncPlaybackRate: 1.2,
    maxBufferLength: 20,
    restrictLiveSyncPlaybackRateCheckToLiveRange: false,
    startFragPrefetch: true,
    workerPath: defaultWorkerPath,
  },
  hls: {
    backBufferLength: 6,
    debug: false,
    enableWorker: false,
    forceKeyFrameOnDiscontinuity: true,
    handleMpegTsVideoIntegrityErrors: "skip",
    liveSyncDurationCount: 2,
    liveMaxLatencyDurationCount: 6,
    liveDurationInfinity: false,
    liveSyncOnStallIncrease: 0,
    maxLiveSyncPlaybackRate: 1.2,
    maxBufferLength: 20,
    restrictLiveSyncPlaybackRateCheckToLiveRange: false,
    startFragPrefetch: true,
    workerPath: defaultWorkerPath,
  },
  lhls: {
    backBufferLength: 6,
    debug: false,
    enableWorker: false,
    forceKeyFrameOnDiscontinuity: true,
    handleMpegTsVideoIntegrityErrors: "skip",
    liveSyncDurationCount: 4,
    liveMaxLatencyDurationCount: 8,
    liveDurationInfinity: false,
    liveSyncOnStallIncrease: 0,
    maxLiveSyncPlaybackRate: 1.2,
    maxBufferLength: 20,
    restrictLiveSyncPlaybackRateCheckToLiveRange: false,
    startFragPrefetch: true,
    workerPath: defaultWorkerPath,
  },
};

export const hlsJsConfiguration = (tech: HlsTech) => (state: RootState) => {
  const config = { ...defaultHlsJsConfig[tech] };
  try {
    const overrides = JSON.parse(
      serverOwnedConfigSelectors.getConfigParamByKey(
        state,
        `web.player.hls-js.config.overrides.${tech}`,
        "{}"
      )
    );

    const isFiniteNumber = (value: unknown) =>
      typeof value === "number" && isFinite(value);

    if (
      `backBufferLength` in overrides &&
      isFiniteNumber(overrides.backBufferLength)
    ) {
      config.backBufferLength = Number(overrides.backBufferLength);
    }

    if (`debug` in overrides) {
      config.debug = Boolean(overrides.debug);
    }

    if (`enableWorker` in overrides) {
      config.enableWorker = Boolean(overrides.enableWorker);
    }

    if (`forceKeyFrameOnDiscontinuity` in overrides) {
      config.forceKeyFrameOnDiscontinuity = Boolean(
        overrides.forceKeyFrameOnDiscontinuity
      );
    }

    if (
      `handleMpegTsVideoIntegrityErrors` in overrides &&
      (overrides.handleMpegTsVideoIntegrityErrors === "skip" ||
        overrides.handleMpegTsVideoIntegrityErrors === "process")
    ) {
      config.handleMpegTsVideoIntegrityErrors =
        overrides.handleMpegTsVideoIntegrityErrors;
    }

    if (
      `liveSyncDurationCount` in overrides &&
      isFiniteNumber(overrides.liveSyncDurationCount)
    ) {
      config.liveSyncDurationCount = Number(overrides.liveSyncDurationCount);
    }

    if (
      `liveMaxLatencyDurationCount` in overrides &&
      isFiniteNumber(overrides.liveMaxLatencyDurationCount)
    ) {
      config.liveMaxLatencyDurationCount = Number(
        overrides.liveMaxLatencyDurationCount
      );
    }

    if (`liveDurationInfinity` in overrides) {
      config.liveDurationInfinity = Boolean(overrides.liveDurationInfinity);
    }

    if (
      `liveSyncOnStallIncrease` in overrides &&
      isFiniteNumber(overrides.liveSyncOnStallIncrease)
    ) {
      config.liveSyncOnStallIncrease = Number(
        overrides.liveSyncOnStallIncrease
      );
    }

    if (
      `maxLiveSyncPlaybackRate` in overrides &&
      isFiniteNumber(overrides.maxLiveSyncPlaybackRate)
    ) {
      config.maxLiveSyncPlaybackRate = Number(
        overrides.maxLiveSyncPlaybackRate
      );
    }

    if (
      `maxBufferLength` in overrides &&
      isFiniteNumber(overrides.maxBufferLength)
    ) {
      config.maxBufferLength = Number(overrides.maxBufferLength);
    }

    if (`restrictLiveSyncPlaybackRateCheckToLiveRange` in overrides) {
      config.restrictLiveSyncPlaybackRateCheckToLiveRange = Boolean(
        overrides.restrictLiveSyncPlaybackRateCheckToLiveRange
      );
    }

    if (`startFragPrefetch` in overrides) {
      config.startFragPrefetch = Boolean(overrides.startFragPrefetch);
    }

    if (`workerPath` in overrides && typeof overrides.workerPath === "string") {
      config.workerPath = overrides.workerPath;
    }
  } catch {
    // ignore
  }

  return config;
};

export enum PlayerType {
  HLS_PLAYER = "hls-player",
  TANGO_PLAYER = "tango-player",
}

const getIsTangoPlayerIosEnabled = parseBooleanEnabledSOC(
  "web.tango.player.ios.enabled",
  "0"
);

const getIsTangoPlayerDesktopEnabled = parseBooleanEnabledSOC(
  "web.tango.player.desktop.enabled",
  "0"
);

export const getPlayerType =
  (deviceType: DeviceType) =>
  (state: RootState): PlayerType => {
    switch (deviceType) {
      case DeviceType.IOS: {
        return getIsTangoPlayerIosEnabled(state)
          ? PlayerType.TANGO_PLAYER
          : PlayerType.HLS_PLAYER;
      }
      case DeviceType.DESKTOP:
        return getIsTangoPlayerDesktopEnabled(state)
          ? PlayerType.TANGO_PLAYER
          : PlayerType.HLS_PLAYER;
      default:
        return PlayerType.HLS_PLAYER;
    }
  };

type TangoPlayerConfig = {
  logLevel: number;
  overlayEnabled: boolean;
};

const defaultTangoConfig: TangoPlayerConfig = {
  logLevel: 0,
  overlayEnabled: false,
};

export const getTangoPlayerConfig = (state: RootState): TangoPlayerConfig => {
  const config = { ...defaultTangoConfig };
  try {
    const overrides = JSON.parse(
      serverOwnedConfigSelectors.getConfigParamByKey(
        state,
        "web.tango.player.config",
        "{}"
      )
    );
    if (`logLevel` in overrides && Number.isSafeInteger(overrides.logLevel)) {
      config.logLevel = Number(config.logLevel);
    }
    if (`overlayEnabled` in overrides) {
      config.overlayEnabled = Boolean(overrides.overlayEnabled);
    }
  } catch {
    // ignore
  }

  return config;
};
