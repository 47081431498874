import React, { TouchEvent, UIEvent, forwardRef } from "react";
import { MosGift } from "src/features/mos/components/MosGift/MosGift";
import { MosSpecialOffer } from "src/features/mos/components/MosSpecialOffer/MosSpecialOffer";
import { MOS_LINEUP_ID } from "src/features/mos/constants";
import { SpecialOfferType } from "src/features/mos/imports/types";
import {
  GiftAnalyticsParams,
  GiftClickParams,
  GiftVariant,
  MosItemTypeAbbreviation,
  MosUiItem,
} from "src/features/mos/types";

interface MosLineup {
  containerClassName?: string;
  handleGiftClick?: (giftParams: GiftClickParams) => GiftAnalyticsParams;
  mosItems: MosUiItem[];
  onOfferClick?: (offer: SpecialOfferType) => void;
  onScroll: (e: UIEvent<HTMLDivElement>) => void;
  variant: GiftVariant;
}

const preventPropagation = (event: TouchEvent<HTMLDivElement>) => {
  event?.stopPropagation?.();
};

export const MosLineup = forwardRef<HTMLDivElement, MosLineup>(
  (
    {
      containerClassName,
      mosItems,
      handleGiftClick,
      onScroll,
      onOfferClick,
      variant,
    },
    ref
  ) => {
    if (!mosItems.length) {
      return null;
    }

    return (
      <div
        id={MOS_LINEUP_ID}
        className={containerClassName}
        ref={ref}
        onScroll={onScroll}
        data-testid="mos-lineup"
        onTouchStart={preventPropagation}
        onTouchMove={preventPropagation}
        onTouchEnd={preventPropagation}
      >
        {mosItems.map(({ content, typeAbbreviation }, index) => {
          if (typeAbbreviation === MosItemTypeAbbreviation.OFFER_PLACEHOLDER) {
            return (
              <MosSpecialOffer
                key={content.gift.campaignId}
                onClick={onOfferClick}
                offer={content.gift}
              />
            );
          }

          return (
            <MosGift
              key={content.gift.id}
              gift={content.gift}
              typeAbbreviation={typeAbbreviation}
              handleGiftClick={handleGiftClick}
              variant={variant}
              position={index}
            />
          );
        })}
      </div>
    );
  }
);

MosLineup.displayName = "MosLineup";
