import { parseBooleanEnabledSOC } from "src/features/broadcastLobby/imports/state";

export const isBroadcastLobbyEnabled = parseBooleanEnabledSOC(
  "web.broadcast.lobby.enabled",
  "0"
);

export const isBroadcastLobbyDesktopEnabled = parseBooleanEnabledSOC(
  "web.broadcast.lobby.desktop.enabled",
  "0"
);

export const isBroadcastLobbyMasksEnabled = parseBooleanEnabledSOC(
  "web.broadcast.lobby.masks.enabled",
  "0"
);

export const isBroadcastLobbyHashtagsEnabled = parseBooleanEnabledSOC(
  "web.broadcast.lobby.hashtags.enabled",
  "0"
);

export const isBroadcastLobbyPremiumEnabled = parseBooleanEnabledSOC(
  "web.broadcast.lobby.premium.enabled",
  "0"
);

export const isBroadcastLobbyPartyEnabled = parseBooleanEnabledSOC(
  "web.broadcast.lobby.party.enabled",
  "0"
);

export const getBroadcastLobbyMasksAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "web.broadcast.lobby.masks.alternative.domain.content.support.enabled",
    "0"
  );
