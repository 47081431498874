/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.MessagePayloads || ($protobuf.roots.MessagePayloads = {});

export const TCHappyMomentInfo = $root.TCHappyMomentInfo = (() => {

    function TCHappyMomentInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    TCHappyMomentInfo.prototype.momentId = "";

    TCHappyMomentInfo.create = function create(properties) {
        return new TCHappyMomentInfo(properties);
    };

    TCHappyMomentInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.momentId != null && Object.hasOwnProperty.call(message, "momentId"))
            writer.uint32(10).string(message.momentId);
        return writer;
    };

    TCHappyMomentInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    TCHappyMomentInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TCHappyMomentInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.momentId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    TCHappyMomentInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    TCHappyMomentInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.TCHappyMomentInfo)
            return object;
        let message = new $root.TCHappyMomentInfo();
        if (object.momentId != null)
            message.momentId = String(object.momentId);
        return message;
    };

    TCHappyMomentInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.momentId = "";
        if (message.momentId != null && message.hasOwnProperty("momentId"))
            object.momentId = message.momentId;
        return object;
    };

    TCHappyMomentInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    TCHappyMomentInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TCHappyMomentInfo";
    };

    return TCHappyMomentInfo;
})();

export { $root as default };
