import { analyticsUpgradeGuest } from "src/core/analytics/utils/analyticsUpgradeGuest";
import { registerVisitor } from "src/features/signin/api/login";
import { CLIENT_CAPABILITIES } from "src/features/signin/imports/constants";
import { LoginProvider, LoginResult } from "src/features/signin/imports/enums";
import { loginSelectors } from "src/features/signin/imports/state";
import { getOsVersion } from "src/features/signin/imports/utils";
import {
  loginWithProviderSelectors,
  shouldRetryLogin,
} from "src/features/signin/state/flows/login";
import providerToApi from "src/features/signin/utils/mapProviderToApi";

const attemptUpgradeGuest = async ({
  credential,
  fingerprint,
  locale,
  provider,
}) => {
  const body = {
    credential,
    fingerprint,
    locale,
    clientCapabilities: CLIENT_CAPABILITIES,
    osVersion: getOsVersion(),
    clientVersion: GENERATED_APP_INFO.fullVersion,
  };

  return registerVisitor({
    body,
    provider: providerToApi[provider],
  });
};

export const upgradeGuestWithRetry = (state) => async (credential) => {
  const { type, phoneNumber, verificationCode } = credential;
  const { isAuthorized, fingerprint, locale } =
    loginWithProviderSelectors(state);

  if (type === LoginProvider.TANGO_PHONE_NUMBER) {
    const resendAllowedTimestamp = loginSelectors.getResendAllowedTimestamp(
      state,
      phoneNumber
    );
    if (
      typeof resendAllowedTimestamp === "number" &&
      typeof verificationCode !== "string" &&
      new Date() < resendAllowedTimestamp
    ) {
      return {
        loginResult: LoginResult.VERIFICATION_REQUIRED,
        // it's possible to resend SMS but not TANGO_CHAT message
        deliveryMethod: "SMS",
        credential: { type: LoginProvider.TANGO_PHONE_NUMBER, phoneNumber },
      };
    }
  }

  const upgradeGuestBody = {
    credential,
    isAuthorized,
    fingerprint,
    locale,
    provider: type,
  };

  const firstAttempt = await attemptUpgradeGuest(upgradeGuestBody);

  if (!shouldRetryLogin(firstAttempt)) {
    analyticsUpgradeGuest(firstAttempt, credential?.type);

    return { ...firstAttempt, credential };
  }

  const secondAttempt = await attemptUpgradeGuest(upgradeGuestBody);

  if (!shouldRetryLogin(secondAttempt)) {
    analyticsUpgradeGuest(secondAttempt, credential?.type);

    return { ...secondAttempt, credential };
  }
  // eslint-disable-next-line no-throw-literal
  throw "Login failed";
};
