import React, { PropsWithChildren } from "react";
import classnames from "classnames";
import { CarouselButton } from "src/features/chat/imports/components";
import { Breakpoints } from "src/features/chat/imports/constants";
import { useBreakpointPrecise } from "src/features/chat/imports/hooks";
import styles from "src/features/chat/components/Mos/MosInChat.scss";

const SCROLL_OFFSET = 300;

interface MosCarousel {
  handleScroll: (offset: number) => VoidFunction;
  isNextBtnDisabled: boolean;
  isPrevBtnDisabled: boolean;
}

export const MosCarouselControls = ({
  isPrevBtnDisabled,
  isNextBtnDisabled,
  handleScroll,
  children,
}: PropsWithChildren<MosCarousel>) => {
  const breakpoint = useBreakpointPrecise();

  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  return (
    <>
      {isDesktop && (
        <CarouselButton
          onClick={handleScroll(-SCROLL_OFFSET)}
          className={classnames(styles.carouselBtn, styles.btnPrev)}
          disabled={isPrevBtnDisabled}
        />
      )}

      {children}

      {isDesktop && (
        <CarouselButton
          type="next"
          onClick={handleScroll(SCROLL_OFFSET)}
          className={classnames(styles.carouselBtn, styles.btnNext)}
          disabled={isNextBtnDisabled}
        />
      )}
    </>
  );
};
