import { useSelector } from "react-redux";
import {
  getAntMediaServerV2WebsocketUrl,
  getAntMediaServerWebsocketUrl,
} from "environment";
import { MIN_AGE_TO_BROADCAST } from "src/constants";
import { getMyAgeSelector } from "state/selectors";

export default () => {
  const antMediaServerV2WebsocketUrl = getAntMediaServerV2WebsocketUrl();
  const antMediaServerWebsocketUrl = getAntMediaServerWebsocketUrl();
  const myAge = useSelector(getMyAgeSelector);

  return (
    (!!antMediaServerWebsocketUrl || !!antMediaServerV2WebsocketUrl) &&
    (!myAge || myAge > MIN_AGE_TO_BROADCAST)
  );
};
