import { getProxycadorHost } from "environment";
import {
  VipManagerLightModelsListResponse,
  VipManagerLoungesListResponse,
} from "src/features/loyaltyProgram/common/types";
import { enhancedFetch } from "src/features/loyaltyProgram/imports/utils";

export const loadVipManagerLightModelsListRequest =
  (): Promise<VipManagerLightModelsListResponse> =>
    enhancedFetch(`${getProxycadorHost()}/vipmanager/v1/vip/lightmodels`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }).then((resp) => resp.json());

export const loadVipManagerLoungesListRequest =
  (): Promise<VipManagerLoungesListResponse> =>
    enhancedFetch(`${getProxycadorHost()}/vipmanager/v3/vip/lounges`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }).then((resp) => resp.json());
