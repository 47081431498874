import { Country } from "src/state/tree/countries";
import {
  isAlternativeDomain,
  replaceDomainWithHostname,
} from "src/utils/alternativeDomainUtils";

export const fetchCountriesForSignIn = (): Promise<Country[]> => {
  const tangoURL = "https://resources.tango.me/web/phone-login-countries.json";
  const url = isAlternativeDomain()
    ? replaceDomainWithHostname(tangoURL)
    : tangoURL;

  return fetch(url).then((resp) => resp.json());
};
