import React, { FC } from "react";
import classnames from "classnames";
import LegalLanguagePicker from "legal/components/LegalLanguagePicker/LegalLanguagePicker";
import PostDate from "legal/components/postDate/PostDate";
import { getLegalLocalisationEnabled } from "legal/imports/environment";
import { useBreakpointPrecise } from "legal/imports/hooks";
import styles from "./ArticleMeta.scss";

interface ArticleMeta {
  postDate: string;
  withLanguagePicker?: boolean;
}

const ArticleMeta: FC<ArticleMeta> = ({
  postDate,
  withLanguagePicker = false,
}) => {
  const breakpoint = useBreakpointPrecise();

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      <PostDate>{postDate}</PostDate>

      {withLanguagePicker && getLegalLocalisationEnabled() && (
        <LegalLanguagePicker />
      )}
    </div>
  );
};

export default ArticleMeta;
