import { parseBooleanEnabledSOC } from "stream/imports/state";

export const getMuteParticipantsInLPEnabled = parseBooleanEnabledSOC(
  "web.mute.participants.in.lp.enabled",
  "0"
);

export const getShowPipMenuByTapEnabled = parseBooleanEnabledSOC(
  "web.show.pip.menu.by.tap.enabled",
  "0"
);

export const getMuteBroadcasterMicrophoneIndicatorEnabled =
  parseBooleanEnabledSOC(
    "web.mute.broadcaster.microphone.indicator.enabled",
    "0"
  );
