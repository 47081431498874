import { getProxycadorHost } from "chat/imports/environment";
import { enhancedFetch } from "chat/imports/utils";
import { FetchMosV2ChatLineupResponse } from "chat/types";

export const fetchMosV2ForChat = ({
  companionId,
}: {
  companionId: string;
}): Promise<FetchMosV2ChatLineupResponse> =>
  enhancedFetch(
    `${getProxycadorHost()}/gift-box/public/v2/client/chat/lineup?companionId=${companionId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((resp) => resp.json());
