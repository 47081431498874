import { batch } from "react-redux";
import { Dispatch } from "redux";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import {
  GIFTING_RESULT_SUCCESS,
  mapErrorToGiftingResult,
} from "@analytics/gifting/giftingResult";
import { buyTicket } from "api/stream";
import {
  boughtTicket,
  failedToBuyTicket,
} from "state/actionCreators/viewerSession";

export interface PrivateStreamAnalyticEventsParams {
  [EventFields.GIFT_ID]: string;
  [EventFields.GIFT_SOURCE]: string;
  [EventFields.ONE_CLICK_GIFTING]: number;
  [EventFields.STREAM_ID]: string;
  [EventFields.STREAM_KIND]?: number;
  [EventFields.TICKET_PRICE]: number;
}

export const executeBuyTicket =
  (
    streamId: string,
    analyticEvents: PrivateStreamAnalyticEventsParams,
    isOneClickPurchase: boolean
  ) =>
  (dispatch: Dispatch) =>
    buyTicket(streamId).then(
      (balance) => {
        batch(() => {
          emitEvent(EventNames.SEND_GIFT, {
            ...analyticEvents,
            [EventFields.ONE_CLICK_PURCHASE]: Number(isOneClickPurchase),
            [EventFields.RESULT]: GIFTING_RESULT_SUCCESS,
          });
          dispatch(boughtTicket({ streamId, balance }));
        });
      },
      (error) => {
        emitEvent(EventNames.SEND_GIFT, {
          ...analyticEvents,
          [EventFields.ONE_CLICK_PURCHASE]: Number(isOneClickPurchase),
          [EventFields.RESULT]: mapErrorToGiftingResult(error),
        });

        return dispatch(failedToBuyTicket(error));
      }
    );
