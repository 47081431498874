export enum GiftDrawerTarget {
  AUDIO_ROOM = "AUDIO_ROOM",
  CALL = "CALL",
  CHAT = "CHAT",
  COLLECTIONS = "COLLECTIONS",
  HAPPY_MOMENT = "HAPPY_MOMENT",
  POST = "POST",
  PREMIUM_ENTRANCE = "PREMIUM_ENTRANCE",
  PREMIUM_MESSAGE = "PREMIUM_MESSAGE",
  PROFILE = "PROFILE",
  STICKER = "STICKER",
  STREAM = "STREAM",
  UNKNOWN_TARGET = "UNKNOWN_TARGET",
}

export enum GetMyGiftDrawerResponseCode {
  FAILED = "FAILED",
  LATEST = "LATEST",
  UPDATED = "UPDATED",
}

export interface GiftDrawerRequest {
  currentDrawerEncryptedId?: string;
  currentDrawerEncryptedVersion?: string;
  target: GiftDrawerTarget;
}

export interface GiftDrawerRequestDTO {
  drawerId?: string;
  drawerVersion?: string;
  target: GiftDrawerTarget;
}

export interface GetMyGiftDrawerResponse {
  giftDrawer: GiftDrawer;
  status: GetMyGiftDrawerResponseCode;
}

export interface GiftDrawer {
  categories: GiftCategory[];
  drawerVersion: string;
  encryptedDrawerId: string;
}

export interface GiftCategory {
  categoryMetadata: CategoryMetadata;
  encryptedCategoryId: string;
  entities: CategoryEntity[];
}

export interface CategoryMetadata {
  activeIconUrl: string;
  iconUrl: string;
  name: string;
}

export type CategoryEntity = Gift | GiftCollection;

export interface Gift {
  gift: {
    badgeMetadata?: BadgeMetadata;
    encryptedGiftId: string;
  };
}

export interface GiftCollection {
  giftCollection: {
    badgeMetadata?: BadgeMetadata;
    encryptedCollectionId: string;
    gifts: Gift[];
  };
}

export interface BadgeMetadata {
  newUntil?: number;
  saleUntil?: number;
}

export const isGiftType = (entity: CategoryEntity): entity is Gift =>
  "gift" in entity;
