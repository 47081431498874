import { defineMessages } from "react-intl";
export const messages = defineMessages({
  aboutUs: {
    id: "nav.about-us",
    defaultMessage: "About Us",
  },
  artistCommunity: {
    id: "menu.items.artist-community",
    defaultMessage: "Artist Community",
  },
  customerSupport: {
    id: "header.dropdown.customer-support",
    defaultMessage: "Customer Support",
  },
  joinTango: {
    id: "join.tango",
    defaultMessage: "Join Tango",
  },
  partnersProgram: {
    id: "partners.program",
    defaultMessage: "Partners Program",
  },
  privacyPolicy: {
    id: "legalNav.privacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  publisherTermsAndConditions: {
    id: "legalNav.publisherTermsAndConditions",
    defaultMessage: "Publisher Terms & Conditions",
  },
  tangoLoyaltyProgram: {
    id: "menu.items.vipLoyaltyProgram",
    defaultMessage: "Tango Loyalty Program",
  },
  termsOfUse: {
    id: "legalNav.termsOfUse",
    defaultMessage: "Terms of Use",
  },
});
