import { liveUrl } from "api/playlist";
import { TANGO_ACCOUNT_ID } from "src/constants";
import { StreamSessionInitializationResult } from "src/enums";
import {
  getEnabledPaymentProviders,
  getMintrouteBannerEnabledCountries,
} from "src/environment";
import { autoLoginSelectors as localAutoLoginSelectors } from "src/features/autoLogin/exports/state";
import { feedPostSelectors as localFeedPostSelectors } from "src/features/feed/exports/state/selectors";
import {
  pointsSelectors as loadPointsSelectors,
  redeemConfigSelectors as loadRedeemConfigSelectors,
} from "src/features/finance/redeems/exports/selectors";
import { gifterStatisticsSelectors as localGifterUsersSelectors } from "src/features/gifterStatistics/exports/state/selectors";
import { homePageStreamsSelectors as localHomePageStreamsSelectors } from "src/features/homePage/shared/streamsState/selectors";
import {
  homePageCreatorsSelectors as localHomePageCreatorsSelectors,
  homePageGamesSelectors as localHomePageGamesSelectors,
  homePageProfilesSelectors as localHomePageProfilesSelectors,
} from "src/features/homePage/v2/exports/state";
import { homePageV2NearbySelectors as localHomePageV2NearbySelectors } from "src/features/homePage/v2/state/selectors";
import { loyaltyProgramSelectors as localLoyaltyProgramSelectors } from "src/features/loyaltyProgram/exports/selectors";
import {
  mosChatV2Selectors as localMosChatV2Selectors,
  mosV2Selectors as localMosV2Selectors,
} from "src/features/mos/exports/state";
import { welcomePremiumOfferSelectors as localWelcomePremiumOfferSelectors } from "src/features/offers/premiumEntrance/welcomePremiumOffer/state/selectors";
import { phoneNumberSettingsSelectors as localPhoneNumberSettingsSelectors } from "src/features/profile/settings/state/phoneNumberSettingsSelectors";
import { visitorProfileSelectors as localVisitorProfileSelectors } from "src/features/profile/visitorProfile/exports/state";
import { bottomSheetSelectors as localBottomSheetSelectors } from "src/features/promotions/promoBottomSheet/state/selectors";
import { loginSelectors as localLoginSelectors } from "src/features/signin/exports/state/selectors";
import {
  socialGamesFreeSpinsSelectors as localSocialGamesFreeSpinsSelectors,
  socialGamesGameSelectors as localSocialGamesGameSelectors,
  socialGamesInStreamLobbySelectors as localSocialGamesInStreamLobbySelectors,
  socialGamesLobbySelectors as localSocialGamesLobbySelectors,
} from "src/features/socialGames/exports/state";
import { localTutorialsSelectors } from "src/features/tutorials/shared/exports/state";
import { bonusWheelSelectors as localBonusWheelSelectors } from "src/state/selectors/bonusWheel";
import { selectors as localGiftsCacheSelectors } from "src/state/tree/giftsCache";
import { PaymentProvider } from "src/types/payment";
import { StreamStatus } from "src/types/richFragment/Stream";
import { calculateAgeFromDate } from "src/utils/dateUtils";
import typedDelegateSelectors from "state/delegate";
import localBannerConfigsSelectors from "state/selectors/bannerConfigs";
import localBlockedUsersSelectors from "state/selectors/blockedUsers";
import localCaptchaSelectors from "state/selectors/captcha";
import localCountriesSelectors from "state/selectors/countries";
import localCountryByIpSelectors from "state/selectors/countryByIp";
import localCurrenciesSelectors from "state/selectors/currencies";
import { selectors as localDeviceInfoSelectors } from "state/selectors/deviceInfo";
import localGuardianSelectors from "state/selectors/guardian";
import { selectors as localLandingPagePhoenixSelectors } from "state/selectors/landingPagePhoenix";
import { selectors as localLeaderBoardLandingSelectors } from "state/selectors/leaderBoardLanding";
import localNearbyCountriesSelectors from "state/selectors/nearbyCountries";
import { selectors as localPersonalOffersSelectors } from "state/selectors/personalOffers";
import { localPipsConfigSelector } from "state/selectors/pipsConfig";
import localRefreshSessionTokenSelectors from "state/selectors/refreshSessionToken";
import localSessionDetailsSelectors from "state/selectors/sessionDetails";
import localStoriesSelectors from "state/selectors/stories";
import localSubscriptionPerStreamerListSelectors from "state/selectors/subscriptionPerStreamerList";
import localSubscriptionsPerStreamerSelectors from "state/selectors/subscriptionsPerStreamer";
import localSuspensionStatusSelectors from "state/selectors/suspensionStatus";
import localTabsConfigSelectors from "state/selectors/tabsConfig";
import localTaxSelectors from "state/selectors/taxes";
import localTcnnSelectors from "state/selectors/tcnn";
import localTournamentsLandingSelectors from "state/selectors/tournamentsLanding";
import localUserPostsSelectors from "state/selectors/userPosts";
import { localVipConfigsSelectors } from "state/selectors/vipConfigs";
import localVisitorSelectors from "state/selectors/visitor";
import { selectors as afterPurchaseTaskManager } from "state/tree/afterPurchaseTaskManager";
import { selectors as localBottomScreenSelectors } from "state/tree/bottomScreen";
import { localBroadcastSelectors } from "state/tree/broadcast";
import { selectors as localServerConfigSelectors } from "state/tree/configuration";
import { selectors as localCMSelectors } from "state/tree/connectionManager";
import { selectors as localCreditCardsSelectors } from "state/tree/creditCards";
import { selectors as localCustomDrawerSelectors } from "state/tree/customDrawer";
import { localDropdownMenuSelectors } from "state/tree/dropdownMenu";
import { selectors as localFollowingListSelectors } from "state/tree/followingList";
import { selectors as localGiftingRequestsSelectors } from "state/tree/giftingRequests";
import { selectors as localHomePageV1Selectors } from "state/tree/homePageV1/selectors";
import { selectors as localIntercomSelectors } from "state/tree/intercom";
import { selectors as localLiveStreamsFeedSelectors } from "state/tree/liveStreamsFeed";
import { selectors as localModalSelectors } from "state/tree/modal";
import { selectors as localNavigationSelectors } from "state/tree/navigation";
import { selectors as localNetworkAvailabilitySelectors } from "state/tree/networkAvailability";
import { selectors as localNFTAuctionSelectors } from "state/tree/nft";
import { selectors as localNotificationsSelectors } from "state/tree/notifications";
import { selectors as localPostsFeedSelectors } from "state/tree/postsFeed";
import { selectors as localProfilesCacheSelectors } from "state/tree/profilesCache";
import { selectors as localReferralRegistrationsSelectors } from "state/tree/referralRegistrations";
import { selectors as localSettingsSelectors } from "state/tree/settings";
import {
  mintrouteShopSelectors as localMintrouteShopSelectors,
  selectors as localShopSelectors,
} from "state/tree/shop";
import { selectors as localSpecialOffers } from "state/tree/specialOffers";
import { selectors as localStreamsCacheSelectors } from "state/tree/streamsCache";
import { selectors as localTaskManagerSelectors } from "state/tree/taskManager";
import { localToastSelectors } from "state/tree/toast";
import { selectors as localTopBroadcastersSelectors } from "state/tree/topBroadcasters";
import { selectors as localUpgradedStreamsSelectors } from "state/tree/upgradedStreams";
import { selectors as localUserSelectors } from "state/tree/user";
import { selectors as localUserSearchSelectors } from "state/tree/userSearch";
import delegateSelectors from "state/tree/utils/delegateSelectors";
import { selectors as localValidationSelectors } from "state/tree/validation";
import { selectors as localViewerSessionSelectors } from "state/tree/viewerSession";
import {
  isAlternativeDomain,
  replaceDomainWithHostname,
} from "utils/alternativeDomainUtils";
import logo from "img/tango-logo.svg?url";

export const vipConfigsSelectors = typedDelegateSelectors(
  localVipConfigsSelectors,
  "vipConfigs"
);

export const modalSelectors = delegateSelectors(
  localModalSelectors,
  (state) => state.modal
);
export const bottomScreenSelectors = delegateSelectors(
  localBottomScreenSelectors,
  (state) => state.bottomScreen
);
export const loginSelectors = delegateSelectors(
  localLoginSelectors,
  (state) => state.login
);
export const userSelectors = delegateSelectors(
  localUserSelectors,
  (state) => state.user
);
export const userPostsSelectors = typedDelegateSelectors(
  localUserPostsSelectors,
  "userPosts"
);
export const followingListSelectors = delegateSelectors(
  localFollowingListSelectors,
  (state) => state.followingList
);
export const liveStreamsFeedSelectors = delegateSelectors(
  localLiveStreamsFeedSelectors,
  (state) => state.liveStreamsFeed
);
export const viewerSessionSelectors = delegateSelectors(
  localViewerSessionSelectors,
  (state) => state.viewerSession
);
export const dropdownMenuSelectors = delegateSelectors(
  localDropdownMenuSelectors,
  (state) => state.dropdownMenu
);
export const toastSelectors = delegateSelectors(
  localToastSelectors,
  (state) => state.toast
);
export const connectionManagerSelectors = delegateSelectors(
  localCMSelectors,
  (state) => state.connectionManager
);
export const giftsCacheSelectors = typedDelegateSelectors(
  localGiftsCacheSelectors,
  "giftsCache"
);
export const customDrawerSelectors = delegateSelectors(
  localCustomDrawerSelectors,
  (state) => state.customDrawer
);
export const giftingRequestsSelectors = delegateSelectors(
  localGiftingRequestsSelectors,
  (state) => state.giftingRequests
);
export const topBroadcastersSelectors = delegateSelectors(
  localTopBroadcastersSelectors,
  (state) => state.topBroadcasters
);
export const nftAuctionSelectors = delegateSelectors(
  localNFTAuctionSelectors,
  (state) => state.nftAuction
);
export const notificationsSelectors = delegateSelectors(
  localNotificationsSelectors,
  (state) => state.notifications
);
export const postsFeedSelectors = delegateSelectors(
  localPostsFeedSelectors,
  (state) => state.postsFeed
);

export const profilesCacheSelectors = delegateSelectors(
  localProfilesCacheSelectors,
  (state) => state.profilesCache
);
export const networkAvailabilitySelectors = delegateSelectors(
  localNetworkAvailabilitySelectors,
  (state) => state.networkAvailability
);
export const subscriptionPerStreamerListSelectors = typedDelegateSelectors(
  localSubscriptionPerStreamerListSelectors,
  "subscriptionPerStreamerList"
);

export const subscriptionsPerStreamerSelectors = typedDelegateSelectors(
  localSubscriptionsPerStreamerSelectors,
  "subscriptionsPerStreamer"
);

export const itemsForSendAfterPurchase = typedDelegateSelectors(
  afterPurchaseTaskManager,
  "afterPurchaseTaskManager"
);
export const creditCardsSelectors = delegateSelectors(
  localCreditCardsSelectors,
  (state) => state.creditCards
);

export const blockedUsersSelectors = typedDelegateSelectors(
  localBlockedUsersSelectors,
  "blockedUsers"
);

const storedBasicProfile = profilesCacheSelectors.getBasicProfile;
const tangoBasicProfile = {
  firstName: "Tango",
  profilePictureUrl: logo,
  profileThumbnailUrl: logo,
};
profilesCacheSelectors.getBasicProfile = (state, accountId) => {
  if (accountId === TANGO_ACCOUNT_ID) {
    return tangoBasicProfile;
  }

  return storedBasicProfile(state, accountId);
};
const storedLiveStats = profilesCacheSelectors.getLiveStats;
profilesCacheSelectors.getLiveStats = (state, accountId) => {
  const liveStats = storedLiveStats(state, accountId) || {};
  if (accountId !== userSelectors.getMyAccountId(state)) {
    return liveStats;
  }

  return { ...liveStats, vipStatus: userSelectors.getVipStatus(state) };
};

export const settingsSelectors = delegateSelectors(
  localSettingsSelectors,
  (state) => state.settings
);

export const streamsCacheSelectors = delegateSelectors(
  localStreamsCacheSelectors,
  (state) => state.streamsCache
);
export const upgradedStreamsSelectors = delegateSelectors(
  localUpgradedStreamsSelectors,
  (state) => state.upgradedStreams
);
export const serverOwnedConfigSelectors = typedDelegateSelectors(
  localServerConfigSelectors,
  "configuration"
);

export const navigationSelectors = typedDelegateSelectors(
  localNavigationSelectors,
  "navigation"
);
export const shopSelectors = delegateSelectors(
  localShopSelectors,
  (state) => state.shop
);
export const promoShopSelectors = delegateSelectors(
  localShopSelectors,
  (state) => state.promoShop
);
export const vipShopSelectors = delegateSelectors(
  localShopSelectors,
  (state) => state.vipShop
);
export const mintrouteShopSelectors = delegateSelectors(
  localMintrouteShopSelectors,
  (state) => state.mintrouteShop
);

export const captchaSelectors = typedDelegateSelectors(
  localCaptchaSelectors,
  "captcha"
);

export const intercomSelectors = typedDelegateSelectors(
  localIntercomSelectors,
  "intercom"
);
export const landingPagePhoenixSelectors = typedDelegateSelectors(
  localLandingPagePhoenixSelectors,
  "landingPagePhoenix"
);
export const personalOffersSelectors = typedDelegateSelectors(
  localPersonalOffersSelectors,
  "personalOffers"
);

export const bannerConfigsSelectors = typedDelegateSelectors(
  localBannerConfigsSelectors,
  "bannerConfigs"
);

export const taskManagerSelectors = typedDelegateSelectors(
  localTaskManagerSelectors,
  "taskManager"
);

export const specialOffersSelectors = typedDelegateSelectors(
  localSpecialOffers,
  "specialOffers"
);

export const getCurrentStream = (state) => {
  const streamId = viewerSessionSelectors.getStreamId(state);

  return streamsCacheSelectors.getStreamById(state, streamId);
};

export const getBroadcasterId = (state) => {
  const cachedStream =
    streamsCacheSelectors.getStreamById(
      state,
      viewerSessionSelectors.getStreamId(state)
    ) || {};

  return (
    viewerSessionSelectors.getBroadcasterId(state) || cachedStream.broadcasterId
  );
};

export const deviceInfoSelectors = typedDelegateSelectors(
  localDeviceInfoSelectors,
  "deviceInfo"
);
export const tcnnSelectors = typedDelegateSelectors(localTcnnSelectors, "tcnn");
export const bottomSheetSelectors = typedDelegateSelectors(
  localBottomSheetSelectors,
  "bottomSheet"
);
export const userSearchSelectors = delegateSelectors(
  localUserSearchSelectors,
  (state) => state.userSearch
);
export const referralRegistrationsSelectors = delegateSelectors(
  localReferralRegistrationsSelectors,
  (state) => state.referralRegistrations
);
export const autoLoginSelectors = typedDelegateSelectors(
  localAutoLoginSelectors,
  "autoLogin"
);

export const validationSelectors = typedDelegateSelectors(
  localValidationSelectors,
  "validation"
);
export const broadcastSelectors = typedDelegateSelectors(
  localBroadcastSelectors,
  "broadcast"
);
export const tabsConfigsSelectors = typedDelegateSelectors(
  localTabsConfigSelectors,
  "tabsConfig"
);
export const taxSelectors = typedDelegateSelectors(localTaxSelectors, "taxes");
export const currenciesSelectors = typedDelegateSelectors(
  localCurrenciesSelectors,
  "currencies"
);
export const leaderBoardLandingSelectors = typedDelegateSelectors(
  localLeaderBoardLandingSelectors,
  "leaderBoardLanding"
);
export const tournamentsLandingSelectors = typedDelegateSelectors(
  localTournamentsLandingSelectors,
  "tournamentsLanding"
);
export const countriesSelectors = typedDelegateSelectors(
  localCountriesSelectors,
  "countries"
);
export const countryByIpSelectors = typedDelegateSelectors(
  localCountryByIpSelectors,
  "countryByIp"
);
export const guardianSelectors = typedDelegateSelectors(
  localGuardianSelectors,
  "guardian"
);

export const nearbyCountriesSelectors = typedDelegateSelectors(
  localNearbyCountriesSelectors,
  "nearbyCountries"
);

export const phoneNumberSettingsSelectors = typedDelegateSelectors(
  localPhoneNumberSettingsSelectors,
  "phoneNumberSettings"
);

export const welcomePremiumOfferSelectors = typedDelegateSelectors(
  localWelcomePremiumOfferSelectors,
  "welcomePremiumOffer"
);

export const socialGamesLobbySelectors = typedDelegateSelectors(
  localSocialGamesLobbySelectors,
  "socialGamesLobby"
);

export const socialGamesFreeSpinsSelectors = typedDelegateSelectors(
  localSocialGamesFreeSpinsSelectors,
  "socialGamesFreeSpins"
);

export const socialGamesGameSelectors = typedDelegateSelectors(
  localSocialGamesGameSelectors,
  "socialGamesGame"
);

export const socialGamesInStreamLobbySelectors = typedDelegateSelectors(
  localSocialGamesInStreamLobbySelectors,
  "socialGamesInStreamLobby"
);

export const storiesSelectors = typedDelegateSelectors(
  localStoriesSelectors,
  "stories"
);

export const suspensionStatusSelectors = typedDelegateSelectors(
  localSuspensionStatusSelectors,
  "suspensionStatus"
);

export const sessionDetailsSelectors = typedDelegateSelectors(
  localSessionDetailsSelectors,
  "sessionDetails"
);

export const refreshSessionTokenSelectors = typedDelegateSelectors(
  localRefreshSessionTokenSelectors,
  "refreshSessionToken"
);

export const visitorSelectors = typedDelegateSelectors(
  localVisitorSelectors,
  "visitor"
);

export const homePageStreamsSelectors = typedDelegateSelectors(
  localHomePageStreamsSelectors,
  "homePageStreams"
);

export const streamPlayerSelector = (state) => {
  const streamId = viewerSessionSelectors.getStreamId(state);

  // Same as getAlternativeDomainUrlReplacementEnabled, but will avoid circular dependency
  const isUrlReplacementEnabled = Boolean(
    JSON.parse(
      serverOwnedConfigSelectors.getConfigParamByKey(
        state,
        "alternative.domain.stream.url.replacement.enabled",
        "0"
      )
    )
  );

  const cachedStream =
    streamsCacheSelectors.getStreamById(state, streamId) || {};
  const canPlay =
    viewerSessionSelectors.getInitializationResult(state) ===
    StreamSessionInitializationResult.SUCCESS;

  const isPaused = cachedStream.status === StreamStatus.SUSPENDED;

  let playlistUrl =
    cachedStream.masterListUrl ||
    cachedStream.liveListUrl ||
    cachedStream.playlistUrl ||
    liveUrl(streamId);

  if (isUrlReplacementEnabled && isAlternativeDomain() && playlistUrl) {
    playlistUrl = replaceDomainWithHostname(playlistUrl);
  }

  return {
    videoUrl: canPlay ? playlistUrl : "",
    isPaused,
  };
};

export const hasValidAccount = (state) =>
  (loginSelectors.isAuthorized(state) || loginSelectors.isLoggedIn(state)) &&
  !!userSelectors.getMyAccountId(state)?.length;

export const getLocaleCountryCode = (state) => {
  const iso2CountryCode = profilesCacheSelectors.getProfileDetails(
    state,
    userSelectors.getMyAccountId(state)
  )?.iso2CountryCode;
  const locale = deviceInfoSelectors.getDeviceLocale(state);

  return `${locale || "en"}_${(iso2CountryCode || "us").toUpperCase()}`;
};

export const getRegion = (state) => state.countryByIp?.data?.toUpperCase();

export const getMyAgeSelector = (state) => {
  const profileDetails = profilesCacheSelectors.getProfileDetails(
    state,
    userSelectors.getMyAccountId(state)
  );

  if (!profileDetails || !profileDetails.birthday) {
    return undefined;
  }

  return calculateAgeFromDate(profileDetails.birthday);
};

export const showGiftCardBannerByCountryCodeSelector = (state) =>
  getMintrouteBannerEnabledCountries().includes(
    profilesCacheSelectors.getProfileDetails(
      state,
      userSelectors.getMyAccountId(state)
    )?.iso2CountryCode
  ) && getEnabledPaymentProviders().includes(PaymentProvider.MINTROUTE);

export const mosV2Selectors = typedDelegateSelectors(
  localMosV2Selectors,
  "mosV2"
);

export const mosChatV2Selectors = typedDelegateSelectors(
  localMosChatV2Selectors,
  "mosChatV2"
);

export const bonusWheelSelectors = typedDelegateSelectors(
  localBonusWheelSelectors,
  "bonusWheel"
);

export const pipsConfigSelectors = typedDelegateSelectors(
  localPipsConfigSelector,
  "pipsConfig"
);

export const homePageV1Selectors = typedDelegateSelectors(
  localHomePageV1Selectors,
  "homePageV1"
);

export const homePageV2NearbySelectors = typedDelegateSelectors(
  localHomePageV2NearbySelectors,
  "homePageV2Nearby"
);

export const homePageGamesSelectors = typedDelegateSelectors(
  localHomePageGamesSelectors,
  "homePageGames"
);

export const homePageCreatorsNearSelectors = typedDelegateSelectors(
  localHomePageCreatorsSelectors,
  "homePageCreators"
);

export const homePageProfilesSelectors = typedDelegateSelectors(
  localHomePageProfilesSelectors,
  "homePageProfiles"
);

export const tutorialsSelectors = typedDelegateSelectors(
  localTutorialsSelectors,
  "tutorials"
);

export const gifterStatisticsSelectors = typedDelegateSelectors(
  localGifterUsersSelectors,
  "gifterUsersList"
);

export const pointsSelectors = typedDelegateSelectors(
  loadPointsSelectors,
  "pointsUserInfo"
);

export const redeemConfigSelectors = typedDelegateSelectors(
  loadRedeemConfigSelectors,
  "redeemConfig"
);

export const visitorProfileSelectors = typedDelegateSelectors(
  localVisitorProfileSelectors,
  "visitorProfile"
);

export const feedPostSelectors = typedDelegateSelectors(
  localFeedPostSelectors,
  "feed"
);

export const loyaltyProgramSelectors = typedDelegateSelectors(
  localLoyaltyProgramSelectors,
  "loyaltyProgram"
);
