import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { messages as chatMessages } from "chat/components/common/messages";
import { CloseIcon } from "chat/imports/assets";
import { Button, Typography } from "chat/imports/components";
import {
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { VoidCallback } from "chat/imports/types";
import { setEditingMessageInProgress } from "chat/state";
import chatSelectors from "chat/state/selectors";
import styles from "./ConversationBarMessageInfo.scss";

interface ConversationBarMessageInfoProps {
  clearValue: VoidCallback;
}

export const ConversationBarMessageInfo: FC<
  ConversationBarMessageInfoProps
> = ({ clearValue }) => {
  const dispatch = useDispatch();
  const editingMessageInProgress = useSelector(
    chatSelectors.getEditingMessageInProgress,
    shallowEqual
  );
  const onClose = () => {
    dispatch(setEditingMessageInProgress(null));
    clearValue();
  };

  if (!editingMessageInProgress) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <Typography className={styles.status} type={TYPOGRAPHY_TYPE.PARAGRAPH5}>
          <FormattedMessage {...chatMessages.editing} />
        </Typography>
        <Typography
          className={styles.message}
          type={TYPOGRAPHY_TYPE.PARAGRAPH4}
        >
          {editingMessageInProgress?.body}
        </Typography>
      </div>
      <Button
        size={ButtonSize.SMALL_24}
        variant={ButtonVariant.ICON_ONLY}
        className={styles.btn}
        onClick={onClose}
      >
        <CloseIcon className={styles.closeIcon} />
      </Button>
    </div>
  );
};
