export enum SocialGamesResponseCode {
  GAME_PROVIDER_ERROR = 2,
  SERVER_ERROR = 1,
  SUCCESS = 0,
}

export enum SocialGamesSectionType {
  COMMON = 1,
  FEATURED = 2,
  FREESPINS = 7,
  LIVE = 6,
  OTHER = 4,
  RECENTLY_PLAYED = 5,
  SLOTS = 3,
}

export enum SocialGamesV2SectionType {
  COMMON = "COMMON",
  FEATURED = "FEATURED",
  FREESPINS = "FREESPINS",
  LIVE = "LIVE",
  NEW = "NEW",
  OTHER = "OTHER",
  RECENTLY_PLAYED = "RECENTLY_PLAYED",
  SLOTS = "SLOTS",
  TABLE = "TABLE",
}

export enum SocialGamesSkeletonType {
  FEATURED_CARD = "featuredCard",
  GRID_CARD = "gridCard",
  GRID_GAME_CARD = "gridGameCard",
  TITLE_BLOCK = "titleBlock",
}

export enum SocialGamesActionType {
  CLICK = "click",
  DISPLAY = "display",
}

export enum SocialGamesTargetType {
  BACK_SOCIAL_GAMES = "back_social_games",
  GET_COINS_SOCIAL_GAMES = "get_coins_social_games",
  INIT_SEARCH_SOCIAL_GAMES = "init_search_social_games",
  SOCIAL_GAME_IN_LOBBY = "social_game_in_lobby",
  SOCIAL_GAME_SELECT = "social_game_select",
  SOCIAL_GAME_VIEW_ALL = "social_game_view_all",
  SOCIAL_GAMES_ENTER = "social_games_enter",
  SOCIAL_GAMES_SEARCH = "social_game_search",
  SOCIAL_GAMES_STAGE = "social_games_stage",
}

export enum SocialGamesScreenName {
  PROFILE = "profile",
}

export enum SocialGamesGameStage {
  GAME_FRAME = "game_frame",
  LOADING_GAME = "loading_game",
  LOADING_GAME_ERROR = "loading_game_error",
  LOADING_LOBBY = "loading_lobby",
  LOBBY = "lobby",
  SEARCH = "search",
  VIEW_ALL = "view_all",
}

export enum SocialGamesAnalyticEventName {
  ACTION = "action",
  ANCHOR_TYPE = "anchor_type",
  COMMENT = "comment",
  GAME_ID = "game_id",
  GAME_STAGE = "game_stage",
  GAMES_IDS = "games_ids",
  ITEM_TYPE = "item_type",
  SCREEN_NAME = "screen_name",
  TARGET = "target",
  TRACKING_ID = "tracking_id",
}

export enum SocialGamesAnchorType {
  ENTER = "enter",
}

export enum SocialGamesAnalyticsItemTypes {
  FEATURED = "hot_new",
  FOUND = "found",
  OTHER = "other",
  RECENTLY_PLAYED = "recently_played",
  SLOTS = "slots",
  SUGGESTED = "suggested",
}
