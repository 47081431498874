import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import { MiniProfileEntranceSource, ProfileType } from "src/enums";
import { BIAdditionalParams } from "src/types/common";
import { currentTimeMillis } from "src/utils/dateUtils";
import { omitUndefinedProps } from "src/utils/omitUndefinedProps";

type emitOpenProfileEventType = {
  [EventFields.ITEM_TYPE]: ProfileType;
  [EventFields.LOGGED_IN_ACCOUNT_ID]: string;
  [EventFields.MINI_PROFILE_IS_PUBLISHER]: number;
  [EventFields.PEER_ID]: string;
  [EventFields.SOURCE]: MiniProfileEntranceSource;
  [EventFields.STREAM_ID]?: string;
  [EventFields.STREAM_KIND]: number | undefined;
  [EventFields.VIEW_SESSION_ID]?: string;
} & Omit<
  BIAdditionalParams,
  | EventFields.ITEM_TYPE
  | EventFields.LOGGED_IN_ACCOUNT_ID
  | EventFields.MINI_PROFILE_IS_PUBLISHER
  | EventFields.PEER_ID
  | EventFields.SOURCE
  | EventFields.STREAM_ID
  | EventFields.STREAM_KIND
  | EventFields.VIEW_SESSION_ID
>;

export const emitOpenProfileEvent = ({
  account_id,
  view_session_id,
  streamKind,
  is_publisher,
  peer_id,
  source,
  item_type,
  stream_id,
  chat_id,
  happy_moment_id,
  nft_card_id,
  is_from_mini_profile,
}: emitOpenProfileEventType) =>
  emitEvent(
    EventNames.OPEN_USER_INFO,
    omitUndefinedProps({
      [EventFields.LOGGED_IN_ACCOUNT_ID]: account_id,
      [EventFields.TIMESTAMP]: currentTimeMillis(),
      [EventFields.ITEM_TYPE]: item_type,
      [EventFields.FROM_MINI_PROFILE]:
        is_from_mini_profile === undefined ? null : is_from_mini_profile,
      [EventFields.STREAM_ID]: stream_id,
      [EventFields.CHAT_ID]: chat_id,
      [EventFields.HAPPY_MOMENT_ID]: happy_moment_id,
      [EventFields.NFT_CARD_ID]: nft_card_id,
      [EventFields.SESSION_ID]: stream_id,
      [EventFields.VIEW_SESSION_ID]: view_session_id,
      [EventFields.MINI_PROFILE_HAPPY_MOMENT_ID]: happy_moment_id,
      [EventFields.STREAM_KIND]: streamKind,
      [EventFields.MINI_PROFILE_IS_PUBLISHER]: is_publisher,
      [EventFields.PEER_ID]: peer_id,
      [EventFields.SOURCE]: source,
    })
  );
