import { datadogLogs } from "@datadog/browser-logs";
import { InMemoryStorage } from "src/core/storage/inMemory/InMemoryStorage";
import { IndexedDBStorage } from "src/core/storage/indexedDB/IndexedDBStorage";
import { LocalStorage } from "src/core/storage/localStorage/LocalStorage";

export const searchParamsStorage = new IndexedDBStorage(
  "SearchParamsDB",
  "searchParamsStorage",
  {
    fallbackStorage: [new LocalStorage(), new InMemoryStorage()],
    onError: (error) => {
      datadogLogs.logger.warn(error.message, { kind: "indexedDB" }, error);
    },
  }
);
