import { createAsyncThunk } from "@reduxjs/toolkit";
import sortBy from "lodash.sortby";
import { emitEvent } from "@analytics/emit";
import { fetchMarketingPersonalOffers } from "src/features/visitorCashier/api/visitorCashier";
import { isApiError } from "src/features/visitorCashier/imports/api";
import {
  BI_LANDING_FIELD,
  EventNames,
  PERSONAL_OFFERS_SOURCE,
  SCREEN_NAME,
} from "src/features/visitorCashier/imports/constants";
import {
  PersonalOffersConfig,
  RootState,
} from "src/features/visitorCashier/imports/types";
import { emitPersonalOffersEvent } from "src/features/visitorCashier/imports/utils";
import { visitorCashierSelectors } from "src/features/visitorCashier/state/selectors";

const SORT_OFFERS_BY = "priority";
const OFFERS_NOT_FOUND_ERROR = "NOT FOUND";

export const loadVisitorMarketingPersonalOffers = createAsyncThunk<
  PersonalOffersConfig,
  void,
  { rejectValue: string; state: RootState }
>("lwc/visitorMarketingCashier/fetch", async (_, api) => {
  try {
    const response = await fetchMarketingPersonalOffers(true);

    const parseNewConfig = {
      ...response,
      pricePoints: sortBy(response.pricePoints, SORT_OFFERS_BY),
    };

    if (response) {
      const startTimestamp = Date.now();
      const state = api.getState();

      const parseOldConfig = visitorCashierSelectors.getVisitorOffers(state);

      emitPersonalOffersEvent({
        parseOldConfig,
        parseNewConfig,
        startTimestamp,
        source: PERSONAL_OFFERS_SOURCE.CASHIER_VISITOR,
        screenName: SCREEN_NAME.CASHIER_VISITOR,
      });

      return parseNewConfig;
    }

    const errorEventParams = {
      [BI_LANDING_FIELD.COMMENT]: PERSONAL_OFFERS_SOURCE.CASHIER_VISITOR,
      [BI_LANDING_FIELD.REASON]: OFFERS_NOT_FOUND_ERROR,
    };

    emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);

    return api.rejectWithValue(OFFERS_NOT_FOUND_ERROR);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    const errorEventParams = {
      [BI_LANDING_FIELD.COMMENT]: PERSONAL_OFFERS_SOURCE.CASHIER_VISITOR,
      [BI_LANDING_FIELD.REASON]: error,
    };

    emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);

    return api.rejectWithValue(error);
  }
});

export const loadMarketingPersonalOffers = createAsyncThunk<
  PersonalOffersConfig,
  void,
  { rejectValue: string; state: RootState }
>("lwc/marketingCashier/fetch", async (_, api) => {
  try {
    const response = await fetchMarketingPersonalOffers(false);

    const parseNewConfig = {
      ...response,
      pricePoints: sortBy(response.pricePoints, SORT_OFFERS_BY),
    };

    if (response) {
      const startTimestamp = Date.now();
      const state = api.getState();

      const parseOldConfig = visitorCashierSelectors.getVisitorOffers(state);

      emitPersonalOffersEvent({
        parseOldConfig,
        parseNewConfig,
        startTimestamp,
        source: PERSONAL_OFFERS_SOURCE.CASHIER_VISITOR,
        screenName: SCREEN_NAME.CASHIER_VISITOR,
      });

      return parseNewConfig;
    }

    const errorEventParams = {
      [BI_LANDING_FIELD.COMMENT]: PERSONAL_OFFERS_SOURCE.CASHIER_VISITOR,
      [BI_LANDING_FIELD.REASON]: OFFERS_NOT_FOUND_ERROR,
    };

    emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);

    return api.rejectWithValue(OFFERS_NOT_FOUND_ERROR);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    const errorEventParams = {
      [BI_LANDING_FIELD.COMMENT]: PERSONAL_OFFERS_SOURCE.CASHIER_VISITOR,
      [BI_LANDING_FIELD.REASON]: error,
    };

    emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);

    return api.rejectWithValue(error);
  }
});
