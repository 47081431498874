import React, { memo, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useChangeRecaptchaBadgeVisibility from "src/features/signin/hooks/useChangeRecaptchaBadgeVisibility";
import {
  EventFields,
  RegistrationSource,
  SCREEN_NAME,
  ScreenViewReporter,
} from "src/features/signin/imports/analytics";
import { LoginModalTitleType } from "src/features/signin/imports/enums";
import { getCaptchaLockingEnabled } from "src/features/signin/imports/environment";
import {
  RootState,
  captchaSelectors,
  loginSelectors,
} from "src/features/signin/imports/state";
import { Spinner } from "src/features/signin/imports/ui";
import LoginProviderSelector from "src/features/signin/modal/LoginProviderSelector";
import LoginUnavailable from "src/features/signin/modal/LoginUnavailable";
import LoginFailed, {
  GenericFailureReason,
  ReateLimitedFailureReason,
} from "src/features/signin/modal/login/layout/LoginFailed";
import CountrySelectStep from "src/features/signin/modal/login/steps/CountrySelectStep";
import PhoneNumberInput from "src/features/signin/modal/login/steps/PhoneNumberInput";
import VerificationCodeStep from "src/features/signin/modal/login/steps/VerificationCodeStep";
import { prepareProviders } from "src/features/signin/state/flows/login";
import { resetPhoneLogin } from "src/features/signin/state/login/actionCreators";
import { VoidCallback } from "src/types/common";
import styles from "./LoginModalContent.scss";

const selector = (state: RootState) => ({
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isLoginFailed: loginSelectors.isLoginFailed(state),
  loginInProgress: loginSelectors.isLoginInProgress(state),
  providers: loginSelectors.getAvailableProviders(state),
  phoneNumberAuthenticationState:
    loginSelectors.getPhoneNumberAuthenticationState(state),
  isPreparingProviders: loginSelectors.isPreparingProviders(state),
  errorMessage: loginSelectors.getLoginErrorMessage(state),
});

interface LoginModalContentParams {
  onLoginSuccess?: VoidCallback;
  registrationSource: RegistrationSource;
  title: LoginModalTitleType;
}

const LoginModalContent = ({
  title,
  onLoginSuccess,
  registrationSource,
}: LoginModalContentParams) => {
  const dispatch = useDispatch();

  const captchaRequired =
    useSelector(captchaSelectors.isLockedByCaptcha) &&
    getCaptchaLockingEnabled();

  useEffect(() => {
    dispatch(prepareProviders());
  }, [dispatch]);

  const {
    providers,
    isLoggedIn,
    isLoginFailed,
    loginInProgress,
    isPreparingProviders,
    phoneNumberAuthenticationState,
    errorMessage,
  } = useSelector(selector, shallowEqual);

  useChangeRecaptchaBadgeVisibility(phoneNumberAuthenticationState);

  const showSpinner =
    isLoggedIn ||
    loginInProgress ||
    (isPreparingProviders && providers.length === 0);

  let body;
  if (showSpinner) {
    body = (
      <div className={styles.spinnerContainer}>
        <Spinner
          className={styles.spinner}
          data-testid="login-modal-spinner"
          color="inherit"
        />
      </div>
    );
  } else if (isLoginFailed) {
    body = (
      <LoginFailed errorMessage={errorMessage}>
        <GenericFailureReason />
      </LoginFailed>
    );
  } else if (phoneNumberAuthenticationState.attemptLimitReached) {
    body = (
      <LoginFailed>
        <ReateLimitedFailureReason />
      </LoginFailed>
    );
  } else if (phoneNumberAuthenticationState.phoneVerificationUnavailable) {
    body = (
      <>
        <ScreenViewReporter
          name={SCREEN_NAME.REGISTRATION_SIGN_IN}
          additionalParams={{
            [EventFields.SCREEN_STATE]: "unavailable_phone",
          }}
        />
        <LoginUnavailable registrationSource={registrationSource} />
      </>
    );
  } else if (phoneNumberAuthenticationState.selectCountry) {
    body = <CountrySelectStep />;
  } else if (phoneNumberAuthenticationState.waitingForCode) {
    body = <VerificationCodeStep onLoginSuccess={onLoginSuccess} />;
  } else if (phoneNumberAuthenticationState.continueWithPhone) {
    body = <PhoneNumberInput onLoginSuccess={onLoginSuccess} />;
  } else {
    body = (
      <LoginProviderSelector
        title={title}
        onLoginSuccess={onLoginSuccess}
        registrationSource={registrationSource}
      />
    );
  }

  useEffect(
    () => () => {
      if (!captchaRequired) {
        dispatch(resetPhoneLogin());
      }
    },
    [dispatch, captchaRequired]
  );

  return <div className={styles.root}>{body}</div>;
};

export default memo(LoginModalContent);
