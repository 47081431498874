import { batch } from "react-redux";
import { Dispatch } from "redux";
import { autoLoginWithToken } from "src/features/autoLogin/api/autoLoginWithToken";
import {
  DatadogTransport,
  EventCategory,
  EventNames,
  emitAF,
  emitEvent,
} from "src/features/autoLogin/imports/analytics";
import {
  CLIENT_CAPABILITIES,
  CLIENT_CAPABILITIES_ARRAY,
} from "src/features/autoLogin/imports/constants";
import { LoginProvider } from "src/features/autoLogin/imports/enums";
import {
  clearUserSession,
  loginSuccess,
  updateAnalyticsKey,
} from "src/features/autoLogin/imports/features";
import { resetSessionDetails } from "src/features/autoLogin/imports/state";
import {
  cleanupQueryWithKeys,
  getOsVersion,
} from "src/features/autoLogin/imports/utils";
import {
  finishAutoLoginFailure,
  finishAutoLoginSuccess,
  startAutoLogin,
} from "src/features/autoLogin/state/slice";
import { AutoLoginQueryKey } from "src/features/autoLogin/types/autoLoginQuery";
import { DatadogAutoLoginMessage } from "src/features/autoLogin/types/datadogAutoLoginMessage";

interface PerformAutoLoginParams {
  accountId: string;
  fingerprint: string;
  isAppleRecover?: boolean;
  locale: string;
  token: string;
}

export const performAutoLogin =
  ({
    accountId,
    fingerprint,
    locale,
    token,
    isAppleRecover,
  }: PerformAutoLoginParams) =>
  async (dispatch: Dispatch) => {
    try {
      DatadogTransport.info(DatadogAutoLoginMessage.STARTED);

      batch(() => {
        dispatch(clearUserSession());
        dispatch(resetSessionDetails());
        dispatch(startAutoLogin());
      });

      const params = {
        accountId,
        capabilities: CLIENT_CAPABILITIES_ARRAY,
        fingerprint,
        locale,
        osVersion: getOsVersion(),
        token,
        isAppleRecover,
        version: GENERATED_APP_INFO.fullVersion,
      };

      const response = await autoLoginWithToken(params);

      emitEvent(EventNames.TANGO_LOGIN);
      emitAF({
        eventCategory: EventCategory.LOGIN,
        eventName: EventNames.TANGO_LOGIN,
      });

      batch(() => {
        dispatch(updateAnalyticsKey({ analyticsKey: response.analyticsKey }));
        dispatch(
          loginSuccess({
            accountId,
            clientCapabilities: CLIENT_CAPABILITIES,
            connectionManagerData: {
              deviceToken: response.deviceToken,
              hostname: response.hordemasterDetails.hostname,
              port: response.hordemasterDetails.port,
              username: response.username,
            },
            entryPoint: "",
            newRegistration: false,
            provider: LoginProvider.TANGO_DISPOSABLE_TOKEN,
          })
        );

        dispatch(finishAutoLoginSuccess());
      });
    } catch (error) {
      DatadogTransport.error(DatadogAutoLoginMessage.FAILED, { error });
      dispatch(finishAutoLoginFailure());
    } finally {
      cleanupQueryWithKeys(Object.values(AutoLoginQueryKey));
    }
  };
