import { createListenerMiddleware } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { MultiBroadcastVolumeState } from "generated/proto/MultiBroadcastMute";
import { RichStateEntriesType, ToastType } from "src/enums";
import { sharedMessages } from "src/features/signin/imports/ui";
import {
  RichStickerEvent,
  StickerStatus,
} from "src/types/richFragment/Sticker";
import { parseMessageFromBase64 } from "src/utils/protobufUtil";
import * as broadcastActionCreators from "state/actionCreators/broadcast";
// eslint-disable-next-line no-restricted-imports
import { VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT } from "state/actionTypes";
import { RootState } from "state/delegate";
import { broadcastSelectors } from "state/selectors";
import { addToast } from "state/tree/toast";

export const pullEventListenerMiddleware =
  createListenerMiddleware<RootState>();

interface RichStateEntriesEvent {
  serializedState: string;
  stateName: string;
}

interface EntryState {
  encryptedStreamId: string;
  volume: number;
}

pullEventListenerMiddleware.startListening({
  type: VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  effect: async (action: AnyAction, { dispatch, getState }) => {
    if (action.error) {
      return;
    }
    const broadcastId = broadcastSelectors.broadcastId(getState());

    const { stickersEvents, richStateEntries } = action.payload;
    if (stickersEvents) {
      stickersEvents.forEach(({ sticker }: RichStickerEvent) => {
        if (
          sticker.status === StickerStatus.MODERATION_FAILED &&
          broadcastId === action.meta.streamId
        ) {
          dispatch(
            addToast({
              type: ToastType.REGULAR,
              title: sharedMessages.stickerModeration,
            })
          );
        }
      });
    }

    if (richStateEntries) {
      richStateEntries.forEach(
        ({ stateName, serializedState }: RichStateEntriesEvent) => {
          if (stateName === RichStateEntriesType.MULTIBROADCAST_VOLUME) {
            const state = parseMessageFromBase64(
              serializedState,
              MultiBroadcastVolumeState
            );

            dispatch(broadcastActionCreators.resetPipMutedState());
            state.entries.forEach(
              ({ encryptedStreamId, volume }: EntryState) => {
                dispatch(
                  broadcastActionCreators.setPipMutedState({
                    streamId: encryptedStreamId,
                    muted: volume === 0,
                  })
                );
              }
            );
          }
        }
      );
    }
  },
});
