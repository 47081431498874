import { SocialProvider } from "src/features/profile/settings/types/accountInfoResponse.type";
import { RootState } from "state/delegate";

export const profileSettingsSelectors = {
  getPhoneNumber: (state: RootState) =>
    state.profileSettings.data?.accountResponse?.account?.phoneNumbers?.phoneNumber?.[0]?.toString() ||
    "",
  getCountryId: (state: RootState) =>
    state.profileSettings.data?.accountResponse?.account?.countryCodeId,
  getEmail: (state: RootState) =>
    state.profileSettings.data?.accountResponse?.account?.emails?.email?.[0] ||
    "",
  getGoogleId: (state: RootState) =>
    state.profileSettings.data?.accountResponse?.account?.socialNetworkIds?.find(
      (provider) => provider.name === SocialProvider.GOOGLE
    )?.id || "",
  getAppleId: (state: RootState) =>
    state.profileSettings.data?.accountResponse?.account?.socialNetworkIds?.find(
      (provider) => provider.name === SocialProvider.APPLE
    )?.id || "",
  getIsConnectedOnlyAppleId: (state: RootState) => {
    const hasAppleId = Boolean(profileSettingsSelectors.getAppleId(state));
    const hasGoogleId = Boolean(profileSettingsSelectors.getGoogleId(state));
    const hasPhoneNumber = Boolean(
      profileSettingsSelectors.getPhoneNumber(state)
    );

    return hasAppleId && !hasGoogleId && !hasPhoneNumber;
  },
};
