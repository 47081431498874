const DEFAULT_ATTEMPTS_COUNT = 3;
const DEFAULT_ATTEMPT_DELAY_MS = 500;

interface RetryOnFailureOptions {
  attemptDelayMs?: number;
  attempts?: number;
  onWarn?: VoidFunction;
}

export const retryOnFailure = async <T>(
  fn: () => Promise<T>,
  {
    attempts: maxRetries = DEFAULT_ATTEMPTS_COUNT,
    attemptDelayMs = DEFAULT_ATTEMPT_DELAY_MS,
    onWarn,
  }: RetryOnFailureOptions = {}
): Promise<T> => {
  let attempts = 0;

  const executeWithRetry = async (): Promise<T> => {
    try {
      attempts++;

      return await fn();
    } catch (error) {
      if (attempts < maxRetries) {
        onWarn?.();

        await new Promise((resolve) =>
          setTimeout(resolve, attemptDelayMs * attempts)
        );

        return executeWithRetry();
      }

      throw error;
    }
  };

  return executeWithRetry();
};
