import once from "lodash.once";
import { Nullable, VoidCallback } from "src/types/common";

type LoadScriptFn = (options: {
  async?: boolean;
  defer?: boolean;
  delay?: number;
  isRemoveOnError?: boolean;
  nodeId: string;
  onError?: Nullable<(event: Event | string) => void>;
  onLoad?: Nullable<VoidCallback>;
  src: string;
}) => void;

export const loadScript: LoadScriptFn = ({
  src,
  nodeId,
  onLoad = null,
  onError = null,
  async = false,
  defer = false,
  delay = 0,
  isRemoveOnError = false,
}) => {
  if (nodeId && document.getElementById(nodeId)) {
    return;
  }

  const f = () => {
    const newScriptNode = document.createElement("script");
    newScriptNode.id = nodeId;
    newScriptNode.src = src;
    newScriptNode.async = async;
    newScriptNode.defer = defer;
    newScriptNode.onload = onLoad;

    newScriptNode.onerror = (e) => {
      onError?.(e);

      if (isRemoveOnError) {
        newScriptNode.remove();
      }
    };

    const [firstScriptNode] = document.getElementsByTagName("script");
    firstScriptNode?.parentNode?.insertBefore(newScriptNode, firstScriptNode);
  };

  if (delay) {
    setTimeout(f, delay);
  } else {
    f();
  }
};

export const makeOneTimeScriptLoader = ({
  src,
  nodeId,
}: {
  nodeId: string;
  src: string;
}) =>
  once(
    () =>
      new Promise<void>((resolve, reject) => {
        loadScript({
          src,
          nodeId,
          onLoad: () => resolve(),
          onError: (e) => {
            /* eslint-disable prefer-promise-reject-errors, no-console */
            console.error(`Failed to load script ${src}`, e);
            reject();
          },
        });
      })
  );
