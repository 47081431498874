import { datadogLogs } from "@datadog/browser-logs";
import {
  UpgradePublicStreamToPremiumParams,
  upgradePublicStreamToPremium,
} from "broadcast/api/premium";
import { upgradePublicStreamRequest } from "broadcast/api/upgrade";
import { TargetStreamType } from "broadcast/enums/premium";
import {
  addSticker,
  deleteSticker,
  editSticker,
  restoreSticker as requestRestoreSticker,
} from "src/features/broadcast/api/stickers";
import {
  DataDogErrorMessage,
  RootState,
  StreamKind,
} from "src/features/broadcast/imports/enums";
import {
  AppDispatch,
  broadcastSelectors,
  viewerSessionSelectors,
} from "src/features/broadcast/imports/state";
import { VoidCallback } from "src/features/broadcast/imports/types";
import {
  RestoreStickerData,
  StickerDataType,
} from "src/features/broadcast/types/types";
import { publishBroadcastAfterInit } from "state/flows/broadcast";
import { getRegion } from "state/selectors";
import { broadcastActionCreators } from "state/tree/broadcast";
import logger from "utils/logger";

interface CreateStickerProps {
  callback: VoidCallback;
  stickerData: StickerDataType;
}

export const createSticker =
  ({ callback, stickerData }: CreateStickerProps) =>
  async (_: AppDispatch, getState: () => RootState) => {
    const streamId = viewerSessionSelectors.getStreamId(getState());

    try {
      addSticker({
        streamId,
        stickerData,
      });

      callback();
    } catch (error) {
      datadogLogs.logger.error(
        DataDogErrorMessage.CREATE_STICKER_FAILED,
        {},
        error as Error
      );
      callback();
    }
  };

interface UpdateStickerProps {
  callback: VoidCallback;
  stickerData: StickerDataType;
}

export const updateSticker =
  ({ stickerData, callback }: UpdateStickerProps) =>
  async (_: AppDispatch, getState: () => RootState) => {
    const streamId = viewerSessionSelectors.getStreamId(getState());

    try {
      editSticker({
        streamId,
        stickerData,
      });

      callback();
    } catch (error) {
      datadogLogs.logger.error(
        DataDogErrorMessage.DELETE_STICKER_FAILED,
        {},
        error as Error
      );
    }
  };

interface RemoveStickerProps {
  stickerId: string;
}

export const removeSticker =
  ({ stickerId }: RemoveStickerProps) =>
  async (_: AppDispatch, getState: () => RootState) => {
    const streamId = viewerSessionSelectors.getStreamId(getState());

    try {
      deleteSticker({
        streamId,
        stickerId,
      });
    } catch (error) {
      datadogLogs.logger.error(
        DataDogErrorMessage.DELETE_STICKER_FAILED,
        {},
        error as Error
      );
    }
  };

interface RestoreStickerProps {
  callback: VoidCallback;
  restoreStickerData: RestoreStickerData;
}

export const restoreSticker =
  ({ callback, restoreStickerData }: RestoreStickerProps) =>
  async (_: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const streamId = viewerSessionSelectors.getStreamId(state);

    try {
      requestRestoreSticker({ restoreStickerData, streamId });
    } catch (error) {
      datadogLogs.logger.error(
        DataDogErrorMessage.RESTORE_STICKER_FAILED,
        {},
        error as Error
      );
    } finally {
      callback();
    }
  };

const UPGRADE_STREAM_TO_PREMIUM_BROADCAST_SOURCE = "feed";

export const upgradeStreamToPremium =
  (params: UpgradePublicStreamToPremiumParams) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const region = getRegion(getState());

    try {
      dispatch(
        broadcastActionCreators.broadcastInitStarted({
          broadcastKind: StreamKind.PRIVATE,
          broadcastSource: UPGRADE_STREAM_TO_PREMIUM_BROADCAST_SOURCE,
        })
      );

      const result = await upgradePublicStreamToPremium({
        ...params,
        region,
      });

      return dispatch(
        publishBroadcastAfterInit(result, () => {
          logger.error("Failed to upgrade stream to premium", result);
        })
      );
    } catch (error) {
      return dispatch(
        broadcastActionCreators.broadcastInitFailed(error as Error)
      );
    }
  };

interface UpgradePublicStreamParams {
  recipientAccountIds?: string[];
  targetStreamType: TargetStreamType;
  ticketPriceInCredits?: number;
}

export const upgradePublicStream =
  (params: UpgradePublicStreamParams) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const region = getRegion(state);
    const encryptedStreamId = broadcastSelectors.broadcastId(state);

    if (!encryptedStreamId) {
      return;
    }

    try {
      const kind =
        params.targetStreamType === TargetStreamType.PREMIUM_TARGET
          ? StreamKind.PRIVATE
          : StreamKind.CHAT;

      dispatch(
        broadcastActionCreators.broadcastInitStarted({
          broadcastKind: kind,
          broadcastSource: UPGRADE_STREAM_TO_PREMIUM_BROADCAST_SOURCE,
        })
      );

      const result = await upgradePublicStreamRequest({
        region,
        encryptedStreamId,
        recipientAccountIds: params.recipientAccountIds ?? [],
        ticketPriceInCredits: params.ticketPriceInCredits,
        targetStreamType: params.targetStreamType,
      });

      return dispatch(
        publishBroadcastAfterInit(result, () => {
          logger.error("Failed to upgrade stream", result);
        })
      );
    } catch (error) {
      return dispatch(
        broadcastActionCreators.broadcastInitFailed(error as Error)
      );
    }
  };
