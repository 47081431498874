import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import {
  TangoRegistrationResponse,
  TangoUpdateAccountRequest,
} from "generated/proto/RegistrationV8";
import {
  UnlinkSocialIdentifierRequest,
  UnlinkSocialIdentifierResponse,
} from "generated/proto/RegistrationV9";
import { CLIENT_CAPABILITIES_ARRAY } from "src/constants";
import {
  SMS_VERIFICATION_PROVIDER,
  TWC_PLATFORM,
  WEB_PLATFORM_INDICATOR,
} from "src/features/profile/settings/common/constants";
import {
  AccountInfoResponse,
  SendValidationSMSRequestParams,
  SendValidationSMSRequestType,
  SendValidationSMSResponse,
  TangoRegistrationResponseType,
  TangoUpdateAccountRequestType,
  UpdateAccountInfoRequest,
} from "src/features/profile/settings/types";
import {
  UnlinkSocialIdentifierRequestType,
  UnlinkSocialIdentifierResponseType,
} from "src/features/profile/settings/types/unlinkSocialIdentifier.type";

export const fetchAccountInfo = (): Promise<AccountInfoResponse> =>
  fetch(`${getProxycadorHost()}/facilitator/rest/v2/accountinfo.json`, {
    headers: {
      "content-type": "application/json",
    },
  }).then((resp) => resp.json());

export const updateAccountInfo = async ({
  iso2CountryCode,
  phoneNumber,
  email,
  firstName,
  lastName,
  clientVersion,
  osVersion,
  device,
  fingerprint,
  username,
  verificationCode,
}: UpdateAccountInfoRequest): Promise<TangoRegistrationResponseType> => {
  const payload: TangoUpdateAccountRequestType = {
    registrationRequest: {
      applicationId: "tango",
      username,
      Contact: {
        SocialNetwork: [],
        PhoneNumber: {
          iso2CountryCode,
          subscriberNumber: phoneNumber,
        },
        email,
        Capabilities: {
          capability: CLIENT_CAPABILITIES_ARRAY,
        },
        isPhone: true,
        abookSize: 0,
        device,
        firstName,
        lastName,
        linkAccounts: false,
        iso2CountryCode,
        ...(verificationCode && {
          verificationProvider: SMS_VERIFICATION_PROVIDER,
          verificationParams: {
            tango: {
              verificationCode,
            },
          },
        }),
      },
      Options: {},
      TangoDevice: {
        clientOsVersion: osVersion,
        clientVersion,
        deviceId: fingerprint,
        platform: TWC_PLATFORM,
      },
      DeviceTokenList: {
        DeviceToken: [],
      },
    },
  };

  const body = TangoUpdateAccountRequest.encode(payload).finish();

  const resp = await fetch(
    `${getProxycadorHost()}/facilitator/rest/updateaccountinfo/v8/accountinfo.proto`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
      body,
    }
  );

  const buffer = await resp.arrayBuffer();

  return TangoRegistrationResponse.decode(new Uint8Array(buffer), undefined);
};

export const unlinkSocialIdentifier = async ({
  accountId,
  socialMediaType,
}: {
  accountId: string;
} & UnlinkSocialIdentifierRequestType): Promise<UnlinkSocialIdentifierResponseType> => {
  const encodedPayload = UnlinkSocialIdentifierRequest.encode({
    socialMediaType,
  }).finish();

  const resp = await fetch(
    `${getProxycadorHost()}/facilitator/rest/social_identifier/v9/unlink`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
        "X-TangoAuth-AccountId": accountId,
      },
      body: encodedPayload,
    }
  );

  const buffer = await resp.arrayBuffer();

  return UnlinkSocialIdentifierResponse.decode(
    new Uint8Array(buffer),
    undefined
  );
};

export const sendValidationSMS = ({
  username,
  isResend = false,
}: SendValidationSMSRequestParams): Promise<SendValidationSMSResponse> => {
  const payload: SendValidationSMSRequestType = {
    validatedDevice: {
      platform: WEB_PLATFORM_INDICATOR,
    },
    deliveryMethod: "SMS_STRICT_ONLY",
  };

  const body = JSON.stringify(payload);

  return fetch(
    `${getProxycadorHost()}/facilitator/rest/validationCodeDelivery/v3/sendValidationCode/${username}.json?isResend=${isResend}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body,
    }
  ).then((resp) => resp.json());
};
