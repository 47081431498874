import { useCallback, useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ThemeType } from "src/enums";
import { useIsVisitorProfile } from "src/features/profile/visitorProfile/exports/hooks";
import { ThemeContext } from "src/ui/Theme";
import { RootState } from "state/delegate";
import { vipConfigsSelectors, visitorProfileSelectors } from "state/selectors";
import { VipImageArgs } from "state/selectors/vipConfigs";
import { useBreakpointPrecise } from "./useBreakpoint";

const useVipImage = ({
  vipModelId,
  vipLevel,
  vipLabel,
  imageName,
}: Omit<VipImageArgs, "breakpoint" | "theme">) => {
  const { dark } = useContext(ThemeContext);
  const breakpoint = useBreakpointPrecise();
  const isVisitorProfile = useIsVisitorProfile();

  const selector = useCallback(
    (state: RootState) =>
      isVisitorProfile
        ? visitorProfileSelectors.getVipImage(state, {
            imageName,
            breakpoint,
            theme: dark ? ThemeType.DARK : ThemeType.LIGHT,
          })
        : vipConfigsSelectors.getVipImage(state, {
            vipModelId,
            imageName,
            vipLevel,
            vipLabel,
            breakpoint,
            theme: dark ? ThemeType.DARK : ThemeType.LIGHT,
          }),
    [
      isVisitorProfile,
      vipModelId,
      imageName,
      vipLevel,
      vipLabel,
      dark,
      breakpoint,
    ]
  );

  return useSelector(selector, shallowEqual);
};

export default useVipImage;
