import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Nullable,
  VipConfigWithAssetConfig,
} from "src/features/profile/visitorProfile/imports/types";
import {
  AddVisitorAccountIdPayload,
  AddVisitorProfileByIdPayload,
  AddVisitorProfileFeedPayload,
  AddVisitorProfilePayload,
  AddVisitorProfileStreamId,
  AddVisitorProfileVipConfigPayload,
  VisitorProfile,
  VisitorProfileFeed,
} from "src/features/profile/visitorProfile/types";

export interface VisitorProfileState {
  isVisitorAccountIdLoadedWithError: boolean;
  visitorAccountId: Nullable<string>;
  visitorProfile: VisitorProfile;
  visitorProfileFeed: VisitorProfileFeed;
  visitorProfileStreamId: Nullable<string>;
  visitorProfileVipConfig: Nullable<VipConfigWithAssetConfig>;
  visitorProfiles: Record<string, VisitorProfile>;
}

const initialState: VisitorProfileState = {
  visitorProfile: null,
  visitorAccountId: null,
  visitorProfileVipConfig: null,
  visitorProfileStreamId: null,
  isVisitorAccountIdLoadedWithError: false,
  visitorProfiles: {},
  visitorProfileFeed: {
    posts: [],
    moments: [],
    isLoading: false,
  },
};

const slice = createSlice({
  name: "visitorProfile",
  initialState,
  reducers: {
    addVisitorProfile(state, action: PayloadAction<AddVisitorProfilePayload>) {
      state.visitorProfile = action.payload;
    },
    addVisitorProfileById(
      state,
      action: PayloadAction<AddVisitorProfileByIdPayload>
    ) {
      state.visitorProfiles[action.payload.accountId] = action.payload.profile;
    },
    addVisitorProfileVipConfig(
      state,
      action: PayloadAction<AddVisitorProfileVipConfigPayload>
    ) {
      state.visitorProfileVipConfig = action.payload;
    },
    addVisitorProfileStreamId(
      state,
      action: PayloadAction<AddVisitorProfileStreamId>
    ) {
      state.visitorProfileStreamId = action.payload.streamId;
    },
    addVisitorProfileFeedStart(state) {
      state.visitorProfileFeed.isLoading = true;
    },
    addVisitorProfileFeedComplete(
      state,
      action: PayloadAction<AddVisitorProfileFeedPayload>
    ) {
      state.visitorProfileFeed = {
        isLoading: false,
        ...action.payload,
      };
    },
    addVisitorAccountByAlias(
      state,
      action: PayloadAction<AddVisitorAccountIdPayload>
    ) {
      const { accountId } = action.payload;
      state.visitorAccountId = accountId;
    },
    addVisitorAccountIdLoadedWithErrors(state) {
      state.isVisitorAccountIdLoadedWithError = true;
    },
    clearVisitorProfile(state) {
      state.visitorProfile = null;
      state.visitorAccountId = null;
      state.visitorProfileVipConfig = null;
      state.visitorProfileStreamId = null;
      state.isVisitorAccountIdLoadedWithError = false;
      state.visitorProfileFeed = {
        isLoading: false,
        posts: [],
        moments: [],
      };
      state.visitorProfiles = {};
    },
  },
});

export const {
  addVisitorProfile,
  addVisitorProfileById,
  addVisitorProfileStreamId,
  addVisitorProfileVipConfig,
  addVisitorAccountByAlias,
  addVisitorAccountIdLoadedWithErrors,
  addVisitorProfileFeedStart,
  addVisitorProfileFeedComplete,
  clearVisitorProfile,
} = slice.actions;

export const visitorProfilerReducer = slice.reducer;
