import React, { FC, memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { differenceInDays, differenceInYears } from "date-fns";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { AllTypographyTypes } from "ui/common/typography/types";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import { ReactComponent as StarIcon } from "img/ic_fan_star_filled.svg";
import styles from "./PostDate.scss";

interface PostDateProps {
  className?: string;
  isForFans: boolean;
  timestamp: number;
  type?: AllTypographyTypes;
}

const PostDate: FC<PostDateProps> = ({
  timestamp,
  isForFans,
  className,
  type = TYPOGRAPHY_TYPE.PARAGRAPH5,
}) => {
  const breakpoint = useBreakpoint();
  const intl = useIntl();
  const diffInDays = differenceInDays(new Date(), timestamp);
  const time = intl.formatTime(timestamp);

  return (
    <Typography
      type={type}
      className={classnames(styles.root, styles[breakpoint], className)}
    >
      {isForFans && (
        <>
          <span className={styles.forFans}>
            <StarIcon className={styles.icon} />
            <FormattedMessage id="fans_only_gift" defaultMessage="Fans Only" />
          </span>
          <span className={styles.bullet} />
        </>
      )}
      <span className={styles.date}>
        {`${
          diffInDays > 1
            ? intl.formatDate(timestamp, {
                year: differenceInYears(new Date(), timestamp)
                  ? "numeric"
                  : undefined,
                month: "long",
                day: "numeric",
              })
            : intl.formatRelativeTime(diffInDays === 1 ? -1 : 0, "day", {
                numeric: "auto",
              })
        }, ${time}`}
      </span>
    </Typography>
  );
};

export default memo(PostDate);
