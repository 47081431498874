import React, { FC, useMemo } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import classnames from "classnames";
import { LoaderIcon, PauseIcon, PlayIcon } from "chat/imports/assets";
import { VoidCallback } from "chat/imports/types";
import styles from "./PlayPauseButton.scss";
import transition from "ui/transitions/FadeTransition.scss";

enum IconState {
  Loading = "loading",
  Pause = "pause",
  Play = "play",
}

const getIconState = (isLoading: boolean, isPlaying: boolean): IconState => {
  if (isLoading) {
    return IconState.Loading;
  }
  if (isPlaying) {
    return IconState.Pause;
  }

  return IconState.Play;
};

interface PlayPauseProps {
  isLoading?: boolean;
  isMyMessage: boolean;
  isPlaying: boolean;
  onClick: VoidCallback;
}

const PlayPauseButton: FC<PlayPauseProps> = ({
  onClick,
  isPlaying,
  isMyMessage,
  isLoading = true,
}) => {
  const currentKey: IconState = getIconState(isLoading, isPlaying);

  const handleClick = () => {
    if (!isLoading) {
      onClick();
    }
  };

  const Icon = useMemo(() => {
    if (isLoading) {
      return <LoaderIcon className={classnames(styles.icon, styles.loading)} />;
    }
    if (isPlaying) {
      return <PauseIcon className={styles.icon} />;
    }

    return <PlayIcon className={styles.icon} />;
  }, [isLoading, isPlaying]);

  return (
    <div
      className={classnames(styles.control, {
        [styles.isMyMessage]: isMyMessage,
      })}
      onClick={handleClick}
    >
      <SwitchTransition>
        <CSSTransition
          key={currentKey}
          classNames={transition}
          timeout={200}
          mountOnEnter
          unmountOnExit
        >
          {Icon}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default PlayPauseButton;
