import { useEffect } from "react";

const useChangeRecaptchaBadgeVisibility = (
  phoneNumberAuthenticationState: object
) => {
  const isPhoneNumberSignIn = Object.keys(
    phoneNumberAuthenticationState
  ).length;

  useEffect(() => {
    if (!isPhoneNumberSignIn) {
      return;
    }

    const badge = document.querySelector(
      ".grecaptcha-badge"
    ) as HTMLElement | null;

    if (badge) {
      badge.style.visibility = "visible";
      badge.style.opacity = "1";
    }

    return () => {
      if (badge) {
        badge.style.visibility = "hidden";
        badge.style.opacity = "0";
      }
    };
  }, [isPhoneNumberSignIn]);
};

export default useChangeRecaptchaBadgeVisibility;
