export const VIDEO_CONVERSION_COMMAND = {
  LOAD: "load",
  CONVERT: "convert",
};

export const VIDEO_CONVERSION_STATUS = {
  CONVERSION_COMPLETE: "conversionComplete",
  CONVERSION_FAILED: "conversionFailed",
  CONVERT: "convert",
  FFMPEG_LOADED: "FFmpegLoaded",
};
