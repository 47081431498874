export enum StickerType {
  GIFT = 2,
  GOAL = 5,
  IMAGE = 3,
  LUCKY_WHEEL = 7,
  STREAM_GAME = 9,
  TANGO_CARD = 8,
  UNKNOWN_TYPE = 1,
  VOTE = 4,
  WHEEL = 6,
}

export enum StickerStatus {
  ACTIVE = 2,
  MODERATION_FAILED = 4,
  PENDING_MODERATION = 3,
  UNKNOWN_STATUS = 1,
}

export enum CreateStickerFlowType {
  GIFT_STICKER_ADD = "GIFT_STICKER_ADD",
  GIFT_STICKER_EDIT = "GIFT_STICKER_EDIT",
  GOAL_STICKER_ADD = "GOAL_STICKER_ADD",
  IMAGE_STICKER_ADD = "IMAGE_STICKER_ADD",
  IMAGE_STICKER_EDIT = "IMAGE_STICKER_EDIT",
}
