import { getProxycadorHost } from "chat/imports/environment";
import { enhancedFetch as fetch } from "chat/imports/utils";
import { SetPinedChatResponse } from "chat/types";

export const setMuted = ({
  conversationId,
  mute = false,
}: {
  conversationId: string;
  mute?: boolean;
}): Promise<SetPinedChatResponse> =>
  fetch(
    `${getProxycadorHost()}/tc2/v2/conversations/${conversationId}/set-muted?mute=${mute}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then((resp) => resp.json());
