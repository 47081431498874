import { useLayoutEffect, useState } from "react";
import { POSITION } from "chat/constants";
import { PopoverPosition } from "src/enums";

export const usePopoverPosition = ({
  anchorEl,
  side,
  isPopoverOpen,
  bottomOffset,
}: {
  anchorEl: HTMLElement | null;
  bottomOffset: number;
  isPopoverOpen: boolean;
  side: string;
}) => {
  const [direction, setDirection] = useState(POSITION.BOTTOM);
  const [directionAddition, setAdditionDirection] = useState(POSITION.TOP);

  useLayoutEffect(() => {
    if (!anchorEl) {
      return;
    }

    const { y, height } = anchorEl.getBoundingClientRect();
    const { innerHeight } = window;

    if (innerHeight - y - height < bottomOffset) {
      setDirection(POSITION.TOP);
      setAdditionDirection(POSITION.BOTTOM);
    }
  }, [anchorEl, bottomOffset, isPopoverOpen]);

  return {
    position:
      PopoverPosition[`${direction}_${side}` as keyof typeof PopoverPosition],
    additionalPosition:
      PopoverPosition[
        `${directionAddition}_${side}` as keyof typeof PopoverPosition
      ],
  };
};
