export enum BROWSER {
  CHROME = "chrome",
  FIREFOX = "firefox",
  SAFARI = "safari",
}

export enum TRACK_KIND {
  AUDIO = "audio",
  VIDEO = "video",
}

export enum WEBSOCKET_COMMAND {
  ERROR = "error",
  NOTIFICATION = "notification",
  PUBLISH = "publish",
  START = "start",
  STOP = "stop",
  TAKE_CANDIDATE = "takeCandidate",
  TAKE_CONFIGURATION = "takeConfiguration",
}

export enum WEBSOCKET_STATE {
  CLOSED = 3,
  CLOSING = 2,
  CONNECTING = 0,
  OPEN = 1,
}

export enum StreamStatusIntCode {
  EXPIRED = 5,
  INITED = 1,
  LIVING = 2,
  SUSPENDED = 4,
  TERMINATED = 3,
  UNKNOWN = 6,
}

export enum StreamEvent {
  AUDIO_MUTE = "audio_mute",
  AUDIO_TRACK_UPDATE = "audio_track_update",
  AUDIO_UNMUTE = "audio_unmute",
  INIT = "init",
  VIDEO_DISABLE = "video_disable",
  VIDEO_ENABLE = "video_enable",
  VIDEO_TRACK_UPDATE = "video_track_update",
}
