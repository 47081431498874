import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalType } from "src/enums";
import {
  Breakpoints,
  useBreakpointPrecise,
} from "src/features/returnAppleLoginUsers/imports/hooks";
import {
  dismissBatchModals,
  fetchProfileSettingsAction,
  loginSelectors,
  openSecureYourAccountBottomScreen,
  openSecureYourAccountModal,
  profileSettingsSelectors,
} from "src/features/returnAppleLoginUsers/imports/state";
import { getIsReturnAppleLoginUsersEnabled } from "src/features/returnAppleLoginUsers/returnAppleLoginUsersSoc";

export const useCheckConnectedOnlyAppleId = () => {
  const dispatch = useDispatch();
  const isReturnAppleLoginUsersEnabled = useSelector(
    getIsReturnAppleLoginUsersEnabled
  );
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const isConnectedOnlyAppleId = useSelector(
    profileSettingsSelectors.getIsConnectedOnlyAppleId
  );

  const isMobile = useBreakpointPrecise() !== Breakpoints.DESKTOP;

  useEffect(() => {
    if (!isLoggedIn || !isReturnAppleLoginUsersEnabled) {
      return;
    }

    dispatch(fetchProfileSettingsAction());
  }, [dispatch, isReturnAppleLoginUsersEnabled, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn || !isReturnAppleLoginUsersEnabled) {
      return;
    }

    if (isConnectedOnlyAppleId) {
      if (isMobile) {
        dispatch(openSecureYourAccountBottomScreen());
      } else {
        dispatch(openSecureYourAccountModal());
      }
    } else {
      if (!isMobile) {
        dispatch(dismissBatchModals([ModalType.SECURE_YOUR_ACCOUNT]));
      }
    }
  }, [
    dispatch,
    isReturnAppleLoginUsersEnabled,
    isLoggedIn,
    isConnectedOnlyAppleId,
    isMobile,
  ]);
};
