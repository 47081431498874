import { StorageKeys } from "src/core/analytics/dataMesh/common/enums";
import { SessionStartParams } from "src/core/analytics/dataMesh/common/types";
import { dataMeshParamsStorage } from "src/core/analytics/dataMesh/utils/dbMethods/dataMeshParamsStorage";
import { parseJson } from "src/core/analytics/imports/utils";

export const getSessionStartParams = async (): Promise<SessionStartParams> =>
  parseJson(
    (await dataMeshParamsStorage.getItem(StorageKeys.SESSION_START_PARAMS)) ||
      "{}"
  );
