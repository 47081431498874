import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getGetTheAppAppStoreUrl,
  getGetTheAppGooglePlayUrl,
} from "src/features/marketing/imports/environment";
import {
  getGooglePlayApkLink,
  getIsOneLinkEnabled,
} from "src/features/marketing/imports/state";
import {
  handleGetTheAppAppStoreUrl,
  handleGetTheAppGooglePlayUrl,
} from "src/features/marketing/imports/utils";

export const useGetDirectStoreUrl = () => {
  const isOneLinkEnabled = useSelector(getIsOneLinkEnabled);
  const googlePlayApkLink = useSelector(getGooglePlayApkLink);

  const [googlePlayLink, setGooglePlayLink] = useState(
    getGetTheAppGooglePlayUrl()
  );
  const [appStoreLink, setAppStoreLink] = useState(getGetTheAppAppStoreUrl());

  useEffect(() => {
    let isMounted = true;
    const fetchAppsFlyerDirectUrls = async () => {
      if (isOneLinkEnabled) {
        const googlePlayUrl = await handleGetTheAppGooglePlayUrl();
        const appStoreUrl = await handleGetTheAppAppStoreUrl();

        if (isMounted) {
          setGooglePlayLink(googlePlayUrl);
          setAppStoreLink(appStoreUrl);
        }
      } else {
        if (isMounted) {
          setGooglePlayLink(googlePlayApkLink);
        }
      }
    };

    fetchAppsFlyerDirectUrls();

    return () => {
      isMounted = false;
    };
  }, [isOneLinkEnabled, googlePlayApkLink]);

  return {
    googlePlayLink,
    appStoreLink,
  };
};
