import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  RootState,
  getIsCustomGiftEnabled,
  giftsCacheActionCreators,
  giftsCacheSelectors,
  loadGiftsBatch,
  mosChatV2Selectors,
} from "src/features/chat/imports/state";
import {
  Gift,
  GiftId,
  MosItem,
  MosItemType,
  MosUiGiftItem,
} from "src/features/chat/imports/types";
import { getMosV2ActionForChat } from "src/features/chat/state/actionCreators";
import { MosChatData } from "src/features/chat/types";
import { SupportedMosItemType } from "src/features/mos/types";

const getV2MosDataForChat = (state: RootState) => {
  const mosV2Items = mosChatV2Selectors.getMosChatV2Items(state);

  const items: MosUiGiftItem[] = [];
  const giftRichModels: Gift[] = [];
  const missedGiftsIds: GiftId[] = [];

  mosV2Items.forEach((mosV2Item: MosItem) => {
    const gift = mosV2Item?.giftRichModel?.gift;

    if (gift) {
      const giftId = gift.encryptedGiftId || gift.id;

      items.push({
        content: { gift: { ...gift, id: giftId } },
        type: mosV2Item.type,
        typeAbbreviation: mosV2Item.typeAbbreviation,
      });

      const isGiftInCache = Boolean(
        giftsCacheSelectors.getGiftById(state, giftId)
      );

      if (!isGiftInCache) {
        giftRichModels.push({ ...gift, id: giftId });
      }

      return;
    }

    const giftId = mosV2Item?.giftReference?.encryptedGiftId;

    if (giftId) {
      const gift = giftsCacheSelectors.getGiftById(state, giftId);

      if (gift) {
        items.push({
          content: { gift },
          type: mosV2Item.type,
          typeAbbreviation: mosV2Item.typeAbbreviation,
        });
      } else {
        missedGiftsIds.push(giftId);
      }
    }
  });

  return { items, missedGiftsIds, giftRichModels };
};

const mosItemsSelector = (state: RootState): MosChatData => {
  const { items, missedGiftsIds, giftRichModels } = getV2MosDataForChat(state);
  const isCustomGiftsEnabled = getIsCustomGiftEnabled(state);

  return {
    items: items.filter(
      ({ type }) =>
        type in SupportedMosItemType ||
        (isCustomGiftsEnabled && type === MosItemType.USER_CUSTOM_GIFT)
    ),
    missedGiftsIds,
    giftRichModels,
  };
};

const useSetRichModelGiftInCache = (giftRichModels: Gift[]): void => {
  const dispatch = useDispatch();

  if (giftRichModels.length) {
    dispatch(giftsCacheActionCreators.addGifts(giftRichModels));
  }
};

export const useMosItemsForChat = ({
  companionId,
}: {
  companionId: string;
}) => {
  const { items, missedGiftsIds, giftRichModels } = useSelector(
    mosItemsSelector,
    shallowEqual
  );

  const dispatch = useDispatch();
  const isLoading = useSelector(mosChatV2Selectors.getMosChatV2Loading);

  useSetRichModelGiftInCache(giftRichModels);

  useEffect(() => {
    dispatch(
      getMosV2ActionForChat({
        companionId,
      })
    );
  }, [dispatch, companionId]);

  useEffect(() => {
    if (missedGiftsIds.length && !isLoading) {
      dispatch(loadGiftsBatch({ giftIds: missedGiftsIds, ignoreCache: true }));
    }
  }, [dispatch, missedGiftsIds, isLoading]);

  return { items, isLoading };
};
