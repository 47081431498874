import { fetchVipConfigs } from "api/vipConfigs";
import { ThemeType, VipConfigState, VipLabel, VipName } from "src/enums";
import { Nullable } from "src/types/common";

export interface VipConfig {
  assignVipConfigId: Nullable<number>;
  assignVipCount: Nullable<number>;
  assignVipPeriod: Nullable<number>;
  bonusPercent: Nullable<number>;
  bundleZipUrl: Nullable<string>;
  cashbackFreeCredits: Nullable<number>;
  created: number;
  days: number;
  id: number;
  jsonVipLoungeRepresentation: Nullable<string>;
  label: VipLabel;
  monthlyPriceToEnter: Nullable<number>;
  monthlyPriceToRetain: Nullable<number>;
  name: VipName;
  priceInCoins: boolean;
  state: VipConfigState;
  version: number;
  vipLevel: number;
  weeklyPriceToEnter: Nullable<number>;
  weeklyPriceToRetain: Nullable<number>;
  weight: number;
}

export type ImageVariant = {
  path: string;
  theme: ThemeType;
};

export type ResolutionsType = {
  DEFAULT?: ImageVariant[];
  MOBILE?: ImageVariant[];
  TABLET?: ImageVariant[];
  WINDOW?: ImageVariant[];
};

export interface VipStylesConfig {
  liveMessageAccountNameTextStyle: {
    guestTextColor: string;
    liveMessageTechnical: string;
    messageSenderName: {
      color: string;
      textShadows: string[];
    };
    regularSubscriberTextColor: string;
  };
}

export interface VipAssetConfigImage {
  resolutions: ResolutionsType;
  rootPath: string;
}

export interface VipAssetConfig {
  avatar: VipAssetConfigImage;
  profileBackground: VipAssetConfigImage;
  profileVideoBackground: VipAssetConfigImage;
}

export interface AssetConfig {
  assets: VipAssetConfig;
  name: string;
  styles: VipStylesConfig;
}

export interface VipConfigWithAssetConfig extends VipConfig {
  assetConfig: AssetConfig;
}

export interface VipModelAsset {
  filename: string;
  url: string;
}

export const getVipConfigs = async (): Promise<VipConfig[]> => {
  const configs = await fetchVipConfigs();

  return configs as VipConfig[];
};
