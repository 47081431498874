import React, { memo, useCallback, useContext } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { Breakpoints, DeviceType } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import { getNsfwPipAlternativeDomainContentSupportEnabled } from "state/abTests";
import { openNsfwContentBottomScreen } from "state/actionCreators/bottomScreen";
import { openNsfwContentModal } from "state/actionCreators/modal";
import { RootState } from "state/delegate";
import { deviceInfoSelectors, streamsCacheSelectors } from "state/selectors";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import MuteContext from "ui/scenes/stream/MuteContext";
import styles from "./NsfwPip.scss";

interface NsfwPipProps {
  pipsCount: number;
  streamId: string;
  style?: Record<string, unknown>;
}

const messages = defineMessages({
  shaderDescription: {
    id: "nsfw.mature.content",
    defaultMessage: "Mature Content",
  },
  shaderTitle: {
    id: "nsfw",
    defaultMessage: "NSFW",
  },
});

const getShaderDescriptionType = (breakpoint: Breakpoints) => {
  switch (breakpoint) {
    case Breakpoints.SMALL_MOBILE:
    case Breakpoints.MOBILE:
      return TYPOGRAPHY_TYPE.PARAGRAPH5;
    case Breakpoints.TABLET:
      return TYPOGRAPHY_TYPE.PARAGRAPH3;
    case Breakpoints.DESKTOP:
      return TYPOGRAPHY_TYPE.PARAGRAPH2;
  }
};

const getShaderTitleType = (breakpoint: Breakpoints) => {
  switch (breakpoint) {
    case Breakpoints.SMALL_MOBILE:
    case Breakpoints.MOBILE:
      return TYPOGRAPHY_TYPE.HEADLINE5;
    case Breakpoints.TABLET:
      return TYPOGRAPHY_TYPE.HEADLINE4;
    case Breakpoints.DESKTOP:
      return TYPOGRAPHY_TYPE.HEADLINE3;
  }
};

const NsfwPip: ComponentWithClassName<NsfwPipProps> = ({
  className,
  pipsCount,
  streamId,
  style,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();

  const { setMuted } = useContext(MuteContext);

  const { deviceType, stream } = useSelector(
    (state: RootState) => ({
      deviceType: deviceInfoSelectors.getDeviceType(state),
      stream: streamsCacheSelectors.getStreamById(state, streamId),
    }),
    shallowEqual
  );

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getNsfwPipAlternativeDomainContentSupportEnabled
  );

  const openNsfwConfirmationForm = useCallback(() => {
    const isDesktop = breakpoint === Breakpoints.DESKTOP;
    const isIos = deviceType === DeviceType.IOS;
    const isIpad = deviceType === DeviceType.IPAD;

    if (isDesktop) {
      dispatch(openNsfwContentModal({ redirectLink: null }));
    } else if (isIos || isIpad) {
      dispatch(
        openNsfwContentBottomScreen({
          /*
           * Solves the issue on Apple non-desktop devices where new streams
           * break already existed streams and pause them
           */
          onBeforeConfirm: () => setMuted(true),
          redirectLink: null,
        })
      );
    } else {
      dispatch(openNsfwContentBottomScreen({ redirectLink: null }));
    }
  }, [breakpoint, deviceType, dispatch, setMuted]);

  return (
    <div
      className={classnames(className, styles[breakpoint], {
        [styles.pipsCount1]: pipsCount === 1,
        [styles.pipsCount2]: pipsCount === 2,
        [styles.pipsCount3]: pipsCount === 3,
      })}
      data-testid={`nsfw-pip-${streamId}`}
      onClick={openNsfwConfirmationForm}
      style={style}
    >
      <img
        alt=""
        className={styles.thumbnail}
        src={makeAlternativeDomainUrl(stream.thumbnail)}
      />
      <div className={styles.shader}>
        <Typography
          className={styles.shaderTitle}
          type={getShaderTitleType(breakpoint)}
        >
          <FormattedMessage {...messages.shaderTitle} />
        </Typography>
        <Typography type={getShaderDescriptionType(breakpoint)}>
          <FormattedMessage {...messages.shaderDescription} />
        </Typography>
      </div>
    </div>
  );
};

export const MemoNsfwPip = memo(NsfwPip);
