import { ClientEventsRequest } from "generated/proto/EventGateway";
import {
  EVENT_SOURCE,
  EventNames,
} from "src/core/analytics/dataMesh/common/enums";
import { DataMeshTrackEventParameters } from "src/core/analytics/dataMesh/common/types";
import dataMesh from "src/core/analytics/dataMesh/dataMeshModule";
import { getAccountId } from "src/core/analytics/dataMesh/utils/store/getStoreEntity";
import { getEnvironmentName } from "src/core/analytics/imports/environment";

interface SendEventUtil {
  clientSessionId: string;
  eventName: EventNames;
  eventUid: string;
  params?: Partial<DataMeshTrackEventParameters>;
  payload: Uint8Array;
  timestamp: number;
}

export const sendEventUtil = ({
  eventName,
  payload,
  eventUid,
  clientSessionId,
  timestamp,
  params,
}: SendEventUtil) => {
  const accountId = getAccountId();

  const parameters = {
    uid: eventUid,
    clientSessionId,
    env: getEnvironmentName(),
    eventTime: timestamp,
    eventSource: EVENT_SOURCE,
    screenName: "",
    screenState: "",
    ...params,
  };

  const body = ClientEventsRequest.encode({
    events: [
      {
        name: eventName,
        payload,
        parameters,
        accountId,
      },
    ],
  }).finish();

  dataMesh.then((analytics) => {
    analytics.track(EventNames.CLIENT_SESSION, body);
  });
};
