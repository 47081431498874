import { createSlice } from "@reduxjs/toolkit";
import { GiftDrawerTarget } from "src/features/giftsDrawer/api/giftDrawerTypes";
import { addUserSessionScopeReducer } from "src/features/giftsDrawer/imports/state";
import { DictionaryBy } from "src/features/giftsDrawer/imports/types";
import {
  Category,
  loadGiftDrawerPending,
  loadGiftDrawerRejected,
  setGiftsDrawer,
} from "./action";
import { transformCategory } from "./utils";

export const persistConfig = {
  whitelist: ["drawers"],
};

export interface GiftDrawerState {
  drawers: {
    [target in GiftDrawerTarget]?: {
      categories: DictionaryBy<Category, "id">;
      categoryIdsList: Category["id"][];
      drawerId: string;
      drawerVersion: string;
    };
  };
  isLoading: boolean;
}

const initialState: GiftDrawerState = {
  isLoading: false,
  drawers: {},
};

const slice = createSlice({
  name: "giftDrawer",
  initialState,
  reducers: {},
  extraReducers(builder) {
    addUserSessionScopeReducer(
      builder
        .addCase(loadGiftDrawerPending, (state) => {
          state.isLoading = true;
        })
        .addCase(loadGiftDrawerRejected, (state) => {
          state.isLoading = false;
        })
        .addCase(setGiftsDrawer, (state, action) => {
          const { target, drawerId, drawerVersion, categories } =
            action.payload;

          state.isLoading = false;

          state.drawers[target] = {
            drawerId,
            drawerVersion,
            categories: categories.reduce(
              (acc, category) => {
                acc[category.id] = transformCategory(category);

                return acc;
              },
              {} as DictionaryBy<Category, "id">
            ),
            categoryIdsList: categories.map(({ id }) => id),
          };
        }),
      () => initialState
    );
  },
});

export const giftDrawerReducer = slice.reducer;
