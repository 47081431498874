/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.MarketingClientPurchaseEvent || ($protobuf.roots.MarketingClientPurchaseEvent = {});

export const MarketingClientPurchaseEvent = $root.MarketingClientPurchaseEvent = (() => {

    function MarketingClientPurchaseEvent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MarketingClientPurchaseEvent.prototype.transactionId = null;
    MarketingClientPurchaseEvent.prototype.currency = null;
    MarketingClientPurchaseEvent.prototype.amount = null;
    MarketingClientPurchaseEvent.prototype.amountUsd = null;
    MarketingClientPurchaseEvent.prototype.hashedEmail = null;
    MarketingClientPurchaseEvent.prototype.hashedPhone = null;

    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientPurchaseEvent.prototype, "_transactionId", {
        get: $util.oneOfGetter($oneOfFields = ["transactionId"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientPurchaseEvent.prototype, "_currency", {
        get: $util.oneOfGetter($oneOfFields = ["currency"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientPurchaseEvent.prototype, "_amount", {
        get: $util.oneOfGetter($oneOfFields = ["amount"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientPurchaseEvent.prototype, "_amountUsd", {
        get: $util.oneOfGetter($oneOfFields = ["amountUsd"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientPurchaseEvent.prototype, "_hashedEmail", {
        get: $util.oneOfGetter($oneOfFields = ["hashedEmail"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(MarketingClientPurchaseEvent.prototype, "_hashedPhone", {
        get: $util.oneOfGetter($oneOfFields = ["hashedPhone"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    MarketingClientPurchaseEvent.create = function create(properties) {
        return new MarketingClientPurchaseEvent(properties);
    };

    MarketingClientPurchaseEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
            writer.uint32(10).string(message.transactionId);
        if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
            writer.uint32(18).string(message.currency);
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
            writer.uint32(25).double(message.amount);
        if (message.amountUsd != null && Object.hasOwnProperty.call(message, "amountUsd"))
            writer.uint32(33).double(message.amountUsd);
        if (message.hashedEmail != null && Object.hasOwnProperty.call(message, "hashedEmail"))
            writer.uint32(42).string(message.hashedEmail);
        if (message.hashedPhone != null && Object.hasOwnProperty.call(message, "hashedPhone"))
            writer.uint32(50).string(message.hashedPhone);
        return writer;
    };

    MarketingClientPurchaseEvent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MarketingClientPurchaseEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MarketingClientPurchaseEvent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.transactionId = reader.string();
                    break;
                }
            case 2: {
                    message.currency = reader.string();
                    break;
                }
            case 3: {
                    message.amount = reader.double();
                    break;
                }
            case 4: {
                    message.amountUsd = reader.double();
                    break;
                }
            case 5: {
                    message.hashedEmail = reader.string();
                    break;
                }
            case 6: {
                    message.hashedPhone = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MarketingClientPurchaseEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MarketingClientPurchaseEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.MarketingClientPurchaseEvent)
            return object;
        let message = new $root.MarketingClientPurchaseEvent();
        if (object.transactionId != null)
            message.transactionId = String(object.transactionId);
        if (object.currency != null)
            message.currency = String(object.currency);
        if (object.amount != null)
            message.amount = Number(object.amount);
        if (object.amountUsd != null)
            message.amountUsd = Number(object.amountUsd);
        if (object.hashedEmail != null)
            message.hashedEmail = String(object.hashedEmail);
        if (object.hashedPhone != null)
            message.hashedPhone = String(object.hashedPhone);
        return message;
    };

    MarketingClientPurchaseEvent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.transactionId != null && message.hasOwnProperty("transactionId")) {
            object.transactionId = message.transactionId;
            if (options.oneofs)
                object._transactionId = "transactionId";
        }
        if (message.currency != null && message.hasOwnProperty("currency")) {
            object.currency = message.currency;
            if (options.oneofs)
                object._currency = "currency";
        }
        if (message.amount != null && message.hasOwnProperty("amount")) {
            object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
            if (options.oneofs)
                object._amount = "amount";
        }
        if (message.amountUsd != null && message.hasOwnProperty("amountUsd")) {
            object.amountUsd = options.json && !isFinite(message.amountUsd) ? String(message.amountUsd) : message.amountUsd;
            if (options.oneofs)
                object._amountUsd = "amountUsd";
        }
        if (message.hashedEmail != null && message.hasOwnProperty("hashedEmail")) {
            object.hashedEmail = message.hashedEmail;
            if (options.oneofs)
                object._hashedEmail = "hashedEmail";
        }
        if (message.hashedPhone != null && message.hasOwnProperty("hashedPhone")) {
            object.hashedPhone = message.hashedPhone;
            if (options.oneofs)
                object._hashedPhone = "hashedPhone";
        }
        return object;
    };

    MarketingClientPurchaseEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MarketingClientPurchaseEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MarketingClientPurchaseEvent";
    };

    return MarketingClientPurchaseEvent;
})();

export { $root as default };
