import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AccountToActiveStreamMap,
  fetchActiveStreamByAccountIds,
} from "api/stream";
import { isApiError } from "api/utils/enhancedFetch";
import { loadStreamIdsByAccountIdsForVisitor } from "src/features/profile/visitorProfile/exports/utils";
import { RootState } from "state/delegate";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";

export const fetchAccountToStreamMap = createAsyncThunk<
  AccountToActiveStreamMap,
  { accountIds: string[]; isLoggedIn: boolean } & FetcherMetaV2,
  { rejectValue: string; state: RootState }
>(
  "lwc/accountToStreamMap/fetch",
  async ({ accountIds, isLoggedIn }, thunkAPI) => {
    try {
      if (isLoggedIn) {
        return await fetchActiveStreamByAccountIds({
          accountIds,
        });
      }

      return await loadStreamIdsByAccountIdsForVisitor(accountIds);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return thunkAPI.rejectWithValue(error);
    }
  }
);
