import { Dispatch } from "@reduxjs/toolkit";
import { fetchGiftDrawer } from "src/features/giftsDrawer/api/giftDrawerApi";
import {
  GetMyGiftDrawerResponseCode,
  GiftDrawerTarget,
  isGiftType,
} from "src/features/giftsDrawer/api/giftDrawerTypes";
import { RootState } from "src/features/giftsDrawer/imports/state";
import { loadGiftDrawerRejected, setGiftsDrawer } from "./action";
import { giftDrawerSelectors } from "./giftDrawerSelectors";

interface LoadGiftDrawerParams {
  isLoadLatest?: boolean;
  target: GiftDrawerTarget;
}

export const loadGiftDrawer =
  ({ target, isLoadLatest }: LoadGiftDrawerParams) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const info = giftDrawerSelectors.getDrawerInfo(getState(), target);

      const data = await fetchGiftDrawer({
        target,
        ...(!isLoadLatest && info),
      });

      if (data.status === GetMyGiftDrawerResponseCode.FAILED) {
        dispatch(loadGiftDrawerRejected());

        return;
      }

      if (
        data.status === GetMyGiftDrawerResponseCode.UPDATED ||
        data.status === GetMyGiftDrawerResponseCode.LATEST
      ) {
        const categories = data.giftDrawer.categories.map((category) => {
          const { collectionIds, giftIds } = category.entities.reduce(
            (acc, gift) => {
              if (isGiftType(gift)) {
                acc.giftIds.push(gift.gift.encryptedGiftId);
              } else {
                acc.collectionIds.push(
                  gift.giftCollection.encryptedCollectionId
                );
              }

              return acc;
            },
            {
              collectionIds: [],
              giftIds: [],
            } as {
              collectionIds: string[];
              giftIds: string[];
            }
          );

          return {
            id: category.encryptedCategoryId,
            name: category.categoryMetadata.name,
            icon: category.categoryMetadata.iconUrl,
            activeIcon: category.categoryMetadata.activeIconUrl,
            collectionIds,
            giftIds,
          };
        });

        dispatch(
          setGiftsDrawer({
            target,
            drawerId: data.giftDrawer.encryptedDrawerId,
            drawerVersion: data.giftDrawer.drawerVersion,
            categories,
          })
        );

        return;
      }

      dispatch(loadGiftDrawerRejected());
    } catch (error) {
      dispatch(loadGiftDrawerRejected());
    }
  };
