import {
  encodePayload,
  encodeRestoreStickerPayload,
} from "broadcast/api/encoders";
import {
  StickerType as NumericStickerType,
  StickerStatus,
  StickerType,
} from "broadcast/enums/stickers";
import { enhancedFetch } from "broadcast/imports/api";
import { getProxycadorHost } from "broadcast/imports/environments";
import { GoalStickerType, Nullable } from "broadcast/imports/types";
import { parseMessageFromBase64 } from "broadcast/imports/utils";
import { RestoreStickerData, StickerDataType } from "broadcast/types/types";
import { StickerGoalPayload, StickerList } from "generated/proto/Sticker";

interface AddStickerParams {
  stickerData: StickerDataType;
  streamId: string;
}

export const addSticker = ({ streamId, stickerData }: AddStickerParams) => {
  enhancedFetch(
    `${getProxycadorHost()}/stream/sticker/v2/stream/${streamId}/stickers?stickersMaxCount=2`,
    {
      method: "POST",
      body: encodePayload(stickerData),
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
    }
  );
};

interface EditStickerParams {
  stickerData: StickerDataType;
  streamId: string;
}

export const editSticker = ({ streamId, stickerData }: EditStickerParams) => {
  enhancedFetch(
    `${getProxycadorHost()}/stream/sticker/v2/stream/${streamId}/stickers/${stickerData.stickerId}?stickersMaxCount=2`,
    {
      method: "PATCH",
      body: encodePayload(stickerData),
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
    }
  );
};

interface DeleteStickerParams {
  stickerId: string;
  streamId: string;
}

export const deleteSticker = ({ streamId, stickerId }: DeleteStickerParams) => {
  enhancedFetch(
    `${getProxycadorHost()}/stream/sticker/v2/stream/${streamId}/stickers/${stickerId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
    }
  );
};

interface ProtoSticker {
  id: string;
  payload: string;
  posX: number;
  posY: number;
  rotation: number;
  scale: number;
  status: StickerStatus;
  text: string;
  type: StickerType;
}

export const getSavedGoalSticker = (): Promise<Nullable<GoalStickerType>> =>
  enhancedFetch(`${getProxycadorHost()}/stream/sticker/v2/stickers/saved`, {
    method: "GET",
    headers: {
      "Content-Type": "application/x-protobuf;charset=UTF-8",
    },
  }).then(async (res) => {
    const buffer = await res.arrayBuffer();
    const { stickers } = StickerList.decode(new Uint8Array(buffer, undefined));
    const savedGoalSticker = stickers.find(
      (item: ProtoSticker) => item.type === NumericStickerType.GOAL
    );

    return savedGoalSticker
      ? {
          ...savedGoalSticker,
          payload: parseMessageFromBase64(
            savedGoalSticker?.payload,
            StickerGoalPayload
          ),
        }
      : null;
  });

interface RestoreStickerParams {
  restoreStickerData: RestoreStickerData;
  streamId: string;
}

export const restoreSticker = ({
  restoreStickerData,
  streamId,
}: RestoreStickerParams) => {
  enhancedFetch(
    `${getProxycadorHost()}/stream/sticker/v2/stream/${streamId}/stickers?stickersMaxCount=2`,
    {
      method: "POST",
      body: encodeRestoreStickerPayload(restoreStickerData),
      headers: { "Content-Type": "application/x-protobuf;charset=UTF-8" },
    }
  );
};
