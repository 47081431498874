import {
  AutoLoginQueryKey,
  AutoLoginQueryParams,
} from "src/features/autoLogin/types/autoLoginQuery";

export const parseAutoLoginQueryParams = (
  search: string
): AutoLoginQueryParams => {
  const searchParams = new URLSearchParams(search);
  const accountId = searchParams.get(AutoLoginQueryKey.ACCOUNT_ID);
  const token = searchParams.get(AutoLoginQueryKey.TOKEN);
  const appleRecoverToken = searchParams.get(
    AutoLoginQueryKey.APPLE_RECOVER_TOKEN
  );

  return { accountId, token, appleRecoverToken };
};
