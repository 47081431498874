import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "delete_all_message_requests_title",
    defaultMessage: "Delete All Requests",
  },
  description: {
    id: "delete_all_message_requests_body",
    defaultMessage: "Are you sure you want to delete all message requests?",
  },
  delete: {
    id: "confirmation.delete",
    defaultMessage: "Delete",
  },
  deleteAll: {
    id: "delete_all",
    defaultMessage: "Delete All",
  },
  translate: {
    id: "chat.translate",
    defaultMessage: "Translate",
  },
});
