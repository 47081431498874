import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { deadMultiBroadcastStreamStatuses } from "enums/multiBroadcastStreamStatus";
import { getAlternativeDomainUrlReplacementEnabled } from "src/state/abTests";
import batchLoadProfiles from "state/flows/batchLoadProfiles";
import loadStreamSettings from "state/flows/loadStreamSettings";
import { loadBatchStreamsInfo } from "state/flows/manageViewerSession";
import { viewerSessionSelectors } from "state/selectors";
import {
  isAlternativeDomain,
  replaceDomainWithHostname,
} from "utils/alternativeDomainUtils";

export const multiBroadcastOtherStreamsSelector = (state) => {
  const streamId = viewerSessionSelectors.getStreamId(state);
  const isUrlReplacementEnabled =
    getAlternativeDomainUrlReplacementEnabled(state);
  const multiBroadcastStreams =
    viewerSessionSelectors.getMultiBroadcastStreams(state);
  const currentStream = Object.values(multiBroadcastStreams).find(
    (stream) => stream.stream.mbDescriptor.streamId === streamId
  );

  if (deadMultiBroadcastStreamStatuses.includes(currentStream?.status)) {
    return [];
  }

  return Object.values(multiBroadcastStreams).reduce((acc, next) => {
    if (
      next.stream.mbDescriptor.streamId !== streamId &&
      !deadMultiBroadcastStreamStatuses.includes(next.status)
    ) {
      let streamURL = next.stream.streamURL.split("?")[0];

      if (isUrlReplacementEnabled && isAlternativeDomain() && streamURL) {
        streamURL = replaceDomainWithHostname(streamURL);
      }

      acc.push({
        streamURL,
        ...next.stream.mbDescriptor,
        status: next.status,
      });
    }

    return acc;
  }, []);
};

export default () => {
  const otherStreams = useSelector(
    multiBroadcastOtherStreamsSelector,
    shallowEqual
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const accountIds = otherStreams.map((x) => x.accountId);
    if (accountIds.length === 0) {
      return;
    }
    dispatch(
      batchLoadProfiles({
        ids: accountIds,
        loadOnlyIfMissing: true,
        params: { basic: true, detail: true, live: true, follow: true },
      })
    );
    otherStreams.forEach((x) => {
      dispatch(
        loadStreamSettings({ streamId: x.streamId, ignoreCache: false })
      );
    });
    dispatch(loadBatchStreamsInfo(otherStreams.map((x) => x.streamId)));
  }, [otherStreams]);

  return otherStreams;
};
