import {
  parseArraySOC,
  parseBooleanEnabledSOC,
} from "src/features/broadcastMasks/imports/state";

export const getIsBanubaSDKEnabled = parseBooleanEnabledSOC(
  "web.banuba.sdk.enabled",
  "0"
);

export const getIsBanubaSDKMobileEnabled = parseBooleanEnabledSOC(
  "web.banuba.sdk.mobile.enabled",
  "0"
);

export const getBanubaSDKRestrictedIOSVersions = parseArraySOC(
  "web.banuba.sdk.restricted.ios.versions",
  "17.4.1,16.7.10"
);

export const getBanubaSDKWhitelistedAndroidGPUList = parseArraySOC(
  "web.banuba.sdk.whitelisted.android.gpu.list",
  ""
);
