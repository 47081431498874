import React from "react";
import { Helmet } from "react-helmet";

export const SEOStructuredData = ({
  structuredData,
}: Record<string, unknown>) => (
  <Helmet>
    <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
  </Helmet>
);
