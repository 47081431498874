import { createAction } from "@reduxjs/toolkit";
// Will be fixed by moving fully to actionCreators or to Zustand
// eslint-disable-next-line no-restricted-imports
import {
  ACME_RECEIVED,
  CONNECTION_MANAGER_CHANGE_USERNAME,
  CONNECTION_MANAGER_CONNECTED,
  CONNECTION_MANAGER_DISCONNECTED,
  CONNECTION_MANAGER_RECONNECT,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED,
  SET_CONNECTION_MANAGER_WEB_PUSH_API_TOKEN,
} from "state/actionTypes";

export const receivedAcme = createAction(
  ACME_RECEIVED,
  ({ serviceName, serviceIdentifier, data }) => ({
    payload: { serviceIdentifier, serviceName, data },
  })
);

export const receivedLiveRichNotification = (payload, meta) => ({
  type: LIVE_RICH_NOTIFICATION_RECEIVED,
  payload,
  meta,
});

export const receivedStreamStartedNotification = ({ stream, following }) => ({
  type: LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED,
  payload: { stream },
  meta: { following },
});

export const requestedWebsocketReconnect = (interactionType) => ({
  type: CONNECTION_MANAGER_RECONNECT,
  meta: interactionType,
});

export const websocketConnected = () => ({
  type: CONNECTION_MANAGER_CONNECTED,
});

export const websocketDisconnected = () => ({
  type: CONNECTION_MANAGER_DISCONNECTED,
});

export const changeUsername = (username) => ({
  type: CONNECTION_MANAGER_CHANGE_USERNAME,
  payload: username,
});

export const setWebPushApiToken = createAction(
  SET_CONNECTION_MANAGER_WEB_PUSH_API_TOKEN,
  (token) => ({
    payload: token,
  })
);
