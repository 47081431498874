import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BroadcastMasksConfigMask } from "src/features/broadcastMasks/common/types";
import {
  broadcastMasksSelectors,
  selectMask,
} from "src/features/broadcastMasks/state";

const DEFAULT_DURATION_SECONDS = 10;

interface GiftARAnimationProps extends Omit<BroadcastMasksConfigMask, "id"> {
  onComplete?: VoidFunction;
}

function extractFilenameFromUrl(url: string) {
  const parts = url.split("/");
  const filename = parts.pop()?.split("?")[0];

  if (!filename) {
    return null;
  }

  return filename.endsWith(".zip") ? filename.slice(0, -4) : null;
}

const GiftARAnimation: React.FC<GiftARAnimationProps> = ({
  name,
  iconUrl,
  bundleUrl,
  duration = DEFAULT_DURATION_SECONDS,
  onComplete,
}) => {
  const dispatch = useDispatch();
  const player = useSelector(broadcastMasksSelectors.getPlayer);

  useEffect(() => {
    const id = extractFilenameFromUrl(bundleUrl);

    if (!id) {
      return;
    }

    dispatch(
      selectMask({
        id,
        name,
        iconUrl,
        bundleUrl,
        duration,
        onComplete,
      })
    );
  }, [player, name, iconUrl, bundleUrl, duration, dispatch, onComplete]);

  return null;
};

export default memo(GiftARAnimation);
