import { createAction } from "@reduxjs/toolkit";
import { GiftDrawerTarget } from "src/features/giftsDrawer/api/giftDrawerTypes";

export const loadGiftDrawerPending = createAction(
  "lwc/gifts/loadGiftDrawerPending"
);

export const loadGiftDrawerRejected = createAction(
  "lwc/gifts/loadGiftDrawerRejected"
);

export interface Category {
  activeIcon: string;
  collectionIds: string[];
  giftIds: string[];
  icon: string;
  id: string;
  name: string;
}

interface SetGiftsDrawerPayload {
  categories: Category[];
  drawerId: string;
  drawerVersion: string;
  target: GiftDrawerTarget;
}

export const setGiftsDrawer = createAction<SetGiftsDrawerPayload>(
  "lwc/gifts/setGiftsDrawer"
);
