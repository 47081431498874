import { getProxycadorHost } from "environment";
import { LPInviter } from "src/features/broadcastOneToOne/imports/enums";
import {
  InitStreamRequest,
  InitStreamResponse,
} from "src/features/broadcastOneToOne/imports/proto";
import {
  StreamProtocol,
  StreamSettings,
} from "src/features/broadcastOneToOne/imports/types";
import { enhancedFetch } from "src/features/broadcastOneToOne/imports/utils";

interface InitOneToOneBroadcastParams {
  recipientId: string;
  region: string;
  settings: StreamSettings;
  source: string;
  username: string;
}

export const initOneToOneBroadcast = async (
  params: InitOneToOneBroadcastParams
) => {
  const response = await enhancedFetch(
    `${getProxycadorHost()}/stream/client/v1/initChat/${params.username}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
      body: InitStreamRequest.encode({
        type: null,
        recipientAccountId: params.recipientId,
        source: params.source,
        saveStoryOnServer: true,
        streamProtocol: StreamProtocol.RTMP,
        region: params.region,
        dontInviteToLiveParty: true,
        settings: params.settings,
        lpInvitableBy: LPInviter.NO_ONE,
      }).finish(),
    }
  );

  const bufferResponse = await response.arrayBuffer();

  return InitStreamResponse.decode(new Uint8Array(bufferResponse), undefined);
};
