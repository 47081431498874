import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchMosV2Lineup } from "src/features/mos/api/mos";
import {
  FetchMosV2LineupParams,
  FetchMosV2LineupResponse,
  MosError,
  MosV2LineupRequestRejectError,
} from "src/features/mos/types";

export const getMosV2Action = createAsyncThunk<
  FetchMosV2LineupResponse,
  { timeoutMs: number } & FetchMosV2LineupParams,
  MosV2LineupRequestRejectError
>(
  "mosV2/get",
  async ({ streamId, streamerId, timeoutMs }, { rejectWithValue }) => {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeoutMs);

      const response = await Promise.race([
        fetchMosV2Lineup({
          streamId,
          streamerId,
          signal: controller.signal,
        }),

        new Promise<FetchMosV2LineupResponse>((_resolve, reject) =>
          setTimeout(() => {
            reject(new Error(MosError.TIMEOUT_ERROR));
          }, timeoutMs)
        ),
      ]);

      clearTimeout(timeoutId);

      return response;
    } catch (error) {
      if (error instanceof Error) {
        const message = error.message || MosError.SOMETHING_WENT_WRONG;

        return rejectWithValue({ message });
      }

      // Fallback if error does not contain expected structure
      return rejectWithValue({ message: MosError.SOMETHING_WENT_WRONG });
    }
  }
);
