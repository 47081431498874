import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import FallbackNotification from "./FallbackNotification";
import useNotificationData from "./useNotificationData";

let showFallbackGlobal = false;

const NativeNotification = ({ notificationId }) => {
  const data = useNotificationData(notificationId, true);
  const [showFallback, setShowFallback] = useState(showFallbackGlobal);

  useLayoutEffect(() => {
    if (showFallback || !data) {
      return;
    }

    let notification;
    const { title, body, icon, tag, onClose, silent } = data;

    try {
      // eslint-disable-next-line compat/compat
      notification = new Notification(title, {
        body,
        icon,
        tag,
        silent,
        renotify: true,
      });
    } catch (_) {
      showFallbackGlobal = true;
      setShowFallback(true);

      return;
    }
    const callback = () => {
      parent.focus();
      window.focus();
      data.onClick();
    };

    notification.ontouchstart = callback;
    notification.onclick = callback;
    notification.onclose = onClose;

    return () => notification.close();
  }, []);

  if (showFallback) {
    return <FallbackNotification notificationId={notificationId} />;
  }

  return null;
};

NativeNotification.propTypes = {
  notificationId: PropTypes.string.isRequired,
};

export default NativeNotification;
