import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CHAT_OLDER_MESSAGES_PAGE_SIZE } from "chat/constants";
import { Direction } from "chat/enums";
import { linkToChat } from "chat/imports/constants";
import { usePrevious } from "chat/imports/hooks";
import {
  RootState,
  settingsSelectors,
  userSelectors,
} from "chat/imports/state";
import { readMessageRequest } from "chat/messageRequest/state/asyncAction";
import {
  fetchConversation,
  readMessages,
  readReactionsToSelf,
} from "chat/state/actionCreators";
import chatSelectors from "chat/state/selectors";
import { ConversationState } from "chat/types";

export default (conversationId: string | undefined) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    conversation,
    latestMessage,
    isNotifySenderDisabled,
    accountId,
    lastReadSelfReactionTs,
  } = useSelector(
    useCallback(
      (state: RootState) => ({
        isNotifySenderDisabled: settingsSelectors.isNotifySenderDisabled(state),
        conversation: conversationId
          ? chatSelectors.getConversation(state, conversationId)
          : null,
        latestMessage: conversationId
          ? chatSelectors.getConversationLatestMessage(state, conversationId)
          : null,
        accountId: userSelectors.getMyAccountId(state),
        lastReadSelfReactionTs: chatSelectors.getLastReadSelfReactionTs(state),
      }),
      [conversationId]
    ),
    shallowEqual
  );

  const isLastMessageMine = latestMessage?.from === accountId;

  const [lastMessageTimestamp, setLastMessageTimestamp] = useState(0);

  const prevConversation = usePrevious(conversation);
  const prevConversationId = usePrevious(conversationId);

  const isMessageRequest =
    conversation?.state === ConversationState.CHAT_REQUEST;

  useLayoutEffect(() => {
    // redirects only when user tries to open restricted chat
    if (
      conversationId != null &&
      conversationId === prevConversationId &&
      !conversation &&
      prevConversation != null
    ) {
      history.replace(linkToChat);
    }
  }, [
    conversation,
    prevConversation,
    history,
    conversationId,
    prevConversationId,
  ]);

  useEffect(() => {
    if (!conversationId) {
      return;
    }

    dispatch(
      fetchConversation({
        conversationId,
        direction: Direction.FORWARD,
        start_timestamp: 0,
        include_group_info: true,
        include_group_members: true,
        limit: CHAT_OLDER_MESSAGES_PAGE_SIZE,
      })
    );
  }, [conversationId, dispatch]);

  useEffect(() => {
    if (!conversation) {
      return;
    }

    const { isLoading, last_message_ts = 0 } = conversation;

    const lastReadMessageTimestamp = last_message_ts || Date.now();

    if (
      !isLoading &&
      conversationId &&
      !isLastMessageMine &&
      lastMessageTimestamp < last_message_ts
    ) {
      dispatch(
        readMessages({
          conversation_id: conversationId,
          last_read_message_ts: lastReadMessageTimestamp,
          notify_sender: !isNotifySenderDisabled,
        })
      );

      if (isMessageRequest) {
        dispatch(
          readMessageRequest({
            conversation_id: conversationId,
            last_read_message_ts: lastReadMessageTimestamp,
            notify_sender: false,
          })
        );
      }
      setLastMessageTimestamp(lastReadMessageTimestamp);
    }
  }, [
    isNotifySenderDisabled,
    conversation,
    conversationId,
    dispatch,
    lastMessageTimestamp,
    isMessageRequest,
    isLastMessageMine,
  ]);

  useEffect(() => {
    if (!conversation) {
      return;
    }

    const { isLoading, lastSelfReadMessageTs = 0 } = conversation;

    if (
      !isLoading &&
      conversationId &&
      lastReadSelfReactionTs > lastSelfReadMessageTs
    ) {
      dispatch(readReactionsToSelf({ conversationId }));
    }
  }, [conversation, conversationId, dispatch, lastReadSelfReactionTs]);

  useEffect(() => {
    setLastMessageTimestamp(0);
  }, [conversationId, isMessageRequest]);
};
