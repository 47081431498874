import { defineMessages } from "react-intl";
export const socialGamesMessages = defineMessages({
  mostPopular: {
    id: "social.games.most.popular",
    defaultMessage: "Most Popular",
  },
  topPick: {
    id: "social.games.top.pick",
    defaultMessage: "Top Pick",
  },
  alsoLike: {
    id: "follow.suggested.title",
    defaultMessage: "You may also like",
  },
  play: {
    id: "home.v2.play",
    defaultMessage: "Play",
  },
  rtp: {
    id: "social.games.rtp",
    defaultMessage: "RTP",
  },
  maxMultiplier: {
    id: "social.games.max.multiplier",
    defaultMessage: "Max Multiplier:",
  },
  upTo: {
    id: "social.games.up.to",
    defaultMessage: "Up to",
  },
  other: {
    id: "moderation.report.reason.other",
    defaultMessage: "Other",
  },
  recentlyPlayed: {
    id: "social.games.recently.played",
    defaultMessage: "Recently Played",
  },
  slots: {
    id: "social.games.slots",
    defaultMessage: "Slots",
  },
  featured: {
    id: "social.games.featured",
    defaultMessage: "Hot & New",
  },
  suggested: {
    id: "social.games.suggested",
    defaultMessage: "Suggested",
  },
  emptySearch: {
    id: "modal.login.phone.select.country.content.empty",
    defaultMessage: "Oops, no results found",
  },
  reloadPage: {
    id: "social.games.reload.page",
    defaultMessage: "Oops... Try reloading the page",
  },
  selectGameToPlay: {
    id: "social.games.select.game.to.play",
    defaultMessage: "Select game to play",
  },
  allGames: {
    id: "social.games.all.games",
    defaultMessage: "All Games",
  },
  live: {
    id: "broadcast.live",
    defaultMessage: "Live",
  },
  freespins: {
    id: "social.games.freespins",
    defaultMessage: "Freespins",
  },
  searchResults: {
    id: "userSearch.title.noQuery",
    defaultMessage: "Search results",
  },
  winInfo: {
    id: "social.games.win.info",
    defaultMessage: "Win up to",
  },
  coins: {
    id: "social.games.win.info.coins",
    defaultMessage: "coins",
  },
  social_games_hot_and_new: {
    id: "social_games_hot_and_new",
    defaultMessage: "Hot & Popular",
  },
  live_home_new: {
    id: "live_home_new",
    defaultMessage: "New",
  },
  social_games_spins: {
    id: "social_games_spins",
    defaultMessage: "Spins",
  },
  social_games_classic: {
    id: "social_games_classic",
    defaultMessage: "Classic",
  },
  social_games_other: {
    id: "social_games_other",
    defaultMessage: "Other",
  },
  social_games_recently_played: {
    id: "social_games_recently_played",
    defaultMessage: "Recently Played",
  },
});
