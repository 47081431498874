import React, { useCallback } from "react";
import { batch, useDispatch } from "react-redux";
import { MessageDescriptor } from "@formatjs/intl";
import { getGoogleProviderEnabled } from "environment";
import useGoogleLogin from "src/features/signin/hooks/useGoogleLogin";
import {
  EventFields,
  RegistrationSource,
  SCREEN_NAME,
  SIGNIN_TARGET,
  emitUiAction,
} from "src/features/signin/imports/analytics";
import { LoginProvider } from "src/features/signin/imports/enums";
import { lockTopModal } from "src/features/signin/imports/state";
import { ButtonVariant } from "src/features/signin/imports/ui";
import ProviderButton from "src/features/signin/modal/login/components/ProviderButton";
import { LoginTypeButton } from "src/features/signin/modal/login/components/ProviderButton/types";
import { loginStart } from "src/features/signin/state/login/actionCreators";
import { ComponentWithClassName, VoidCallback } from "src/types/common";

interface LoginGoogleButtonProps {
  buttonText?: MessageDescriptor;
  buttonVariant?: ButtonVariant.PRIMARY | ButtonVariant.SECONDARY;
  loginTypeVariant?: ButtonVariant;
  onLoginSuccess?: VoidCallback;
  provider: LoginProvider;
  registrationSource?: RegistrationSource;
  sendCustomAnalyticsEvent?: VoidCallback;
  shouldSkipErrorHandling?: boolean;
  type?: LoginTypeButton;
}

const LoginGoogleButton: ComponentWithClassName<LoginGoogleButtonProps> = ({
  buttonVariant = ButtonVariant.SECONDARY,
  provider,
  sendCustomAnalyticsEvent,
  shouldSkipErrorHandling,
  type,
  loginTypeVariant,
  className,
  buttonText,
  onLoginSuccess,
  registrationSource,
}) => {
  const dispatch = useDispatch();

  const startGoogleLogin = useGoogleLogin({
    shouldSkipErrorHandling,
    onLoginSuccess,
  });

  const onGoogleLogin = useCallback(() => {
    if (sendCustomAnalyticsEvent) {
      sendCustomAnalyticsEvent();
    } else {
      emitUiAction({
        target: SIGNIN_TARGET.SIGNIN_WITH_GOOGLE,
        tango_screen: {
          reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
        },
        additionalParams: {
          [EventFields.SOURCE]: registrationSource,
        },
      });
    }

    batch(() => {
      dispatch(lockTopModal());
      dispatch(loginStart());
    });

    startGoogleLogin();
  }, [dispatch, sendCustomAnalyticsEvent, startGoogleLogin]);

  if (!getGoogleProviderEnabled()) {
    return null;
  }

  return (
    <ProviderButton
      buttonVariant={buttonVariant}
      key={provider}
      provider={provider}
      loginTypeButtonVariant={loginTypeVariant}
      onClick={onGoogleLogin}
      type={type}
      className={className}
      buttonText={buttonText}
    />
  );
};

export default LoginGoogleButton;
