import { batch } from "react-redux";
import { StreamStatusIntCode } from "stream/common/enums";
import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { emitMultiStreamInvitationReceived } from "@analytics/multiStreamInvitationEmitters";
import {
  checkImage,
  getStreamStatus,
  initStream,
  setStreamStartNotification,
  terminateStream,
} from "api/stream";
import { uploadImage } from "api/upload";
import { initStream as initStreamV2 } from "broadcast/api/init";
import { isBroadcastGatewayEndpointsEnabled } from "broadcast/soc/broadcastSoc";
import {
  BroadcastSource,
  BroadcastStatus,
  StreamSessionInitializationCode,
  StreamSessionInitializationResult,
} from "src/enums";
import { InitStreamResponseCode } from "src/features/broadcastExternal/common/enums";
import { broadcastExternalSelectors } from "src/features/broadcastExternal/state/selectors";
import { initOneToOneBroadcast } from "src/features/broadcastOneToOne/api/broadcastOneToOne";
import { StreamKind } from "src/types/richFragment/Stream";
import { prepareImageForUpload } from "src/utils/imageUtils";
import {
  getGiftsSoundMp4Enabled,
  getLivePartyInvitesEnabled,
  getLivePartyInvitesOnPublicBroadcastEnabled,
} from "state/abTests";
import {
  endSessionInitialization,
  leftViewerSession,
  resetViewerSession,
} from "state/actionCreators/viewerSession";
import {
  broadcastSelectors,
  connectionManagerSelectors,
  getRegion,
  navigationSelectors,
} from "state/selectors";
import { broadcastActionCreators } from "state/tree/broadcast";
import logger from "utils/logger";

export async function initStreamStrategy({
  broadcastKind,
  broadcastTitle,
  broadcastSource,
  gift,
  landscape,
  broadcastPictureUrl,
  username,
  broadcastRecipientId,
  settings,
  region,
  isGatewayEndpointsEnabled,
}) {
  if (isGatewayEndpointsEnabled) {
    return initStreamV2({
      conversationId: broadcastRecipientId,
      dontInviteToLiveParty: broadcastKind === StreamKind.CHAT,
      landscape,
      multiBattleSupport: broadcastKind !== StreamKind.CHAT,
      region,
      settings,
      source: broadcastSource,
      thumbnail: broadcastPictureUrl,
      title: broadcastTitle,
      type: broadcastKind,
      username,
      gift,
    });
  }

  if (broadcastKind === StreamKind.CHAT) {
    return initOneToOneBroadcast({
      username,
      settings,
      recipientId: broadcastRecipientId,
      source: broadcastSource,
      region,
    });
  }

  return initStream({
    title: broadcastTitle,
    source: broadcastSource,
    type: broadcastKind,
    settings,
    gift,
    landscape,
    thumbnail: broadcastPictureUrl,
  });
}

export const publishBroadcastAfterInit =
  (result, onFallback) => async (dispatch) => {
    if (
      result.code === StreamSessionInitializationCode.SUCCESS ||
      result.code === InitStreamResponseCode.SUCCESS
    ) {
      return batch(() => {
        dispatch(resetViewerSession(result.encryptedStreamId));
        dispatch(
          endSessionInitialization(
            result.encryptedStreamId,
            StreamSessionInitializationResult.SUCCESS,
            BroadcastSource.BROADCASTING
          )
        );
        dispatch(
          broadcastActionCreators.broadcastInitSucceeded({
            broadcastId: result.encryptedStreamId,
            broadcastKey: result.key,
          })
        );
      });
    }
    if (
      result.code === StreamSessionInitializationCode.EXISTING_LIVE_STREAM ||
      result.code === InitStreamResponseCode.EXISTING_LIVE_STREAM
    ) {
      await terminateStream({ streamId: result.encryptedStreamId });

      return onFallback();
    }
    if (result.userBanDuration) {
      return dispatch(
        broadcastActionCreators.broadcastInitUserBan(result.userBanDuration)
      );
    }

    return dispatch(
      broadcastActionCreators.broadcastInitFailed(
        new Error(`Unknown result code: ${result.code}`)
      )
    );
  };

export const checkStreamRestorationStatus = async ({
  broadcastId,
  broadcastKind,
  broadcastKey,
  currentBroadcastKind,
}) => {
  if (!broadcastKey || !broadcastId || broadcastKind !== currentBroadcastKind) {
    return false;
  }

  try {
    const statusObj = await getStreamStatus({
      streamId: broadcastId,
    });

    return (
      statusObj.code === InitStreamResponseCode.SUCCESS &&
      statusObj.status === StreamStatusIntCode.SUSPENDED
    );
  } catch (error) {
    logger.error(
      `broadcast init flow checkStreamRestorationStatus error`,
      error
    );

    return false;
  }
};

export const broadcastInit = (props) => async (dispatch, getState) => {
  const {
    broadcastSource,
    broadcastKind,
    gift,
    landscape,
    defaultBroadcastTitle,
  } = props;
  const state = getState();
  const broadcastPictureUrl = broadcastSelectors.broadcastPictureUrl(state);
  const username = connectionManagerSelectors.getUsername(state);
  const region = getRegion(state);

  const persistedBroadcastId = broadcastSelectors.broadcastId(state);
  const persistedBroadcastKind = broadcastSelectors.broadcastKind(state);
  const broadcastKey = broadcastSelectors.broadcastKey(state);
  const imageIsOk = await checkImage(broadcastPictureUrl);
  if (!imageIsOk) {
    dispatch(broadcastActionCreators.broadcastBadPicture());

    return;
  }
  AnalyticsUtils.updateInteractionId();
  dispatch(
    broadcastActionCreators.broadcastInitStarted({
      broadcastKind,
      broadcastSource,
    })
  );
  const broadcastTitle = broadcastSelectors.broadcastTitle(state);
  const broadcastRecipientId = broadcastSelectors.broadcastRecipientId(state);
  const isGiftSoundsEnabled = getGiftsSoundMp4Enabled(state);
  const isGatewayEndpointsEnabled = isBroadcastGatewayEndpointsEnabled(state);

  const parsedTitle = broadcastTitle || defaultBroadcastTitle;

  const settings = {
    audioEnabled: broadcastSelectors.getBroadcastAudioEnabled(state),
    videoEnabled:
      broadcastSelectors.isBroadcastScreenShareEnabled(state) ||
      broadcastSelectors.getBroadcastVideoEnabled(state),
    ...(isGiftSoundsEnabled && {
      muteGiftSound: broadcastSelectors.getBroadcastGiftSoundsMuted(state),
    }),
  };

  try {
    await setStreamStartNotification({ message: parsedTitle });
  } catch (error) {
    logger.error("Failed to set stream start notification", error);
  }

  const fallbackHandler = () => dispatch(broadcastInit(props));

  try {
    let isRestorable = false;

    if (broadcastKind !== StreamKind.CHAT) {
      isRestorable = await checkStreamRestorationStatus({
        broadcastId: persistedBroadcastId,
        broadcastKey,
        broadcastKind: persistedBroadcastKind,
        currentBroadcastKind: broadcastKind,
      });
    }

    if (isRestorable) {
      return dispatch(
        publishBroadcastAfterInit(
          {
            code: StreamSessionInitializationCode.SUCCESS,
            encryptedStreamId: persistedBroadcastId,
            key: broadcastKey,
          },
          fallbackHandler
        )
      );
    }

    const result = await initStreamStrategy({
      broadcastKind,
      broadcastTitle: parsedTitle,
      broadcastSource,
      gift,
      landscape,
      broadcastPictureUrl,
      username,
      settings,
      broadcastRecipientId,
      region,
      isGatewayEndpointsEnabled,
    });

    dispatch(broadcastActionCreators.broadcastUpdateGift(gift));

    return dispatch(publishBroadcastAfterInit(result, fallbackHandler));
  } catch (error) {
    return dispatch(broadcastActionCreators.broadcastInitFailed(error));
  }
};

export const broadcastTerminate = () => async (dispatch, getState) => {
  const broadcastId = broadcastSelectors.broadcastId(getState());
  if (!broadcastId) {
    // No broadcast to terminate, act like terminate succeeded for now
    dispatch(broadcastActionCreators.broadcastTerminateSucceeded());

    return;
  }
  AnalyticsUtils.updateInteractionId();
  dispatch(broadcastActionCreators.broadcastTerminateStarted());
  try {
    await terminateStream({ streamId: broadcastId });
    batch(() => {
      dispatch(leftViewerSession(broadcastId));
      dispatch(broadcastActionCreators.broadcastTerminateSucceeded());
    });
  } catch (error) {
    dispatch(broadcastActionCreators.broadcastTerminateFailed(error));
  }
};

export const manageMultiBroadcastInvite = (payload) => (dispatch, getState) => {
  const state = getState();

  const isLivePartyInvitesOnObsBroadcastEnabled =
    getLivePartyInvitesEnabled(state);
  const isLivePartyInvitesOnPublicBroadcastEnabled =
    getLivePartyInvitesOnPublicBroadcastEnabled(state);

  if (
    !isLivePartyInvitesOnObsBroadcastEnabled &&
    !isLivePartyInvitesOnPublicBroadcastEnabled
  ) {
    return;
  }

  const broadcastExternalStatus =
    broadcastExternalSelectors.getBroadcastExternalStatus(state);
  const broadcastId = broadcastSelectors.broadcastId(state);
  const broadcastKind = broadcastSelectors.broadcastKind(state);
  const broadcastStatus = broadcastSelectors.broadcastStatus(state);
  const currentRoute = navigationSelectors.getCurrentRoute(state);

  emitMultiStreamInvitationReceived(payload, {
    broadcastExternalStatus,
    broadcastId,
    broadcastKind,
    broadcastStatus,
    currentRoute,
  });

  dispatch(broadcastActionCreators.broadcastGetInvite(payload));
};

export const broadcastPictureUpload =
  (newPicture) => async (dispatch, getState) => {
    const state = getState();
    const prevBroadcastPictureUrl =
      broadcastSelectors.broadcastPictureUrl(state);
    const prevBroadcastPictureThumbnailUrl =
      broadcastSelectors.broadcastPictureThumbnailUrl(state);
    const pictureUrl = URL.createObjectURL(newPicture);
    dispatch(
      broadcastActionCreators.broadcastPictureUploadStarted({
        broadcastPictureUrl: pictureUrl,
        broadcastPictureThumbnailUrl: pictureUrl,
      })
    );
    try {
      const nextPicturePreparedForUpload =
        await prepareImageForUpload(pictureUrl);
      const {
        url: nextBroadcastPictureUrl,
        thumbnailUrl: nextBroadcastPictureThumbnailUrl,
      } = await uploadImage(nextPicturePreparedForUpload);

      return dispatch(
        broadcastActionCreators.broadcastPictureUploadSucceeded({
          broadcastPictureUrl: nextBroadcastPictureUrl,
          broadcastPictureThumbnailUrl: nextBroadcastPictureThumbnailUrl,
        })
      );
    } catch (error) {
      return dispatch(
        broadcastActionCreators.broadcastPictureUploadFailed(error, {
          prevBroadcastPictureUrl,
          prevBroadcastPictureThumbnailUrl,
        })
      );
    } finally {
      URL.revokeObjectURL(pictureUrl);
    }
  };

export const markPipAsStartedPlayingForBroadcaster =
  (streamId) => async (dispatch, getState) => {
    const state = getState();

    const myBroadcastId = broadcastSelectors.broadcastId(state);
    const myBroadcastStatus = broadcastSelectors.broadcastStatus(state);
    const startedPlayingPipsIds =
      broadcastSelectors.getStartedPlayingPipsIds(state);

    if (
      !myBroadcastId ||
      myBroadcastStatus === BroadcastStatus.TERMINATE_SUCCEEDED ||
      startedPlayingPipsIds.includes(streamId)
    ) {
      return;
    }

    dispatch(broadcastActionCreators.markPipAsStartedPlaying(streamId));
  };
