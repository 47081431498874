import {
  fetchVisitorProfileByAccountId,
  fetchVisitorProfileByNickname,
  fetchVisitorProfileFeedByAccountId,
  fetchVisitorProfileStreamByAccountId,
  fetchVisitorProfileVipConfigById,
} from "src/features/profile/visitorProfile/api/visitorProfileApi";
import {
  getAssetsForVipConfigs,
  isApiError,
} from "src/features/profile/visitorProfile/imports";
import {
  ApplicationThunk,
  HTTP_CODE_NOT_FOUND,
} from "src/features/profile/visitorProfile/imports/types";
import {
  addVisitorAccountByAlias,
  addVisitorAccountIdLoadedWithErrors,
  addVisitorProfile,
  addVisitorProfileById,
  addVisitorProfileFeedComplete,
  addVisitorProfileFeedStart,
  addVisitorProfileStreamId,
  addVisitorProfileVipConfig,
} from "src/features/profile/visitorProfile/state/slice";
import {
  getVisitorProfileFeedEnabled,
  getVisitorProfileStreamBadgeEnabled,
  getVisitorProfileVipStatusEnabled,
} from "state/abTests";

export const loadVisitorProfileVipConfig =
  (vipConfigId: string): ApplicationThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const isVisitorProfileVipStatusEnabled =
        getVisitorProfileVipStatusEnabled(state);

      if (isVisitorProfileVipStatusEnabled) {
        const vipConfig = await fetchVisitorProfileVipConfigById(vipConfigId);
        const assetConfigs = await getAssetsForVipConfigs([vipConfig]);

        dispatch(
          addVisitorProfileVipConfig({
            ...vipConfig,
            assetConfig: assetConfigs.find(
              ({ name }) => name === vipConfig.label
            )!,
          })
        );
      }
    } catch (error) {}
  };

export const loadVisitorProfile =
  (accountId: string): ApplicationThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const isVisitorProfileFeedEnabled = getVisitorProfileFeedEnabled(state);

      const profile = await fetchVisitorProfileByAccountId(accountId);

      dispatch(addVisitorProfile(profile));

      if (isVisitorProfileFeedEnabled) {
        dispatch(addVisitorProfileFeedStart());

        const feed = await fetchVisitorProfileFeedByAccountId(accountId);

        dispatch(addVisitorProfileFeedComplete(feed));
      }

      dispatch(loadVisitorProfileVipConfig(profile.live.vipConfigId));
    } catch (error) {
      if (isApiError(error) && error.status === HTTP_CODE_NOT_FOUND) {
        dispatch(addVisitorAccountIdLoadedWithErrors());
      }
    }
  };

export const loadVisitorProfileStream =
  (accountId: string): ApplicationThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const isVisitorProfileStreamBadgeEnabled =
        getVisitorProfileStreamBadgeEnabled(state);

      if (isVisitorProfileStreamBadgeEnabled) {
        const streamId = await fetchVisitorProfileStreamByAccountId(accountId);

        dispatch(addVisitorProfileStreamId({ streamId }));
      }
    } catch (error) {}
  };

export const loadVisitorAccountIdByAlias =
  (nickname: string): ApplicationThunk =>
  async (dispatch) => {
    try {
      const accountId = await fetchVisitorProfileByNickname(nickname);

      if (!accountId) {
        dispatch(addVisitorAccountIdLoadedWithErrors());

        return;
      }
      dispatch(addVisitorAccountByAlias({ accountId }));
    } catch (error) {
      dispatch(addVisitorAccountIdLoadedWithErrors());
    }
  };

export const loadVisitorProfileById =
  (accountId: string): ApplicationThunk =>
  async (dispatch) => {
    try {
      const profile = await fetchVisitorProfileByAccountId(accountId);

      dispatch(addVisitorProfileById({ accountId, profile }));
    } catch (error) {}
  };
