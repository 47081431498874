import { shallowEqual, useSelector } from "react-redux";
import {
  Breakpoints,
  DeviceType,
} from "src/features/broadcastExternal/imports/enums";
import { useBreakpointPrecise } from "src/features/broadcastExternal/imports/hooks";
import {
  deviceInfoSelectors,
  getBroadcastOBSEnabled,
} from "src/features/broadcastExternal/imports/state";
import { RootState } from "src/features/broadcastExternal/imports/types";

const selector = (state: RootState) => ({
  deviceType: deviceInfoSelectors.getDeviceType(state),
  isBroadcastOBSEnabled: getBroadcastOBSEnabled(state),
});

export const useBroadcastOBSEnabled = () => {
  const breakpoint = useBreakpointPrecise();

  const { deviceType, isBroadcastOBSEnabled } = useSelector(
    selector,
    shallowEqual
  );

  return (
    isBroadcastOBSEnabled &&
    deviceType === DeviceType.DESKTOP &&
    breakpoint === Breakpoints.DESKTOP
  );
};
