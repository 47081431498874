import logger from "src/utils/logger";

interface IndexedDBStorageOptions {
  fallbackStorage: Storage | Storage[];
  onError?: (error: Error) => void;
}

export class IndexedDBStorage {
  private db: IDBDatabase | null = null;
  private dbName: string;
  private fallbackStorage: Storage | Storage[];
  private onError?: (error: Error) => void;
  private storeName: string;

  constructor(
    dbName: string,
    storeName: string,
    options: IndexedDBStorageOptions
  ) {
    this.dbName = dbName;
    this.storeName = storeName;

    this.onError = options.onError || logger.error;
    this.fallbackStorage = options.fallbackStorage;
  }

  private callOnError(error: Error | null) {
    if (error) {
      this.onError?.(error);
    }
  }

  private getAvailableFallbackStorage(value?: string): Storage {
    if (Array.isArray(this.fallbackStorage)) {
      for (const storage of this.fallbackStorage) {
        try {
          const testValue = value || "storage_verification_value";
          const testKey = "storage_verification_key";

          storage.setItem(testKey, testValue);

          if (storage.getItem(testKey) !== testValue) {
            const error = new Error("Storage verification failed");
            this.callOnError(error);
            throw error;
          }

          storage.removeItem(testKey);

          return storage;
        } catch (error) {
          this.callOnError(error as Error);
        }
      }
      const error = new Error("No available fallback storage");
      this.callOnError(error);
      throw error;
    }

    return this.fallbackStorage;
  }

  private getObjectStore(mode: IDBTransactionMode): IDBObjectStore {
    if (!this.db) {
      const error = new Error("Database not initialized");
      this.callOnError(error);
      throw error;
    }

    return this.db
      .transaction(this.storeName, mode)
      .objectStore(this.storeName);
  }

  async clear(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const store = this.getObjectStore("readwrite");
        const request = store.clear();

        request.onsuccess = () => resolve();

        request.onerror = () => {
          this.callOnError(request.error);
          reject(request.error);
        };
      } catch (error) {
        this.callOnError(error as Error);
        this.getAvailableFallbackStorage().clear();
        resolve();
      }
    });
  }

  async getItem(key: string): Promise<null | string> {
    return new Promise((resolve, reject) => {
      try {
        const store = this.getObjectStore("readonly");
        const request = store.get(key);

        request.onsuccess = () => resolve(request.result || null);

        request.onerror = () => {
          this.callOnError(request.error);
          reject(request.error);
        };
      } catch (error) {
        this.callOnError(error as Error);
        resolve(this.getAvailableFallbackStorage(key).getItem(key));
      }
    });
  }

  async init(version: number = 1): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, version);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName);
        }
      };

      request.onsuccess = () => {
        this.db = request.result;
        resolve();
      };

      request.onerror = () => {
        this.callOnError(request.error);
        reject(request.error);
      };
    });
  }

  async length(): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        const store = this.getObjectStore("readonly");
        const request = store.count();

        request.onsuccess = () => resolve(request.result);

        request.onerror = () => {
          this.callOnError(request.error);
          reject(request.error);
        };
      } catch (error) {
        this.callOnError(error as Error);
        resolve(this.getAvailableFallbackStorage().length);
      }
    });
  }

  async removeItem(key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const store = this.getObjectStore("readwrite");
        const request = store.delete(key);

        request.onsuccess = () => resolve();

        request.onerror = () => {
          this.callOnError(request.error);
          reject(request.error);
        };
      } catch (error) {
        this.callOnError(error as Error);
        this.getAvailableFallbackStorage(key).removeItem(key);
        resolve();
      }
    });
  }

  async setItem(key: string, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const store = this.getObjectStore("readwrite");
        const request = store.put(value, key);

        request.onsuccess = () => resolve();

        request.onerror = () => {
          this.callOnError(request.error);
          reject(request.error);
        };
      } catch (error) {
        this.callOnError(error as Error);
        this.getAvailableFallbackStorage(value).setItem(key, value);
        resolve();
      }
    });
  }
}
