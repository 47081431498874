import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { emitChatMenuOptionClicked } from "chat/analytics/emitChatMenuOptionClicked";
import MessageMenuOptionBase from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuOptionBase";
import { CHAT_POPOVER_OPTIONS } from "chat/constants/chatMenuOptionsConfig";
import { UIActionMenuTarget } from "chat/enums";
import { StoredConversation, muteConversation } from "chat/state";

export interface MuteOptionProps {
  conversation: StoredConversation;
}

const MuteOption: FC<MuteOptionProps> = ({ conversation }) => {
  const dispatch = useDispatch();
  const isMuted = !!conversation.muted_message_ts;
  const onMuteHandler = () => {
    const target = isMuted
      ? UIActionMenuTarget.UNMUTE_CHAT
      : UIActionMenuTarget.MUTE_CHAT;

    dispatch(
      muteConversation({
        conversationId: conversation.conversation_id,
        mute: !isMuted,
      })
    );

    emitChatMenuOptionClicked({
      target,
      chatId: conversation.conversation_id,
      isFamily: !!conversation.group_info?.familyId,
    });
  };

  return (
    <MessageMenuOptionBase
      option={isMuted ? CHAT_POPOVER_OPTIONS.unmute : CHAT_POPOVER_OPTIONS.mute}
      onClick={onMuteHandler}
    />
  );
};

export default MuteOption;
