import { AccountType } from "src/types/richFragment/Account";
import { CountryType } from "src/types/richFragment/Country";
import { Anchor } from "src/types/streams/CategoryInfo";

export enum StreamStatus {
  EXPIRED = "EXPIRED",
  INITED = "INITED",
  LIVING = "LIVING",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
  UNKNOWN = "UNKNOWN",
}
export enum StreamKind {
  CHAT = "CHAT",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  TICKET_PRIVATE = "TICKET_PRIVATE",
}

export enum StreamProtocol {
  RTMP = "RTMP",
  SRT = "SRT",
}

export type HlsStreamInfo = {
  hdUrl: string;
  ldUrl: string;
  masterUrl: string;
  previewUrl: string;
  sdUrl: string;
};

export type SrtEdge = {
  ip: string;
  port: number;
};

export type SrtRegionInfo = {
  edges: SrtEdge[];
  region: string;
};

export type SrtStreamInfo = {
  regions: SrtRegionInfo[];
};
export type RtmpStreamInfo = {
  url: string;
};

export type WebRtcStreamInfo = {
  enabled: boolean;
  pttActive?: boolean;
};

export type StreamType = {
  completeListUrl?: string;
  duration: number;
  egressUrl?: string;
  encryptedAccountId: string;
  id: string;
  landscape?: boolean;
  liveListUrl?: string;
  masterListUrl?: string;
  previewListUrl?: string;
  status?: StreamStatus;
  streamKind?: StreamKind;
  streamProtocol?: StreamProtocol;
  thumbnail?: string;
  thumbnailHeight?: number;
  thumbnailWidth?: number;
  title?: string;
};

export type RecordedVideoViewInfo = {
  masterUrl?: string;
  previewUrl?: string;
};

export type RelatedStream = {
  thumbnail: string;
};

export type StreamSubscriptionFee = {
  level: number;
};

export type StreamGiftFee = {
  abonnement: { amount: number };
  ticketPriceInCredits: number;
};

export type StreamAccessInitRestriction = {
  gift: StreamGiftFee;
  subscription: StreamSubscriptionFee;
};

export type StreamInitRestriction = {
  and?: StreamAccessInitRestriction;
  gift?: StreamGiftFee;
  or?: StreamAccessInitRestriction;
  subscription?: StreamSubscriptionFee;
};

export type StreamRestrictions = {
  init?: StreamInitRestriction;
  remain?: StreamInitRestriction;
};

export type StreamSettings = {
  audioEnabled: boolean;
  incognitoEnabled?: boolean;
  muteGiftSound?: boolean;
  videoEnabled: boolean;
};

export type StreamViewInfo = {
  hlsStreamInfo?: HlsStreamInfo;
  rtmpStreamInfo?: RtmpStreamInfo;
  srtStreamInfo?: SrtStreamInfo;
  streamId: string;
  webRtcStreamInfo?: WebRtcStreamInfo;
};

export type StreamDetailType = {
  anchor?: AccountType;
  anchorPoints?: number;
  approved?: boolean;
  country?: CountryType;
  featured?: boolean;
  hashtags?: string[];
  isPublic?: boolean;
  landscape?: boolean;
  likeCount?: number;
  moderationLevel?: number;
  postId?: number;
  pttActive?: boolean;
  recordedVideoViewInfo?: RecordedVideoViewInfo;
  redirectStreamId?: string;
  relatedStreams?: RelatedStream[];
  restrictions?: StreamRestrictions;
  settings?: StreamSettings;
  showAsShutdownable?: boolean;
  source?: string;
  stream?: StreamType;
  streamerVector?: number[];
  subscriberCount?: number;
  tags?: string[];
  ticketPrice?: number;
  totalPointsInStream?: number;
  uniqueViewerCount?: number;
  viewInfo?: StreamViewInfo;
  viewerCount?: number;
};

export type BasicStreamInfo = {
  anchor?: Anchor;
  anchorPoints?: number;
  broadcasterId: string;
  hashtags: string[];
  id: string;
  kind: StreamKind;
  landscape: boolean;
  moderationLevel?: number;
  playlistUrl: string;
  relatedStreams: RelatedStream[];
  restrictions: StreamRestrictions;
  startTime: number;
  status: StreamStatus;
  tags: string[];
  thumbnail: string;
  ticketPrice: number;
  title: string;
  viewerCount: number;
};

export type ExtendedStreamInfo = {
  liveListUrl?: string;
  previewListUrl?: string;
} & BasicStreamInfo;
