import { BlockedUsersState } from "src/state/tree/blockedUsers";
import { generateAsyncSelectors } from "state/hor/addFetcherActionsToBuilder";

const { data, meta } = generateAsyncSelectors<BlockedUsersState>();

export default {
  data,
  meta,
  getAllBlockedUsers: (state: BlockedUsersState) => data(state).accountIds,
  isBlockedUser: (state: BlockedUsersState, accountId?: string) => {
    if (!accountId) {
      return false;
    }

    return data(state).accountIds.includes(accountId);
  },
};
