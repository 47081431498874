import { MessageDescriptor, defineMessages } from "react-intl";
export const broadcastMasksMessages: Record<string, MessageDescriptor> =
  defineMessages({
    lipstick: {
      id: "makeup_lipstick",
      defaultMessage: "Lipstick",
    },
    blush: {
      id: "makeup_blush",
      defaultMessage: "Blush",
    },
    eyebrow: {
      id: "makeup_eyebrow",
      defaultMessage: "Eyebrow",
    },
    eyeshadow: {
      id: "makeup_eyeshadow",
      defaultMessage: "Eyeshadow",
    },
    eyeliner: {
      id: "makeup_eyeliner",
      defaultMessage: "Eyeliner",
    },
    eyelash: {
      id: "makeup_eyelash",
      defaultMessage: "Eyelash",
    },
    lens: {
      id: "makeup_contactlens",
      defaultMessage: "Lens",
    },
    masks: {
      id: "live_filters_settings_tab_masks_title",
      defaultMessage: "Masks",
    },
    beauty: {
      id: "live_filters_settings_tab_beauty_title",
      defaultMessage: "Beauty",
    },
    makeup: {
      id: "live_filters_settings_tab_makeup_title",
      defaultMessage: "Makeup",
    },
    fineSmooth: {
      id: "filter_fine_smooth",
      defaultMessage: "Fine Smooth",
    },
    toothWhiten: {
      id: "filter_tooth_whiten",
      defaultMessage: "Tooth whiten",
    },
    whiten: {
      id: "filter_whiten",
      defaultMessage: "Whiten",
    },
    ruddy: {
      id: "filter_rudy",
      defaultMessage: "Ruddy",
    },
    vFace: {
      id: "filter_v_face",
      defaultMessage: "V Face",
    },
    cheekThin: {
      id: "filter_cheek_thin",
      defaultMessage: "Cheek Thin",
    },
    cheekSmall: {
      id: "filter_cheek_small",
      defaultMessage: "Cheek Small",
    },
    cheekNarrow: {
      id: "filter_cheek_narrow",
      defaultMessage: "Cheek Narrow",
    },
    nose: {
      id: "filter_nose",
      defaultMessage: "Nose",
    },
    eyeEnlarge: {
      id: "filter_eye_enlarge",
      defaultMessage: "Eye Enlarge",
    },
    eyeDistance: {
      id: "filter_eye_distance",
      defaultMessage: "Eye Distance",
    },
    mouth: {
      id: "filter_mouth",
      defaultMessage: "Mouth",
    },
    removePouchStrength: {
      id: "filter_remove_pouch_strength",
      defaultMessage: "Remove Pouch Strength",
    },
    resetSettingsDialog: {
      id: "reset_settings_dialog",
      defaultMessage: "Reset Settings?",
    },
    reset: {
      id: "reset_button",
      defaultMessage: "Reset",
    },
    keep: {
      id: "keep_button",
      defaultMessage: "Keep",
    },
    masks_category_ears: {
      id: "masks_category_ears",
      defaultMessage: "Ears",
    },
    masks_category_cute: {
      id: "masks_category_cute",
      defaultMessage: "Cute",
    },
    masks_category_avatars: {
      id: "masks_category_avatars",
      defaultMessage: "Avatars",
    },
    masks_category_characters: {
      id: "masks_category_characters",
      defaultMessage: "Characters",
    },
    masks_category_hats: {
      id: "masks_category_hats",
      defaultMessage: "Hats",
    },
    masks_category_others: {
      id: "masks_category_others",
      defaultMessage: "Others",
    },
    apply_mask: {
      id: "apply_mask",
      defaultMessage: "Apply",
    },
    applied_mask: {
      id: "applied_mask",
      defaultMessage: "Applied",
    },
  });
