import { parseBooleanEnabledSOC } from "src/features/broadcastOneToOne/imports/state";

export const isBroadcastOneToOneEnabled = parseBooleanEnabledSOC(
  "web.broadcast.one.to.one.enabled",
  "0"
);

export const isBroadcastOneToOneInstreamEnabled = parseBooleanEnabledSOC(
  "web.broadcast.one.to.one.instream.enabled",
  "0"
);
