import { sendMessage } from "chat/api/chat";
import { ApiMessageType } from "chat/enums";
import { MessageType } from "chat/types";

export const sendSaveMediaMessage = async (
  messageType: MessageType,
  chatId?: string
) => {
  if (!chatId) {
    return;
  }

  const message_type =
    messageType === MessageType.IMAGE_MESSAGE
      ? ApiMessageType.PHOTO_SAVED_INFO_MESSAGE
      : ApiMessageType.VIDEO_SAVED_INFO_MESSAGE;

  await sendMessage({
    messages: [{ message_type }],
    to: [chatId],
  });
};
