import { defineMessages } from "react-intl";
import { SECOND } from "src/constants";
import {
  LoginModalTitleType,
  ToastType,
} from "src/features/signin/imports/enums";
import { sharedMessages } from "src/features/signin/imports/ui";
import { LoginModalMessages } from "src/features/signin/modal/enums";

export const titleMessages: LoginModalMessages = defineMessages({
  [LoginModalTitleType.REGULAR]: {
    id: "modal.login.title.sign.up",
    defaultMessage: "Welcome to Tango!",
  },
  [LoginModalTitleType.DO_MORE_WITH_TANGO]: {
    id: "modal.login.title.do.more.with.tango",
    defaultMessage: "Do more with Tango",
  },
  [LoginModalTitleType.BROADCAST]: {
    id: "modal.login.title.broadcast",
    defaultMessage: "Sign up to broadcast",
  },
  [LoginModalTitleType.REPORT]: {
    id: "modal.login.title.report",
    defaultMessage: "Sign up to report",
  },
  [LoginModalTitleType.SEND_MESSAGES]: {
    id: "modal.login.title.send.messages",
    defaultMessage: "Sign up to send messages",
  },
  [LoginModalTitleType.LOGIN_OR_SIGNUP]: {
    id: "modal.login.title.login.or.signup",
    defaultMessage: "Log in or Sign up",
  },
});

export const descriptionMessages: LoginModalMessages = defineMessages({
  [LoginModalTitleType.DO_MORE_WITH_TANGO]: {
    id: "modal.login.description.get.full.experience",
    defaultMessage: "Sign up to get full experience",
  },
  [LoginModalTitleType.BROADCAST]: {
    id: "modal.login.description.join.community",
    defaultMessage: "Join a worldwide live-streaming community",
  },
  [LoginModalTitleType.REPORT]: {
    id: "modal.login.description.report",
    defaultMessage: "Only registered users can report issues",
  },
  [LoginModalTitleType.SEND_MESSAGES]: {
    id: "modal.login.description.send.messages",
    defaultMessage: "Interact with your favourite streamers",
  },
  [LoginModalTitleType.LOGIN_OR_SIGNUP]: {
    id: "modal.login.description.join.community",
    defaultMessage: "Join a worldwide live-streaming community",
  },
});

export const appleLoginErrorToast = {
  id: "notifyAppleLoginError",
  type: ToastType.HEADLINE,
  headline: sharedMessages.registrationFailed,
  title: sharedMessages.appleSignInFailed,
  duration: 8 * SECOND,
};
