import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SocialGamesResponseCode } from "src/features/socialGames/common/enums";
import {
  SocialGamesInStreamListResponse,
  SocialGamesListGameDto,
} from "src/features/socialGames/common/types";
import { Nullable } from "src/features/socialGames/imports/types";

export interface SocialGamesFreeSpinsState {
  error: Nullable<string>;
  games: SocialGamesListGameDto[];
  loading: boolean;
  responseCode: Nullable<SocialGamesResponseCode>;
}

const initialState: SocialGamesFreeSpinsState = {
  error: null,
  games: [],
  loading: false,
  responseCode: null,
};

const slice = createSlice({
  name: "socialGamesFreeSpins",
  initialState,
  reducers: {
    loadSocialGamesFreespinsStart(state) {
      state.loading = true;
      state.responseCode = null;
      state.error = null;
    },
    loadSocialGamesFreespinsComplete(
      state,
      action: PayloadAction<SocialGamesInStreamListResponse>
    ) {
      state.loading = false;
      state.games = action.payload.games;
      state.responseCode = action.payload.responseCode;
    },
    loadSocialGamesFreespinsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadSocialGamesFreespinsStart,
  loadSocialGamesFreespinsComplete,
  loadSocialGamesFreespinsFailure,
} = slice.actions;

export const socialGamesFreespinsReducer = slice.reducer;
