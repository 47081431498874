import { createAsyncThunk } from "@reduxjs/toolkit";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import { fetchBannerConfigs } from "api/bannerConfigs";
import { isApiError } from "api/utils/enhancedFetch";
import { BannerConfigsState, BannerType } from "src/types/banner";
import { RootState } from "state/delegate";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { bannerConfigsSelectors, userSelectors } from "state/selectors";

export const getInitialData = (): BannerConfigsState["data"] => ({
  [BannerType.WEB_BANNER]: [],
  [BannerType.CASHIER_NEW]: [],
  [BannerType.REFILL]: [],
  [BannerType.FOLLOWING]: [],
  [BannerType.CHATS]: [],
  [BannerType.LIVE_GRID]: [],
});

const actions = {
  fetchBannerConfigs: createAsyncThunk<
    Data<BannerConfigsState>,
    FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/bannerConfigs/fetch",
    async (args, api) => {
      try {
        const { banners } = await fetchBannerConfigs();

        const accountId = userSelectors.getMyAccountId(api.getState());

        return banners
          ? [...banners]
              .sort((a, b) => b.startTs - a.startTs)
              .reduce((acc, banner) => {
                const { type } = banner.displaySection;

                if (!acc[type]) {
                  acc[type] = [];
                }

                acc[type].push(banner);

                emitEvent(EventNames.BANNER_RECEIVED, {
                  [EventFields.BANNER_TRACKING_ID]: banner.trackingId,
                  [EventFields.ITEM_TYPE]: banner.name,
                  [EventFields.ITEM_ID]: banner.id,
                  [EventFields.SHARE_SOURCE_ID]: banner.displaySection.type,
                  [EventFields.LOGGED_IN_ACCOUNT_ID]: accountId,
                });

                return acc;
              }, getInitialData())
          : getInitialData();
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();
        const meta = bannerConfigsSelectors.meta(state as RootState);

        return !meta.loading;
      },
    }
  ),
};

export default actions;
