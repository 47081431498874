import { Breakpoints } from "src/enums";
import { TYPOGRAPHY_TYPE } from "src/ui/common/typography/types";

export const lottieConfig = {
  path: "https://resources.tango.me/lottie_animations/streamgoal/data.json",
  loop: false,
  renderer: "canvas",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  autoplay: true,
} as const;

export const breakpointToTitleTypography = {
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.HEADLINE5,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.HEADLINE5,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE4,
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HEADLINE4,
};

export const breakpointToProgressTypography = {
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.MINI,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.MINI,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE6,
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HEADLINE6,
};

export const breakpointToActionWheelTypography = {
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.HEADLINE7,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.HEADLINE7,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE6,
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HEADLINE5,
};
