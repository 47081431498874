import { BroadcastStatus } from "src/enums";
import { BroadcastExternalStatus } from "src/features/broadcastExternal/exports/enums";
import { broadcastExternalSelectors } from "src/features/broadcastExternal/exports/state";
import { StreamKind as BroadcastKind } from "src/types/richFragment/Stream";
import { broadcastSelectors } from "state/selectors";

export const isActiveExternalBroadcast = (
  broadcastExternalStatus: ReturnType<
    typeof broadcastExternalSelectors.getBroadcastExternalStatus
  >
) => broadcastExternalStatus === BroadcastExternalStatus.ACTIVE;

export const isActiveInternalBroadcast = (
  broadcastStatus: ReturnType<typeof broadcastSelectors.broadcastStatus>,
  broadcastExternalStatus: ReturnType<
    typeof broadcastExternalSelectors.getBroadcastExternalStatus
  >
) =>
  broadcastStatus === BroadcastStatus.INIT_SUCCEEDED &&
  broadcastExternalStatus === null;

export const isPublicBroadcast = (
  broadcastKind: ReturnType<typeof broadcastSelectors.broadcastKind>
) => broadcastKind === BroadcastKind.PUBLIC;
