import { getRecaptchaKeyId } from "src/features/signin/imports/environment";
import { Nullable } from "src/features/signin/imports/types";

export const generateRecaptchaToken = (): Promise<Nullable<string>> =>
  new Promise((resolve) => {
    if (!window.grecaptcha) {
      return resolve("");
    }

    try {
      window.grecaptcha.enterprise
        .execute(getRecaptchaKeyId(), {
          action: "SMS_SEND",
        })
        .then((token: string) => {
          resolve(token);
        })
        .catch(() => {
          resolve("");
        });
    } catch (error) {
      resolve("");
    }
  });
