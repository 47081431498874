import { useLayoutEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState, getMoodsV2ForChatEnabled } from "chat/imports/state";
import chatSelectors from "chat/state/selectors";

const selector = (state: RootState) => ({
  isMOSinChatEnabled: getMoodsV2ForChatEnabled(state),
  isMessageEditingActive: chatSelectors.getEditingMessageInProgress(state),
});

export const useCalculateBottomOffset = (isPopoverOpen: boolean) => {
  const [offset, setOffset] = useState(0);

  const { isMOSinChatEnabled, isMessageEditingActive } = useSelector(
    selector,
    shallowEqual
  );

  useLayoutEffect(() => {
    const MOS_HEIGHT = isMOSinChatEnabled ? 64 : 0;
    const CONVERSATION_BAR_HEIGHT = 105;
    const MARGIN = 5;
    const MESSAGE_EDITING_PANEL_HEIGHT = isMessageEditingActive ? 55 : 0;
    const BOTTOM_OFFSET =
      CONVERSATION_BAR_HEIGHT +
      MOS_HEIGHT +
      MARGIN +
      MESSAGE_EDITING_PANEL_HEIGHT;

    setOffset(BOTTOM_OFFSET);
  }, [isMOSinChatEnabled, isMessageEditingActive, isPopoverOpen]);

  return offset;
};
