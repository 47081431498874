import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { DeviceType } from "src/features/signin/imports/enums";
import {
  RootState,
  deviceInfoSelectors,
  getPwaGoogleLoginTrickEnabled,
  loginSelectors,
} from "src/features/signin/imports/state";
import { isStandalone } from "src/features/signin/imports/utils";

const FOCUS_TIMEOUT_DELAY = 500;
const TARGET_GOOGLE_LOGIN_POPUP = "g_auth_code_window";

const pwaGoogleLoginControllerSelector = (state: RootState) => ({
  deviceType: deviceInfoSelectors.getDeviceType(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  pwaGoogleLoginTrickEnabled: getPwaGoogleLoginTrickEnabled(state),
});

export const PwaGoogleLoginController = () => {
  const { deviceType, isLoggedIn, pwaGoogleLoginTrickEnabled } = useSelector(
    pwaGoogleLoginControllerSelector
  );

  const shouldApplyTrick = useMemo(
    () =>
      pwaGoogleLoginTrickEnabled &&
      isStandalone &&
      deviceType !== DeviceType.DESKTOP &&
      !isLoggedIn,
    [deviceType, isLoggedIn, pwaGoogleLoginTrickEnabled]
  );

  useEffect(() => {
    if (!shouldApplyTrick) {
      return;
    }

    const originWindowOpen = window.open;

    const openedWindows: ReturnType<typeof originWindowOpen>[] = [];

    const customWindowOpen = (...args: Parameters<typeof originWindowOpen>) => {
      const openedWindow = originWindowOpen(...args);

      const target = args[1];
      if (target === TARGET_GOOGLE_LOGIN_POPUP) {
        openedWindows.push(openedWindow);
      }

      return openedWindow;
    };

    let timerId: ReturnType<typeof setTimeout> | null = null;

    const blurHandler = () => {
      if (timerId) {
        clearTimeout(timerId);
        timerId = null;
      }
    };

    const focusHandler = () => {
      timerId = setTimeout(
        () =>
          openedWindows.forEach((openedWindow) => {
            if (!openedWindow?.closed) {
              openedWindow?.close();
            }
          }),
        FOCUS_TIMEOUT_DELAY
      );
    };

    window.open = customWindowOpen;
    window.addEventListener("blur", blurHandler);
    window.addEventListener("focus", focusHandler);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
      window.open = originWindowOpen;
      window.removeEventListener("blur", blurHandler);
      window.removeEventListener("focus", focusHandler);
    };
  }, [shouldApplyTrick]);

  return null;
};
