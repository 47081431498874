/* eslint-disable no-restricted-imports */
import { AcmeServiceIdentifier, AcmeServiceName } from "src/enums";
import { StreamKind } from "src/types/richFragment/Stream";
import STREAM_INIT_SUPPORTED_RESULTS from "src/utils/streamUtils";
import {
  ACME_RECEIVED,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  VIEWER_SESSION_CLOSE_PIP_PLAYER,
  VIEWER_SESSION_INITIALIZATION_END,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_UPDATE,
} from "state/actionTypes";
import { getStreamId } from "./commonSelectors";

const initialState = {
  broadcasterId: "",
  streamKind: "",
  subscriptionRequired: false,
  uniqueViewerCount: 0,
  points: 0,
  redirectStreamId: "",
  source: "",
  pipPlayerVisible: true,
  bonusPercentage: 0,
  moderationLevels: {},
};

const streamKindFromBool = (isPublic) =>
  isPublic ? StreamKind.PUBLIC : StreamKind.TICKET_PRIVATE;

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      if (action.payload === getStreamId(context)) {
        return { ...state, pipPlayerVisible: initialState.pipPlayerVisible };
      }

      return initialState;
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }
      const patch = {};

      if (action.payload.bonus) {
        const { bonusPercentage = 0 } = action.payload.bonus;
        patch.bonusPercentage = bonusPercentage;
      }

      if (!action.payload.detail) {
        return { ...state, ...patch };
      }

      const {
        detail: {
          anchor: { encryptedAccountId: broadcasterId },
          isPublic,
          uniqueViewerCount,
          totalPointsInStream: points,
          redirectStreamId,
          restrictions,
        },
      } = action.payload;

      return {
        ...state,
        ...patch,
        broadcasterId,
        streamKind: streamKindFromBool(isPublic),
        uniqueViewerCount,
        points,
        redirectStreamId,
        restrictions,
      };
    }
    case ACME_RECEIVED: {
      if (action.payload.serviceName === AcmeServiceName.STREAM) {
        const [eventName, , nextSessionId] =
          action.payload.serviceIdentifier.split(":");
        if (eventName === AcmeServiceIdentifier.STREAM_UPGRADE_TO_CHAT) {
          return {
            ...state,
            redirectStreamId: nextSessionId,
          };
        }

        return state;
      }

      return state;
    }
    case LIVE_RICH_NOTIFICATION_RECEIVED:
    case VIEWER_SESSION_UPDATE: {
      const {
        uniqueViewerCount,
        totalPointsInStream = 0,
        isPublic,
        bonusPercentage,
        moderationLevel,
      } = action.payload;
      const { broadcasterId } = action.meta;
      const fromNotification = action.type === LIVE_RICH_NOTIFICATION_RECEIVED;
      const patch = {};

      if (!fromNotification) {
        patch.broadcasterId = broadcasterId;
        patch.streamKind = streamKindFromBool(isPublic);
        patch.uniqueViewerCount = uniqueViewerCount;
      }

      if (bonusPercentage) {
        patch.bonusPercentage = bonusPercentage;
      }

      patch.points = Math.max(state.points, totalPointsInStream);

      return {
        ...state,
        ...patch,
        moderationLevels: {
          ...state.moderationLevels,
          [broadcasterId]: moderationLevel,
        },
      };
    }
    case VIEWER_SESSION_CLOSE_PIP_PLAYER: {
      return {
        ...state,
        pipPlayerVisible: false,
      };
    }
    case VIEWER_SESSION_INITIALIZATION_END: {
      const {
        payload: result,
        meta: { source },
      } = action;
      if (STREAM_INIT_SUPPORTED_RESULTS.includes(result)) {
        return { ...state, source };
      }
    }
  }

  return state;
};

export const selectors = {
  getBroadcasterId: (state) => state.broadcasterId,
  getUniqueViewersCount: (state) => state.uniqueViewerCount,
  getPointsGiftedInStream: (state) => state.points,
  isPublic: (state) => state.streamKind !== StreamKind.TICKET_PRIVATE, // defaults to public if unknown
  getStreamKind: (state) => state.streamKind,
  getRedirectStreamId: (state) => state.redirectStreamId,
  getStreamSource: (state) => state.source,
  isPipPlayerVisible: (state) => state.pipPlayerVisible,
  getBonusPercentage: (state) => state.bonusPercentage,
  getModerationLevelByAccountId: (state, accountId) =>
    state.moderationLevels[accountId],
};
