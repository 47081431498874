import React, { FC, MouseEventHandler, memo } from "react";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { OtherStreamsType } from "src/types/streams/Pips";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useLivePartyOtherStreamsData from "ui/scenes/stream/common/useLivePartyOtherStreamsData";
import { useModeratedOtherStreamsData } from "ui/scenes/stream/common/useModeratedOtherStreamsData";
import PictureInPicturePlayer from "ui/scenes/stream/streamPlayer/PictureInPicturePlayer";
import styles from "ui/scenes/stream/LivingStreamLayout.scss";

interface MemoizedPipsProps {
  className: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const MemoizedPips: FC<MemoizedPipsProps> = ({ className, onClick }) => {
  const unsafeOtherStreams = useLivePartyOtherStreamsData();
  const otherStreams =
    useModeratedOtherStreamsData<OtherStreamsType>(unsafeOtherStreams);

  const breakpoint = useBreakpointPrecise();

  if (otherStreams.length === 0) {
    return null;
  }

  return (
    <div className={classnames(styles.pipContainer, className)}>
      {otherStreams.map((stream: OtherStreamsType) => (
        <PictureInPicturePlayer
          key={stream.streamId}
          className={styles[`pip${otherStreams.length}`]}
          forceDisableHd={
            breakpoint !== Breakpoints.DESKTOP || otherStreams.length > 1
          }
          pipsCount={otherStreams.length}
          onClick={onClick}
          {...stream}
        />
      ))}
    </div>
  );
};

export default memo(MemoizedPips);
