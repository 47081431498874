export enum EventFields {
  ACCOUNT_ID = "account_id",
  CURRENCY = "currency",
  DEVICE_ID = "device_id",
  ECOMMERCE = "ecommerce",
  EVENT_CATEGORY = "event_category",
  EVENT_DETAIL = "event_detail",
  EVENT_NAME = "event_name",
  FOLLOWERS = "followers",
  FOLLOWING = "following",
  GIFT_ID = "gift_id",
  GIFT_SOURCE = "GiftSource",
  HASHE = "hashe",
  HASHP = "hashp",
  IS_LOGGED_IN = "is_logged_in",
  ITEM_TYPE = "item_type",
  ITEMS = "items",
  MARKET_OFFER_ID = "marketOfferId",
  OFFER_SKU = "offerSku",
  ONE_CLICK = "one_click",
  ONE_CLICK_PURCHASE = "one_click_purchase",
  PAGE_TYPE = "page_type",
  PAYMENT_PROVIDER = "payment_provider",
  PEER_ID = "peer_id",
  PERSONAL_OFFER_TYPE = "personal_offer_type",
  SOURCE_ID = "source_id",
  STREAM_KIND = "stream_kind",
  TANGO_PLATFORM = "tango_platform",
  TANGO_PREVIOUS_SCREEN = "tango_previous_screen",
  TANGO_SCREEN = "tango_screen",
  TANGO_STREAM_ID = "tango_stream_id",
  TANGO_STREAM_SOURCE = "tango_stream_source",
  TANGO_USER_ID = "tango_user_id",
  TANGO_VERSION = "tango_version",
  TAX = "tax",
  TRANSACTION_ID = "transaction_id",
  TRANSACTION_METHOD = "transaction_method",
  TRANSACTION_TYPE = "transaction_type",
  UNIQUE_STREAM_ID = "unique_stream_id",
  USER_ID = "user_id",
  VALUE = "value",
  VIP_STATUS = "vip_status",
}

export enum MosEventFields {
  ACCOUNT_ID = "account_id",
  ACTION = "action",
  BUCKET_ID = "bucket_id",
  GIFT_PLACEHOLDER = "gift_placeholder",
  GIFT_PLACEHOLDER_ID = "gift_placeholder_id",
  GIFT_SOURCE = "gift_source",
  GIFT_SOURCE_ID = "gift_source_id",
  MAX_COLUMN_POSITION = "max_column_position",
  MIN_COLUMN_POSITION = "min_column_position",
  MOS_TYPE = "mos_type",
  OBJECT_ID = "object_id",
  PEER_ID = "peer_id",
  POSITION = "position_",
  REASON = "reason",
  RULE_ID = "rule_id",
  TARGET = "target",
}

export enum OfferEventFields {
  INDEX = "index",
  ITEM_CATEGORY = "item_category",
  ITEM_CATEGORY_2 = "item_category2",
  ITEM_CATEGORY_3 = "item_category3",
  ITEM_CATEGORY_4 = "item_category4",
  ITEM_ID = "item_id",
  ITEM_NAME = "item_name",
  PRICE = "price",
  QUANTITY = "quantity",
}

export enum StorageKeys {
  OFFER_DATA = "offerData",
  ROUTE_DATA = "routeStorageData",
  SELECTED_OFFER = "selectedOffer",
  WEBVIEW_OFFERS_DATA = "webviewOffersData",
}

export enum EventNames {
  EVENT = "GTM event to GA",
  MOS_DISPLAYED = "mos_displayed",
  MOS_RECEIVED_ERROR = "mos_received_error",
  PAGE = "GTM page to GA",
  PURCHASE_EVENT = "purchase_ecom",
  UI_ACTION = "ui_action",
}

export enum EventDenominations {
  FOLLOW_STREAMER = "follow_streamer",
  LOGIN = "login",
  SEND_GIFT = "send_gift",
  SING_UP = "sign_up",
}

export enum Platforms {
  WEB = "web",
}

export enum TransactionMethod {
  ONE_TIME = "one time",
  SUBSCRIPTION = "subscription",
}

export enum EventCategory {
  PERSONAL_ACCOUNT_EVENTS = "personal_account_events",
  STREAM_EVENTS = "stream_events",
}

export enum StreamSource {
  LOBBY = "lobby",
}

export enum EventValues {
  NA = "NA",
}

export enum AppsFlyerPlatform {
  ANDROID = "android",
  IOS = "ios",
  NATIVE_PC = "nativepc",
}

export enum MosTargetValue {
  MOS = "mos",
}

export enum MosType {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum GiftSource {
  CHAT = "chat",
  STREAM = "stream",
}

export enum GiftPlaceholder {
  GIFT_DRAWER = "gift_drawer",
  MOS_V2 = "mos_v2",
  PREMIUM_MESSAGE = "premium_message",
}

export enum Action {
  CLICK = "click",
  SCROLL = "scroll",
}
