/* eslint-disable no-restricted-imports */
import { VIEWER_SESSION_SET_CLEAR_VIEW } from "state/actionTypes";

const initialState = {
  isClearView: false,
};

export const localStreamSettingsSelectors = {
  getClearView: (state) => state.isClearView,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEWER_SESSION_SET_CLEAR_VIEW: {
      return {
        ...state,
        isClearView: action.payload,
      };
    }
  }

  return state;
};
