import { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  loginSelectors,
  visitorProfileSelectors,
} from "src/features/profile/visitorProfile/imports/state";
import {
  BasicProfile,
  RootState,
} from "src/features/profile/visitorProfile/imports/types";
import { loadVisitorProfileById } from "src/features/profile/visitorProfile/state/flow";
import { toBasicProfile } from "src/features/profile/visitorProfile/utils";

const selectorFactory = (accountId?: string) => (state: RootState) => ({
  profile: visitorProfileSelectors.getVisitorProfileById(state, accountId),
  isLoggedIn: loginSelectors.isLoggedIn(state),
});

export const useVisitorProfileById = (accountId?: string) => {
  const selector = useMemo(() => selectorFactory(accountId), [accountId]);
  const { profile, isLoggedIn } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoggedIn && accountId) {
      dispatch(loadVisitorProfileById(accountId));
    }
  }, [isLoggedIn, accountId]);

  return (profile?.basic ? toBasicProfile(profile) : {}) as BasicProfile;
};
