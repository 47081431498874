import React, { FC, useMemo, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import MessageMenuOptionsList from "chat/components/MessageMenu/MessageMenuOptionsList/MessageMenuOptionsList";
import { MessageReactionsList } from "chat/components/MessageMenu/MessageReactionsList/MessageReactionsList";
import { POSITION } from "chat/constants";
import { chatSelectors } from "chat/exports/state/selectors";
import { useCalculateBottomOffset } from "chat/hooks/useCalculateBottomOffset";
import { useDetectTouchMove } from "chat/hooks/useDetectTouchMove";
import { usePopoverPosition } from "chat/hooks/usePopoverPosition";
import { NativePopover } from "chat/imports/components";
import { RootState } from "chat/imports/state";
import {
  getIsChatDeleteMessageEnabled,
  getIsChatEditMessageEnabled,
  getIsMessageReactionsEnabled,
} from "chat/soc/chatSoc";
import { StoredMessage } from "chat/state";
import { MessageType } from "chat/types";
import styles from "./MessageActionsMenu.scss";

const selector = (state: RootState) => ({
  isDeleteMessageEnabled: getIsChatDeleteMessageEnabled(state),
  isEditMessageEnabled: getIsChatEditMessageEnabled(state),
  isReactionEnabled: getIsMessageReactionsEnabled(state),
  currentConversationId: chatSelectors.getCurrentConversationId(state),
});

interface MessageActionsMenuProps {
  isMessageMenuEnabled: boolean;
  isMyMessage: boolean;
  isPopoverOpen: boolean;
  message: StoredMessage;
  setPopoverOpen: (arg: boolean) => void;
}

const MessageActionsMenu: FC<MessageActionsMenuProps> = ({
  children,
  message,
  isMyMessage,
  setPopoverOpen,
  isPopoverOpen,
  isMessageMenuEnabled,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isScrollingInProgress = useDetectTouchMove();
  const offset = useCalculateBottomOffset(isPopoverOpen);
  const { position, additionalPosition } = usePopoverPosition({
    anchorEl: ref.current,
    isPopoverOpen,
    side: isMyMessage ? POSITION.RIGHT : POSITION.LEFT,
    bottomOffset: offset,
  });

  const {
    isDeleteMessageEnabled,
    isEditMessageEnabled,
    isReactionEnabled,
    currentConversationId,
  } = useSelector(selector, shallowEqual);

  const closeMenu = () => setPopoverOpen(false);

  const messageClassNames = classnames({
    [styles.messagePopoverPadding]:
      (isMyMessage &&
        isEditMessageEnabled &&
        message.type === MessageType.TEXT_MESSAGE) ||
      (isEditMessageEnabled && isDeleteMessageEnabled) ||
      isDeleteMessageEnabled,
  });

  const Component = useMemo(
    () => (
      <MessageMenuOptionsList
        message={message}
        isMyMessage={isMyMessage}
        closeMenu={closeMenu}
        ref={ref}
        className={messageClassNames}
      />
    ),
    [message, isMyMessage]
  );

  const ReactionsComponent = useMemo(() => {
    if (!isReactionEnabled) {
      return;
    }

    return (
      <MessageReactionsList
        closeMenu={closeMenu}
        message={message.id}
        messageSenderId={message.from}
        conversationId={currentConversationId}
      />
    );
  }, [message, isReactionEnabled]);

  if (!isMessageMenuEnabled) {
    return <>{children}</>;
  }

  return (
    <div className={styles.wrap}>
      <NativePopover
        disable={isScrollingInProgress}
        additionalContent={ReactionsComponent}
        additionalContentClassName={styles.reactionsWrapper}
        isOpen={isPopoverOpen}
        onChange={setPopoverOpen}
        content={Component}
        position={position}
        additionalPosition={additionalPosition}
        contentClassName={styles.messagePopover}
      >
        {children}
      </NativePopover>
    </div>
  );
};

export default MessageActionsMenu;
