import { createSlice } from "@reduxjs/toolkit";
import { BroadcastExternalState } from "src/features/broadcastExternal/common/types";
import {
  resetBroadcastExternalStatus,
  setBroadcastExternalStatus,
  setOBSInstructionVisibility,
  startMultiBroadcastExternal,
  stopMultiBroadcastExternal,
} from "src/features/broadcastExternal/state/actionCreators";

const initialState: BroadcastExternalState = {
  status: null,
  isMultiBroadcastStarted: false,
  isOBSInstructionVisible: false,
};

const slice = createSlice({
  name: "broadcastExternal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setBroadcastExternalStatus, (state, action) => {
        state.status = action.payload;
      })
      .addCase(startMultiBroadcastExternal, (state) => {
        state.isMultiBroadcastStarted = true;
      })
      .addCase(stopMultiBroadcastExternal, (state) => {
        state.isMultiBroadcastStarted = false;
      })
      .addCase(setOBSInstructionVisibility, (state, action) => {
        state.isOBSInstructionVisible = action.payload;
      })
      .addCase(resetBroadcastExternalStatus, (state) => {
        state.status = null;
      });
  },
});

export const broadcastExternalPersistConfig = {
  whitelist: ["streamKey"],
};

export const broadcastExternalReducer = slice.reducer;
