import { FileType } from "chat/enums";
import { ChatMessageSentFlags } from "chat/types";

export const getFileFlag = (fileType: string): ChatMessageSentFlags => {
  switch (true) {
    case fileType.startsWith(FileType.IMAGE):
      return ChatMessageSentFlags.IMAGE;
    case fileType.startsWith(FileType.VIDEO):
      return ChatMessageSentFlags.VIDEO;
    default:
      return ChatMessageSentFlags.TEXT;
  }
};
