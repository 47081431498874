import React, { FC, memo } from "react";
import ArticleMeta from "legal/components/ArticleMeta/ArticleMeta";
import { Headline, ListItem, Paragraph } from "legal/components/typography";
import { SCREEN_NAME, ScreenViewReporter } from "legal/imports/analytics";
import { FormattedMessageTitle } from "legal/imports/components";
import { legalMessages } from "legal/imports/constants";
import {
  LinkToCommunityGuidelines,
  MailToCopyright,
  MailToSupport,
} from "legal/links";
import { SEOStructuredData } from "src/features/legal/imports/components";
import { FAQ_PAGE_STRUCTURED_DATA } from "src/features/legal/imports/constants";
import styles from "legal/common/LegalArticle.scss";

/* eslint-disable react/jsx-no-literals, react/no-unescaped-entities */
const FAQ: FC = () => (
  <>
    <SEOStructuredData structuredData={FAQ_PAGE_STRUCTURED_DATA} />
    <ScreenViewReporter name={SCREEN_NAME.LEGAL_FAQ} />
    <FormattedMessageTitle {...legalMessages.faq} />

    <article className={styles.article}>
      <ArticleMeta postDate="Last Modified: 11.12.2023" withLanguagePicker />

      <Headline level={1}>Frequently Asked Question (FAQ)</Headline>

      <Paragraph>
        <ListItem>
          <b>Q: What types of videos can be uploaded on Tango's platform?</b>
        </ListItem>
        <ListItem>
          <b>A:</b> Tango's platform allows various types of content as long as
          they abide by our Community Guidelines. Examples include original
          works, world travel, educational content, and creative pieces.
          However, we prohibit content that infringes on copyright or
          intellectual property laws, promotes hate speech, or violates personal
          privacy, as explained in our <LinkToCommunityGuidelines />.
        </ListItem>
      </Paragraph>
      <Paragraph>
        <ListItem>
          <b>Q: How can I report inappropriate content?</b>
        </ListItem>
        <ListItem>
          <b>A:</b> To report any inappropriate or offensive content, users can
          approach us via: <MailToSupport />. For users based in India, you can
          contact our grievance mechanism available on Tango's Terms of Use. Our
          team will review the report and take appropriate action.
        </ListItem>
      </Paragraph>
      <Paragraph>
        <ListItem>
          <b>Q: Can I share personal information about others on Tango?</b>
        </ListItem>
        <ListItem>
          <b>A:</b> No, sharing personal information about others on Tango's is
          prohibited without their consent. This includes sharing photos,
          videos, profiles, full names, addresses, phone numbers, or email
          addresses.
        </ListItem>
      </Paragraph>
      <Paragraph>
        <ListItem>
          <b>
            Q: How can I protect my intellectual property rights on the
            platform?
          </b>
        </ListItem>
        <ListItem>
          <b>A:</b> Tango respects and upholds intellectual property rights.
          Users can report any copyrighted content uploaded without permission
          through the email: <MailToCopyright />. Our team will investigate and
          take necessary action.
        </ListItem>
      </Paragraph>
      <Paragraph>
        <ListItem>
          <b>Q: Can I earn money from my content on the platform?</b>
        </ListItem>
        <ListItem>
          <b>A:</b> Yes, users can monetize their content on Tango by joining
          the Artist Community.
        </ListItem>
      </Paragraph>
      <Paragraph>
        <ListItem>
          <b>Q: How can I delete my account?</b>
        </ListItem>
        <ListItem>
          <b>A:</b> Users can delete their account at any time by going to
          account settings and selecting the option to delete. Tango will then
          delete all your content and account details. Tango reserves to right
          to retain any of your data that is necessary to comply with any
          applicable law or legal obligation.
        </ListItem>
      </Paragraph>
    </article>
  </>
);

export default memo(FAQ);
