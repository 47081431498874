import { tutorialsActions } from "src/features/tutorials/shared/state/slice";
import { TutorialName } from "src/features/tutorials/shared/state/types";

export const completeObsClickToInviteTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.OBS_CLICK_TO_INVITE);

export const completeStickersClickTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.STICKERS_CLICK);

export const completeCreateGiftStickerTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.STICKERS_CREATE_GIFT_STICKER);

export const completeCreateImageStickerTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.STICKERS_CREATE_IMAGE_STICKER);

export const completeCreateGoalStickerTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.STICKERS_CREATE_GOAL_STICKER);

export const completeStreamsSwipesTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.STREAMS_SWIPES);

export const startStreamsSwipesTutorial = () =>
  tutorialsActions.startTutorial(TutorialName.STREAMS_SWIPES);

export const completeWebToAppClickToDownloadTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.WEB_TO_APP_CLICK_TO_DOWNLOAD);

export const completeBattleClickTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.BATTLE_CLICK);

export const completePWATutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.PWA_TUTORIAL);

export const refreshPWATutorial = () =>
  tutorialsActions.refreshTutorial(TutorialName.PWA_TUTORIAL);
