import { useEffect, useState } from "react";
import { Breakpoints } from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";

const SAFE_SCROLL_TIMEOUT = 300;

export const useDetectTouchMove = () => {
  const breakpoint = useBreakpointPrecise();
  const [isScrollingInProgress, setIsScrollingInProgress] = useState(false);
  const shouldDetectScroll = breakpoint !== Breakpoints.DESKTOP;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;

    const handleTouchMove = () => {
      setIsScrollingInProgress(true);

      clearTimeout(timer);

      timer = setTimeout(() => {
        setIsScrollingInProgress(false);
      }, SAFE_SCROLL_TIMEOUT);
    };

    if (!shouldDetectScroll) {
      return;
    }

    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [shouldDetectScroll]);

  return isScrollingInProgress;
};
