import { getAccessToPremiumFromSOC, getNSFWFromSOC } from "src/state/SOC/nsfw";
import { getInMemoryStore } from "src/utils/getStoreHandler";
import { NSFW_KEY } from "ui/scenes/settings/contents/app/SettingsEnum";

const PREFERENCE_ACCESS_TO_PREMIUM = "accessToPremium";

export const mergedSettingsSelector = (state) =>
  Object.assign({}, state.settings, ...state.settingsPatches);

const socHandlers = {
  [NSFW_KEY]: () => getNSFWFromSOC(getInMemoryStore()),
  [PREFERENCE_ACCESS_TO_PREMIUM]: () =>
    getAccessToPremiumFromSOC(getInMemoryStore()),
};

export const getPreference = (state, key, ignoreSOC = false) => {
  const settings = mergedSettingsSelector(state);

  if (!ignoreSOC && socHandlers[key]) {
    const value = socHandlers[key]();

    if (value) {
      return value;
    }
  }

  return settings[key];
};
