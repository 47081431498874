/* eslint-disable no-restricted-imports */
import {
  CLEAR_LIVE_STREAM_VOLUME,
  UPDATE_BATCH_LIVE_STREAM_VOLUME,
  UPDATE_LIVE_STREAM_BATCH_STATUSES,
  UPDATE_LIVE_STREAM_INFO,
  UPDATE_LIVE_STREAM_SETTINGS,
  UPDATE_LIVE_STREAM_VOLUME,
} from "state/actionTypes";

export const updatedLiveStreamInfo = ({ streamId, info }) => ({
  type: UPDATE_LIVE_STREAM_INFO,
  payload: info,
  meta: { streamId },
});

export const updatedLiveStreamSettings = ({ streamId, settings }) => ({
  type: UPDATE_LIVE_STREAM_SETTINGS,
  payload: settings,
  meta: { streamId },
});

export const updatedLiveStreamVolume = ({ streamId, volume }) => ({
  type: UPDATE_LIVE_STREAM_VOLUME,
  payload: volume,
  meta: { streamId },
});

export const updatedBatchLiveStreamVolume = ({ streamIds, volume }) => ({
  type: UPDATE_BATCH_LIVE_STREAM_VOLUME,
  payload: volume,
  meta: { streamIds },
});

export const clearLiveStreamVolume = () => ({
  type: CLEAR_LIVE_STREAM_VOLUME,
});

export const updatedBatchStreamStatuses = (
  streamIdToStatusMap,
  streamsInfo
) => ({
  type: UPDATE_LIVE_STREAM_BATCH_STATUSES,
  payload: streamIdToStatusMap,
  meta: { streamsInfo },
});
