import { useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { messages } from "chat/components/common/messages";
import { ToastType } from "chat/imports/constants";
import { useToast } from "chat/imports/hooks";
import { RootState, profilesCacheSelectors } from "chat/imports/state";
import { formatDisplayName } from "chat/imports/utils";
import { resetDeletedChatUserId } from "chat/state";
import chatSelectors from "chat/state/selectors";

const deleteChatSelector = (state: RootState) => ({
  deletedChatUserId: chatSelectors.getDeletedChatUserId(state),
});

const basicProfileSelector =
  (accountId: string | undefined) => (state: RootState) => ({
    basicProfile: profilesCacheSelectors.getBasicProfile(state, accountId),
  });

export const useDeleteChatToast = () => {
  const dispatch = useDispatch();
  const { deletedChatUserId } = useSelector(deleteChatSelector, shallowEqual);
  const { basicProfile } = useSelector(
    basicProfileSelector(deletedChatUserId),
    shallowEqual
  );
  const intl = useIntl();
  const name = formatDisplayName({ intl, basicProfile });
  const { notify } = useToast();

  useEffect(() => {
    if (deletedChatUserId) {
      notify({
        type: ToastType.REGULAR,
        title: intl.formatMessage(messages.deletedChatNotification, { name }),
      });

      dispatch(resetDeletedChatUserId());
    }
  }, [deletedChatUserId]);
};
