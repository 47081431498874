import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  emitFailedListFetch,
  emitSuccessfulListFetch,
} from "@analytics/happyMoments/happyMomentsEvents";
import { deleteHappyMoment, fetchStreamerMoments } from "api/happyMoments";
import { isApiError } from "api/utils/enhancedFetch";
import { ResponseCode, Story } from "src/types/happyMoments";
import { ensureHttps } from "src/utils/ensureHttps";
import { RootState } from "state/delegate";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { StoriesState } from "state/tree/stories";

export const clearStories = createAction("lwc/stories/clear");

export const deleteStory = createAsyncThunk<
  void,
  string,
  { rejectValue: ResponseCode }
>("lwc/stories/deleteStory", async (id, api) => {
  const { code } = await deleteHappyMoment(id);
  if (code !== ResponseCode.OK) {
    return api.rejectWithValue(code);
  }
});

export const forceStoriesSet = createAction<Story[]>(
  "lwc/stories/forceStoriesSet"
);

type LoadStoriesFulfilledPayload = Omit<StoriesState["data"], "wasFetched">;

export const loadStories = createAsyncThunk<
  LoadStoriesFulfilledPayload,
  { cursor?: string; streamerId: string } & FetcherMetaV2,
  { rejectValue: string; state: RootState }
>("lwc/stories/fetch", async (args, api) => {
  try {
    const {
      details = [],
      stories = [],
      nextCursor,
      previousCursor,
    } = await fetchStreamerMoments({
      streamerId: args.streamerId,
      cursor: args.cursor,
    });
    emitSuccessfulListFetch({ details, stories, nextCursor, previousCursor });

    return stories.reduce(
      (acc: LoadStoriesFulfilledPayload, next) => {
        const story = details.find((detail) => detail.storyId === next);
        if (story) {
          acc.list.push(next);
          acc.map[next] = {
            ...story,
            gifterProfileThumbnailUrl: ensureHttps(
              story.gifterProfileThumbnailUrl
            ),
            streamerProfileThumbnailUrl: ensureHttps(
              story.streamerProfileThumbnailUrl
            ),
          };
        }

        return acc;
      },
      {
        list: [],
        map: {},
        nextCursor,
        previousCursor,
      }
    );
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    emitFailedListFetch(e);

    return api.rejectWithValue(error);
  }
});

export const resetGiftAnimation = createAction<boolean>(
  "lwc/stories/resetGiftAnimation"
);
