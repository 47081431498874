import { useCallback } from "react";
import { useSelector } from "react-redux";
import { streamsCacheSelectors, viewerSessionSelectors } from "state/selectors";

const selector = (state) => {
  const settings = streamsCacheSelectors.getStreamSettingsById(
    state,
    viewerSessionSelectors.getStreamId(state)
  );
  return settings ? settings.videoEnabled : true;
};

export default () => useSelector(selector);

export const useVideoEnabledForStream = (streamId) => {
  const selector = useCallback(
    (state) => {
      const settings = streamsCacheSelectors.getStreamSettingsById(
        state,
        streamId
      );
      return settings ? settings.videoEnabled : true;
    },
    [streamId]
  );
  return useSelector(selector);
};
