import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  MessageAction,
  emitMessageActionEvent,
  getMessageFlag,
} from "chat/analytics/emitMessageActionEvent";
import MessageMenuOptionBase from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuOptionBase";
import useMessageTranslate from "chat/components/currentConversation/useMessageTranslate";
import { MESSAGE_POPOVER_OPTIONS } from "chat/constants/messageMenuOptionsConfig";
import { chatSelectors } from "chat/exports/state/selectors";
import { StoredMessage } from "chat/state";
import { MessageType } from "chat/types";
import { RootState } from "state/delegate";
import { deviceInfoSelectors } from "state/selectors";

export interface MessageMenuOptionProps {
  closeMenu: VoidFunction;
  isMyMessage: boolean;
  message: StoredMessage;
}

const selector = (state: RootState) => ({
  conversationId: chatSelectors.getCurrentConversationId(state) as string,
  locale: deviceInfoSelectors.getDeviceLocale(state),
});

const MessageMenuTranslateOption: FC<MessageMenuOptionProps> = ({
  message,
  isMyMessage,
  closeMenu,
}) => {
  const { conversationId, locale } = useSelector(selector);
  const isTextMessage = message.type === MessageType.TEXT_MESSAGE;
  const translateMessage = useMessageTranslate({
    message,
    locale,
    conversationId,
    isMyMessage,
  });

  const onTranslateHandler = () => {
    translateMessage();
    closeMenu();

    emitMessageActionEvent({
      action: MessageAction.MESSAGE_TRANSLATE,
      chatId: message.id.chat_id,
      flag: getMessageFlag(message),
    });
  };

  if (isMyMessage || !isTextMessage) {
    return null;
  }

  return (
    <MessageMenuOptionBase
      option={MESSAGE_POPOVER_OPTIONS.translate}
      onClick={onTranslateHandler}
    />
  );
};

export default MessageMenuTranslateOption;
