import { EmblaOptionsType } from "embla-carousel";
import {
  BanubaBeautyType,
  BanubaMakeupType,
  BroadcastMasksViewType,
  TangoBeautyType,
  TangoMakeupType,
} from "src/features/broadcastMasks/common/enums";
import { broadcastMasksMessages } from "src/features/broadcastMasks/common/messages";
import { BroadcastMasksFilterConfig } from "src/features/broadcastMasks/common/types";
import { BroadcastFilterType } from "src/features/broadcastMasks/imports/analytics";

export const MAKEUP_CONFIG: BroadcastMasksFilterConfig = {
  label: broadcastMasksMessages.makeup,
  icon: "https://resources.tango.me/web/banuba/broadcast-makeup.png",
  viewType: BroadcastMasksViewType.Makeup,
  analyticsFilterType: BroadcastFilterType.MAKEUP,
  dataTestId: "makeup-lobby-card",
  countDataTestId: "makeup-lobby-count",
};

export const BEAUTY_CONFIG: BroadcastMasksFilterConfig = {
  label: broadcastMasksMessages.beauty,
  icon: "https://resources.tango.me/web/banuba/broadcast-beauty.png",
  viewType: BroadcastMasksViewType.Beauty,
  analyticsFilterType: BroadcastFilterType.BEAUTY,
  dataTestId: "beauty-lobby-card",
  countDataTestId: "beauty-lobby-count",
};

export const MASKS_CONFIG: BroadcastMasksFilterConfig = {
  label: broadcastMasksMessages.masks,
  icon: "https://resources.tango.me/web/banuba/broadcast-masks.png",
  viewType: BroadcastMasksViewType.Masks,
  analyticsFilterType: BroadcastFilterType.MASKS,
  dataTestId: "masks-lobby-card",
  countDataTestId: "masks-lobby-count",
};

export const BROADCAST_FILTERS: BroadcastMasksFilterConfig[] = [
  MAKEUP_CONFIG,
  BEAUTY_CONFIG,
  MASKS_CONFIG,
];

export const BidirectionalTypes = [
  TangoBeautyType.EyeDistance,
  TangoBeautyType.Mouth,
];

export const BROADCAST_MASKS_AVAILABLE_BEAUTY_FILTERS = [
  {
    id: TangoBeautyType.FineSmooth,
    property: BanubaBeautyType.SkinsmoothStrength,
    label: broadcastMasksMessages.fineSmooth,
    path: ["tg_beauty_makeup", "beauty"],
  },
  {
    id: TangoBeautyType.ToothWhiten,
    label: broadcastMasksMessages.toothWhiten,
    path: ["tg_beauty_makeup", "beauty"],
  },
  {
    id: TangoBeautyType.Whiten,
    label: broadcastMasksMessages.whiten,
    path: ["tg_morph_lut", "lut"],
  },
  {
    id: TangoBeautyType.Ruddy,
    label: broadcastMasksMessages.ruddy,
    path: ["tg_morph_lut", "lut"],
  },
  {
    id: TangoBeautyType.VFace,
    label: broadcastMasksMessages.vFace,
    path: ["tg_morph_lut", "morph"],
  },
  {
    id: TangoBeautyType.CheekThin,
    label: broadcastMasksMessages.cheekThin,
    path: ["tg_morph_lut", "morph"],
  },
  {
    id: TangoBeautyType.CheekSmall,
    label: broadcastMasksMessages.cheekSmall,
    path: ["tg_morph_lut", "morph"],
  },
  {
    id: TangoBeautyType.CheekNarrow,
    label: broadcastMasksMessages.cheekNarrow,
    path: ["tg_morph_lut", "morph"],
  },
  {
    id: TangoBeautyType.Nose,
    label: broadcastMasksMessages.nose,
    path: ["tg_morph_lut", "morph"],
  },
  {
    id: TangoBeautyType.EyeEnlarge,
    label: broadcastMasksMessages.eyeEnlarge,
    path: ["tg_morph_lut", "morph"],
  },
  {
    id: TangoBeautyType.EyeDistance,
    label: broadcastMasksMessages.eyeDistance,
    path: ["tg_morph_lut", "morph"],
  },
  {
    id: TangoBeautyType.Mouth,
    label: broadcastMasksMessages.mouth,
    path: ["tg_morph_lut", "morph"],
  },
  {
    id: TangoBeautyType.RemovePouchStrength,
    property: BanubaBeautyType.Circles,
    label: broadcastMasksMessages.removePouchStrength,
    path: ["tg_beauty_makeup", "beauty"],
  },
];

export const BROADCAST_MASKS_AVAILABLE_BEAUTY_FILTERS_MAP = new Map(
  BROADCAST_MASKS_AVAILABLE_BEAUTY_FILTERS.map((filter) => [filter.id, filter])
);

export const BROADCAST_MASKS_AVAILABLE_MAKEUP_FILTERS = [
  {
    id: TangoMakeupType.Lipstick,
    label: broadcastMasksMessages.lipstick,
  },
  {
    id: TangoMakeupType.Blush,
    label: broadcastMasksMessages.blush,
  },
  {
    id: TangoMakeupType.Eyebrow,
    label: broadcastMasksMessages.eyebrow,
  },
  {
    id: TangoMakeupType.Eyeshadow,
    label: broadcastMasksMessages.eyeshadow,
  },
  {
    id: TangoMakeupType.Eyelinear,
    label: broadcastMasksMessages.eyeliner,
  },
  {
    id: TangoMakeupType.Eyelash,
    label: broadcastMasksMessages.eyelash,
  },
  {
    id: TangoMakeupType.Contactlens,
    label: broadcastMasksMessages.lens,
  },
];

export const BROADCAST_MASKS_AVAILABLE_MAKEUP_FILTERS_MAP = new Map(
  BROADCAST_MASKS_AVAILABLE_MAKEUP_FILTERS.map((filter) => [filter.id, filter])
);

export const TANGO_BANUBA_MAKEUP_TYPE_MAP: Record<
  TangoMakeupType,
  BanubaMakeupType
> = {
  [TangoMakeupType.Lipstick]: BanubaMakeupType.Lips,
  [TangoMakeupType.Blush]: BanubaMakeupType.Blush,
  [TangoMakeupType.Eyebrow]: BanubaMakeupType.Brows,
  [TangoMakeupType.Eyeshadow]: BanubaMakeupType.Eyeshadow,
  [TangoMakeupType.Eyelinear]: BanubaMakeupType.Eyeliner,
  [TangoMakeupType.Eyelash]: BanubaMakeupType.Eyelashes,
  [TangoMakeupType.Contactlens]: BanubaMakeupType.Lenses,
};

export const DEFAULT_COLOR: number[] = [0, 0, 0, 0];

export const BROADCAST_MASKS_DEFAULT_ALPHA = 0.3;

export const EMBLA_CAROUSEL_OPTIONS: EmblaOptionsType = {
  containScroll: false,
  skipSnaps: true,
};

export const ANALYTICS_DEBOUNCE_VALUE = 100;

export const DEFAULT_BEAUTY_MULTIPLIER = 1.0;

export const BEAUTY_MULTIPLIERS_BY_TYPE = {
  [TangoBeautyType.Ruddy]: 0.5,
  [TangoBeautyType.Mouth]: 0.8,
  [TangoBeautyType.FineSmooth]: 1.2,
  [TangoBeautyType.ToothWhiten]: 2.0,
} as Record<Partial<TangoBeautyType>, number>;
