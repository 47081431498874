import React, { ComponentType } from "react";
import { useSelector } from "react-redux";
import { RouteProps } from "react-router";
import { Route } from "react-router-dom";
import { LoggedInRoute } from "src/features/visitorTournament/imports/components";
import { getVisitorTournamentEnabled } from "src/features/visitorTournament/imports/state";

interface TournamentRouteProps extends RouteProps {
  component: ComponentType;
}

export const TournamentRoute = (props: TournamentRouteProps) => {
  const { component: Tournament, ...rest } = props;
  const isVisitorTournamentEnabled = useSelector(getVisitorTournamentEnabled);

  return isVisitorTournamentEnabled ? (
    <Route {...rest} component={Tournament} />
  ) : (
    <LoggedInRoute {...rest} component={Tournament} />
  );
};
