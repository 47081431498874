import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  RootState,
  actionCreators,
  getGiftsOnScreenList,
  getIsCustomGiftEnabled,
  getIsOfferInMosEnabled,
  getMoodsLegacyV2Enabled,
  getMoodsV2Enabled,
  getMosReorderingEnabled,
  getMosReorderingOrder,
  getMosV2FallbackGifts,
  giftingRequestsSelectors,
  giftsCacheSelectors,
  giftsFetchingTimeout,
  loadGiftsBatch,
  mosV2Selectors,
  specialOffersSelectors,
  userSelectors,
} from "src/features/mos/imports/state";
import { Gift } from "src/features/mos/imports/types";
import { useMount } from "src/features/mos/imports/utils";
import { getMosV2Action } from "src/features/mos/state/getMosV2Action";
import {
  BucketsMap,
  GiftBalance,
  GiftId,
  MosData,
  MosItemType,
  MosItemTypeAbbreviation,
  MosUiGiftItem,
  MosUiItem,
  MosVersion,
  isSupportedStreamType,
} from "src/features/mos/types";

const V0_GIFTS_COUNT = 20;

const generateRawMosData = (
  state: RootState,
  bucketsOrder: string[]
): Omit<MosData, "version"> => {
  const defaultGiftsIds = getGiftsOnScreenList(state) as GiftId[];
  const giftsBalances = userSelectors.getGiftsBalance(state) as GiftBalance[];
  const inventoryGiftsIds = giftsBalances.map(({ gift }) => gift);
  const personalGifts = giftsCacheSelectors.getPersonalGifts(state) as Gift[];
  const personalGiftsIds = personalGifts.map(({ id }) => id);
  const topSentGiftsIds = giftsCacheSelectors.getTopSentGifts(
    state
  ) as GiftId[];

  const bucketsMap: BucketsMap = {
    [MosItemTypeAbbreviation.ALGORITHM_PERSONAL_GIFT]: {
      giftsIds: personalGiftsIds,
      type: MosItemType.ALGORITHM_PERSONAL_GIFT,
      typeAbbreviation: MosItemTypeAbbreviation.ALGORITHM_PERSONAL_GIFT,
    },
    [MosItemTypeAbbreviation.ALGORITHM_TOP_GIFT_TOP_SENT_GIFT]: {
      giftsIds: topSentGiftsIds,
      type: MosItemType.ALGORITHM_TOP_GIFT_TOP_SENT_GIFT,
      typeAbbreviation:
        MosItemTypeAbbreviation.ALGORITHM_TOP_GIFT_TOP_SENT_GIFT,
    },
    [MosItemTypeAbbreviation.DEFAULT_GIFT]: {
      giftsIds: defaultGiftsIds,
      type: MosItemType.DEFAULT_GIFT,
      typeAbbreviation: MosItemTypeAbbreviation.DEFAULT_GIFT,
    },
    [MosItemTypeAbbreviation.INVENTORY_GIFT]: {
      giftsIds: inventoryGiftsIds,
      type: MosItemType.INVENTORY_GIFT,
      typeAbbreviation: MosItemTypeAbbreviation.INVENTORY_GIFT,
    },
  };

  const alreadyAddedGiftsIds: GiftId[] = [];
  const missedGiftsIds: GiftId[] = [];

  const items = bucketsOrder.reduce(
    (acc: MosUiGiftItem[], typeAbbreviation) => {
      const bucket = bucketsMap[typeAbbreviation];

      if (bucket) {
        bucket.giftsIds.forEach((giftId) => {
          /*
        Each gift can appear only once.
        We prioritize gifts from earlier buckets.
        */
          if (alreadyAddedGiftsIds.includes(giftId)) {
            return;
          }

          alreadyAddedGiftsIds.push(giftId);

          /*
        Personal Gifts are not part of the gift drawer gifts.
        They are managed using a separate storage.
        */
          if (bucket.type === MosItemType.ALGORITHM_PERSONAL_GIFT) {
            const gift = personalGifts.find(({ id }) => id === giftId);

            if (gift) {
              acc.push({
                content: { gift },
                type: bucket.type,
                typeAbbreviation: bucket.typeAbbreviation,
              });
            } else {
              /*
            The list of Personal Gifts ids should be generated based on the
            personal gifts storage, making it impossible for a gift to be
            missed. Additionally, we do not have any implementation for fetching
            missing personal gifts.
            */
            }

            return;
          }

          const gift = giftsCacheSelectors.getGiftById(state, giftId);

          if (gift) {
            acc.push({
              content: { gift },
              type: bucket.type,
              typeAbbreviation: bucket.typeAbbreviation,
            });
          } else {
            missedGiftsIds.push(giftId);
          }
        });
      }

      return acc;
    },
    []
  );

  return { items, missedGiftsIds };
};

const getV0MosData = (state: RootState): MosData => {
  /*
  The usage of 'live.moods.v2' SOC within the method to defining data for V0 may
  lead to misunderstandings. However, this logic was transferred from the
  original behavior. Other platforms don't use it and don't know anything about
  this SOC.
  */
  const isLegacyMoodsV2Enabled = getMoodsLegacyV2Enabled(state);

  const topSentGiftsIds = giftsCacheSelectors.getTopSentGifts(
    state
  ) as GiftId[];

  let items: MosUiItem[] = [];

  if (!isLegacyMoodsV2Enabled || !topSentGiftsIds?.length) {
    items = generateRawMosData(state, [
      MosItemTypeAbbreviation.ALGORITHM_PERSONAL_GIFT,
      MosItemTypeAbbreviation.DEFAULT_GIFT,
    ]).items;
  } else {
    items = generateRawMosData(state, [
      /*
      Yes, V0 supports Inventory Gifts, but they work unstably.
      Some or all of them may not be displayed.
      */
      MosItemTypeAbbreviation.INVENTORY_GIFT,
      MosItemTypeAbbreviation.ALGORITHM_PERSONAL_GIFT,
      MosItemTypeAbbreviation.ALGORITHM_TOP_GIFT_TOP_SENT_GIFT,
      MosItemTypeAbbreviation.DEFAULT_GIFT,
    ]).items.slice(0, V0_GIFTS_COUNT);
  }

  return {
    items,
    missedGiftsIds: [], // We don't load missed gifts for V0
    version: MosVersion.V0,
  };
};

const getV1MosData = (state: RootState): MosData => {
  const bucketsOrder = getMosReorderingOrder(state);
  const { items, missedGiftsIds } = generateRawMosData(state, bucketsOrder);

  return { items, missedGiftsIds, version: MosVersion.V1 };
};

const getV2MosData = (state: RootState) => {
  const mosV2Items = mosV2Selectors.getMosV2Items(state);
  const mosV2Error = mosV2Selectors.getMosV2Error(state);
  const mosV2FallbackGiftsIds = getMosV2FallbackGifts(state);
  const isOfferEnabled = getIsOfferInMosEnabled(state);

  const specialOffersList = specialOffersSelectors.getLastActiveSpecialOffer(
    state,
    ["MOS"]
  );

  const items: MosUiItem[] = [];
  const giftRichModels: Gift[] = [];
  const missedGiftsIds: GiftId[] = [];

  mosV2Items.forEach((mosV2Item) => {
    const gift = mosV2Item?.giftRichModel?.gift;

    if (
      mosV2Item.type === MosItemType.OFFER_PLACEHOLDER &&
      specialOffersList &&
      isOfferEnabled
    ) {
      const {
        pricePoints: [offer],
        expirationDateMs: ts,
        sasTemplate,
        campaignId,
      } = specialOffersList;

      if (!offer) {
        return;
      }

      const specialOffer = {
        ...(offer || {}),
        ts,
        sasPricePoint: offer?.sasPricePoint,
        sasTemplate,
        campaignId,
      };

      items.push({
        content: { gift: specialOffer },
        type: mosV2Item.type,
        typeAbbreviation: mosV2Item.typeAbbreviation,
      });

      return;
    }

    if (gift) {
      const giftId = gift?.encryptedGiftId || gift.id;

      items.push({
        content: { gift: { ...gift, id: giftId } },
        type: mosV2Item.type,
        typeAbbreviation: mosV2Item.typeAbbreviation,
      });

      const isGiftInCache = Boolean(
        giftsCacheSelectors.getGiftById(state, giftId)
      );

      if (!isGiftInCache) {
        giftRichModels.push({ ...gift, id: giftId });
      }

      return;
    }

    const giftId = mosV2Item?.giftReference?.encryptedGiftId;

    if (giftId) {
      const gift = giftsCacheSelectors.getGiftById(state, giftId);

      if (gift) {
        items.push({
          content: { gift },
          type: mosV2Item.type,
          typeAbbreviation: mosV2Item.typeAbbreviation,
        });
      } else {
        missedGiftsIds.push(giftId);
      }
    }
  });

  if (mosV2Error) {
    mosV2FallbackGiftsIds.forEach((giftId: string) => {
      const gift = giftsCacheSelectors.getGiftById(state, giftId);

      if (gift) {
        items.push({
          content: { gift },
          type: MosItemType.DEFAULT_GIFT,
          typeAbbreviation: MosItemTypeAbbreviation.DEFAULT_GIFT,
        });
      } else {
        missedGiftsIds.push(giftId);
      }
    });
  }

  return { items, missedGiftsIds, giftRichModels, version: MosVersion.V2 };
};

const getMosData = (state: RootState): MosData => {
  const isReorderingEnabled = getMosReorderingEnabled(state);
  const isMoodsV2Enabled = getMoodsV2Enabled(state);

  if (isMoodsV2Enabled) {
    return getV2MosData(state);
  }

  if (isReorderingEnabled) {
    return getV1MosData(state);
  }

  return getV0MosData(state);
};

const mosItemsSelector = (state: RootState): MosData => {
  const { items, missedGiftsIds, giftRichModels, version } = getMosData(state);
  const isCustomGiftsEnabled = getIsCustomGiftEnabled(state);

  return {
    items:
      version === MosVersion.V0
        ? items
        : // We leave only supported items for V1 and V2
          items.filter(
            ({ type }) =>
              isSupportedStreamType(type) ||
              (isCustomGiftsEnabled && type === MosItemType.USER_CUSTOM_GIFT)
          ),
    missedGiftsIds,
    giftRichModels,
    version,
  };
};

const useLoaderForMissedGifts = (
  missedGiftsIds: GiftId[],
  version: MosVersion
): void => {
  const dispatch = useDispatch();
  const isLoading = useSelector(mosV2Selectors.getMosV2Loading);
  useMount(() => {
    if (version === MosVersion.V1 && missedGiftsIds.length) {
      dispatch(loadGiftsBatch({ giftIds: missedGiftsIds, ignoreCache: true }));
    }
  });

  useEffect(() => {
    /*
    We don't load missed gifts for V0.
    */
    if (version === MosVersion.V2 && missedGiftsIds.length && !isLoading) {
      dispatch(loadGiftsBatch({ giftIds: missedGiftsIds, ignoreCache: true }));
    }
  }, [dispatch, missedGiftsIds, version, isLoading]);
};

const streamSelector = (state: RootState) => ({
  streamerId: giftingRequestsSelectors.getRecipientAccountId(state),
  streamId: giftingRequestsSelectors.getSecondaryRecipientId(state),
});

const useFetchForMosV2Lineup = (version: MosVersion): void => {
  const { streamerId, streamId } = useSelector(streamSelector);
  const dispatch = useDispatch();

  const timeoutDuration = useSelector(
    (state: RootState) => giftsFetchingTimeout(state) * 1000
  );

  useEffect(() => {
    if (version === MosVersion.V2 && streamerId && streamId) {
      dispatch(
        getMosV2Action({ streamId, streamerId, timeoutMs: timeoutDuration })
      );
    }
  }, [dispatch, streamId, streamerId, version]);
};

const useSetRichModelGiftInCache = (giftRichModels?: Gift[]): void => {
  const dispatch = useDispatch();

  if (giftRichModels?.length) {
    dispatch(actionCreators.addGifts(giftRichModels));
  }
};

export const useMosItems = (): MosUiItem[] => {
  const { items, missedGiftsIds, giftRichModels, version } = useSelector(
    mosItemsSelector,
    shallowEqual
  );

  useSetRichModelGiftInCache(giftRichModels);

  useLoaderForMissedGifts(missedGiftsIds, version);

  useFetchForMosV2Lineup(version);

  return items;
};
