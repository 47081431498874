import { GiftDrawerTarget } from "src/features/giftsDrawer/api/giftDrawerTypes";
import {
  getDomainFromPathname,
  useDomain,
} from "src/features/giftsDrawer/imports/commonHooks";
import { DomainType } from "src/features/giftsDrawer/imports/enums";

const domainToTarget: Record<DomainType, GiftDrawerTarget> = {
  [DomainType.CHAT]: GiftDrawerTarget.CHAT,
  [DomainType.STREAM]: GiftDrawerTarget.STREAM,
  [DomainType.UNKNOWN]: GiftDrawerTarget.PROFILE,
};

export const getTargetFromPathname = (pathname: string) => {
  const domain = getDomainFromPathname(pathname);

  return domainToTarget[domain];
};

export const useGiftDrawerTarget = () => {
  const domain = useDomain();

  return domainToTarget[domain];
};
