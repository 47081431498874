/* eslint-disable react/jsx-no-literals */
import React, { FC, memo } from "react";
import { Link, LinkProps, useParams } from "react-router-dom";
import {
  linkToAgencyProgramAgreement,
  linkToArtistsClub,
  linkToBroadcasterAgreement,
  linkToCommunityGuidelines,
  linkToCopyright,
  linkToFAQ,
  linkToPrivacyPolicy,
  linkToTermsOfUse,
} from "legal/imports/constants";

const LocalizedLink: FC<LinkProps> = ({ to, children, ...props }) => {
  const { locale } = useParams<{ locale: string }>();
  const path = locale ? `/${locale}${to}` : to;

  return (
    <Link {...props} to={path}>
      {children}
    </Link>
  );
};

const LinkToPrivacyPolicy: FC = ({ children = "Privacy Policy" }) => (
  <LocalizedLink target="_blank" to={linkToPrivacyPolicy}>
    {children}
  </LocalizedLink>
);

const LinkToCopyright = memo(({ children = "Copyright Policy" }) => (
  <LocalizedLink target="_blank" to={linkToCopyright}>
    {children}
  </LocalizedLink>
));
LinkToCopyright.displayName = "LinkToCopyright";

const LinkToBroadcasterAgreement = memo(
  ({ children = "Broadcaster Agreement" }) => (
    <LocalizedLink target="_blank" to={linkToBroadcasterAgreement}>
      {children}
    </LocalizedLink>
  )
);
LinkToBroadcasterAgreement.displayName = "LinkToBroadcasterAgreement";

const LinkToAgencyProgramAgreement = memo(
  ({ children = "Agency Program Agreement" }) => (
    <LocalizedLink target="_blank" to={linkToAgencyProgramAgreement}>
      {children}
    </LocalizedLink>
  )
);
LinkToAgencyProgramAgreement.displayName = "LinkToBroadcasterAgreement";

const LinkToCommunityGuidelines = memo(
  ({ children = "Community Guidelines" }) => (
    <LocalizedLink target="_blank" to={linkToCommunityGuidelines}>
      {children}
    </LocalizedLink>
  )
);
LinkToCommunityGuidelines.displayName = "LinkToCommunityGuidelines";

const LinkToFAQ = memo(({ children = "FAQ page" }) => (
  <LocalizedLink target="_blank" to={linkToFAQ}>
    {children}
  </LocalizedLink>
));
LinkToFAQ.displayName = "LinkToFAQ";

const LinkToArtistProgram = memo(({ children = "Artist Program" }) => (
  <Link target="_blank" to={linkToArtistsClub}>
    {children}
  </Link>
));
LinkToArtistProgram.displayName = "LinkToCommunityGuidelines";

const MailToSupport = memo(() => (
  <a href="mailto:support@tango.me">support@tango.me</a>
));
MailToSupport.displayName = "MailToSupport";

const MailToLegal = memo(() => (
  <a href="mailto:legal@tango.me">legal@tango.me</a>
));
MailToLegal.displayName = "MailToLegal";

const MailToTangoLive = memo(() => (
  <a href="mailto:live@tango.me">live@tango.me</a>
));
MailToTangoLive.displayName = "MailToTangoLive";

const LinkToTangoWebsite = memo(() => (
  <a href="https://www.tango.me/" rel="noopener noreferrer" target="_blank">
    https://www.tango.me/
  </a>
));
LinkToTangoWebsite.displayName = "LinkToTangoWebsite";

const MailToCopyright = memo(() => (
  <a href="mailto:copyright@tango.me">copyright@tango.me</a>
));
MailToCopyright.displayName = "MailToCopyright";

const LinkToTermsOfUse: FC = ({ children = "https://www.tango.me/" }) => (
  <LocalizedLink target="_blank" to={linkToTermsOfUse}>
    {children}
  </LocalizedLink>
);

const LinkToGoogleCookies = memo(() => (
  <a
    href="https://support.google.com/chrome/answer/95647?hl=en"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    Google Chrome
  </a>
));
LinkToGoogleCookies.displayName = "LinkToGoogleCookies";

const LinkToIECookies = memo(() => (
  <a
    href="https://support.microsoft.com/en-us/help/260971/description-of-cookies"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    Internet Explorer
  </a>
));
LinkToIECookies.displayName = "LinkToIECookies";

const LinkToFirefoxCookies = memo(() => (
  <a
    href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    Mozilla Firefox
  </a>
));
LinkToFirefoxCookies.displayName = "LinkToFirefoxCookies";

const LinkToSafariDesktopCookies = ({ children = "Safari (Desktop)" }) => (
  <a
    href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    {children}
  </a>
);

const LinkToSafariMobileCookies = ({ children = "Safari (Mobile)" }) => (
  <a
    href="https://support.apple.com/en-us/HT201265"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    {children}
  </a>
);

const LinkToAndroidCookies = memo(() => (
  <a
    href="https://support.google.com/nexus/answer/54068?visit_id=637249861772874734-2281104728&hl=en&rd=1"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    Android Browser
  </a>
));
LinkToAndroidCookies.displayName = "LinkToAndroidCookies";

const LinkToIAB = ({
  children = "The Interactive Advertising Bureau (US)",
}) => (
  <a
    href="https://www.iab.com/"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    {children}
  </a>
);

const LinkToIABEurope = ({
  children = "The Interactive Advertising Bureau (EU)",
}) => (
  <a
    href="https://iabeurope.eu/"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    {children}
  </a>
);

const LinkToYourOnlineChoices = ({
  children = "European Interactive Digital Advertising Alliance (EU)",
}) => (
  <a
    href="https://www.youronlinechoices.com/"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    {children}
  </a>
);

const LinkToFTCTestimonialsAndEndorsements = memo(
  ({
    children = "Guidelines Concerning the Use of Testimonials and Endorsements in Advertising",
  }) => (
    <a
      href="https://www.ftc.gov/sites/default/files/attachments/press-releases/ftc-publishes-final-guides-governing-endorsements-testimonials/091005revisedendorsementguides.pdf"
      rel="noreferrer noopener"
      target="_blank"
    >
      {children}
    </a>
  )
);
LinkToFTCTestimonialsAndEndorsements.displayName =
  "LinkToFTCTestimonialsAndEndorsements";

const LinkToFTCDisclosuresGuide = memo(({ children = "Disclosures Guide" }) => (
  <a
    href="https://www.ftc.gov/system/files/documents/plain-language/bus41-dot-com-disclosures-information-about-online-advertising.pdf"
    rel="noreferrer noopener"
    target="_blank"
  >
    {children}
  </a>
));
LinkToFTCDisclosuresGuide.displayName = "LinkToFTCDisclosuresGuide";

const LinkToFTCNativeAdvertising = memo(
  ({ children = "Native Advertising Guidelines" }) => (
    <a
      href="https://www.ftc.gov/tips-advice/business-center/guidance/native-advertising-guide-businesses"
      rel="noreferrer noopener"
      target="_blank"
    >
      {children}
    </a>
  )
);
LinkToFTCNativeAdvertising.displayName = "LinkToFTCNativeAdvertising";

const LinkToEuropeanAdequacyDecisions = memo(() => (
  <a
    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en.
  </a>
));
LinkToEuropeanAdequacyDecisions.displayName = "LinkToEuropeanAdequacyDecisions";

const LinkToOFACListSanctions = memo(() => (
  <a
    href="https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-programs-and-country-information"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    OFAC list sanctions
  </a>
));
LinkToOFACListSanctions.displayName = "LinkToOFACListSanctions";

const LinkToEurLex = memo(() => (
  <a
    href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX:32021D0914&locale=en"
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX:32021D0914&locale=en.
  </a>
));
LinkToEurLex.displayName = "LinkToEurLex";

export {
  LinkToAgencyProgramAgreement,
  LinkToAndroidCookies,
  LinkToArtistProgram,
  LinkToBroadcasterAgreement,
  LinkToCommunityGuidelines,
  LinkToCopyright,
  LinkToEurLex,
  LinkToEuropeanAdequacyDecisions,
  LinkToFAQ,
  LinkToFTCDisclosuresGuide,
  LinkToFTCNativeAdvertising,
  LinkToFTCTestimonialsAndEndorsements,
  LinkToFirefoxCookies,
  LinkToGoogleCookies,
  LinkToIAB,
  LinkToIABEurope,
  LinkToIECookies,
  LinkToOFACListSanctions,
  LinkToPrivacyPolicy,
  LinkToSafariDesktopCookies,
  LinkToSafariMobileCookies,
  LinkToTangoWebsite,
  LinkToTermsOfUse,
  LinkToYourOnlineChoices,
  MailToCopyright,
  MailToLegal,
  MailToSupport,
  MailToTangoLive,
};
