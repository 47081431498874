import { PayloadAction } from "@reduxjs/toolkit";

export type TutorialAction = PayloadAction<TutorialName>;

export enum TutorialName {
  BATTLE_CLICK = "battle:click",
  OBS_CLICK_TO_INVITE = "obs:clickToInvite",
  PWA_TUTORIAL = "pwa:showTutorial",
  STICKERS_CLICK = "stickers:click",
  STICKERS_CREATE_GIFT_STICKER = "stickers:createGiftSticker",
  STICKERS_CREATE_GOAL_STICKER = "stickers:createGoalSticker",
  STICKERS_CREATE_IMAGE_STICKER = "stickers:createImageSticker",
  STREAMS_SWIPES = "streams:swipes",
  WEB_TO_APP_CLICK_TO_DOWNLOAD = "webToApp:clickToDownload",
}

export enum TutorialState {
  COMPLETED = "COMPLETED",
  NOT_COMPLETED = "NOT_COMPLETED",
  STARTED = "STARTED",
}

export type TutorialsState = {
  [key in TutorialName]: TutorialState;
};
