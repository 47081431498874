import { useCallback, useRef } from "react";

export const useCountRenderingGift = () => {
  const data = useRef({});

  const giftSetRenderPosition = useCallback(
    (giftId, categoryName = "moods_on_screen") => {
      if (!(categoryName in data.current)) {
        data.current[categoryName] = {};
      }

      if (!(giftId in data.current[categoryName])) {
        let lastPosition = Object.keys(data.current[categoryName]).length;
        data.current[categoryName][giftId] = lastPosition++;
      }
    },
    []
  );

  const giftGetRenderPosition = useCallback(
    (giftId, categoryName = "moods_on_screen") =>
      data.current[categoryName][giftId] || 0,
    []
  );

  return {
    giftSetRenderPosition,
    giftGetRenderPosition,
  };
};
