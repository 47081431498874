import React, {
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { MosContainerSkeleton } from "src/features/chat/components/Mos/MosContainerSkeleton";
import { useMosItemsForChat } from "src/features/chat/hooks/useMosItemsForChat";
import {
  GiftPlaceholder,
  GiftSource,
  MosEventFields,
} from "src/features/chat/imports/analytics";
import { MosLineup } from "src/features/chat/imports/components";
import {
  DEBOUNCE_DELAY,
  GIFT_SELECTOR,
  THROTTLE_DELAY,
} from "src/features/chat/imports/constants";
import {
  useBreakpointPrecise,
  useMosAnalytics,
} from "src/features/chat/imports/hooks";
import { RootState, mosChatV2Selectors } from "src/features/chat/imports/state";
import { GiftClickParams, GiftVariant } from "src/features/chat/imports/types";
import {
  debounce,
  getVisibleElementsIndexes,
  throttle,
} from "src/features/chat/imports/utils";
import { MosCarouselControls } from "./MosCarouselControls";
import styles from "./MosInChat.scss";

interface MosInChat {
  companionId: string;
  conversationId: string;
}

const selector = (state: RootState) => ({
  lineupId: mosChatV2Selectors.getMosChatV2LineupId(state),
  mosErrorLoading: mosChatV2Selectors.getMosChatV2Error(state),
});

export const MosInChat = ({ companionId, conversationId }: MosInChat) => {
  const [maskStyles, setMaskStyles] = useState(styles.maskRightSide);
  const { items, isLoading } = useMosItemsForChat({ companionId });
  const { mosErrorLoading, lineupId } = useSelector(selector, shallowEqual);
  const { giftClickAction, startScrollAction, endScrollAction, errorAction } =
    useMosAnalytics();

  const breakpoint = useBreakpointPrecise();
  const carouselRef = useRef<HTMLDivElement>(null);
  const isScrollingRef = useRef(false);

  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);

  useEffect(() => {
    if (mosErrorLoading) {
      errorAction({
        [MosEventFields.GIFT_SOURCE_ID]: conversationId,
        [MosEventFields.PEER_ID]: companionId,
        [MosEventFields.GIFT_SOURCE]: GiftSource.CHAT,
        [MosEventFields.REASON]: mosErrorLoading,
        [MosEventFields.GIFT_PLACEHOLDER]: GiftPlaceholder.MOS_V2,
      });
    }
  }, [mosErrorLoading]);

  const handleGiftClick = ({
    id,
    typeAbbreviation,
    position,
  }: GiftClickParams) => {
    const sendGiftAnalyticsParams = {
      [MosEventFields.PEER_ID]: companionId,
      [MosEventFields.BUCKET_ID]: typeAbbreviation,
      [MosEventFields.GIFT_SOURCE]: GiftSource.CHAT,
      [MosEventFields.GIFT_SOURCE_ID]: conversationId,
      [MosEventFields.GIFT_PLACEHOLDER]: GiftPlaceholder.MOS_V2,
      [MosEventFields.GIFT_PLACEHOLDER_ID]: lineupId,
    };

    giftClickAction({
      [MosEventFields.RULE_ID]: lineupId,
      [MosEventFields.POSITION + position]: position,
      [MosEventFields.BUCKET_ID]: typeAbbreviation,
      [MosEventFields.OBJECT_ID]: id,
      [MosEventFields.GIFT_SOURCE]: GiftSource.CHAT,
      [MosEventFields.GIFT_SOURCE_ID]: conversationId,
    });

    return sendGiftAnalyticsParams;
  };

  const handleStartScrollAction = (lineupId: string) => {
    if (isScrollingRef.current) {
      return;
    }

    startScrollAction({
      [MosEventFields.RULE_ID]: lineupId,
      [MosEventFields.GIFT_SOURCE]: GiftSource.CHAT,
      [MosEventFields.GIFT_SOURCE_ID]: conversationId,
    });

    isScrollingRef.current = true;
  };

  const handleEndScrollAction = useMemo(
    () =>
      debounce((lineupId: string) => {
        const visibleGifts = getVisibleElementsIndexes(
          carouselRef,
          GIFT_SELECTOR
        );

        endScrollAction({
          [MosEventFields.RULE_ID]: lineupId,
          [MosEventFields.GIFT_SOURCE_ID]: conversationId,
          [MosEventFields.PEER_ID]: companionId,
          [MosEventFields.GIFT_SOURCE]: GiftSource.CHAT,
          [MosEventFields.GIFT_PLACEHOLDER]: GiftPlaceholder.MOS_V2,
          [MosEventFields.MIN_COLUMN_POSITION]: visibleGifts[0],
          [MosEventFields.MAX_COLUMN_POSITION]:
            visibleGifts[visibleGifts.length - 1],
        });

        isScrollingRef.current = false;
      }, DEBOUNCE_DELAY),
    [lineupId]
  );

  const handleScrollButtonClick = (scrollOffset: number) => () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += scrollOffset;
    }
  };

  const scrollAnalyticsActions = useMemo(
    () =>
      throttle((lineupId: string) => {
        handleEndScrollAction(lineupId);

        handleStartScrollAction(lineupId);
      }, THROTTLE_DELAY),
    []
  );

  // TODO: reduce amount of initial renders for this component. JIRA ticket https://tango-me.atlassian.net/browse/WEB-8413.
  const handleLineupScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement;
      const scrollLeftMax =
        target.scrollWidth - target.getBoundingClientRect().width;

      setIsPrevBtnDisabled(target.scrollLeft <= 1);
      setIsNextBtnDisabled(scrollLeftMax - target.scrollLeft <= 1);

      if (lineupId) {
        scrollAnalyticsActions(lineupId);
      }

      if (target.scrollLeft <= 1) {
        setMaskStyles(styles.maskRightSide);

        return;
      }
      if (scrollLeftMax - target.scrollLeft <= 1) {
        setMaskStyles(styles.maskLeftSide);

        return;
      }

      setMaskStyles(styles.maskBothSides);
    },
    [lineupId]
  );

  if (isLoading) {
    return <MosContainerSkeleton />;
  }

  if (!items?.length) {
    return null;
  }

  return (
    <div className={classnames(styles.mosContainer, styles[breakpoint])}>
      <MosCarouselControls
        isPrevBtnDisabled={isPrevBtnDisabled}
        isNextBtnDisabled={isNextBtnDisabled}
        handleScroll={handleScrollButtonClick}
      >
        <MosLineup
          ref={carouselRef}
          containerClassName={classnames(styles.carousel, maskStyles)}
          onScroll={handleLineupScroll}
          handleGiftClick={handleGiftClick}
          mosItems={items}
          variant={GiftVariant.CHAT}
        />
      </MosCarouselControls>
    </div>
  );
};
