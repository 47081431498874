import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { addUserSessionScopeReducer } from "src/features/visitorCashier/imports/state";
import {
  Nullable,
  PersonalOffersConfig,
  PricePoint,
} from "src/features/visitorCashier/imports/types";
import {
  loadMarketingPersonalOffers,
  loadVisitorMarketingPersonalOffers,
} from "src/features/visitorCashier/state/asyncAction";

export interface VisitorCashierState {
  isBuyOfferAfterLogin: boolean;
  offers: Nullable<PersonalOffersConfig>;
  selectedOffer: Nullable<PricePoint>;
  visitorOffers: Nullable<PersonalOffersConfig>;
}

const initialState: VisitorCashierState = {
  visitorOffers: null,
  offers: null,
  isBuyOfferAfterLogin: false,
  selectedOffer: null,
};

const slice = createSlice({
  name: "visitorCashier",
  initialState,
  reducers: {
    setIsBuyOfferAfterLogin(state, action: PayloadAction<boolean>) {
      state.isBuyOfferAfterLogin = action.payload;
    },
    setSelectedOffer(state, action: PayloadAction<PricePoint>) {
      state.selectedOffer = action.payload;
    },
  },
  extraReducers(builder) {
    addUserSessionScopeReducer(
      builder
        .addCase(
          loadVisitorMarketingPersonalOffers.fulfilled,
          (state, action) => {
            state.visitorOffers = action.payload;
          }
        )
        .addCase(loadMarketingPersonalOffers.fulfilled, (state, action) => {
          state.offers = action.payload;
        }),
      () => initialState
    );
  },
});

export const { setIsBuyOfferAfterLogin, setSelectedOffer } = slice.actions;

export const visitorCashierReducer = slice.reducer;
