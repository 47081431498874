import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { StreamKind } from "src/types/richFragment/Stream";
import { retrieveStreamURL } from "state/actionCreators/retrieveStreamURL";
import { viewerSessionSelectors } from "state/selectors";

interface UseRetrieveStreamURLParams {
  streamId: string;
  streamURL?: string;
}

const useRetrieveStreamURL = ({
  streamId,
  streamURL,
}: UseRetrieveStreamURLParams) => {
  const dispatch = useDispatch();
  const multiBroadcastStream = useSelector(
    (state) =>
      viewerSessionSelectors.getMultiBroadcastStreamById(state, streamId),
    shallowEqual
  );
  const streamKind = useSelector(viewerSessionSelectors.getStreamKind);
  const mainStreamId = useSelector(viewerSessionSelectors.getStreamId);

  useEffect(() => {
    if (
      streamURL ||
      !multiBroadcastStream ||
      multiBroadcastStream.stream.streamURL ||
      streamKind === StreamKind.PUBLIC ||
      streamId === mainStreamId
    ) {
      return;
    }

    dispatch(retrieveStreamURL({ streamId }));
  }, [
    streamURL,
    multiBroadcastStream,
    streamId,
    streamKind,
    dispatch,
    mainStreamId,
  ]);
};

export default useRetrieveStreamURL;
