import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { MessageDateNow } from "chat/components/MessageDateNow/MessageDateNow";
import { messages } from "chat/components/common/messages";
import {
  MESSAGES_FROM_TANGO_DATE_DEFAULT,
  MESSAGES_WITH_DEFAULT_SPACING,
  MESSAGES_WITHOUT_ABSOLUTE_DATE,
} from "chat/components/currentConversation/constants";
import { TranslateIcon } from "chat/imports/assets";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import { ComponentWithClassName } from "chat/imports/types";
import { getIsMessageReadIndicatorEnabled } from "chat/soc/chatSoc";
import { StoredMessage } from "chat/state/reducer";
import { MessageType } from "chat/types";
import { ReactComponent as ReadIcon } from "src/features/chat/assets/messageReadIcon.svg";
import { ReactComponent as UnreadIcon } from "src/features/chat/assets/messageUnreadIcon.svg";
import styles from "chat/components/currentConversation/Message.scss";

interface MessageInfoIconsProps {
  isMessageRead: boolean;
  isMyMessage: boolean;
  isPending?: boolean | undefined;
  message: StoredMessage;
  preventAbsoluteFill: boolean;
  translation?: string;
}

const MessageInfoIcons: ComponentWithClassName<MessageInfoIconsProps> = ({
  message,
  translation,
  className,
  preventAbsoluteFill,
  isMyMessage,
  isPending = false,
  isMessageRead,
}) => {
  const isReadMessageIndicatorEnabled = useSelector(
    getIsMessageReadIndicatorEnabled
  );

  const areInfoIconsInAbsolutePositions =
    !MESSAGES_WITH_DEFAULT_SPACING.includes(message.type) &&
    !MESSAGES_WITHOUT_ABSOLUTE_DATE.includes(message.type) &&
    !preventAbsoluteFill;

  return (
    <div
      className={classnames(styles.infoIcons, className, {
        [styles.absolute]: areInfoIconsInAbsolutePositions,
        [styles.tangoMessage]: MESSAGES_FROM_TANGO_DATE_DEFAULT.includes(
          message.type
        ),
        [styles.profileMessageDate]:
          message.type === MessageType.PROFILE_MESSAGE,
      })}
    >
      {message.isTranslated && translation && (
        <div
          className={styles.translate}
          data-testid="chat-message-translated-icon"
        >
          <TranslateIcon className={styles.icon} />
        </div>
      )}
      {message.edited && (
        <div className={styles.translate} data-testId="edit-idicator-message">
          <Typography
            type={TYPOGRAPHY_TYPE.PARAGRAPH5}
            className={styles.edited}
          >
            <FormattedMessage {...messages.edited} />
          </Typography>
        </div>
      )}
      <div className={styles.timeAndReadStatus}>
        <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH5} className={styles.time}>
          <MessageDateNow value={Number(message.id.ts)} />
        </Typography>
        {isReadMessageIndicatorEnabled && isMyMessage && !isPending && (
          <div className={classnames(styles.readIndicator)}>
            {isMessageRead ? (
              <ReadIcon
                className={classnames(styles.readIndicatorIcon, {
                  [styles.readIndicatorForAbsoluteIcons]:
                    areInfoIconsInAbsolutePositions,
                  [styles.readIndicatorForNonAbsoluteIcons]:
                    !areInfoIconsInAbsolutePositions,
                })}
                data-testid="read-indicator-icon"
              />
            ) : (
              <UnreadIcon
                className={classnames(styles.readIndicatorIcon, {
                  [styles.readIndicatorForAbsoluteIcons]:
                    areInfoIconsInAbsolutePositions,
                  [styles.readIndicatorForNonAbsoluteIcons]:
                    !areInfoIconsInAbsolutePositions,
                })}
                data-testid="unread-indicator-icon"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageInfoIcons;
