import { sendEventToGateway } from "src/core/analytics/dataMesh/api/dataMeshEventsApi";
import { getIsLoggedIn } from "src/core/analytics/dataMesh/utils/store/getStoreEntity";

interface Payload {
  event: string;
  properties: BodyInit;
  type: string;
}

export const dataMeshPlugin = () => ({
  NAMESPACE: "data-mesh-plugin",
  track: ({ payload }: { payload: Payload }) => {
    sendEventToGateway({
      isUserLoggedIn: getIsLoggedIn(),
      body: payload.properties,
    });
  },
  loaded: () => true,
});
