import { StreamKindExternal } from "src/features/broadcastExternal/exports/enums";
import { StreamKind } from "src/types/richFragment/Stream";

const streamKindToAnalyticsStreamKind = (
  kind: StreamKind | StreamKindExternal | undefined
): number | undefined => {
  switch (kind) {
    case StreamKind.CHAT:
      return 1;
    case StreamKind.PUBLIC:
      return 2;
    case StreamKind.PRIVATE:
      return 3;
    case StreamKind.TICKET_PRIVATE:
      return 4;
    case StreamKindExternal.OBS:
      return 5;
  }
};

export default streamKindToAnalyticsStreamKind;
