import { parseBooleanEnabledSOC, parseNumberSOC } from "state/abTests";

export const getStreamerCreateStickersEnabled = parseBooleanEnabledSOC(
  "web.streamer.stickers.enabled",
  "0"
);

export const getStreamerCreateGiftStickerEnabled = parseBooleanEnabledSOC(
  "web.streamer.stickers.gift.enabled",
  "0"
);

export const getStreamerCreateImageStickerEnabled = parseBooleanEnabledSOC(
  "web.streamer.stickers.image.enabled",
  "0"
);

export const getStreamerCreateGoalStickerEnabled = parseBooleanEnabledSOC(
  "web.streamer.stickers.goal.enabled",
  "0"
);

export const getDefaultGoalStickerAmount = parseNumberSOC(
  "web.streamer.sticker.goal.default.amount",
  "1000"
);

export const getGoalStickerAmountStep = parseNumberSOC(
  "web.streamer.sticker.goal.amount.step",
  "100"
);
