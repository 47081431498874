import { enhancedFetch } from "src/features/signin/imports/api";
import { CLIENT_CAPABILITIES_ARRAY } from "src/features/signin/imports/constants";
import { getProxycadorHost } from "src/features/signin/imports/environment";

export enum DeviceTokenType {
  TANGO_TOKEN = "0",
  WEB_PUSH_API = "11",
  WEB_PUSH_TOKEN = "9",
}

interface DeviceTokenItem {
  deviceToken: string;
  deviceTokenType: DeviceTokenType;
}

interface ValidationParams {
  deviceToken: string;
  deviceTokens: DeviceTokenItem[];
  locale: string;
  osVersion: string;
}

export const validation = ({
  deviceToken,
  deviceTokens = [],
  locale,
  osVersion,
}: ValidationParams) => {
  const newDeviceTokens = [...deviceTokens];
  const isTangoTokenIncluded = newDeviceTokens.find(
    (token) => token.deviceTokenType === DeviceTokenType.TANGO_TOKEN
  );

  if (!isTangoTokenIncluded) {
    newDeviceTokens.push({
      deviceToken,
      deviceTokenType: DeviceTokenType.TANGO_TOKEN,
    });
  }

  return enhancedFetch(`${getProxycadorHost()}/foreground/validation/lwc`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      locale,
      osVersion,
      deviceTokens: newDeviceTokens,
      ...(!!deviceToken && { deviceToken }),
      clientCapabilities: CLIENT_CAPABILITIES_ARRAY,
      clientVersion: GENERATED_APP_INFO.fullVersion,
    }),
  });
};
