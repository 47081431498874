import FingerprintJS from "@fingerprintjs/fingerprintjs";
import FingerprintPro from "@fingerprintjs/fingerprintjs-pro";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IBrowser, IOS } from "ua-parser-js";
import { DeviceType } from "src/enums";
import { getIsFingerprintProEnabled } from "src/state/abTests";
import { RootState } from "src/state/delegate";
import { LanguageIsoCode } from "src/types/common";

export const deviceTypeDetected = createAction<{
  browserName: IBrowser["name"];
  browserVersion: IBrowser["version"];
  os: IOS["name"];
  type: DeviceType;
  version: IOS["version"];
}>("lwc/device/typeDetected");
export const setLocale = createAction<LanguageIsoCode>("lwc/device/setLocale");
export const setWebview = createAction<boolean>("lwc/device/setWebview");

const FINGERPRINT_PROXY_URL = "https://metricsfg.tango.me";

export const getFingerprint = createAsyncThunk<string>(
  "lwc/device/getFingerprint",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const isFingerprintProEnabled = getIsFingerprintProEnabled(state);

    if (isFingerprintProEnabled) {
      const fpPromise = await FingerprintPro.load({
        apiKey: "ZxRoNalSANiT3lHW4sCN",
        endpoint: FINGERPRINT_PROXY_URL,
        scriptUrlPattern: `${FINGERPRINT_PROXY_URL}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
      });

      const { visitorId } = await fpPromise.get();

      return Promise.resolve(visitorId);
    }

    const fp = await FingerprintJS.load();
    const { components } = await fp.get();
    const {
      colorDepth,
      colorGamut,
      screenResolution,
      screenFrame,
      contrast,
      hdr,
      forcedColors,
      monochrome,
      reducedMotion,
      fontPreferences,
      ...stableComponents
    } = components;

    return FingerprintJS.hashComponents(stableComponents);
  }
);

export const setDeviceGPUModel = createAction<string>(
  "lwc/device/setDeviceGPUModel"
);
