import { SECOND } from "src/constants";

const SERVICE_WORKER_PATH = "/service-worker";

async function registerServiceWorker(): Promise<ServiceWorkerRegistration> {
  if (!("serviceWorker" in navigator)) {
    throw new Error("ServiceWorker not supported");
  }

  return navigator.serviceWorker.register(
    `${SERVICE_WORKER_PATH}-${window.twcVersion}.js`
  );
}

async function waitForActivation(
  serviceWorkerRegistration: ServiceWorkerRegistration
): Promise<ServiceWorker> {
  return new Promise((resolve, reject) => {
    if (serviceWorkerRegistration.active) {
      return resolve(serviceWorkerRegistration.active);
    }

    if (serviceWorkerRegistration.waiting) {
      serviceWorkerRegistration.waiting.addEventListener("statechange", () => {
        if (serviceWorkerRegistration.waiting?.state === "activated") {
          resolve(serviceWorkerRegistration.waiting);
        }
      });

      return;
    }

    serviceWorkerRegistration.addEventListener("updatefound", () => {
      const newServiceWorker = serviceWorkerRegistration.installing;

      if (!newServiceWorker) {
        reject(new Error("ServiceWorker installation failed"));

        return;
      }

      newServiceWorker.addEventListener("statechange", () => {
        if (newServiceWorker.state === "activated") {
          resolve(newServiceWorker);
        }
      });
    });

    setTimeout(() => {
      reject(new Error("ServiceWorker activation timed out"));
    }, 10 * SECOND);
  });
}

export async function startServiceWorker(deviceType: string) {
  const registration = await registerServiceWorker();
  const serviceWorker = await waitForActivation(registration);

  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data && event.data.type === "NOTIFICATION_CLICKED") {
      window.location.href = event.data.url;
    }
  });

  if (serviceWorker.state === "activated") {
    serviceWorker.postMessage({ type: "INIT", deviceType });
  } else {
    throw new Error("ServiceWorker failed to activate");
  }
}
