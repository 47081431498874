import { createAsyncThunk } from "@reduxjs/toolkit";
import { enterStream } from "api/stream";
import { isApiError } from "api/utils/enhancedFetch";
import { updatePremiumMBStartWatch } from "state/actionCreators/viewerSession";
import { RootState } from "state/delegate";
import { Data } from "state/hor/addFetcherActionsToBuilder";

export const retrieveStreamURL = createAsyncThunk<
  Data<null>,
  {
    streamId: string;
  },
  { rejectValue: string; state: RootState }
>(
  "lwc/viewerSession/premium/multiBroadcast/startWatch/handleData",
  async ({ streamId }, api) => {
    try {
      const streamInfo = await enterStream(streamId);

      api.dispatch(updatePremiumMBStartWatch({ streamId, streamInfo }));
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;
      return api.rejectWithValue(error);
    }
  }
);
