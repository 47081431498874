import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { ResponseCode } from "src/types/richFragment/ResponseCode";
import { CountryInfo } from "state/types";

export const fetchNearbyCountries = (locale: string): Promise<CountryInfo[]> =>
  fetch(
    `${getProxycadorHost()}/stream/countrypicker/v1/list?locale=${locale}&returnOnlyActive=false`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json; charset=UTF-8",
      },
    }
  )
    .then((resp) => resp.json())
    .then(({ code, countries }) => {
      if (code !== ResponseCode.OK) {
        throw new Error(code);
      }

      return countries;
    });

export const updateNearbyCountries = (activeCodes: string[]) =>
  fetch(`${getProxycadorHost()}/stream/countrypicker/v1/list`, {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Accept: "application/json; charset=UTF-8",
    },
    body: JSON.stringify({ activeCodes }),
  }).then((resp) => resp.json());
