import { RefObject, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoredMessage } from "chat/messageRequest/types";
import { editChatMessage, setEditingMessageInProgress } from "chat/state";
import chatSelectors from "chat/state/selectors";

interface EditAndSendTextMessageProps {
  conversationId: string;
  setValue: (value: string) => void;
  textAreaRef: RefObject<HTMLTextAreaElement>;
  value: string;
}

export const useEditAndSendTextMessage = ({
  value,
  setValue,
  conversationId,
  textAreaRef,
}: EditAndSendTextMessageProps) => {
  const dispatch = useDispatch();
  const editingMessageInProgress = useSelector(
    chatSelectors.getEditingMessageInProgress
  );

  const cleanUpMessageEditing = () => {
    if (editingMessageInProgress) {
      setValue("");
      dispatch(setEditingMessageInProgress(null));
    }
  };

  useEffect(() => {
    if (editingMessageInProgress?.body) {
      setValue(editingMessageInProgress.body);
      textAreaRef?.current?.focus();
    }
  }, [editingMessageInProgress, setValue]);

  useEffect(() => {
    cleanUpMessageEditing();

    return cleanUpMessageEditing;
  }, [conversationId]);

  const editAndSendText = useCallback(() => {
    if (value !== editingMessageInProgress?.body) {
      dispatch(
        editChatMessage({
          message: {
            ...editingMessageInProgress,
            body: value,
          } as StoredMessage,
        })
      );
    }

    setValue("");
    dispatch(setEditingMessageInProgress(null));
  }, [dispatch, value, editingMessageInProgress, setValue]);

  return editAndSendText;
};
