import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  InvitationStatus,
  InvitationType,
} from "src/features/broadcast/enums/invites";
import { VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT } from "src/features/broadcast/imports/state";
import { Nullable } from "src/features/broadcast/imports/types";
import { isSentInvitation } from "src/features/broadcast/state/utils";

interface SenderInfo {
  accountId: string;
  bonus: number;
  firstName: string;
  lastName: string;
  previewUrl: string;
  streamId: string;
  thumbnailUrl: string;
  viewersCount: number;
}

export interface Invitation {
  id: string;
  invitationId: string;
  invitationStatus: InvitationStatus;
  invitationType: InvitationType;
  recipientAccountId: string;
  sender: SenderInfo;
  timestamp: number;
}

interface InvitationsBySender {
  [senderId: string]: Invitation[];
}

export interface InvitationState {
  error: Nullable<string>;
  invitationFlow: Nullable<InvitationType>;
  invitationsBySender: InvitationsBySender;
  lastUpdated: Nullable<number>;
  loading: boolean;
}

const isAcceptedInvitation = (status: InvitationStatus): boolean =>
  status === InvitationStatus.ACCEPTED_BY_USER;

const isMatchingInvitation = (inv: Invitation, newInv: Invitation): boolean =>
  inv.recipientAccountId === newInv.recipientAccountId &&
  inv.invitationType === newInv.invitationType;

const removeExistingSentInvitation = (
  invitations: Invitation[],
  newInv: Invitation
): Invitation[] =>
  invitations.filter(
    (inv) =>
      !(
        isMatchingInvitation(inv, newInv) &&
        isSentInvitation(inv.invitationStatus)
      )
  );

const removeAcceptedInvitationsWithActiveStream = ({
  invitations,
  activeStreamsIds,
  invitationType,
}: {
  activeStreamsIds: string[];
  invitationType: InvitationType;
  invitations: Invitation[];
}): Invitation[] =>
  invitations.filter(
    (inv) =>
      !(
        isAcceptedInvitation(inv.invitationStatus) &&
        activeStreamsIds.includes(inv.recipientAccountId) &&
        inv.invitationType === invitationType
      )
  );

const initialState: InvitationState = {
  invitationsBySender: {},
  loading: false,
  error: null,
  lastUpdated: null,
  invitationFlow: null,
};

const slice = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    setInvitationFlow: (
      state,
      action: PayloadAction<Nullable<InvitationType>>
    ) => {
      state.invitationFlow = action.payload;
      state.lastUpdated = Date.now();
    },
    resetInvitationsByType: (
      state,
      action: PayloadAction<{
        broadcastId: string;
        invitationType: InvitationType;
      }>
    ) => {
      const { broadcastId, invitationType } = action.payload;
      if (state.invitationsBySender[broadcastId]) {
        state.invitationsBySender[broadcastId] = state.invitationsBySender[
          broadcastId
        ].filter((inv) => inv.invitationType !== invitationType);
        state.lastUpdated = Date.now();
      }
    },
    removeAcceptedInvitationsForActiveStreams: (
      state,
      action: PayloadAction<{
        activeStreamsIds: string[];
        broadcastId: string;
        invitationType: InvitationType;
      }>
    ) => {
      const { broadcastId, activeStreamsIds, invitationType } = action.payload;
      if (state.invitationsBySender[broadcastId]) {
        state.invitationsBySender[broadcastId] =
          removeAcceptedInvitationsWithActiveStream({
            invitations: state.invitationsBySender[broadcastId],
            activeStreamsIds,
            invitationType,
          });
        state.lastUpdated = Date.now();
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action): action is PayloadAction<{ invitations: Invitation[] }> =>
        action.type === VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
      (state, action) => {
        if (!action.payload.invitations) {
          return;
        }

        action.payload.invitations.forEach((newInv) => {
          const broadcastId = newInv.sender.streamId;

          if (!state.invitationsBySender[broadcastId]) {
            state.invitationsBySender[broadcastId] = [];
          }

          if (newInv.invitationStatus === InvitationStatus.REJECTED_BY_USER) {
            state.invitationsBySender[broadcastId] =
              removeExistingSentInvitation(
                state.invitationsBySender[broadcastId],
                newInv
              );

            return;
          }

          if (
            isAcceptedInvitation(newInv.invitationStatus) ||
            isSentInvitation(newInv.invitationStatus)
          ) {
            state.invitationsBySender[broadcastId] = [
              ...removeExistingSentInvitation(
                state.invitationsBySender[broadcastId],
                newInv
              ),
              newInv,
            ];
          }
        });

        state.lastUpdated = Date.now();
        state.loading = false;
      }
    );
  },
});

export const {
  removeAcceptedInvitationsForActiveStreams,
  resetInvitationsByType,
  setInvitationFlow,
} = slice.actions;

export const invitationsReducer = slice.reducer;
