import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emitChatMenuOptionClicked } from "chat/analytics/emitChatMenuOptionClicked";
import MessageMenuOptionBase from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuOptionBase";
import { messages } from "chat/components/common/messages";
import { MAX_PIN_CHATS_NUMBER } from "chat/constants";
import { CHAT_POPOVER_OPTIONS } from "chat/constants/chatMenuOptionsConfig";
import { UIActionMenuTarget } from "chat/enums";
import { chatSelectors } from "chat/exports/state/selectors";
import { ToastType } from "chat/imports/constants";
import { useToast } from "chat/imports/hooks";
import { StoredConversation, pinConversation } from "chat/state";

export interface PinOptionProps {
  conversation: StoredConversation;
}

const PinOption: FC<PinOptionProps> = ({ conversation }) => {
  const dispatch = useDispatch();
  const isPinned = conversation.isPinned;
  const { notify } = useToast();
  const pinnedConversationsCount = useSelector(
    chatSelectors.getPinnedConversationsCount
  );

  const onPinHandler = () => {
    const shouldPin = !isPinned;
    const target = shouldPin
      ? UIActionMenuTarget.PIN_CHAT
      : UIActionMenuTarget.UNPIN_CHAT;

    if (pinnedConversationsCount >= MAX_PIN_CHATS_NUMBER && shouldPin) {
      notify({
        type: ToastType.REGULAR,
        title: messages.maxPins,
      });

      return;
    }

    dispatch(
      pinConversation({
        conversationId: conversation.conversation_id,
        pin: shouldPin,
      })
    );

    emitChatMenuOptionClicked({
      target,
      chatId: conversation.conversation_id,
      isFamily: !!conversation.group_info?.familyId,
    });
  };

  return (
    <MessageMenuOptionBase
      option={isPinned ? CHAT_POPOVER_OPTIONS.unpin : CHAT_POPOVER_OPTIONS.pin}
      onClick={onPinHandler}
    />
  );
};

export default PinOption;
