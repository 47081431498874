export const getVideoInfo = (
  videoFile: File,
  generateThumbnail: boolean = false
) =>
  new Promise<{
    duration: number;
    height: number;
    loadingThumbnail?: string;
    width: number;
  }>((resolve) => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    video.onloadedmetadata = function () {
      const duration = Math.round(video.duration);
      const width = video.videoWidth;
      const height = video.videoHeight;

      let loadingThumbnail;

      if (generateThumbnail) {
        canvas.width = width;
        canvas.height = height;
        video.currentTime = 0.5;

        video.onseeked = function () {
          context?.drawImage(video, 0, 0, width, height);
          loadingThumbnail = canvas.toDataURL("image/jpeg", 0.5);

          URL.revokeObjectURL(video.src);

          resolve({ duration, width, height, loadingThumbnail });
        };
      } else {
        URL.revokeObjectURL(video.src);
        resolve({ duration, width, height });
      }
    };

    video.src = URL.createObjectURL(videoFile);
    video.preload = "metadata";
    video.load();
  });
