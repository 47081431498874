import { defineMessages } from "react-intl";
import { AgencyLevel } from "src/features/referralProgram/common/enums";

export const ReferralProgramMessages = defineMessages({
  agency: {
    id: "agency",
    defaultMessage: "Agency",
  },
  referralProgram: {
    id: "header.dropdown.referral-program",
    defaultMessage: "Referral Program",
  },
  referredUsers: {
    id: "referral.dashboard.referred.users",
    defaultMessage: "Referred Users",
  },
  totalReferrals: {
    id: "referral.dashboard.referred.users.plural",
    defaultMessage: "{totalUsers} Total Referrals",
  },
  totalReferralsSingular: {
    id: "referral.dashboard.referred.users.singular",
    defaultMessage: "1 Total Referral",
  },
  showingOf: {
    id: "referral.dashboard.showing",
    defaultMessage: "Showing {currentStart}-{currentEnd} of {total}",
  },
  ofTotal: {
    id: "referral.dashboard.ofTotal",
    defaultMessage: "of {total}",
  },
  invitedUsers: {
    id: "referral.dashboard.invited.users",
    defaultMessage: "Invited Users",
  },
  inviteCreators: {
    id: "invite.creators",
    defaultMessage: "Invite Creators",
  },
  inviteSupporters: {
    id: "invite.supporters",
    defaultMessage: "Invite Supporters",
  },
  username: {
    id: "referral.dashboard.user",
    defaultMessage: "User",
  },
  streamDays: {
    id: "referral.dashboard.stream.days",
    defaultMessage: "Streaming Days",
  },
  streamHours: {
    id: "referral.dashboard.stream.hours",
    defaultMessage: "Streaming Hours",
  },
  earned: {
    id: "profile.header.stats.section.earned",
    defaultMessage: "Earned",
  },
  redeem: {
    id: "referral.dashboard.redeem",
    defaultMessage: "Redeem",
  },
  redeemed: {
    id: "referral.dashboard.redeemed",
    defaultMessage: "Redeemed",
  },
  yourEarnings: {
    id: "referral.dashboard.your.earnings",
    defaultMessage: "Your earnings",
  },
  myEarnings: {
    id: "referral.dashboard.my.earnings",
    defaultMessage: "My earnings",
  },
  referAndEarn: {
    id: "referral.refer.and.earn",
    defaultMessage: "Refer & Earn",
  },
  noEarnings: {
    id: "referral.no.earnings",
    defaultMessage: "No Earnings Yet",
  },
  earningsFromTango: {
    id: "referral.earnings.from.tango",
    defaultMessage: "Earnings from Tango",
  },
  total: {
    id: "leaderboard.total",
    defaultMessage: "Total",
  },
  creator: {
    id: "referral.dashboard.creator",
    defaultMessage: "Creator",
  },
  previous: {
    id: "referral.dashboard.navigation.previous",
    defaultMessage: "Previous",
  },
  next: {
    id: "referral.dashboard.navigation.next",
    defaultMessage: "Next",
  },
  totalCreators: {
    id: "referral.dashboard.total.creators",
    defaultMessage: "Total Creators: {count}",
  },
  totalSupporters: {
    id: "referral.dashboard.total.supporters",
    defaultMessage: "Total Supporters: {count}",
  },
  creatorsCardTitle: {
    id: "referral.creators.card.title",
    defaultMessage: "Up to +{bonusPercent}% Diamonds from Earnings",
  },
  creatorsCardBody: {
    id: "referral.creators.card.body",
    defaultMessage:
      "Get up to {bonusPercent}%💎 of Referred Creators’ withdrawals for 24 months",
  },
  creatorsCardAction: {
    id: "invite.creators",
    defaultMessage: "Invite Creators",
  },
  supportersCardTitle: {
    id: "referral.supporters.card.title",
    defaultMessage: "+5% Diamonds from Spendings",
  },
  supportersCardBody: {
    id: "referral.supporters.card.body",
    defaultMessage: "Get 5%💎 of Referred Supporters’ purchases for 6 months",
  },
  supportersCardAction: {
    id: "invite.supporters",
    defaultMessage: "Invite Supporters",
  },
  howItWorks: {
    id: "how.it.works",
    defaultMessage: "How It Works",
  },
  termsAndConditions: {
    id: "terms_and_conditions",
    defaultMessage: "Terms & Conditions",
  },
  creatorsStep1: {
    id: "how.it.works.creators.step.1",
    defaultMessage: "Invite creators to install Tango",
  },
  creatorsStep2: {
    id: "how.it.works.creators.step.2",
    defaultMessage: "Help them earn Diamonds",
  },
  creatorsStep3: {
    id: "how.it.works.creators.step.3",
    defaultMessage: "Show them how to withdraw",
  },
  creatorsStep4: {
    id: "how.it.works.creators.step.4",
    defaultMessage: "Get your reward!",
  },
  creatorsStep1Description: {
    id: "how.it.works.creators.step.1.description",
    defaultMessage:
      "Connected creators will appear in your referral page once they install using your Referral Link",
  },
  creatorsStep2Description: {
    id: "how.it.works.creators.step.2.description",
    defaultMessage:
      "Track your referred creators' success and see their progress in referral page",
  },
  creatorsStep3Description: {
    id: "how.it.works.creators.step.3.description",
    defaultMessage:
      "Monitor each referred creators' withdrawals in your referral page",
  },
  creatorsStep4Description: {
    id: "how.it.works.creators.step.4.description",
    defaultMessage:
      "Earn up to 15% Bonus Diamonds when each creator you refer makes a withdrawal for 24 months (12 months for referrals who joined before 01.12.2024).",
  },
  supportersStep1: {
    id: "how.it.works.supporters.step.1",
    defaultMessage: "Invite supporters to install Tango",
  },
  supportersStep1Description: {
    id: "how.it.works.supporters.step.1.description",
    defaultMessage:
      "Connected supporters will appear in your referral page once they install using your Referral Link",
  },
  supportersStep2: {
    id: "how.it.works.supporters.step.2",
    defaultMessage: "Help them purchase Coins",
  },
  supportersStep2Description: {
    id: "how.it.works.supporters.step.2.description",
    defaultMessage:
      "Track total bonuses for referred supporters purchases in your referral page",
  },
  supportersStep3Description: {
    id: "how.it.works.supporters.step.3.description",
    defaultMessage:
      "Earn +5% Bonus Diamonds when each supporter you refer makes a purchase for 6 months",
  },
  monthEarnings: {
    id: "referral.month.earnings",
    defaultMessage: "This Month's Earnings",
  },
  lifetimeEarnings: {
    id: "referral.lifetime.earnings",
    defaultMessage: "Lifetime Earnings",
  },
  viewTiers: {
    id: "referral.view.tiers",
    defaultMessage: "Tiers",
  },
  agencyTiers: {
    id: "referral.agency.tiers",
    defaultMessage: "Agency Tiers",
  },
  criteria: {
    id: "referral.criteria",
    defaultMessage: "Criteria",
  },
  benefits: {
    id: "referral.benefits",
    defaultMessage: "Benefits",
  },
  [AgencyLevel.BRONZE]: {
    id: "referral.agency.tiers.bronze",
    defaultMessage: "Bronze",
  },
  [AgencyLevel.SILVER]: {
    id: "referral.agency.tiers.silver",
    defaultMessage: "Silver",
  },
  [AgencyLevel.GOLD]: {
    id: "referral.agency.tiers.gold",
    defaultMessage: "Gold",
  },
  [AgencyLevel.ELITE]: {
    id: "referral.agency.tiers.elite",
    defaultMessage: "Elite",
  },
  [AgencyLevel.ROYAL]: {
    id: "referral.agency.tiers.royal",
    defaultMessage: "Royal",
  },
  [AgencyLevel.ROOKIE]: {
    id: "referral.agency.tiers.rookie",
    defaultMessage: "Rookie",
  },
  revenueShare: {
    id: "referral.agency.tiers.revenueShare",
    defaultMessage: "{percent}% Revenue Share",
  },
  onFireTitle: {
    id: "referral.promotion.on.fire.title",
    defaultMessage: "Wow, You're on Fire!",
  },
  onFireDescription: {
    id: "referral.promotion.on.fire.description",
    defaultMessage: "Your achievements qualify you for {agencyName} next month",
  },
  actFastTitle: {
    id: "referral.promotion.act.fast.title",
    defaultMessage: "Act Fast to Keep Your Status!",
  },
  actFastDescription: {
    id: "referral.promotion.act.fast.description",
    defaultMessage: "Achieve monthly criteria to stay {agencyName} next month",
  },
  hurryUpTitle: {
    id: "referral.promotion.act.fast.title",
    defaultMessage: "Act Fast to Keep Your Status!",
  },
  hurryUpDescription: {
    id: "referral.promotion.act.fast.description",
    defaultMessage: "Achieve monthly criteria to stay {agencyName} next month",
  },
  hundredPercentTitle: {
    id: "referral.promotion.hundred.percent.title",
    defaultMessage: "Act Fast to Upgrade Your Status!",
  },
  hundredPercentDescription: {
    id: "referral.promotion.hundred.percent.description",
    defaultMessage:
      "Achieve monthly criteria to upgrade to {agencyName} next month",
  },
  wellDoneTitle: {
    id: "referral.promotion.well.done.title",
    defaultMessage: "Well Done!",
  },
  wellDoneDescription: {
    id: "referral.promotion.on.fire.description",
    defaultMessage: "Your achievements qualify you for {agencyName} next month",
  },
  wellDoneDescriptionV2: {
    id: "referral.promotion.well.done.description",
    defaultMessage: "You maintained your Status for next month",
  },
  upgradeTitle: {
    id: "referral.promotion.upgrade.title",
    defaultMessage: "Upgrade Your Status!!",
  },
  upgradeDescription: {
    id: "referral.promotion.upgrade.description",
    defaultMessage: "Achieve this Status's goals to reach it next month",
  },
  maintainTitle: {
    id: "referral.promotion.maintain.title",
    defaultMessage: "Maintain Your Status!",
  },
  maintainDescription: {
    id: "referral.promotion.maintain.description",
    defaultMessage: "Achieve your goals to maintain your Status next month",
  },
  achievedTitle: {
    id: "referral.promotion.achieved.title",
    defaultMessage: "Status Achieved!",
  },
  achievedDescription: {
    id: "referral.promotion.achieved.description",
    defaultMessage: "You successfully achieved this Status",
  },
  redeemRevenues: {
    id: "referral.kpi.redeem.revenues",
    defaultMessage: "Creators’ Redeemed Revenues",
  },
  redeemedRevenues: {
    id: "redeemed.revenues",
    defaultMessage: "Redeemed Revenues",
  },
  monthlyRevenueGrowthPercent: {
    id: "referral.kpi.monthly.revenue.percent",
    defaultMessage: "Monthly Revenue Growth (+{targetPercent}% of Last Month)",
  },
  monthlyRevenueGrowth: {
    id: "referral.kpi.monthly.revenue",
    defaultMessage: "Monthly Revenue Growth",
  },
  firstTimeRedeemers: {
    id: "referral.kpi.first.time.redeemers",
    defaultMessage: "First-Time Redeemers",
  },
  cashNowTitle: {
    id: "referral.agency.tiers.benefits.cash.now.title",
    defaultMessage: "Cash Now",
  },
  cashNowDescription: {
    id: "referral.agency.tiers.benefits.cash.now.description",
    defaultMessage: "INSTANT Tango Diamond payments when your Creators redeem!",
  },
  exclusiveStatusTitle: {
    id: "referral.agency.tiers.benefits.exclusive.status.title",
    defaultMessage: "Exclusive Status",
  },
  exclusiveStatusDescription: {
    id: "referral.agency.tiers.benefits.exclusive.status.description",
    defaultMessage:
      "Personalized ribbons, custom gifts, and new features to highlight your Agency's prestige.",
  },
  promotionalSupportTitle: {
    id: "referral.agency.tiers.benefits.promotional.support.title",
    defaultMessage: "Promotional Support",
  },
  promotionalSupportDescription: {
    id: "referral.agency.tiers.benefits.promotional.support.description",
    defaultMessage:
      "Dedicated Partnership Manager, special events, marketing budgets and a variety of growth opportunities tailored for you.",
  },
  monetaryAdvantagesTitle: {
    id: "referral.agency.tiers.benefits.monetary.advantages.title",
    defaultMessage: "Monetary Advantages",
  },
  monetaryAdvantagesDescription: {
    id: "referral.agency.tiers.benefits.monetary.advantages.description",
    defaultMessage:
      "Increased RevShare percentages and new Bonus plans to help you increase monthly revenues.",
  },
  realTimeMonitoringTitle: {
    id: "referral.agency.tiers.real.time.monitoring.title",
    defaultMessage: "Real-Time Monitoring",
  },
  realTimeMonitoringDescription: {
    id: "referral.agency.tiers.benefits.real.time.monitoring.description",
    defaultMessage:
      "Track Creators’ activity directly in the Tango App - no more 3rd party hassle.",
  },
  criteriaAchieved: {
    id: "referral.criteria.achieved",
    defaultMessage: "{count} Criteria Achieved",
  },
  goalsAchieved: {
    id: "goals.achieved",
    defaultMessage: "Goals Achieved",
  },
  tier: {
    id: "referral.tier",
    defaultMessage: "Tier",
  },
  timeRange: {
    id: "time.range",
    defaultMessage: "Time Range",
  },
  downloadCompleted: {
    id: "download.completed",
    defaultMessage: "Download completed",
  },
  downloadFailed: {
    id: "download.failed",
    defaultMessage: "Download failed",
  },
  achieved: {
    id: "vip_lounge_status_achieved",
    defaultMessage: "Achieved!",
  },
  goals: {
    id: "goals",
    defaultMessage: "Goals",
  },
  myStatistics: {
    id: "my.statistics",
    defaultMessage: "My Statistics",
  },
  contactSupport: {
    id: "contact.support",
    defaultMessage: `Need support? {linebreak}Contact your Account Manager or Agency Support at {email} anytime.`,
  },
  dontWasteMoment: {
    id: "dont.waste.moment",
    defaultMessage:
      "Don't waste another moment.\nInvite your 1st Creator & start earning big!",
  },
});

export const FaqQuestionsMessages = defineMessages({
  questionWhatAreDiamonds: {
    id: "faq.question.whatAreDiamonds",
    defaultMessage: "What are Diamonds?",
  },
  questionHowGetPaidAgency: {
    id: "faq.question.howGetPaidAgency",
    defaultMessage: "How will I get paid as an Agency?",
  },
  questionTrackReferrals: {
    id: "faq.question.trackReferrals",
    defaultMessage: "How do I track my referrals?",
  },
  questionEarningTimeline: {
    id: "faq.question.earningTimeline",
    defaultMessage: "What’s the earning timeline?",
  },
  questionCreatorsCheckEarnings: {
    id: "faq.question.creatorsCheckEarnings",
    defaultMessage: "How can Creators check their earnings and withdrawals?",
  },
  questionSuccessMetrics: {
    id: "faq.question.successMetrics",
    defaultMessage:
      "What metrics should I focus on to measure my success as an Agency?",
  },
  questionAgencyIncentives: {
    id: "faq.question.agencyIncentives",
    defaultMessage: "Are there incentives for Agencies that perform well?",
  },
});

export const FaqAnswersMessages = defineMessages({
  answerWhatAreDiamonds: {
    id: "faq.answer.whatAreDiamonds",
    defaultMessage:
      "Diamonds are Tango’s virtual currency for Creators. When Viewers send gifts, they are converted into Diamonds, which Creators can redeem for real money.",
  },
  answerHowGetPaidAgency: {
    id: "faq.answer.howGetPaidAgency",
    defaultMessage:
      "As an Agency, you instantly receive your commission in Diamonds when your Creators redeem their earnings. You can then convert your Diamonds into real money through Tango’s withdrawal methods. Payments are secure, immediate, and offer multiple payout options.",
  },
  answerTrackReferrals: {
    id: "faq.answer.trackReferrals",
    defaultMessage:
      "You can easily monitor your referred Creators' performance, earnings, and withdrawals by navigating to the 'My Statistics' section in your Agency Program page. Click the 'View All' button for a detailed breakdown of each of your Creators’ activity.",
  },
  answerEarningTimeline: {
    id: "faq.answer.earningTimeline",
    defaultMessage:
      "You can earn up to 15% Bonus Diamonds on each referred Creator’s withdrawals for 24 months (or 12 months for referrals who joined before December 1, 2024).",
  },
  answerCreatorsCheckEarnings: {
    id: "faq.answer.creatorsCheckEarnings",
    defaultMessage:
      "Creators can track their income, Diamonds, received gifts, and overall performance in the 'Statistics' section located in the settings menu, accessible from their Profile Avatar. For pending withdrawals and withdrawal history, Creators can check the 'Get Money' section in the settings menu, accessible from their Profile Avatar.",
  },
  answerSuccessMetrics: {
    id: "faq.answer.successMetrics",
    defaultMessage:
      "Your key performance metrics include:\n• Creators’ Redeemed Revenue – The total earnings withdrawn by your referred Creators.\n• Monthly Revenue Growth – The percentage increase in earnings compared to the previous month.\n• First-Time Redeemers – The number of newly referred Creators who have successfully made their first Tango withdrawal.",
  },
  answerAgencyIncentives: {
    id: "faq.answer.agencyIncentives",
    defaultMessage:
      "Yes! Tango offers performance-based bonuses and commissions for Agencies that meet or exceed their recruitment and revenue targets.",
  },
});

export default ReferralProgramMessages;
