import React, { FC, MouseEventHandler } from "react";
import classnames from "classnames";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { Gift } from "chat/imports/components";
import { ContentStatus } from "chat/premiumMessage/types";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { MessageDuration } from "./MessageDuration";
import { MessageStatus } from "./MessageStatus";
import styles from "../messages/ViewerUnlockedMessage.scss";
import commonStyles from "./common.scss";

interface SenderMessageOverlayProps {
  duration?: number;
  giftId: string;
  messageConfig: MessageConfig;
  onClick?: MouseEventHandler<HTMLDivElement>;
  status: ContentStatus;
}

export const SenderMessageOverlay: FC<SenderMessageOverlayProps> = ({
  duration,
  status,
  giftId,
  onClick,
  messageConfig,
}) => (
  <div
    className={classnames(
      styles.overlay,
      getGroupMessageClassnames(styles, messageConfig)
    )}
    onClick={onClick}
  >
    {!!duration && <MessageDuration duration={duration} />}

    <MessageStatus status={status} />

    <Gift id={giftId} className={commonStyles.gift} giftSendDisabled />
  </div>
);
