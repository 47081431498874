import { useState, useEffect } from "react";

const STALL_DURATION_TILL_RETRY = 10000;

export const usePlayerStalledWatchdog = (
  url,
  playbackStalled,
  playbackPaused
) => {
  const [keyBuster, setKeyBuster] = useState(0);
  useEffect(() => {
    if (playbackPaused || !playbackStalled) {
      return;
    }
    const timeoutHandle = setTimeout(() => {
      setKeyBuster((x) => x + 1);
    }, STALL_DURATION_TILL_RETRY);
    return () => clearTimeout(timeoutHandle);
  }, [url, playbackStalled, playbackPaused]);

  return `${url}-${keyBuster}`;
};

export default usePlayerStalledWatchdog;
