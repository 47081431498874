import React, { FC, forwardRef, useCallback, useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { MediaEngine, Player, Presenter } from "@video/video-framework";
import { UAParser } from "ua-parser-js";
import { getRealProxycadorHost } from "src/environment";
import { getTangoPlayerConfig } from "src/state/SOC/playback";
import { RootState } from "src/state/delegate";
import {
  connectionManagerSelectors,
  userSelectors,
  viewerSessionSelectors,
} from "src/state/selectors";
import { Nullable, VoidCallback } from "src/types/common";
import { useUnmount } from "src/utils/miniReactUse";
import { PlayerProps } from "./PlayerProps";

const mediaEngine = new MediaEngine(
  MediaEngine.makeDumpsterAnalyticsReporter(
    `${getRealProxycadorHost()}/dumpster/event`
  ),
  new UAParser()
);

interface Context {
  applicationVersion: string;
  streamId: string;
  userAccount: string;
  userId: string;
}

class LWCPlayerAdapter {
  private muted: boolean = false;
  private player: Nullable<Player> = null;
  private presenter: Nullable<Presenter> = null;
  private url: string;

  constructor(url: string, muted: boolean) {
    this.url = url;
    this.muted = muted;
  }

  mute() {
    this.muted = true;
    this.player?.mute();
  }

  pause() {
    this.player?.close();
    this.player = null;
  }

  play(context: Context, listeners?: Record<string, VoidCallback>) {
    if (this.player === null) {
      this.player = mediaEngine.play(
        this.url,
        {
          muted: this.muted,
        },
        context
      );
      listeners?.play?.();
      if (this.presenter) {
        this.player.presenter = this.presenter;
      }
    }
  }

  setPresenter(presenter: Presenter) {
    this.presenter = presenter;
    if (this.player) {
      this.player.presenter = this.presenter;
    }
  }

  unmute() {
    this.muted = false;
    this.player?.unmute();
  }

  get src(): string {
    return this.url;
  }
}

function isValidUrl(src: string): boolean {
  try {
    const url = new URL(src);

    return Boolean(url);
  } catch (e) {
    return false;
  }
}

function isEmptyString(str: string): boolean {
  return str.trim().length === 0;
}

const selector = (state: RootState) => ({
  streamId: viewerSessionSelectors.getStreamId(state),
  accountId: userSelectors.getMyAccountId(state),
  username: connectionManagerSelectors.getUsername(state),
});

const TangoPlayer: FC<PlayerProps> = ({
  forwardedRef,
  src,
  poster,
  muted,
  videoEventListeners,
  forceDisableHd,
  paused,
  ...rest
}) => {
  const { streamId, accountId, username } = useSelector(selector, shallowEqual);

  const tangoPlayerConfig = useSelector(getTangoPlayerConfig, shallowEqual);

  const player = useRef<Nullable<LWCPlayerAdapter>>(null);

  const destroyPlayer = () => {
    player.current?.pause();
    player.current = null;
  };

  const createPlayer = useCallback(
    (src: string) => {
      if (!isValidUrl(src) || src === player.current?.src) {
        return;
      }

      destroyPlayer();
      player.current = new LWCPlayerAdapter(src, false);
      const canvas = (forwardedRef as React.MutableRefObject<HTMLCanvasElement>)
        .current;
      canvas.getContext("2d")?.clearRect(0, 0, canvas.width, canvas.height);
      player.current.setPresenter(mediaEngine.createCanvasPresenter(canvas));
      mediaEngine.applyDebugOptions(tangoPlayerConfig);
    },
    [forwardedRef, tangoPlayerConfig]
  );

  useEffect(() => {
    muted ? player.current?.mute() : player.current?.unmute();
  }, [muted]);

  useEffect(() => {
    if (
      isEmptyString(accountId) ||
      isEmptyString(streamId) ||
      isEmptyString(username) ||
      isEmptyString(src)
    ) {
      return;
    }

    if (!paused) {
      createPlayer(src);
      player.current?.play(
        {
          streamId,
          userAccount: accountId,
          userId: username,
          applicationVersion: window?.twcVersion ?? "N/A",
        },
        videoEventListeners
      );
    } else {
      destroyPlayer();
    }
  }, [
    accountId,
    createPlayer,
    paused,
    src,
    streamId,
    username,
    videoEventListeners,
  ]);

  useUnmount(() => {
    destroyPlayer();
  });

  return <canvas ref={forwardedRef} width={720} height={1280} {...rest} />;
};

const ref = forwardRef<HTMLCanvasElement, PlayerProps>((props, ref) => (
  <TangoPlayer {...props} forwardedRef={ref} />
));
ref.displayName = "TangoPlayer";

export default ref;
