import { v4 as uuidv4 } from "uuid";
import { ClientSessionEvent } from "generated/proto/ClientSessionEventModel";
import {
  EventNames,
  StorageKeys,
} from "src/core/analytics/dataMesh/common/enums";
import { dataMeshParamsStorage } from "src/core/analytics/dataMesh/utils/dbMethods/dataMeshParamsStorage";
import { getSessionStartParams } from "src/core/analytics/dataMesh/utils/dbMethods/getSessionStartParams";
import { sendEventUtil } from "src/core/analytics/dataMesh/utils/sendEventUtil";
import { getSessionStartPayloadFromStore } from "src/core/analytics/dataMesh/utils/store/getSessionStartPayloadFromStore";
import { currentTimeMillis } from "src/core/analytics/imports/utils";

export const startNewSession = async (
  isSkipTimeCheck = false
): Promise<void> => {
  const timestamp = currentTimeMillis();
  const sessionStartParams = await getSessionStartParams();
  const sessionStartTime = sessionStartParams[StorageKeys.SESSION_START_TIME];
  let clientSessionId = sessionStartParams[StorageKeys.SESSION_START_ID];

  if (
    !isSkipTimeCheck &&
    clientSessionId &&
    !!sessionStartTime &&
    timestamp < Number(sessionStartTime)
  ) {
    return;
  }

  clientSessionId = uuidv4();

  const newSessionStartParams = {
    [StorageKeys.SESSION_START_TIME]:
      sessionStartParams[StorageKeys.SESSION_START_TIME],
    [StorageKeys.SESSION_START_ID]: clientSessionId,
  };

  await dataMeshParamsStorage.setItem(
    StorageKeys.SESSION_START_PARAMS,
    JSON.stringify(newSessionStartParams)
  );

  const payload = getSessionStartPayloadFromStore();

  await dataMeshParamsStorage.setItem(
    StorageKeys.SESSION_START_PAYLOAD,
    JSON.stringify(payload)
  );

  sendEventUtil({
    eventName: EventNames.CLIENT_SESSION,
    payload: ClientSessionEvent.encode(payload).finish(),
    eventUid: clientSessionId,
    clientSessionId,
    timestamp,
  });
};
