import {
  ensureHttps,
  isAlternativeDomain,
  replaceDomainWithHostname,
} from "src/features/giftsDrawer/imports/utils";
import { Category } from "./action";

const ensureHttpsOnCategory = ({ activeIcon, icon, ...rest }: Category) => ({
  ...rest,
  activeIcon: ensureHttps(activeIcon),
  icon: ensureHttps(icon),
});

const replaceAlternativeDomainIconUrl = ({
  activeIcon,
  icon,
  ...rest
}: Category) => ({
  ...rest,
  activeIcon: replaceDomainWithHostname(activeIcon),
  icon: replaceDomainWithHostname(icon),
});

export const transformCategory = (category: Category) => {
  const ensuredHttpsCategory = ensureHttpsOnCategory(category);

  if (isAlternativeDomain()) {
    return replaceAlternativeDomainIconUrl(ensuredHttpsCategory);
  }

  return ensuredHttpsCategory;
};
