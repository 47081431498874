import React, { FC, memo, useCallback, useState } from "react";
import classnames from "classnames";
import { ReactComponent as EyeIcon } from "chat/assets/eye.svg";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { useAlternativeDomainContentSupport } from "chat/hooks/useAlternativeDomainContentSupport";
import { Button } from "chat/imports/components";
import { ButtonSize, ButtonVariant } from "chat/imports/constants";
import { useLightbox } from "chat/imports/context";
import { ContentType, PremiumMessageDetails } from "chat/premiumMessage/types";
import { disableEventBubbling } from "chat/utils/disableEventBubbling";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { MessageDuration } from "../overlays/MessageDuration";
import PremiumImage from "./PremiumImage";
import PremiumVideo from "./PremiumVideo";
import styles from "./ViewerUnlockedMessage.scss";

interface ViewerUnlockedMessageProps {
  detail: PremiumMessageDetails;
  messageConfig: MessageConfig;
}

const ViewerUnlockedMessage: FC<ViewerUnlockedMessageProps> = ({
  detail,
  messageConfig,
}) => {
  const [isTempBlurShow, setIsTempBlurShow] = useState(true);

  const { mediaInfo, type } = detail || {};
  const { contentUrl, width, height, duration } = mediaInfo || {};
  const changedContentUrl = useAlternativeDomainContentSupport(contentUrl);

  const openLightbox = useLightbox();

  const handleImageClick = useCallback(() => {
    // @ts-ignore TODO: WEB-3243 rewrite to ts
    changedContentUrl &&
      openLightbox({
        photos: [changedContentUrl],
      });
  }, [changedContentUrl, openLightbox]);

  const handleHideTempBlur = useCallback(() => setIsTempBlurShow(false), []);

  const components = {
    [ContentType.VIDEO]: (
      <PremiumVideo
        isShowOnlyPreview={isTempBlurShow}
        media={mediaInfo}
        messageConfig={messageConfig}
      />
    ),
    [ContentType.PHOTO]: contentUrl && (
      <PremiumImage
        url={contentUrl}
        width={width}
        height={height}
        onClick={handleImageClick}
        messageConfig={messageConfig}
      />
    ),
  };

  return (
    <div className={styles.root} data-testid="premium-message-viewer-unlocked">
      {isTempBlurShow && (
        <div
          className={classnames(
            styles.overlay,
            styles.blur,
            getGroupMessageClassnames(styles, messageConfig)
          )}
        >
          {!!duration && <MessageDuration duration={duration} />}
          <Button
            data-testid="hide-blur-button"
            variant={ButtonVariant.SYSTEM_DARK}
            size={ButtonSize.CIRCLE_BIG_48}
            onClick={handleHideTempBlur}
            onContextMenuCapture={disableEventBubbling}
            onMouseDown={disableEventBubbling}
            onTouchStart={disableEventBubbling}
          >
            <EyeIcon />
          </Button>
        </div>
      )}

      {components[type]}
    </div>
  );
};

export default memo(ViewerUnlockedMessage);
