import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash.isempty";
import { CHAT_EVENTS_DEFAULT_TIMESTAMP } from "chat/constants";
import { CHAT_EVENTS_TYPES } from "chat/enums";
import { chatSelectors } from "chat/exports/state/selectors";
import { useMount } from "chat/imports/hooks";
import { RootState } from "chat/imports/state";
import {
  getIsChatDeleteMessageEnabled,
  getIsChatEditMessageEnabled,
  getIsChatEventsEnabled,
  getIsDeleteChatEnabled,
  getIsMuteChatEnabled,
} from "chat/soc/chatSoc";
import {
  deleteChatFromChatEvent,
  deleteMessages,
  fetchChatEvents,
  fetchMessagesById,
  muteChatFromChatEvents,
} from "chat/state";

const selector = (state: RootState) => ({
  chatEventsStatus: chatSelectors.getChatEventsStatus(state),
  lastEventTs: chatSelectors.getLastChatEventTs(state),
  currentConversationId: chatSelectors.getCurrentConversationId(state),
  chatEvents: chatSelectors.getChatEvents(state),
  isEditMessagesEnabled: getIsChatEditMessageEnabled(state),
  isMuteChatEnabled: getIsMuteChatEnabled(state),
  isDeleteMessagesEnabled: getIsChatDeleteMessageEnabled(state),
  isChatEventsEnabled: getIsChatEventsEnabled(state),
  isDeleteChatEnabled: getIsDeleteChatEnabled(state),
});

export const useChatEvents = () => {
  const {
    chatEventsStatus,
    isDeleteMessagesEnabled,
    isEditMessagesEnabled,
    lastEventTs,
    currentConversationId,
    chatEvents,
    isChatEventsEnabled,
    isMuteChatEnabled,
    isDeleteChatEnabled,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(chatEvents)) {
      for (const conversation in chatEvents) {
        if (
          chatEvents[conversation].DELETE_CHAT_FOR_ME &&
          isDeleteChatEnabled
        ) {
          dispatch(deleteChatFromChatEvent({ conversationId: conversation }));
        }
      }
    }
  }, [chatEvents, dispatch, isDeleteChatEnabled]);

  useEffect(() => {
    if (currentConversationId && chatEvents[currentConversationId]) {
      const currentConversationEvents = chatEvents[currentConversationId];

      if (
        isChatEventsEnabled &&
        isDeleteMessagesEnabled &&
        (currentConversationEvents[CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ME] ||
          currentConversationEvents[CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ALL])
      ) {
        dispatch(
          deleteMessages({
            messages: [
              ...(currentConversationEvents[
                CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ME
              ] ?? []),
              ...(currentConversationEvents[
                CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ALL
              ] ?? []),
            ],
          })
        );
      }

      if (
        isChatEventsEnabled &&
        isEditMessagesEnabled &&
        currentConversationEvents[CHAT_EVENTS_TYPES.EDIT_MESSAGE]
      ) {
        const payload = currentConversationEvents[
          CHAT_EVENTS_TYPES.EDIT_MESSAGE
        ].map((message) => ({
          id: message.messageId,
          ts: message.messageTs,
          chat_id: currentConversationId,
        }));

        dispatch(fetchMessagesById({ message_identifier: payload }));
      }
    }

    if (
      isChatEventsEnabled &&
      isMuteChatEnabled &&
      Object.keys(chatEvents).length !== 0
    ) {
      Object.entries(chatEvents).forEach(([, events]) => {
        const allEvents = Object.values(events).flat();
        const muteChatEvents = [
          CHAT_EVENTS_TYPES.CHAT_MUTED,
          CHAT_EVENTS_TYPES.CHAT_UNMUTED,
        ];

        const hasMuteEvent = allEvents.some(({ eventType }) =>
          muteChatEvents.includes(eventType as CHAT_EVENTS_TYPES)
        );

        if (hasMuteEvent) {
          const mostRecentEvent = allEvents.reduce((latest, event) =>
            !latest || event.eventTs > latest.eventTs ? event : latest
          );

          if (mostRecentEvent) {
            dispatch(
              muteChatFromChatEvents({
                conversationId: mostRecentEvent.chatId,
                timestamp: Number(mostRecentEvent.eventTs),
                mute:
                  mostRecentEvent.eventType === CHAT_EVENTS_TYPES.CHAT_MUTED,
              })
            );
          }
        }
      });
    }
  }, [
    isChatEventsEnabled,
    currentConversationId,
    chatEvents,
    dispatch,
    isEditMessagesEnabled,
    isDeleteMessagesEnabled,
    isMuteChatEnabled,
  ]);

  useMount(() => {
    if (isChatEventsEnabled) {
      dispatch(fetchChatEvents({ last_event_timestamp: Date.now() }));
    }
  });

  useEffect(() => {
    if (
      chatEventsStatus &&
      lastEventTs !== CHAT_EVENTS_DEFAULT_TIMESTAMP &&
      isChatEventsEnabled
    ) {
      dispatch(fetchChatEvents({ last_event_timestamp: lastEventTs }));
    }
  }, [chatEventsStatus, lastEventTs, dispatch, isChatEventsEnabled]);
};
