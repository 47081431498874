import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoginProvider } from "src/features/signin/imports/enums";
import { loginSelectors } from "src/features/signin/imports/state";

enum ProviderType {
  MAIN = "mainProvider",
  OTHERS = "otherProviders",
}

const isMainProvider = (value: string): value is ProviderType.MAIN =>
  value === LoginProvider.TANGO_PHONE_NUMBER;

const sortByOs = (providers: LoginProvider[]) =>
  providers.sort((provider) => (provider === LoginProvider.GOOGLE ? -1 : 1));

const useGetLoginProviders = () => {
  const providers = useSelector(
    loginSelectors.getAvailableProviders,
    shallowEqual
  ) as LoginProvider[];

  return useMemo(() => {
    type providerType = {
      [ProviderType.MAIN]: LoginProvider | undefined;
      [ProviderType.OTHERS]: LoginProvider[];
    };

    const sortedProviders = sortByOs(providers);

    return sortedProviders.reduce(
      (acc, provider) => {
        if (isMainProvider(provider)) {
          acc[ProviderType.MAIN] = provider;
        } else {
          acc[ProviderType.OTHERS].push(provider);
        }

        return acc;
      },
      {
        [ProviderType.MAIN]: undefined,
        [ProviderType.OTHERS]: [],
      } as providerType
    );
  }, [providers]);
};

export default useGetLoginProviders;
