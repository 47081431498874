import {
  BEAUTY_MULTIPLIERS_BY_TYPE,
  BROADCAST_MASKS_DEFAULT_ALPHA,
  DEFAULT_BEAUTY_MULTIPLIER,
  DEFAULT_COLOR,
  TANGO_BANUBA_MAKEUP_TYPE_MAP,
} from "src/features/broadcastMasks/common/constants";
import {
  BanubaLipsType,
  BanubaMakeupType,
  TangoBeautyType,
  TangoMakeupType,
} from "src/features/broadcastMasks/common/enums";
import {
  BroadcastMasksMakeupStateValue,
  BroadcastMasksMakeupStateValueItem,
  BroadcastMasksUrlParams,
} from "src/features/broadcastMasks/common/types";

export const splitBanubaColorIntoChunks = (
  color: number[],
  chunkSize: number = 4
): number[][] => {
  const result: number[][] = [];

  for (let i = 0; i < color.length; i += chunkSize) {
    result.push(color.slice(i, i + chunkSize));
  }

  return result;
};

export const isColorsEqual = (color1: number[], color2: number[]) =>
  color1.every((value, index) => value === color2[index]);

const parseAlphaParam = (alpha: string) => {
  const parsedAlpha = parseFloat(alpha);

  return isNaN(parsedAlpha) ? BROADCAST_MASKS_DEFAULT_ALPHA : parsedAlpha;
};

const extractBundleParams = (bundleUrl: string) => {
  const urlObj = new URL(bundleUrl);
  const params: BroadcastMasksUrlParams = {
    type: "",
    tex: "",
    alpha: BROADCAST_MASKS_DEFAULT_ALPHA,
  };

  params.type = urlObj.searchParams.get("type") ?? "";
  params.alpha = parseAlphaParam(urlObj.searchParams.get("alpha") ?? "");

  if (urlObj.searchParams.has("alpha")) {
    params.alpha = parseAlphaParam(urlObj.searchParams.get("alpha")!);
  }

  if (urlObj.searchParams.has("tex")) {
    params.tex = `textures/${urlObj.searchParams.get("tex")}`;
  }

  return params;
};

const generateMakeupLipsConfig = (type: string) => {
  switch (type) {
    case BanubaLipsType.Normal: {
      return {
        brightness: 1,
        saturation: 1,
      };
    }
    case BanubaLipsType.Shine: {
      return {
        brightness: 1,
        saturation: 1.5,
        shineIntensity: 1,
        shineBleeding: 0.5,
        shineScale: 1,
      };
    }
    case BanubaLipsType.Glitter: {
      return {
        brightness: 1,
        saturation: 1,
        shineIntensity: 0.9,
        shineBleeding: 0.6,
        shineScale: 1,
        glitterGrain: 0.4,
        glitterIntensity: 1,
        glitterBleeding: 0.4,
      };
    }
    case BanubaLipsType.Soft: {
      return {
        brightness: 1,
        saturation: 1,
        soft: 1,
      };
    }
    default:
      return {};
  }
};

const applyAlpha = (color: number[], alpha: number) =>
  color.map((value, index) => {
    if (index % 4 === 3) {
      return alpha;
    }

    return value;
  });

const transformStoreValueToMakeup = (
  key: string,
  value: BroadcastMasksMakeupStateValueItem
) => {
  const { type, tex, alpha } = extractBundleParams(value.config.bundleUrl);

  const color = applyAlpha(value.color, alpha);
  const banubaType = TANGO_BANUBA_MAKEUP_TYPE_MAP[key as TangoMakeupType];

  switch (key) {
    case TangoMakeupType.Eyelinear:
    case TangoMakeupType.Blush:
    case TangoMakeupType.Contactlens:
      return [
        banubaType,
        {
          texture: tex,
          color,
        },
      ];
    case TangoMakeupType.Eyebrow:
      return [
        BanubaMakeupType.Brows,
        {
          color,
          index: value.config.browWarpType + 1,
        },
      ];
    case TangoMakeupType.Eyelash:
      return [
        BanubaMakeupType.Eyelashes,
        {
          texture: tex,
          color,
          length: value.config.value,
        },
      ];
    case TangoMakeupType.Eyeshadow: {
      const [color1, color2, color3] = splitBanubaColorIntoChunks(color);

      return [
        banubaType,
        {
          texture: tex,
          color1: color1 || DEFAULT_COLOR,
          color2: color2 || DEFAULT_COLOR,
          color3: color3 || DEFAULT_COLOR,
        },
      ];
    }
    case TangoMakeupType.Lipstick: {
      return [
        BanubaMakeupType.Lips,
        {
          ...generateMakeupLipsConfig(type),
          color,
        },
      ];
    }
    default:
      return null;
  }
};

export const mapStoreToMakeupValues = (
  values: BroadcastMasksMakeupStateValue
) => {
  const banubaObject = {};

  Object.entries(values).forEach(([key, value]) => {
    if (!value) {
      return;
    }
    const transformedValue = transformStoreValueToMakeup(key, value);
    if (transformedValue) {
      // @ts-ignore
      banubaObject[transformedValue[0]] = transformedValue[1];
    }
  });

  return banubaObject;
};

export const multiplyBeautyValue = (key: TangoBeautyType, value: number) => {
  const multiplier =
    BEAUTY_MULTIPLIERS_BY_TYPE[key] ?? DEFAULT_BEAUTY_MULTIPLIER;
  const multipliedValue = Number((value * multiplier).toFixed(2));

  return !isNaN(multipliedValue) ? multipliedValue : value;
};
