import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state/delegate";

export const componentSwitcher = <T extends object>(
  soc: (stat: RootState) => boolean,
  ComponentWhenOn: FC<T>,
  ComponentWhenOff: FC<T>
) => {
  const ComponentSwitcher = (props: T) => {
    const Component = useSelector(soc) ? ComponentWhenOn : ComponentWhenOff;

    return <Component {...props} />;
  };
  ComponentSwitcher.displayName = "ComponentSwitcher";

  return ComponentSwitcher;
};
