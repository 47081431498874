const countries = require("i18n-iso-countries");
module.exports = function(cb) {
    require.ensure([
      "i18n-iso-countries/langs/sv.json",
      "date-fns/locale/sv",
      "translations/sv.json",
    ], (require) => {
      countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));
      cb({
        translatedMessages: require("translations/sv.json"),
        dateFnsLocale: require("date-fns/locale/sv").default
      });
    }, "locale-sv-no-intl");
};