import {
  EventFields,
  SCREEN_NAME,
  UIActionTarget,
  emitUiAction,
} from "chat/imports/analytics";
import { ConversationScreenState } from "chat/types";

export const emitOneToOneStreamStartClicked = ({
  chatId,
}: {
  chatId: string;
}) => {
  emitUiAction({
    [EventFields.TARGET]: UIActionTarget.ONE_TO_ONE,
    [EventFields.TANGO_SCREEN]: SCREEN_NAME.CHAT_SINGLE,
    additionalParams: {
      [EventFields.TEXT]: ConversationScreenState.ONE_ON_ONE_CHAT,
      [EventFields.CHAT_ID]: chatId,
    },
  });
};
