import {
  CUSTOM_DRAWER_BEGIN_FETCH,
  CUSTOM_DRAWER_END_FETCH,
} from "state/actionTypes";
import withFetcher, {
  createFetcherActions,
  fetcherSelectors,
} from "state/hor/withFetcher";

export const persistConfig = {
  blacklist: ["isLoadFailed", "isLoadInProgress"],
};

export const actionCreators = {
  ...createFetcherActions({
    beginFetchActionType: CUSTOM_DRAWER_BEGIN_FETCH,
    endFetchActionType: CUSTOM_DRAWER_END_FETCH,
  }),
};

export const selectors = {
  ...fetcherSelectors,
  // The third parameter as a crutch to remove TC errors
  // will be removed after this task https://tango-me.atlassian.net/browse/WEB-9188
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCategoryById: (state, categoryId, _) =>
    state.categories?.find((cat) => cat.id === categoryId),
  getCategories: (state) => state.categories,
  getDrawerId: (state) => state.id,
  getDrawerVersion: (state) => state.drawerVersion,
};

export default withFetcher({
  beginFetchActionType: CUSTOM_DRAWER_BEGIN_FETCH,
  endFetchActionType: CUSTOM_DRAWER_END_FETCH,
  initialData: {
    categories: null,
    drawerVersion: "",
    id: "",
  },
  extractData: (data) => data,
  mergeData: (oldData, newData) => ({ ...oldData, ...newData }),
})((state) => state);
