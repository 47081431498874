import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  MessageAction,
  emitMessageActionEvent,
  getMessageFlag,
} from "chat/analytics/emitMessageActionEvent";
import MessageMenuOptionBase from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuOptionBase";
import { MESSAGE_POPOVER_OPTIONS } from "chat/constants/messageMenuOptionsConfig";
import { Breakpoints } from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { openDeleteChatMessageModalHandler } from "chat/imports/state";
import { StoredMessage } from "chat/state";

export interface MessageMenuOptionProps {
  closeMenu: VoidFunction;
  message: StoredMessage;
}

const MessageMenuDeleteOption: FC<MessageMenuOptionProps> = ({
  message,
  closeMenu,
}) => {
  const dispatch = useDispatch();
  const isMobile = useBreakpointPrecise() !== Breakpoints.DESKTOP;

  const onDeleteHandler = useCallback(() => {
    dispatch(
      openDeleteChatMessageModalHandler({
        isMobile,
        messages: [message],
      })
    );
    closeMenu();

    emitMessageActionEvent({
      action: MessageAction.MESSAGE_DELETE,
      chatId: message.id.chat_id,
      flag: getMessageFlag(message),
    });
  }, [dispatch, message, isMobile]);

  return (
    <MessageMenuOptionBase
      option={MESSAGE_POPOVER_OPTIONS.delete}
      onClick={onDeleteHandler}
    />
  );
};

export default MessageMenuDeleteOption;
