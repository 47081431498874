import { batch } from "react-redux";
import {
  fetchFeedByTag,
  fetchFollowingFeed,
  fetchFollowingRecommendationsFeed,
  fetchHottestFeed,
  fetchLatestFeed,
  fetchNearbyFeed,
  fetchRecommendationsFeed,
} from "api/stream";
import { LiveFeedType, PersonalFeedType } from "src/enums";
import { currentTimeMillis } from "src/utils/dateUtils";
import {
  getLiveSortAlgorithmVersion,
  getStreamMaxModerationLevel,
  getStreamMaxModerationLevelFullAccess,
  getStreamModerationLevelWhenUndefinedInResponse,
} from "state/abTests";
import {
  getLocaleCountryCode,
  getRegion,
  liveStreamsFeedSelectors,
  loginSelectors,
  settingsSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/liveStreamsFeed";

export const PAGE_SIZE = 48;

const makeTaggedFetcher = (mode) => (params) =>
  fetchFeedByTag({ tag: mode, ...params });

const fetchersMap = {
  [LiveFeedType.POPULAR]: fetchHottestFeed,
  [LiveFeedType.NEW]: fetchLatestFeed,
  [LiveFeedType.NEARBY]: fetchNearbyFeed,
  [LiveFeedType.RECOMMENDED]: fetchRecommendationsFeed,
  [LiveFeedType.CREATORS]: makeTaggedFetcher(LiveFeedType.CREATORS),
  [PersonalFeedType.OLD_FOLLOWING]: fetchFollowingFeed,
  [PersonalFeedType.FOLLOWING]: fetchFollowingRecommendationsFeed,
  [PersonalFeedType.RECOMMENDED]: fetchFollowingRecommendationsFeed,
  [PersonalFeedType.ALL]: fetchFollowingRecommendationsFeed,
};

const getFetcher = (mode) => fetchersMap[mode] || makeTaggedFetcher(mode);

export const moderateStreamMap = (
  streamMap,
  maxAvailableLevel,
  defaultStreamModerationLevel
) =>
  Object.entries(streamMap).reduce((acc, [streamId, stream]) => {
    if (typeof stream.moderationLevel !== "number") {
      stream.moderationLevel = defaultStreamModerationLevel;
    }

    if (stream.moderationLevel <= maxAvailableLevel) {
      acc[streamId] = stream;
    }

    return acc;
  }, {});

// istanbul ignore next
export const moderateResponseWithCategoryInfoList = (
  response,
  maxAvailableLevel
) => {
  if (
    response?.categoryInfoList &&
    response.categoryInfoList instanceof Array
  ) {
    response.categoryInfoList.forEach((category) => {
      if (
        category?.streamInfoList?.streamDetails &&
        category.streamInfoList.streamDetails instanceof Array
      ) {
        const streamDetailsList = category.streamInfoList.streamDetails;

        category.streamInfoList.streamDetails = streamDetailsList.filter(
          (streamDetails) => streamDetails.moderationLevel <= maxAvailableLevel
        );
      }
    });
  }
};

// istanbul ignore next
export const moderateResponseWithEntities = (
  response,
  maxAvailableLevel,
  defaultStreamModerationLevel
) => {
  if (
    response?.entities?.stream &&
    typeof response?.entities?.stream === "object" &&
    response?.result &&
    typeof response?.result === "object"
  ) {
    const streamMap = response.entities.stream;
    const streamIds = response.result;

    const moderatedStreamMap = moderateStreamMap(
      streamMap,
      maxAvailableLevel,
      defaultStreamModerationLevel
    );

    const moderatedStreamIds = Object.keys(moderatedStreamMap);

    response.entities.stream = moderatedStreamMap;
    response.result = streamIds.filter((streamId) =>
      moderatedStreamIds.includes(streamId)
    );
  }
};

export const loadMore =
  (mode, pageSize = PAGE_SIZE) =>
  (dispatch, getState) => {
    const state = getState();

    if (
      !liveStreamsFeedSelectors.canLoadMore(state, mode) ||
      liveStreamsFeedSelectors.isLoadingMode(state, mode)
    ) {
      return Promise.resolve();
    }
    const pageCount = liveStreamsFeedSelectors.getPageCount(state, mode);
    const sessionId = liveStreamsFeedSelectors.getSessionId(state, mode);
    const sortVersion = getLiveSortAlgorithmVersion(state);
    const region = getRegion(state);
    const locale = getLocaleCountryCode(state);
    const guest = !loginSelectors.isLoggedIn(state);
    const streamMaxModerationLevel = getStreamMaxModerationLevel(state);
    const streamMaxModerationLevelFullAccess =
      getStreamMaxModerationLevelFullAccess(state);
    const isAccessToPremiumEnabled = Number(
      settingsSelectors.isAccessToPremiumEnabled(state)
    );
    const streamModerationLevelWhenUndefinedInResponse =
      getStreamModerationLevelWhenUndefinedInResponse(state);

    dispatch(actionCreators.fetchBegan({ mode }));

    return getFetcher(mode)({
      pageSize,
      pageCount,
      sessionId,
      sortVersion,
      region,
      locale,
      guest,
      streamMaxModerationLevel,
      streamMaxModerationLevelFullAccess,
      accessToPremium: isAccessToPremiumEnabled,
    })
      .then((posts) =>
        batch(() => {
          moderateResponseWithEntities(
            posts,
            streamMaxModerationLevelFullAccess,
            streamModerationLevelWhenUndefinedInResponse
          );

          dispatch(
            actionCreators.fetchCompleted({
              mode,
              data: { ...posts, timestamp: currentTimeMillis() },
              replaceAll: false,
            })
          );

          moderateResponseWithCategoryInfoList(
            posts,
            streamMaxModerationLevelFullAccess
          );
          // HOTFIX
          // ToDo: https://tango-me.atlassian.net/browse/WEB-5413
          if (
            posts &&
            posts.categoryInfoList &&
            posts.categoryInfoList.find(
              (item) => item.tag === PersonalFeedType.RECOMMENDED
            )
          ) {
            dispatch(
              actionCreators.fetchCompleted({
                mode: PersonalFeedType.RECOMMENDED,
                data: { ...posts, timestamp: currentTimeMillis() },
                replaceAll: false,
              })
            );
          }
        })
      )
      .catch((error) =>
        dispatch(
          actionCreators.fetchFailed({
            mode,
            error,
            removeAll: false,
          })
        )
      );
  };

export const refresh =
  (mode, utm = "") =>
  (dispatch, getState) => {
    const state = getState();
    const sortVersion = getLiveSortAlgorithmVersion(state);
    const region = getRegion(state);
    const locale = getLocaleCountryCode(state);
    const guest = !loginSelectors.isLoggedIn(state);
    const streamMaxModerationLevel = getStreamMaxModerationLevel(state);
    const streamMaxModerationLevelFullAccess =
      getStreamMaxModerationLevelFullAccess(state);
    const isAccessToPremiumEnabled = Number(
      settingsSelectors.isAccessToPremiumEnabled(state)
    );
    const streamModerationLevelWhenUndefinedInResponse =
      getStreamModerationLevelWhenUndefinedInResponse(state);
    dispatch(actionCreators.fetchBegan({ mode }));

    return getFetcher(mode)({
      pageSize: PAGE_SIZE,
      sortVersion,
      region,
      locale,
      guest,
      utm,
      streamMaxModerationLevel,
      streamMaxModerationLevelFullAccess,
      accessToPremium: isAccessToPremiumEnabled,
    })
      .then((data) => {
        moderateResponseWithCategoryInfoList(
          data,
          streamMaxModerationLevelFullAccess
        );
        // HOTFIX
        // ToDo: https://tango-me.atlassian.net/browse/WEB-5413
        if (
          data &&
          data.categoryInfoList &&
          data.categoryInfoList.find(
            (item) => item.tag === PersonalFeedType.RECOMMENDED
          )
        ) {
          dispatch(
            actionCreators.fetchCompleted({
              mode: PersonalFeedType.RECOMMENDED,
              data: { ...data, timestamp: currentTimeMillis() },
              replaceAll: false,
            })
          );
        }

        moderateResponseWithEntities(
          data,
          streamMaxModerationLevelFullAccess,
          streamModerationLevelWhenUndefinedInResponse
        );

        return dispatch(
          actionCreators.fetchCompleted({
            mode,
            data: { ...data, timestamp: currentTimeMillis() },
            replaceAll: true,
          })
        );
      })
      .catch((error) =>
        dispatch(
          actionCreators.fetchFailed({
            mode,
            error,
            removeAll: false,
          })
        )
      );
  };
