import React, { FC, memo } from "react";
import ArticleMeta from "legal/components/ArticleMeta/ArticleMeta";
import { Headline, ListItem, Paragraph } from "legal/components/typography";
import { FormattedMessageTitle } from "legal/imports/components";
import { legalMessages } from "legal/imports/constants";
import { LinkToOFACListSanctions, LinkToTermsOfUse } from "legal/links";
import styles from "legal/common/LegalArticle.scss";

/* eslint-disable react/jsx-no-literals */
const BugBountyProgram: FC = () => (
  <>
    <FormattedMessageTitle {...legalMessages.bugBountyProgram} />

    <article className={styles.article}>
      <ArticleMeta postDate="Last Modified: 14.02.2023" />

      <Headline level={1}>
        {legalMessages.bugBountyProgram.defaultMessage}
      </Headline>

      <Headline level={2}>Overview</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          TangoMe Inc and its subsidiaries (hereafter referred to as{" "}
          <b>&quot;Tango&quot;</b>) hereby extend an invitation to external
          security researchers and members of our user community to participate
          in our bug bounty program (<b>&quot;Program&quot;</b>), pursuant to
          the terms herein (<b>&quot;Terms&quot;</b>
          );
        </ListItem>
        <ListItem>
          The Program is intended to provide an opportunity for individuals to
          submit vulnerabilities and exploitation techniques (
          <b>&quot;Vulnerabilities&quot;</b>) in relation to eligible Tango
          products and services (<b>&quot;Products&quot;</b>), for the
          possibility of earning rewards (<b>&quot;Bounty(ies)&quot;</b>), as
          determined by Tango in its sole discretion;
        </ListItem>
        <ListItem>
          By submitting Vulnerabilities or otherwise participating in the
          Program, you (<b>&quot;You&quot;</b> or <b>&quot;Your&quot;</b>) agree
          to these Terms as a legally binding contract between Tango and
          Yourself;
        </ListItem>
        <ListItem>
          These Terms are integrated into and forms part of Tango's&nbsp;
          <LinkToTermsOfUse>Terms of Use</LinkToTermsOfUse>, and is incorporated
          therewith by reference;
        </ListItem>
        <ListItem>
          Tango may change or cease this Program at any time in its sole
          discretion. By participating in the Program after the publication of
          such changes, You are deemed to have read and accepted to be bound by
          the new Terms;
        </ListItem>
        <ListItem>
          If You do not agree to these Terms, You should stop using the Program
          immediately and not submit any Vulnerabilities in the Program;
        </ListItem>
        <ListItem>
          Violations of these Terms may result in the return of any Bounties
          paid for the Vulnerability and disqualification from participation in
          the Program;
        </ListItem>
      </ol>

      <Headline level={2}>Confidentiality</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          As part of Your participation in the Program, You may have access to
          certain non-public and/or proprietary information of Tango, in any
          form or media, including (without limitation) confidential trade
          secrets and other information related to the products, software,
          technology, data, know-how, and to any other information that a
          reasonable person or entity should have reason to believe is
          proprietary, confidential, or competitively sensitive (
          <b>"Confidential Information"</b>). Notwithstanding anything to the
          contrary, Tango's intellectual propriety rights are deemed as Tango's
          Confidential Information;
        </ListItem>
        <ListItem>
          By providing a Submission, or by agreeing to these Terms, You
          acknowledge and agree that You may not disclose any Confidential
          Information about the Vulnerability or the contents of Your submission
          to any third parties without Tango&#39;s prior written approval;
        </ListItem>
        <ListItem>
          You hereby acknowledge and agree that due to the unique nature of the
          Confidential Information, there may be no adequate remedy at law for
          any breach of its obligations under this section, and that any such
          breach or any unauthorized use or release of any Confidential
          Information may result in irreparable harm to Tango. Therefore, upon
          any such breach or any threat thereof, Tango shall be entitled to
          appropriate equitable relief, including without limitation, injunctive
          relief against any breach of this section in addition to whatever
          remedies Tango might have at law, and Tango shall be entitled to be
          indemnified by the You from any loss or harm, including, without
          limitation, attorney’s fees, as further set forth in the
          Indemnification section, in connection with any breach or enforcement
          of Your obligations pursuant to this section or the unauthorized use
          or release of any Confidential Information;
        </ListItem>
      </ol>

      <Headline level={2}>Program Eligibility</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          In order to be eligible to participate in the Program, you must not:
          <ol className={styles.ol}>
            <ListItem>
              be under the age of 18 or the age of majority in the country in
              which You reside;
            </ListItem>
            <ListItem>
              be a resident of, or make Your submission from a country that is
              subject to export sanctions or other trade restrictions,
              including&nbsp;
              <LinkToOFACListSanctions />;
            </ListItem>
            <ListItem>
              be in violation of any national, state, or local law or
              regulation;
            </ListItem>
            <ListItem>
              be employed at an organization that does not allow You to
              participate in these types of programs;
            </ListItem>
            <ListItem>
              be a public sector employee and participate in the Program without
              obtaining permission from Your ethics compliance officer;
            </ListItem>
            <ListItem>
              be involved in any part of the development, administration, and/or
              execution of this Program;
            </ListItem>
            <ListItem>
              be employed by Tango or its subsidiaries, or perform services for
              Tango or for a Tango&#39;s subsidiary in an external staff
              capacity that requires access to Tango&#39;s systems and/or
              network, such as agency temporary worker, vendor employee,
              business guest, or contractor;
            </ListItem>
            <ListItem>
              be an immediate family member of a person employed by Tango or its
              subsidiaries or affiliates;
            </ListItem>
          </ol>
        </ListItem>
        <ListItem>
          It is Your responsibility to comply with any policies that Your
          employer may have that would affect Your eligibility to participate in
          the Program. If You are participating in violation of Your employer’s
          policies, You may be disqualified from participating or receiving any
          reward or payment;
        </ListItem>
        <ListItem>
          All payments will be made in compliance with local laws, regulations,
          and ethics rules. Tango disclaims any and all liability or
          responsibility for disputes arising between an employee and their
          employer related to this matter. If Tango discovers that You meet any
          of the criteria above, Tango will remove You from the Program and
          disqualify You from receiving any payments or rewards;
        </ListItem>
      </ol>

      <Headline level={2}>Code of Conduct</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          Any activities conducted in a manner consistent with this Program
          (excluding any activities identified below as Prohibited Activities or
          otherwise in violation of these Terms) will be considered an
          authorized conduct;
        </ListItem>
        <ListItem>
          By participating in the Program, You will not, directly or indirectly,
          engage in any of the following activities:
          <ol className={styles.ol}>
            <ListItem>
              Engage in any activity beyond the scope of identifying in-scope
              Vulnerabilities (as identified herein) or beyond the systems
              and/or system components identified herein as made available as
              part of the Program;
            </ListItem>
            <ListItem>
              Engage in any illegal activity, any activity that is fraudulent or
              misleading, or any activity that infringes on the rights of any
              third parties, including privacy and intellectual property rights;
            </ListItem>
            <ListItem>
              Engage in any activity that does not comply with any default third
              party security or system requirements or settings, or any activity
              impacting or degrading the performance, reliability, integrity or
              confidentiality of any third party systems and/or data;
            </ListItem>
            <ListItem>
              Engage in any activity that interferes with, disrupts, damages, or
              accesses in an unauthorized manner third party&#39;s devices,
              servers, networks, application programming interfaces (APIs), or
              services, including but not limited to Google, Apple, or an
              authorized carrier’s network;
            </ListItem>
            <ListItem>
              Engage in any activity that exploits, harms, or threatens to harm
              or degrade our Products or any third parties&#39; services
              (including by way of transmitting viruses or malware, including
              without limitation Potentially Harmful Applications, binaries,
              trojans, phishing, or spyware apps);
            </ListItem>
            <ListItem>
              Modify any files or data, including permissions, or intentionally
              view or access any data beyond what is needed to prove the
              vulnerability;
            </ListItem>
            <ListItem>
              Engage in any intentional conduct that deletes or alters data,
              impairs, disrupts, or disables systems, or renders data
              inaccessible;
            </ListItem>
          </ol>
        </ListItem>
        <ListItem>
          Participants are required to contact Tango for clarification before
          engaging in conduct that may be inconsistent with or unaddressed by
          the policy. If You violate the Terms of the Program, You may be
          prohibited from participating in the Program in the future and any
          Submissions You have provided may be deemed to be ineligible for
          Bounty payments;
        </ListItem>
      </ol>

      <Headline level={2}>Submission Process</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          Each Vulnerability submitted to Tango (<b>&quot;Submission&quot;</b>)
          must be sent to bug_bounty@tango.me;
        </ListItem>
        <ListItem>
          Please clearly and accurately describe the vulnerability that You have
          discovered, include as much of the following information as possible:
          <ol className={styles.ol}>
            <ListItem>The affected Product or service;</ListItem>
            <ListItem>
              The operating system, browser and version of the software;
            </ListItem>
            <ListItem>
              The type of vulnerability (e.g. SQL injection, cross-site
              scripting, etc.);
            </ListItem>
            <ListItem>
              Proof-of-concept code or other relevant materials that can help
              Tango understands and verifies the Vulnerability, including sample
              code that demonstrates how to exploit the Vulnerability, or
              screenshots or videos that show the Vulnerability in action;
            </ListItem>
            <ListItem>
              The specific requests or inputs that are needed to trigger the
              vulnerability;
            </ListItem>
            <ListItem>
              The impact of the vulnerability (e.g. data leakage, remote code
              execution, etc.);
            </ListItem>
            <ListItem>
              Step-by-step instructions to reproduce the issue on a fresh
              install, including any specific configurations or conditions that
              are required.
            </ListItem>
          </ol>
        </ListItem>
        <ListItem>
          Submissions that do not meet the minimum bar described above are
          considered incomplete and not eligible for Bounties;
        </ListItem>
        <ListItem>
          If You submit a Vulnerability for a product or service that is not
          covered by the Program at the time You submitted it, You will not be
          eligible to receive Bounty payments if the product or service is later
          added to the Program;
        </ListItem>
        <ListItem>
          Tango is not responsible for Submissions that we do not receive for
          any reason;
        </ListItem>
      </ol>

      <Headline level={2}>Submission Review Process by Tango</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          After a Submission is sent to Tango in accordance with the Terms of
          this Program, Tango engineers will review the Submission and validate
          its eligibility. The review time will vary depending on the complexity
          and completeness of Your Submission, as well as on the number of
          Submissions we receive;
        </ListItem>
        <ListItem>
          Tango retains sole discretion in determining which Submissions are
          qualified. If we receive multiple bug reports for the same issue from
          different parties, the Bounty will be granted to the first eligible
          Submission. If a duplicate report provides new information that was
          previously unknown to Tango, we may award a differential to the person
          submitting the duplicate report;
        </ListItem>
      </ol>

      <Headline level={2}>Submission License</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          Accessing or participating in the Program does not grant any express
          or implied right to You or any other person to any of the Tango&#39;s
          intellectual property;
        </ListItem>
        <ListItem>
          By providing any Submission to Tango, such Submission will not be
          considered Your confidential or proprietary information. Tango shall
          own all Submissions and may, at its discretion and for any purpose,
          freely use, modify, and incorporate into its Products any Submission,
          Vulnerabilities, comments, or suggestions provided by you;
        </ListItem>
        <ListItem>
          You hereby agree to sign any documentation that may be required for us
          or our designees to confirm the rights aforementioned;
        </ListItem>
        <ListItem>
          You waive any claims You may have relating to the rights
          aforementioned;
        </ListItem>
        <ListItem>
          Understand that You are not guaranteed any compensation or credit for
          use of Your Submission;
        </ListItem>
        <ListItem>
          Represent and warrant that Your Submission is Your own work, does not
          infringe any intellectual property rights or other rights of any third
          party and does not violate any applicable law, and that You have the
          legal right to provide the Submission to Tango;
        </ListItem>
      </ol>

      <Headline level={2}>In-Scope Vulnerabilities</Headline>
      <Paragraph>
        In-scope Vulnerabilities include, but are not limited to:
      </Paragraph>
      <ol className={styles.alphabetical}>
        <ListItem>Cross-site scripting (xss);</ListItem>
        <ListItem>SQL injection;</ListItem>
        <ListItem>Cross-site request forgery (csrf);</ListItem>
        <ListItem>Insecure direct object references;</ListItem>
        <ListItem>Insecure authentication and authorization;</ListItem>
        <ListItem>Server-side request forgery (ssrf);</ListItem>
        <ListItem>
          File inclusion Vulnerabilities (e.g. local file inclusion (lfi),
          remote file inclusion (rfi));
        </ListItem>
        <ListItem>Insecure cryptographic storage;</ListItem>
        <ListItem>Insecure communication;</ListItem>
        <ListItem>Clickjacking;</ListItem>
        <ListItem>Insufficient logging and monitoring;</ListItem>
        <ListItem>
          Injection Vulnerabilities (e.g. command injection, code injection);
        </ListItem>
        <ListItem>Unvalidated redirects and forwards;</ListItem>
        <ListItem>Business logic Vulnerabilities;</ListItem>
        <ListItem>Information leakage;</ListItem>
      </ol>

      <Headline level={2}>Out Of-Scope Vulnerabilities</Headline>
      <Paragraph>
        Certain Vulnerabilities are considered out-of-scope for the Program and
        will not be eligible for a Bounty. Those out-of-scope Vulnerabilities
        include, but are not limited to:
      </Paragraph>
      <ol className={styles.alphabetical}>
        <ListItem>
          Social engineering of employees, affiliates, customers or users;
        </ListItem>
        <ListItem>
          Physical attacks (e.g. office access, data center access);
        </ListItem>
        <ListItem>Denial of service (dos) attacks;</ListItem>
        <ListItem>
          Vulnerabilities requiring physical access to a user&#39;s device;
        </ListItem>
        <ListItem>
          Vulnerabilities in third-party services or libraries;
        </ListItem>
        <ListItem>
          Vulnerabilities that have already been reported or are already known
          to Tango, Tango's employees or agents;
        </ListItem>
        <ListItem>Vulnerabilities that are not reproducible;</ListItem>
        <ListItem>
          Vulnerabilities that are related to outdated or unsupported software;
        </ListItem>
        <ListItem>
          Vulnerabilities that are disclosed publicly without giving Tango a
          reasonable time to address them;
        </ListItem>
        <ListItem>
          Vulnerabilities that are specific to individuals rather than the
          organization or application;
        </ListItem>
      </ol>

      <Headline level={2}>Bounty Payments and Rewards</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          The decisions made by Tango regarding Bounties are final and binding.
          If we have determined that Your Submission is eligible for a Bounty,
          we will notify You of the Bounty amount and provide You with the
          necessary paperwork to process Your payment;
        </ListItem>
        <ListItem>
          If there is a dispute as to who the qualified submitter is, we will
          consider the eligible submitter to be the authorized account holder of
          the email address used to enter the Program;
        </ListItem>
        <ListItem>
          If Your Submission qualifies for a Bounty, please note:
          <ol className={styles.ol}>
            <ListItem>
              You may not designate someone else as the Bounty recipient;
            </ListItem>
            <ListItem>
              if You are unable or unwilling to accept Your Bounty, we reserve
              the right to rescind it;
            </ListItem>
            <ListItem>
              if You accept a Bounty, You will be solely responsible for all
              applicable taxes related to accepting the payment(s).
            </ListItem>
          </ol>
        </ListItem>
      </ol>

      <Headline level={2}>No Warranties</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          Tango, its affiliates, resellers, distributors, and vendors, make no
          warranties, express or implied, guarantees or conditions with respect
          to the Program. You understand that Your participation in the Program
          is at Your own risk. Under no circumstances shall Tango be held liable
          for Your choice to participate in the Program;
        </ListItem>
        <ListItem>
          Without limiting the foregoing, Tango does not endorse or recommend
          anyone to participate in the Program and does not guarantee the award
          of Bounty associated therewith. Each participant in the Program is
          solely responsible for exercising their own judgment, determining
          whether the Program suits their purposes and deciding whether to
          participate;
        </ListItem>
        <ListItem>
          To the extent permitted under Your local law, we exclude any implied
          warranties in connection with the program. You may have certain rights
          under Your local law. Nothing in these Terms is intended to affect
          those rights, if they are applicable;
        </ListItem>
      </ol>

      <Headline level={2}>Limitation of Liability &amp; Disputes</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          To the fullest extent permitted under applicable laws the limitations
          and exclusions set below shall apply to anything or any claims
          regarding this Program;
        </ListItem>
        <ListItem>
          Should You have any basis for recovering damages in connection with
          this program (including breach of these Terms), You agree that Your
          exclusive remedy is to recover, from tango or any of its respective
          officers, directors, agents, employees, affiliates, resellers,
          distributors, third-party providers, and vendors, direct damages up to
          100 USD dollars;
        </ListItem>
        <ListItem>
          No other damages or losses, under any theory of liability, including
          but not limited to contract or tort, including direct, consequential,
          loss of profits, lost revenues, loss of goodwill, exemplary, special,
          indirect, incidental, or punitive damages shall be recoverable;
        </ListItem>
        <ListItem>
          The abovementioned limitations and exclusions shall apply even if the
          remedy would lead to incomplete compensation for any losses or would
          fail of its essential purpose or if we knew or should have known about
          the possibility of the damages;
        </ListItem>
      </ol>

      <Headline level={2}>Governing Law and Disputes</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          By entering into this Program You agree to waive Your right to
          participate as a class representative or class member in any class
          claim that You may have against us, including any right to class
          arbitration or any consolidation of individual arbitrations or claims;
        </ListItem>
        <ListItem>
          This Program shall be governed by and construed according to the laws
          of the State of New York, without regard to its choice of law
          principles. The competent courts of the State of New York shall have
          exclusive jurisdiction to hear any dispute relating to this Program or
          arising thereunder and no other courts will have jurisdiction
          whatsoever in respect of such disputes;
        </ListItem>
      </ol>

      <Headline level={2}>Miscellaneous</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          These Terms are the entire agreement between You and Tango for Your
          Participation in the Program. It supersedes any prior agreements
          between You and Tango regarding Your participation in the Program;
        </ListItem>
        <ListItem>
          All parts of these Terms apply to the maximum extent permitted by
          relevant law. If a court holds that we can't enforce a part of These
          Terms as written, we may replace these Terms with similar terms to the
          extent enforceable under the relevant law, but the rest of these Terms
          won't change;
        </ListItem>
      </ol>
    </article>
  </>
);

export default memo(BugBountyProgram);
