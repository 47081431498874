import React, { FC } from "react";
import { useSelector } from "react-redux";
import { sendSaveMediaMessage } from "chat/state/flows/sendSaveMediaMessage";
import MessageMenuOptionBase from "src/features/chat/components/MessageMenu/MessageMenuOptions/MessageMenuOptionBase";
import { MESSAGE_POPOVER_OPTIONS } from "src/features/chat/constants/messageMenuOptionsConfig";
import {
  DeviceType,
  ToastType,
  sharedMessages,
} from "src/features/chat/imports/constants";
import { useToast } from "src/features/chat/imports/hooks";
import { deviceInfoSelectors } from "src/features/chat/imports/state";
import { StoredMessage } from "src/features/chat/state";
import { MessageType } from "src/features/chat/types";
import { downloadMedia } from "src/features/chat/utils/downloadMedia";

export interface MessageMenuOptionProps {
  closeMenu: VoidFunction;
  message: StoredMessage;
}

export const MediaMessageSaveOption: FC<MessageMenuOptionProps> = ({
  message,
  closeMenu,
}) => {
  const { notify } = useToast();

  const isIOS =
    useSelector(deviceInfoSelectors.getDeviceType) === DeviceType.IOS;
  const isIPAD =
    useSelector(deviceInfoSelectors.getDeviceType) === DeviceType.IPAD;

  const isImage = message.type === MessageType.IMAGE_MESSAGE;
  const isVideo = message.type === MessageType.VIDEO_MESSAGE;

  const handleDownload = async () => {
    const mediaUrl = message.media?.[0].download_url;

    if (!mediaUrl) {
      notify({
        type: ToastType.REGULAR,
        title: sharedMessages.noMediaFound,
      });

      return;
    }

    const callbackHandler = (messageText: {
      defaultMessage: string;
      id: string;
    }) => {
      notify({ type: ToastType.REGULAR, title: messageText });
    };

    const isDownloadSuccessful = await downloadMedia(
      mediaUrl,
      message.type,
      callbackHandler,
      isIOS,
      isIPAD
    );

    if (isDownloadSuccessful) {
      await sendSaveMediaMessage(message.type, message.id.chat_id);
    }

    closeMenu();
  };

  if (!isImage && !isVideo) {
    return null;
  }

  return (
    <MessageMenuOptionBase
      option={MESSAGE_POPOVER_OPTIONS.save}
      onClick={handleDownload}
    />
  );
};
