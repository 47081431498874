import enhancedFetch from "api/utils/enhancedFetch";
import {
  FetchOBSTangoProfileUrl,
  FetchStreamKeyResponse,
  InitExternalStreamParams,
  InitExternalStreamResponse,
} from "src/features/broadcastExternal/common/types";
import { getProxycadorHost } from "src/features/broadcastExternal/imports/environment";
import {
  InitStreamRequest,
  InitStreamResponse,
} from "src/features/broadcastExternal/imports/proto";
import { mapInitExternalRequestParams } from "src/features/broadcastExternal/utils/mapInitExternalRequestParams";

export const initExternalBroadcast = async (
  params: InitExternalStreamParams
): Promise<InitExternalStreamResponse> => {
  const response = await enhancedFetch(
    `${getProxycadorHost()}/stream/client/v1/initPublicExternal`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
      body: InitStreamRequest.encode({
        ...mapInitExternalRequestParams(params),
        saveStoryOnServer: true,
        multiBattleSupport: true,
      }).finish(),
    }
  );

  const bufferResponse = await response.arrayBuffer();

  return InitStreamResponse.decode(new Uint8Array(bufferResponse), undefined);
};

export const fetchStreamKey = (): Promise<FetchStreamKeyResponse> =>
  enhancedFetch(`${getProxycadorHost()}/stream-key-provider/api/stream-key`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((resp) => resp.json());

export const fetchOBSTangoProfileUrl = ({
  obsTangoProfileUrl,
}: FetchOBSTangoProfileUrl): Promise<Blob> =>
  fetch(obsTangoProfileUrl).then((res) => res.blob());
