import type { RealTimeRecommendationsClientEventRequest } from "src/features/recommendations/common/types";
import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";

export const sendRealTimeRecommendationsEventRequest = (
  request: RealTimeRecommendationsClientEventRequest
): Promise<boolean> =>
  fetch(`${getProxycadorHost()}/clienteventreporter/v1/events`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(request),
    keepalive: true,
  }).then((response) => response.ok);

export const sendRealTimeRecommendationsEventBeaconRequest = (
  request: RealTimeRecommendationsClientEventRequest
): boolean => {
  const blob = new Blob([JSON.stringify(request)], {
    type: "application/json",
  });

  return navigator.sendBeacon(
    `${getProxycadorHost()}/clienteventreporter/v1/events`,
    blob
  );
};
