import { useEffect, useState } from "react";

export const useKeyboardOpen = () => {
  const [viewportHeight, setViewportHeight] = useState(
    window.visualViewport?.height || 0
  );
  const [keyboardIsOpened, setKeyboardIsOpened] = useState(false);

  useEffect(() => {
    setKeyboardIsOpened(
      !!viewportHeight && document.documentElement.clientHeight > viewportHeight
    );
  }, [viewportHeight]);

  useEffect(() => {
    const cb = (e: Event) => {
      const target = e.target as VisualViewport;
      setViewportHeight(target.height);
    };
    visualViewport?.addEventListener("resize", cb);

    return () => {
      visualViewport?.removeEventListener("resize", cb);
    };
  }, []);

  return { viewportHeight, keyboardIsOpened };
};
