import { createAction } from "@reduxjs/toolkit";
import { BroadcastExternalStatus } from "src/features/broadcastExternal/common/enums";

export const setBroadcastExternalStatus = createAction<BroadcastExternalStatus>(
  "lwc/broadcastExternal/setStatus"
);

export const resetBroadcastExternalStatus = createAction(
  "lwc/broadcastExternal/resetStatus"
);

export const startMultiBroadcastExternal = createAction(
  "lwc/broadcastExternal/multiBroadcast/start"
);

export const stopMultiBroadcastExternal = createAction(
  "lwc/broadcastExternal/multiBroadcast/stop"
);

export const setOBSInstructionVisibility = createAction<boolean>(
  "lwc/broadcastExternal/setOBSInstructionVisibility"
);
