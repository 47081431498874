import React, { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { MessageConfig } from "chat/components/currentConversation/Message";
import VideoMessage from "chat/components/currentConversation/VideoMessage";
import { makeLinkToHappyMoment } from "chat/imports/constants";
import { TCHappyMomentInfo } from "chat/imports/proto";
import { parseMessageFromBase64 } from "chat/imports/utils";
import { MessageMedia } from "chat/types";

interface HappyMomentMessageProps {
  conversationId: string;
  messageConfig: MessageConfig;
  messageId: number;
  messageMedia: MessageMedia;
  messagePayload: string;
}

interface ParsedPayload {
  momentId?: string;
}

export const HappyMomentMessage: FC<HappyMomentMessageProps> = ({
  conversationId,
  messageConfig,
  messageId,
  messageMedia,
  messagePayload,
}) => {
  const history = useHistory();

  const goToHappyMoment = useMemo(() => {
    const { momentId }: ParsedPayload = parseMessageFromBase64(
      messagePayload,
      TCHappyMomentInfo
    );

    if (!momentId) {
      return;
    }

    const happyMomentLink = makeLinkToHappyMoment(momentId);

    return () =>
      history.push(happyMomentLink, { fromHappyMomentMessage: true });
  }, [history, messagePayload]);

  return (
    <VideoMessage
      alternateClickFullscreenHandler={goToHappyMoment}
      conversationId={conversationId}
      id={messageId}
      isBlurred={false}
      isShowOnlyPreview
      media={messageMedia}
      messageConfig={messageConfig}
    />
  );
};
