import { publicFeedResponseAdapter } from "api/utils/adapters";
import fetch, { enhancedFetch, urlAndParams } from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { PublicFeedByAccountResponse } from "generated/proto/FeedApi";

export const fetchVisitorProfileByNickname = (nickname: string) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/visitors/profilealias/api/alias/convert`,
      {
        alias: nickname,
      }
    )
  ).then((resp) => resp.text());

export const fetchVisitorProfileByAccountId = (accountId: string) =>
  enhancedFetch(
    urlAndParams(`${getProxycadorHost()}/profilerator/v4/visitors/profile`, {
      encryptedAccountId: accountId,
    }),
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }
  ).then((resp) => resp.json());

export const fetchVisitorProfileFeedByAccountId = (accountId: string) =>
  enhancedFetch(`${getProxycadorHost()}/feed/v9/feed/public/${accountId}`, {
    method: "GET",
    headers: {
      Accept: "application/x-protobuf",
      "Content-Type": "application/json",
    },
  })
    .then((resp) => resp.arrayBuffer())
    .then((buffer) =>
      publicFeedResponseAdapter(
        PublicFeedByAccountResponse.decode(new Uint8Array(buffer), undefined)
      )
    );

export const fetchVisitorProfileStreamByAccountId = (broadcasterId: string) =>
  enhancedFetch(
    urlAndParams(
      `${getProxycadorHost()}/showroom-streams/api/public/stream/v1/id`,
      { broadcasterId }
    )
  ).then((resp) => resp.text());

export const fetchVisitorProfileVipConfigById = (vipConfigId: string) =>
  enhancedFetch(
    `${getProxycadorHost()}/visitors/vipmanager/v1/vip/configs/id/${vipConfigId}`
  ).then((resp) => resp.json());
