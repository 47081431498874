import { PersonalFeedType } from "src/enums";
import { BasicStreamInfo } from "src/types/richFragment/Stream";
import {
  CategoryInfo,
  Settings,
  StreamDetail,
} from "src/types/streams/CategoryInfo";
import { LiveStreamsData } from "src/types/streams/LiveStreams";

enum CATEGORY_INFO {
  CODE = "code",
  LIST = "categoryInfoList",
}

export interface StreamsFeedWithSettings {
  [key: string]: { settings: Settings } & BasicStreamInfo;
}

export interface StreamsFeed extends LiveStreamsData {
  gotEmptyResult: boolean;
  pageCount: number;
}

export interface FollowingRecommendationsData {
  [CATEGORY_INFO.CODE]: string;
  [CATEGORY_INFO.LIST]: CategoryInfo[];
}

export const commonFollowingsRecommendations = [
  PersonalFeedType.RECOMMENDED,
  PersonalFeedType.FOLLOWING,
];

export const allFollowingsRecommendations = [
  ...commonFollowingsRecommendations,
  PersonalFeedType.ALL,
];

export const selectStreamsListByMode = (
  data: FollowingRecommendationsData,
  mode: PersonalFeedType
): CategoryInfo | undefined =>
  data[CATEGORY_INFO.LIST]?.find(
    (categoryInfo: CategoryInfo) => categoryInfo.tag === mode
  );

const parseCachedStreamsFeed = (streamDetails: StreamDetail[]) =>
  streamDetails.reduce(
    (
      streamsFeed,
      {
        stream,
        relatedStreams,
        restrictions,
        hashtags,
        anchor,
        anchorPoints,
        settings,
        ticketPrice,
        viewerCount,
        tags,
        moderationLevel,
      }
    ) => ({
      ...streamsFeed,
      [stream.id]: {
        id: stream.id,
        status: stream.status,
        kind: stream.streamKind,
        title: stream.title,
        thumbnail: stream.thumbnail,
        broadcasterId: stream.encryptedAccountId,
        playlistUrl: stream.masterListUrl,
        relatedStreams,
        restrictions,
        hashtags,
        landscape: stream.landscape,
        moderationLevel,
        ticketPrice,
        viewerCount,
        tags,
        anchor,
        anchorPoints,
      },
      settings,
    }),
    {}
  );

const parseLiveStreamsFeed = (
  data: FollowingRecommendationsData | LiveStreamsData,
  meta: { mode: PersonalFeedType },
  cache = false
): StreamsFeed | StreamsFeedWithSettings => {
  if (cache === true) {
    if (
      CATEGORY_INFO.LIST in data &&
      commonFollowingsRecommendations.includes(meta.mode)
    ) {
      const streams = selectStreamsListByMode(data, meta.mode);
      if (!streams || !streams.streamInfoList.streamDetails) {
        return {};
      }

      return parseCachedStreamsFeed(streams.streamInfoList.streamDetails);
    } else if (
      CATEGORY_INFO.LIST in data &&
      [PersonalFeedType.ALL].includes(meta.mode)
    ) {
      return parseCachedStreamsFeed(
        data[CATEGORY_INFO.LIST].flatMap(
          (item) => item.streamInfoList.streamDetails || []
        )
      );
    } else if (CATEGORY_INFO.CODE in data) {
      return {};
    }
  }

  if (
    CATEGORY_INFO.LIST in data &&
    commonFollowingsRecommendations.includes(meta.mode)
  ) {
    const streams = selectStreamsListByMode(data, meta.mode);
    if (!streams || !streams.streamInfoList) {
      return {
        pageCount: 1,
        sessionId: "",
        requestId: streams?.requestId || "",
        totalCount: 0,
        result: [],
        gotEmptyResult: true,
        timestamp: new Date().getTime(),
      };
    }

    const { streamInfoList, requestId } = streams;
    const { sessionId, streamDetails = [] } = streamInfoList;

    const streamIds = streamDetails.map(
      (streamDetail: StreamDetail) => streamDetail.stream.id
    );

    return {
      pageCount: 1,
      sessionId,
      requestId,
      totalCount: streamIds.length,
      result: streamIds,
      gotEmptyResult: !(streamIds.length > 0),
      timestamp: new Date().getTime(),
    };
  }

  if (
    CATEGORY_INFO.LIST in data &&
    [PersonalFeedType.ALL].includes(meta.mode)
  ) {
    const list = data[CATEGORY_INFO.LIST];
    const streamIds = list.flatMap(
      (item) =>
        item.streamInfoList.streamDetails?.map((detail) => detail.stream.id) ||
        []
    );

    return {
      pageCount: 1,
      sessionId: list[0].streamInfoList.sessionId,
      requestId: list[0].requestId,
      totalCount: streamIds.length,
      result: streamIds,
      gotEmptyResult: !(streamIds.length > 0),
      timestamp: new Date().getTime(),
    };
  }

  const {
    sessionId,
    requestId,
    totalCount,
    result = [],
    timestamp,
  } = data as LiveStreamsData;

  return {
    pageCount: 1,
    sessionId,
    requestId,
    totalCount,
    result,
    gotEmptyResult: !(result.length > 0),
    timestamp,
  };
};

export default parseLiveStreamsFeed;
