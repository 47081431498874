import {
  EventFields,
  EventNames,
  SCREEN_NAME,
  emitEvent,
} from "chat/imports/analytics";
import {
  ChatMessageAnalyticsParams,
  ChatMessageSentStatusAnalytic,
} from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";

export function emitMessageSentEvent(
  params: ChatMessageAnalyticsParams,
  apiRequestStatusParams: ChatMessageSentStatusAnalytic
): void {
  const {
    chatId,
    recipientAccountId,
    flags,
    membersCnt,
    comment,
    screenState,
    peerId,
  } = params;
  const isGroupChat = isGroupChatId(chatId);
  const eventName = isGroupChat
    ? EventNames.GROUP_CHAT_MESSAGE_SENT
    : EventNames.SINGLE_CHAT_MESSAGE_SENT;

  const oneToOneChatParams = {
    [EventFields.RECIPIENT_ACCOUNT_ID]: recipientAccountId,
    [EventFields.CHAT_ID]: recipientAccountId,
    [EventFields.COMMENT]: comment,
    [EventFields.SCREEN]: SCREEN_NAME.CHAT_SINGLE,
    [EventFields.SCREEN_STATE]: screenState,
  };

  const groupChatParams = {
    [EventFields.CHAT_ID]: chatId,
    [EventFields.MEMBERS_CNT]: membersCnt,
  };

  emitEvent(eventName, {
    [EventFields.FLAGS]: flags,
    [EventFields.PEER_ID]: peerId,
    ...apiRequestStatusParams,
    ...(isGroupChat ? groupChatParams : oneToOneChatParams),
  });
}
