import { useIntl } from "react-intl";
import { chatMessages } from "chat/components/common/messages";
import { DROPZONE_FILE_REJECTION_CODES } from "chat/enums";
import { ToastType } from "chat/imports/constants";
import { getChatMaxMediaSize } from "chat/imports/environment";
import { convertBytesToMB } from "chat/utils/convertBytesToMB";

export const useDropzoneErrorsMap = () => {
  const intl = useIntl();

  const MEDIA_MAX_SIZE = getChatMaxMediaSize();
  const MEDIA_MAX_SIZE_IN_MB = convertBytesToMB(MEDIA_MAX_SIZE);

  return {
    [DROPZONE_FILE_REJECTION_CODES.FILE_INVALID_TYPE]: {
      type: ToastType.REGULAR,
      title: chatMessages.typeIsInvalid,
    },
    [DROPZONE_FILE_REJECTION_CODES.FILE_TOO_LARGE]: {
      type: ToastType.REGULAR,
      title: intl.formatMessage(chatMessages.maxSize, {
        mb: MEDIA_MAX_SIZE_IN_MB,
      }),
    },
  };
};
