import { shallowEqual, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { chatSelectors } from "chat/exports/state/selectors";
import {
  getIsChatDeleteMessageEnabled,
  getIsChatEditMessageEnabled,
  getIsChatSaveMediaEnabled,
  getIsCopyMessageEnabled,
} from "chat/soc/chatSoc";
import { isSystemChat } from "state/abTests";
import { RootState } from "state/delegate";
import { linkToMessageRequest } from "ui/navigation/links";

export const selector = (state: RootState) => {
  const conversationId = chatSelectors.getCurrentConversationId(state);

  return {
    isDeleteMessageEnabled: getIsChatDeleteMessageEnabled(state),
    isEditMessageEnabled: getIsChatEditMessageEnabled(state),
    isCopyMessageEnabled: getIsCopyMessageEnabled(state),
    isSaveMediaEnabled: getIsChatSaveMediaEnabled(state),
    isSystem: isSystemChat(state, conversationId as string),
  };
};

export const useShowMessageActionsMenu = () => {
  const {
    isDeleteMessageEnabled,
    isEditMessageEnabled,
    isCopyMessageEnabled,
    isSaveMediaEnabled,
    isSystem,
  } = useSelector(selector, shallowEqual);

  const isMessageRequestRoute = useRouteMatch(linkToMessageRequest);

  const isAnyOptionEnabled =
    isDeleteMessageEnabled ||
    isEditMessageEnabled ||
    isCopyMessageEnabled ||
    isSaveMediaEnabled;

  return !(!isAnyOptionEnabled || isSystem || isMessageRequestRoute);
};
