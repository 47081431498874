import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import {
  loadReferredUsersListRequest,
  registerReferralUserRequest,
} from "src/features/referralProgram/api/referralUsersApi";
import { TOP_USERS_NUM_MOBILE } from "src/features/referralProgram/common/constants";
import {
  ReferralType,
  ReferredUsersOrder,
  ReferredUsersSortBy,
  TimeRange,
  UserActivity,
} from "src/features/referralProgram/common/enums";
import {
  ReferralInvitation,
  ReferredUsersListResponse,
} from "src/features/referralProgram/common/types";
import { batchLoadProfiles } from "src/features/referralProgram/imports/state";
import { RootState } from "state/delegate";

export const loadReferralUsersList = createAsyncThunk<
  ReferredUsersListResponse,
  void,
  { rejectValue: string; state: RootState }
>("lwc/referredUsers/loadReferralUsersList", async (payload, api) => {
  try {
    const userList = await loadReferredUsersListRequest(
      api.getState().referralProgram.queryParams
    );
    const accountIds = userList.data.map(({ accountId }) => accountId);

    api.dispatch(
      batchLoadProfiles({
        ids: accountIds,
        loadOnlyIfMissing: true,
        params: { basic: true, detail: false, live: true, follow: false },
      })
    );

    return userList;
  } catch (e) {
    // istanbul ignore next
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const loadTopReferralUsersList = createAsyncThunk<
  ReferredUsersListResponse,
  void,
  { rejectValue: string; state: RootState }
>("lwc/referredUsers/loadTopReferralUsersList", async (payload, api) => {
  const queryParams = {
    page: 1,
    order: ReferredUsersOrder.DESC,
    sortBy: ReferredUsersSortBy.EARNED_POINTS,
    activity: UserActivity.ANY,
    timeRange: TimeRange.LIFETIME,
    pageSize: TOP_USERS_NUM_MOBILE,
    nameFilter: "",
  };

  const [creatorsResp, supportersResp] = await Promise.all([
    loadReferredUsersListRequest({
      ...queryParams,
      referralType: ReferralType.CREATORS,
    }),
    loadReferredUsersListRequest({
      ...queryParams,
      referralType: ReferralType.SUPPORTERS,
    }),
  ]);

  creatorsResp.data.push(...supportersResp.data);
  creatorsResp.meta.totalUsers += supportersResp.meta.totalUsers;

  api.dispatch(
    batchLoadProfiles({
      ids: creatorsResp.data.map(({ accountId }) => accountId),
      loadOnlyIfMissing: true,
      params: { basic: true, detail: false, live: true, follow: false },
    })
  );

  return creatorsResp;
});

export const registerReferralUser = createAsyncThunk<
  void,
  ReferralInvitation,
  { state: RootState }
>("lwc/referredUsers/registerReferralUser", (referralInvitation) =>
  registerReferralUserRequest(referralInvitation)
);
