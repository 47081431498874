export enum SocialProvider {
  APPLE = "apple",
  GOOGLE = "google",
}

export interface AccountInfoResponse {
  accountResponse: {
    account: {
      countryCodeId: string;
      emails: {
        email: string[];
      };
      phoneNumbers: {
        phoneNumber: number[];
      };
      socialNetworkIds?: {
        id: string;
        name: SocialProvider;
      }[];
    };
  };
}
