import { parseBooleanEnabledSOC } from "state/abTests";

export const getIsChatDeleteMessageEnabled = parseBooleanEnabledSOC(
  "web.chats.message.delete.action.enabled",
  "0"
);

export const getIsChatEditMessageEnabled = parseBooleanEnabledSOC(
  "web.chats.message.edit.action.enabled",
  "0"
);

export const getIsChatSaveMediaEnabled = parseBooleanEnabledSOC(
  "web.chats.media.save.action.enabled",
  "0"
);

export const getIsMessageReadIndicatorEnabled = parseBooleanEnabledSOC(
  "web.chats.message.read.indicator.enabled",
  "0"
);

export const getIsCopyMessageEnabled = parseBooleanEnabledSOC(
  "web.chats.message.copy.action.enabled",
  "0"
);

export const getIsPinChatEnabled = parseBooleanEnabledSOC(
  "web.chats.pin.action.enabled",
  "0"
);

export const getIsChatMenuEnabled = parseBooleanEnabledSOC(
  "web.chats.conversation.menu.enabled",
  "0"
);

export const getIsMuteChatEnabled = parseBooleanEnabledSOC(
  "web.chats.conversation.mute.enabled",
  "0"
);

export const getIsChatEventsEnabled = parseBooleanEnabledSOC(
  "web.chats.events.enabled",
  "0"
);

export const getIsDeleteChatEnabled = parseBooleanEnabledSOC(
  "web.chats.delete.action.enabled",
  "0"
);

export const getIsVoiceMessageEnabled = parseBooleanEnabledSOC(
  "chats.voice.message.enabled",
  "0"
);

export const getIsMessageReactionsEnabled = parseBooleanEnabledSOC(
  "web.chats.message.reactions.enabled",
  "0"
);

export const getChatSendVideoEnabled = parseBooleanEnabledSOC(
  "web.chat.send.video.enabled",
  "0"
);
