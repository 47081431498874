import { EventFields, SCREEN_NAME, emitUiAction } from "chat/imports/analytics";
import { ConversationScreenState } from "chat/types";

export enum MessageTarget {
  LIVE_STREAM = "live_stream",
}

export const emitLiveStreamWatchClicked = ({ chatId }: { chatId: string }) => {
  emitUiAction({
    [EventFields.TARGET]: MessageTarget.LIVE_STREAM,
    [EventFields.TANGO_SCREEN]: SCREEN_NAME.CHAT_SINGLE,
    additionalParams: {
      [EventFields.TEXT]: ConversationScreenState.ONE_ON_ONE_CHAT,
      [EventFields.CHAT_ID]: chatId,
    },
  });
};
