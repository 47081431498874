export const pollUntil = (
  callback: () => boolean,
  delay: number,
  maxRetries = Infinity
): Promise<boolean> =>
  new Promise((resolve) => {
    const attempt = (remainingRetries: number) => {
      if (callback()) {
        resolve(true);
      } else if (remainingRetries === 0) {
        resolve(false);
      } else {
        setTimeout(() => attempt(remainingRetries - 1), delay);
      }
    };

    attempt(maxRetries);
  });
