import merge from "lodash.merge";
import { combineReducers } from "redux";
import { createTransform } from "redux-persist";
import {
  isAlternativeDomain,
  replaceDomainWithHostname,
} from "src/utils/alternativeDomainUtils";
import { ensureHttps } from "src/utils/ensureHttps";
import { mapValues, uniq, without } from "src/utils/miniLodash";
// eslint-disable-next-line no-restricted-imports
import {
  FAILED_TO_LOAD_PROFILE_IDS,
  FOLLOW_USER,
  GIFT_SENT,
  LIVE_POSTS_END_FETCH,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  LOADED_PROFILE_BY_NICKNAME,
  LOADED_PROFILES,
  REFERRALS_LIST_FETCH_END,
  RESET_LOADING_PROFILE_ERRORS,
  SET_LOADING_PROFILE_IDS,
  SINGLE_PROFILE_BEGIN_FETCH,
  SINGLE_PROFILE_END_FETCH,
  TOP_BROADCASTERS_END_FETCH,
  UNFOLLOW_USER,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_TOP_GIFTERS_END_FETCH,
  VIEWER_SESSION_UPDATE,
} from "state/actionTypes";

const initialState = {
  data: {},
  loading: [],
  loadingErrors: [],
};

export const persistConfig = {
  transforms: [
    createTransform(
      ({ data, storeAccountId }) => {
        if (storeAccountId && data && data[storeAccountId]) {
          return {
            ...initialState,
            data: { [storeAccountId]: data[storeAccountId] },
          };
        }

        return initialState;
      },
      (outboundState) => outboundState
    ),
  ],
  whitelist: ["basicProfile", "profileDetails", "liveStats", "followStats"],
};

const invalidIdsReducer = (state = [], action) => {
  switch (action.type) {
    case SINGLE_PROFILE_END_FETCH: {
      if (action.error && action.payload.status === 404) {
        return uniq([...state, action.meta.accountId].filter((x) => x));
      }
    }
  }

  return state;
};

const aliasIdsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOADED_PROFILE_BY_NICKNAME: {
      return { ...state, [action.payload.nickname]: action.payload.profileId };
    }
  }

  return state;
};

const reducerBase =
  ({ paramFilter, profileDataExtractor, additionalActionsHandler }) =>
  (state = initialState, action) => {
    switch (action.type) {
      case SINGLE_PROFILE_BEGIN_FETCH: {
        const { fieldsRequested, accountId } = action.meta;
        if (paramFilter(fieldsRequested) && accountId) {
          return { ...state, loading: [...state.loading, accountId] };
        }

        return state;
      }
      case SINGLE_PROFILE_END_FETCH: {
        const { fieldsRequested, isMe } = action.meta;
        if (!paramFilter(fieldsRequested)) {
          return state;
        }
        if (!action.error) {
          const { encryptedAccountId, ...rest } = action.payload;
          const data = profileDataExtractor(rest);

          return {
            ...state,
            storeAccountId: isMe ? encryptedAccountId : state.storeAccountId,
            data: {
              ...state.data,
              [encryptedAccountId]: data,
            },
            loading: without(state.loading, encryptedAccountId),
            loadingErrors: without(
              state.loadingErrors || [],
              encryptedAccountId
            ),
          };
        }
        const { accountId } = action.meta;
        if (accountId) {
          return {
            ...state,
            loading: without(state.loading, accountId),
            loadingErrors: state.loadingErrors
              ? [...state.loadingErrors, accountId]
              : [accountId],
          };
        }

        return state;
      }
      case SET_LOADING_PROFILE_IDS: {
        const { params } = action.meta;
        if (paramFilter(params)) {
          return {
            ...state,
            loading: [...state.loading, ...action.payload],
          };
        }
        break;
      }
      case FAILED_TO_LOAD_PROFILE_IDS: {
        const { ids, params } = action.meta;
        if (paramFilter(params)) {
          return {
            ...state,
            loading: without(state.loading, ...ids),
            loadingErrors: state.loadingErrors
              ? [...state.loadingErrors, ...ids]
              : [...ids],
          };
        }
        break;
      }
      case LOADED_PROFILES: {
        const { params } = action.meta;
        if (!paramFilter(params)) {
          break;
        }
        const ids = Object.keys(action.payload);
        const data = mapValues(action.payload, profileDataExtractor);

        return {
          ...state,
          data: merge({}, state.data, data),
          loading: without(state.loading, ...ids),
          loadingErrors: without(state.loadingErrors || [], ...ids),
        };
      }
      case LOADED_PROFILE_BY_NICKNAME: {
        const { params } = action.meta;

        if (!paramFilter(params)) {
          break;
        }

        const id = action.payload.nickname;
        const data = mapValues(action.payload.profile, profileDataExtractor);

        return {
          ...state,
          data: merge({}, state.data, data),
          loading: without(state.loading, id),
        };
      }
      case RESET_LOADING_PROFILE_ERRORS: {
        return {
          ...state,
          loadingErrors: [],
        };
      }
    }

    return (
      (additionalActionsHandler && additionalActionsHandler(state, action)) ||
      state
    );
  };

const replaceAlternativeDomainProfileUrl = ({
  profileThumbnailUrl,
  profilePictureUrl,
  ...rest
}) => ({
  ...rest,
  profileThumbnailUrl: replaceDomainWithHostname(profileThumbnailUrl),
  profilePictureUrl: replaceDomainWithHostname(profilePictureUrl),
});

const ensureHttpsOnBasicProfile = ({
  profileThumbnailUrl,
  profilePictureUrl,
  ...rest
}) => {
  const ensuredHttpsProfile = {
    ...rest,
    profileThumbnailUrl:
      profileThumbnailUrl && ensureHttps(profileThumbnailUrl),
    profilePictureUrl: profilePictureUrl && ensureHttps(profilePictureUrl),
  };

  if (isAlternativeDomain()) {
    return replaceAlternativeDomainProfileUrl(ensuredHttpsProfile);
  }

  return ensuredHttpsProfile;
};

const ensureSubscriptionLevel = (basicProfile) => {
  Object.keys(basicProfile).forEach((item) => {
    if (!basicProfile[item].subscriptionLevel) {
      basicProfile[item].subscriptionLevel = 0;
    }
  });

  return basicProfile;
};

const basicProfile = reducerBase({
  paramFilter: (param) => param.basic,
  profileDataExtractor: (x) =>
    x.basicProfile ? ensureHttpsOnBasicProfile(x.basicProfile) : undefined,
  additionalActionsHandler: (state, action) => {
    if (action.error) {
      return state;
    }
    if (
      action.type === TOP_BROADCASTERS_END_FETCH ||
      action.type === LIVE_POSTS_END_FETCH ||
      action.type === VIEWER_SESSION_UPDATE ||
      action.type === LIVE_RICH_NOTIFICATION_RECEIVED ||
      action.type === VIEWER_SESSION_TOP_GIFTERS_END_FETCH ||
      action.type === VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT ||
      action.type === REFERRALS_LIST_FETCH_END
    ) {
      if (
        action.payload &&
        action.payload.entities &&
        action.payload.entities.basicProfile
      ) {
        return {
          ...state,
          data: merge(
            {},
            state.data,
            mapValues(
              ensureSubscriptionLevel(action.payload.entities.basicProfile),
              ensureHttpsOnBasicProfile
            )
          ),
        };
      }
    }
  },
});
const profileDetails = reducerBase({
  paramFilter: (param) => param.detail,
  profileDataExtractor: (x) => {
    if (x.profileDetails) {
      const { backgroundUrl, ...rest } = x.profileDetails;

      return {
        ...rest,
        backgroundUrl: backgroundUrl && ensureHttps(backgroundUrl),
      };
    }
  },
});
const liveStats = reducerBase({
  paramFilter: (param) => param.live,
  profileDataExtractor: (x) => x.liveStats,
  additionalActionsHandler: (state, action) => {
    if (action.type === GIFT_SENT) {
      const { userId, additionInPoint } = action.payload;
      if (additionInPoint === undefined) {
        return state;
      }
      const liveData = state.data[userId];
      if (!liveData) {
        return state;
      }
      const newLiveData = {
        ...liveData,
        points: liveData.points + additionInPoint,
      };

      return {
        ...state,
        data: {
          ...state.data,
          [userId]: newLiveData,
        },
      };
    }
    if (action.type === LIVE_POSTS_END_FETCH && !action.error) {
      // TODO: Remove old streams logic with entities after all backend endpoints are updated
      // Jira ticket: https://tango-me.atlassian.net/browse/WEB-4575
      const { liveStats = {} } = action.payload.entities || {};
      const extractedPoints = Object.entries(liveStats).reduce(
        (pointsAccumulator, [id, { points }]) => {
          pointsAccumulator[id] = { points };

          return pointsAccumulator;
        },
        {}
      ); // taking only points since vip are invalid there

      return {
        ...state,
        data: merge({}, state.data, extractedPoints),
      };
    }
    if (
      action.type === VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT &&
      !action.error
    ) {
      const {
        detail: {
          anchor: { encryptedAccountId: broadcasterId } = {},
          anchorPoints,
        } = {},
        entities: { liveStats = {} } = {},
      } = action.payload;

      if (!broadcasterId) {
        return state;
      }

      const existingPoints = state.data[broadcasterId]
        ? state.data[broadcasterId].points
        : -Infinity;

      return {
        ...state,
        data: merge(
          {},
          state.data,
          {
            [broadcasterId]: { points: Math.max(anchorPoints, existingPoints) },
          },
          liveStats
        ),
      };
    }
    if (
      (action.type === VIEWER_SESSION_UPDATE && !action.error) ||
      action.type === LIVE_RICH_NOTIFICATION_RECEIVED
    ) {
      const { totalPointsOfBroadcaster } = action.payload;
      const { broadcasterId } = action.meta;
      if (
        !broadcasterId ||
        !totalPointsOfBroadcaster ||
        totalPointsOfBroadcaster < 0
      ) {
        return state;
      }
      const existingPoints = state.data[broadcasterId]
        ? state.data[broadcasterId].points
        : -Infinity;

      return {
        ...state,
        data: merge({}, state.data, {
          [broadcasterId]: {
            points: Math.max(totalPointsOfBroadcaster, existingPoints),
          },
        }),
      };
    }
  },
});
const followStats = reducerBase({
  paramFilter: (param) => param.follow,
  profileDataExtractor: ({ followStats }) => {
    if (followStats) {
      return mapValues(followStats, (v) => Math.max(v, 0));
    }

    return followStats;
  },
  additionalActionsHandler: (state, action) => {
    if (action.type === FOLLOW_USER || action.type === UNFOLLOW_USER) {
      const diff = action.type === FOLLOW_USER ? 1 : -1;
      const { accountId, me } = action.payload;
      let otherProfile = state.data[accountId];

      let myProfile = state.data[me];
      if (myProfile) {
        myProfile = {
          ...myProfile,
          followingCount: myProfile.followingCount + diff,
        };
      }
      if (otherProfile) {
        otherProfile = {
          ...otherProfile,
          followersCount: otherProfile.followersCount + diff,
        };
      }
      if (otherProfile || myProfile) {
        return {
          ...state,
          data: { ...state.data, [accountId]: otherProfile, [me]: myProfile },
        };
      }
    }
    if (action.type === TOP_BROADCASTERS_END_FETCH && !action.error) {
      return {
        ...state,
        data: merge({}, state.data, action.payload.entities.followStats),
      };
    }
  },
});

export default combineReducers({
  basicProfile,
  profileDetails,
  liveStats,
  followStats,
  invalidIds: invalidIdsReducer,
  aliasIds: aliasIdsReducer,
});

const localSelectors = {
  isLoadingProfileById: (state, id) => state.loading.includes(id),
  isLoadingProfileByIdWithError: (state, id) =>
    state.loadingErrors && state.loadingErrors.includes(id),
  getDataById: (state, id) => state.data[id],
};

const checker =
  (selector) =>
  (
    state,
    id,
    { basic = false, detail = false, live = false, follow = false } = {}
  ) => {
    if (basic && !selector(state.basicProfile, id)) {
      return false;
    }
    if (detail && !selector(state.profileDetails, id)) {
      return false;
    }
    if (live && !selector(state.liveStats, id)) {
      return false;
    }

    return !(follow && !selector(state.followStats, id));
  };

export const selectors = {
  isLoadingProfileById: checker(localSelectors.isLoadingProfileById),
  isLoadingProfileByIdWithError: checker(
    localSelectors.isLoadingProfileByIdWithError
  ),
  isLoadingProfileFailed: (state, id) =>
    state.basicProfile.loadingErrors.includes(id),
  getLoadingProfileErrors: (state) => state.basicProfile.loadingErrors || [],
  hasCompleteProfileForParams: checker(localSelectors.getDataById),
  getBasicProfile: (state, id) =>
    localSelectors.getDataById(state.basicProfile, id),
  getProfileDetails: (state, id) =>
    localSelectors.getDataById(state.profileDetails, id),
  getLiveStats: (state, id) => localSelectors.getDataById(state.liveStats, id),
  getFollowStats: (state, id) =>
    localSelectors.getDataById(state.followStats, id),
  isBadAccountId: (state, id) => state.invalidIds.includes(id),
  getProfileIdByNickname: (state, nickname) => state.aliasIds[nickname],
  isUserPostsListVisible: (state) => state.isUserPostsListVisible,
};
