import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import siftWrapper from "@analytics/sift/siftWrapper";
import { getIsSiftEnabled, getSiftBeaconKey } from "state/abTests";
import { RootState } from "state/delegate";
import { deviceInfoSelectors, userSelectors } from "state/selectors";

const selector = (state: RootState) => ({
  accountId: userSelectors.getMyAccountId(state),
  siftBeaconKey: getSiftBeaconKey(state),
  isSiftEnabled: getIsSiftEnabled(state),
  isWebview: deviceInfoSelectors.getWebview(state),
});

const useSiftSetup = () => {
  const { accountId, siftBeaconKey, isSiftEnabled, isWebview } = useSelector(
    selector,
    shallowEqual
  );

  useEffect(() => {
    if (isWebview || !isSiftEnabled || !siftBeaconKey) {
      return;
    }

    if (accountId) {
      siftWrapper.init({ beaconKey: siftBeaconKey, userId: accountId });
    } else {
      siftWrapper.unsetUserId();
    }
  }, [accountId, isSiftEnabled, siftBeaconKey, isWebview]);
};

export default useSiftSetup;
