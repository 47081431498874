import { enhancedFetch } from "src/features/mos/imports/api";
import { getProxycadorHost } from "src/features/mos/imports/environment";
import {
  FetchMosV2LineupResponse,
  FreeFollowGift,
  FreeFollowGiftParams,
  FreeFollowGiftResponse,
} from "src/features/mos/types";

const STREAM_GIFT_ANCHOR_TYPE = 1;

export const fetchMosV2Lineup = ({
  streamId,
  streamerId,
  signal,
}: {
  signal: AbortSignal;
  streamId: string;
  streamerId: string;
}): Promise<FetchMosV2LineupResponse> =>
  enhancedFetch(
    `${getProxycadorHost()}/gift-box/public/v2/client/lineup?streamId=${streamId}&streamerId=${streamerId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      signal,
    }
  ).then((resp) => resp.json());

const sendFreeFollowGift = (
  body: FreeFollowGift
): Promise<FreeFollowGiftResponse> =>
  enhancedFetch(`${getProxycadorHost()}/stream/social/v2/followGift`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => resp.json());

export const sendFreeFollowGiftToStream = ({
  streamId,
  giftId,
}: FreeFollowGiftParams) =>
  sendFreeFollowGift({
    giftAnchorType: STREAM_GIFT_ANCHOR_TYPE,
    giftAnchorId: streamId,
    giftId,
  });
