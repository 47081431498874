import { MessageType } from "chat/types";

export const MESSAGES_WITH_DEFAULT_SPACING = [
  MessageType.TEXT_MESSAGE,
  MessageType.UNSUPPORTED,
  MessageType.UNKNOWN,
  MessageType.VIDEO_SAVED_INFO_MESSAGE,
  MessageType.PHOTO_SAVED_INFO_MESSAGE,
  MessageType.VOICE_MESSAGE_2,
  MessageType.VIDEO_PTT,
  MessageType.CHAT_UNLOCKED,
  MessageType.PROFILE_MESSAGE,
  MessageType.AUDIO_MESSAGE,
  MessageType.DEEPLINK_MESSAGE,
  MessageType.FAMILY_INVITE_MESSAGE,
  MessageType.GO_LIVE_TO_BC_MESSAGE,
  MessageType.GROUP_MEMBER_JOIN,
  MessageType.GROUP_MEMBER_LEAVE,
  MessageType.GROUP_NAME_CHANGE,
  MessageType.GROUP_PICTURE_CHANGE,
  MessageType.GROUP_SETTING_CHANGED,
  MessageType.GROUP_UPDATE,
  MessageType.LIKE_MESSAGE,
  MessageType.MISSED_CALL_MESSAGE,
  MessageType.NORMAL_CALL_MESSAGE,
  MessageType.REFERRAL_MESSAGE,
  MessageType.SCREENSHOT_INFO_MESSAGE,
  MessageType.SOCIAL_POST_MESSAGE,
  MessageType.STICKER_MESSAGE,
  MessageType.TANGO_SURPRISE_MESSAGE,
  MessageType.TEXT_MESSAGE_WITH_LINK,
  MessageType.VIP_ASSIGNMENT_MESSAGE,
];

export const MESSAGES_FROM_TANGO_DATE_DEFAULT = [
  MessageType.SDK_EXTERNAL_MESSAGE,
  MessageType.DEVICE_LOGIN_INFO_MESSAGE,
];

export const MESSAGES_WITHOUT_ABSOLUTE_DATE = [MessageType.GIFT_IN_CHAT];
export const DELIMITERS_REGEX = /^(\r\n\t|\n|\r\t)/;
export const DESKTOP_INPUT_BAR_HEIGHT_DEFAULT = 42;
export const MOBILE_INPUT_BAR_HEIGHT_DEFAULT = 42;
export const DESKTOP_ROWS_COUNT_DEFAULT = 6;
export const MOBILE_ROWS_COUNT_DEFAULT = 5;
