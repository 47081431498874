import { type PayloadAction, createReducer } from "@reduxjs/toolkit";
import { GoalStickerItemType } from "@analytics/enums";
import { BROADCAST_TITLE_MAX_LENGTH } from "src/constants";
import {
  BroadcastPictureUploadStatus,
  BroadcastSource,
  BroadcastStatus,
  StreamEvents,
} from "src/enums";
import { Gift } from "src/features/broadcastLobby/common/types";
import { MEDIA_DEVICES_PERMISSION_SHOWN_KEY } from "src/features/broadcastLobby/exports/constants";
import { LOGOUT_END } from "src/features/signin/exports/state/actionTypes";
import { Nullable } from "src/types/common";
import { StreamKind } from "src/types/richFragment/Stream";
import { isEmptyOrWhitespace } from "src/utils/miniLodash";
import * as broadcastActionCreators from "state/actionCreators/broadcast";
// eslint-disable-next-line no-restricted-imports
import { VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT } from "state/actionTypes";

export interface MultiBroadcastInvite {
  action: string;
  badgeCount: number;
  category: string;
  countryIconUrl?: string;
  countryIsoCode?: string;
  hostAccountId: string;
  hostCoverUrl: string;
  hostFirstName: string;
  hostFullName: string;
  hostLPBonus: number;
  hostLPBonusV2: number;
  hostLastName: string;
  hostPreviewUrl: Nullable<string>;
  hostStreamId: string;
  hostThumbnailUrl: Nullable<string>;
  hostTotalPoint: number;
  hostViewerCount: number;
  id: string;
  invitationType?: string;
  isBattleRequest?: boolean;
  isPremium: boolean;
  message: string;
  requestId: string;
  serverOnly: string;
  soundFile: string;
  sourceUuid: Nullable<string>;
  title: string;
  type: string;
}

interface MultiBroadcast {
  mbId?: string;
  multiBroadcastInvite?: MultiBroadcastInvite;
  participantsMutedState: {
    [key: string]: boolean;
  };
}

export interface BroadcastState {
  broadcastAudioEnabled: boolean;
  broadcastBanDuration?: number;
  broadcastGift?: Gift;
  broadcastGiftSoundsMuted: boolean;
  broadcastId?: string;
  broadcastKey?: string;
  broadcastKind?: StreamKind;
  broadcastPictureStatus: BroadcastPictureUploadStatus;
  broadcastPictureThumbnailUrl?: string;
  broadcastPictureUrl?: string;
  broadcastRecipientId?: string;
  broadcastScreenShareEnabled: boolean;
  broadcastSource?: BroadcastSource;
  broadcastStatus?: string;
  broadcastTitle: string;
  broadcastVideoEnabled: boolean;
  goalStickerItemType: GoalStickerItemType;
  mobileMediaPermissionsGranted: boolean;
  multiBroadcast: MultiBroadcast;
  startedPlayingPipsIds: string[];
}

export const broadcastPersistConfig = {
  whitelist: [
    "broadcastPictureStatus",
    "broadcastPictureUrl",
    "broadcastPictureThumbnailUrl",
    "broadcastTitle",
    "broadcastKey",
    "broadcastId",
    "broadcastKind",
    "broadcastGift",
  ],
};

export const getInitialMobileMediaPermissionsValue = () => {
  const storedValue = localStorage.getItem(MEDIA_DEVICES_PERMISSION_SHOWN_KEY);

  if (storedValue === null) {
    return false;
  }

  return JSON.parse(storedValue) !== false;
};

const broadcastInitialState = {
  broadcastKey: undefined,
  broadcastId: undefined,
  broadcastStatus: undefined,
  broadcastKind: undefined,
  broadcastSource: undefined,
  broadcastTitle: "",
  broadcastPictureStatus: BroadcastPictureUploadStatus.SUCCEEDED,
  broadcastPictureUrl: undefined,
  broadcastPictureThumbnailUrl: undefined,
  broadcastAudioEnabled: true,
  broadcastVideoEnabled: true,
  broadcastGiftSoundsMuted: false,
  broadcastScreenShareEnabled: false,
  broadcastBanDuration: undefined,
  broadcastRecipientId: "",
  broadcastGift: undefined,
  startedPlayingPipsIds: [],
  mobileMediaPermissionsGranted: getInitialMobileMediaPermissionsValue(),
  goalStickerItemType: GoalStickerItemType.CONTINUE,
  multiBroadcast: {
    mbId: undefined,
    participantsMutedState: {},
  },
};

const getBroadcastPictureStatus = (state: BroadcastState) =>
  state.broadcastPictureStatus;
const getBroadcastPictureUrl = (state: BroadcastState) =>
  state.broadcastPictureUrl;
const getBroadcastPictureThumbnailUrl = (state: BroadcastState) =>
  state.broadcastPictureThumbnailUrl;
const getBroadcastTitle = (state: BroadcastState) => state.broadcastTitle;

export const localBroadcastSelectors = {
  broadcastKey: (state: BroadcastState) => state.broadcastKey,
  broadcastId: (state: BroadcastState) => state.broadcastId,
  broadcastStatus: (state: BroadcastState) => state.broadcastStatus,
  broadcastBanDuration: (state: BroadcastState) => state.broadcastBanDuration,
  broadcastKind: (state: BroadcastState) => state.broadcastKind,
  broadcastSource: (state: BroadcastState) => state.broadcastSource,
  broadcastTitle: getBroadcastTitle,
  broadcastRecipientId: (state: BroadcastState) => state.broadcastRecipientId,
  isBroadcastTitleValid: (state: BroadcastState) => {
    const broadcastTitle = getBroadcastTitle(state);

    return (
      !isEmptyOrWhitespace(broadcastTitle) &&
      broadcastTitle.length < BROADCAST_TITLE_MAX_LENGTH
    );
  },
  getGoalStickerItemType: (state: BroadcastState) => state.goalStickerItemType,
  broadcastPictureStatus: getBroadcastPictureStatus,
  broadcastPictureUrl: getBroadcastPictureUrl,
  broadcastPictureThumbnailUrl: getBroadcastPictureThumbnailUrl,
  isBroadcastPictureValid: (state: BroadcastState) =>
    getBroadcastPictureUrl(state) !== undefined &&
    getBroadcastPictureThumbnailUrl(state) !== undefined &&
    getBroadcastPictureStatus(state) !== BroadcastPictureUploadStatus.STARTED,
  getBroadcastVideoEnabled: (state: BroadcastState) =>
    state.broadcastVideoEnabled,
  getBroadcastAudioEnabled: (state: BroadcastState) =>
    state.broadcastAudioEnabled,
  getBroadcastGiftSoundsMuted: (state: BroadcastState) =>
    state.broadcastGiftSoundsMuted,
  isBroadcastScreenShareEnabled: (state: BroadcastState) =>
    state.broadcastScreenShareEnabled,
  getMultiBroadcastInvite: (state: BroadcastState) =>
    state.multiBroadcast.multiBroadcastInvite,
  getStartedPlayingPipsIds: (state: BroadcastState) =>
    state.startedPlayingPipsIds,
  getMobileMediaPermissionsGranted: (state: BroadcastState) =>
    state.mobileMediaPermissionsGranted,
  broadcastGift: (state: BroadcastState) => state.broadcastGift,
  getMbId: (state: BroadcastState) => state.multiBroadcast.mbId,
  getStreamMutedStateByStreamId: (state: BroadcastState, streamId: string) =>
    state.multiBroadcast.participantsMutedState[streamId],
};

const broadcastReducer = createReducer<BroadcastState>(
  broadcastInitialState,
  (builder) => {
    builder
      .addCase(broadcastActionCreators.broadcastResetOneToOne, (state) => ({
        ...broadcastInitialState,
        broadcastKind: StreamKind.CHAT,
        broadcastRecipientId: state.broadcastRecipientId,
      }))
      .addCase(broadcastActionCreators.broadcastReset, (state) => ({
        ...broadcastInitialState,
        broadcastPictureUrl: state.broadcastPictureUrl,
        broadcastPictureThumbnailUrl: state.broadcastPictureThumbnailUrl,
        broadcastTitle: state.broadcastTitle,
        broadcastId: state.broadcastId,
        broadcastKey: state.broadcastKey,
        broadcastGift: state.broadcastGift,
        broadcastKind:
          state.broadcastKind === StreamKind.CHAT
            ? broadcastInitialState.broadcastKind
            : state.broadcastKind,
      }))
      .addCase(
        broadcastActionCreators.broadcastTitleChanged,
        (state, action) => {
          state.broadcastTitle = action.payload;
        }
      )
      .addCase(
        broadcastActionCreators.broadcastPictureUploadStarted,
        (state, action) => {
          const { broadcastPictureUrl, broadcastPictureThumbnailUrl } =
            action.payload;
          Object.assign(state, {
            broadcastPictureStatus: BroadcastPictureUploadStatus.STARTED,
            broadcastPictureUrl,
            broadcastPictureThumbnailUrl,
          });
        }
      )
      .addCase(
        broadcastActionCreators.broadcastPictureUploadSucceeded,
        (state, action) => {
          const { broadcastPictureUrl, broadcastPictureThumbnailUrl } =
            action.payload;
          Object.assign(state, {
            broadcastPictureStatus: BroadcastPictureUploadStatus.SUCCEEDED,
            broadcastPictureUrl,
            broadcastPictureThumbnailUrl,
          });
        }
      )
      .addCase(
        broadcastActionCreators.broadcastPictureUploadFailed,
        (state, action) => {
          const { prevBroadcastPictureUrl, prevBroadcastPictureThumbnailUrl } =
            action.meta;
          Object.assign(state, {
            broadcastPictureStatus: BroadcastPictureUploadStatus.FAILED,
            broadcastPictureUrl: prevBroadcastPictureUrl,
            broadcastPictureThumbnailUrl: prevBroadcastPictureThumbnailUrl,
          });
        }
      )
      .addCase(
        broadcastActionCreators.broadcastInitStarted,
        (state, action) => {
          const { broadcastKind, broadcastSource } = action.payload;
          Object.assign(state, {
            broadcastPictureStatus: BroadcastPictureUploadStatus.SUCCEEDED,
            broadcastStatus: BroadcastStatus.INIT_STARTED,
            broadcastKind,
            broadcastSource,
            broadcastKey: undefined,
            broadcastId: undefined,
          });
        }
      )
      .addCase(broadcastActionCreators.broadcastInitFailed, (state) => {
        state.broadcastStatus = BroadcastStatus.INIT_FAILED;
      })
      .addCase(broadcastActionCreators.broadcastGetInvite, (state, action) => {
        state.multiBroadcast.multiBroadcastInvite = action.payload;
      })
      .addCase(broadcastActionCreators.broadcastClearInvite, (state) => {
        state.multiBroadcast.multiBroadcastInvite = undefined;
      })
      .addCase(
        broadcastActionCreators.broadcastInitUserBan,
        (state, action) => {
          Object.assign(state, {
            broadcastBanDuration: action.payload,
            broadcastStatus: BroadcastStatus.INIT_USER_BAN,
          });
        }
      )
      .addCase(broadcastActionCreators.broadcastBadPicture, (state) => {
        state.broadcastStatus = BroadcastStatus.BAD_PICTURE;
      })
      .addCase(
        broadcastActionCreators.broadcastInitSucceeded,
        (state, action) => {
          const { broadcastId, broadcastKey } = action.payload;
          Object.assign(state, {
            broadcastStatus: BroadcastStatus.INIT_SUCCEEDED,
            broadcastId,
            broadcastKey,
          });
        }
      )
      .addCase(broadcastActionCreators.broadcastTerminateStarted, (state) => {
        state.broadcastStatus = BroadcastStatus.TERMINATE_STARTED;
      })
      .addCase(broadcastActionCreators.broadcastTerminateFailed, (state) => {
        state.broadcastStatus = BroadcastStatus.TERMINATE_FAILED;
      })
      .addCase(broadcastActionCreators.broadcastTerminateSucceeded, (state) => {
        state.broadcastStatus = BroadcastStatus.TERMINATE_SUCCEEDED;
      })
      .addCase(broadcastActionCreators.broadcastToggleVideo, (state) => {
        state.broadcastVideoEnabled = !state.broadcastVideoEnabled;
      })
      .addCase(broadcastActionCreators.broadcastToggleAudio, (state) => {
        state.broadcastAudioEnabled = !state.broadcastAudioEnabled;
      })
      .addCase(broadcastActionCreators.broadcastToggleGiftSounds, (state) => {
        state.broadcastGiftSoundsMuted = !state.broadcastGiftSoundsMuted;
      })
      .addCase(broadcastActionCreators.broadcastToggleScreenShare, (state) => {
        state.broadcastScreenShareEnabled = !state.broadcastScreenShareEnabled;
      })
      .addCase(
        broadcastActionCreators.mobileMediaPermissionsGranted,
        (state, action) => {
          state.mobileMediaPermissionsGranted = action.payload;
        }
      )
      .addCase(broadcastActionCreators.clearStartedPlayingPips, (state) => {
        state.startedPlayingPipsIds = [];
      })
      .addCase(
        broadcastActionCreators.setGoalStickerItemType,
        (state, action) => {
          state.goalStickerItemType = action.payload;
        }
      )
      .addCase(
        broadcastActionCreators.markPipAsStartedPlaying,
        (state, { payload: streamId }) => {
          state.startedPlayingPipsIds.push(streamId);
        }
      )
      .addCase(
        broadcastActionCreators.broadcastTransformToOneToOne,
        (state, { payload }) => {
          state.broadcastKind = StreamKind.CHAT;
          state.broadcastRecipientId = payload;
        }
      )
      .addCase(
        broadcastActionCreators.broadcastUpdateGift,
        (state, { payload }) => {
          state.broadcastGift = payload;
        }
      )
      .addCase(
        broadcastActionCreators.setPipMutedState,
        (state, { payload }) => {
          state.multiBroadcast.participantsMutedState[payload.streamId] =
            payload.muted;
        }
      )
      .addCase(broadcastActionCreators.resetPipMutedState, (state) => {
        state.multiBroadcast.participantsMutedState = {};
      })
      .addMatcher(
        (
          action
        ): action is PayloadAction<{
          entities: {
            events: Array<{
              data: {
                mbId: string;
              };
              type: StreamEvents;
            }>;
          };
        }> => action.type === VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
        (state, action) => {
          if (action.payload.entities) {
            const { events } = action.payload.entities;

            for (const eventId in events) {
              const event = events[eventId];

              if (event.type === StreamEvents.MULTI_BROADCAST) {
                state.multiBroadcast.mbId = event.data.mbId;
              }
            }
          }
        }
      )
      .addMatcher(
        (action) => action.type === LOGOUT_END,
        (state) => ({
          ...broadcastInitialState,
          broadcastPictureUrl: state.broadcastPictureUrl,
          broadcastPictureThumbnailUrl: state.broadcastPictureThumbnailUrl,
          broadcastTitle: state.broadcastTitle,
        })
      );
  }
);

export { broadcastActionCreators };

export default broadcastReducer;
