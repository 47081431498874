/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.VoiceMessageType || ($protobuf.roots.VoiceMessageType = {});

export const VoiceMessageType = $root.VoiceMessageType = (() => {

    function VoiceMessageType(properties) {
        this.waves = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    VoiceMessageType.prototype.waves = $util.emptyArray;

    VoiceMessageType.create = function create(properties) {
        return new VoiceMessageType(properties);
    };

    VoiceMessageType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.waves != null && message.waves.length)
            for (let i = 0; i < message.waves.length; ++i)
                writer.uint32(8).int32(message.waves[i]);
        return writer;
    };

    VoiceMessageType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    VoiceMessageType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VoiceMessageType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.waves && message.waves.length))
                        message.waves = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.waves.push(reader.int32());
                    } else
                        message.waves.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    VoiceMessageType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    VoiceMessageType.fromObject = function fromObject(object) {
        if (object instanceof $root.VoiceMessageType)
            return object;
        let message = new $root.VoiceMessageType();
        if (object.waves) {
            if (!Array.isArray(object.waves))
                throw TypeError(".VoiceMessageType.waves: array expected");
            message.waves = [];
            for (let i = 0; i < object.waves.length; ++i)
                message.waves[i] = object.waves[i] | 0;
        }
        return message;
    };

    VoiceMessageType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.waves = [];
        if (message.waves && message.waves.length) {
            object.waves = [];
            for (let j = 0; j < message.waves.length; ++j)
                object.waves[j] = message.waves[j];
        }
        return object;
    };

    VoiceMessageType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    VoiceMessageType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VoiceMessageType";
    };

    return VoiceMessageType;
})();

export { $root as default };
