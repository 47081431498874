import React, { FC, memo } from "react";
import ArticleMeta from "legal/components/ArticleMeta/ArticleMeta";
import { Headline, ListItem, Paragraph } from "legal/components/typography";
import { SCREEN_NAME, ScreenViewReporter } from "legal/imports/analytics";
import { FormattedMessageTitle } from "legal/imports/components";
import { legalMessages } from "legal/imports/constants";
import {
  LinkToFTCDisclosuresGuide,
  LinkToFTCNativeAdvertising,
  LinkToFTCTestimonialsAndEndorsements,
  LinkToTermsOfUse,
  MailToLegal,
  MailToTangoLive,
} from "legal/links";
import styles from "legal/common/LegalArticle.scss";

/* eslint-disable react/jsx-no-literals */
const BroadcasterAgreement: FC = () => (
  <>
    <ScreenViewReporter name={SCREEN_NAME.LEGAL_BROADCASTER_AGREEMENT} />
    <FormattedMessageTitle {...legalMessages.broadcasterAgreement} />

    <article className={styles.article}>
      <ArticleMeta postDate="Last Modified: 11.01.2025" withLanguagePicker />

      <Headline level={1}>Broadcaster Agreement</Headline>

      <Headline level={2}>Introduction</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          Please read carefully the following provisions of this Broadcaster
          Agreement (“<b>Agreement</b>”). This is a legal agreement between you
          ("
          <b>you</b>", "<b>your</b>" or “<b>Broadcaster</b>”) and TangoMe, Inc.
          (“
          <b>Tango</b>”) regarding your creation and transmission of live stream
          audiovisual content (the “<b>Broadcast Content</b>”) through use of
          the Tango software programs and related documentation being installed
          or used by you on any of your devices (the “<b>Software</b>
          ”). By downloading or otherwise accessing the Software, however
          accessed, and by providing Broadcast Content through the Software, you
          acknowledge that you have read, understood, and agree to be bound by
          the following terms of use of this Agreement (including any additional
          guidelines and future modifications).
        </ListItem>
        <ListItem>
          This Agreement shall be read in conjunction with the{" "}
          <LinkToTermsOfUse>Tango Terms of Use</LinkToTermsOfUse> ("
          <b>Terms of Use</b>") and Community Guidelines. In the event of any
          conflict or inconsistency between the terms of this Agreement and any
          applicable provision of the Terms of Use, as between Tango and you,
          this Agreement shall prevail to the extent of such conflict or
          inconsistency. Except otherwise provided, all capitalized terms shall
          have the same meaning as defined in the Terms of Use.
        </ListItem>
      </ol>

      <Headline level={2}>Eligibility and Additional Restrictions</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          You may only be a Broadcaster if you are eighteen (18) years of age or
          older and reside in a jurisdiction in which the use of the Software is
          permitted. Any transmitting of Broadcast Content by anyone under
          eighteen (18) or in a jurisdiction in which the use of the Software is
          prohibited, is unauthorized, unlicensed, and in violation of this
          Agreement.
        </ListItem>
        <ListItem>
          You are solely responsible for your Broadcast Content and the
          consequences of uploading, publishing or making it available. You
          represent and warrant that: (1) you are the creator and owner of the
          Broadcast Content, or otherwise have sufficient rights and authority
          to grant the rights granted herein; (ii) your Broadcast Content does
          not and will not violate this Agreement and the Terms of Use; (iii)
          your Broadcast Content does not and will not violate the rights of
          third parties, including but not limited to, copyrights, trademarks,
          rights of publicity, right or privacy etc.
        </ListItem>
        <ListItem>
          For avoidance of doubt, it is forbidden to:
          <ListItem>
            (i) stream fully or partially naked, or covering your body with a
            towel or blanket;
          </ListItem>
          <ListItem>
            (ii) stream in revealing lingerie, transparent nightgowns, or
            suggestive bodysuits;
          </ListItem>
          <ListItem>
            (iii) take a bath or shower during the livestream;
          </ListItem>
          <ListItem>
            (iv) perform any sexual motions, positions, or sounds;
          </ListItem>
          <ListItem>
            (v) engage in sexual touching or fondling (breast, buttocks, crotch,
            hips, or tongue);
          </ListItem>
          <ListItem>(vi) display or use sex toys;</ListItem>
          <ListItem>
            (vii) kiss and hug in a sexual way during the broadcast (a brief
            friendly hug or a kiss on the cheek is allowed);
          </ListItem>
          <ListItem>
            (viii) consume, produce, or display illicit substances;
          </ListItem>
          <ListItem>
            (ix) show harmful or violent imagery in livestreams (corpses,
            suicide, animal cruelty, terrorism);
          </ListItem>
          <ListItem>
            (x) display or use firearms, cold steel arms, or other kinds of
            weapons.
          </ListItem>
        </ListItem>
        <ListItem>
          You are solely responsible for the completeness, truth and accuracy of
          any Broadcast Content transmitted or displayed by you using the
          Software. Any Broadcast Content transmitted by you will be considered
          non-confidential and non-proprietary. Without derogating from the
          above, you shall possess all authorizations, licenses, consents and
          permissions necessary to publish or distribute any of the Broadcast
          Content to any person (including the right of Tango to use such
          Broadcast Content in accordance with the Terms of Use).
        </ListItem>
        <ListItem>
          Your use of the Software to broadcast Broadcast Content is for
          personal use only, except as permitted herein or as otherwise
          permitted by Tango. You shall not allow others to use your Tango
          account to provide Broadcast Content. If your access to the Software
          has been suspended, limited or terminated by Tango, you shall not
          create another Tango account or request others to create another Tango
          account in your favor.
        </ListItem>
        <ListItem>
          The Software is provided to you by Tango free of charge. Your use of
          the Software to broadcast Broadcast Content that may or may not be
          viewed by other users of the Software, shall not give rise to any
          actual or deemed payment of consideration between you and Tango.
        </ListItem>
      </ol>

      <Headline level={2}>Limitation on Use</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          You hereby warrant that you have all necessary and sufficient rights
          to share any information with Tango, the Software and other users of
          its community.
        </ListItem>
        <ListItem>You hereby warrant that you will not:</ListItem>
      </ol>

      <ul className={styles.ul}>
        <ListItem>
          Impersonate any person or entity or otherwise misrepresent
          affiliation, connection or association with any person or entity, or
          use any fraudulent, misleading or inaccurate contact information;
        </ListItem>
        <ListItem>
          Violate other users' or third parties' rights to privacy, publicity
          and any other rights;
        </ListItem>
        <ListItem>
          Use the Software to broadcast Broadcast Content that a reasonable
          person could deem to be: stalking, offensive, abusive, obscene,
          objectionable, defamatory, libelous, fraudulent or deceptive,
          indecent, pornographic, profane, threatening, advocating harassment or
          intimidation, distressing, vulgar, hateful, malicious, harmful for
          minors, racially or ethnically offensive, advocating racism, bigotry,
          abusive towards minors in any manner, hatred or physical harm of any
          kind against any group or individual, or disparaging the religious,
          political, or legal agenda of any person or entity, or is otherwise
          inconsistent with our Community Guidelines;
        </ListItem>
        <ListItem>
          Submit or promote Broadcast Content that may endanger your life, lead
          to your physical harm, or encourage others to engage in physically
          harmful behavior; this includes but is not limited to: suicide
          threats, glorification or encouragement of self-harm, intentional
          physical trauma, illegal use of drugs, illegal or dangerous
          consumption of alcohol, and dangerous or distracting driving;
        </ListItem>
        <ListItem>
          Use the Software to broadcast Broadcast Content in connection with any
          advertisements, solicitations, chain letters, pyramid schemes,
          spamming or any other commercial communication, except as permitted
          under this Agreement;
        </ListItem>
        <ListItem>
          Use the Software to broadcast Broadcast Content which promotes illegal
          activities, or the violation of any local, state, national, or
          international law or regulation, including, without limitation, laws
          governing intellectual property and other proprietary rights
          (including, but not limited to, patents, trademarks, trade secrets,
          copyrights, or any confidential, proprietary or trade secret
          information of any third party), information protection and privacy,
          including, but not limited to, content which disseminates another
          person's personal information without his or her permission.
        </ListItem>
      </ul>

      <Headline level={2}>Promotions</Headline>
      <Paragraph>
        You may promote, administer, or conduct a promotion (e.g., a contest or
        sweepstakes) on, through, or utilizing the Software (a “<b>Promotion</b>
        ”). If you choose to promote, administer, or conduct a Promotion, you
        must adhere to the following rules: (1) you may carry out Promotions to
        the extent permitted by applicable law and you are solely responsible
        for ensuring that any Promotions comply with any and all applicable
        laws, obligations, and restrictions; (2) you will be classified as the
        promoter of your Promotion in the applicable jurisdiction(s) and you
        will be solely responsible for all aspects of and expenses related to
        your Promotion, including without limitation the execution,
        administration, and operation of the Promotion; drafting and posting any
        official rules; selecting winners; issuing prizes; and obtaining all
        necessary third-party permissions and approvals, including without
        limitation filing any and all necessary registrations and bonds. Tango
        has the right to remove your Promotion from the Software or Services if
        Tango reasonably believes that your Promotion does not comply with this
        Agreement, the Terms of Use or applicable law; (3) Tango is not
        responsible for and does not endorse or support any such Promotions. You
        may not indicate that Tango is a sponsor or co-sponsor of the Promotion;
        and (4) you will display or read out the following disclaimer when
        promoting, administering, or conducting a Promotion: “This is a
        promotion by [Your Name]. Tango does not sponsor or endorse this
        promotion and is not responsible for it.”
      </Paragraph>

      <Headline level={2}>Endorsements/Testimonials</Headline>
      <Paragraph>
        You agree that if your Broadcast Content will include any endorsements
        or testimonials your Broadcast Content will comply with the FTC’s{" "}
        <LinkToFTCTestimonialsAndEndorsements />, the FTC’s{" "}
        <LinkToFTCDisclosuresGuide />, the FTC’s <LinkToFTCNativeAdvertising />,
        and any other guidelines issued by the FTC from time to time (the “
        <b>FTC Guidelines</b>”), as well as any other advertising guidelines
        required under applicable law. For example, if you have been paid or
        provided with free products in exchange for discussing or promoting a
        product or service through the Software or Services, or if you are an
        employee of a company and you decide to discuss or promote that
        company’s products or services through the Software or Services, you
        agree to comply with the FTC Guidelines’ requirements for disclosing
        such relationships. You, and not Tango, are solely responsible for any
        endorsements or testimonials you make regarding any product or service
        through the Software or Services.
      </Paragraph>

      <Headline level={2}>Diamonds Program</Headline>
      <ol className={styles.alphabetical}>
        <ListItem>
          Any Digital Gifts that are given to you are converted in your account
          to, and are referred to herein as, “Diamonds”. Notwithstanding your
          receipt or possession of Diamonds, you acknowledge and agree that the
          amounts of any Diamonds in your Tango account do not refer to any
          credit balance of real currency or its equivalent until withdrawn.
        </ListItem>
        <ListItem>
          Any giving of a Digital Gift by a user of the Software to a
          Broadcaster is made solely in the User's discretion. Tango merely
          facilitates the collection and transmission of the Digital Gift to the
          Broadcaster and shall not be deemed as acting as an agent in procuring
          or providing the Digital Gifts. Tango also retains no actual or
          apparent authority to contract on behalf of either any user of the
          Software or Broadcaster to effectuate the giving of Digital Gifts. The
          sole contractual rights arising as between the Broadcaster and the
          User are granted in this Agreement and the Terms of Use and nothing in
          this Agreement or the Terms of Use shall be read to imply that a right
          or obligation is provided with respect to Tango as to the giving of
          Digital Gifts.
        </ListItem>
        <ListItem>
          You acknowledge that the ratio of Digital Gift to Diamonds is detailed
          on Tango's platform (as amended). By your use of the Software to
          broadcast Broadcast Content, you confirm your understanding and
          acceptance of the then-current ratio of Digital Gifts to Diamonds at
          the time of their redemption.
        </ListItem>
        <ListItem>
          Any virtual currency balance shown in your Tango account does not
          constitute a real world balance or reflect any stored value. Due to
          the conversion, clearing, and transfer costs associated with any
          payment made to a Broadcaster seeking to redeem their Diamonds (
          <b>"Transfer Fees"</b>), please note that in the event the Transfer
          Fees exceed your current Diamond balance, you will be charged for any
          discrepancies between the balance and the Transfer Fees. Furthermore,
          when you ask to redeem your Diamonds, you acknowledge and agree that
          you shall pay any applicable charges, including taxes and any fees
          related to your redemption, including, without limitation, platform
          fees and processing fees. You may not redeem more than 400,000
          Diamonds (or such other amount as may be determined from time to time
          by Tango in accordance with its policies and procedures) per day.
          Tango reserves the right to temporarily confiscate from your Tango
          account any unredeemed Diamonds in the event that Tango determines, in
          its sole discretion, that you have not logged in to your Tango account
          during a period of 45 days. Any confiscated Diamonds will be
          reinstated in your account on return. In addition, you will not be
          able to redeem Diamonds if your access to the Software has been
          suspended, limited or terminated by you or by Tango.
        </ListItem>
        <ListItem>
          You may occasionally receive Diamonds that were converted from Coins
          earned from incentive programs or referral rewards made by Tango, as
          determined in Tango's sole discretion (“<b>Promoted Diamonds</b>”).
          You must redeem the Promoted Diamonds in your Tango account by no
          later than 45 days after such Promoted Diamonds have been received by
          you (“<b>Redemption Period</b>”). Tango reserves the right to
          temporarily confiscate from your Tango account any Promoted Diamonds
          not redeemed within the Redemption Period. Any confiscated Promoted
          Diamonds will be reinstated in your account on return.
        </ListItem>
        <ListItem>
          Tango may, at its sole discretion, reject any attempted redemption and
          withhold any funds related to your Tango account if it has a reason to
          believe that: (i) you have breached this Agreement or the Terms of
          Use; (ii) your account is related to fraudulent activity; (iii) your
          account or Broadcast Content are related to illegal activities; or
          (iv) the electronic transfer of funds by the User to Tango was not
          successfully completed for any reason.
        </ListItem>
        <ListItem>
          In the event your Tango account balance with respect to Diamonds is
          incorrect due to system maintenance or other technical failures as
          determined by Tango in its sole discretion, Tango will use
          commercially reasonable efforts to correct your Tango account balance.
          You may contact us regarding your Tango account balance at{" "}
          <MailToTangoLive />.
        </ListItem>
        <ListItem>
          You are solely responsible for paying all federal, state and other all
          type of taxes (direct or indirect taxes) related to your Diamonds,
          funds or withdrawal in accordance with all applicable laws. Incase we
          are entitled to withheld any tax or duty on behalf of you; we may send
          you federal or state tax forms and/or other appropriate form(s).. In
          that case, we may withhold from your any amount required to be
          withheld by applicable laws, including amounts due in connection with
          your failure to complete relevant tax documentation.
        </ListItem>
        <ListItem>
          Tango takes fraud seriously. Please note that in addition to taking
          any desirable or necessary legal action, Tango reserves the right to
          terminate your use of the Software and Services and/or to permanently
          confiscate any Diamonds from your Tango account that it determines, in
          its sole discretion, were acquired using fraudulent or otherwise
          improper means. This includes confiscation of any Diamonds which have
          been converted from Digital Gifts received by you from another user in
          which such Digital Gifts were purchased or received fraudulently.
          Tango will notify you prior to such confiscation in order to provide
          an opportunity for you to make an appeal via the Services; provided,
          however, that Tango’s determination, in its sole discretion, shall be
          final and binding on you.
        </ListItem>
        <ListItem>
          Any amounts confiscated from you in accordance herewith shall be
          reduced from your Diamonds balance.
        </ListItem>
      </ol>

      <Headline level={2}>General</Headline>
      <Paragraph>
        Section 24 of the Terms of Use applies to this Agreement, insofar as
        applicable in the context of this Agreement.
      </Paragraph>

      <Headline level={2}>Contact Us</Headline>
      <Paragraph>
        If you have any questions about this Terms of Use or the Software,
        please contact us at: <MailToLegal />
      </Paragraph>
    </article>
  </>
);

export default memo(BroadcasterAgreement);
