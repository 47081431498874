import { getStreamSettings } from "api/stream";
import { updatedLiveStreamSettings } from "state/actionCreators/streamInfo";
import { streamsCacheSelectors } from "state/selectors";
import { genericNetworkError } from "state/actionCreators/networkError";

export default ({ streamId, ignoreCache = false }) =>
  async (dispatch, getState) => {
    if (!streamId) {
      return;
    }
    const state = getState();
    if (!ignoreCache) {
      if (streamsCacheSelectors.getStreamSettingsById(state, streamId)) {
        return;
      }
    }
    try {
      const data = await getStreamSettings({ streamId });
      const { code, settings } = data;
      if (code !== "OK") {
        dispatch(genericNetworkError({ status: code }));
        return;
      }
      dispatch(updatedLiveStreamSettings({ streamId, settings }));
    } catch (error) {
      dispatch(genericNetworkError(error));
    }
  };
