/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.StreamClient || ($protobuf.roots.StreamClient = {});

export const StreamType = $root.StreamType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "PUBLIC"] = 1;
    values[valuesById[2] = "PRIVATE"] = 2;
    return values;
})();

export const StreamProtocol = $root.StreamProtocol = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "RTMP"] = 1;
    values[valuesById[2] = "SRT"] = 2;
    return values;
})();

export const TargetStreamType = $root.TargetStreamType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "PUBLIC_TARGET"] = 1;
    values[valuesById[2] = "PREMIUM_TARGET"] = 2;
    values[valuesById[3] = "CHAT_TARGET"] = 3;
    values[valuesById[4] = "CHAT_GROUP_TARGET"] = 4;
    return values;
})();

export const LPInviter = $root.LPInviter = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "NO_ONE"] = 1;
    values[valuesById[2] = "FRIENDS_ONLY"] = 2;
    values[valuesById[3] = "ANYONE"] = 3;
    return values;
})();

export const StreamStatus = $root.StreamStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "INITED"] = 1;
    values[valuesById[2] = "LIVING"] = 2;
    values[valuesById[3] = "TERMINATED"] = 3;
    values[valuesById[4] = "SUSPENDED"] = 4;
    values[valuesById[5] = "EXPIRED"] = 5;
    values[valuesById[6] = "UNKNOWN"] = 6;
    return values;
})();

export const GetViewerResponseCode = $root.GetViewerResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "GET_VIEWER_SUCCESS"] = 1;
    values[valuesById[2] = "GET_VIEWER_BAD_REQUEST"] = 2;
    values[valuesById[3] = "GET_VIEWER_FAILED"] = 3;
    values[valuesById[4] = "GET_VIEWER_NOT_FOUND"] = 4;
    values[valuesById[5] = "GET_VIEWER_UNAUTHORIZED"] = 5;
    values[valuesById[6] = "GET_VIEWER_TERMINATED"] = 6;
    values[valuesById[7] = "GET_VIEWER_EXPIRED"] = 7;
    values[valuesById[8] = "GET_VIEWER_UNINVITED"] = 8;
    return values;
})();

export const Gender = $root.Gender = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "NONE"] = 1;
    values[valuesById[2] = "MALE"] = 2;
    values[valuesById[3] = "FEMALE"] = 3;
    values[valuesById[4] = "BOTH"] = 4;
    return values;
})();

export const ResponseCode = $root.ResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "SUCCESS"] = 1;
    values[valuesById[2] = "BAD_REQUEST"] = 2;
    values[valuesById[3] = "FAILED"] = 3;
    values[valuesById[4] = "EXISTING_LIVE_STREAM"] = 4;
    values[valuesById[5] = "NOT_FOUND"] = 5;
    values[valuesById[6] = "UNAUTHORIZED"] = 6;
    values[valuesById[7] = "UPGRADE_DISABLED"] = 7;
    return values;
})();

export const BcChallengeExperiment = $root.BcChallengeExperiment = (() => {

    function BcChallengeExperiment(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    BcChallengeExperiment.prototype.onboardingExperimentEnabled = false;
    BcChallengeExperiment.prototype.experiencedExperimentEnabled = false;

    BcChallengeExperiment.create = function create(properties) {
        return new BcChallengeExperiment(properties);
    };

    BcChallengeExperiment.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.onboardingExperimentEnabled != null && Object.hasOwnProperty.call(message, "onboardingExperimentEnabled"))
            writer.uint32(8).bool(message.onboardingExperimentEnabled);
        if (message.experiencedExperimentEnabled != null && Object.hasOwnProperty.call(message, "experiencedExperimentEnabled"))
            writer.uint32(16).bool(message.experiencedExperimentEnabled);
        return writer;
    };

    BcChallengeExperiment.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    BcChallengeExperiment.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BcChallengeExperiment();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.onboardingExperimentEnabled = reader.bool();
                    break;
                }
            case 2: {
                    message.experiencedExperimentEnabled = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    BcChallengeExperiment.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    BcChallengeExperiment.fromObject = function fromObject(object) {
        if (object instanceof $root.BcChallengeExperiment)
            return object;
        let message = new $root.BcChallengeExperiment();
        if (object.onboardingExperimentEnabled != null)
            message.onboardingExperimentEnabled = Boolean(object.onboardingExperimentEnabled);
        if (object.experiencedExperimentEnabled != null)
            message.experiencedExperimentEnabled = Boolean(object.experiencedExperimentEnabled);
        return message;
    };

    BcChallengeExperiment.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.onboardingExperimentEnabled = false;
            object.experiencedExperimentEnabled = false;
        }
        if (message.onboardingExperimentEnabled != null && message.hasOwnProperty("onboardingExperimentEnabled"))
            object.onboardingExperimentEnabled = message.onboardingExperimentEnabled;
        if (message.experiencedExperimentEnabled != null && message.hasOwnProperty("experiencedExperimentEnabled"))
            object.experiencedExperimentEnabled = message.experiencedExperimentEnabled;
        return object;
    };

    BcChallengeExperiment.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    BcChallengeExperiment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BcChallengeExperiment";
    };

    return BcChallengeExperiment;
})();

export const AccountType = $root.AccountType = (() => {

    function AccountType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AccountType.prototype.encryptedAccountId = "";
    AccountType.prototype.firstName = "";
    AccountType.prototype.lastName = "";
    AccountType.prototype.profilePictureUrl = "";
    AccountType.prototype.gender = 1;
    AccountType.prototype.profileThumbnailUrl = "";
    AccountType.prototype.vipStatus = 0;
    AccountType.prototype.subscriptionLevel = 0;
    AccountType.prototype.guest = false;

    AccountType.create = function create(properties) {
        return new AccountType(properties);
    };

    AccountType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.encryptedAccountId);
        if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
            writer.uint32(18).string(message.firstName);
        if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
            writer.uint32(26).string(message.lastName);
        if (message.profilePictureUrl != null && Object.hasOwnProperty.call(message, "profilePictureUrl"))
            writer.uint32(34).string(message.profilePictureUrl);
        if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
            writer.uint32(40).int32(message.gender);
        if (message.profileThumbnailUrl != null && Object.hasOwnProperty.call(message, "profileThumbnailUrl"))
            writer.uint32(50).string(message.profileThumbnailUrl);
        if (message.vipStatus != null && Object.hasOwnProperty.call(message, "vipStatus"))
            writer.uint32(61).sfixed32(message.vipStatus);
        if (message.subscriptionLevel != null && Object.hasOwnProperty.call(message, "subscriptionLevel"))
            writer.uint32(69).sfixed32(message.subscriptionLevel);
        if (message.guest != null && Object.hasOwnProperty.call(message, "guest"))
            writer.uint32(72).bool(message.guest);
        return writer;
    };

    AccountType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AccountType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AccountType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.encryptedAccountId = reader.string();
                    break;
                }
            case 2: {
                    message.firstName = reader.string();
                    break;
                }
            case 3: {
                    message.lastName = reader.string();
                    break;
                }
            case 4: {
                    message.profilePictureUrl = reader.string();
                    break;
                }
            case 5: {
                    message.gender = reader.int32();
                    break;
                }
            case 6: {
                    message.profileThumbnailUrl = reader.string();
                    break;
                }
            case 7: {
                    message.vipStatus = reader.sfixed32();
                    break;
                }
            case 8: {
                    message.subscriptionLevel = reader.sfixed32();
                    break;
                }
            case 9: {
                    message.guest = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("encryptedAccountId"))
            throw $util.ProtocolError("missing required 'encryptedAccountId'", { instance: message });
        return message;
    };

    AccountType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AccountType.fromObject = function fromObject(object) {
        if (object instanceof $root.AccountType)
            return object;
        let message = new $root.AccountType();
        if (object.encryptedAccountId != null)
            message.encryptedAccountId = String(object.encryptedAccountId);
        if (object.firstName != null)
            message.firstName = String(object.firstName);
        if (object.lastName != null)
            message.lastName = String(object.lastName);
        if (object.profilePictureUrl != null)
            message.profilePictureUrl = String(object.profilePictureUrl);
        switch (object.gender) {
        default:
            if (typeof object.gender === "number") {
                message.gender = object.gender;
                break;
            }
            break;
        case "NONE":
        case 1:
            message.gender = 1;
            break;
        case "MALE":
        case 2:
            message.gender = 2;
            break;
        case "FEMALE":
        case 3:
            message.gender = 3;
            break;
        case "BOTH":
        case 4:
            message.gender = 4;
            break;
        }
        if (object.profileThumbnailUrl != null)
            message.profileThumbnailUrl = String(object.profileThumbnailUrl);
        if (object.vipStatus != null)
            message.vipStatus = object.vipStatus | 0;
        if (object.subscriptionLevel != null)
            message.subscriptionLevel = object.subscriptionLevel | 0;
        if (object.guest != null)
            message.guest = Boolean(object.guest);
        return message;
    };

    AccountType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.encryptedAccountId = "";
            object.firstName = "";
            object.lastName = "";
            object.profilePictureUrl = "";
            object.gender = options.enums === String ? "NONE" : 1;
            object.profileThumbnailUrl = "";
            object.vipStatus = 0;
            object.subscriptionLevel = 0;
            object.guest = false;
        }
        if (message.encryptedAccountId != null && message.hasOwnProperty("encryptedAccountId"))
            object.encryptedAccountId = message.encryptedAccountId;
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            object.firstName = message.firstName;
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            object.lastName = message.lastName;
        if (message.profilePictureUrl != null && message.hasOwnProperty("profilePictureUrl"))
            object.profilePictureUrl = message.profilePictureUrl;
        if (message.gender != null && message.hasOwnProperty("gender"))
            object.gender = options.enums === String ? $root.Gender[message.gender] === undefined ? message.gender : $root.Gender[message.gender] : message.gender;
        if (message.profileThumbnailUrl != null && message.hasOwnProperty("profileThumbnailUrl"))
            object.profileThumbnailUrl = message.profileThumbnailUrl;
        if (message.vipStatus != null && message.hasOwnProperty("vipStatus"))
            object.vipStatus = message.vipStatus;
        if (message.subscriptionLevel != null && message.hasOwnProperty("subscriptionLevel"))
            object.subscriptionLevel = message.subscriptionLevel;
        if (message.guest != null && message.hasOwnProperty("guest"))
            object.guest = message.guest;
        return object;
    };

    AccountType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AccountType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AccountType";
    };

    return AccountType;
})();

export const EventType = $root.EventType = (() => {

    function EventType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    EventType.prototype.id = "";
    EventType.prototype.encryptAccountId = "";
    EventType.prototype.account = null;
    EventType.prototype.type = "";
    EventType.prototype.data = "";
    EventType.prototype.clientEventId = "";
    EventType.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    EventType.create = function create(properties) {
        return new EventType(properties);
    };

    EventType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.encryptAccountId);
        $root.AccountType.encode(message.account, writer.uint32(26).fork()).ldelim();
        writer.uint32(34).string(message.type);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(42).string(message.data);
        if (message.clientEventId != null && Object.hasOwnProperty.call(message, "clientEventId"))
            writer.uint32(50).string(message.clientEventId);
        writer.uint32(57).sfixed64(message.timestamp);
        return writer;
    };

    EventType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    EventType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.EventType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.encryptAccountId = reader.string();
                    break;
                }
            case 3: {
                    message.account = $root.AccountType.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.type = reader.string();
                    break;
                }
            case 5: {
                    message.data = reader.string();
                    break;
                }
            case 6: {
                    message.clientEventId = reader.string();
                    break;
                }
            case 7: {
                    message.timestamp = reader.sfixed64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("encryptAccountId"))
            throw $util.ProtocolError("missing required 'encryptAccountId'", { instance: message });
        if (!message.hasOwnProperty("account"))
            throw $util.ProtocolError("missing required 'account'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("timestamp"))
            throw $util.ProtocolError("missing required 'timestamp'", { instance: message });
        return message;
    };

    EventType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    EventType.fromObject = function fromObject(object) {
        if (object instanceof $root.EventType)
            return object;
        let message = new $root.EventType();
        if (object.id != null)
            message.id = String(object.id);
        if (object.encryptAccountId != null)
            message.encryptAccountId = String(object.encryptAccountId);
        if (object.account != null) {
            if (typeof object.account !== "object")
                throw TypeError(".EventType.account: object expected");
            message.account = $root.AccountType.fromObject(object.account);
        }
        if (object.type != null)
            message.type = String(object.type);
        if (object.data != null)
            message.data = String(object.data);
        if (object.clientEventId != null)
            message.clientEventId = String(object.clientEventId);
        if (object.timestamp != null)
            if ($util.Long)
                (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
            else if (typeof object.timestamp === "string")
                message.timestamp = parseInt(object.timestamp, 10);
            else if (typeof object.timestamp === "number")
                message.timestamp = object.timestamp;
            else if (typeof object.timestamp === "object")
                message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
        return message;
    };

    EventType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.encryptAccountId = "";
            object.account = null;
            object.type = "";
            object.data = "";
            object.clientEventId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.timestamp = options.longs === String ? "0" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.encryptAccountId != null && message.hasOwnProperty("encryptAccountId"))
            object.encryptAccountId = message.encryptAccountId;
        if (message.account != null && message.hasOwnProperty("account"))
            object.account = $root.AccountType.toObject(message.account, options);
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = message.data;
        if (message.clientEventId != null && message.hasOwnProperty("clientEventId"))
            object.clientEventId = message.clientEventId;
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (typeof message.timestamp === "number")
                object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
            else
                object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
        return object;
    };

    EventType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    EventType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/EventType";
    };

    return EventType;
})();

export const GetStreamEventResponse = $root.GetStreamEventResponse = (() => {

    function GetStreamEventResponse(properties) {
        this.event = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    GetStreamEventResponse.prototype.code = 1;
    GetStreamEventResponse.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    GetStreamEventResponse.prototype.event = $util.emptyArray;
    GetStreamEventResponse.prototype.lastEventId = "";

    GetStreamEventResponse.create = function create(properties) {
        return new GetStreamEventResponse(properties);
    };

    GetStreamEventResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
            writer.uint32(17).sfixed64(message.timestamp);
        if (message.event != null && message.event.length)
            for (let i = 0; i < message.event.length; ++i)
                $root.EventType.encode(message.event[i], writer.uint32(26).fork()).ldelim();
        if (message.lastEventId != null && Object.hasOwnProperty.call(message, "lastEventId"))
            writer.uint32(34).string(message.lastEventId);
        return writer;
    };

    GetStreamEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    GetStreamEventResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetStreamEventResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.timestamp = reader.sfixed64();
                    break;
                }
            case 3: {
                    if (!(message.event && message.event.length))
                        message.event = [];
                    message.event.push($root.EventType.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.lastEventId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    GetStreamEventResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    GetStreamEventResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetStreamEventResponse)
            return object;
        let message = new $root.GetStreamEventResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "GET_VIEWER_SUCCESS":
        case 1:
            message.code = 1;
            break;
        case "GET_VIEWER_BAD_REQUEST":
        case 2:
            message.code = 2;
            break;
        case "GET_VIEWER_FAILED":
        case 3:
            message.code = 3;
            break;
        case "GET_VIEWER_NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "GET_VIEWER_UNAUTHORIZED":
        case 5:
            message.code = 5;
            break;
        case "GET_VIEWER_TERMINATED":
        case 6:
            message.code = 6;
            break;
        case "GET_VIEWER_EXPIRED":
        case 7:
            message.code = 7;
            break;
        case "GET_VIEWER_UNINVITED":
        case 8:
            message.code = 8;
            break;
        }
        if (object.timestamp != null)
            if ($util.Long)
                (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
            else if (typeof object.timestamp === "string")
                message.timestamp = parseInt(object.timestamp, 10);
            else if (typeof object.timestamp === "number")
                message.timestamp = object.timestamp;
            else if (typeof object.timestamp === "object")
                message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
        if (object.event) {
            if (!Array.isArray(object.event))
                throw TypeError(".GetStreamEventResponse.event: array expected");
            message.event = [];
            for (let i = 0; i < object.event.length; ++i) {
                if (typeof object.event[i] !== "object")
                    throw TypeError(".GetStreamEventResponse.event: object expected");
                message.event[i] = $root.EventType.fromObject(object.event[i]);
            }
        }
        if (object.lastEventId != null)
            message.lastEventId = String(object.lastEventId);
        return message;
    };

    GetStreamEventResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.event = [];
        if (options.defaults) {
            object.code = options.enums === String ? "GET_VIEWER_SUCCESS" : 1;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.timestamp = options.longs === String ? "0" : 0;
            object.lastEventId = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.GetViewerResponseCode[message.code] === undefined ? message.code : $root.GetViewerResponseCode[message.code] : message.code;
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (typeof message.timestamp === "number")
                object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
            else
                object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
        if (message.event && message.event.length) {
            object.event = [];
            for (let j = 0; j < message.event.length; ++j)
                object.event[j] = $root.EventType.toObject(message.event[j], options);
        }
        if (message.lastEventId != null && message.hasOwnProperty("lastEventId"))
            object.lastEventId = message.lastEventId;
        return object;
    };

    GetStreamEventResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    GetStreamEventResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetStreamEventResponse";
    };

    return GetStreamEventResponse;
})();

export const Abonnement = $root.Abonnement = (() => {

    function Abonnement(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Abonnement.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    Abonnement.create = function create(properties) {
        return new Abonnement(properties);
    };

    Abonnement.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
            writer.uint32(9).sfixed64(message.amount);
        return writer;
    };

    Abonnement.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Abonnement.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Abonnement();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.amount = reader.sfixed64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Abonnement.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Abonnement.fromObject = function fromObject(object) {
        if (object instanceof $root.Abonnement)
            return object;
        let message = new $root.Abonnement();
        if (object.amount != null)
            if ($util.Long)
                (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
            else if (typeof object.amount === "string")
                message.amount = parseInt(object.amount, 10);
            else if (typeof object.amount === "number")
                message.amount = object.amount;
            else if (typeof object.amount === "object")
                message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
        return message;
    };

    Abonnement.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.amount = options.longs === String ? "0" : 0;
        if (message.amount != null && message.hasOwnProperty("amount"))
            if (typeof message.amount === "number")
                object.amount = options.longs === String ? String(message.amount) : message.amount;
            else
                object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
        return object;
    };

    Abonnement.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Abonnement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Abonnement";
    };

    return Abonnement;
})();

export const StreamAccessInitRestriction = $root.StreamAccessInitRestriction = (() => {

    function StreamAccessInitRestriction(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamAccessInitRestriction.prototype.gift = null;
    StreamAccessInitRestriction.prototype.subscription = null;

    StreamAccessInitRestriction.create = function create(properties) {
        return new StreamAccessInitRestriction(properties);
    };

    StreamAccessInitRestriction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.StreamGiftFee.encode(message.gift, writer.uint32(10).fork()).ldelim();
        $root.StreamSubscriptionFee.encode(message.subscription, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    StreamAccessInitRestriction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamAccessInitRestriction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamAccessInitRestriction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.gift = $root.StreamGiftFee.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.subscription = $root.StreamSubscriptionFee.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("gift"))
            throw $util.ProtocolError("missing required 'gift'", { instance: message });
        if (!message.hasOwnProperty("subscription"))
            throw $util.ProtocolError("missing required 'subscription'", { instance: message });
        return message;
    };

    StreamAccessInitRestriction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamAccessInitRestriction.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamAccessInitRestriction)
            return object;
        let message = new $root.StreamAccessInitRestriction();
        if (object.gift != null) {
            if (typeof object.gift !== "object")
                throw TypeError(".StreamAccessInitRestriction.gift: object expected");
            message.gift = $root.StreamGiftFee.fromObject(object.gift);
        }
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".StreamAccessInitRestriction.subscription: object expected");
            message.subscription = $root.StreamSubscriptionFee.fromObject(object.subscription);
        }
        return message;
    };

    StreamAccessInitRestriction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.gift = null;
            object.subscription = null;
        }
        if (message.gift != null && message.hasOwnProperty("gift"))
            object.gift = $root.StreamGiftFee.toObject(message.gift, options);
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.StreamSubscriptionFee.toObject(message.subscription, options);
        return object;
    };

    StreamAccessInitRestriction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamAccessInitRestriction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamAccessInitRestriction";
    };

    return StreamAccessInitRestriction;
})();

export const StreamGiftFee = $root.StreamGiftFee = (() => {

    function StreamGiftFee(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamGiftFee.prototype.ticketPriceInCredits = 0;
    StreamGiftFee.prototype.abonnement = null;

    StreamGiftFee.create = function create(properties) {
        return new StreamGiftFee(properties);
    };

    StreamGiftFee.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.ticketPriceInCredits);
        if (message.abonnement != null && Object.hasOwnProperty.call(message, "abonnement"))
            $root.Abonnement.encode(message.abonnement, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    StreamGiftFee.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamGiftFee.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamGiftFee();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ticketPriceInCredits = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.abonnement = $root.Abonnement.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("ticketPriceInCredits"))
            throw $util.ProtocolError("missing required 'ticketPriceInCredits'", { instance: message });
        return message;
    };

    StreamGiftFee.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamGiftFee.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamGiftFee)
            return object;
        let message = new $root.StreamGiftFee();
        if (object.ticketPriceInCredits != null)
            message.ticketPriceInCredits = object.ticketPriceInCredits | 0;
        if (object.abonnement != null) {
            if (typeof object.abonnement !== "object")
                throw TypeError(".StreamGiftFee.abonnement: object expected");
            message.abonnement = $root.Abonnement.fromObject(object.abonnement);
        }
        return message;
    };

    StreamGiftFee.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.ticketPriceInCredits = 0;
            object.abonnement = null;
        }
        if (message.ticketPriceInCredits != null && message.hasOwnProperty("ticketPriceInCredits"))
            object.ticketPriceInCredits = message.ticketPriceInCredits;
        if (message.abonnement != null && message.hasOwnProperty("abonnement"))
            object.abonnement = $root.Abonnement.toObject(message.abonnement, options);
        return object;
    };

    StreamGiftFee.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamGiftFee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamGiftFee";
    };

    return StreamGiftFee;
})();

export const StreamSubscriptionFee = $root.StreamSubscriptionFee = (() => {

    function StreamSubscriptionFee(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamSubscriptionFee.prototype.level = 0;

    StreamSubscriptionFee.create = function create(properties) {
        return new StreamSubscriptionFee(properties);
    };

    StreamSubscriptionFee.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.level);
        return writer;
    };

    StreamSubscriptionFee.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamSubscriptionFee.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamSubscriptionFee();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.level = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("level"))
            throw $util.ProtocolError("missing required 'level'", { instance: message });
        return message;
    };

    StreamSubscriptionFee.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamSubscriptionFee.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamSubscriptionFee)
            return object;
        let message = new $root.StreamSubscriptionFee();
        if (object.level != null)
            message.level = object.level | 0;
        return message;
    };

    StreamSubscriptionFee.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.level = 0;
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        return object;
    };

    StreamSubscriptionFee.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamSubscriptionFee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamSubscriptionFee";
    };

    return StreamSubscriptionFee;
})();

export const StreamInitRestriction = $root.StreamInitRestriction = (() => {

    function StreamInitRestriction(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamInitRestriction.prototype.or = null;
    StreamInitRestriction.prototype.and = null;
    StreamInitRestriction.prototype.gift = null;
    StreamInitRestriction.prototype.subscription = null;

    StreamInitRestriction.create = function create(properties) {
        return new StreamInitRestriction(properties);
    };

    StreamInitRestriction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.or != null && Object.hasOwnProperty.call(message, "or"))
            $root.StreamAccessInitRestriction.encode(message.or, writer.uint32(10).fork()).ldelim();
        if (message.and != null && Object.hasOwnProperty.call(message, "and"))
            $root.StreamAccessInitRestriction.encode(message.and, writer.uint32(18).fork()).ldelim();
        if (message.gift != null && Object.hasOwnProperty.call(message, "gift"))
            $root.StreamGiftFee.encode(message.gift, writer.uint32(26).fork()).ldelim();
        if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
            $root.StreamSubscriptionFee.encode(message.subscription, writer.uint32(34).fork()).ldelim();
        return writer;
    };

    StreamInitRestriction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamInitRestriction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamInitRestriction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.or = $root.StreamAccessInitRestriction.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.and = $root.StreamAccessInitRestriction.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.gift = $root.StreamGiftFee.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.subscription = $root.StreamSubscriptionFee.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamInitRestriction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamInitRestriction.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamInitRestriction)
            return object;
        let message = new $root.StreamInitRestriction();
        if (object.or != null) {
            if (typeof object.or !== "object")
                throw TypeError(".StreamInitRestriction.or: object expected");
            message.or = $root.StreamAccessInitRestriction.fromObject(object.or);
        }
        if (object.and != null) {
            if (typeof object.and !== "object")
                throw TypeError(".StreamInitRestriction.and: object expected");
            message.and = $root.StreamAccessInitRestriction.fromObject(object.and);
        }
        if (object.gift != null) {
            if (typeof object.gift !== "object")
                throw TypeError(".StreamInitRestriction.gift: object expected");
            message.gift = $root.StreamGiftFee.fromObject(object.gift);
        }
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".StreamInitRestriction.subscription: object expected");
            message.subscription = $root.StreamSubscriptionFee.fromObject(object.subscription);
        }
        return message;
    };

    StreamInitRestriction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.or = null;
            object.and = null;
            object.gift = null;
            object.subscription = null;
        }
        if (message.or != null && message.hasOwnProperty("or"))
            object.or = $root.StreamAccessInitRestriction.toObject(message.or, options);
        if (message.and != null && message.hasOwnProperty("and"))
            object.and = $root.StreamAccessInitRestriction.toObject(message.and, options);
        if (message.gift != null && message.hasOwnProperty("gift"))
            object.gift = $root.StreamGiftFee.toObject(message.gift, options);
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.StreamSubscriptionFee.toObject(message.subscription, options);
        return object;
    };

    StreamInitRestriction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamInitRestriction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamInitRestriction";
    };

    return StreamInitRestriction;
})();

export const StreamLPInvitationSettings = $root.StreamLPInvitationSettings = (() => {

    function StreamLPInvitationSettings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamLPInvitationSettings.prototype.invitableBy = 1;

    StreamLPInvitationSettings.create = function create(properties) {
        return new StreamLPInvitationSettings(properties);
    };

    StreamLPInvitationSettings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.invitableBy != null && Object.hasOwnProperty.call(message, "invitableBy"))
            writer.uint32(8).int32(message.invitableBy);
        return writer;
    };

    StreamLPInvitationSettings.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamLPInvitationSettings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamLPInvitationSettings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.invitableBy = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamLPInvitationSettings.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamLPInvitationSettings.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamLPInvitationSettings)
            return object;
        let message = new $root.StreamLPInvitationSettings();
        switch (object.invitableBy) {
        default:
            if (typeof object.invitableBy === "number") {
                message.invitableBy = object.invitableBy;
                break;
            }
            break;
        case "NO_ONE":
        case 1:
            message.invitableBy = 1;
            break;
        case "FRIENDS_ONLY":
        case 2:
            message.invitableBy = 2;
            break;
        case "ANYONE":
        case 3:
            message.invitableBy = 3;
            break;
        }
        return message;
    };

    StreamLPInvitationSettings.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.invitableBy = options.enums === String ? "NO_ONE" : 1;
        if (message.invitableBy != null && message.hasOwnProperty("invitableBy"))
            object.invitableBy = options.enums === String ? $root.LPInviter[message.invitableBy] === undefined ? message.invitableBy : $root.LPInviter[message.invitableBy] : message.invitableBy;
        return object;
    };

    StreamLPInvitationSettings.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamLPInvitationSettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamLPInvitationSettings";
    };

    return StreamLPInvitationSettings;
})();

export const StreamSettings = $root.StreamSettings = (() => {

    function StreamSettings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamSettings.prototype.videoEnabled = false;
    StreamSettings.prototype.audioEnabled = false;
    StreamSettings.prototype.incognitoEnabled = false;
    StreamSettings.prototype.muteGiftSound = false;
    StreamSettings.prototype.liveChatForFollowersOnly = false;
    StreamSettings.prototype.audioRoom = false;

    StreamSettings.create = function create(properties) {
        return new StreamSettings(properties);
    };

    StreamSettings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).bool(message.videoEnabled);
        writer.uint32(16).bool(message.audioEnabled);
        if (message.incognitoEnabled != null && Object.hasOwnProperty.call(message, "incognitoEnabled"))
            writer.uint32(24).bool(message.incognitoEnabled);
        if (message.muteGiftSound != null && Object.hasOwnProperty.call(message, "muteGiftSound"))
            writer.uint32(32).bool(message.muteGiftSound);
        if (message.liveChatForFollowersOnly != null && Object.hasOwnProperty.call(message, "liveChatForFollowersOnly"))
            writer.uint32(40).bool(message.liveChatForFollowersOnly);
        if (message.audioRoom != null && Object.hasOwnProperty.call(message, "audioRoom"))
            writer.uint32(48).bool(message.audioRoom);
        return writer;
    };

    StreamSettings.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamSettings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamSettings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.videoEnabled = reader.bool();
                    break;
                }
            case 2: {
                    message.audioEnabled = reader.bool();
                    break;
                }
            case 3: {
                    message.incognitoEnabled = reader.bool();
                    break;
                }
            case 4: {
                    message.muteGiftSound = reader.bool();
                    break;
                }
            case 5: {
                    message.liveChatForFollowersOnly = reader.bool();
                    break;
                }
            case 6: {
                    message.audioRoom = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("videoEnabled"))
            throw $util.ProtocolError("missing required 'videoEnabled'", { instance: message });
        if (!message.hasOwnProperty("audioEnabled"))
            throw $util.ProtocolError("missing required 'audioEnabled'", { instance: message });
        return message;
    };

    StreamSettings.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamSettings.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamSettings)
            return object;
        let message = new $root.StreamSettings();
        if (object.videoEnabled != null)
            message.videoEnabled = Boolean(object.videoEnabled);
        if (object.audioEnabled != null)
            message.audioEnabled = Boolean(object.audioEnabled);
        if (object.incognitoEnabled != null)
            message.incognitoEnabled = Boolean(object.incognitoEnabled);
        if (object.muteGiftSound != null)
            message.muteGiftSound = Boolean(object.muteGiftSound);
        if (object.liveChatForFollowersOnly != null)
            message.liveChatForFollowersOnly = Boolean(object.liveChatForFollowersOnly);
        if (object.audioRoom != null)
            message.audioRoom = Boolean(object.audioRoom);
        return message;
    };

    StreamSettings.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.videoEnabled = false;
            object.audioEnabled = false;
            object.incognitoEnabled = false;
            object.muteGiftSound = false;
            object.liveChatForFollowersOnly = false;
            object.audioRoom = false;
        }
        if (message.videoEnabled != null && message.hasOwnProperty("videoEnabled"))
            object.videoEnabled = message.videoEnabled;
        if (message.audioEnabled != null && message.hasOwnProperty("audioEnabled"))
            object.audioEnabled = message.audioEnabled;
        if (message.incognitoEnabled != null && message.hasOwnProperty("incognitoEnabled"))
            object.incognitoEnabled = message.incognitoEnabled;
        if (message.muteGiftSound != null && message.hasOwnProperty("muteGiftSound"))
            object.muteGiftSound = message.muteGiftSound;
        if (message.liveChatForFollowersOnly != null && message.hasOwnProperty("liveChatForFollowersOnly"))
            object.liveChatForFollowersOnly = message.liveChatForFollowersOnly;
        if (message.audioRoom != null && message.hasOwnProperty("audioRoom"))
            object.audioRoom = message.audioRoom;
        return object;
    };

    StreamSettings.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamSettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamSettings";
    };

    return StreamSettings;
})();

export const BlpsStreamerInfo = $root.BlpsStreamerInfo = (() => {

    function BlpsStreamerInfo(properties) {
        this.bonusLevels = [];
        this.bonuses = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    BlpsStreamerInfo.prototype.previousTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    BlpsStreamerInfo.prototype.previousDiamonds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    BlpsStreamerInfo.prototype.currentBonusLevel = 0;
    BlpsStreamerInfo.prototype.lastBonusPeriodDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    BlpsStreamerInfo.prototype.bonusLevels = $util.emptyArray;
    BlpsStreamerInfo.prototype.bonuses = $util.emptyArray;

    BlpsStreamerInfo.create = function create(properties) {
        return new BlpsStreamerInfo(properties);
    };

    BlpsStreamerInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(9).sfixed64(message.previousTime);
        writer.uint32(17).sfixed64(message.previousDiamonds);
        writer.uint32(29).sfixed32(message.currentBonusLevel);
        writer.uint32(33).sfixed64(message.lastBonusPeriodDuration);
        if (message.bonusLevels != null && message.bonusLevels.length)
            for (let i = 0; i < message.bonusLevels.length; ++i)
                $root.BonusType.encode(message.bonusLevels[i], writer.uint32(42).fork()).ldelim();
        if (message.bonuses != null && message.bonuses.length)
            for (let i = 0; i < message.bonuses.length; ++i)
                $root.Bonus.encode(message.bonuses[i], writer.uint32(50).fork()).ldelim();
        return writer;
    };

    BlpsStreamerInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    BlpsStreamerInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BlpsStreamerInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.previousTime = reader.sfixed64();
                    break;
                }
            case 2: {
                    message.previousDiamonds = reader.sfixed64();
                    break;
                }
            case 3: {
                    message.currentBonusLevel = reader.sfixed32();
                    break;
                }
            case 4: {
                    message.lastBonusPeriodDuration = reader.sfixed64();
                    break;
                }
            case 5: {
                    if (!(message.bonusLevels && message.bonusLevels.length))
                        message.bonusLevels = [];
                    message.bonusLevels.push($root.BonusType.decode(reader, reader.uint32()));
                    break;
                }
            case 6: {
                    if (!(message.bonuses && message.bonuses.length))
                        message.bonuses = [];
                    message.bonuses.push($root.Bonus.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("previousTime"))
            throw $util.ProtocolError("missing required 'previousTime'", { instance: message });
        if (!message.hasOwnProperty("previousDiamonds"))
            throw $util.ProtocolError("missing required 'previousDiamonds'", { instance: message });
        if (!message.hasOwnProperty("currentBonusLevel"))
            throw $util.ProtocolError("missing required 'currentBonusLevel'", { instance: message });
        if (!message.hasOwnProperty("lastBonusPeriodDuration"))
            throw $util.ProtocolError("missing required 'lastBonusPeriodDuration'", { instance: message });
        return message;
    };

    BlpsStreamerInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    BlpsStreamerInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.BlpsStreamerInfo)
            return object;
        let message = new $root.BlpsStreamerInfo();
        if (object.previousTime != null)
            if ($util.Long)
                (message.previousTime = $util.Long.fromValue(object.previousTime)).unsigned = false;
            else if (typeof object.previousTime === "string")
                message.previousTime = parseInt(object.previousTime, 10);
            else if (typeof object.previousTime === "number")
                message.previousTime = object.previousTime;
            else if (typeof object.previousTime === "object")
                message.previousTime = new $util.LongBits(object.previousTime.low >>> 0, object.previousTime.high >>> 0).toNumber();
        if (object.previousDiamonds != null)
            if ($util.Long)
                (message.previousDiamonds = $util.Long.fromValue(object.previousDiamonds)).unsigned = false;
            else if (typeof object.previousDiamonds === "string")
                message.previousDiamonds = parseInt(object.previousDiamonds, 10);
            else if (typeof object.previousDiamonds === "number")
                message.previousDiamonds = object.previousDiamonds;
            else if (typeof object.previousDiamonds === "object")
                message.previousDiamonds = new $util.LongBits(object.previousDiamonds.low >>> 0, object.previousDiamonds.high >>> 0).toNumber();
        if (object.currentBonusLevel != null)
            message.currentBonusLevel = object.currentBonusLevel | 0;
        if (object.lastBonusPeriodDuration != null)
            if ($util.Long)
                (message.lastBonusPeriodDuration = $util.Long.fromValue(object.lastBonusPeriodDuration)).unsigned = false;
            else if (typeof object.lastBonusPeriodDuration === "string")
                message.lastBonusPeriodDuration = parseInt(object.lastBonusPeriodDuration, 10);
            else if (typeof object.lastBonusPeriodDuration === "number")
                message.lastBonusPeriodDuration = object.lastBonusPeriodDuration;
            else if (typeof object.lastBonusPeriodDuration === "object")
                message.lastBonusPeriodDuration = new $util.LongBits(object.lastBonusPeriodDuration.low >>> 0, object.lastBonusPeriodDuration.high >>> 0).toNumber();
        if (object.bonusLevels) {
            if (!Array.isArray(object.bonusLevels))
                throw TypeError(".BlpsStreamerInfo.bonusLevels: array expected");
            message.bonusLevels = [];
            for (let i = 0; i < object.bonusLevels.length; ++i) {
                if (typeof object.bonusLevels[i] !== "object")
                    throw TypeError(".BlpsStreamerInfo.bonusLevels: object expected");
                message.bonusLevels[i] = $root.BonusType.fromObject(object.bonusLevels[i]);
            }
        }
        if (object.bonuses) {
            if (!Array.isArray(object.bonuses))
                throw TypeError(".BlpsStreamerInfo.bonuses: array expected");
            message.bonuses = [];
            for (let i = 0; i < object.bonuses.length; ++i) {
                if (typeof object.bonuses[i] !== "object")
                    throw TypeError(".BlpsStreamerInfo.bonuses: object expected");
                message.bonuses[i] = $root.Bonus.fromObject(object.bonuses[i]);
            }
        }
        return message;
    };

    BlpsStreamerInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.bonusLevels = [];
            object.bonuses = [];
        }
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.previousTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.previousTime = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.previousDiamonds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.previousDiamonds = options.longs === String ? "0" : 0;
            object.currentBonusLevel = 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.lastBonusPeriodDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastBonusPeriodDuration = options.longs === String ? "0" : 0;
        }
        if (message.previousTime != null && message.hasOwnProperty("previousTime"))
            if (typeof message.previousTime === "number")
                object.previousTime = options.longs === String ? String(message.previousTime) : message.previousTime;
            else
                object.previousTime = options.longs === String ? $util.Long.prototype.toString.call(message.previousTime) : options.longs === Number ? new $util.LongBits(message.previousTime.low >>> 0, message.previousTime.high >>> 0).toNumber() : message.previousTime;
        if (message.previousDiamonds != null && message.hasOwnProperty("previousDiamonds"))
            if (typeof message.previousDiamonds === "number")
                object.previousDiamonds = options.longs === String ? String(message.previousDiamonds) : message.previousDiamonds;
            else
                object.previousDiamonds = options.longs === String ? $util.Long.prototype.toString.call(message.previousDiamonds) : options.longs === Number ? new $util.LongBits(message.previousDiamonds.low >>> 0, message.previousDiamonds.high >>> 0).toNumber() : message.previousDiamonds;
        if (message.currentBonusLevel != null && message.hasOwnProperty("currentBonusLevel"))
            object.currentBonusLevel = message.currentBonusLevel;
        if (message.lastBonusPeriodDuration != null && message.hasOwnProperty("lastBonusPeriodDuration"))
            if (typeof message.lastBonusPeriodDuration === "number")
                object.lastBonusPeriodDuration = options.longs === String ? String(message.lastBonusPeriodDuration) : message.lastBonusPeriodDuration;
            else
                object.lastBonusPeriodDuration = options.longs === String ? $util.Long.prototype.toString.call(message.lastBonusPeriodDuration) : options.longs === Number ? new $util.LongBits(message.lastBonusPeriodDuration.low >>> 0, message.lastBonusPeriodDuration.high >>> 0).toNumber() : message.lastBonusPeriodDuration;
        if (message.bonusLevels && message.bonusLevels.length) {
            object.bonusLevels = [];
            for (let j = 0; j < message.bonusLevels.length; ++j)
                object.bonusLevels[j] = $root.BonusType.toObject(message.bonusLevels[j], options);
        }
        if (message.bonuses && message.bonuses.length) {
            object.bonuses = [];
            for (let j = 0; j < message.bonuses.length; ++j)
                object.bonuses[j] = $root.Bonus.toObject(message.bonuses[j], options);
        }
        return object;
    };

    BlpsStreamerInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    BlpsStreamerInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BlpsStreamerInfo";
    };

    return BlpsStreamerInfo;
})();

export const BonusType = $root.BonusType = (() => {

    function BonusType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    BonusType.prototype.level = 0;
    BonusType.prototype.percent = 0;
    BonusType.prototype.diamondsThreshold = 0;
    BonusType.prototype.timeThreshold = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    BonusType.create = function create(properties) {
        return new BonusType(properties);
    };

    BonusType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.level);
        writer.uint32(21).sfixed32(message.percent);
        writer.uint32(25).double(message.diamondsThreshold);
        writer.uint32(33).sfixed64(message.timeThreshold);
        return writer;
    };

    BonusType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    BonusType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BonusType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.level = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.percent = reader.sfixed32();
                    break;
                }
            case 3: {
                    message.diamondsThreshold = reader.double();
                    break;
                }
            case 4: {
                    message.timeThreshold = reader.sfixed64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("level"))
            throw $util.ProtocolError("missing required 'level'", { instance: message });
        if (!message.hasOwnProperty("percent"))
            throw $util.ProtocolError("missing required 'percent'", { instance: message });
        if (!message.hasOwnProperty("diamondsThreshold"))
            throw $util.ProtocolError("missing required 'diamondsThreshold'", { instance: message });
        if (!message.hasOwnProperty("timeThreshold"))
            throw $util.ProtocolError("missing required 'timeThreshold'", { instance: message });
        return message;
    };

    BonusType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    BonusType.fromObject = function fromObject(object) {
        if (object instanceof $root.BonusType)
            return object;
        let message = new $root.BonusType();
        if (object.level != null)
            message.level = object.level | 0;
        if (object.percent != null)
            message.percent = object.percent | 0;
        if (object.diamondsThreshold != null)
            message.diamondsThreshold = Number(object.diamondsThreshold);
        if (object.timeThreshold != null)
            if ($util.Long)
                (message.timeThreshold = $util.Long.fromValue(object.timeThreshold)).unsigned = false;
            else if (typeof object.timeThreshold === "string")
                message.timeThreshold = parseInt(object.timeThreshold, 10);
            else if (typeof object.timeThreshold === "number")
                message.timeThreshold = object.timeThreshold;
            else if (typeof object.timeThreshold === "object")
                message.timeThreshold = new $util.LongBits(object.timeThreshold.low >>> 0, object.timeThreshold.high >>> 0).toNumber();
        return message;
    };

    BonusType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.level = 0;
            object.percent = 0;
            object.diamondsThreshold = 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.timeThreshold = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.timeThreshold = options.longs === String ? "0" : 0;
        }
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        if (message.percent != null && message.hasOwnProperty("percent"))
            object.percent = message.percent;
        if (message.diamondsThreshold != null && message.hasOwnProperty("diamondsThreshold"))
            object.diamondsThreshold = options.json && !isFinite(message.diamondsThreshold) ? String(message.diamondsThreshold) : message.diamondsThreshold;
        if (message.timeThreshold != null && message.hasOwnProperty("timeThreshold"))
            if (typeof message.timeThreshold === "number")
                object.timeThreshold = options.longs === String ? String(message.timeThreshold) : message.timeThreshold;
            else
                object.timeThreshold = options.longs === String ? $util.Long.prototype.toString.call(message.timeThreshold) : options.longs === Number ? new $util.LongBits(message.timeThreshold.low >>> 0, message.timeThreshold.high >>> 0).toNumber() : message.timeThreshold;
        return object;
    };

    BonusType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    BonusType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BonusType";
    };

    return BonusType;
})();

export const Bonus = $root.Bonus = (() => {

    function Bonus(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Bonus.prototype.type = 0;
    Bonus.prototype.percent = 0;

    Bonus.create = function create(properties) {
        return new Bonus(properties);
    };

    Bonus.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(8).int32(message.type);
        if (message.percent != null && Object.hasOwnProperty.call(message, "percent"))
            writer.uint32(21).sfixed32(message.percent);
        return writer;
    };

    Bonus.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Bonus.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Bonus();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 2: {
                    message.percent = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Bonus.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Bonus.fromObject = function fromObject(object) {
        if (object instanceof $root.Bonus)
            return object;
        let message = new $root.Bonus();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.type = 0;
            break;
        case "YOUTUBE":
        case 1:
            message.type = 1;
            break;
        }
        if (object.percent != null)
            message.percent = object.percent | 0;
        return message;
    };

    Bonus.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "UNKNOWN" : 0;
            object.percent = 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.Bonus.Type[message.type] === undefined ? message.type : $root.Bonus.Type[message.type] : message.type;
        if (message.percent != null && message.hasOwnProperty("percent"))
            object.percent = message.percent;
        return object;
    };

    Bonus.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Bonus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Bonus";
    };

    Bonus.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "YOUTUBE"] = 1;
        return values;
    })();

    return Bonus;
})();

export const InitStreamRequest = $root.InitStreamRequest = (() => {

    function InitStreamRequest(properties) {
        this.recipientAccountIds = [];
        this.hashtags = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    InitStreamRequest.prototype.type = 1;
    InitStreamRequest.prototype.recipientAccountId = "";
    InitStreamRequest.prototype.recipientGroupId = "";
    InitStreamRequest.prototype.title = "";
    InitStreamRequest.prototype.forceStart = false;
    InitStreamRequest.prototype.thumbnail = "";
    InitStreamRequest.prototype.thumbnailWidth = 0;
    InitStreamRequest.prototype.thumbnailHeight = 0;
    InitStreamRequest.prototype.source = "";
    InitStreamRequest.prototype.ticketPriceInCredits = 0;
    InitStreamRequest.prototype.recipientAccountIds = $util.emptyArray;
    InitStreamRequest.prototype.saveStoryOnServer = false;
    InitStreamRequest.prototype.streamProtocol = 1;
    InitStreamRequest.prototype.region = "";
    InitStreamRequest.prototype.dontInviteToLiveParty = false;
    InitStreamRequest.prototype.restrictions = null;
    InitStreamRequest.prototype.settings = null;
    InitStreamRequest.prototype.hashtags = $util.emptyArray;
    InitStreamRequest.prototype.landscape = false;
    InitStreamRequest.prototype.transcodingRequested = false;
    InitStreamRequest.prototype.lpInvitableBy = 1;
    InitStreamRequest.prototype.webRtcSupport = false;
    InitStreamRequest.prototype.isBcChallengeEnabledForUser = false;
    InitStreamRequest.prototype.multiBattleSupport = false;
    InitStreamRequest.prototype.bcChallengeExperiment = null;
    InitStreamRequest.prototype.birthdayStream = false;

    InitStreamRequest.create = function create(properties) {
        return new InitStreamRequest(properties);
    };

    InitStreamRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(8).int32(message.type);
        if (message.recipientAccountId != null && Object.hasOwnProperty.call(message, "recipientAccountId"))
            writer.uint32(18).string(message.recipientAccountId);
        if (message.recipientGroupId != null && Object.hasOwnProperty.call(message, "recipientGroupId"))
            writer.uint32(26).string(message.recipientGroupId);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(34).string(message.title);
        if (message.forceStart != null && Object.hasOwnProperty.call(message, "forceStart"))
            writer.uint32(40).bool(message.forceStart);
        if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
            writer.uint32(50).string(message.thumbnail);
        if (message.thumbnailWidth != null && Object.hasOwnProperty.call(message, "thumbnailWidth"))
            writer.uint32(61).sfixed32(message.thumbnailWidth);
        if (message.thumbnailHeight != null && Object.hasOwnProperty.call(message, "thumbnailHeight"))
            writer.uint32(69).sfixed32(message.thumbnailHeight);
        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
            writer.uint32(74).string(message.source);
        if (message.ticketPriceInCredits != null && Object.hasOwnProperty.call(message, "ticketPriceInCredits"))
            writer.uint32(85).sfixed32(message.ticketPriceInCredits);
        if (message.recipientAccountIds != null && message.recipientAccountIds.length)
            for (let i = 0; i < message.recipientAccountIds.length; ++i)
                writer.uint32(90).string(message.recipientAccountIds[i]);
        if (message.saveStoryOnServer != null && Object.hasOwnProperty.call(message, "saveStoryOnServer"))
            writer.uint32(96).bool(message.saveStoryOnServer);
        if (message.streamProtocol != null && Object.hasOwnProperty.call(message, "streamProtocol"))
            writer.uint32(104).int32(message.streamProtocol);
        if (message.region != null && Object.hasOwnProperty.call(message, "region"))
            writer.uint32(114).string(message.region);
        if (message.dontInviteToLiveParty != null && Object.hasOwnProperty.call(message, "dontInviteToLiveParty"))
            writer.uint32(120).bool(message.dontInviteToLiveParty);
        if (message.restrictions != null && Object.hasOwnProperty.call(message, "restrictions"))
            $root.StreamInitRestriction.encode(message.restrictions, writer.uint32(130).fork()).ldelim();
        if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
            $root.StreamSettings.encode(message.settings, writer.uint32(138).fork()).ldelim();
        if (message.hashtags != null && message.hashtags.length)
            for (let i = 0; i < message.hashtags.length; ++i)
                writer.uint32(146).string(message.hashtags[i]);
        if (message.landscape != null && Object.hasOwnProperty.call(message, "landscape"))
            writer.uint32(152).bool(message.landscape);
        if (message.transcodingRequested != null && Object.hasOwnProperty.call(message, "transcodingRequested"))
            writer.uint32(160).bool(message.transcodingRequested);
        if (message.lpInvitableBy != null && Object.hasOwnProperty.call(message, "lpInvitableBy"))
            writer.uint32(168).int32(message.lpInvitableBy);
        if (message.webRtcSupport != null && Object.hasOwnProperty.call(message, "webRtcSupport"))
            writer.uint32(176).bool(message.webRtcSupport);
        if (message.isBcChallengeEnabledForUser != null && Object.hasOwnProperty.call(message, "isBcChallengeEnabledForUser"))
            writer.uint32(184).bool(message.isBcChallengeEnabledForUser);
        if (message.multiBattleSupport != null && Object.hasOwnProperty.call(message, "multiBattleSupport"))
            writer.uint32(192).bool(message.multiBattleSupport);
        if (message.bcChallengeExperiment != null && Object.hasOwnProperty.call(message, "bcChallengeExperiment"))
            $root.BcChallengeExperiment.encode(message.bcChallengeExperiment, writer.uint32(202).fork()).ldelim();
        if (message.birthdayStream != null && Object.hasOwnProperty.call(message, "birthdayStream"))
            writer.uint32(208).bool(message.birthdayStream);
        return writer;
    };

    InitStreamRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    InitStreamRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.InitStreamRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 2: {
                    message.recipientAccountId = reader.string();
                    break;
                }
            case 3: {
                    message.recipientGroupId = reader.string();
                    break;
                }
            case 4: {
                    message.title = reader.string();
                    break;
                }
            case 5: {
                    message.forceStart = reader.bool();
                    break;
                }
            case 6: {
                    message.thumbnail = reader.string();
                    break;
                }
            case 7: {
                    message.thumbnailWidth = reader.sfixed32();
                    break;
                }
            case 8: {
                    message.thumbnailHeight = reader.sfixed32();
                    break;
                }
            case 9: {
                    message.source = reader.string();
                    break;
                }
            case 10: {
                    message.ticketPriceInCredits = reader.sfixed32();
                    break;
                }
            case 11: {
                    if (!(message.recipientAccountIds && message.recipientAccountIds.length))
                        message.recipientAccountIds = [];
                    message.recipientAccountIds.push(reader.string());
                    break;
                }
            case 12: {
                    message.saveStoryOnServer = reader.bool();
                    break;
                }
            case 13: {
                    message.streamProtocol = reader.int32();
                    break;
                }
            case 14: {
                    message.region = reader.string();
                    break;
                }
            case 15: {
                    message.dontInviteToLiveParty = reader.bool();
                    break;
                }
            case 16: {
                    message.restrictions = $root.StreamInitRestriction.decode(reader, reader.uint32());
                    break;
                }
            case 17: {
                    message.settings = $root.StreamSettings.decode(reader, reader.uint32());
                    break;
                }
            case 18: {
                    if (!(message.hashtags && message.hashtags.length))
                        message.hashtags = [];
                    message.hashtags.push(reader.string());
                    break;
                }
            case 19: {
                    message.landscape = reader.bool();
                    break;
                }
            case 20: {
                    message.transcodingRequested = reader.bool();
                    break;
                }
            case 21: {
                    message.lpInvitableBy = reader.int32();
                    break;
                }
            case 22: {
                    message.webRtcSupport = reader.bool();
                    break;
                }
            case 23: {
                    message.isBcChallengeEnabledForUser = reader.bool();
                    break;
                }
            case 24: {
                    message.multiBattleSupport = reader.bool();
                    break;
                }
            case 25: {
                    message.bcChallengeExperiment = $root.BcChallengeExperiment.decode(reader, reader.uint32());
                    break;
                }
            case 26: {
                    message.birthdayStream = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    InitStreamRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    InitStreamRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.InitStreamRequest)
            return object;
        let message = new $root.InitStreamRequest();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "PUBLIC":
        case 1:
            message.type = 1;
            break;
        case "PRIVATE":
        case 2:
            message.type = 2;
            break;
        }
        if (object.recipientAccountId != null)
            message.recipientAccountId = String(object.recipientAccountId);
        if (object.recipientGroupId != null)
            message.recipientGroupId = String(object.recipientGroupId);
        if (object.title != null)
            message.title = String(object.title);
        if (object.forceStart != null)
            message.forceStart = Boolean(object.forceStart);
        if (object.thumbnail != null)
            message.thumbnail = String(object.thumbnail);
        if (object.thumbnailWidth != null)
            message.thumbnailWidth = object.thumbnailWidth | 0;
        if (object.thumbnailHeight != null)
            message.thumbnailHeight = object.thumbnailHeight | 0;
        if (object.source != null)
            message.source = String(object.source);
        if (object.ticketPriceInCredits != null)
            message.ticketPriceInCredits = object.ticketPriceInCredits | 0;
        if (object.recipientAccountIds) {
            if (!Array.isArray(object.recipientAccountIds))
                throw TypeError(".InitStreamRequest.recipientAccountIds: array expected");
            message.recipientAccountIds = [];
            for (let i = 0; i < object.recipientAccountIds.length; ++i)
                message.recipientAccountIds[i] = String(object.recipientAccountIds[i]);
        }
        if (object.saveStoryOnServer != null)
            message.saveStoryOnServer = Boolean(object.saveStoryOnServer);
        switch (object.streamProtocol) {
        default:
            if (typeof object.streamProtocol === "number") {
                message.streamProtocol = object.streamProtocol;
                break;
            }
            break;
        case "RTMP":
        case 1:
            message.streamProtocol = 1;
            break;
        case "SRT":
        case 2:
            message.streamProtocol = 2;
            break;
        }
        if (object.region != null)
            message.region = String(object.region);
        if (object.dontInviteToLiveParty != null)
            message.dontInviteToLiveParty = Boolean(object.dontInviteToLiveParty);
        if (object.restrictions != null) {
            if (typeof object.restrictions !== "object")
                throw TypeError(".InitStreamRequest.restrictions: object expected");
            message.restrictions = $root.StreamInitRestriction.fromObject(object.restrictions);
        }
        if (object.settings != null) {
            if (typeof object.settings !== "object")
                throw TypeError(".InitStreamRequest.settings: object expected");
            message.settings = $root.StreamSettings.fromObject(object.settings);
        }
        if (object.hashtags) {
            if (!Array.isArray(object.hashtags))
                throw TypeError(".InitStreamRequest.hashtags: array expected");
            message.hashtags = [];
            for (let i = 0; i < object.hashtags.length; ++i)
                message.hashtags[i] = String(object.hashtags[i]);
        }
        if (object.landscape != null)
            message.landscape = Boolean(object.landscape);
        if (object.transcodingRequested != null)
            message.transcodingRequested = Boolean(object.transcodingRequested);
        switch (object.lpInvitableBy) {
        default:
            if (typeof object.lpInvitableBy === "number") {
                message.lpInvitableBy = object.lpInvitableBy;
                break;
            }
            break;
        case "NO_ONE":
        case 1:
            message.lpInvitableBy = 1;
            break;
        case "FRIENDS_ONLY":
        case 2:
            message.lpInvitableBy = 2;
            break;
        case "ANYONE":
        case 3:
            message.lpInvitableBy = 3;
            break;
        }
        if (object.webRtcSupport != null)
            message.webRtcSupport = Boolean(object.webRtcSupport);
        if (object.isBcChallengeEnabledForUser != null)
            message.isBcChallengeEnabledForUser = Boolean(object.isBcChallengeEnabledForUser);
        if (object.multiBattleSupport != null)
            message.multiBattleSupport = Boolean(object.multiBattleSupport);
        if (object.bcChallengeExperiment != null) {
            if (typeof object.bcChallengeExperiment !== "object")
                throw TypeError(".InitStreamRequest.bcChallengeExperiment: object expected");
            message.bcChallengeExperiment = $root.BcChallengeExperiment.fromObject(object.bcChallengeExperiment);
        }
        if (object.birthdayStream != null)
            message.birthdayStream = Boolean(object.birthdayStream);
        return message;
    };

    InitStreamRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.recipientAccountIds = [];
            object.hashtags = [];
        }
        if (options.defaults) {
            object.type = options.enums === String ? "PUBLIC" : 1;
            object.recipientAccountId = "";
            object.recipientGroupId = "";
            object.title = "";
            object.forceStart = false;
            object.thumbnail = "";
            object.thumbnailWidth = 0;
            object.thumbnailHeight = 0;
            object.source = "";
            object.ticketPriceInCredits = 0;
            object.saveStoryOnServer = false;
            object.streamProtocol = options.enums === String ? "RTMP" : 1;
            object.region = "";
            object.dontInviteToLiveParty = false;
            object.restrictions = null;
            object.settings = null;
            object.landscape = false;
            object.transcodingRequested = false;
            object.lpInvitableBy = options.enums === String ? "NO_ONE" : 1;
            object.webRtcSupport = false;
            object.isBcChallengeEnabledForUser = false;
            object.multiBattleSupport = false;
            object.bcChallengeExperiment = null;
            object.birthdayStream = false;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.StreamType[message.type] === undefined ? message.type : $root.StreamType[message.type] : message.type;
        if (message.recipientAccountId != null && message.hasOwnProperty("recipientAccountId"))
            object.recipientAccountId = message.recipientAccountId;
        if (message.recipientGroupId != null && message.hasOwnProperty("recipientGroupId"))
            object.recipientGroupId = message.recipientGroupId;
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.forceStart != null && message.hasOwnProperty("forceStart"))
            object.forceStart = message.forceStart;
        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
            object.thumbnail = message.thumbnail;
        if (message.thumbnailWidth != null && message.hasOwnProperty("thumbnailWidth"))
            object.thumbnailWidth = message.thumbnailWidth;
        if (message.thumbnailHeight != null && message.hasOwnProperty("thumbnailHeight"))
            object.thumbnailHeight = message.thumbnailHeight;
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = message.source;
        if (message.ticketPriceInCredits != null && message.hasOwnProperty("ticketPriceInCredits"))
            object.ticketPriceInCredits = message.ticketPriceInCredits;
        if (message.recipientAccountIds && message.recipientAccountIds.length) {
            object.recipientAccountIds = [];
            for (let j = 0; j < message.recipientAccountIds.length; ++j)
                object.recipientAccountIds[j] = message.recipientAccountIds[j];
        }
        if (message.saveStoryOnServer != null && message.hasOwnProperty("saveStoryOnServer"))
            object.saveStoryOnServer = message.saveStoryOnServer;
        if (message.streamProtocol != null && message.hasOwnProperty("streamProtocol"))
            object.streamProtocol = options.enums === String ? $root.StreamProtocol[message.streamProtocol] === undefined ? message.streamProtocol : $root.StreamProtocol[message.streamProtocol] : message.streamProtocol;
        if (message.region != null && message.hasOwnProperty("region"))
            object.region = message.region;
        if (message.dontInviteToLiveParty != null && message.hasOwnProperty("dontInviteToLiveParty"))
            object.dontInviteToLiveParty = message.dontInviteToLiveParty;
        if (message.restrictions != null && message.hasOwnProperty("restrictions"))
            object.restrictions = $root.StreamInitRestriction.toObject(message.restrictions, options);
        if (message.settings != null && message.hasOwnProperty("settings"))
            object.settings = $root.StreamSettings.toObject(message.settings, options);
        if (message.hashtags && message.hashtags.length) {
            object.hashtags = [];
            for (let j = 0; j < message.hashtags.length; ++j)
                object.hashtags[j] = message.hashtags[j];
        }
        if (message.landscape != null && message.hasOwnProperty("landscape"))
            object.landscape = message.landscape;
        if (message.transcodingRequested != null && message.hasOwnProperty("transcodingRequested"))
            object.transcodingRequested = message.transcodingRequested;
        if (message.lpInvitableBy != null && message.hasOwnProperty("lpInvitableBy"))
            object.lpInvitableBy = options.enums === String ? $root.LPInviter[message.lpInvitableBy] === undefined ? message.lpInvitableBy : $root.LPInviter[message.lpInvitableBy] : message.lpInvitableBy;
        if (message.webRtcSupport != null && message.hasOwnProperty("webRtcSupport"))
            object.webRtcSupport = message.webRtcSupport;
        if (message.isBcChallengeEnabledForUser != null && message.hasOwnProperty("isBcChallengeEnabledForUser"))
            object.isBcChallengeEnabledForUser = message.isBcChallengeEnabledForUser;
        if (message.multiBattleSupport != null && message.hasOwnProperty("multiBattleSupport"))
            object.multiBattleSupport = message.multiBattleSupport;
        if (message.bcChallengeExperiment != null && message.hasOwnProperty("bcChallengeExperiment"))
            object.bcChallengeExperiment = $root.BcChallengeExperiment.toObject(message.bcChallengeExperiment, options);
        if (message.birthdayStream != null && message.hasOwnProperty("birthdayStream"))
            object.birthdayStream = message.birthdayStream;
        return object;
    };

    InitStreamRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    InitStreamRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/InitStreamRequest";
    };

    return InitStreamRequest;
})();

export const InitStreamResponse = $root.InitStreamResponse = (() => {

    function InitStreamResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    InitStreamResponse.prototype.code = 1;
    InitStreamResponse.prototype.encryptedStreamId = "";
    InitStreamResponse.prototype.targetDuration = 0;
    InitStreamResponse.prototype.key = "";
    InitStreamResponse.prototype.blpsInfo = null;
    InitStreamResponse.prototype.ingressUrl = "";
    InitStreamResponse.prototype.userBanDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    InitStreamResponse.prototype.transcodingSupported = false;
    InitStreamResponse.prototype.fallbackUrl = "";

    InitStreamResponse.create = function create(properties) {
        return new InitStreamResponse(properties);
    };

    InitStreamResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.encryptedStreamId != null && Object.hasOwnProperty.call(message, "encryptedStreamId"))
            writer.uint32(18).string(message.encryptedStreamId);
        if (message.targetDuration != null && Object.hasOwnProperty.call(message, "targetDuration"))
            writer.uint32(29).sfixed32(message.targetDuration);
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            writer.uint32(34).string(message.key);
        if (message.blpsInfo != null && Object.hasOwnProperty.call(message, "blpsInfo"))
            $root.BlpsStreamerInfo.encode(message.blpsInfo, writer.uint32(42).fork()).ldelim();
        if (message.ingressUrl != null && Object.hasOwnProperty.call(message, "ingressUrl"))
            writer.uint32(50).string(message.ingressUrl);
        if (message.userBanDuration != null && Object.hasOwnProperty.call(message, "userBanDuration"))
            writer.uint32(57).sfixed64(message.userBanDuration);
        if (message.transcodingSupported != null && Object.hasOwnProperty.call(message, "transcodingSupported"))
            writer.uint32(64).bool(message.transcodingSupported);
        if (message.fallbackUrl != null && Object.hasOwnProperty.call(message, "fallbackUrl"))
            writer.uint32(74).string(message.fallbackUrl);
        return writer;
    };

    InitStreamResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    InitStreamResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.InitStreamResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.encryptedStreamId = reader.string();
                    break;
                }
            case 3: {
                    message.targetDuration = reader.sfixed32();
                    break;
                }
            case 4: {
                    message.key = reader.string();
                    break;
                }
            case 5: {
                    message.blpsInfo = $root.BlpsStreamerInfo.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.ingressUrl = reader.string();
                    break;
                }
            case 7: {
                    message.userBanDuration = reader.sfixed64();
                    break;
                }
            case 8: {
                    message.transcodingSupported = reader.bool();
                    break;
                }
            case 9: {
                    message.fallbackUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    InitStreamResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    InitStreamResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.InitStreamResponse)
            return object;
        let message = new $root.InitStreamResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "SUCCESS":
        case 1:
            message.code = 1;
            break;
        case "BAD_REQUEST":
        case 2:
            message.code = 2;
            break;
        case "FAILED":
        case 3:
            message.code = 3;
            break;
        case "EXISTING_LIVE_STREAM":
        case 4:
            message.code = 4;
            break;
        case "NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        case "UNAUTHORIZED":
        case 6:
            message.code = 6;
            break;
        case "UPGRADE_DISABLED":
        case 7:
            message.code = 7;
            break;
        }
        if (object.encryptedStreamId != null)
            message.encryptedStreamId = String(object.encryptedStreamId);
        if (object.targetDuration != null)
            message.targetDuration = object.targetDuration | 0;
        if (object.key != null)
            message.key = String(object.key);
        if (object.blpsInfo != null) {
            if (typeof object.blpsInfo !== "object")
                throw TypeError(".InitStreamResponse.blpsInfo: object expected");
            message.blpsInfo = $root.BlpsStreamerInfo.fromObject(object.blpsInfo);
        }
        if (object.ingressUrl != null)
            message.ingressUrl = String(object.ingressUrl);
        if (object.userBanDuration != null)
            if ($util.Long)
                (message.userBanDuration = $util.Long.fromValue(object.userBanDuration)).unsigned = false;
            else if (typeof object.userBanDuration === "string")
                message.userBanDuration = parseInt(object.userBanDuration, 10);
            else if (typeof object.userBanDuration === "number")
                message.userBanDuration = object.userBanDuration;
            else if (typeof object.userBanDuration === "object")
                message.userBanDuration = new $util.LongBits(object.userBanDuration.low >>> 0, object.userBanDuration.high >>> 0).toNumber();
        if (object.transcodingSupported != null)
            message.transcodingSupported = Boolean(object.transcodingSupported);
        if (object.fallbackUrl != null)
            message.fallbackUrl = String(object.fallbackUrl);
        return message;
    };

    InitStreamResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "SUCCESS" : 1;
            object.encryptedStreamId = "";
            object.targetDuration = 0;
            object.key = "";
            object.blpsInfo = null;
            object.ingressUrl = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.userBanDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.userBanDuration = options.longs === String ? "0" : 0;
            object.transcodingSupported = false;
            object.fallbackUrl = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.encryptedStreamId != null && message.hasOwnProperty("encryptedStreamId"))
            object.encryptedStreamId = message.encryptedStreamId;
        if (message.targetDuration != null && message.hasOwnProperty("targetDuration"))
            object.targetDuration = message.targetDuration;
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        if (message.blpsInfo != null && message.hasOwnProperty("blpsInfo"))
            object.blpsInfo = $root.BlpsStreamerInfo.toObject(message.blpsInfo, options);
        if (message.ingressUrl != null && message.hasOwnProperty("ingressUrl"))
            object.ingressUrl = message.ingressUrl;
        if (message.userBanDuration != null && message.hasOwnProperty("userBanDuration"))
            if (typeof message.userBanDuration === "number")
                object.userBanDuration = options.longs === String ? String(message.userBanDuration) : message.userBanDuration;
            else
                object.userBanDuration = options.longs === String ? $util.Long.prototype.toString.call(message.userBanDuration) : options.longs === Number ? new $util.LongBits(message.userBanDuration.low >>> 0, message.userBanDuration.high >>> 0).toNumber() : message.userBanDuration;
        if (message.transcodingSupported != null && message.hasOwnProperty("transcodingSupported"))
            object.transcodingSupported = message.transcodingSupported;
        if (message.fallbackUrl != null && message.hasOwnProperty("fallbackUrl"))
            object.fallbackUrl = message.fallbackUrl;
        return object;
    };

    InitStreamResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    InitStreamResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/InitStreamResponse";
    };

    return InitStreamResponse;
})();

export const GetStreamStatusResponse = $root.GetStreamStatusResponse = (() => {

    function GetStreamStatusResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    GetStreamStatusResponse.prototype.code = 1;
    GetStreamStatusResponse.prototype.status = 1;
    GetStreamStatusResponse.prototype.duration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    GetStreamStatusResponse.prototype.viewers = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    GetStreamStatusResponse.prototype.redirectStreamId = "";

    GetStreamStatusResponse.create = function create(properties) {
        return new GetStreamStatusResponse(properties);
    };

    GetStreamStatusResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(16).int32(message.status);
        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
            writer.uint32(25).sfixed64(message.duration);
        if (message.viewers != null && Object.hasOwnProperty.call(message, "viewers"))
            writer.uint32(33).sfixed64(message.viewers);
        if (message.redirectStreamId != null && Object.hasOwnProperty.call(message, "redirectStreamId"))
            writer.uint32(42).string(message.redirectStreamId);
        return writer;
    };

    GetStreamStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    GetStreamStatusResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetStreamStatusResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.status = reader.int32();
                    break;
                }
            case 3: {
                    message.duration = reader.sfixed64();
                    break;
                }
            case 4: {
                    message.viewers = reader.sfixed64();
                    break;
                }
            case 5: {
                    message.redirectStreamId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    GetStreamStatusResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    GetStreamStatusResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetStreamStatusResponse)
            return object;
        let message = new $root.GetStreamStatusResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "SUCCESS":
        case 1:
            message.code = 1;
            break;
        case "BAD_REQUEST":
        case 2:
            message.code = 2;
            break;
        case "FAILED":
        case 3:
            message.code = 3;
            break;
        case "EXISTING_LIVE_STREAM":
        case 4:
            message.code = 4;
            break;
        case "NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        case "UNAUTHORIZED":
        case 6:
            message.code = 6;
            break;
        case "UPGRADE_DISABLED":
        case 7:
            message.code = 7;
            break;
        }
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "INITED":
        case 1:
            message.status = 1;
            break;
        case "LIVING":
        case 2:
            message.status = 2;
            break;
        case "TERMINATED":
        case 3:
            message.status = 3;
            break;
        case "SUSPENDED":
        case 4:
            message.status = 4;
            break;
        case "EXPIRED":
        case 5:
            message.status = 5;
            break;
        case "UNKNOWN":
        case 6:
            message.status = 6;
            break;
        }
        if (object.duration != null)
            if ($util.Long)
                (message.duration = $util.Long.fromValue(object.duration)).unsigned = false;
            else if (typeof object.duration === "string")
                message.duration = parseInt(object.duration, 10);
            else if (typeof object.duration === "number")
                message.duration = object.duration;
            else if (typeof object.duration === "object")
                message.duration = new $util.LongBits(object.duration.low >>> 0, object.duration.high >>> 0).toNumber();
        if (object.viewers != null)
            if ($util.Long)
                (message.viewers = $util.Long.fromValue(object.viewers)).unsigned = false;
            else if (typeof object.viewers === "string")
                message.viewers = parseInt(object.viewers, 10);
            else if (typeof object.viewers === "number")
                message.viewers = object.viewers;
            else if (typeof object.viewers === "object")
                message.viewers = new $util.LongBits(object.viewers.low >>> 0, object.viewers.high >>> 0).toNumber();
        if (object.redirectStreamId != null)
            message.redirectStreamId = String(object.redirectStreamId);
        return message;
    };

    GetStreamStatusResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "SUCCESS" : 1;
            object.status = options.enums === String ? "INITED" : 1;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.duration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.duration = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.viewers = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.viewers = options.longs === String ? "0" : 0;
            object.redirectStreamId = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.StreamStatus[message.status] === undefined ? message.status : $root.StreamStatus[message.status] : message.status;
        if (message.duration != null && message.hasOwnProperty("duration"))
            if (typeof message.duration === "number")
                object.duration = options.longs === String ? String(message.duration) : message.duration;
            else
                object.duration = options.longs === String ? $util.Long.prototype.toString.call(message.duration) : options.longs === Number ? new $util.LongBits(message.duration.low >>> 0, message.duration.high >>> 0).toNumber() : message.duration;
        if (message.viewers != null && message.hasOwnProperty("viewers"))
            if (typeof message.viewers === "number")
                object.viewers = options.longs === String ? String(message.viewers) : message.viewers;
            else
                object.viewers = options.longs === String ? $util.Long.prototype.toString.call(message.viewers) : options.longs === Number ? new $util.LongBits(message.viewers.low >>> 0, message.viewers.high >>> 0).toNumber() : message.viewers;
        if (message.redirectStreamId != null && message.hasOwnProperty("redirectStreamId"))
            object.redirectStreamId = message.redirectStreamId;
        return object;
    };

    GetStreamStatusResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    GetStreamStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetStreamStatusResponse";
    };

    return GetStreamStatusResponse;
})();

export const UpgradeStreamRequest = $root.UpgradeStreamRequest = (() => {

    function UpgradeStreamRequest(properties) {
        this.recipientAccountIds = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UpgradeStreamRequest.prototype.recipientAccountIds = $util.emptyArray;
    UpgradeStreamRequest.prototype.title = "";
    UpgradeStreamRequest.prototype.thumbnail = "";
    UpgradeStreamRequest.prototype.thumbnailWidth = 0;
    UpgradeStreamRequest.prototype.thumbnailHeight = 0;
    UpgradeStreamRequest.prototype.ticketPriceInCredits = 0;
    UpgradeStreamRequest.prototype.streamProtocol = 1;
    UpgradeStreamRequest.prototype.region = "";
    UpgradeStreamRequest.prototype.restrictions = null;
    UpgradeStreamRequest.prototype.incognitoEnabled = false;
    UpgradeStreamRequest.prototype.transcodingRequested = false;
    UpgradeStreamRequest.prototype.targetStreamType = 1;
    UpgradeStreamRequest.prototype.sendInvites = false;

    UpgradeStreamRequest.create = function create(properties) {
        return new UpgradeStreamRequest(properties);
    };

    UpgradeStreamRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.recipientAccountIds != null && message.recipientAccountIds.length)
            for (let i = 0; i < message.recipientAccountIds.length; ++i)
                writer.uint32(10).string(message.recipientAccountIds[i]);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(18).string(message.title);
        if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
            writer.uint32(26).string(message.thumbnail);
        if (message.thumbnailWidth != null && Object.hasOwnProperty.call(message, "thumbnailWidth"))
            writer.uint32(37).sfixed32(message.thumbnailWidth);
        if (message.thumbnailHeight != null && Object.hasOwnProperty.call(message, "thumbnailHeight"))
            writer.uint32(45).sfixed32(message.thumbnailHeight);
        if (message.ticketPriceInCredits != null && Object.hasOwnProperty.call(message, "ticketPriceInCredits"))
            writer.uint32(53).sfixed32(message.ticketPriceInCredits);
        if (message.streamProtocol != null && Object.hasOwnProperty.call(message, "streamProtocol"))
            writer.uint32(56).int32(message.streamProtocol);
        if (message.region != null && Object.hasOwnProperty.call(message, "region"))
            writer.uint32(66).string(message.region);
        if (message.restrictions != null && Object.hasOwnProperty.call(message, "restrictions"))
            $root.StreamInitRestriction.encode(message.restrictions, writer.uint32(74).fork()).ldelim();
        if (message.incognitoEnabled != null && Object.hasOwnProperty.call(message, "incognitoEnabled"))
            writer.uint32(80).bool(message.incognitoEnabled);
        if (message.transcodingRequested != null && Object.hasOwnProperty.call(message, "transcodingRequested"))
            writer.uint32(88).bool(message.transcodingRequested);
        if (message.targetStreamType != null && Object.hasOwnProperty.call(message, "targetStreamType"))
            writer.uint32(96).int32(message.targetStreamType);
        if (message.sendInvites != null && Object.hasOwnProperty.call(message, "sendInvites"))
            writer.uint32(104).bool(message.sendInvites);
        return writer;
    };

    UpgradeStreamRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UpgradeStreamRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpgradeStreamRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.recipientAccountIds && message.recipientAccountIds.length))
                        message.recipientAccountIds = [];
                    message.recipientAccountIds.push(reader.string());
                    break;
                }
            case 2: {
                    message.title = reader.string();
                    break;
                }
            case 3: {
                    message.thumbnail = reader.string();
                    break;
                }
            case 4: {
                    message.thumbnailWidth = reader.sfixed32();
                    break;
                }
            case 5: {
                    message.thumbnailHeight = reader.sfixed32();
                    break;
                }
            case 6: {
                    message.ticketPriceInCredits = reader.sfixed32();
                    break;
                }
            case 7: {
                    message.streamProtocol = reader.int32();
                    break;
                }
            case 8: {
                    message.region = reader.string();
                    break;
                }
            case 9: {
                    message.restrictions = $root.StreamInitRestriction.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.incognitoEnabled = reader.bool();
                    break;
                }
            case 11: {
                    message.transcodingRequested = reader.bool();
                    break;
                }
            case 12: {
                    message.targetStreamType = reader.int32();
                    break;
                }
            case 13: {
                    message.sendInvites = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    UpgradeStreamRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UpgradeStreamRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpgradeStreamRequest)
            return object;
        let message = new $root.UpgradeStreamRequest();
        if (object.recipientAccountIds) {
            if (!Array.isArray(object.recipientAccountIds))
                throw TypeError(".UpgradeStreamRequest.recipientAccountIds: array expected");
            message.recipientAccountIds = [];
            for (let i = 0; i < object.recipientAccountIds.length; ++i)
                message.recipientAccountIds[i] = String(object.recipientAccountIds[i]);
        }
        if (object.title != null)
            message.title = String(object.title);
        if (object.thumbnail != null)
            message.thumbnail = String(object.thumbnail);
        if (object.thumbnailWidth != null)
            message.thumbnailWidth = object.thumbnailWidth | 0;
        if (object.thumbnailHeight != null)
            message.thumbnailHeight = object.thumbnailHeight | 0;
        if (object.ticketPriceInCredits != null)
            message.ticketPriceInCredits = object.ticketPriceInCredits | 0;
        switch (object.streamProtocol) {
        default:
            if (typeof object.streamProtocol === "number") {
                message.streamProtocol = object.streamProtocol;
                break;
            }
            break;
        case "RTMP":
        case 1:
            message.streamProtocol = 1;
            break;
        case "SRT":
        case 2:
            message.streamProtocol = 2;
            break;
        }
        if (object.region != null)
            message.region = String(object.region);
        if (object.restrictions != null) {
            if (typeof object.restrictions !== "object")
                throw TypeError(".UpgradeStreamRequest.restrictions: object expected");
            message.restrictions = $root.StreamInitRestriction.fromObject(object.restrictions);
        }
        if (object.incognitoEnabled != null)
            message.incognitoEnabled = Boolean(object.incognitoEnabled);
        if (object.transcodingRequested != null)
            message.transcodingRequested = Boolean(object.transcodingRequested);
        switch (object.targetStreamType) {
        default:
            if (typeof object.targetStreamType === "number") {
                message.targetStreamType = object.targetStreamType;
                break;
            }
            break;
        case "PUBLIC_TARGET":
        case 1:
            message.targetStreamType = 1;
            break;
        case "PREMIUM_TARGET":
        case 2:
            message.targetStreamType = 2;
            break;
        case "CHAT_TARGET":
        case 3:
            message.targetStreamType = 3;
            break;
        case "CHAT_GROUP_TARGET":
        case 4:
            message.targetStreamType = 4;
            break;
        }
        if (object.sendInvites != null)
            message.sendInvites = Boolean(object.sendInvites);
        return message;
    };

    UpgradeStreamRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.recipientAccountIds = [];
        if (options.defaults) {
            object.title = "";
            object.thumbnail = "";
            object.thumbnailWidth = 0;
            object.thumbnailHeight = 0;
            object.ticketPriceInCredits = 0;
            object.streamProtocol = options.enums === String ? "RTMP" : 1;
            object.region = "";
            object.restrictions = null;
            object.incognitoEnabled = false;
            object.transcodingRequested = false;
            object.targetStreamType = options.enums === String ? "PUBLIC_TARGET" : 1;
            object.sendInvites = false;
        }
        if (message.recipientAccountIds && message.recipientAccountIds.length) {
            object.recipientAccountIds = [];
            for (let j = 0; j < message.recipientAccountIds.length; ++j)
                object.recipientAccountIds[j] = message.recipientAccountIds[j];
        }
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
            object.thumbnail = message.thumbnail;
        if (message.thumbnailWidth != null && message.hasOwnProperty("thumbnailWidth"))
            object.thumbnailWidth = message.thumbnailWidth;
        if (message.thumbnailHeight != null && message.hasOwnProperty("thumbnailHeight"))
            object.thumbnailHeight = message.thumbnailHeight;
        if (message.ticketPriceInCredits != null && message.hasOwnProperty("ticketPriceInCredits"))
            object.ticketPriceInCredits = message.ticketPriceInCredits;
        if (message.streamProtocol != null && message.hasOwnProperty("streamProtocol"))
            object.streamProtocol = options.enums === String ? $root.StreamProtocol[message.streamProtocol] === undefined ? message.streamProtocol : $root.StreamProtocol[message.streamProtocol] : message.streamProtocol;
        if (message.region != null && message.hasOwnProperty("region"))
            object.region = message.region;
        if (message.restrictions != null && message.hasOwnProperty("restrictions"))
            object.restrictions = $root.StreamInitRestriction.toObject(message.restrictions, options);
        if (message.incognitoEnabled != null && message.hasOwnProperty("incognitoEnabled"))
            object.incognitoEnabled = message.incognitoEnabled;
        if (message.transcodingRequested != null && message.hasOwnProperty("transcodingRequested"))
            object.transcodingRequested = message.transcodingRequested;
        if (message.targetStreamType != null && message.hasOwnProperty("targetStreamType"))
            object.targetStreamType = options.enums === String ? $root.TargetStreamType[message.targetStreamType] === undefined ? message.targetStreamType : $root.TargetStreamType[message.targetStreamType] : message.targetStreamType;
        if (message.sendInvites != null && message.hasOwnProperty("sendInvites"))
            object.sendInvites = message.sendInvites;
        return object;
    };

    UpgradeStreamRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UpgradeStreamRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpgradeStreamRequest";
    };

    return UpgradeStreamRequest;
})();

export const UpgradeStreamResponse = $root.UpgradeStreamResponse = (() => {

    function UpgradeStreamResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UpgradeStreamResponse.prototype.code = 1;
    UpgradeStreamResponse.prototype.encryptedStreamId = "";
    UpgradeStreamResponse.prototype.targetDuration = 0;
    UpgradeStreamResponse.prototype.key = "";
    UpgradeStreamResponse.prototype.blpsInfo = null;
    UpgradeStreamResponse.prototype.ingressUrl = "";
    UpgradeStreamResponse.prototype.transcodingSupported = false;
    UpgradeStreamResponse.prototype.fallbackUrl = "";

    UpgradeStreamResponse.create = function create(properties) {
        return new UpgradeStreamResponse(properties);
    };

    UpgradeStreamResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.encryptedStreamId != null && Object.hasOwnProperty.call(message, "encryptedStreamId"))
            writer.uint32(18).string(message.encryptedStreamId);
        if (message.targetDuration != null && Object.hasOwnProperty.call(message, "targetDuration"))
            writer.uint32(29).sfixed32(message.targetDuration);
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            writer.uint32(34).string(message.key);
        if (message.blpsInfo != null && Object.hasOwnProperty.call(message, "blpsInfo"))
            $root.BlpsStreamerInfo.encode(message.blpsInfo, writer.uint32(42).fork()).ldelim();
        if (message.ingressUrl != null && Object.hasOwnProperty.call(message, "ingressUrl"))
            writer.uint32(50).string(message.ingressUrl);
        if (message.transcodingSupported != null && Object.hasOwnProperty.call(message, "transcodingSupported"))
            writer.uint32(56).bool(message.transcodingSupported);
        if (message.fallbackUrl != null && Object.hasOwnProperty.call(message, "fallbackUrl"))
            writer.uint32(66).string(message.fallbackUrl);
        return writer;
    };

    UpgradeStreamResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UpgradeStreamResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpgradeStreamResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.encryptedStreamId = reader.string();
                    break;
                }
            case 3: {
                    message.targetDuration = reader.sfixed32();
                    break;
                }
            case 4: {
                    message.key = reader.string();
                    break;
                }
            case 5: {
                    message.blpsInfo = $root.BlpsStreamerInfo.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.ingressUrl = reader.string();
                    break;
                }
            case 7: {
                    message.transcodingSupported = reader.bool();
                    break;
                }
            case 8: {
                    message.fallbackUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    UpgradeStreamResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UpgradeStreamResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpgradeStreamResponse)
            return object;
        let message = new $root.UpgradeStreamResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "SUCCESS":
        case 1:
            message.code = 1;
            break;
        case "BAD_REQUEST":
        case 2:
            message.code = 2;
            break;
        case "FAILED":
        case 3:
            message.code = 3;
            break;
        case "EXISTING_LIVE_STREAM":
        case 4:
            message.code = 4;
            break;
        case "NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        case "UNAUTHORIZED":
        case 6:
            message.code = 6;
            break;
        case "UPGRADE_DISABLED":
        case 7:
            message.code = 7;
            break;
        }
        if (object.encryptedStreamId != null)
            message.encryptedStreamId = String(object.encryptedStreamId);
        if (object.targetDuration != null)
            message.targetDuration = object.targetDuration | 0;
        if (object.key != null)
            message.key = String(object.key);
        if (object.blpsInfo != null) {
            if (typeof object.blpsInfo !== "object")
                throw TypeError(".UpgradeStreamResponse.blpsInfo: object expected");
            message.blpsInfo = $root.BlpsStreamerInfo.fromObject(object.blpsInfo);
        }
        if (object.ingressUrl != null)
            message.ingressUrl = String(object.ingressUrl);
        if (object.transcodingSupported != null)
            message.transcodingSupported = Boolean(object.transcodingSupported);
        if (object.fallbackUrl != null)
            message.fallbackUrl = String(object.fallbackUrl);
        return message;
    };

    UpgradeStreamResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "SUCCESS" : 1;
            object.encryptedStreamId = "";
            object.targetDuration = 0;
            object.key = "";
            object.blpsInfo = null;
            object.ingressUrl = "";
            object.transcodingSupported = false;
            object.fallbackUrl = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.encryptedStreamId != null && message.hasOwnProperty("encryptedStreamId"))
            object.encryptedStreamId = message.encryptedStreamId;
        if (message.targetDuration != null && message.hasOwnProperty("targetDuration"))
            object.targetDuration = message.targetDuration;
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        if (message.blpsInfo != null && message.hasOwnProperty("blpsInfo"))
            object.blpsInfo = $root.BlpsStreamerInfo.toObject(message.blpsInfo, options);
        if (message.ingressUrl != null && message.hasOwnProperty("ingressUrl"))
            object.ingressUrl = message.ingressUrl;
        if (message.transcodingSupported != null && message.hasOwnProperty("transcodingSupported"))
            object.transcodingSupported = message.transcodingSupported;
        if (message.fallbackUrl != null && message.hasOwnProperty("fallbackUrl"))
            object.fallbackUrl = message.fallbackUrl;
        return object;
    };

    UpgradeStreamResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UpgradeStreamResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpgradeStreamResponse";
    };

    return UpgradeStreamResponse;
})();

export { $root as default };
