import React, { CSSProperties, FC } from "react";
import { Spinner } from "chat/imports/components";
import { ensureHttps } from "chat/imports/utils";
import { getMediaDimensionsStyle } from "chat/premiumMessage/common/getMediaDimensionsStyle";
import { StoredMessage } from "chat/state";
import styles from "./VideoImageLoading.scss";

interface VideoImageLoadingProps {
  message: StoredMessage;
}

const VideoImageLoading: FC<VideoImageLoadingProps> = ({ message }) => {
  const mediaData = message.media?.[0];

  if (!mediaData) {
    return null;
  }

  const { width, height, thumbnail_url } = mediaData;
  const mediaStyles = getMediaDimensionsStyle(Number(width), Number(height));

  return (
    <div
      className={styles.root}
      data-testid="video-message-loading"
      style={mediaStyles}
    >
      <div
        className={styles.overlay}
        style={
          thumbnail_url
            ? ({
                "--thumbnail": `url(${ensureHttps(thumbnail_url)})`,
              } as CSSProperties)
            : undefined
        }
      />
      <Spinner className={styles.spinner} size={32} />
    </div>
  );
};

export default VideoImageLoading;
