import React, {
  FC,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import emptyFunction from "fbjs/lib/emptyFunction";
import { DeviceType } from "src/enums";
import { useEffectOnce } from "src/utils/miniReactUse";
import { deviceInfoSelectors } from "state/selectors";
import usePersistentState from "ui/hooks/usePersistentState";

const MuteContext = createContext({
  muted: false,
  setMuted: emptyFunction as (value: boolean) => void,
  userInteractedMute: false,
  setUserInteractedMuted: emptyFunction as (value: boolean) => void,
});

export default MuteContext;

const MUTED_STORAGE_KEY = "muted";

export const MuteContextProvider: FC = ({ children }) => {
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const mutedInitially =
    deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD;
  const [userInteractedMute, setUserInteractedMute] = useState(false);

  const [muted, setMuted] = usePersistentState({
    key: MUTED_STORAGE_KEY,
    session: true,
    listenForUpdates: true,
    restoreOldValue: true,
    initialValue: mutedInitially,
  });

  const setUserInteractedMuteHandler = useCallback(
    (value) => {
      setMuted(value);
      setUserInteractedMute(value);
    },
    [setMuted]
  );

  useEffectOnce(() => {
    if (!mutedInitially) {
      return;
    }

    const cb = () => {
      setMuted(document.visibilityState !== "visible");
    };
    document.addEventListener("visibilitychange", cb);

    return () => document.removeEventListener("visibilitychange", cb);
  });

  const value = useMemo(
    () => ({
      muted,
      setMuted,
      userInteractedMute,
      setUserInteractedMuted: setUserInteractedMuteHandler,
    }),
    [muted, setMuted, setUserInteractedMuteHandler, userInteractedMute]
  );

  return <MuteContext.Provider value={value}>{children}</MuteContext.Provider>;
};
