import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserType, DeviceType } from "src/enums";
import {
  getGiftAnimationsAlternativeDomainContentSupportEnabled,
  getGiftsVideoEnabled,
  getGiftsVideoMovEnabled,
} from "src/state/abTests";
import { RootState } from "src/state/delegate";
import { deviceInfoSelectors } from "src/state/selectors";
import { Gift, VideoSource } from "src/types/gift";
import { useMakeAlternativeDomainUrl } from "src/ui/hooks/useMakeAlternativeDomainUrl";

const selector = (state: RootState) => ({
  browserName: deviceInfoSelectors.getBrowserName(state),
  deviceType: deviceInfoSelectors.getDeviceType(state),
  isGiftsVideoMovEnabled: getGiftsVideoMovEnabled(state),
  isGiftsVideoEnabled: getGiftsVideoEnabled(state),
});

const useGenerateEnabledVideoSources = (gift: Gift): VideoSource[] => {
  const {
    browserName,
    isGiftsVideoMovEnabled,
    isGiftsVideoEnabled,
    deviceType,
  } = useSelector(selector, shallowEqual);

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftAnimationsAlternativeDomainContentSupportEnabled
  );

  const isSafari = browserName === BrowserType.SAFARI;
  const isIos = deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD;

  return useMemo(() => {
    if (!isGiftsVideoEnabled) {
      return [];
    }

    const {
      webmAnimation720Url,
      webmAnimation360Url,
      movAnimation720Url,
      movAnimation360Url,
    } = gift;

    const videoSources: VideoSource[] = [];

    if ((isSafari || isIos) && isGiftsVideoMovEnabled) {
      movAnimation720Url &&
        videoSources.push({
          src: makeAlternativeDomainUrl(movAnimation720Url),
          type: "video/quicktime",
        });
      movAnimation360Url &&
        videoSources.push({
          src: makeAlternativeDomainUrl(movAnimation360Url),
          type: "video/quicktime",
        });
    } else if (!isSafari) {
      webmAnimation720Url &&
        videoSources.push({
          src: makeAlternativeDomainUrl(webmAnimation720Url),
          type: "video/webm",
        });
      webmAnimation360Url &&
        videoSources.push({
          src: makeAlternativeDomainUrl(webmAnimation360Url),
          type: "video/webm",
        });
    }

    return videoSources;
  }, [
    isGiftsVideoEnabled,
    gift,
    isSafari,
    isIos,
    isGiftsVideoMovEnabled,
    makeAlternativeDomainUrl,
  ]);
};

export default useGenerateEnabledVideoSources;
