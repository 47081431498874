import { shallowEqual, useSelector } from "react-redux";
import { LocationDescriptor, LocationState, Path } from "history";
import { navigationSelectors } from "state/selectors";
import { defaultRoute } from "ui/navigation/links";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { RootState } from "state/delegate";

const selector = (state: RootState) => ({
  prevRoute: navigationSelectors.getPreviousRoute(state),
  currentRoute: navigationSelectors.getCurrentRoute(state),
});

export default <HistoryLocationState = LocationState>(
  fallback: Path | LocationDescriptor<HistoryLocationState> = defaultRoute
) => {
  const { prevRoute, currentRoute } = useSelector(selector, shallowEqual);
  const history = useHistory();
  return useCallback(() => {
    if (prevRoute) {
      return prevRoute === currentRoute
        ? history.replace(fallback)
        : history.goBack();
    }

    history.push(fallback);
  }, [prevRoute, history, fallback, currentRoute]);
};
