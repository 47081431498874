import React, { memo } from "react";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { ReactComponent as MicroOffIcon16 } from "img/stream/microphone_fill_16.svg";
import { ReactComponent as MicroOffIcon24 } from "img/stream/microphone_fill_24.svg";
import { ReactComponent as SoundOffIcon16 } from "img/stream/sound_fill_16.svg";
import { ReactComponent as SoundOffIcon24 } from "img/stream/sound_fill_24.svg";
import styles from "./SoundAndMicrophoneStatus.scss";

interface SoundAndMicrophoneStatusProps {
  showMicro?: boolean;
  showSound?: boolean;
  size?: SoundAndMicrophoneStatusSize;
}

export enum SoundAndMicrophoneStatusSize {
  BIG = "big",
  SMALL = "small",
  XSMALL = "xsmall",
}

const SoundAndMicrophoneStatus = ({
  showMicro,
  showSound,
  size = SoundAndMicrophoneStatusSize.SMALL,
}: SoundAndMicrophoneStatusProps) => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const SoundIcon = isDesktop ? SoundOffIcon24 : SoundOffIcon16;
  const MicroIcon = isDesktop ? MicroOffIcon24 : MicroOffIcon16;

  return (
    <div
      className={classnames(styles.container, styles[breakpoint], styles[size])}
    >
      {showSound && <SoundIcon className={styles.icon} />}
      {showMicro && <MicroIcon className={styles.icon} />}
    </div>
  );
};

export default memo(SoundAndMicrophoneStatus);
