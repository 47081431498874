import { kickViewerFromStream } from "api/stream";
import { VoidCallback } from "src/types/common";
import { RootState } from "state/delegate";
import { broadcastSelectors } from "state/selectors";
import { AppDispatch } from "state/types";

interface KickUserFromStreamProps {
  accountId: string;
  onError?: VoidCallback;
  onSuccess?: VoidCallback;
}

export const kickUserFromStream =
  ({ accountId, onSuccess, onError }: KickUserFromStreamProps) =>
  async (_: AppDispatch, getState: () => RootState) => {
    const streamId = broadcastSelectors.broadcastId(getState()) || "";

    try {
      await kickViewerFromStream({ streamId, accountId });
      onSuccess?.();
    } catch (error) {
      onError?.();
    }
  };
