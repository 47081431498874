import { parseBooleanEnabledSOC, parseNumberSOC } from "state/abTests";

export const getIsSoundWobblerEnabled = parseBooleanEnabledSOC(
  "sound.wobbler.enabled",
  "0"
);

export const getSoundControlAutoHideThreshold = parseNumberSOC(
  "sound.control.auto.hide.threshold",
  "100"
);

export const getSoundControlAutoHideDelayMS = parseNumberSOC(
  "sound.control.auto.hide.delay.ms",
  "1500"
);

export const getSoundWobblerAnimationDelayMS = parseNumberSOC(
  "sound.wobbler.animation.delay.ms",
  "2000"
);

export const getIsDropDownEnabled = parseBooleanEnabledSOC(
  "sound.wobbler.dropdown.enabled",
  "0"
);
