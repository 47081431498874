import type { MessageDescriptor } from "react-intl";
import type { SocialGamesListRequest } from "src/features/socialGames/common/types";
import {
  SocialGamesSectionType,
  SocialGamesV2SectionType,
} from "src/features/socialGames/common/enums";
import { socialGamesMessages } from "src/features/socialGames/common/messages";

export const GRID_GAMES_HEADERS: Partial<
  Record<SocialGamesSectionType, MessageDescriptor>
> = {
  [SocialGamesSectionType.OTHER]: socialGamesMessages.other,
  [SocialGamesSectionType.RECENTLY_PLAYED]: socialGamesMessages.recentlyPlayed,
  [SocialGamesSectionType.SLOTS]: socialGamesMessages.slots,
  [SocialGamesSectionType.LIVE]: socialGamesMessages.live,
  [SocialGamesSectionType.FREESPINS]: socialGamesMessages.freespins,
  [SocialGamesSectionType.FEATURED]: socialGamesMessages.featured,
};

export const GRID_GAMES_V2_HEADERS: Partial<
  Record<SocialGamesV2SectionType, MessageDescriptor>
> = {
  [SocialGamesV2SectionType.FEATURED]:
    socialGamesMessages.social_games_hot_and_new,
  [SocialGamesV2SectionType.NEW]: socialGamesMessages.live_home_new,
  [SocialGamesV2SectionType.SLOTS]: socialGamesMessages.social_games_spins,
  [SocialGamesV2SectionType.LIVE]: socialGamesMessages.live,
  [SocialGamesV2SectionType.TABLE]: socialGamesMessages.social_games_classic,
  [SocialGamesV2SectionType.OTHER]: socialGamesMessages.social_games_other,
  [SocialGamesV2SectionType.RECENTLY_PLAYED]:
    socialGamesMessages.social_games_recently_played,
  [SocialGamesV2SectionType.FREESPINS]: socialGamesMessages.freespins,
};

export const ALLOWED_GAMES_SECTIONS: Array<SocialGamesSectionType> = [
  SocialGamesSectionType.FEATURED,
  SocialGamesSectionType.SLOTS,
  SocialGamesSectionType.OTHER,
  SocialGamesSectionType.LIVE,
];

export const DEFAULT_SUGGESTED_GAMES_COUNT = 12;

export const DEBOUNCE_INPUT_SEARCH_TIMEOUT = 150;

export const GAME_ID_PROVIDER_PREFIX_REGEXP = /^.*?:/;

export const DEBOUNCE_SCROLL_SLIDE_TIMEOUT = 500;

export const SOCIAL_GAMES_CURRENCY_NAME = "COIN";

export const DEFAULT_SOCIAL_GAMES_LIST_REQUEST: SocialGamesListRequest = {
  sections: [
    SocialGamesSectionType.COMMON,
    SocialGamesSectionType.FEATURED,
    SocialGamesSectionType.OTHER,
    SocialGamesSectionType.RECENTLY_PLAYED,
    SocialGamesSectionType.SLOTS,
    SocialGamesSectionType.LIVE,
  ],
};

export const DEFAULT_SOCIAL_GAMES_V2_LIST_REQUEST: SocialGamesListRequest = {
  sections: [
    SocialGamesV2SectionType.COMMON,
    SocialGamesV2SectionType.FEATURED,
    SocialGamesV2SectionType.OTHER,
    SocialGamesV2SectionType.RECENTLY_PLAYED,
    SocialGamesV2SectionType.SLOTS,
    SocialGamesV2SectionType.LIVE,
  ],
};

export const RECENTLY_PLAYED_GAMES_LIST_REQUEST: Pick<
  SocialGamesListRequest,
  "sections"
> = {
  sections: [SocialGamesSectionType.RECENTLY_PLAYED],
};

export const CATEGORY_TYPE_QUERY_PARAM = "type";

export const SOCIAL_GAMES_CATEGORY_NAMING_MAP: Record<
  SocialGamesSectionType,
  string
> = {
  [SocialGamesSectionType.COMMON]: "common",
  [SocialGamesSectionType.FEATURED]: "featured",
  [SocialGamesSectionType.OTHER]: "other",
  [SocialGamesSectionType.RECENTLY_PLAYED]: "recently_played",
  [SocialGamesSectionType.SLOTS]: "slots",
  [SocialGamesSectionType.LIVE]: "live",
  [SocialGamesSectionType.FREESPINS]: "freespins",
};

export const SEARCH_SKELETON_CARDS_AMOUNT = 12;

export const SOCIAL_GAMES_STREAM_GAME_QUERY_KEY = "sg";

export const SOCIAL_GAMES_WIN_UP_TO_VALUE = 1000000;

export const TYPE_FREESPINS = "FREESPINS";
