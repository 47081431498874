import { getProxycadorHost } from "environment";
import fetch from "./utils/enhancedFetch";

export interface UploadedMedia {
  contentType: string;
  mediaId: string;
  thumbnailUrl: string;
  url: string;
}

export const uploadImage = ({
  image,
  thumbnail,
}: {
  image: Blob;
  thumbnail: Blob;
}): Promise<UploadedMedia> => {
  const formData = new FormData();
  formData.append("file", image);
  formData.append("thumbnail", thumbnail);

  return fetch(`${getProxycadorHost()}/proxycador/api/public/v1/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  }).then((res) => res.json());
};

interface ContentImageResponse {
  code: number;
  download_url: string;
  media_id: string;
  non_tango_webview_url: string;
  permission: string;
  reference_id: string;
  thumbnail_url: string;
  type: string;
}

export const uploadContentImage = (
  url: string,
  image: Blob
): Promise<ContentImageResponse> => {
  const formData = new FormData();
  formData.append("file", image);

  return fetch(url, {
    method: "POST",
    body: formData,
    credentials: "include",
  }).then((res) => res.json());
};

interface UploadTokenMediaInfo {
  height: number;
  size: number;
  type: string;
  width: number;
}

interface UploadTokenResponse {
  code: number;
  media_id: string;
  type: string;
  upload_url: string;
}

export const getUploadToken = ({
  height,
  width,
  size,
  type,
}: UploadTokenMediaInfo): Promise<UploadTokenResponse> =>
  fetch(`${getProxycadorHost()}/contentserver/private/token/ssl`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      acknowledge: false,
      media_info: {
        height,
        mime_type: type,
        size,
        width,
      },
      permission: "public",
    }),
    credentials: "include",
  }).then((res) => res.json());
