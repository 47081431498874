import { fetch } from "src/features/visitorCashier/imports/api";
import { getProxycadorHost } from "src/features/visitorCashier/imports/environment";
import {
  PersonalOffersConfig,
  PricePoint,
} from "src/features/visitorCashier/imports/types";

export const fetchMarketingPersonalOffers = async (
  isVisitor: boolean
): Promise<PersonalOffersConfig> => {
  const resp = await fetch(
    `${getProxycadorHost()}${isVisitor ? "/visitors" : ""}/personal-offers/client/offer/marketing`,
    {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }
  );

  const data = await resp.json();

  if (data.pricePoints) {
    data.pricePoints = data.pricePoints.filter((pricePoint: PricePoint) =>
      Boolean(pricePoint.offers)
    );
  }

  return data;
};
