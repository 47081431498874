import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import DatadogTransport from "@analytics/DatadogUtils";
import { useWebViewDetector } from "src/core/webview/WebViewController/useWebViewDetector";
import { DatadogMessage } from "src/enums";
import { logoutSuccess as clearUserSession } from "src/features/signin/exports/state/actionCreators";
import { combinedLegalPaths } from "src/ui/navigation/RootRouter";
import { resetSessionDetails } from "state/actionCreators/sessionDetails";
import { RootState } from "state/delegate";
import { autoLoginSelectors, loginSelectors } from "state/selectors";
import { UserDataSecurityGateOpener } from "ui/UserDataSecurityGate/UserDataSecurityGateOpener";
import useSplashScreen from "ui/hooks/useSplashScreen";
import { useMount } from "utils/miniReactUse";

const userDataSecurityGateSelector = (state: RootState) => ({
  isAccount: loginSelectors.isLoggedIn(state),
  isAutoLoginSession: autoLoginSelectors.getIsAutoLoginSession(state),
});

export const UserDataSecurityGate: FC = ({ children }) => {
  const dispatch = useDispatch();

  const isLegalRoute = useRouteMatch(combinedLegalPaths);

  const { isAccount, isAutoLoginSession } = useSelector(
    userDataSecurityGateSelector
  );

  const isWebView = useWebViewDetector();
  const isWebViewOnMount = useRef(isWebView);

  const [shouldIgnoreGateRules, setShouldIgnoreGateRules] = useState(false);
  const handleGateOpen = useCallback(() => setShouldIgnoreGateRules(true), []);

  const isGateOpen = useMemo(
    () =>
      shouldIgnoreGateRules ||
      !isAccount ||
      !isWebViewOnMount.current ||
      isAutoLoginSession ||
      !isLegalRoute,
    [isAccount, isAutoLoginSession, isLegalRoute, shouldIgnoreGateRules]
  );

  useMount(() => {
    if (isWebViewOnMount.current && !isAutoLoginSession && isAccount) {
      /*
       * We only log out the user on legal pages to avoid logging out on payment
       * pages. If we don't see logs from the other pages, we can remove this
       * check.
       */
      if (isLegalRoute) {
        DatadogTransport.warn(DatadogMessage.LOGOUT_FOR_SECURITY_REASONS);
        batch(() => {
          dispatch(clearUserSession());
          dispatch(resetSessionDetails());
        });
      } else {
        DatadogTransport.info(DatadogMessage.LOGOUT_FOR_SECURITY_REASONS);
      }
    }
  });

  useSplashScreen(!isGateOpen);

  return isGateOpen ? (
    <UserDataSecurityGateOpener onMount={handleGateOpen}>
      {children}
    </UserDataSecurityGateOpener>
  ) : null;
};
