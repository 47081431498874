import {
  fetchAllAvailableGifts,
  fetchDrawerVersion,
  fetchPersonalGifts,
  fetchSingleGift,
  fetchTopSentGifts,
} from "api/gifts";
import { fetchGiftsBatch } from "api/typedGifts";
import { getIsGiftsDrawerV2Enabled } from "src/features/giftsDrawer/exports/abTests";
import {
  loadGiftDrawerPending,
  loadGiftDrawerRejected,
} from "src/features/giftsDrawer/exports/action";
import { loadGiftDrawer } from "src/features/giftsDrawer/exports/asyncAction";
import { GiftDrawerTarget } from "src/features/giftsDrawer/exports/enums";
import { pollUntil } from "src/utils/pollUntil";
import { getMoodsLegacyV2Enabled, getMoodsV2Enabled } from "state/abTests";
import {
  deviceInfoSelectors,
  giftsCacheSelectors,
  loginSelectors,
  userSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/giftsCache";

const DRAWER_INCLUDES_SPECIALS = ["AR_GIFT"];

const loadDrawerImpl = async (locale, drawerVersion, dispatch) => {
  dispatch(actionCreators.setAvailableDrawerVersion(drawerVersion));
  dispatch(actionCreators.fetchBegan());

  try {
    const drawer = await fetchAllAvailableGifts({
      includeSpecials: DRAWER_INCLUDES_SPECIALS,
      locale,
      version: drawerVersion,
    });

    dispatch(
      actionCreators.fetchCompleted({
        data: { ...drawer, locale },
        replaceAll: false,
      })
    );
  } catch (error) {
    dispatch(actionCreators.fetchFailed({ error }));
  }
};

export default () => async (dispatch, getState) => {
  const state = getState();

  if (giftsCacheSelectors.getIsLoadInProgress(state)) {
    return;
  }

  let drawerVersion = 0;
  try {
    const response = await fetchDrawerVersion();
    drawerVersion = response.drawerVersion;
  } catch (error) {
    return;
  }

  const locale = deviceInfoSelectors.getDeviceLocale(state);
  const isGiftsDrawerV2Enabled = getIsGiftsDrawerV2Enabled(state);

  if (
    drawerVersion <= giftsCacheSelectors.getLoadedVersion(state) &&
    locale === giftsCacheSelectors.getLoadedGiftsLocale(state) &&
    !giftsCacheSelectors.isStale(state, isGiftsDrawerV2Enabled)
  ) {
    return;
  }

  await loadDrawerImpl(locale, drawerVersion, dispatch);
};

const RETRY_MS = 100;
const MAX_RETRIES = 100;

export const loadGiftsDrawer =
  (target = GiftDrawerTarget.STREAM) =>
  async (dispatch, getState) => {
    dispatch(loadGiftDrawerPending());

    // TODO: Fix race condition https://tango-me.atlassian.net/browse/WEB-9188
    const isSuccessfulWaiting = await pollUntil(
      () => !giftsCacheSelectors.getIsLoadInProgress(getState()),
      RETRY_MS,
      MAX_RETRIES
    );

    if (!isSuccessfulWaiting) {
      dispatch(loadGiftDrawerRejected());

      return;
    }

    let drawerVersion = 0;
    try {
      const response = await fetchDrawerVersion();
      drawerVersion = response.drawerVersion;
    } catch (error) {
      dispatch(loadGiftDrawerRejected());

      return;
    }

    const state = getState();

    const locale = deviceInfoSelectors.getDeviceLocale(state);
    const isGiftsDrawerV2Enabled = getIsGiftsDrawerV2Enabled(state);

    if (
      giftsCacheSelectors.isStale(state, isGiftsDrawerV2Enabled) ||
      drawerVersion > giftsCacheSelectors.getLoadedVersion(state) ||
      locale !== giftsCacheSelectors.getLoadedGiftsLocale(state)
    ) {
      await loadDrawerImpl(locale, drawerVersion, dispatch);
    }

    await dispatch(
      loadGiftDrawer({
        target,
        isLoadLatest:
          locale !== giftsCacheSelectors.getLoadedGiftsLocale(state),
      })
    );
  };

export const loadSingleGift = (giftId) => async (dispatch, getState) => {
  const state = getState();
  if (giftsCacheSelectors.getGiftById(state, giftId)) {
    return;
  }
  const locale = deviceInfoSelectors.getDeviceLocale(state);
  const latestKnownDrawerVersion = giftsCacheSelectors.getLoadedVersion(state);
  const response = await fetchSingleGift({
    locale,
    giftId,
  });
  dispatch(actionCreators.fetchedSingleGift(response));
  if (response.drawerVersion > latestKnownDrawerVersion) {
    await loadDrawerImpl(locale, response.drawerVersion, dispatch);
  }
};

export const loadGiftsBatch =
  ({ giftIds, ignoreCache }) =>
  async (dispatch, getState) => {
    const state = getState();
    const idsToLoad = ignoreCache
      ? giftIds
      : giftIds.filter((x) => !giftsCacheSelectors.getGiftById(state, x));
    if (!idsToLoad.length) {
      return;
    }
    const locale = deviceInfoSelectors.getDeviceLocale(state);
    const loadedVersion = giftsCacheSelectors.getLoadedVersion(state);
    const giftsBatch = await fetchGiftsBatch(giftIds);
    dispatch(actionCreators.giftsBatchReceived(giftsBatch));
    if (giftsBatch.drawerVersion > loadedVersion) {
      await loadDrawerImpl(locale, giftsBatch.drawerVersion, dispatch);
    }
  };

export const loadTopSentGifts = () => async (dispatch, getState) => {
  const state = getState();
  if (
    getMoodsV2Enabled(state) ||
    !getMoodsLegacyV2Enabled(state) ||
    !loginSelectors.isLoggedIn(state)
  ) {
    return;
  }
  try {
    const data = await fetchTopSentGifts({
      accountId: userSelectors.getMyAccountId(state),
    });
    const { topSentGifts } = data;
    if (!topSentGifts?.length) {
      return;
    }
    dispatch(actionCreators.setTopSentGifts(topSentGifts));
    await dispatch(
      loadGiftsBatch({ giftIds: topSentGifts, ignoreCache: false })
    );
  } catch (e) {
    // do nothing
  }
};

export const loadPersonalGifts = (streamerId) => async (dispatch, getState) => {
  const state = getState();
  if (!loginSelectors.isLoggedIn(state)) {
    return;
  }
  try {
    const data = await fetchPersonalGifts(streamerId);
    const { gifts } = data;
    if (!gifts?.length) {
      dispatch(actionCreators.setPersonalGifts([]));

      return;
    }
    dispatch(actionCreators.setPersonalGifts(gifts));
  } catch (e) {
    dispatch(actionCreators.setPersonalGifts([]));
  }
};
