export enum BroadcastMasksViewType {
  Beauty = "Beauty",
  Lobby = "Lobby",
  Makeup = "Makeup",
  Masks = "Masks",
}

export enum TangoMakeupType {
  Blush = "blush",
  Contactlens = "contactlens",
  Eyebrow = "eyebrow",
  Eyelash = "eyelash",
  Eyelinear = "eyelinear",
  Eyeshadow = "eyeshadow",
  Lipstick = "lipstick",
}

export enum BanubaMakeupType {
  Blush = "blush",
  Brows = "brows",
  Eyelashes = "eyelashes",
  Eyeliner = "eyeliner",
  Eyeshadow = "eyeshadow",
  Lenses = "lenses",
  Lips = "lips",
}

export enum TangoBeautyType {
  CheekNarrow = "cheek_narrow",
  CheekSmall = "cheek_small",
  CheekThin = "cheek_thin",
  EyeDistance = "eye_distance",
  EyeEnlarge = "eye_enlarge",
  FineSmooth = "fine_smooth",
  Mouth = "mouth",
  Nose = "nose",
  RemovePouchStrength = "remove_pouch_strength",
  Ruddy = "ruddy",
  ToothWhiten = "tooth_whiten",
  VFace = "v_face",
  Whiten = "whiten",
}

export enum BanubaBeautyType {
  CheekNarrow = "cheek_narrow",
  CheekSmall = "cheek_small",
  CheekThin = "cheek_thin",
  Circles = "circles",
  EyeDistance = "eye_distance",
  EyeEnlarge = "eye_enlarge",
  Mouth = "mouth",
  Nose = "nose",
  Ruddy = "ruddy",
  SkinsmoothStrength = "skinsmooth_strength",
  ToothWhiten = "tooth_whiten",
  VFace = "v_face",
  Whiten = "whiten",
}

export enum BanubaLipsType {
  Glitter = "glitter",
  Normal = "normal",
  Shine = "shine",
  Soft = "soft",
}
