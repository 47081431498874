export enum MultiStreamInviteTarget {
  BATTLE = "BATTLE",
  LIVE_PARTY = "LIVEPARTY",
}

export enum MultiStreamInviteText {
  SEARCH = "search",
}

export enum MultiStreamInviteAnchorType {
  STREAM = "STREAM",
}

export enum MultiStreamInviteState {
  FOR_YOU = "FOR_YOU",
  FRIENDS = "FRIENDS",
}

export enum MultiStreamInviteStreamType {
  OBS = "OBS",
  PREMIUM = "premium",
  PUBLIC = "public",
}

export enum BattleSourceId {
  LP = "lp",
  SINGLE = "single",
}
