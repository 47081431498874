import React, { FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import {
  AnchorType,
  MessageAction,
  emitMessageActionEvent,
  getMessageFlag,
} from "chat/analytics/emitMessageActionEvent";
import { messages as chatMessages } from "chat/components/common/messages";
import { useDeleteChatMessageModalMode } from "chat/hooks/useDeleteChatMessageModalMode";
import { Button, Checkbox, Typography } from "chat/imports/components";
import {
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { sharedMessages } from "chat/imports/utils";
import { StoredMessage, deleteChatMessage } from "chat/state";
import styles from "./MessageDeleteModalView.scss";

interface PropsModalData {
  messages: StoredMessage[];
}

interface MessageDeleteModalViewProps {
  dismiss: VoidFunction;
  modalData: PropsModalData;
  screenData: PropsModalData;
}

export const MessageDeleteModalView: FC<MessageDeleteModalViewProps> = ({
  dismiss,
  modalData,
  screenData,
}) => {
  const dispatch = useDispatch();
  const [isForAllChecked, setForAllChecked] = useState(false);
  const breakpoint = useBreakpointPrecise();

  const { messages } = screenData || modalData;
  const isOnlyForMeMode = useDeleteChatMessageModalMode(messages[0]);

  const isTablet = breakpoint === Breakpoints.TABLET;
  const title = isOnlyForMeMode
    ? chatMessages.deleteMessageOnlyForMe
    : chatMessages.deleteMessage;

  const handleForAllToggle = useCallback(
    (e) => setForAllChecked(e.target.checked),
    []
  );

  const handleDeleteMessage = async () => {
    dismiss();
    dispatch(deleteChatMessage({ messages, forAll: isForAllChecked }));

    emitMessageActionEvent({
      action: MessageAction.CONFIRM_DELETE_MESSAGE,
      chatId: messages[0].id.chat_id,
      size: 1,
      anchorType: isForAllChecked
        ? AnchorType.DELETE_FOR_EVERYONE
        : AnchorType.DELETE_FOR_ME,
      flag: getMessageFlag(messages[0]),
    });
  };

  const handleCancelDeleteMessage = () => {
    dismiss();
    emitMessageActionEvent({
      action: MessageAction.CANCEL_DELETE_MESSAGE,
      chatId: messages[0].id.chat_id,
      flag: getMessageFlag(messages[0]),
    });
  };

  return (
    <div
      className={classNames(styles.root, styles[breakpoint])}
      data-testid="chat-delete-message-confirm-for-all-moodal"
    >
      <div className={styles.info}>
        <Typography
          type={
            isTablet ? TYPOGRAPHY_TYPE.HEADLINE1 : TYPOGRAPHY_TYPE.HEADLINE2
          }
        >
          <FormattedMessage {...title} />
        </Typography>

        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH2}
          className={styles.description}
        >
          <FormattedMessage {...chatMessages.confirmDelete} />
        </Typography>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          dataTestId="chat-delete-message-confirm-modal-delete"
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.PRIMARY}
          className={styles.button}
          onClick={handleDeleteMessage}
        >
          <FormattedMessage {...chatMessages.deleteMessage} />
        </Button>

        <Button
          dataTestId="chat-delete-message-confirm-modal-cancel"
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.SECONDARY}
          className={styles.button}
          onClick={handleCancelDeleteMessage}
        >
          <FormattedMessage {...sharedMessages.cancel} />
        </Button>
      </div>
      {!isOnlyForMeMode && (
        <Checkbox
          markClassName={styles.checkboxMark}
          checked={isForAllChecked}
          onChange={handleForAllToggle}
          data-testid="chat-delete-message-confirm-for-all"
          label={<FormattedMessage {...chatMessages.confirmForAll} />}
        />
      )}
    </div>
  );
};
