import React, { UIEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import GiftsList from "src/features/gifts/components/giftsList/GiftsList";
import { RawGift, VoidCallback } from "src/features/gifts/imports";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import CarouselButton from "ui/common/buttons/CarouselButton";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import { ReactComponent as MoreIcon } from "img/ic_dots_32.svg";
import styles from "src/features/gifts/components/giftsCarousel/GiftsCarousel.scss";
const SCROLL_OFFSET = 300;
enum MaskSide {
  BOTH = "both",
  LEFT = "left",
  RIGHT = "right",
}
type GiftCarouselProps = {
  gifts: RawGift[];
  onClickMore: VoidCallback;
  onSelectGift: (giftId: string) => void;
  selectedGiftId: string;
};
const GiftsCarousel = ({
  gifts,
  selectedGiftId,
  onSelectGift,
  onClickMore,
}: GiftCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const selectedGiftRef = useRef<HTMLDivElement>(null);
  const isDesktop = useBreakpoint() === Breakpoints.DESKTOP;
  const [maskStyles, setMaskStyles] = useState(MaskSide.RIGHT);
  useEffect(() => {
    selectedGiftRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [selectedGiftId]);
  const handleScroll = (scrollOffset: number) => () => {
    carouselRef.current!.scrollLeft += scrollOffset;
  };
  const handleCarouselScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const scrollLeftMax =
      target.scrollWidth - target.getBoundingClientRect().width;
    if (target.scrollLeft === 0) {
      setMaskStyles(MaskSide.RIGHT);
    } else if (target.scrollLeft === scrollLeftMax) {
      setMaskStyles(MaskSide.LEFT);
    } else {
      setMaskStyles(MaskSide.BOTH);
    }
  };

  return (
    <div className={styles.root}>
      {isDesktop && (
        <CarouselButton
          onClick={handleScroll(-SCROLL_OFFSET)}
          className={classnames(styles.carouselBtn, styles.prev)}
        />
      )}
      <div
        ref={carouselRef}
        className={classnames({
          [styles.carousel]: true,
          [styles.maskLeftSide]: maskStyles === MaskSide.LEFT,
          [styles.maskRightSide]: maskStyles === MaskSide.RIGHT,
          [styles.maskBothSides]: maskStyles === MaskSide.BOTH,
        })}
        onScroll={handleCarouselScroll}
      >
        <GiftsList
          gifts={gifts}
          onClickGift={onSelectGift}
          selectedGiftRef={selectedGiftRef}
          selectedGiftId={selectedGiftId}
        />
        <div className={styles.moreBtnContainer}>
          <Button
            size={ButtonSize.CIRCLE_MEDIUM_40}
            variant={ButtonVariant.SECONDARY}
            onClick={onClickMore}
          >
            <MoreIcon />
          </Button>
          <Typography type={TYPOGRAPHY_TYPE.MINI}>
            <FormattedMessage
              id="modal.send-media.gifts-carousel.more-btn"
              defaultMessage="More"
            />
          </Typography>
        </div>
      </div>
      {isDesktop && (
        <CarouselButton
          type="next"
          onClick={handleScroll(SCROLL_OFFSET)}
          className={classnames(styles.carouselBtn, styles.next)}
        />
      )}
    </div>
  );
};
export default GiftsCarousel;
