import AcmeProto from "generated/proto/Acme";
import { CashierPersonalOfferResponse as Offer } from "generated/proto/CashierPersonalOffer";
import { TransactionStatusMessage } from "generated/proto/TransactionProtos";
import { AcmeServiceName } from "src/enums";
import { parseMessageFromBase64 } from "src/utils/protobufUtil";
import { textEncoder } from "utils/textEncoder";

const { OverrideableMessage, RefreshMessage } = AcmeProto;

export const parseAcme = (decodedAcme, onAcme) => {
  onAcme({
    data: {
      acmeId: decodedAcme.id,
    },
  });

  if (decodedAcme.serviceName === AcmeServiceName.FINANCE) {
    const { serviceName, serviceIdentifier, payload } = parseMessageFromBase64(
      decodedAcme.payload,
      OverrideableMessage
    );

    const data = parseMessageFromBase64(payload, TransactionStatusMessage);
    onAcme({
      serviceName,
      serviceIdentifier,
      data,
    });

    return;
  }

  if (decodedAcme.serviceName === AcmeServiceName.FACILITATOR) {
    const refreshMessage = RefreshMessage.decode(
      textEncoder.encode(decodedAcme.payload)
    );

    refreshMessage && onAcme(refreshMessage);
  }

  if (decodedAcme.serviceName === AcmeServiceName.PERSONAL_OFFERS) {
    const { serviceName, serviceIdentifier, payload } = parseMessageFromBase64(
      decodedAcme.payload,
      OverrideableMessage
    );

    const data = parseMessageFromBase64(payload, Offer);
    onAcme({
      serviceName,
      serviceIdentifier,
      data: {
        ...data,
        ts: Date.now(),
      },
    });
  }
};
