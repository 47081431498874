/**
 * Until react-use (https://github.com/streamich/react-use) enables importing
 * individual hooks instead of the whole library, the needed hooks are
 * copy-pasted here from the react-use repo to reduce bundle size.
 */

import {
  EffectCallback,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import emptyFunction from "fbjs/lib/emptyFunction";
import { WebRtcAdaptorStatus } from "src/enums";
import { VoidCallback } from "src/types/common";

/**
 * @see https://github.com/streamich/react-use/blob/master/src/useEffectOnce.ts
 */
export const useEffectOnce = (effect: EffectCallback) => {
  useEffect(effect, []);
};

export const useLayoutEffectOnce = (effect: EffectCallback) => {
  useLayoutEffect(effect, []);
};

/**
 * @see https://github.com/streamich/react-use/blob/master/src/useUnmount.ts
 */
export const useUnmount = (fn: VoidCallback) => {
  const fnRef = useRef(fn);

  // update the ref each render so if it change the newest callback will be invoked
  fnRef.current = fn;

  // eslint-disable-next-line arrow-body-style
  useEffectOnce(() => {
    return () => fnRef.current();
  });
};

export const useLayoutUnmount = (fn: VoidCallback) => {
  const fnRef = useRef(fn);

  // update the ref each render so if it change the newest callback will be invoked
  fnRef.current = fn;

  // eslint-disable-next-line arrow-body-style
  useLayoutEffectOnce(() => {
    return () => fnRef.current();
  });
};

/**
 * @see https://github.com/streamich/react-use/blob/master/src/useMount.ts
 */
export const useMount = (fn: VoidCallback) => {
  useEffectOnce(() => {
    fn();
  });
};

export const useLayoutMount = (fn: VoidCallback) => {
  useLayoutEffectOnce(() => {
    fn();
  });
};

export const useIsMountedRef = () => {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
};

export type MediaDevices = Omit<MediaDeviceInfo, "toJSON">[];

export const useMediaDevices = (webRtcAdaptorStatus?: WebRtcAdaptorStatus) => {
  const [state, setState] = useState<MediaDevices>([]);
  const isMountedRef = useIsMountedRef();
  const getDevices = useCallback(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        if (isMountedRef.current) {
          setState(
            devices.map(({ deviceId, groupId, kind, label }) => ({
              deviceId,
              groupId,
              kind,
              label,
            }))
          );
        }
      })
      .catch(emptyFunction);
  }, []);

  useEffect(() => {
    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);

  useEffect(() => {
    if (
      webRtcAdaptorStatus &&
      webRtcAdaptorStatus === WebRtcAdaptorStatus.INITIALIZED
    ) {
      getDevices();
    }
  }, [webRtcAdaptorStatus]);

  return state;
};
