import { SearchParams } from "@analytics/enums";
import { searchParamsStorage } from "@analytics/parseSearchParams/searchParamsStorage";
import {
  getAndroidAppId,
  getGetTheAppAppStoreUrl,
  getGetTheAppGooglePlayUrl,
  getIosAppId,
} from "environment";
import {
  APPS_FLYER_APP_STORE_DIRECT_LINK_URL,
  APPS_FLYER_GOOGLE_PLAY_DIRECT_LINK_URL,
  APPS_FLYER_ONE_LINK_URL,
} from "src/core/analytics/constants";
import { AppsFlyerPlatform } from "src/core/analytics/enums";

const oneLinkURL = "https://tango.onelink.me/RCIH";
const mediaSource = { keys: ["utm_source"], defaultValue: "Web_Organic" };
const deepLinkValue = {
  keys: ["deep_link_value"],
  defaultValue: "page_unknown",
};
const button_name = {
  paramKey: "button_name",
  keys: ["button_name"],
  defaultValue: "button_unknown",
};
const campaign = { keys: ["utm_campaign"], defaultValue: "Web_Campaign" };
const afSub1 = { keys: ["utm_content"], defaultValue: "Web_Content" };
const afSub2 = { keys: ["utm_medium"], defaultValue: "Web_Medium" };
const afSub3 = { keys: ["utm_term"], defaultValue: "Web_Term" };
const afSub4 = { keys: ["utm_id"], defaultValue: "Web_ID" };

const clickIds = Object.values(SearchParams);

const afSub5 = {
  keys: clickIds,
  defaultValue: "Web_ID",
};
const af_dp = {
  paramKey: "af_dp",
  keys: ["af_dp"],
  defaultValue: "tango%3A%2F%2F",
};

const custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" };

export const DEFAULT_SESSION_ID = "session_id_unknown";

export const afParameters = {
  mediaSource,
  deepLinkValue,
  afCustom: [button_name, af_dp, custom_ss_ui],
  campaign,
  afSub1,
  afSub2,
  afSub3,
  afSub4,
  afSub5,
};

export const setAppsFlyerOneLinkURL = async (options = {}) => {
  const generatedOneLinkURL =
    window.AF_SMART_SCRIPT?.generateOneLinkURL({
      oneLinkURL,
      afParameters,
      ...options,
    })?.clickURL || "";

  await searchParamsStorage.setItem(
    APPS_FLYER_ONE_LINK_URL,
    generatedOneLinkURL
  );
};

export const getAppsFlyerOneLinkURL = async () =>
  await searchParamsStorage.getItem(APPS_FLYER_ONE_LINK_URL);

const generateAppsFlyerDirectLinkURL = (
  platform: string,
  appid: string,
  redirectURL: string,
  options = {}
) =>
  window.AF_SMART_SCRIPT?.generateDirectClickURL({
    afParameters,
    platform,
    app_id: appid,
    redirectURL,
    ...options,
  })?.clickURL || "";

export const setAppsFlyerDirectLinkURL = async (options: {
  googlePlayApkLink: string;
}) => {
  const { googlePlayApkLink, ...restOptions } = options;
  const generatedGooglePlatDirectLinkURL = generateAppsFlyerDirectLinkURL(
    AppsFlyerPlatform.ANDROID,
    getAndroidAppId(),
    googlePlayApkLink,
    restOptions
  );

  const generatedAppStoreDirectLinkURL = generateAppsFlyerDirectLinkURL(
    AppsFlyerPlatform.IOS,
    getIosAppId(),
    getGetTheAppAppStoreUrl(),
    restOptions
  );

  await searchParamsStorage.setItem(
    APPS_FLYER_GOOGLE_PLAY_DIRECT_LINK_URL,
    generatedGooglePlatDirectLinkURL
  );

  await searchParamsStorage.setItem(
    APPS_FLYER_APP_STORE_DIRECT_LINK_URL,
    generatedAppStoreDirectLinkURL
  );
};

export const handleGetTheAppGooglePlayUrl = async () =>
  (await searchParamsStorage.getItem(APPS_FLYER_GOOGLE_PLAY_DIRECT_LINK_URL)) ||
  getGetTheAppGooglePlayUrl();

export const handleGetTheAppAppStoreUrl = async () =>
  (await searchParamsStorage.getItem(APPS_FLYER_APP_STORE_DIRECT_LINK_URL)) ||
  getGetTheAppAppStoreUrl();
