import { datadogLogs } from "@datadog/browser-logs";
import AnalyticsUtils from "@analytics/AnalyticsUtils";
import DatadogTransport from "@analytics/DatadogUtils";
import { HTTP_CODE_UNAUTHORIZED } from "enums/httpCodes";
import { FetchHeader } from "src/api/fetchHeader.type";
import { API_ERROR_NETWORK_ERROR_TEXT } from "src/constants";
import { StorageKeys } from "src/core/analytics/dataMesh/common/enums";
import { getSessionStartParams } from "src/core/analytics/dataMesh/utils/dbMethods/getSessionStartParams";
import { DatadogMessage, ServerErrorActionType } from "src/enums";
import { UriComponent, VoidCallback } from "src/types/common";
import isLockedByCaptcha from "src/utils/isLockedByCaptcha";
import { ABORT_ERROR_NAME, AbortError } from "./abortError";
import ApiError from "./apiError";
import { addRequestToHistory } from "./requestHistory";

export type Params = Record<string, UriComponent>;

export const isApiError = (error: unknown): error is ApiError =>
  error instanceof ApiError;

const defaultInit: RequestInit = {
  credentials: "include",
  mode: "cors",
};

const additionalUnauthorizedErrorHandlers: VoidCallback[] = [];
export const addAdditionalUnauthorizedErrorHandler = (cb: VoidCallback) => {
  additionalUnauthorizedErrorHandlers.push(cb);
};

type captchaHandlers = (error: ApiError) => void;
const captchaErrorHandlers: captchaHandlers[] = [];
export const addCaptchaHandler = (cb: (error: ApiError) => void) => {
  captchaErrorHandlers.push(cb);
};

const sessionTokenErrorHandlers: VoidCallback[] = [];
export const addSessionTokenRefreshHandler = (cb: VoidCallback) => {
  sessionTokenErrorHandlers.push(cb);
};

interface ServerErrorAction {
  type: ServerErrorActionType;
}

const serverErrorInterceptor = (resp: Response, action?: ServerErrorAction) => {
  addRequestToHistory(resp.url, resp.status);

  if (!resp.ok) {
    const error = new ApiError({
      status: resp.status,
      statusText: resp.statusText,
    });

    if (resp.status === HTTP_CODE_UNAUTHORIZED) {
      if (action?.type === ServerErrorActionType.LOGOUT) {
        additionalUnauthorizedErrorHandlers.forEach((cb) => cb());
      } else {
        sessionTokenErrorHandlers.forEach((cb) => cb());
      }
    }

    isLockedByCaptcha(error) && captchaErrorHandlers.forEach((cb) => cb(error));

    return resp
      .json()
      .catch(() => {
        throw error;
      })
      .then((json) => {
        throw new ApiError({
          status: resp.status,
          statusText: resp.statusText,
          body: json,
        });
      });
  }

  return resp;
};

const networkErrorInterceptor = (
  fetchUrl: RequestInfo,
  options: RequestInit,
  error: Error
) => {
  const url = typeof fetchUrl === "string" ? fetchUrl : fetchUrl.url;

  if (error.name.includes(ABORT_ERROR_NAME)) {
    throw new AbortError();
  }

  DatadogTransport.info(
    `Network error when API call ${url}`,
    {
      body: options.body,
      fetchUrl,
      error,
    },
    error
  );

  throw new ApiError({ status: 0, statusText: API_ERROR_NETWORK_ERROR_TEXT });
};

const normalizeHeaders = (headers?: HeadersInit): Headers =>
  headers instanceof Headers ? headers : new Headers(headers);

export const enhancedFetch = (
  url: RequestInfo,
  init: RequestInit = {},
  action?: ServerErrorAction
) => {
  const options = {
    ...defaultInit,
    ...init,
    headers: normalizeHeaders(init.headers || defaultInit.headers),
  };

  return getSessionStartParams()
    .then((sessionStartParams) => {
      const sessionStartId = sessionStartParams[StorageKeys.SESSION_START_ID];

      if (typeof sessionStartId === "string") {
        options.headers.set(
          FetchHeader.X_APP_CLIENT_SESSION_ID,
          sessionStartId
        );
      }
    })
    .catch(() => {
      datadogLogs.logger.warn(
        DatadogMessage.FAILED_TO_PARSE_DATA_MESH_STORAGE_PARAMS
      );

      return "continue";
    })
    .then(() => {
      Object.entries(AnalyticsUtils.getHeaders()).forEach(
        ([analyticsHeaderKey, analyticsHeaderValue]) => {
          options.headers.set(analyticsHeaderKey, String(analyticsHeaderValue));
        }
      );

      return fetch(url, options);
    })
    .catch((e) => networkErrorInterceptor(url, options, e))
    .then((response) => serverErrorInterceptor(response, action));
};

export default enhancedFetch;

export const formatParams = (params: Params) =>
  Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");

export const urlAndParams = (url: string, params: Params) =>
  `${url}?${formatParams(params)}`;
