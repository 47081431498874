import {
  CHAT,
  HAPPY_MOMENTS,
  POST,
  PROFILE,
  STREAM,
} from "enums/giftRecipientType";

export const GIFT_SOURCE_STREAM = "Stream";
export const GIFT_SOURCE_CHAT = "Chat";
export const GIFT_SOURCE_POST = "Post";
export const GIFT_SOURCE_PROFILE = "Profile";
export const GIFT_SOURCE_HAPPY_MOMENTS = "HappyMoments";
export const GIFT_SOURCE_PRIVATE_TICKET = "PrivateTicketPaid";

export const giftRecipientTypeToGiftSource = (type) => {
  switch (type) {
    case POST:
      return GIFT_SOURCE_POST;
    case STREAM:
      return GIFT_SOURCE_STREAM;
    case PROFILE:
      return GIFT_SOURCE_PROFILE;
    case HAPPY_MOMENTS:
      return GIFT_SOURCE_HAPPY_MOMENTS;
    case CHAT:
      return GIFT_SOURCE_CHAT;
  }
};
