import { batch } from "react-redux";
import { updateAnalyticsKey } from "src/features/marketing/state/slice";
import { attemptRegisterReferralUser } from "src/features/referralProgram/exports/asyncActions";
import {
  EventCategory,
  EventNames,
  emitAF,
  emitEvent,
} from "src/features/signin/imports/analytics";
import { CLIENT_CAPABILITIES } from "src/features/signin/imports/constants";
import { LoginResult } from "src/features/signin/imports/enums";
import {
  clearVisitorData,
  fetchSessionDetails,
  setDropdownAvatarState,
} from "src/features/signin/imports/state";
import { emitImpactSignUpCompleted } from "src/features/signin/imports/utils";
import { sendReferralRegistrationRequest } from "src/features/signin/state/flows/login";
import {
  loginSuccess,
  phoneLoginAttemptLimitReached,
  phoneLoginMethodUnavailableForRegisteredUser,
  phoneLoginPhoneVerificationUnavailable,
  phoneLoginSetSmsResendDelay,
  phoneLoginVerificationFailed,
  phoneLoginVerificationRequired,
  phoneLoginVerificationRequiredSMSNotPossible,
} from "src/features/signin/state/login/actionCreators";

export const handleSuccessLogin = ({
  getState,
  dispatch,
  provider,
  onLoginSuccess,
  accountId,
  newRegistration,
  analyticsKey,
  anchorType,
  ...connectionManagerData
}) => {
  if (!newRegistration) {
    emitEvent(EventNames.TANGO_LOGIN);
    emitAF({
      eventName: EventNames.TANGO_LOGIN,
      eventCategory: EventCategory.LOGIN,
    });
  }

  if (!newRegistration && analyticsKey) {
    dispatch(updateAnalyticsKey({ analyticsKey }));
  }

  newRegistration && emitImpactSignUpCompleted(accountId);

  batch(() => {
    dispatch(
      loginSuccess({
        accountId,
        anchorType,
        newRegistration,
        connectionManagerData,
        provider,
        clientCapabilities: CLIENT_CAPABILITIES,
      })
    );
    dispatch(
      setDropdownAvatarState({
        isAvatarMenuVisible: false,
        shouldHideInstantly: true,
      })
    );
    dispatch(clearVisitorData());
    dispatch(fetchSessionDetails());
  });

  if (typeof onLoginSuccess === "function") {
    onLoginSuccess();
  }

  dispatch(attemptRegisterReferralUser());

  return {
    accountId,
    ...sendReferralRegistrationRequest(getState),
  };
};

export default (getState, dispatch, provider, onLoginSuccess) =>
  ({
    analyticsKey,
    loginResult,
    resendDelay,
    errorMessage,
    accountId,
    newRegistration,
    deliveryMethod,
    provider: codeProvider,
    ...connectionManagerData
  }) => {
    switch (loginResult) {
      case LoginResult.VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE:
        dispatch(phoneLoginVerificationRequiredSMSNotPossible());
      /* eslint-disable no-fallthrough */
      case LoginResult.VERIFICATION_REQUIRED:
        batch(() => {
          dispatch(
            phoneLoginVerificationRequired({ codeProvider, deliveryMethod })
          );
          if (typeof resendDelay === "number") {
            dispatch(phoneLoginSetSmsResendDelay(resendDelay));
          }
        });
        break;
      case LoginResult.METHOD_UNAVAILABLE_FOR_REGISTERED_USER:
        dispatch(phoneLoginMethodUnavailableForRegisteredUser());
        break;
      case LoginResult.METHOD_UNAVAILABLE:
        dispatch(phoneLoginPhoneVerificationUnavailable());
        break;
      case LoginResult.LIMIT_REACHED:
        dispatch(phoneLoginAttemptLimitReached(errorMessage));
        break;
      case LoginResult.WRONG_CODE:
        dispatch(phoneLoginVerificationFailed(errorMessage));
        break;
      case LoginResult.LOGGED_IN:
        handleSuccessLogin({
          getState,
          dispatch,
          provider,
          onLoginSuccess,
          accountId,
          newRegistration,
          analyticsKey,
          ...connectionManagerData,
        });
    }
  };
