import React, { useContext, useMemo } from "react";
import classnames from "classnames";
import animationSrc from "@animations/equalizer.json";
import LottieAnimation from "ui/common/lottie/LottieAnimation";
import MuteContext from "../MuteContext";
import styles from "./EqualizerAnimation.scss";

const baseLottieConfig = {
  renderer: "svg",
  path: animationSrc,
  loop: true,
} as const;

interface EqualizerAnimationProps {
  className?: string;
  animationClassName?: string;
  showWhenMuted?: boolean;
}

const EqualizerAnimation: React.FC<EqualizerAnimationProps> = ({
  className,
  animationClassName,
  showWhenMuted = false,
}) => {
  const { muted } = useContext(MuteContext);
  const lottieConfig = useMemo(
    () => ({
      ...baseLottieConfig,
      autoplay: !muted,
    }),
    [muted]
  );
  if (muted && !showWhenMuted) {
    return null;
  }
  return (
    <div
      className={classnames(styles.root, className)}
      data-testid="equalizer-animation"
    >
      <LottieAnimation
        lottieConfig={lottieConfig}
        className={classnames(styles.animation, animationClassName)}
      />
    </div>
  );
};

export default EqualizerAnimation;
