import { getPersonalGiftsHost, getProxycadorHost } from "environment";
import { toNumber } from "src/utils/toNumber";
import fetch from "./utils/enhancedFetch";

export const fetchAllAvailableGifts = ({ locale, version, includeSpecials }) =>
  fetch(
    `${getProxycadorHost()}/stickaloger/catalog/v4/gifts/drawer.proto?locale=${locale}&drawerVersion=${version}&include_special=${includeSpecials.join(
      ","
    )}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  ).then((resp) => resp.json());

export const fetchSingleGift = ({ locale, giftId }) =>
  fetch(
    `${getProxycadorHost()}/stickaloger/catalog/v4/gifts/single/${giftId}?locale=${locale}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  ).then((resp) => resp.json());

export const fetchGiftsBalance = () =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/balance/gifts`
  ).then((resp) => resp.json());

export const fetchDrawerVersion = () =>
  fetch(`${getProxycadorHost()}/proxycador/api/gifts/v1/drawerVersion`).then(
    (resp) => resp.json()
  );

export const fetchCollectedGifts = ({ accountId, giftIds }) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/gifts/v1/collectedGifts?accountId=${accountId}&giftIds=${giftIds.join(
      ","
    )}`
  ).then((resp) => resp.json());

export const fetchStatusGiftWithDiamonds = () =>
  fetch(`${getProxycadorHost()}/proxycador/api/gifts/v1/pointgift/status`).then(
    (resp) => resp.json()
  );

const sendGift = (body) =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/gifts/v1/send`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => resp.json());

const sendGiftAndMessage = (body) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/gifts/v1/chatGiftFromBc`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    }
  ).then((resp) => resp.json());

export const sendGiftToPost = ({ postId, giftId, authorId }) =>
  sendGift({
    receiverType: "POST",
    receiver: `${authorId}:${postId}`,
    giftId,
    receiverAccountId: authorId,
  });

export const sendGiftToProfile = ({ accountId, giftId }) =>
  sendGift({
    receiverType: "PROFILE",
    receiver: accountId,
    giftId,
    receiverAccountId: accountId,
  });

export const sendGiftFromBc = ({ accountId, giftId, message }) =>
  sendGiftAndMessage({
    receiver: accountId,
    giftId,
    message,
  });

export const sendGiftToStream = ({ streamId, giftId, streamerId, withPoint }) =>
  sendGift({
    receiverType: "STREAM",
    receiver: streamId,
    giftId,
    receiverAccountId: streamerId,
    ...(withPoint && { withPoint: true }),
  });

export const fetchUserBalance = () =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/gifts/v1/balance`)
    .then((resp) => resp.json())
    .then((resp) => {
      const {
        userBalance: { credits, points, referralPoints, totalPoints },
      } = resp;

      return {
        ...resp,
        userBalance: {
          ...resp.userBalance,
          credits: toNumber(credits),
          points: toNumber(points),
          referralPoints: toNumber(referralPoints),
          totalPoints: toNumber(totalPoints),
        },
      };
    });

export const fetchTotalCredits = () =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/gifts/v1/balance/totalCredits`
  )
    .then((resp) => resp.text())
    .then(Number.parseInt);

export const fetchTopSentGifts = ({ accountId, strategy = "USER_POPULAR" }) =>
  fetch(`${getProxycadorHost()}/stream/social/v2/topGifts`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify({ accountId, strategy }),
  }).then((resp) => resp.json());

export const fetchPersonalGifts = (streamerId) =>
  fetch(`${getPersonalGiftsHost()}/${streamerId}.json`, {
    headers: {
      "content-type": "application/json",
    },
    credentials: "omit",
  }).then((resp) => resp.json());

export const fetchCustomDrawer = ({ drawerId, version }) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/giftdrawers/myGiftdrawer?drawerId=${drawerId}&version=${version}`
  ).then((resp) => resp.json());
