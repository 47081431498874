import React, { memo, useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { DisplayName } from "chat/imports/components";
import { useDeleteChat, useProfileById, useUnmount } from "chat/imports/hooks";
import { Disclaimer } from "chat/messageRequest/components/disclaimer/Disclaimer";
import { DisclaimerForSender } from "chat/messageRequest/components/disclaimerForSender/DisclaimerForSender";
import { useBreakpointPrecise } from "chat/messageRequest/imports/hooks";
import { openDeleteBlockRequestModalHandler } from "chat/messageRequest/imports/state";
import {
  AccountInfo,
  Breakpoints,
  Nullable,
} from "chat/messageRequest/imports/types";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
  linkToMessageRequest,
  sharedMessages,
} from "chat/messageRequest/imports/uiCommon";
import { removeMessagesRequest } from "chat/messageRequest/state/asyncAction";
import { messageRequestSelectors } from "chat/messageRequest/state/selectors";
import { blockUserAction } from "src/state/actionCreators/blockedUsers";
import styles from "./DisclaimersArea.scss";

interface DisclaimersAreaProps {
  accountInfo?: AccountInfo;
  conversationId: string;
  isChatRequestDisclaimerShown: boolean;
  isCoinChatRequestDisclaimerShown: boolean;
}

export const DisclaimersArea = memo<DisclaimersAreaProps>(
  ({
    isChatRequestDisclaimerShown,
    accountInfo,
    conversationId,
    isCoinChatRequestDisclaimerShown,
  }) => {
    const dispatch = useDispatch();
    const breakpoint = useBreakpointPrecise();
    const [isBlocked, setIsBlocked] = useState(false);
    const [isBlockIsLoading, setIsBlockIsLoading] = useState(false);
    const [timeoutId, setTimeoutId] =
      useState<Nullable<ReturnType<typeof setTimeout>>>(null);
    const history = useHistory();

    const {
      account_id: accountId,
      first_name: firstName,
      last_name: lastName,
    } = accountInfo || {};
    const { basicProfile } = useProfileById(accountId, true);

    const { isLoadingDelete } = useSelector(messageRequestSelectors.getLoading);

    const isDesktop = breakpoint === Breakpoints.DESKTOP;

    const commonModalProperty = useMemo(
      () => ({
        isMobile: !isDesktop,
        dismissText: sharedMessages.cancel,
      }),
      [isDesktop]
    );
    const onClickDeleteCallback = useDeleteChat({
      accountInfo,
      conversationId,
    });

    const onClickBlockCallback = useCallback(() => {
      dispatch(
        openDeleteBlockRequestModalHandler({
          ...commonModalProperty,
          title: sharedMessages.blockUserTitle,
          body: (
            <FormattedMessage
              {...sharedMessages.blockUserQuestion}
              values={{
                name: (
                  <b>
                    <DisplayName
                      basicProfile={basicProfile}
                      firstName={firstName}
                      lastName={lastName}
                    />
                  </b>
                ),
              }}
            />
          ),
          confirmText: sharedMessages.blockUserTitle,
          confirm: async () => {
            setIsBlockIsLoading(true);

            dispatch(
              blockUserAction({
                accountId,
              })
            );

            setIsBlocked(true);

            const newTimeoutId = setTimeout(() => {
              dispatch(
                removeMessagesRequest({
                  conversationId,
                })
              );

              setIsBlocked(false);
              setTimeoutId(null);

              history.push(linkToMessageRequest);
            }, 2000);

            setTimeoutId(newTimeoutId);
            setIsBlockIsLoading(false);
          },
        })
      );
    }, [
      dispatch,
      commonModalProperty,
      accountInfo?.first_name,
      accountInfo?.account_id,
      conversationId,
      history,
      basicProfile,
      firstName,
      lastName,
    ]);

    useUnmount(() => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    });

    return (
      <>
        {(isCoinChatRequestDisclaimerShown || isChatRequestDisclaimerShown) && (
          <div
            className={classnames(styles.notificationRoot, styles[breakpoint])}
          >
            {isCoinChatRequestDisclaimerShown && <DisclaimerForSender />}
            {isChatRequestDisclaimerShown && !isBlocked && (
              <>
                <Disclaimer
                  accountInfo={accountInfo}
                  conversationId={conversationId}
                />
                <div className={styles.buttonsContainer}>
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    size={isDesktop ? ButtonSize.BIG_48 : ButtonSize.MEDIUM_32}
                    onClick={onClickBlockCallback}
                    dataTestId="chat-request-block-user-button"
                    disabled={isLoadingDelete || isBlockIsLoading}
                    className={styles.button}
                  >
                    <Typography
                      type={
                        isDesktop
                          ? TYPOGRAPHY_TYPE.HEADLINE4
                          : TYPOGRAPHY_TYPE.HEADLINE5
                      }
                    >
                      <FormattedMessage
                        id="confirmation.block"
                        defaultMessage="Block"
                      />
                    </Typography>
                  </Button>
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    size={isDesktop ? ButtonSize.BIG_48 : ButtonSize.MEDIUM_32}
                    onClick={onClickDeleteCallback}
                    dataTestId="chat-request-delete-user-button"
                    disabled={isLoadingDelete || isBlockIsLoading}
                    className={styles.button}
                  >
                    <Typography
                      type={
                        isDesktop
                          ? TYPOGRAPHY_TYPE.HEADLINE4
                          : TYPOGRAPHY_TYPE.HEADLINE5
                      }
                    >
                      <FormattedMessage
                        id="confirmation.delete"
                        defaultMessage="Delete"
                      />
                    </Typography>
                  </Button>
                </div>
              </>
            )}
          </div>
        )}

        {isBlocked && (
          <div
            className={styles.notificationRoot}
            data-testid="chat-request-block-title"
          >
            <Typography
              type={TYPOGRAPHY_TYPE.PARAGRAPH5}
              className={styles.notificationText}
            >
              <FormattedMessage
                id="you.blocked.this.user"
                defaultMessage="You blocked this user"
              />
            </Typography>
          </div>
        )}
      </>
    );
  }
);

DisclaimersArea.displayName = "ChatRequestDisclaimerArea";
