import React, { FC, memo } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import classnames from "classnames";
import { unsupportedMessages } from "chat/components/common/messages";
import { MessageConfig } from "./Message";
import styles from "./UnsupportedMessage.scss";

interface UnsupportedMessageProps {
  message?: MessageDescriptor;
  messageConfig: MessageConfig;
}

const UnsupportedMessage: FC<UnsupportedMessageProps> = ({
  message = unsupportedMessages.default_short,
  messageConfig,
}) => (
  <div
    className={classnames(styles.root, {
      [styles.myMessage]: messageConfig.isMyMessage,
    })}
  >
    <FormattedMessage
      {...message}
      values={{
        br: <br />,
      }}
    />
  </div>
);

export default memo(UnsupportedMessage);
