import React from "react";
import classNames from "classnames";
import { GiftVariant } from "src/features/mos/types";
import styles from "./MosGiftTypeLabel.scss";

interface MosGiftTypeLabel {
  typeAbbreviation: string;
  variant: GiftVariant;
}
export const MosGiftTypeLabel = ({
  typeAbbreviation,
  variant,
}: MosGiftTypeLabel) => (
  <div className={classNames(styles.label, styles[variant])}>
    {typeAbbreviation}
  </div>
);
