import { useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import { CredentialResponse, useGoogleOneTapLogin } from "@react-oauth/google";
import {
  DataDogErrorMessage,
  DatadogMessage,
} from "src/features/signin/imports/enums";
import {
  RootState,
  deviceInfoSelectors,
  getGoogleOneTapLogsEnabled,
  getIsGoogleOneTapEnabled,
  loginSelectors,
  marketingSelectors,
} from "src/features/signin/imports/state";
import { useMount } from "src/features/signin/imports/utils";
import { loginWithGoogleOneTap } from "src/features/signin/state/flows/loginWithGoogleOneTap";
import { loginHideInProgressFlag } from "src/features/signin/state/login/actionCreators";

const selector = (state: RootState) => ({
  isGoogleOneTapEnabled: getIsGoogleOneTapEnabled(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isWebview: deviceInfoSelectors.getWebview(state),
  analyticsKey: marketingSelectors.getAnalyticsKey(state),
  isLogsEnabled: getGoogleOneTapLogsEnabled(state),
});

const useGoogleOneTap = () => {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);

  const {
    isGoogleOneTapEnabled,
    isLoggedIn,
    isWebview,
    analyticsKey,
    isLogsEnabled,
  } = useSelector(selector, shallowEqual);

  const onSuccess = useCallback(
    (credentialResponse: CredentialResponse) => {
      dispatch(loginWithGoogleOneTap({ credentialResponse }));
    },
    [dispatch]
  );

  const onError = useCallback(() => {
    dispatch(loginHideInProgressFlag());
    datadogLogs.logger.error(DataDogErrorMessage.GOOGLE_ONE_TAP_FAILED);
  }, [dispatch]);

  const isDisabled =
    !isGoogleOneTapEnabled || isLoggedIn || isWebview || !isReady;

  useMount(() => {
    // Prevent the Google One Tap before isWebview is set
    setTimeout(() => {
      setIsReady(true);
    }, 0);
  });

  useGoogleOneTapLogin({
    onSuccess,
    onError,
    disabled: isDisabled,
    cancel_on_tap_outside: false,
    promptMomentNotification: (data) => {
      const notDisplayedReason = data.getNotDisplayedReason();

      if (isLogsEnabled && notDisplayedReason) {
        datadogLogs.logger.info(
          `${DatadogMessage.GOOGLE_ONE_TAP} - ${notDisplayedReason}`,
          {
            GoogleOneTapLogin: {
              isDisplayMoment: data.isDisplayMoment(),
              isDisplayed: data.isDisplayed(),
              isNotDisplayed: data.isNotDisplayed(),
              isSkippedMoment: data.isSkippedMoment(),
              isDismissedMoment: data.isDismissedMoment(),
              skippedReason: data.getSkippedReason(),
              dismissedReason: data.getDismissedReason(),
              momentType: data.getMomentType(),
              notDisplayedReason,
            },
            visitorId: analyticsKey,
          }
        );
      }
    },
  });
};

export default useGoogleOneTap;
