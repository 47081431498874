import type { AnyAction, Dispatch, Store } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchUserPreferences } from "api/preferences";
import { ONE_CLICK_GIFTING } from "src/constants";
import { AcmeServiceIdentifier, AcmeServiceName } from "src/enums";
// eslint-disable-next-line no-restricted-imports
import { ACME_RECEIVED } from "state/actionTypes";
import { RootState } from "state/delegate";
import { loadSettings } from "state/flows/settings";

export type SettingsServiceAcmeActionStore = {
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
} & Store<RootState>;

interface SettingsServiceAcmeAction {
  payload?: {
    data: {
      acmeId: string;
    };
    serviceIdentifier: string | undefined;
    serviceName: string | undefined;
  };
  type: string;
}

export const settingServiceAcmeMiddleware =
  (store: SettingsServiceAcmeActionStore) =>
  (next: Dispatch) =>
  async (action: SettingsServiceAcmeAction) => {
    if (action.type === ACME_RECEIVED) {
      if (!action.payload || !action.payload.data) {
        return next(action);
      }

      const { data } = action.payload;

      if (
        data.acmeId &&
        data.acmeId.startsWith(
          `${AcmeServiceName.PREFERENATOR}.${
            AcmeServiceIdentifier.SYNC_PREFERENCE
          }`
        )
      ) {
        const json = await fetchUserPreferences();

        const payload = { [ONE_CLICK_GIFTING]: json[ONE_CLICK_GIFTING] };

        store.dispatch(loadSettings(payload));

        return;
      }
    }

    next(action);
  };
