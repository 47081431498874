import isEmpty from "lodash.isempty";
import isEqual from "lodash/isEqual";
import { StorageKeys } from "src/core/analytics/dataMesh/common/enums";
import { startNewSession } from "src/core/analytics/dataMesh/eventMethods/startNewSession";
import { dataMeshParamsStorage } from "src/core/analytics/dataMesh/utils/dbMethods/dataMeshParamsStorage";
import { getSessionStartPayloadFromStore } from "src/core/analytics/dataMesh/utils/store/getSessionStartPayloadFromStore";
import { parseJson } from "src/core/analytics/imports/utils";

export const checkSessionStartEventPayload = async () => {
  const payloadString = await dataMeshParamsStorage.getItem(
    StorageKeys.SESSION_START_PAYLOAD
  );
  const savedSessionStartEventPayload = parseJson(payloadString || "{}");
  const currentSessionStartPayload = getSessionStartPayloadFromStore();

  if (
    !isEmpty(currentSessionStartPayload) &&
    !isEqual(savedSessionStartEventPayload, currentSessionStartPayload)
  ) {
    await startNewSession(true);
  }
};
