import {
  AppleRecoverTokenQueryParams,
  AutoLoginQueryParams,
} from "src/features/autoLogin/types/autoLoginQuery";

export const checkAutoLoginQueryParams = ({
  accountId,
  token,
}: AutoLoginQueryParams) => !!(accountId && token);

export const checkAppleRecoverTokenAutoLoginQueryParams = ({
  accountId,
  appleRecoverToken,
}: AppleRecoverTokenQueryParams) => !!(accountId && appleRecoverToken);
