import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import {
  GetUserHashedInfoResponse,
  getUserHashedInfo,
} from "src/features/marketing/api/marketing";
import { RootState } from "state/delegate";

export const loadUserHashedInfo = createAsyncThunk<
  GetUserHashedInfoResponse,
  void,
  { rejectValue: string; state: RootState }
>("lwc/marketing/fetch", async (_, api) => {
  try {
    return await getUserHashedInfo();
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
