import { TargetStreamType } from "broadcast/enums/premium";
import { StreamProtocol } from "broadcast/imports/enums";
import { getProxycadorHost } from "broadcast/imports/environments";
import {
  UpgradeStreamRequest,
  UpgradeStreamResponse,
} from "broadcast/imports/proto";
import { enhancedFetch } from "broadcast/imports/utils";
import { UpgradeStreamRequestParams } from "broadcast/types/types";

export interface UpgradePublicStreamRequestParams {
  encryptedStreamId: string;
  recipientAccountIds: string[];
  region: string;
  targetStreamType: TargetStreamType;
  ticketPriceInCredits: number | undefined;
}

export const upgradePublicStreamRequest = async (
  params: UpgradePublicStreamRequestParams
) => {
  const requestBody: UpgradeStreamRequestParams = {
    recipientAccountIds: params.recipientAccountIds,
    targetStreamType: params.targetStreamType,
    streamProtocol: StreamProtocol.RTMP,
    region: params.region,
  };

  if (params.ticketPriceInCredits) {
    requestBody.ticketPriceInCredits = params.ticketPriceInCredits;
    requestBody.restrictions = {
      gift: {
        ticketPriceInCredits: params.ticketPriceInCredits,
      },
    };
  }

  const response = await enhancedFetch(
    `${getProxycadorHost()}/stream/client/v2/stream/${params.encryptedStreamId}/upgrade`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
      body: UpgradeStreamRequest.encode(requestBody).finish(),
    }
  );

  const bufferResponse = await response.arrayBuffer();

  return UpgradeStreamResponse.decode(
    new Uint8Array(bufferResponse),
    undefined
  );
};
