import { ContentSharedPayloadMessage } from "chat/premiumMessage/types";
import { StoredMessage } from "chat/state";
import { ChatMessageSentFlags, MessageType } from "chat/types";
import { ContentSharedPayload } from "generated/proto/PremiumMessage";
import { getScreenNameByConversationId } from "src/features/chat/analytics/emitMessageTranslateEvent";
import {
  EventFields,
  EventNames,
  emitEvent,
} from "src/features/chat/imports/analytics";
import { parseMessageFromBase64 } from "utils/protobufUtil";

export enum AnchorType {
  DELETE_FOR_EVERYONE = "delete_for_everyone",
  DELETE_FOR_ME = "delete_for_me",
}

export enum MessageAction {
  CANCEL_DELETE_MESSAGE = "cancel_delete_message",
  CONFIRM_DELETE_MESSAGE = "confirm_delete_message",
  EDIT_MESSAGE_BUTTON = "edit_message_button",
  MESSAGE_COPY = "message_copy",
  MESSAGE_DELETE = "message_delete",
  MESSAGE_EDIT = "message_edit",
  MESSAGE_TRANSLATE = "message_translate",
}

export const getMessageFlag = (
  message: StoredMessage
): ChatMessageSentFlags => {
  switch (message.type) {
    case MessageType.TEXT_MESSAGE:
      return ChatMessageSentFlags.TEXT;

    case MessageType.IMAGE_MESSAGE:
    case MessageType.GIF_MESSAGE:
      return ChatMessageSentFlags.IMAGE;

    case MessageType.VIDEO_MESSAGE:
      return ChatMessageSentFlags.VIDEO;

    case MessageType.AUDIO_MESSAGE:
      return ChatMessageSentFlags.AUDIO;

    case MessageType.GIFT_IN_CHAT:
      return ChatMessageSentFlags.GIFT;

    case MessageType.PROFILE_MESSAGE:
      return ChatMessageSentFlags.PROFILE;

    case MessageType.PREMIUM_MESSAGE_SHARED: {
      const messagePayload = parseMessageFromBase64(
        message.payload,
        ContentSharedPayload
      ) as ContentSharedPayloadMessage;

      return Number(messagePayload.mediaInfo.duration) > 0
        ? ChatMessageSentFlags.VIDEO
        : ChatMessageSentFlags.IMAGE;
    }

    default:
      return ChatMessageSentFlags.TEXT;
  }
};

export const emitMessageActionEvent = ({
  chatId,
  action,
  anchorType,
  size,
  flag,
}: {
  action: MessageAction;
  anchorType?: AnchorType;
  chatId?: string;
  flag: ChatMessageSentFlags;
  size?: number;
}) => {
  emitEvent(EventNames.MESSAGE_ACTION, {
    [EventFields.ACTION]: action,
    [EventFields.TANGO_SCREEN]: getScreenNameByConversationId(chatId ?? ""),
    [EventFields.CHAT_ID]: chatId,
    [EventFields.FLAG]: flag,
    ...(size && { [EventFields.SIZE]: size }),
    ...(anchorType && {
      [EventFields.ANCHOR_TYPE]: anchorType,
    }),
  });
};
