import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { useSnackbar } from "notistack";
import useEmitLinkSharedEvent, {
  RESULT_CANCELLED,
  RESULT_SHARED,
} from "@analytics/emitLinkSharedEvent";
import { EventFields } from "@analytics/enums";
import { emitMomentHidden } from "@analytics/happyMoments/happyMomentsEvents";
import { GLOBAL } from "enums/modalScope";
import { Breakpoints, ModalType } from "src/enums";
import { Nullable } from "src/types/common";
import { Story, StoryType } from "src/types/happyMoments";
import { useUnmount } from "src/utils/miniReactUse";
import {
  getHappyMomentsSettingsEnabled,
  getHappyMomentsViralityEnabled,
} from "state/abTests";
import { openConfirmationModal } from "state/actionCreators/modal";
import { deleteStory } from "state/actionCreators/stories";
import { RootState } from "state/delegate";
import sendGift from "state/flows/sendGift";
import { userSelectors } from "state/selectors";
import { actionCreators as giftActionCreators } from "state/tree/giftingRequests";
import { FREE_LIKE_ID } from "state/tree/stories";
import { formatDisplayName } from "ui/common/DisplayName";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import usePrevious from "ui/hooks/usePrevious";
import useShare from "ui/hooks/useShare";
import useUiAction from "ui/hooks/useUiAction";
import MuteContext from "ui/scenes/stream/MuteContext";
import { createMomentViralityLink } from "ui/utils/viralityLinkUtils";
import { ReactComponent as DeleteIcon } from "img/ic_delete.svg";
import { ReactComponent as LikeIcon } from "img/ic_like.svg";
import { ReactComponent as LikeFilledIcon } from "img/ic_like_filled.svg";
import { ReactComponent as ShareIcon } from "img/ic_share_large.svg";
import { ReactComponent as SoundOffIcon } from "img/ic_sound_off.svg";
import { ReactComponent as SoundOnIcon } from "img/ic_sound_on.svg";
import styles from "./Actions.scss";

interface BaseActionProps {
  moment: Story;
}

export const { modalText, snack, viralityMessage } = defineMessages({
  modalText: {
    id: "confirm_story_delete_message",
    defaultMessage: "This moment will be deleted forever",
  },
  snack: {
    id: "happy_moments_failed_to_delete",
    defaultMessage: "Failed to delete this moment",
  },
  viralityMessage: {
    id: "happy-moments.virality.link.description",
    defaultMessage: "Check out {name} Happy moment on Tango!",
  },
});

const ShareAction = memo<BaseActionProps>(({ moment }) => {
  const intl = useIntl();
  const description = useMemo(
    () =>
      intl.formatMessage(viralityMessage, {
        name: formatDisplayName({
          intl,
          firstName: moment.streamerFirstName,
          lastName: moment.streamerLastName,
        }),
      }),
    [intl, moment.streamerFirstName, moment.streamerLastName]
  );

  const emitLinkSharedEvent = useEmitLinkSharedEvent({
    linkType: "story",
    linkTypeParam: moment.storyId,
    peerId: moment.streamerId,
    sessionId: undefined,
  });

  const link = useMemo(
    () => createMomentViralityLink(moment.storyId),
    [moment.storyId]
  );

  const afterShare = useCallback(
    () => emitLinkSharedEvent(link, RESULT_SHARED),
    [emitLinkSharedEvent, link]
  );

  const afterLinkCopied = useCallback(
    () => emitLinkSharedEvent(link),
    [emitLinkSharedEvent, link]
  );

  const onUnableToShare = useCallback(
    () => emitLinkSharedEvent(link, RESULT_CANCELLED),
    [emitLinkSharedEvent, link]
  );

  const share = useShare({
    title: description,
    link,
    afterShare,
    afterLinkCopied,
    onUnableToShare,
  });

  return (
    <button
      className={styles.action}
      onTouchStart={emptyFunction}
      onClick={share}
    >
      <ShareIcon />
      <FormattedMessage {...sharedMessages.share} />
    </button>
  );
});

ShareAction.displayName = "ShareAction";

const increment = "+1";

const LikeAction = memo<BaseActionProps>(({ moment }) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { likedByMe, likesCount = 0, storyId, streamerId } = moment;

  const likesCountNumber = Number(likesCount);

  const prevLiked = usePrevious(likedByMe);
  const [isLikeAnimationActive, setIsLikeAnimationActive] = useState(false);
  const timerRef = useRef<Nullable<number>>(null);
  const isLikeInProgress = useRef(false);

  const handleTransitionEnd = useCallback(() => {
    if (isLikeAnimationActive) {
      timerRef.current = window.setTimeout(() => {
        setIsLikeAnimationActive(false);
        timerRef.current = null;
      }, 1000);
    }
  }, [isLikeAnimationActive]);

  const handleClick = useUiAction({
    target: "happy_moment_free_like",
    additionalParams: {
      [EventFields.PEER_ID]: streamerId,
      [EventFields.GIFT_ID]: FREE_LIKE_ID,
    },
    callback: useCallback(async () => {
      if (isLikeInProgress.current || likedByMe) {
        return;
      }
      isLikeInProgress.current = true;
      const onError = () => {
        enqueueSnackbar(intl.formatMessage(sharedMessages.somethingWentWrong), {
          autoHideDuration: 1500,
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      };

      try {
        await dispatch(
          sendGift({
            giftId: FREE_LIKE_ID,
            type: StoryType.BY_STREAMER,
            isMobile: breakpoint !== Breakpoints.DESKTOP,
            // @ts-ignore TODO:types
            errorCallback: () => {
              isLikeInProgress.current = false;
              onError();
            },
          })
        );
        isLikeInProgress.current = false;
      } catch (e) {
        onError();
      }
    }, [storyId, likedByMe, intl, enqueueSnackbar, breakpoint, streamerId]),
  });

  useLayoutEffect(() => {
    if (likedByMe && prevLiked === false) {
      setIsLikeAnimationActive(true);

      return;
    }

    if (!likedByMe && isLikeAnimationActive) {
      setIsLikeAnimationActive(false);
      timerRef.current && window.clearTimeout(timerRef.current);
    }
  }, [likedByMe, prevLiked, isLikeAnimationActive]);

  useUnmount(() => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
    }
  });

  return (
    <button
      className={styles.action}
      onTouchStart={emptyFunction}
      onClick={handleClick}
      disabled={likedByMe}
    >
      <LikeIcon
        className={classnames(styles.like, likedByMe && styles.hidden)}
      />
      <LikeFilledIcon
        className={classnames(styles.filledLike, likedByMe && styles.visible)}
      />
      <span
        className={classnames(
          styles.likesCount,
          isLikeAnimationActive && styles.hidden
        )}
      >
        {isLikeAnimationActive ? likesCountNumber - 1 : likesCountNumber}
      </span>
      <span
        className={classnames(
          styles.increment,
          isLikeAnimationActive && styles.visible
        )}
        onTransitionEnd={handleTransitionEnd}
      >
        {increment}
      </span>
    </button>
  );
});

LikeAction.displayName = "LikeAction";

const SoundAction = memo(() => {
  const { muted, setMuted } = useContext(MuteContext);
  const toggleMute = useCallback(() => {
    setMuted(!muted);
  }, [setMuted, muted]);

  return (
    <button
      className={styles.action}
      onTouchStart={emptyFunction}
      onClick={toggleMute}
    >
      {muted ? (
        <>
          <SoundOnIcon />
          <FormattedMessage {...sharedMessages.happyMomentUnMute} />
        </>
      ) : (
        <>
          <SoundOffIcon />
          <FormattedMessage {...sharedMessages.happyMomentMute} />
        </>
      )}
    </button>
  );
});

SoundAction.displayName = "SoundAction";

const DeleteAction = memo<BaseActionProps>(({ moment }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { storyId, streamerId } = moment;

  const handleClick = useCallback(() => {
    dispatch(
      // @ts-ignore TODO: types
      openConfirmationModal({
        title: modalText,
        modalType: ModalType.DELETE_STORY_CONFIRMATION_MODAL,
        modalScope: GLOBAL,
        confirm: async () => {
          try {
            await dispatch(deleteStory(storyId));
            emitMomentHidden({ momentId: storyId, streamerId });
          } catch (e) {
            enqueueSnackbar(intl.formatMessage(snack), {
              variant: "error",
            });
          }
        },
      })
    );
  }, [storyId, streamerId, intl]);

  return (
    <button
      className={styles.action}
      onTouchStart={emptyFunction}
      onClick={handleClick}
    >
      <DeleteIcon />
      <FormattedMessage id="confirmation.delete" defaultMessage="Delete" />
    </button>
  );
});

DeleteAction.displayName = "DeleteAction";

interface ActionsProps extends BaseActionProps {
  breakpoint: Breakpoints;
  isActive: boolean;
}

const Actions: React.FC<ActionsProps> = ({ moment, isActive, breakpoint }) => {
  const dispatch = useDispatch();
  const { isShareEnabled, isDeleteEnabled } = useSelector(
    useCallback(
      (state: RootState) => ({
        isShareEnabled: getHappyMomentsViralityEnabled(state),
        isDeleteEnabled:
          userSelectors.getMyAccountId(state) === moment.streamerId &&
          getHappyMomentsSettingsEnabled(state),
      }),
      [moment.streamerId]
    ),
    shallowEqual
  );

  useEffect(() => {
    if (!isActive) {
      return;
    }
    dispatch(giftActionCreators.setHappyMomentsGiftingContext(moment));
  }, [isActive, moment.storyId]);

  return (
    <Typography
      type={TYPOGRAPHY_TYPE.HEADLINE6}
      className={classnames(styles.root, styles[breakpoint])}
    >
      {isDeleteEnabled && <DeleteAction moment={moment} />}
      <SoundAction />
      {isShareEnabled && <ShareAction moment={moment} />}
      <LikeAction moment={moment} />
    </Typography>
  );
};

export default memo(Actions);
