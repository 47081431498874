import { Effect, FS, FS as FSConstructor } from "@banuba/webar";
import {
  BROADCAST_MASKS_AVAILABLE_BEAUTY_FILTERS_MAP,
  BROADCAST_MASKS_DEFAULT_ALPHA,
} from "src/features/broadcastMasks/common/constants";
import { TangoBeautyType } from "src/features/broadcastMasks/common/enums";
import {
  BroadcastMasksBeautyStateValue,
  BroadcastMasksConfigMask,
} from "src/features/broadcastMasks/common/types";
import { BanubaPlayer } from "src/features/broadcastMasks/imports/classes";
import { Nullable } from "src/features/broadcastMasks/imports/types";
import { multiplyBeautyValue } from "src/features/broadcastMasks/utils/makeupUtils";

type ExtendedFS = {
  readFile: (...args: unknown[]) => string;
} & FS;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Face = any;

export const applyBeautyValues = (
  face: Face,
  beautyValues: BroadcastMasksBeautyStateValue
) => {
  for (const key in beautyValues) {
    const cfg = BROADCAST_MASKS_AVAILABLE_BEAUTY_FILTERS_MAP.get(
      key as TangoBeautyType
    );

    if (!cfg) {
      continue;
    }

    const [category, type] = cfg.path;

    face[category][type][cfg.property || key] = multiplyBeautyValue(
      key as TangoBeautyType,
      beautyValues[key]
    );
  }
};

export const applyMaskEffect = async (
  face: Face,
  FS: ExtendedFS,
  selectedMask: Nullable<BroadcastMasksConfigMask>,
  banubaPlayer: BanubaPlayer,
  signal?: AbortSignal
) => {
  if (!selectedMask) {
    delete face.tg_fx;

    return;
  }

  if (!banubaPlayer.loadedMasks.has(selectedMask.id)) {
    const fx = new Effect(
      new Request(selectedMask.bundleUrl, {
        signal,
      })
    );

    // @ts-ignore
    await fx._load();

    // @ts-ignore
    fx._resource.mount(
      // @ts-ignore
      new FSConstructor(banubaPlayer.player._sdk),
      selectedMask.id
    );

    banubaPlayer.loadedMasks.add(selectedMask.id);
  }

  const fxConfig = FS.readFile(`${selectedMask.id}/config.json`, {
    encoding: "utf8",
  });

  return JSON.parse(fxConfig);
};

export const mapBanubaColorToRgba = (banubaColor: number[]) =>
  `rgba(${banubaColor[0] * 255}, ${banubaColor[1] * 255}, ${banubaColor[2] * 255}, ${banubaColor[3] || BROADCAST_MASKS_DEFAULT_ALPHA})`;
