import { Conversation, PinnedChatInfo } from "chat/types";

export const getMissingPinnedConversationsIds = (
  allConversations: Conversation[],
  pinned: PinnedChatInfo[]
): string[] => {
  const result = pinned.map(({ conversationId }) => conversationId);

  allConversations.forEach(({ conversation: { conversation_id } }) => {
    if (result.includes(conversation_id)) {
      result.splice(result.indexOf(conversation_id), 1);
    }
  });

  return result;
};
