import { RootState } from "src/state/delegate";
import { selectors } from "src/state/tree/configuration";
import typedDelegateSelectors from "state/delegate";

const serverOwnedConfigSelectors = typedDelegateSelectors(
  selectors,
  "configuration"
);

export const getNSFWFromSOC = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(state, "tango.nsfw.main", "");

export const getAccessToPremiumFromSOC = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "tango.nsfw.premium",
    ""
  );
