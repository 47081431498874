import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { Nullable } from "chat//imports/types";
import { REACTIONS_LIST } from "chat/constants";
import { REACTION } from "chat/enums";
import { chatSelectors } from "chat/exports/state/selectors";
import { Typography } from "chat/imports/components";
import { HeroTypographyType } from "chat/imports/constants";
import { RootState } from "chat/imports/state";
import { sendMessageReaction } from "chat/state";
import { MessageIdentifier } from "chat/types";
import styles from "./MessageReactionList.scss";

interface MessageReactionsListProps {
  closeMenu: VoidFunction;
  conversationId: Nullable<string>;
  message: MessageIdentifier;
  messageSenderId: string | undefined;
}

const myReactionSelector =
  (conversationId: null | string, messageId: number) => (state: RootState) => ({
    myReactionId: chatSelectors.getMyMessageReaction(
      state,
      conversationId,
      messageId
    ),
  });

export const MessageReactionsList = ({
  conversationId,
  closeMenu,
  message,
  messageSenderId,
}: MessageReactionsListProps) => {
  const dispatch = useDispatch();
  const { myReactionId } = useSelector(
    myReactionSelector(conversationId, message.id)
  );

  const onReactionClickHandler = (reaction: REACTION) => () => {
    dispatch(
      sendMessageReaction({
        reactionId: reaction,
        deleteReaction: reaction === myReactionId,
        messageSenderId,
        message,
      })
    );

    closeMenu();
  };

  return (
    <div className={styles.root}>
      {REACTIONS_LIST.map((reaction) => (
        <button
          key={reaction}
          className={classnames(styles.reaction, {
            [styles.mySelectedReaction]: reaction === myReactionId,
          })}
          onClick={onReactionClickHandler(reaction)}
        >
          <Typography
            className={styles.reaction}
            type={HeroTypographyType.HERO4}
          >
            {reaction}
          </Typography>
        </button>
      ))}
    </div>
  );
};
