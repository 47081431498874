import React, { FC } from "react";
import { FormattedNumber } from "react-intl";

const SECONDS_IN_MINUTE = 60;
const COLON = ":";

interface FormattedAudioTimeProps {
  seconds: number;
}

const FormattedAudioTime: FC<FormattedAudioTimeProps> = ({ seconds }) => {
  const minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  const remainingSeconds = Math.floor(seconds % SECONDS_IN_MINUTE);

  return (
    <span>
      <FormattedNumber value={minutes} />
      {COLON}
      <FormattedNumber value={remainingSeconds} minimumIntegerDigits={2} />
    </span>
  );
};

export default FormattedAudioTime;
