import { StreamEvents } from "src/enums";
import { MediaGift } from "src/types/richFragment/RichGiftEvent";
import { SongIdType } from "src/ui/scenes/stream/chat/types";

export enum GiftKind {
  ANIMATED = "ANIMATED",
  STATIC = "STATIC",
  VIRTUAL = "VIRTUAL",
}

export enum SpecialKind {
  AI_GIFT = "AI_GIFT",
  AR_GIFT = "AR_GIFT",
  AVATAR_GIFT = "AVATAR_GIFT",
  GAME = "GAME",
  GIFT_TO_TOP = "GIFT_TO_TOP",
  GOODS = "GOODS",
  MEDIA = "MEDIA",
  PERSONAL = "PERSONAL",
  REFERRAL = "REFERRAL",
  STREAMER_AVATAR_GIFT = "STREAMER_AVATAR_GIFT",
  TANGO_CARD = "TANGO_CARD",
  WEB_ONLY = "WEB_ONLY",
}

export interface Gift {
  arAnimationUrlV2: string;
  arGiftDetails?: {
    animationUrl?: string;
    duration?: string;
  };
  assetBundle?: string;
  comboAnimationUrl?: string;
  creatorId?: string;
  drawerAnimationUrl?: string;
  enableTime?: number;
  encryptedGiftId?: string;
  expiryTime?: number;
  free?: boolean;
  giftKind: GiftKind;
  icon: string;
  id: string;
  lottieAnimationUrl?: string;
  lottieAnimationZipUrl?: string;
  movAnimation360Url?: string;
  movAnimation720Url?: string;
  mp4AnimationUrl?: string;
  name: string;
  nonStandardResolution: boolean;
  notCollectedIcon?: string;
  priceInCredit: number;
  priceInPoint: number;
  special?: SpecialKind;
  streamerId?: string;
  vipLevel: number;
  webmAnimation360Url?: string;
  webmAnimation720Url?: string;
  weight?: number;
  withdrawInPoint?: number;
}

export interface GiftEvent {
  accountId: string;
  bonusPercentage?: number;
  eventIds: number[];
  giftId: string;
  mediaGift: MediaGift;
  multiplier: number;
  percent: number;
  points: number;
  songId: SongIdType;
  type: StreamEvents;
  withPoint: boolean;
}
