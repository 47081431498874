import { initFlirtyAudience } from "src/features/marketing/api/marketing";
import {
  AdditionalDataPoints,
  cleanSearchParamsStorageByKeys,
  searchParamsStorage,
} from "src/features/marketing/imports";

export const handleInitFlirtyAudience = async () => {
  if (!(await searchParamsStorage.getItem(AdditionalDataPoints.MARKETING_FL))) {
    return;
  }

  initFlirtyAudience().finally(() => {
    cleanSearchParamsStorageByKeys([AdditionalDataPoints.MARKETING_FL]);
  });
};
