import { datadogLogs } from "@datadog/browser-logs";
import { VoidCallback } from "src/types/common";

class DatadogTransport {
  static availableToLogger(isAvailable: boolean, callback: VoidCallback) {
    if (isAvailable) {
      callback();
    }
  }

  static debug(message: string, messageContext?: object, error?: Error) {
    datadogLogs.logger.debug(message, messageContext, error);
  }

  static error(message: string, messageContext?: object, error?: Error) {
    datadogLogs.logger.error(message, messageContext, error);
  }

  static info(message: string, messageContext?: object, error?: Error) {
    datadogLogs.logger.info(message, messageContext, error);
  }

  static setGlobalContext(context: object) {
    datadogLogs.setGlobalContext(context);
  }

  static setGlobalContextProperty(
    key: Parameters<typeof datadogLogs.setGlobalContextProperty>[0],
    value: Parameters<typeof datadogLogs.setGlobalContextProperty>[1]
  ) {
    datadogLogs.setGlobalContextProperty(key, value);
  }

  static warn(message: string, messageContext?: object, error?: Error) {
    datadogLogs.logger.warn(message, messageContext, error);
  }
}

export default DatadogTransport;
