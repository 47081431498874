import { fetchVisitorProfileStreamByAccountId } from "src/features/profile/visitorProfile/api/visitorProfileApi";
import {
  BasicProfile,
  Gender,
} from "src/features/profile/visitorProfile/imports/types";
import { VisitorProfile } from "src/features/profile/visitorProfile/types";

export const toBasicProfile = (profile: VisitorProfile): BasicProfile => ({
  aliases: profile?.basic?.aliases || [],
  firstName: profile?.basic?.firstName || "",
  gender: Gender.NONE,
  lastName: profile?.basic?.lastName || "",
  profilePictureUrl: profile?.basic?.pictureUrl || "",
  profileThumbnailUrl: profile?.basic?.thumbnailUrl || "",
  verified: false,
});

export const loadStreamIdsByAccountIdsForVisitor = async (
  accountIds: string[]
): Promise<{ [key: string]: string }> => {
  const result: { [key: string]: string } = {};

  for (const accountId of accountIds) {
    try {
      result[accountId] = await fetchVisitorProfileStreamByAccountId(accountId);
    } catch (error) {
      result[accountId] = "";
    }
  }

  return result;
};
