import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BroadcastMasksViewType } from "src/features/broadcastMasks/common/enums";
import {
  BroadcastMasksBeautyStateValue,
  BroadcastMasksConfig,
  BroadcastMasksConfigMask,
  BroadcastMasksMakeupStateValue,
  BroadcastMasksMakeupStateValueItem,
} from "src/features/broadcastMasks/common/types";
import { BanubaPlayer } from "src/features/broadcastMasks/imports/classes";
import { Nullable } from "src/features/broadcastMasks/imports/types";

export interface BroadcastMasksState {
  beauty: BroadcastMasksBeautyStateValue;
  config: Nullable<BroadcastMasksConfig>;
  error: boolean;
  loading: boolean;
  loadingCompleted: boolean;
  makeup: BroadcastMasksMakeupStateValue;
  mobileView: Nullable<BroadcastMasksViewType>;
  player: Nullable<BanubaPlayer>;
  selectedMask: Nullable<BroadcastMasksConfigMask>;
}

export const initialState: BroadcastMasksState = {
  config: null,
  error: false,
  loading: false,
  loadingCompleted: false,
  selectedMask: null,
  player: null,
  mobileView: null,
  beauty: {},
  makeup: {},
};

export const persistConfig = {
  whitelist: ["selectedMask", "beauty", "makeup"],
};

const slice = createSlice({
  name: "broadcastMasks",
  initialState,
  reducers: {
    initPlayer: (state, action: PayloadAction<BanubaPlayer>) => {
      // Note: Banuba SDK returns a player instance with a typing error
      // @ts-ignore
      state.player = action.payload;
    },
    loadConfigStart: (state) => {
      state.loading = true;
      state.error = false;
      state.config = null;
    },
    loadConfigComplete: (
      state,
      action: PayloadAction<BroadcastMasksConfig>
    ) => {
      state.loading = false;
      state.loadingCompleted = true;
      state.error = false;

      state.config = action.payload;
    },
    loadConfigFailed: (state) => {
      state.loading = false;
      state.loadingCompleted = true;
      state.error = true;
      state.config = null;
    },
    selectMask: (
      state,
      action: PayloadAction<Nullable<BroadcastMasksConfigMask>>
    ) => {
      state.selectedMask = action.payload;
    },
    updateBeautyValue: (
      state,
      action: PayloadAction<{ key: string; value: number }>
    ) => {
      const { key, value } = action.payload;
      if (value === 0) {
        delete state.beauty[key];

        return;
      }
      state.beauty[key] = value;
    },
    updateMakeupValue: (
      state,
      action: PayloadAction<{
        id: string;
        value: Nullable<BroadcastMasksMakeupStateValueItem>;
      }>
    ) => {
      state.makeup[action.payload.id] = action.payload.value;
    },
    updateMobileView: (
      state,
      action: PayloadAction<BroadcastMasksViewType>
    ) => {
      state.mobileView = action.payload;
    },
    resetMakeup: (state) => {
      state.makeup = {};
    },
    resetBeauty: (state) => {
      state.beauty = { ...initialState.beauty };
    },
    resetMobileView: (state) => {
      state.mobileView = null;
    },
    resetPlayer: (state) => {
      state.player = null;
    },
  },
});

export const {
  loadConfigStart,
  loadConfigFailed,
  loadConfigComplete,
  selectMask,
  initPlayer,
  updateBeautyValue,
  updateMakeupValue,
  updateMobileView,
  resetMakeup,
  resetBeauty,
  resetMobileView,
  resetPlayer,
} = slice.actions;

export const broadcastMasksReducer = slice.reducer;
