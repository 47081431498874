import {
  EventFields,
  SCREEN_NAME,
  UIActionTarget,
  emitUiAction,
} from "chat/imports/analytics";

export const emitMoreButtonClicked = ({ chatId }: { chatId: string }) => {
  emitUiAction({
    [EventFields.TARGET]: UIActionTarget.MORE,
    [EventFields.TANGO_SCREEN]: SCREEN_NAME.CHAT_SINGLE,
    additionalParams: {
      [EventFields.CHAT_ID]: chatId,
    },
  });
};
