import { createReducer } from "@reduxjs/toolkit";
import {
  blockUserAction,
  loadBlockedUsers,
  unblockUserAction,
} from "src/state/actionCreators/blockedUsers";
import { ACME_RECEIVED } from "src/state/actionTypes";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";

export type BlockedUsersState = AsyncState<{
  accountIds: string[];
}>;

const blockedUsersInitialState = {
  data: {
    accountIds: [],
  },
  meta: initialFetcherStateMeta,
};

const blockedUsers = createReducer<BlockedUsersState>(
  blockedUsersInitialState,
  (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        initialData: blockedUsersInitialState.data,
        builder,
        action: loadBlockedUsers,
        prepareData: (_, newData) => ({ accountIds: newData }),
      })
        .addCase(blockUserAction.fulfilled, (state, action) => {
          if (action.payload) {
            state.data.accountIds.push(action.payload);
          }
        })
        .addCase(unblockUserAction.fulfilled, (state, action) => {
          state.data.accountIds = state.data.accountIds.filter(
            (accountId) => accountId !== action.payload
          );
        })
        .addMatcher(
          (action) => action.type === ACME_RECEIVED,
          (state, action) => {
            const { serviceName, serviceIdentifier } = action.payload;

            if (
              serviceIdentifier === "syncBlockList" &&
              serviceName === "abregistrar"
            ) {
              state.meta.stale = true;
            }

            return state;
          }
        ),
      () => blockedUsersInitialState
    );
  }
);

export default blockedUsers;
