import { StorageKeys } from "src/core/analytics/dataMesh/common/enums";
import { dataMeshParamsStorage } from "src/core/analytics/dataMesh/utils/dbMethods/dataMeshParamsStorage";
import { getSessionStartParams } from "src/core/analytics/dataMesh/utils/dbMethods/getSessionStartParams";
import { getAnalyticsSessionDurationInMs } from "src/core/analytics/imports/environment";

const addTimeToDate = (date: Date, time: number) =>
  new Date(date.getTime() + time);

export const updateSessionStartDate = async () => {
  const clientSessionId = (await getSessionStartParams())[
    StorageKeys.SESSION_START_ID
  ];

  const sessionStartParams = {
    [StorageKeys.SESSION_START_ID]: clientSessionId,
    [StorageKeys.SESSION_START_TIME]: addTimeToDate(
      new Date(),
      getAnalyticsSessionDurationInMs()
    ).getTime(),
  };

  await dataMeshParamsStorage.setItem(
    StorageKeys.SESSION_START_PARAMS,
    JSON.stringify(sessionStartParams)
  );
};
