import isEmpty from "lodash.isempty";
import merge from "lodash.merge";
import { deadStreamStatuses, livingStreamStatuses } from "src/constants";
import { StreamSessionInitializationResult } from "src/enums";
import parseLiveStreamsFeed, {
  allFollowingsRecommendations,
} from "src/state/tree/utils/parseLiveStreamsFeed";
import { StreamKind, StreamStatus } from "src/types/richFragment/Stream";
import { ensureHttps } from "src/utils/ensureHttps";
import { invert, mapValues, uniq } from "src/utils/miniLodash";
import ensurePremiumStreamListUrl from "src/utils/stream/streamsCacheUtils";
import { fetchAccountToStreamMap } from "state/actionCreators/userToStream";
// eslint-disable-next-line no-restricted-imports
import {
  ACME_RECEIVED,
  CLEAR_LIVE_STREAM_VOLUME,
  LIVE_POSTS_END_FETCH,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  LIVE_STREAM_FETCH_BY_ACCOUNT_ID,
  LIVE_STREAM_FETCH_BY_ACCOUNT_IDS,
  LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED,
  TOP_BROADCASTERS_END_FETCH,
  UPDATE_BATCH_LIVE_STREAM_VOLUME,
  UPDATE_LIVE_STREAM_BATCH_STATUSES,
  UPDATE_LIVE_STREAM_INFO,
  UPDATE_LIVE_STREAM_SETTINGS,
  UPDATE_LIVE_STREAM_VOLUME,
  VIEWER_SESSION_INITIALIZATION_END,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_UPDATE,
} from "state/actionTypes";

const mapCommandToStatus = {
  resumed: StreamStatus.LIVING,
  suspended: StreamStatus.SUSPENDED,
  terminated: StreamStatus.TERMINATED,
};

const UPGRADED_COMMAND = "streamUpgraded";

const knownCommands = Object.keys(mapCommandToStatus);

const updateBroadcastersPatch = (
  broadcasters,
  newStatus,
  broadcasterId,
  streamId,
  patch
) => {
  if (
    newStatus === StreamStatus.LIVING &&
    broadcasters[broadcasterId] !== streamId
  ) {
    patch[broadcasterId] = streamId;
  }
  if (
    broadcasters[broadcasterId] === streamId &&
    newStatus !== StreamStatus.LIVING
  ) {
    patch[broadcasterId] = "";
  }
};

const patchBroadcasters = (
  broadcasters,
  newStatus,
  broadcasterId,
  streamId
) => {
  const patch = {};
  updateBroadcastersPatch(
    broadcasters,
    newStatus,
    broadcasterId,
    streamId,
    patch
  );
  if (!isEmpty(patch)) {
    return {
      ...broadcasters,
      ...patch,
    };
  }

  return broadcasters;
};

const mergeStatus = (oldStatus, newStatus) => {
  if (
    newStatus === StreamStatus.EXPIRED ||
    oldStatus === StreamStatus.EXPIRED
  ) {
    return StreamStatus.EXPIRED;
  }

  return oldStatus !== StreamStatus.TERMINATED ? newStatus : oldStatus;
};

const ensureHttpsOnStream = ({ thumbnail, relatedStreams, ...rest }) => ({
  ...rest,
  ...(thumbnail && { thumbnail: ensureHttps(thumbnail) }),
  ...(relatedStreams && {
    relatedStreams: relatedStreams.map(({ thumbnail, ...rest }) => ({
      thumbnail: ensureHttps(thumbnail),
      ...rest,
    })),
  }),
});

const mergeStreams = (streams, patch) =>
  merge({}, streams, mapValues(patch, ensureHttpsOnStream));

const mergeLivingStreams = ({ state, settings, ...streams }) => {
  const broadcastersOfLivingStreams = Object.values(streams).reduce(
    (accum, { id, broadcasterId, status }) =>
      patchBroadcasters(accum, status, broadcasterId, id),
    state.broadcasters
  );

  return {
    ...state,
    streams: mergeStreams(state.streams, streams),
    broadcasters: broadcastersOfLivingStreams,
    settings: {
      ...state.settings,
      ...settings,
    },
  };
};

export default (
  state = { streams: {}, broadcasters: {}, settings: {}, volume: {} },
  action
) => {
  switch (action.type) {
    case LIVE_POSTS_END_FETCH: {
      if (!action.error) {
        // TODO: Remove old streams logic with entities after all backend endpoints are updated
        // Jira ticket: https://tango-me.atlassian.net/browse/WEB-4575
        if (
          action.meta.mode &&
          allFollowingsRecommendations.includes(action.meta.mode)
        ) {
          const streams = parseLiveStreamsFeed(
            action.payload,
            action.meta,
            true
          );

          return mergeLivingStreams({ state, ...streams });
        }
        const { stream: streams, settings = {} } = action.payload.entities;

        return mergeLivingStreams({ state, settings, ...streams });
      }
      break;
    }
    case LIVE_STREAM_FETCH_BY_ACCOUNT_ID: {
      if (action.error) {
        break;
      }

      const {
        payload: { records },
        meta: { accountId },
      } = action;

      if (!records || !records.length) {
        break;
      }

      const { stream, moderationLevel } = records[0];

      return {
        ...state,
        streams: mergeStreams(state.streams, {
          [stream.id]: {
            ...stream,
            moderationLevel,
            kind: stream.kind || stream.streamKind,
            ...ensurePremiumStreamListUrl({
              streams: state.streams,
              updatedStream: stream,
            }),
          },
        }),
        broadcasters: { ...state.broadcasters, [accountId]: stream.id },
      };
    }
    case LIVE_STREAM_FETCH_BY_ACCOUNT_IDS: {
      if (action.error) {
        break;
      }

      const {
        payload: { records },
      } = action;

      if (!records || !records.length) {
        break;
      }

      const { streams, broadcasters } = records.reduce(
        (acc, { stream, moderationLevel }) => ({
          streams: {
            ...acc.streams,
            [stream.id]: {
              ...stream,
              moderationLevel,
              kind: stream.kind || stream.streamKind,
              ...ensurePremiumStreamListUrl({
                streams: state.streams,
                updatedStream: stream,
              }),
            },
          },
          broadcasters: {
            ...acc.broadcasters,
            [stream.encryptedAccountId]: stream.id,
          },
        }),
        { streams: [], broadcasters: {} }
      );

      return {
        ...state,
        streams: mergeStreams(state.streams, streams),
        broadcasters: { ...state.broadcasters, ...broadcasters },
      };
    }
    case LIVE_RICH_NOTIFICATION_RECEIVED: {
      const { streamId, viewerCount, upgradedStream, upgradedStreamId } =
        action.payload;
      if (viewerCount === undefined && !upgradedStreamId) {
        return state;
      }
      const patch = {};
      if (viewerCount !== undefined) {
        patch[streamId] = { viewerCount };
      }
      if (upgradedStreamId) {
        patch[upgradedStreamId] = {
          ...upgradedStream,
          kind: StreamKind.TICKET_PRIVATE,
        };
        patch[streamId] = { status: StreamStatus.TERMINATED };
      }

      return {
        ...state,
        streams: mergeStreams(state.streams, patch),
        broadcasters: upgradedStream
          ? patchBroadcasters(
              state.broadcasters,
              upgradedStream.status,
              upgradedStream.broadcasterId,
              upgradedStream.id
            )
          : state.broadcasters,
      };
    }
    case TOP_BROADCASTERS_END_FETCH: {
      if (action.error) {
        return state;
      }
      const {
        entities: { liveStreams },
      } = action.payload;
      if (isEmpty(liveStreams)) {
        return state;
      }
      const filteredLiveStreams = Object.keys(liveStreams)
        .filter((accountId) => {
          const streamId = liveStreams[accountId];
          const cachedStream = state.streams[streamId];

          return (
            !cachedStream || !deadStreamStatuses.includes(cachedStream.status)
          );
        })
        .reduce((a, accountId) => {
          a[accountId] = liveStreams[accountId];

          return a;
        }, {});

      if (isEmpty(filteredLiveStreams)) {
        return state;
      }

      const cashedLiveStreams = Object.values(filteredLiveStreams).reduce(
        (a, streamId) => {
          a[streamId] = { id: streamId, kind: StreamKind.PUBLIC };

          return a;
        },
        {}
      );

      return {
        ...state,
        broadcasters: { ...state.broadcasters, ...filteredLiveStreams },
        streams: mergeStreams(state.streams, cashedLiveStreams),
      };
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }

      const {
        detail: {
          anchor: { encryptedAccountId: broadcasterId } = {},
          restrictions,
          redirectStreamId,
          stream: {
            streamKind: kind,
            startTime,
            id,
            status,
            title,
            thumbnail,
          } = {},
          viewerCount,
        } = {},
        entities: { settings } = {},
      } = action.payload;

      const streamPatch = {};
      let broadcasters = state.broadcasters;

      if (redirectStreamId) {
        streamPatch[redirectStreamId] = { id: redirectStreamId };
      }

      if (id) {
        const existingStream = state.streams[id];
        const newStatus = existingStream
          ? mergeStatus(existingStream.status, status)
          : status;

        streamPatch[id] = {
          id,
          broadcasterId,
          restrictions,
          kind,
          startTime,
          status: newStatus,
          title,
          thumbnail,
          viewerCount,
        };

        broadcasters = patchBroadcasters(
          broadcasters,
          newStatus,
          broadcasterId,
          id
        );
      }

      if (!Object.keys(streamPatch).length && !settings) {
        return state;
      }

      const newStreams = Object.keys(streamPatch).length
        ? mergeStreams(state.streams, streamPatch)
        : state.streams;

      const newSettings = settings
        ? { ...state.settings, ...settings }
        : state.settings;

      return {
        ...state,
        streams: newStreams,
        broadcasters,
        settings: newSettings,
      };
    }
    case UPDATE_LIVE_STREAM_BATCH_STATUSES: {
      const streamToStatusMap = action.payload;
      const streamsInfo = (action.meta && action.meta.streamsInfo) || {};
      const streamsPatch = Object.keys(streamToStatusMap)
        .map((id) => {
          const status = streamToStatusMap[id];
          const existingStream = state.streams[id] || {};
          const additionalStreamInfo = streamsInfo[id] || {};

          return {
            id,
            ...existingStream,
            ...additionalStreamInfo,
            status: mergeStatus(existingStream.status, status),
          };
        })
        .reduce((a, x) => {
          a[x.id] = x;

          return a;
        }, {});
      const broadcastersPatch = {};
      const invertedBroadcasters = invert(state.broadcasters);
      Object.keys(streamToStatusMap).forEach((streamId) => {
        const broadcasterId =
          invertedBroadcasters[streamId] ||
          (streamsInfo[streamId] || {}).broadcasterId;
        if (broadcasterId) {
          updateBroadcastersPatch(
            state.broadcasters,
            streamToStatusMap[streamId],
            broadcasterId,
            streamId,
            broadcastersPatch
          );
        }
      });

      return {
        ...state,
        streams: mergeStreams(state.streams, streamsPatch),
        broadcasters: {
          ...state.broadcasters,
          ...broadcastersPatch,
        },
      };
    }
    case VIEWER_SESSION_UPDATE:
    case UPDATE_LIVE_STREAM_INFO: {
      if (!action.error) {
        const {
          basicInfo,
          isPublic,
          settings: streamSettings,
        } = action.payload;
        if (basicInfo) {
          let mergedObject = { ...basicInfo };
          const existingObject = state.streams[basicInfo.id];
          if (
            isPublic !== undefined &&
            existingObject?.kind !== StreamKind.CHAT
          ) {
            mergedObject.kind = isPublic
              ? StreamKind.PUBLIC
              : StreamKind.TICKET_PRIVATE;
          }
          if (existingObject) {
            mergedObject = {
              ...existingObject,
              ...mergedObject,
              status: mergeStatus(existingObject.status, basicInfo.status),
            };
          }
          let settings = state.settings;
          if (streamSettings) {
            settings = {
              ...settings,
              [basicInfo.id]: streamSettings,
            };
          }

          return {
            ...state,
            streams: mergeStreams(state.streams, {
              [basicInfo.id]: mergedObject,
            }),
            broadcasters: patchBroadcasters(
              state.broadcasters,
              mergedObject.status,
              mergedObject.broadcasterId,
              mergedObject.id
            ),
            settings,
          };
        }
      }
      break;
    }
    case LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED: {
      const {
        payload: { stream },
      } = action;
      const previousStreamId = state.broadcasters[stream.broadcasterId];

      return {
        ...state,
        streams: merge({}, state.streams, {
          [stream.id]: stream,
          ...(previousStreamId && {
            [previousStreamId]: { status: StreamStatus.TERMINATED },
          }),
        }),
        broadcasters: {
          ...state.broadcasters,
          [stream.broadcasterId]: stream.id,
        },
      };
    }
    case VIEWER_SESSION_INITIALIZATION_END: {
      const {
        payload: result,
        meta: { streamId, source },
      } = action;
      if (
        result === StreamSessionInitializationResult.SUCCESS &&
        source === "tc"
      ) {
        return {
          ...state,
          streams: merge({}, state.streams, {
            [streamId]: { kind: StreamKind.CHAT, id: streamId },
          }),
        };
      }

      return state;
    }
    case ACME_RECEIVED: {
      const { serviceName, serviceIdentifier } = action.payload;
      if (serviceName === "stream") {
        const [command, id, newId] = serviceIdentifier.split(":");
        const existingStreamObject = state.streams[id];
        if (command === UPGRADED_COMMAND) {
          if (newId) {
            const newStreamObject = {
              ...existingStreamObject,
              id: newId,
              status: StreamStatus.LIVING,
            };

            return {
              ...state,
              streams: { ...state.streams, [newId]: newStreamObject },
              broadcasters: {
                ...state.broadcasters,
                [existingStreamObject.broadcasterId]: newId,
              },
            };
          }
        } else {
          if (knownCommands.includes(command)) {
            if (existingStreamObject) {
              const newStatus = mergeStatus(
                existingStreamObject.status,
                mapCommandToStatus[command]
              );
              if (existingStreamObject.status !== newStatus) {
                return {
                  ...state,
                  streams: {
                    ...state.streams,
                    [id]: { ...existingStreamObject, status: newStatus },
                  },
                  broadcasters: patchBroadcasters(
                    state.broadcasters,
                    newStatus,
                    existingStreamObject.broadcasterId,
                    existingStreamObject.id
                  ),
                };
              }
            } else {
              return {
                ...state,
                streams: {
                  ...state.streams,
                  [id]: { status: mapCommandToStatus[command] },
                },
              };
            }
          }
        }
      }
      break;
    }
    case UPDATE_LIVE_STREAM_SETTINGS: {
      const {
        payload: settings,
        meta: { streamId },
      } = action;

      return {
        ...state,
        settings: {
          ...state.settings,
          [streamId]: settings,
        },
      };
    }
    case UPDATE_LIVE_STREAM_VOLUME: {
      const {
        payload: volume,
        meta: { streamId },
      } = action;

      return {
        ...state,
        volume: {
          ...state.volume,
          [streamId]: volume,
        },
      };
    }
    case UPDATE_BATCH_LIVE_STREAM_VOLUME: {
      const {
        payload: volume,
        meta: { streamIds },
      } = action;

      const streams = {};

      streamIds.forEach((streamId) => {
        streams[streamId] = volume;
      });

      return {
        ...state,
        volume: {
          ...state.volume,
          ...streams,
        },
      };
    }
    case CLEAR_LIVE_STREAM_VOLUME: {
      return {
        ...state,
        volume: {},
      };
    }
    case fetchAccountToStreamMap.fulfilled.type: {
      const { payload } = action;

      return {
        ...state,
        broadcasters: {
          ...state.broadcasters,
          ...payload,
        },
      };
    }
  }

  return state;
};

const filterByKeyInCollection = (stream, key, valuesCollection) =>
  !valuesCollection ||
  !valuesCollection.length ||
  valuesCollection.includes(stream[key]);
const filterByPresenceInCollection = (collection) =>
  !collection || !collection.length ? (x) => x : (x) => collection.includes(x);

export const selectors = {
  getStreamById: (state, id) => state.streams[id],
  getStreamsByIds: (state, ids) => ids.map((id) => state.streams[id]),
  getStreamStatus: (state, id) =>
    state.streams[id]?.status || StreamStatus.UNKNOWN,
  getStreamByBroadcasterId: (state, id) => {
    const streamId = state.broadcasters[id];

    return state.streams[streamId];
  },
  getStreamsByBroadcasterIds: (state, ids) =>
    ids.map((id) => state.streams[state.broadcasters[id]]),
  getStreamSettingsById: (state, id) => state.settings[id],
  getStreamVolumeById: (state, id) => state.volume[id],
  getLivingStreamIdOfBroadcaster: (state, id) => state.broadcasters[id],
  getLivingStreamIdsOfBroadcasters: (state, ids) => {
    const streamIds = ids.reduce((acc, id) => {
      if (state.broadcasters[id]) {
        acc.push(state.broadcasters[id]);
      }

      return acc;
    }, []);

    return streamIds.length ? streamIds : undefined;
  },
  getLivingPublicStreamIdOfBroadcaster: (state, id) => {
    // treats unknowns as public
    const streamId = state.broadcasters[id];
    if (!streamId) {
      return streamId;
    }

    const cachedStream = state.streams[streamId];
    if (!cachedStream) {
      return streamId;
    }

    return cachedStream.kind === StreamKind.PUBLIC ? streamId : "";
  },
  getLivingPublicOrPrivateStreamIdOfBroadcaster: (state, id) => {
    const streamId = state.broadcasters[id];

    if (!streamId) {
      return "";
    }

    const streamDetails = state.streams[streamId];

    if (
      !streamDetails ||
      (streamDetails.kind !== StreamKind.PUBLIC &&
        streamDetails.kind !== StreamKind.TICKET_PRIVATE)
    ) {
      return "";
    }

    return streamId;
  },
  getKnownLivingBroadcastersToStreamIdMap: (state) => state.broadcasters,
  getAllKnownLivingStreamIds: (
    state,
    { includeStreamIds, includeAccountIds } = {}
  ) => {
    const fromStreamsCache = Object.values(state.streams)
      .filter(
        (x) =>
          (livingStreamStatuses.includes(x.status) ||
            x.status === StreamStatus.UNKNOWN) &&
          filterByKeyInCollection(x, "id", includeStreamIds) &&
          filterByKeyInCollection(x, "broadcasterId", includeAccountIds)
      )
      .map((x) => x.id);
    const fromBroadcastersMap = Object.keys(state.broadcasters)
      .filter(filterByPresenceInCollection(includeAccountIds))
      .map((x) => state.broadcasters[x])
      .filter(filterByPresenceInCollection(includeStreamIds));

    return uniq([...fromBroadcastersMap, ...fromStreamsCache]);
  },
};
