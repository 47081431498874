import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  BattleSourceId,
  MultiStreamInviteStreamType,
} from "broadcast/enums/analytics";
import {
  EventFields,
  EventNames,
  emitEvent,
} from "broadcast/imports/analytics";
import {
  RootState,
  batchLoadProfiles,
  broadcastExternalSelectors,
  broadcastSelectors,
  userSelectors,
} from "broadcast/imports/state";
import { isPublicBroadcast } from "broadcast/imports/utils";
import { InvitationType } from "src/features/broadcast/enums/invites";
import {
  useCompetitionState,
  useLivePartyOtherStreamsData,
  useModeratedOtherStreamsData,
} from "src/features/broadcast/imports/hooks";
import { invitationsSelectors } from "src/features/broadcast/state/invitationsSelectors";
import {
  removeAcceptedInvitationsForActiveStreams,
  resetInvitationsByType,
  setInvitationFlow,
} from "src/features/broadcast/state/invitationsSlice";

const MAX_ACTIVE_STREAMS = 3;
const MAX_WAITING_TIME_MS = 60000;

const selector = (activeStreamsCount: number) => (state: RootState) => {
  const broadcastId = broadcastSelectors.broadcastId(state);
  const invitationFlow = invitationsSelectors.getInvitationFlow(state);

  const acceptedRecipientIds =
    broadcastId && invitationFlow
      ? invitationsSelectors.getAcceptedInvitationsRecipients(
          state,
          broadcastId,
          invitationFlow
        )
      : [];

  const sentRecipientIds =
    broadcastId && invitationFlow
      ? invitationsSelectors.getSentInvitationsRecipients(
          state,
          broadcastId,
          invitationFlow,
          Math.max(acceptedRecipientIds.length, activeStreamsCount)
        )
      : [];

  return {
    broadcastId,
    sentRecipientIds,
    acceptedRecipientIds,
    invitationFlow,
    accountId: userSelectors.getMyAccountId(state),
    broadcastExternalStatus:
      broadcastExternalSelectors.getBroadcastExternalStatus(state),
    broadcastKind: broadcastSelectors.broadcastKind(state),
  };
};

export const useCompetitionInvitationsData = () => {
  const dispatch = useDispatch();
  const unsafeOtherStreams = useLivePartyOtherStreamsData();
  const otherStreams = useModeratedOtherStreamsData(unsafeOtherStreams);
  const { isCompetitionStarted } = useCompetitionState();
  const {
    sentRecipientIds,
    acceptedRecipientIds,
    broadcastId,
    accountId,
    invitationFlow,
    broadcastKind,
  } = useSelector(selector(otherStreams.length), shallowEqual);

  const acceptedWithoutStreams = acceptedRecipientIds.filter(
    (acceptedId) =>
      !otherStreams.some((stream) => stream.accountId === acceptedId)
  );

  const activeCount = otherStreams.length + acceptedWithoutStreams.length;

  useEffect(() => {
    if (isCompetitionStarted && broadcastId) {
      dispatch(
        resetInvitationsByType({
          broadcastId,
          invitationType: InvitationType.COMPETITION,
        })
      );
      dispatch(setInvitationFlow(null));
    }

    if (sentRecipientIds.length) {
      dispatch(
        batchLoadProfiles({
          ids: sentRecipientIds,
          loadOnlyIfMissing: true,
        })
      );
    }
  }, [sentRecipientIds.length, dispatch, isCompetitionStarted, broadcastId]);

  useEffect(() => {
    if (broadcastId && otherStreams.length > 0) {
      const streamAccountIds = otherStreams.map((s) => s.accountId);
      const hasAcceptedInvitationsInStreams = acceptedRecipientIds.some((id) =>
        streamAccountIds.includes(id)
      );

      if (hasAcceptedInvitationsInStreams) {
        dispatch(
          removeAcceptedInvitationsForActiveStreams({
            broadcastId,
            activeStreamsIds: streamAccountIds,
            invitationType: InvitationType.COMPETITION,
          })
        );
      }
    }
  }, [acceptedRecipientIds, broadcastId, dispatch, otherStreams]);

  useEffect(() => {
    if (isCompetitionStarted) {
      emitEvent(EventNames.COMPETITION_STARTED, {
        [EventFields.LOGGED_IN_ACCOUNT_ID]: accountId,
        [EventFields.MEMBERS_CNT]: otherStreams.length,
        [EventFields.STREAM_ID]: broadcastId,
        [EventFields.STREAM_TYPE]: isPublicBroadcast(broadcastKind)
          ? MultiStreamInviteStreamType.PUBLIC
          : MultiStreamInviteStreamType.PREMIUM,
        [EventFields.SHARE_SOURCE_ID]: invitationFlow
          ? BattleSourceId.SINGLE
          : BattleSourceId.LP,
      });
    }
  }, [isCompetitionStarted]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (
      sentRecipientIds.length &&
      !acceptedRecipientIds.length &&
      !otherStreams.length &&
      broadcastId
    ) {
      timeoutId = setTimeout(() => {
        dispatch(
          resetInvitationsByType({
            broadcastId,
            invitationType: InvitationType.COMPETITION,
          })
        );
        dispatch(setInvitationFlow(null));
      }, MAX_WAITING_TIME_MS);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [broadcastId, dispatch, sentRecipientIds.length]);

  const totalCount =
    activeCount < MAX_ACTIVE_STREAMS && sentRecipientIds.length > 0
      ? activeCount + 1
      : activeCount;

  return {
    otherStreams,
    sentRecipientIds,
    acceptedWithoutStreams,
    totalCount,
    activeCount,
  };
};
