import React, { FC, memo, useContext, useState } from "react";
import AudioWaveform from "chat/components/currentConversation/components/VoiceMessage/AudioWaveForm";
import PlayPauseButton from "chat/components/currentConversation/components/VoiceMessage/PlayPauseButton";
import { ThemeContext } from "chat/imports/context";
import { VoiceMessageType, parseMessageFromBase64 } from "chat/imports/proto";
import { StoredMessage } from "chat/state";
import styles from "./VoiceMesssage.scss";

interface VoiceMessageProps {
  isMyMessage: boolean;
  message: StoredMessage;
}

const VoiceMessage: FC<VoiceMessageProps> = ({ message, isMyMessage }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { dark: isDark } = useContext(ThemeContext);

  const audioUrl = message.media?.[0]?.download_url;
  const { waves } = parseMessageFromBase64(message.payload, VoiceMessageType);

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className={styles.root}>
      <PlayPauseButton
        onClick={togglePlay}
        isPlaying={isPlaying}
        isMyMessage={isMyMessage}
        isLoading={!audioUrl}
      />

      <AudioWaveform
        audioUrl={audioUrl}
        barValues={waves}
        isMyMessage={isMyMessage}
        isDark={isDark}
        isPlaying={isPlaying}
        onAudioEnded={handleAudioEnded}
      />
    </div>
  );
};

export default memo(VoiceMessage);
