import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BroadcastMasksConfigMask } from "src/features/broadcastMasks/common/types";
import { SECOND } from "src/features/broadcastMasks/imports/constants";
import {
  RootState,
  broadcastSelectors,
} from "src/features/broadcastMasks/imports/state";
import { Nullable } from "src/features/broadcastMasks/imports/types";
import { logger, useUnmount } from "src/features/broadcastMasks/imports/utils";
import {
  broadcastMasksSelectors,
  resetPlayer,
  selectMask,
} from "src/features/broadcastMasks/state";
import { mapStoreToMakeupValues } from "src/features/broadcastMasks/utils/makeupUtils";
import {
  applyBeautyValues,
  applyMaskEffect,
} from "src/features/broadcastMasks/utils/playerUtils";

type Timeout = {
  id: number;
  onComplete?: VoidFunction;
};

const selector = (state: RootState) => ({
  selectedMask: broadcastMasksSelectors.getMask(state),
  beautyValues: broadcastMasksSelectors.getBeauty(state),
  player: broadcastMasksSelectors.getPlayer(state),
  makeupValues: broadcastMasksSelectors.getMakeup(state),
  effectsConfig: broadcastMasksSelectors.getConfig(state),
  broadcastId: broadcastSelectors.broadcastId(state),
});

const EMPTY_BASE_EFFECT = {
  tg_camera: {},
  scene: "tg_prefab_fx",
  version: "2.0.0",
};

export const useBroadcastMasksComputedConfig = () => {
  const dispatch = useDispatch();
  const [computedConfig, setComputedConfig] = useState<string>("");

  const cachedMask = useRef<Nullable<BroadcastMasksConfigMask>>(null);
  const cachedMaskBackup = useRef<Nullable<BroadcastMasksConfigMask>>(null);
  const cachedMaskConfig = useRef<Nullable<unknown>>(null);
  const cachedMaskConfigBackup = useRef<Nullable<unknown>>(null);

  const isGiftMaskPlayed = useRef(true);

  const { selectedMask, beautyValues, player, makeupValues } = useSelector(
    selector,
    shallowEqual
  );

  useEffect(() => {
    if (!player) {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const timeout: Timeout = {
      id: 0,
    };

    const updateConfig = async () => {
      try {
        const [baseEffect, FS] = player.loadBaseConfig();

        const isEffectsConfigEmpty =
          !selectedMask &&
          !Object.keys(beautyValues).length &&
          !Object.keys(makeupValues).length;

        if (isEffectsConfigEmpty) {
          setComputedConfig(JSON.stringify(EMPTY_BASE_EFFECT));

          return;
        }

        if (!baseEffect || !FS) {
          return;
        }

        const face = baseEffect.faces[0];

        const mappedMakeupValues = mapStoreToMakeupValues(makeupValues);

        face.tg_beauty_makeup.makeup = {
          ...face.tg_beauty_makeup.makeup,
          ...mappedMakeupValues,
        };

        if (!cachedMask || cachedMask.current?.id !== selectedMask?.id) {
          cachedMask.current = selectedMask;

          cachedMaskConfig.current = await applyMaskEffect(
            face,
            FS,
            selectedMask,
            player,
            signal
          );
        }

        if (cachedMaskConfig.current) {
          if (selectedMask?.duration && selectedMask.onComplete) {
            delete face.tg_fx;
            face.tg_gift = cachedMaskConfig.current;

            isGiftMaskPlayed.current = false;

            timeout.onComplete = selectedMask.onComplete;
            timeout.id = setTimeout(() => {
              selectedMask.onComplete?.();

              dispatch(selectMask(cachedMaskBackup.current));

              isGiftMaskPlayed.current = true;
            }, selectedMask.duration * SECOND) as unknown as number;
          } else {
            delete face.tg_gift;
            face.tg_fx = cachedMaskConfig.current;
            cachedMaskBackup.current = cachedMask.current;
            cachedMaskConfigBackup.current = cachedMaskConfig.current;
          }
        }

        applyBeautyValues(face, beautyValues);

        if (signal.aborted) {
          return;
        }

        setComputedConfig(JSON.stringify(baseEffect));
      } catch (error) {
        // @ts-ignore
        if (error.name !== "AbortError") {
          logger.error(`Error applying mask effect: ${error}`);
        }
      }
    };

    updateConfig().then(() => {});

    return () => {
      clearTimeout(timeout.id);

      timeout.onComplete?.();

      controller.abort();
    };
  }, [player, selectedMask, makeupValues, beautyValues]);

  useUnmount(() => {
    if (!isGiftMaskPlayed.current) {
      dispatch(selectMask(cachedMaskBackup.current));
    }

    dispatch(resetPlayer());
  });

  return computedConfig;
};
