import React, { forwardRef } from "react";
import HLSPlayer from "src/ui/player/HLSPlayer";
import MetaPlayerImpl, { LazyPlayer } from "src/ui/player/MetaPlayer";

export const HLSPlayerWithRef = forwardRef((props, ref) => (
  <HLSPlayer {...props} forwardedRef={ref} />
));

HLSPlayerWithRef.displayName = "HLSPlayerWithRef";

export default LazyPlayer;

export const MetaPlayer = forwardRef((props, ref) => (
  <MetaPlayerImpl {...props} ref={ref} />
));

MetaPlayer.displayName = "MetaPlayer";
