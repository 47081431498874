import React, { FC, memo, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { emitChatInboxClicked } from "chat/analytics/emitChatInboxClicked";
import { MAX_DISPLAYED_MESSAGES_COUNT } from "chat/imports/constants";
import { InboxIcon } from "chat/messageRequest/imports/images";
import { RootState, VoidCallback } from "chat/messageRequest/imports/types";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
} from "chat/messageRequest/imports/uiCommon";
import {
  getIsMessageRequestEnabled,
  openNsfwContentModal,
  settingsSelectors,
} from "src/features/chat/messageRequest/imports/state";
import styles from "./HeaderControls.scss";

interface HeaderControlsProps {
  count: number;
  onClick: VoidCallback;
}

const selector = (state: RootState) => ({
  isMessageRequestEnabled: getIsMessageRequestEnabled(state),
  isNsfwEnabled: settingsSelectors.isNsfwEnabled(state),
});

export const HeaderControls: FC<HeaderControlsProps> = memo(
  ({ onClick, count }) => {
    const { locale } = useIntl();
    const dispatch = useDispatch();
    const { isMessageRequestEnabled, isNsfwEnabled } = useSelector(
      selector,
      shallowEqual
    );

    const handleOpenMessageRequest = useCallback(() => {
      if (isMessageRequestEnabled && !isNsfwEnabled) {
        dispatch(
          openNsfwContentModal({
            redirectLink: null,
            shouldUseCallback: true,
            callback: onClick,
          })
        );

        return;
      }

      onClick();
      emitChatInboxClicked(count);
    }, [count, dispatch, isMessageRequestEnabled, isNsfwEnabled, onClick]);

    return (
      <div className={classnames(styles[locale], styles.root)}>
        <div className={styles.imageContainer}>
          <InboxIcon className={styles.inboxIcon} />
          {count > 0 ? (
            <Typography
              type={TYPOGRAPHY_TYPE.HEADLINE6}
              className={styles.unreadIndicator}
              data-testid="unread-threads-indicator"
            >
              {count > MAX_DISPLAYED_MESSAGES_COUNT
                ? `${MAX_DISPLAYED_MESSAGES_COUNT}+`
                : count}
            </Typography>
          ) : null}
        </div>

        <Typography type={TYPOGRAPHY_TYPE.HEADLINE4} className={styles.title}>
          <FormattedMessage
            id="chat.request.headerTitle"
            defaultMessage="Message Requests"
          />
        </Typography>
        <Button
          data-testid="go-to-message-request"
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.SECONDARY}
          onClick={handleOpenMessageRequest}
        >
          <Typography type={TYPOGRAPHY_TYPE.HEADLINE5}>
            <FormattedMessage
              id="chat.request.viewButton"
              defaultMessage="View"
            />
          </Typography>
        </Button>
      </div>
    );
  }
);

HeaderControls.displayName = "HeaderControls";
