import { LPInviter } from "broadcast/enums/liveParty";
import { getProxycadorHost } from "broadcast/imports/environments";
import { InitStreamRequest, InitStreamResponse } from "broadcast/imports/proto";
import {
  Gift,
  StreamKind,
  StreamProtocol,
  StreamSettings,
} from "broadcast/imports/types";
import { enhancedFetch } from "broadcast/imports/utils";
import { InitStreamRequestParams } from "broadcast/types/types";

export const getInitStreamUrl = (type: StreamKind, username: string) => {
  switch (type) {
    case StreamKind.PRIVATE:
      return `${getProxycadorHost()}/stream/client/v1/initPrivate/${username}`;
    case StreamKind.CHAT:
      return `${getProxycadorHost()}/stream/client/v1/initChat/${username}`;
    default:
      return `${getProxycadorHost()}/stream/client/v1/initPublic/${username}`;
  }
};

export const getInitStreamRequestType = (
  type: StreamKind
): StreamKind | null => {
  if (type === StreamKind.CHAT) {
    return null;
  }

  return type;
};

export interface InitStreamParams {
  conversationId?: string;
  dontInviteToLiveParty: boolean;
  gift?: Gift;
  landscape?: boolean;
  multiBattleSupport?: boolean;
  recipientAccountIds?: string[];
  region: string;
  settings: StreamSettings;
  source: string;
  thumbnail?: string;
  title?: string;
  type: StreamKind;
  username: string;
}

export const initStream = async (params: InitStreamParams) => {
  const apiUrl = getInitStreamUrl(params.type, params.username);
  const requestBody: InitStreamRequestParams = {
    type: getInitStreamRequestType(params.type),
    forceStart: true,
    title: params.title,
    thumbnail: params.thumbnail,
    source: params.source,
    saveStoryOnServer: true,
    streamProtocol: StreamProtocol.RTMP,
    region: params.region,
    dontInviteToLiveParty: params.dontInviteToLiveParty,
    settings: params.settings,
    landscape: params.landscape,
    lpInvitableBy:
      params.type === StreamKind.CHAT ? LPInviter.NO_ONE : LPInviter.ANYONE,
    multiBattleSupport: params.multiBattleSupport,
    recipientAccountIds: params.recipientAccountIds || [],
  };

  if (params.conversationId) {
    if (params.conversationId.startsWith("GROUP:")) {
      requestBody.recipientGroupId = params.conversationId;
    } else {
      requestBody.recipientAccountId = params.conversationId;
    }
  }

  if (params.gift) {
    requestBody.ticketPriceInCredits = params.gift.ticketPriceInCredits;
    requestBody.restrictions = {
      gift: {
        ticketPriceInCredits: params.gift.ticketPriceInCredits,
      },
    };
  }

  const response = await enhancedFetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-protobuf;charset=UTF-8",
    },
    body: InitStreamRequest.encode(requestBody).finish(),
  });

  const bufferResponse = await response.arrayBuffer();

  return InitStreamResponse.decode(new Uint8Array(bufferResponse), undefined);
};
