import {
  Sticker,
  StickerGiftPayload,
  StickerGoalPayload,
  StickerImagePayload,
} from "generated/proto/Sticker";
import {
  StickerStatus,
  StickerType,
} from "src/features/broadcast/enums/stickers";
import { encodeMessageToBase64 } from "src/features/broadcast/imports/utils";
import {
  RestoreStickerData,
  StickerDataType,
} from "src/features/broadcast/types/types";

const encodeImageStickerPayload = (stickerData: StickerDataType) => {
  const { type, text } = stickerData;

  return Sticker.encode({
    type,
    payload: encodeMessageToBase64(stickerData, StickerImagePayload),
    text,
    status: StickerStatus.ACTIVE,
  }).finish();
};

const encodeGoalStickerPayload = (stickerData: StickerDataType) => {
  const { type, text } = stickerData;

  return Sticker.encode({
    type,
    payload: encodeMessageToBase64(stickerData, StickerGoalPayload),
    text,
    status: StickerStatus.ACTIVE,
  }).finish();
};

const encodeGiftStickerPayload = (stickerData: StickerDataType) => {
  const { text, type } = stickerData;

  return Sticker.encode({
    type,
    payload: encodeMessageToBase64(stickerData, StickerGiftPayload),
    text,
    status: StickerStatus.ACTIVE,
  }).finish();
};

export const encodePayload = (stickerData: StickerDataType) => {
  switch (stickerData.type) {
    case StickerType.IMAGE:
      return encodeImageStickerPayload(stickerData);
    case StickerType.GOAL:
      return encodeGoalStickerPayload(stickerData);
    case StickerType.GIFT:
      return encodeGiftStickerPayload(stickerData);
    default:
      return new Error(`${stickerData.type} is not supported`);
  }
};

export const encodeRestoreStickerPayload = (
  restoreStickerData: RestoreStickerData
) => Sticker.encode(restoreStickerData).finish();
