import { defineMessage } from "react-intl";
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import {
  ACME_CHAT_EVENT_IDENTIFICATOR,
  ACME_CHAT_REACTION_IDENTIFICATOR,
  ACME_CHAT_SELFREACTION_IDENTIFICATOR,
  ACME_READ_MESSAGE_EVENT_IDENTIFICATOR,
  ACME_READ_RECEIPT_IDENTIFICATOR,
  ALLOWED_CHAT_EVENTS,
  CHAT_EVENTS_DEFAULT_TIMESTAMP,
} from "chat/constants";
import { CHAT_EVENTS_TYPES, ChatErrorType, ResponseCode } from "chat/enums";
import {
  CUSTOMER_SUPPORT_LINK,
  TANGO_ACCOUNT_ID,
} from "chat/imports/constants";
import { getChatIdForLoadLandingsFromShouts } from "chat/imports/environment";
import {
  ContentSharedPayload,
  encodeMessageToBase64,
  parseMessageFromBase64,
} from "chat/imports/proto";
import {
  ACME_RECEIVED,
  AsyncState,
  addAsyncCasesToBuilderV2,
  addUserSessionScopeReducer,
  initialFetcherStateMeta,
} from "chat/imports/state";
import { Nullable } from "chat/imports/types";
import {
  sendPremiumMediaMessage,
  sendPremiumMessage,
} from "chat/premiumMessage/state/asyncAction";
import { ContentSharedPayloadMessage } from "chat/premiumMessage/types";
import * as actions from "chat/state/actionCreators";
import {
  ChatEventsState,
  ConversationInfo,
  ConversationMessagesReactions,
  Message,
  MessageIdentifier,
  MessageMedia,
  MessageReaction,
  MessageType,
  UnreadDataState,
  UnreadReactionState,
  conversationId,
} from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import { visibilityChangeReconnection } from "state/actionCreators/visibilityChangeReconnection";

const autoreplyMessage = defineMessage({
  id: "chat.tango.autoreply",
  defaultMessage: `We love hearing from you! If you require assistance, please contact our Customer Support: {csLink}`,
});

let fakeMessageId = 0;

// for tests purpose only
export const resetFakeMessageId = (value = 0) => {
  fakeMessageId = value;
};

type StoredMessageMedia = {
  caption?: string;
  isUploading?: boolean;
} & MessageMedia;

export type StoredConversation = {
  hasMoreMessages?: boolean;
  isLoading?: boolean;
  isLoadingFailed?: boolean;
  isPinned?: boolean;
  pinnedTs?: number;
} & ConversationInfo;

export type StoredMessage = {
  edited?: boolean;
  error?: string;
  id: { requestId?: string } & MessageIdentifier;
  isBlurred?: boolean;
  isMediaLoading?: boolean;
  isPending?: boolean;
  isTranslated?: boolean;
  isTranslating?: boolean;
  language?: string;
  media?: StoredMessageMedia[];
  translation?: Record<string, string>;
} & Omit<Message, "id" | "media">;

export type ChatState = AsyncState<{
  canLoadMore: boolean;
  chatEvents: ChatEventsState;
  conversations: Record<conversationId, StoredConversation>;
  currentConversationId: Nullable<conversationId>;
  deletedChatUserId: string | undefined;
  editingMessageInProgress?: Nullable<StoredMessage>;
  messageReactions: ConversationMessagesReactions;
  messages: Record<conversationId, StoredMessage[]>;
  pinnedConversationsCount: number;
  readReactionStatus: boolean;
  readSelfReactionStatus: UnreadReactionState;
  readStatus: UnreadDataState;
  shouldUpdateBatchOfMessages: boolean;
  totalUnreadCount: number;
}>;

export const chatInitialState = {
  data: {
    shouldUpdateBatchOfMessages: false,
    currentConversationId: null,
    conversations: {},
    deletedChatUserId: undefined,
    messages: {},
    canLoadMore: false,
    totalUnreadCount: 0,
    chatEvents: {
      status: false,
      lastEventTs: CHAT_EVENTS_DEFAULT_TIMESTAMP,
      events: {},
    },
    messageReactions: {
      lastReactionTs: 0,
      conversationReactions: {},
    },
    readStatus: {
      status: false,
      requestInProgress: false,
      lastReadMessageTs: 0,
      readMessageData: {},
    },
    readReactionStatus: false,
    readSelfReactionStatus: {
      status: false,
      lastReadReactionTs: 0,
      readReactionData: {},
    },
    pinnedConversationsCount: 0,
  },
  meta: { ...initialFetcherStateMeta, stale: false },
};

const getUniqMessages = (messages: StoredMessage[]) =>
  Object.values(
    messages.reduce((acc: Record<number, StoredMessage>, next) => {
      const id = next.id.id;

      if (!acc[id]) {
        acc[id] = next;
      } else {
        acc[id] = { ...acc[id], ...next };
      }

      return acc;
    }, {})
  ).sort((a, b) => b.id.ts - a.id.ts);

const getMessageByMessageId = (
  messages: Record<string, StoredMessage[]>,
  conversationId: string,
  messageId: number
) => messages[conversationId].find((item) => item.id.id === messageId);

const reducer = createReducer<ChatState>(chatInitialState, (builder) => {
  addUserSessionScopeReducer(
    addAsyncCasesToBuilderV2({
      builder,
      action: actions.fetchConversations,
      prepareData: (prevData, nextData, meta) => {
        const { isRefreshing } = meta.arg;
        const pinnedConversations = nextData.pinned?.pinnedChatInfos || [];
        const currentData = {
          ...prevData,
          canLoadMore: isRefreshing
            ? prevData.canLoadMore
            : !!nextData.has_more_conversations,
          totalUnreadCount: Number(nextData.total_unread_count),
        };

        const result = nextData.conversations
          ? nextData.conversations.reduce((acc, next) => {
              const { conversation, messages = [] } = next;
              const currentConversation =
                acc.conversations[conversation.conversation_id] || {};
              const currentMessages =
                acc.messages[conversation.conversation_id] || [];

              acc.conversations[conversation.conversation_id] = Object.assign(
                currentConversation,
                conversation
              );
              acc.messages[conversation.conversation_id] = getUniqMessages([
                ...currentMessages,
                ...messages,
              ]);

              return acc;
            }, currentData)
          : currentData;

        if (pinnedConversations.length) {
          result.pinnedConversationsCount = pinnedConversations.length;

          pinnedConversations.forEach(({ conversationId, pinnedTs }) => {
            if (result.conversations[conversationId]) {
              result.conversations[conversationId] = {
                ...result.conversations[conversationId],
                isPinned: true,
                pinnedTs,
              };
            }
          });
        }

        result.shouldUpdateBatchOfMessages = false;

        return result;
      },
      initialData: chatInitialState.data,
    })
      .addCase(actions.setCurrentConversationId, (state, action) => {
        state.data.currentConversationId = action.payload;
      })
      .addCase(actions.updateChatMessageBlur, (state, action) => {
        const { conversationId, messageId, isBlurred } = action.payload;

        const message = state.data.messages[conversationId].find(
          (message) => Number(message.id.id) === messageId
        );

        if (message) {
          message.isBlurred = isBlurred;
        }
      })
      .addCase(actions.deleteChatMessage.fulfilled, (state, action) => {
        const { messages } = action.meta.arg;
        const currentConversationId = state.data
          .currentConversationId as string;
        const conversationMessages = state.data.messages[currentConversationId];

        messages.forEach(({ id }) => {
          const { id: messageIdToDelete } = id;
          const messageIdToDeleteNumber = Number(messageIdToDelete);
          const indexMsgToRemove = conversationMessages.findIndex(
            (msg) => msg.id.id === messageIdToDeleteNumber
          );

          if (indexMsgToRemove !== -1) {
            conversationMessages.splice(indexMsgToRemove, 1);
          }
        });
      })
      .addCase(actions.deleteMessages, (state, action) => {
        const { messages } = action.payload;
        const currentConversationId = state.data
          .currentConversationId as string;
        const conversationMessages = state.data.messages[currentConversationId];

        messages.forEach(({ messageId: messageIdToDelete }) => {
          const messageIdToDeleteNumber = Number(messageIdToDelete);
          const indexMsgToRemove = conversationMessages.findIndex(
            (msg) => msg.id.id === messageIdToDeleteNumber
          );

          if (indexMsgToRemove !== -1) {
            conversationMessages.splice(indexMsgToRemove, 1);
          }
        });

        delete state.data.chatEvents.events[currentConversationId][
          CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ME
        ];
        delete state.data.chatEvents.events[currentConversationId][
          CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ALL
        ];
      })
      .addCase(actions.editChatMessage.fulfilled, (state, action) => {
        const { message } = action.meta.arg;
        const conversationId = state.data.currentConversationId as string;

        const editedMessage = getMessageByMessageId(
          state.data.messages,
          conversationId,
          Number(message.id.id)
        );

        if (editedMessage) {
          editedMessage.edited = true;
          editedMessage.body = message.body;
        }
      })
      .addCase(actions.fetchMessagesById.fulfilled, (state, action) => {
        const conversationId = state.data.currentConversationId as string;
        const { message } = action.payload;

        (message ?? []).forEach((message) => {
          const editedMessage = getMessageByMessageId(
            state.data.messages,
            conversationId,
            Number(message.id.id)
          );

          if (editedMessage) {
            editedMessage.edited = message.edited;
            editedMessage.body = message.body;
          }
        });

        delete state.data.chatEvents.events[conversationId][
          CHAT_EVENTS_TYPES.EDIT_MESSAGE
        ];
      })
      .addCase(actions.fetchConversation.pending, (state, action) => {
        const { conversationId } = action.meta.arg;
        const currentConversation =
          state.data.conversations[conversationId] ||
          (isGroupChatId(conversationId)
            ? {
                conversation_id: conversationId,
              }
            : {
                conversation_id: conversationId,
                account_info: {
                  account_id: conversationId,
                },
              });
        state.data.conversations[conversationId] = Object.assign(
          currentConversation,
          { isLoading: true, isLoadingFailed: false }
        );
      })
      .addCase(actions.fetchConversation.fulfilled, (state, action) => {
        const { conversationId } = action.meta.arg;
        if (action.payload.conversation) {
          const { timestamp } = action.payload.status;
          const { conversation, has_more_messages, messages } =
            action.payload.conversation;
          state.data.conversations[conversationId] = Object.assign(
            state.data.conversations[conversationId],
            conversation,
            {
              hasMoreMessages: has_more_messages,
              isLoading: false,
              isLoadingFailed: false,
              isSelfReactionRead: true,
              lastSelfReadMessageTs: timestamp,
            }
          );

          if (messages) {
            state.data.messages[conversationId] = getUniqMessages([
              ...(state.data.messages[conversationId] || []),
              ...messages,
            ]);
          }

          return state;
        }

        if (
          action.payload.status.code ===
            ResponseCode.RESPONSE_STATUS_CODE_SVR_ERR ||
          (ResponseCode.RESPONSE_STATUS_CODE_MESSAGE_NONEXISTENT &&
            isGroupChatId(conversationId))
        ) {
          delete state.data.conversations[conversationId];
          delete state.data.messages[conversationId];

          return state;
        }

        if (
          action.payload.status.code ===
          ResponseCode.RESPONSE_STATUS_CODE_MESSAGE_NONEXISTENT
        ) {
          state.data.conversations[conversationId] = Object.assign(
            state.data.conversations[conversationId],
            {
              isLoading: false,
              isLoadingFailed: false,
            }
          );
        }
      })
      .addCase(actions.fetchConversation.rejected, (state, action) => {
        state.data.conversations[action.meta.arg.conversationId] = {
          ...state.data.conversations[action.meta.arg.conversationId],
          isLoading: false,
          isLoadingFailed: true,
        };
      })
      .addCase(actions.readMessages.fulfilled, (state, action) => {
        const { conversation_id } = action.meta.arg;
        const {
          unread_count,
          total_unread_count,
          status: { timestamp },
        } = action.payload;
        state.data.totalUnreadCount = Number(total_unread_count);
        state.data.conversations[conversation_id] = Object.assign(
          state.data.conversations[conversation_id],
          {
            unread_message_count: unread_count,
            lastSelfReadMessageTs: timestamp,
          }
        );
      })
      .addCase(actions.uploadImage.fulfilled, (state, action) => {
        const { messageId, retryId, conversationId } = action.meta.arg;
        const pendingMessage = (state.data.messages[conversationId] || []).find(
          (message) => message.id.requestId === (retryId || messageId)
        );

        if (!pendingMessage || !pendingMessage.media?.[0]) {
          return state;
        }

        const conversation = state.data.conversations[conversationId];
        if (conversation) {
          const lastMessageTimestamp = Date.now();
          conversation.last_message_ts = lastMessageTimestamp;
          conversation.lastSelfReadMessageTs = lastMessageTimestamp;
        }

        const { url, thumbnailUrl, width, height } = action.payload;
        pendingMessage.media[0] = Object.assign(pendingMessage.media[0], {
          download_url: url,
          thumbnail_url: thumbnailUrl,
          width,
          height,
          isUploading: false,
        });
      })
      .addCase(actions.uploadVideo.fulfilled, (state, action) => {
        const { messageId, retryId, conversationId } = action.meta.arg;
        const pendingMessage = (state.data.messages[conversationId] || []).find(
          (message) => message.id.requestId === (retryId || messageId)
        );

        if (!pendingMessage || !pendingMessage.media?.[0]) {
          return state;
        }

        const conversation = state.data.conversations[conversationId];
        if (conversation) {
          const lastMessageTimestamp = Date.now();
          conversation.last_message_ts = lastMessageTimestamp;
          conversation.lastSelfReadMessageTs = lastMessageTimestamp;
        }

        const { url } = action.payload;
        pendingMessage.media[0] = Object.assign(pendingMessage.media[0], {
          download_url: url,
        });
      })
      .addCase(actions.getMessageTranslation.pending, (state, action) => {
        const { conversationId, messageId } = action.meta.arg;
        const message = getMessageByMessageId(
          state.data.messages,
          conversationId,
          messageId
        );

        if (message) {
          message.isTranslated = false;
          message.isTranslating = true;
        }
      })
      .addCase(actions.getMessageTranslation.fulfilled, (state, action) => {
        const { conversationId, messageId, locale } = action.meta.arg;

        const message = getMessageByMessageId(
          state.data.messages,
          conversationId,
          messageId
        );
        if (!message) {
          return state;
        }

        const { translated, language } = action.payload;

        message.language = language;
        if (!message.translation) {
          message.translation = {};
        }
        if (translated) {
          message.translation[locale] = translated;
          message.isTranslated = true;
        }
        message.isTranslating = false;

        if (message.error === ChatErrorType.TRANSLATION_ERROR) {
          message.error = undefined;
        }
      })
      .addCase(actions.getMessageTranslation.rejected, (state, action) => {
        const { conversationId, messageId } = action.meta.arg;
        const message = getMessageByMessageId(
          state.data.messages,
          conversationId,
          messageId
        );

        if (message) {
          message.isTranslating = false;
          message.error = ChatErrorType.TRANSLATION_ERROR;
        }
      })
      .addCase(actions.setIsTranslated, (state, action) => {
        const { conversationId, messageId, isTranslated } = action.payload;
        const message = getMessageByMessageId(
          state.data.messages,
          conversationId,
          messageId
        );

        if (message) {
          message.isTranslated = isTranslated;
        }
      })
      .addCase(actions.setEditingMessageInProgress, (state, action) => {
        state.data.editingMessageInProgress = action.payload;
      })
      .addCase(sendPremiumMediaMessage.pending, (state, action) => {
        const {
          arg: { conversationId, from },
          requestId,
        } = action.meta;

        if (!state.data.messages[conversationId]) {
          state.data.messages[conversationId] = [];
        }

        state.data.messages[conversationId].unshift({
          id: {
            id: --fakeMessageId,
            ts: Date.now(),
            chat_id: conversationId,
            requestId,
          },
          type: MessageType.PREMIUM_MESSAGE_SHARED,
          from,
          isPending: true,
          isMediaLoading: true,
        });
      })
      .addCase(sendPremiumMessage.pending, (state, action) => {
        const {
          arg: { conversationId, giftId, items, retryId, messageId },
        } = action.meta;

        const pendingMessage = state.data.messages[conversationId].find(
          (message) => message.id.requestId === (retryId || messageId)
        );

        const messageToRetry = retryId
          ? state.data.messages[conversationId].find(
              (message) => message.id.requestId === retryId
            )
          : null;

        if (retryId && !messageToRetry) {
          return state;
        }

        if (!pendingMessage) {
          return state;
        }

        if (messageToRetry) {
          messageToRetry.isPending = true;
          messageToRetry.error = undefined;

          return state;
        }

        delete pendingMessage.isMediaLoading;

        const { type, mediaInfo } = items[0];

        pendingMessage.payload = encodeMessageToBase64(
          {
            giftId,
            messageId: "",
            thumbnailBlurUrl: "",
            type,
            mediaInfo,
          },
          ContentSharedPayload
        );
      })
      .addCase(sendPremiumMessage.fulfilled, (state, action) => {
        const {
          arg: { conversationId, retryId, messageId },
        } = action.meta;
        const fakeId = retryId || messageId;

        const shareItemResponse = action.payload.items[0];

        const conversation = state.data.conversations[conversationId];

        if (conversation && Boolean(shareItemResponse.state)) {
          conversation.state = shareItemResponse.state;
        }

        const pendingMessage = state.data.messages[conversationId].find(
          (message) => message.id.requestId === fakeId
        );

        if (!pendingMessage) {
          return state;
        }

        if (conversation) {
          const lastMessageTimestamp = Date.now();
          conversation.last_message_ts = lastMessageTimestamp;
          conversation.lastSelfReadMessageTs = lastMessageTimestamp;
        }

        pendingMessage.id = {
          ...pendingMessage.id,
          ...shareItemResponse.tc2Id,
        };
        delete pendingMessage.isPending;

        const payloadData = parseMessageFromBase64(
          pendingMessage.payload,
          ContentSharedPayload
        ) as ContentSharedPayloadMessage;

        pendingMessage.payload = encodeMessageToBase64(
          {
            ...payloadData,
            messageId: shareItemResponse.messageId,
          },
          ContentSharedPayload
        );
      })
      .addCase(sendPremiumMessage.rejected, (state, action) => {
        const {
          arg: { conversationId, retryId, messageId },
        } = action.meta;

        const pendingMessage = state.data.messages[conversationId].find(
          (message) => message.id.requestId === (retryId || messageId)
        );

        if (!pendingMessage) {
          return state;
        }

        delete pendingMessage.isPending;
        pendingMessage.error = ChatErrorType.SEND_MESSAGE_ERROR;
      })
      .addCase(actions.removeConversation.pending, (state) => {
        state.meta.loading = true;
      })
      .addCase(actions.removeConversation.fulfilled, (state, action) => {
        if (action.meta.arg.conversationId) {
          delete state.data.conversations[action.meta.arg.conversationId];
          delete state.data.messages[action.meta.arg.conversationId];
        }
        state.meta.loading = false;
      })
      .addCase(actions.fetchUnreadMessagesRequest.pending, (state) => {
        state.data.readStatus.requestInProgress = true;
      })
      .addCase(
        actions.fetchUnreadMessagesRequest.fulfilled,
        (state, action) => {
          state.data.readStatus.requestInProgress = false;
          const conversations = action.payload.conversations ?? [];
          const { lastReadTs, unreadConversations } = conversations.reduce(
            ({ lastReadTs, unreadConversations }, conversation) => {
              let newLastReadTs = 0;
              if (Number(conversation.lastReadMessageTs) > lastReadTs) {
                newLastReadTs = Number(conversation.lastReadMessageTs);
              } else {
                newLastReadTs = lastReadTs;
              }

              return {
                lastReadTs: newLastReadTs,
                unreadConversations: {
                  ...unreadConversations,
                  [conversation.peerId]: conversation.lastReadMessageTs,
                },
              };
            },
            { lastReadTs: 0, unreadConversations: {} }
          );

          state.data.readStatus.lastReadMessageTs = lastReadTs;
          state.data.readStatus.readMessageData = {
            ...state.data.readStatus.readMessageData,
            ...unreadConversations,
          };

          state.data.readStatus.status = false;
        }
      )
      .addCase(
        actions.fetchSelfMessagesReactions.fulfilled,
        (state, action) => {
          const { currentConversationId } = action.meta.arg;
          const { timestamp } = action.payload.status as { timestamp?: string };
          const conversationReactions =
            action.payload.conversationReactionsDetails ?? [];
          const { lastReadReactionTs, unreadConversations } =
            conversationReactions.reduce(
              (
                { lastReadReactionTs, unreadConversations },
                conversationReaction
              ) => {
                const hasActiveReaction =
                  conversationReaction.reactionDetails?.some(
                    (detail) => detail.reactionDetails.deleted === false
                  );
                if (!hasActiveReaction) {
                  return {
                    lastReadReactionTs,
                    unreadConversations: {
                      ...unreadConversations,
                      [conversationReaction.conversationId]: 0,
                    },
                  };
                }

                let newLastReadTs = lastReadReactionTs;
                if (
                  Number(conversationReaction.lastSelfReactionsTs) >
                  lastReadReactionTs
                ) {
                  newLastReadTs = Number(
                    conversationReaction.lastSelfReactionsTs
                  );
                }

                return {
                  lastReadReactionTs: newLastReadTs,
                  unreadConversations: {
                    ...unreadConversations,
                    [conversationReaction.conversationId]:
                      conversationReaction.lastSelfReactionsTs,
                  },
                };
              },
              { lastReadReactionTs: 0, unreadConversations: {} }
            );

          if (currentConversationId) {
            state.data.conversations[currentConversationId] = Object.assign(
              state.data.conversations[currentConversationId],
              {
                lastSelfReadMessageTs: timestamp,
              }
            );
          }

          state.data.readSelfReactionStatus.lastReadReactionTs =
            lastReadReactionTs;
          state.data.readSelfReactionStatus.readReactionData = {
            ...state.data.readSelfReactionStatus.readReactionData,
            ...unreadConversations,
          };
          state.data.readSelfReactionStatus.status = false;
        }
      )
      .addCase(actions.readReactionsToSelf.fulfilled, (state, action) => {
        const { conversationId } = action.meta.arg;
        const { status, selfReadReactionTs } = action.payload;

        if (status.code === 0) {
          state.data.conversations[conversationId] = Object.assign(
            state.data.conversations[conversationId],
            {
              lastSelfReadMessageTs: selfReadReactionTs,
            }
          );
        }
      })
      .addCase(actions.fetchChatEvents.fulfilled, (state, action) => {
        const { chatEvents, lastEventTimestamp } = action.payload;
        state.data.chatEvents.status = false;
        state.data.chatEvents.lastEventTs = Number(lastEventTimestamp);

        if (chatEvents) {
          chatEvents.forEach((event) => {
            event.typedChatEvents.forEach((typedEvent) => {
              if (ALLOWED_CHAT_EVENTS.includes(typedEvent.eventType)) {
                state.data.chatEvents.events[event.chatId] = {
                  ...state.data.chatEvents.events[event.chatId],
                  [typedEvent.eventType]: [
                    ...(state.data.chatEvents.events[event.chatId]?.[
                      typedEvent.eventType
                    ] ?? []),
                    ...typedEvent.events,
                  ],
                };
              }
            });
          });
        }
      })
      .addCase(
        visibilityChangeReconnection.reconnectionEstablished,
        (state) => {
          state.data.shouldUpdateBatchOfMessages = true;
        }
      )
      .addCase(actions.resetDeletedChatUserId, (state) => {
        state.data.deletedChatUserId = undefined;
      })
      .addCase(actions.pinConversation.fulfilled, (state, action) => {
        const { conversationId, pin } = action.meta.arg;
        const { timestamp } = action.payload;

        if (state.data.conversations[conversationId]) {
          state.data.conversations[conversationId] = {
            ...state.data.conversations[conversationId],
            isPinned: pin,
            pinnedTs: pin ? timestamp : 0,
          };

          state.data.pinnedConversationsCount =
            state.data.pinnedConversationsCount + (pin ? 1 : -1);
        }
      })
      .addCase(actions.muteConversation.fulfilled, (state, action) => {
        const { conversationId, mute } = action.meta.arg;
        const { timestamp } = action.payload;

        if (state.data.conversations[conversationId]) {
          state.data.conversations[conversationId] = {
            ...state.data.conversations[conversationId],
            muted_message_ts: mute ? timestamp : 0,
          };
        }
      })

      .addCase(actions.getReactionsByChat.fulfilled, (state, action) => {
        const {
          conversationReactions,
          status: { code },
        } = action.payload;
        const { conversationId } = action.meta.arg;
        if (code === 0 && conversationReactions && conversationId) {
          state.data.messageReactions.lastReactionTs = Number(
            conversationReactions.lastReactionTs
          );

          if (
            conversationReactions.messageReactions.length &&
            !state.data.messageReactions.conversationReactions[conversationId]
          ) {
            state.data.messageReactions.conversationReactions[conversationId] =
              {};
          }
          conversationReactions.messageReactions.forEach((reactionData) => {
            const messageId = String(reactionData.identifier.id);
            const existing = state.data.messageReactions.conversationReactions[
              conversationId
            ][messageId] || {
              messageReactions: [],
              myReactionId: reactionData.myReactionId,
            };

            const incomingReactions = reactionData.messageReaction;

            incomingReactions.forEach((newReaction) => {
              const oldReaction = existing.messageReactions.find(
                (r) => r.reactionId === newReaction.reactionId
              );

              if (oldReaction && newReaction.reactionCount > 0) {
                oldReaction.reactionCount = newReaction.reactionCount;
              } else if (oldReaction && newReaction.reactionCount <= 0) {
                existing.messageReactions = existing.messageReactions.filter(
                  (reaction) => reaction.reactionId !== newReaction.reactionId
                );
              } else if (!oldReaction && newReaction.reactionCount > 0) {
                existing.messageReactions.push({
                  reactionId: newReaction.reactionId,
                  reactionCount: newReaction.reactionCount,
                });
              }

              state.data.messageReactions.conversationReactions[conversationId][
                messageId
              ] = existing;
            });
          });
        }

        state.data.readReactionStatus = false;
      })
      .addCase(actions.muteChatFromChatEvents, (state, action) => {
        const { conversationId, mute, timestamp } = action.payload;

        if (state.data.conversations[conversationId]) {
          state.data.conversations[conversationId] = {
            ...state.data.conversations[conversationId],
            muted_message_ts: mute ? timestamp : 0,
          };
        }
      })
      .addCase(actions.deleteChatFromChatEvent, (state, action) => {
        const { conversationId } = action.payload;
        if (conversationId) {
          delete state.data.conversations[conversationId];
          delete state.data.messages[conversationId];
        }

        delete state.data.chatEvents.events[conversationId][
          CHAT_EVENTS_TYPES.DELETE_CHAT_FOR_ME
        ];
      })
      .addMatcher(
        isAnyOf(
          actions.sendMessageReaction.fulfilled,
          actions.updateMessageReaction.fulfilled
        ),
        (state, action) => {
          const { status, messageReactions, lastReactionTs } = action.payload;
          const { message } = action.meta.arg;
          const conversationId = message?.chat_id || "";
          if (status.code === 0 && conversationId) {
            state.data.messageReactions.lastReactionTs = Number(lastReactionTs);

            if (
              !state.data.messageReactions.conversationReactions[conversationId]
            ) {
              state.data.messageReactions.conversationReactions[
                conversationId
              ] = {};
            }

            state.data.messageReactions.conversationReactions[conversationId][
              message.id
            ] = {
              messageReactions: messageReactions.messageReaction.filter(
                (reaction: MessageReaction) => reaction.reactionCount > 0
              ),
              myReactionId: messageReactions.myReactionId,
            };
          }
        }
      )
      .addMatcher(
        isAnyOf(actions.deleteChat.pending, actions.leaveGroupChat.pending),
        (state) => {
          state.data.deletedChatUserId = undefined;
        }
      )
      .addMatcher(
        isAnyOf(actions.deleteChat.fulfilled, actions.leaveGroupChat.fulfilled),
        (state, action) => {
          const { conversationId } = action.meta.arg;
          if (conversationId) {
            state.data.deletedChatUserId =
              state.data.conversations[conversationId].account_info?.account_id;
            delete state.data.conversations[conversationId];
            delete state.data.messages[conversationId];
          }
        }
      )
      .addMatcher(
        isAnyOf(
          actions.sendTextMessage.pending,
          actions.sendImageMessage.pending,
          actions.sendVideoMessage.pending
        ),
        (state, action) => {
          const {
            arg: {
              conversationId,
              body,
              imageUrl,
              from,
              retryId,
              mediaFile,
              mediaData,
            },
            requestId,
          } = action.meta;
          let mediaPayload = {};

          if (!state.data.messages[conversationId]) {
            state.data.messages[conversationId] = [];
          }

          const messageToRetry = retryId
            ? state.data.messages[conversationId].find(
                (message) => message.id.requestId === retryId
              )
            : null;

          if (retryId && !messageToRetry) {
            return state;
          }

          let messageType = MessageType.TEXT_MESSAGE;

          if (imageUrl) {
            messageType = MessageType.IMAGE_MESSAGE;
            mediaPayload = {
              thumbnail_url: imageUrl,
              download_url: imageUrl,
            };
          }

          if (mediaFile) {
            messageType = MessageType.VIDEO_MESSAGE;
            mediaPayload = {
              thumbnail_url: mediaData.loadingThumbnail,
              download_url: mediaData.loadingThumbnail,
              ...mediaData,
            };
          }

          if (messageToRetry) {
            messageToRetry.isPending = true;
            messageToRetry.error = undefined;

            return state;
          }

          state.data.messages[conversationId].unshift({
            id: {
              id: --fakeMessageId,
              ts: Date.now(),
              chat_id: conversationId,
              requestId,
            },
            type: messageType,
            body,
            media:
              imageUrl || mediaFile
                ? [
                    {
                      isUploading: true,
                      ...mediaPayload,
                    },
                  ]
                : undefined,
            from,
            isPending: true,
          });
        }
      )
      .addMatcher(
        isAnyOf(
          actions.sendTextMessage.fulfilled,
          actions.sendImageMessage.fulfilled,
          actions.sendVideoMessage.fulfilled
        ),
        (state, action) => {
          const {
            arg: { conversationId, formatMessage, retryId },
            requestId,
          } = action.meta;
          const fakeId = retryId || requestId;
          const responseDetails = action.payload.details?.[0];
          const isSystemChat =
            conversationId === TANGO_ACCOUNT_ID ||
            conversationId === getChatIdForLoadLandingsFromShouts();

          const conversation = state.data.conversations[conversationId];

          if (conversation && !!responseDetails?.state) {
            conversation.state = responseDetails.state;
          }

          if (conversation && conversation.hidden) {
            conversation.hidden = false;
          }

          if (!responseDetails?.id && !isSystemChat) {
            state.data.messages[conversationId] = state.data.messages[
              conversationId
            ].filter((message) => message.id.requestId !== fakeId);

            return state;
          }

          const pendingMessage = state.data.messages[conversationId].find(
            (message) => message.id.requestId === fakeId
          );

          if (!pendingMessage) {
            return state;
          }

          if (conversation) {
            const lastMessageTimestamp = Date.now();
            conversation.last_message_ts = lastMessageTimestamp;
            conversation.lastSelfReadMessageTs = lastMessageTimestamp;
          }

          pendingMessage.id = responseDetails?.id || pendingMessage.id;
          delete pendingMessage.isPending;

          if (pendingMessage.media && pendingMessage.media[0].isUploading) {
            pendingMessage.media[0].isUploading = false;
          }

          if (isSystemChat) {
            state.data.messages[conversationId].unshift({
              id: {
                id: --fakeMessageId,
                ts: pendingMessage.id.ts + 1,
                chat_id: conversationId,
              },
              from: conversationId,
              type: MessageType.TEXT_MESSAGE,
              body: formatMessage(autoreplyMessage, {
                csLink: CUSTOMER_SUPPORT_LINK,
              }),
            });
          }
        }
      )
      .addMatcher(
        isAnyOf(
          actions.sendTextMessage.rejected,
          actions.sendImageMessage.rejected,
          actions.sendVideoMessage.rejected
        ),
        (state, action) => {
          const {
            arg: { conversationId, retryId },
            requestId,
          } = action.meta;

          const pendingMessage = state.data.messages[conversationId].find(
            (message) => message.id.requestId === (retryId || requestId)
          );

          if (!pendingMessage) {
            return state;
          }

          delete pendingMessage.isPending;
          pendingMessage.error = ChatErrorType.SEND_MESSAGE_ERROR;
        }
      )
      .addMatcher(
        (action) => action.type === ACME_RECEIVED,
        (state, action) => {
          const { payload } = action;
          const serviceIdentifier: string = payload.serviceIdentifier || "";
          // TODO: https://tango-me.atlassian.net/browse/WEB-8952
          if (
            serviceIdentifier === ACME_READ_RECEIPT_IDENTIFICATOR ||
            serviceIdentifier === ACME_READ_MESSAGE_EVENT_IDENTIFICATOR
          ) {
            state.data.readStatus.status = true;
          }

          // TODO: https://tango-me.atlassian.net/browse/WEB-8951
          if (serviceIdentifier === ACME_CHAT_EVENT_IDENTIFICATOR) {
            state.data.chatEvents.status = true;
          }
          if (serviceIdentifier === ACME_CHAT_REACTION_IDENTIFICATOR) {
            state.data.readReactionStatus = true;
          }
          if (serviceIdentifier === ACME_CHAT_SELFREACTION_IDENTIFICATOR) {
            state.data.readSelfReactionStatus.status = true;
          }

          state.meta.stale = true;
        }
      ),
    () => chatInitialState
  );
});

export default reducer;
