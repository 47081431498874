import { shallowEqual, useSelector } from "react-redux";
import { getStreamMaxModerationLevel } from "state/abTests";
import { RootState } from "state/delegate";
import { settingsSelectors, streamsCacheSelectors } from "state/selectors";

export const useShouldShowNsfwPip = (streamId: string) => {
  const { isNsfwEnabledInUserSettings, stream, streamMaxModerationLevel } =
    useSelector(
      (state: RootState) => ({
        isNsfwEnabledInUserSettings: settingsSelectors.isNsfwEnabled(state),
        stream: streamsCacheSelectors.getStreamById(state, streamId),
        streamMaxModerationLevel: getStreamMaxModerationLevel(state),
      }),
      shallowEqual
    );

  return (
    !isNsfwEnabledInUserSettings &&
    !!stream &&
    stream.moderationLevel > streamMaxModerationLevel
  );
};
