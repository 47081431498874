import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loadVipManagerLightModelsListRequest,
  loadVipManagerLoungesListRequest,
} from "src/features/loyaltyProgram/api/loyaltyUsersApi";
import { VipManager } from "src/features/loyaltyProgram/common/types";
import { RootState } from "src/features/loyaltyProgram/imports/types";
import { isApiError } from "src/features/loyaltyProgram/imports/utils";

export const loadVipManagerLightModelsList = createAsyncThunk<
  VipManager,
  void,
  { rejectValue: string; state: RootState }
>("lwc/loyaltyProgram/loadVipManagerLightModelsList", async (_, api) => {
  try {
    const lightModelsList = await loadVipManagerLightModelsListRequest();
    const { currentVipLevelDetails, levelsDetails } =
      await loadVipManagerLoungesListRequest();
    const loungesListIds = levelsDetails.map(
      ({ vipConfigId }: { vipConfigId: string }) => vipConfigId
    );

    const vipModels = lightModelsList.vipModels
      .filter(({ id }) => loungesListIds.includes(id))
      .sort((a, b) => a.weight - b.weight);

    return { vipModels, currentVipLevelDetails, levelsDetails };
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
