import {
  parseBooleanEnabledSOC,
  parseNumberSOC,
} from "broadcast/imports/state";

export const isBroadcastDatadogLoggingEnabled = parseBooleanEnabledSOC(
  "web.broadcast.datadog.logging.enabled",
  "0"
);

export const isBroadcastConsoleLoggingEnabled = parseBooleanEnabledSOC(
  "web.broadcast.console.logging.enabled",
  "0"
);

export const isBroadcastInStreamPremiumEnabled = parseBooleanEnabledSOC(
  "web.broadcast.instream.premium.enabled",
  "0"
);

export const isH264CodecPresenceEnforced = parseBooleanEnabledSOC(
  "web.ant.media.server.h264.presence.check.enabled",
  "0"
);

export const isAntMediaServerOutgoingMediaGrouped = parseBooleanEnabledSOC(
  "web.ant.media.server.group.tracks.enabled",
  "0"
);

export const isBroadcastGatewayEndpointsEnabled = parseBooleanEnabledSOC(
  "web.broadcast.gateway.endpoints.enabled",
  "0"
);

export const livePartyVideoResolutionScaleDownBy = parseNumberSOC(
  "web.live.party.outgoing.video.downscale.factor",
  "4"
);
